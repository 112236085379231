import React, { useState } from 'react';

const ReadMoreText = ({ description = '', maxLength = 150, btnClasses }) => {
	const [showFullDescription, setShowFullDescription] = useState(false);

	const toggleDescription = () => {
		setShowFullDescription(!showFullDescription);
	};

	const renderDescription = () => {
		if (description?.length <= maxLength || showFullDescription) {
			return description;
		}
		return description?.slice(0, maxLength) + '...';
	};

	const renderReadMoreButton = () => {
		if (description?.length <= maxLength) {
			return null;
		}
		return (
			<button onClick={toggleDescription} className={btnClasses}>
				{showFullDescription ? 'Read Less' : 'Read More'}
			</button>
		);
	};

	return (
		<>
			{renderDescription()}
			{renderReadMoreButton()}
		</>
	);
};

export default ReadMoreText;
