import DropDown from './DropDown';
import SimpleText from '../TextViews/SimpleText';

export function EditableDropdown({
	Edit,
	label,
	name,
	options,
	value,
	onChange,
	className,
	selectDisabled,
	tooltipTitle,
	placeholder,
	selectClassName,
	required,
    style,
    disableNull,
    normalDropDown
}) {
    return (
		<div className='ProfilePage' data-toggle="tooltip" data-placement="top" title={tooltipTitle}>
			{Edit ? (
				<DropDown
                    normalDropDown={normalDropDown}
                    disableNull={disableNull}
					placeholder={placeholder}
					onChange={onChange}
					label={label}
					name={name}
					value={value}
					options={options}
					className={className + ' text-left m-0'}
					selectDisabled={selectDisabled}
					selectClassName={selectClassName}
					required={required}
                    style={style}
				/>
			) : (
				<SimpleText label={label} name={name} value={value?.label} />
			)}
		</div>
	);
}
