/* eslint-disable no-mixed-spaces-and-tabs */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import SkeletonLoading from '../../Others/SkeletonLoading';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as Close } from '../../../assets/icons/Close.svg';
import { ReactComponent as Edit } from '../../../assets/icons/PencilLight.svg';
import { ReactComponent as Copy } from '../../../assets/icons/CopySimple.svg';
import moment from 'moment';
import {
	MEETING_ACCEPT_STATUS,
	NOTIFICATION_STATUS,
	REPEAT_TYPE,
	TRACKING_STATUS,
	ordinal_suffix_of,
	toLocalTz,
	weekOfMonth,
} from '../../../utilities/utilities';
import { getWeeksInMonth } from 'date-fns/esm';
import * as Actions from '../../../redux/actions/dashboard/workspaces-actions';
import * as userActions from '../../../redux/actions/user-management.actions';
import * as ActionCenterActions from '../../../redux/actions/action-center';
import MeeingAgendaTab from './MeeingAgendaTab';
import MeetingGuestsTab from './MeetingGuestsTab';
import TimeTrackingTab from '../TimeTrackingTab';
import TimeTracker from './TimeTracker';
import NewMyTaskDeleteAlert from '../../Alerts/NewMyTaskDeleteAlert';
import EditMeetingMyTaskModal from './EditMeetingMyTaskModal';
import ExpandedNotes from './ExpandedNotes';
import TaskAlert from '../../Alerts/TaskAlert';
import useModal from '../../Modal/useModal';
import DeclineInviteModal from './DeclineInviteModal';
import RecurringMeetingInviteModal from './RecurringMeetingInviteModal';
import InviteConfirmModal from './InviteConfirmModal';
import { CircularProgress } from '@mui/material';
import { getUserSettings } from '../../../hooks/getUserSettings';

const MeetingMyTaskDetailsModal = ({ handleModalClose, id, handleTaskTimerRef }) => {
	const dispatch = useDispatch();
	const [edit, setEdit] = useState(false);
	const timeLoggedRef = useRef(0);
	const [taskDetails, setTaskDetails] = useState(undefined);
	const [taskInfoForEdit, setTaskInfoForEdit] = useState(undefined);
	const taskDetailsRedux = useSelector((state) => state.workSpaceReducer.newTaskDetails);

	useEffect(() => {
		if (taskDetailsRedux?.id) {
			const task_date = moment.utc(taskDetailsRedux?.start_date).format('YYYY-MM-DD');
			const startDateTime = moment.tz(
				task_date + ' ' + taskDetailsRedux.start_time,
				'YYYY-MM-DD HH:mm:ss',
			);
			const endDateTime = moment.tz(
				task_date + ' ' + taskDetailsRedux.end_time,
				'YYYY-MM-DD HH:mm:ss',
			);
			const localeStartDateTime = toLocalTz(startDateTime);
			const localeEndDateTime = toLocalTz(endDateTime);

			setTaskDetails({
				...taskDetailsRedux,
				start_date: toLocalTz(localeStartDateTime).format('YYYY-MM-DD'),
				start_time: moment(localeStartDateTime),
				end_time: moment(localeEndDateTime),
			});
		}
	}, [taskDetailsRedux]);

	const todayDate = moment().format('MM-DD-YYYY');
	const today = moment(todayDate).startOf('day');
	const task_date = moment(taskDetails?.start_date).format('MM-DD-YYYY');

	const start_date = moment(taskDetails?.start_date).startOf('day');
	const user = useSelector((state) => state.authReducer.user);
	const userDetails = useSelector((state) => state.authReducer.userDetails);
	const { date_format } = getUserSettings(userDetails);

	const timeTrackingError = useSelector((state) => state.workSpaceReducer.timeTrackingError);
	const isTaskEditAble =
		taskDetails?.my_task?.creator_id == user.userId && !start_date.isBefore(today);
	const weeklyMeetingTasks = useSelector((state) => state.workSpaceReducer.weeklyMeetingTasks);
	const assignee = taskDetails?.assignee_users?.find(
		(assignee) => assignee?.user_id === user?.userId,
	);
	const notifications = useSelector((state) => state.actionCenterReducer.notifications);
	const { modalIsOpen, toggleModal, ModalName, ModalType } = useModal();

	const [open, setOpen] = useState(false);
	const [inviteType, setInviteType] = useState('');
	const updateInviteRef = useRef(null);
	const [actionLoading, setActionLoading] = useState(false);
	const [acceptloading, setAcceptLoading] = useState(false);
	const [expandView, setExpandView] = useState({
		isExpanded: false,
		content: null,
		title: '',
		isEdit: false,
	});

	const is_time_tracking = useMemo(() => {
		return Boolean(
			taskDetails &&
				timeTrackingError == null &&
				task_date == todayDate &&
				taskDetails?.assignee_users?.some((assignee) => assignee.user_id === user.userId),
		);
	}, [taskDetails?.id, timeTrackingError, task_date, todayDate, user]);

	const tabs = [
		{
			name: 'Agenda',
			id: 1,
		},
		{
			name: 'Guests',
			id: 2,
		},
		{
			name: 'Time Tracking',
			id: 3,
		},
	];

	const [tab, setTab] = useState(tabs[0]);
	const changeTab = (item) => {
		setTab(item);
	};

	const toggleAlert = () => {
		setOpen(!open);
	};

	const copyLink = () => {
		dispatch(Actions.setSnackBarIsOpen(true, true, 'Text Copied'));
		navigator.clipboard.writeText(taskDetails?.metadata?.meeting_link || '');
	};

	const handleEditTask = () => {
        setTaskInfoForEdit(taskDetails);
		setEdit(true);
	};

	const cancelButtonClick = () => {
		setEdit(false);
	};

	const deleteTask = (delete_future) => {
		dispatch(
			Actions.deleteMeetingMyTask(
				{
					id: taskDetails?.id,
					delete_future,
					...taskDetails,
				},
				onDeleteSuccess,
			),
		);
		toggleAlert();
	};

	const onDeleteSuccess = (update_future) => {
		update_future && dispatch(Actions.getMeetings());
		dispatch(Actions.setSnackBarIsOpen(true, true, 'Meeting Deleted'));
		handleModalClose();
	};
	const handleInvitation = (event) => {
		const inviteType = event.target.name;
		setInviteType(inviteType);
		if (taskDetails?.task_type_id == 2) {
			ModalType('RECURRING_MEETING_INVITE');
			toggleModal();
		} else {
			inviteType == MEETING_ACCEPT_STATUS.ACCEPTED && setAcceptLoading(true);
			acceptInvitation(inviteType);
		}
	};

	const acceptInvitation = (inviteType, update_all) => {
		updateInviteRef.current = update_all;
		if (inviteType == MEETING_ACCEPT_STATUS.ACCEPTED) {
			setActionLoading(true);
			dispatch(
				Actions.updateMeetingInvitationStatus({
					task_id: taskDetails?.id,
					update_all: update_all == undefined ? false : update_all,
					status: inviteType,
				}),
			)
				.then(() => {
					if (weeklyMeetingTasks?.find((meeting) => meeting.task_id == taskDetails?.id)) {
						dispatch(Actions.setSnackBarIsOpen(true, true, 'Invitation accepted'));
						dispatch(Actions.getMeetings());
						modalIsOpen && toggleModal();
					} else {
						dispatch(Actions.getMeetings());
						ModalType('CONFIRM_INVITATION');
						!modalIsOpen && toggleModal();
					}

					setActionLoading(false);
					setAcceptLoading(false);
				})
				.catch((error) => {
					setActionLoading(false);
					setAcceptLoading(false);
					dispatch(Actions.setSnackBarIsOpen(false, true, error.message));
				});
		} else {
			ModalType('DECLINE_INVITATION');
			!modalIsOpen && toggleModal();
		}
	};
	const declineInvitaion = (reason) => {
		setActionLoading(true);
		dispatch(
			Actions.updateMeetingInvitationStatus({
				task_id: taskDetails?.id,
				update_all: updateInviteRef.current == undefined ? false : updateInviteRef.current,
				status: MEETING_ACCEPT_STATUS.REJECTED,
				reason: reason,
			}),
		)
			.then(() => {
				dispatch(Actions.getMeetings());
				ModalType('CONFIRM_INVITATION');
				toggleModal();
				handleModalClose();
				setActionLoading(false);
			})
			.catch((error) => {
				setActionLoading(false);
				dispatch(Actions.setSnackBarIsOpen(false, true, error.message));
			});
	};

	const unreadNotifications = notifications?.filter(
		(notification) =>
			notification?.metadata?.task_id === taskDetails?.id &&
			notification?.status === 'UNREAD',
	);

	useEffect(() => {
		if (unreadNotifications.length > 0) {
			const notificationIds = unreadNotifications?.map((notification) => notification?.id);
			dispatch(
				ActionCenterActions.markNotification(notificationIds, NOTIFICATION_STATUS.READ),
			)
				.then((response) => {
					const notifiactionIds = response;
					const updatedNotification = notifications?.map((notification) => {
						if (notifiactionIds?.includes(notification?.id)) {
							return {
								...notification,
								status: NOTIFICATION_STATUS.READ,
							};
						} else return notification;
					});
					dispatch(ActionCenterActions.updateNotifications(updatedNotification));
				})
				.catch((error) => {
					dispatch(Actions.setSnackBarIsOpen(false, true, error.message));
				});
		}
	}, [unreadNotifications]);

	const handleTaskTimerFunction = useCallback(
		(tracking_status) => {
			handleTaskTimerRef.current(tracking_status);
		},
		[handleTaskTimerRef],
	);

	useEffect(() => {
		if (is_time_tracking) {
			handleTaskTimerFunction(TRACKING_STATUS.STARTED);
		}
	}, [handleTaskTimerFunction, is_time_tracking]);

	useEffect(() => {
		let intervalId = null;
		if (is_time_tracking) {
			intervalId = setInterval(() => {
				if (is_time_tracking) {
					handleTaskTimerFunction(TRACKING_STATUS.IN_PROGRESS);
				}
			}, 10000);
		} else {
			clearInterval(intervalId);
		}
		return () => clearInterval(intervalId);
	}, [handleTaskTimerFunction, is_time_tracking]);

	useEffect(() => {
		dispatch(userActions.getAllTenantUsers());

		return () => {
			dispatch(Actions.resetTaskDetails());
			dispatch(Actions.resetTaskTimer());
			dispatch(Actions.resetComment());
		};
	}, [dispatch]);

	useMemo(() => {
		dispatch(Actions.resetTimeTrackingError());
	}, []);

	useEffect(() => {
		if (taskDetails) {
			dispatch(Actions.getTaskComments(taskDetails?.id));
		}
	}, [taskDetails?.id]);

	return (
		<>
			<>
				{edit && taskInfoForEdit && (
					<EditMeetingMyTaskModal
						taskDetails={taskInfoForEdit}
						cancelButtonClick={() => {
                            setTaskInfoForEdit(undefined);
                            cancelButtonClick();
                        }}
						setEdit={setEdit}
						handleModalClose={() => {
                            setTaskInfoForEdit(undefined);
                            handleModalClose();
                        }}
						modalStyle={{
							position: 'absolute',
							zIndex: 1,
						}}
					/>
				)}
				{expandView.isExpanded && (
					<ExpandedNotes
						content={expandView?.content}
						title={expandView?.title}
						isEdit={expandView?.isEdit}
						setExpandView={setExpandView}
						taskDetails={taskDetailsRedux}
					/>
				)}
				<div className='meeting-my-details-modal'>
					<div className='meeting-task-details-header'>
						<div
							className='header-details w-100'
							style={{
								borderLeft: `${
									taskDetails
										? `4px solid ${
												taskDetails?.metadata?.color
													? taskDetails?.metadata?.color
													: '#FB7A03'
										  }`
										: ''
								}`,
							}}
						>
							<div className='d-flex flex-row justify-content-between align-items-center'>
								<SkeletonLoading
									loading={taskDetails ? false : true}
									variant='text'
									width={100}
									height={45}
								>
									<div className='d-flex'>
										<div className='HeadlineTwoBold dark-text task-title'>
											{taskDetails?.title}
										</div>

										<TimeTracker
											id={id}
											handleTaskTimerRef={handleTaskTimerRef}
											timeLoggedRef={timeLoggedRef}
											taskDetails={taskDetails}
											isTimeTracking={is_time_tracking}
										/>
									</div>
								</SkeletonLoading>

								<div
									style={{ gap: '8px' }}
									className='d-flex flex-row action-icons'
								>
									{isTaskEditAble && (
										<>
											<SkeletonLoading
												loading={taskDetails ? false : true}
												variant='text'
												width={32}
												height={32}
											>
												<span className='pointer' onClick={handleEditTask}>
													<Edit />
												</span>
											</SkeletonLoading>
										</>
									)}
									<SkeletonLoading
										loading={taskDetails ? false : true}
										variant='text'
										width={50}
										height={32}
									>
										<span className='pointer' onClick={handleModalClose}>
											<Close />
										</span>
									</SkeletonLoading>
								</div>
							</div>

							<div className='BodyTwoLight dark-text text-left'>
								<SkeletonLoading
									loading={taskDetails ? false : true}
									variant='text'
									width={200}
									height={30}
								>
									<>
										<span>
											{moment(taskDetails?.start_date).format('dddd')} {' - '}{' '}
											{moment(taskDetails?.start_date).format(date_format)}
										</span>

										{taskDetails?.start_time && taskDetails?.end_time && (
											<span className='pl-2'>
												{`${moment(taskDetails?.start_time, [
													'HH.mm',
												]).format('hh:mm a')} - ${moment(
													taskDetails?.end_time,
													['HH.mm'],
												).format('hh:mm a')}`}
											</span>
										)}
									</>
								</SkeletonLoading>
							</div>

							<div className='BodyTwoLight dark-text text-left'>
								<SkeletonLoading
									loading={taskDetails ? false : true}
									variant='text'
									width={150}
									height={30}
								>
									{taskDetails?.repeat_interval === null && 'Today'}
									{taskDetails?.repeat_interval !== null && (
										<>
											{/* For Daily Recurrence */}
											{taskDetails?.repeat_type === REPEAT_TYPE.DAILY &&
												`Every ${
													taskDetails?.repeat_interval != 1
														? ordinal_suffix_of(
																taskDetails?.repeat_interval,
														  )
														: ''
												} day`}

											{/* For Weekly Recurrence */}
											{taskDetails?.repeat_type === REPEAT_TYPE.WEEKLY &&
												`${moment(taskDetails?.start_date).format(
													'dddd',
												)} of every ${
													taskDetails?.repeat_interval != 1
														? ordinal_suffix_of(
																taskDetails?.repeat_interval,
														  )
														: ''
												} week`}

											{/* For Monthly Recurrence */}
											{taskDetails?.repeat_type === REPEAT_TYPE.MONTHLY && (
												<>
													{taskDetails?.recur_preference
														? ` ${
																taskDetails?.recur_preference === 1
																	? '4th'
																	: 'last'
														  } ${moment(
																taskDetails?.start_date,
														  ).format('dddd')} of every ${
																taskDetails?.repeat_interval != 1
																	? ordinal_suffix_of(
																			taskDetails?.repeat_interval,
																	  )
																	: ''
														  } month`
														: `${
																getWeeksInMonth(
																	taskDetails?.repeat_interval,
																) ==
																weekOfMonth(
																	moment(taskDetails?.start_date),
																)
																	? 'last'
																	: ordinal_suffix_of(
																			weekOfMonth(
																				moment(
																					taskDetails?.start_date,
																				),
																			),
																	  )
														  } ${moment(
																taskDetails?.start_date,
														  ).format('dddd')} of every ${
																taskDetails?.repeat_interval != 1
																	? ordinal_suffix_of(
																			taskDetails?.repeat_interval,
																	  )
																	: ''
														  } month`}
												</>
											)}
											{/* For Yearly Recurrence */}
											{taskDetails?.repeat_type === REPEAT_TYPE.YEARLY &&
												`${moment(taskDetails?.start_date).format(
													'MMMM DD',
												)} of every ${
													taskDetails?.repeat_interval != 1
														? ordinal_suffix_of(
																taskDetails?.repeat_interval,
														  )
														: ''
												}  year`}
										</>
									)}
								</SkeletonLoading>
							</div>
							{taskDetails?.metadata?.meeting_link && (
								<div
									className='BodyTwoLight d-flex'
									style={{ gap: '6px', width: '100%' }}
								>
									<SkeletonLoading
										loading={taskDetails ? false : true}
										variant='text'
										width={100}
										height={45}
									>
										<span
											style={{
												whiteSpace: 'nowrap',
												overflow: 'hidden',
												textOverflow: 'ellipsis',
											}}
											onClick={() =>
												window.open(
													taskDetails?.metadata?.meeting_link,
													'_blank',
												)
											}
											className='primary-text pointer text-left'
										>
											{taskDetails?.metadata?.meeting_link}
										</span>
									</SkeletonLoading>
									<SkeletonLoading
										loading={taskDetails ? false : true}
										variant='text'
										width={100}
										height={45}
									>
										<span className='pointer' onClick={copyLink}>
											<Copy />
										</span>
									</SkeletonLoading>
								</div>
							)}
						</div>
					</div>

					<div className='meeting-task-details-body'>
						<SkeletonLoading
							loading={taskDetails ? false : true}
							variant='text'
							width={'100%'}
							height={45}
						>
							<div
								className='HeadlineTwoBold dark-text text-left'
								style={{ height: 26 }}
							>
								{taskDetails?.client?.dba || taskDetails?.client?.company_name}
							</div>
						</SkeletonLoading>

						<SkeletonLoading
							loading={taskDetails ? false : true}
							variant='text'
							width={100}
							height={45}
						>
							<div className='task-tabs-container'>
								<div className='task-tabs w-100'>
									{tabs.map((item) => {
										return (
											<div
												onClick={
													!taskDetails ? null : () => changeTab(item)
												}
												key={item.id}
												className={`task-tab ${
													tab.id == item.id && 'active-tab'
												} `}
											>
												<span className='BodyTwoBold'>{item.name}</span>
											</div>
										);
									})}
								</div>
								<SkeletonLoading
									loading={taskDetails ? false : true}
									variant='text'
									width={'100%'}
									height={422}
								>
									<div
										key={tab.id}
										className='task-tabs-details-container'
										style={{ backgroundColor: tab.id === 3 ? 'white' : '' }}
									>
										{tab.id === 1 && (
											<MeeingAgendaTab
												id={id}
												setExpandView={setExpandView}
											/>
										)}
										{tab.id === 2 && (
											<MeetingGuestsTab setExpandView={setExpandView} />
										)}
										{tab.id === 3 && (
											<TimeTrackingTab taskDetails={taskDetails} />
										)}
									</div>
								</SkeletonLoading>
							</div>
						</SkeletonLoading>
					</div>

					<div className='meeting-task-details-footer justify-content-between'>
						{isTaskEditAble && (
							<button
								type='button'
								id='Element'
								name={`${MEETING_ACCEPT_STATUS.REJECTED}`}
								className='d-flex align-items-center secondary'
								style={{
									borderRadius: '50px',
									width: '160px',
									height: '32px',
								}}
								onClick={() => toggleAlert()}
							>
								Delete Meeting
							</button>
						)}
						{taskDetails?.my_task?.creator_id !== user?.userId && (
							<SkeletonLoading
								loading={taskDetails ? false : true}
								variant='text'
								width={200}
								height={40}
							>
								<div className='d-flex align-items-center' style={{ gap: '8px' }}>
									<span className='BodyOneBold'>Accept Invitation?</span>
									<div className='rounded-bottom d-flex' style={{ gap: 8 }}>
										<button
											name={`${MEETING_ACCEPT_STATUS.ACCEPTED}`}
											type='button'
											id='Element'
											className={`align-items-center ${
												assignee?.status == MEETING_ACCEPT_STATUS.ACCEPTED
													? 'primary'
													: 'secondary'
											}`}
											style={{
												borderRadius: '50px',
												width: '125px',
												height: '32px',
											}}
											disabled={
												actionLoading ||
												assignee?.status == MEETING_ACCEPT_STATUS.ACCEPTED
											}
											onClick={handleInvitation}
										>
											{acceptloading ? (
												<CircularProgress
													size={24}
													sx={{ color: '#FB7A03' }}
													disableShrink
												/>
											) : (
												'Yes'
											)}
										</button>
										<button
											type='button'
											name={`${MEETING_ACCEPT_STATUS.REJECTED}`}
											className='d-flex align-items-center secondary'
											style={{
												borderRadius: '50px',
												width: '125px',
												height: '32px',
											}}
											id='Element'
											onClick={handleInvitation}
										>
											No
										</button>
									</div>
									<span className='BodyTwoLight'>
										Not sure? Click <strong>Yes</strong>. You can decline the
										invitation later.
									</span>
								</div>
							</SkeletonLoading>
						)}
					</div>

					<TaskAlert
						message={timeTrackingError?.message}
						header={'Alert'}
						open={timeTrackingError && timeTrackingError !== null}
						closeHandler={() => {
							handleModalClose();
							dispatch(Actions.getMeetings());
						}}
						closeButton='OK'
					/>

					<NewMyTaskDeleteAlert
						open={open}
						taskType={taskDetails?.task_type_id}
						closeHandler={toggleAlert}
						submitHandler={({ delete_future }) => {
							deleteTask(delete_future);
						}}
					/>
					{ModalName == 'RECURRING_MEETING_INVITE' && (
						<RecurringMeetingInviteModal
							open={modalIsOpen}
							inviteType={inviteType}
							closeHandler={toggleModal}
							submitHandler={acceptInvitation}
							loading={actionLoading}
						/>
					)}
					{ModalName == 'DECLINE_INVITATION' && (
						<DeclineInviteModal
							open={modalIsOpen}
							closeHandler={toggleModal}
							declineInvite={declineInvitaion}
							loading={actionLoading}
						/>
					)}
					{ModalName == 'CONFIRM_INVITATION' && (
						<InviteConfirmModal open={modalIsOpen} closeHandler={toggleModal} />
					)}
				</div>
			</>
		</>
	);
};

export default MeetingMyTaskDetailsModal;
