/* eslint-disable no-mixed-spaces-and-tabs */
import moment from 'moment';
import React, { useEffect, memo, useMemo, useCallback } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from '../../redux/actions/dashboard/workspaces-actions';
import { useHistory } from 'react-router-dom';
import ExpandableClientTasksView from './ExpandableClientTasksView';
import { useSessionStorage } from '../../hooks/useSessionStorage';
import TaskAlert from '../Alerts/TaskAlert';
import { ERROR_CODE, detailsModal, taskDragAlertContent } from '../../utilities/utilities';
import WorkspaceDateNavigation from './WorkspaceDateNavigation';
import MeetingsAndMyTasksview from './MeetingsAndMyTasksview';

const WeeklyCalendar = () => {
	const history = useHistory();
	// let monday = null;
	// if (days?.length > 0) {
	// 	monday = days[0];
	// }

	const dispatch = useDispatch();
	const [dragOverContainer, setDragOverContainer] = useState('');
	const active_task_id = useSessionStorage('active_task_id');
	const [currentTaskDetails, setCurrentTaskDetails] = useState();
	const start_date = useSelector((state) => state.workSpaceReducer.start_date);
	const end_date = useSelector((state) => state.workSpaceReducer.end_date);
	const weekDaysOff = useSelector((state) => state.workSpaceReducer.weekDaysOff);
	const weekData = useSelector((state) => state.workSpaceReducer.weekDate);
	const days = weekData ? weekData?.daysOfTheWeek : [];
	const triggerGetTasks = useSelector((state) => state.workSpaceReducer.triggerGetTasks);
	const showTeamMembersMeetings = useSelector(
		(state) => state.workSpaceReducer.showTeamMembersMeetings,
	);
	const weeklyClientTasks = useSelector((state) => state.workSpaceReducer.weeklyClientTasks);
	const [taskDetailsForError, setTaskDetailsForError] = useState(null);
	const [dateToUpdate, setDateToUpdate] = useState(null);
	const [isTaskAlert, setIsTaskAlert] = useState(false);
	const taskDragError = useSelector((state) => state.workSpaceReducer.taskDragError);
	const { header, doneButtonText, cancelButtonText } =
		isTaskAlert && taskDragAlertContent(taskDragError?.error_code);

	const isTaskMinimized = useSelector((state) => state.workSpaceReducer.isTaskMinimized);

	const filters = useSelector((state) => state.workSpaceReducer.filters);

	const getTaskDetails = useCallback(
		(currentTask) => () => {
			setCurrentTaskDetails(currentTask);
		},
		[],
	);

	const handleDragEnd = useCallback(
		(task, index) => () => {
			const dragOverDate = moment(dragOverContainer).format('YYYY-MM-DD');
			setCurrentTaskDetails(null);
			setTaskDetailsForError(task);
			setDateToUpdate(dragOverDate);
			setDragOverContainer('');
			const task_date = task.extended_date
				? moment(task?.extended_date)
				: moment(task?.start_date);
			const start_date = days?.length > 0 ? days[0].format('YYYY-MM-DD') : undefined;
			const end_date =
				days?.length > 0 ? days[days?.length - 1].format('YYYY-MM-DD') : undefined;

			if (moment(dragOverDate).isValid() && !moment(dragOverDate).isSame(task_date)) {
				dispatch(
					Actions.updateTaskCard(
						{ task, index, dragOverContainer: dragOverDate, weeklyClientTasks },
						true,
						filters,
						start_date,
						end_date,
					),
				);
			}
		},
		[dragOverContainer],
	);

	const handleDragOver = useCallback(
		(date) => (e) => {
			e.preventDefault();
			setDragOverContainer(date);
		},
		[],
	);

	const toggleTaskAlert = () => {
		setIsTaskAlert(!isTaskAlert);
	};

	const handleDoneTaskAlert = (error_code) => {
		toggleTaskAlert();
		dispatch(Actions.resetTaskError());

		if (
			error_code == ERROR_CODE.START_DATE_BEFORE_PRECEEDING_TASK_SAME_ASSIGNEE ||
			error_code == ERROR_CODE.START_DATE_BEFORE_PRECEEDING_TASK_DIFFERENT_ASSIGNEE
		) {
			dispatch(
				Actions.handleTaskClick({
					taskId: taskDetailsForError?.dependent_task_id,
					modalType: detailsModal.CLIENT,
				}),
			);
		}
		if (
			error_code == ERROR_CODE.MOVED_BY_MANAGER_BEFORE_START_DATE ||
			error_code == ERROR_CODE.MOVED_BY_MANAGER_AFTER_DUE_DATE
		) {
			if (taskDetailsForError?.my_task?.task_type == 'ADHOC') {
				updateAdhocTaskAlertOnAlert(error_code);
			} else {
				dispatch(
					Actions.updateTaskDetails(taskDetailsForError?.id, {
						start_date:
							error_code == ERROR_CODE.MOVED_BY_MANAGER_BEFORE_START_DATE
								? dateToUpdate
								: taskDetailsForError?.start_date,
						due_date:
							error_code == ERROR_CODE.MOVED_BY_MANAGER_AFTER_DUE_DATE
								? dateToUpdate
								: taskDetailsForError?.due_date,
						extended_date: dateToUpdate,
					}),
				)
					.then(() => {})
					.catch((error) => {
						dispatch(Actions.setSnackBarIsOpen(false, true, error.message));
					});
			}
		}
	};

	const updateAdhocTaskAlertOnAlert = (error_code) => {
		const formDetails = {
			id: taskDetailsForError?.id,
			type: taskDetailsForError?.my_task?.type,
			title: taskDetailsForError?.title,
			client_id:
				taskDetailsForError?.client_id == null
					? null
					: Number(taskDetailsForError?.client_id),
			budgeted_hours: Number(taskDetailsForError?.budgeted_hours),
			assignee_list:
				taskDetailsForError?.task_assignees?.length > 0
					? [taskDetailsForError?.task_assignees[0]?.user_profile?.id]
					: [],
			assignee_user_id: Number(taskDetailsForError?.task_assignees[0]?.user_profile?.id),
			approval_from: taskDetailsForError?.approval_from
				? Number(taskDetailsForError?.approval_from)
				: null,
			is_approval_required: taskDetailsForError?.is_approval_required,
			start_date:
				error_code == ERROR_CODE.MOVED_BY_MANAGER_BEFORE_START_DATE
					? dateToUpdate
					: taskDetailsForError?.start_date,
			extended_date: dateToUpdate,
			is_billable: taskDetailsForError?.my_task?.is_billable,
			task_type: taskDetailsForError?.my_task?.task_type,
			end_on: taskDetailsForError?.my_task?.end_on,
			required_days:
				error_code == ERROR_CODE.MOVED_BY_MANAGER_AFTER_DUE_DATE
					? moment(dateToUpdate).diff(moment(taskDetailsForError?.start_date), 'days') + 1
					: moment(taskDetailsForError?.due_date).diff(moment(dateToUpdate), 'days') + 1,
			repeat_type: taskDetailsForError?.my_task?.repeat_type,
			repeat_interval:
				taskDetailsForError?.my_task?.repeat_interval == null
					? null
					: Number(taskDetailsForError?.my_task?.repeat_interval),
			recur_preference: taskDetailsForError?.my_task?.recur_preference,
			is_monthly_on_day: taskDetailsForError?.my_task?.is_monthly_on_day,
		};
		dispatch(Actions.updateMeetingTaskDetails(formDetails, false))
			.then(() => {})
			.catch((error) => {
				dispatch(Actions.setSnackBarIsOpen(false, true, error.message));
			});
	};

	useMemo(() => {
		if (taskDragError !== null) {
			toggleTaskAlert();
		}
	}, [taskDragError]);

	useEffect(() => {
		history.push(
			`/workspace/${start_date}/${
				active_task_id.getSessionStorage() && !isTaskMinimized
					? `?task_id=${active_task_id.getSessionStorage()}`
					: ''
			}`,
		);
		if (start_date && end_date && !showTeamMembersMeetings) {
			dispatch(Actions.getWeeklyClientTasks(filters, start_date, end_date));
		}
	}, [dispatch, filters, triggerGetTasks, start_date, end_date, showTeamMembersMeetings]);

	useEffect(() => {
		return () => {
			dispatch(Actions.resetWorkspaceTasks());
		};
	}, []);

	return (
		<React.Fragment>
			<div
				className='d-flex flex-column'
				style={{
					minWidth: weekDaysOff ? '1400px' : '1000px',
					padding: '0px 12px 0px 12px',
					boxSizing: 'border-box',
				}}
			>
				<WorkspaceDateNavigation dragOverContainer={dragOverContainer} />
				<div className='d-flex flex-column'>
					<MeetingsAndMyTasksview weekDaysOff={weekDaysOff} days={days} />
					{!showTeamMembersMeetings &&
						weeklyClientTasks
							?.map((item) => {
								return {
									...item,
									displayName:
										item?.client_dba?.trim() == ''
											? item?.client_name
											: item?.client_dba,
								};
							})
							?.sort((a, b) => {
								if (a.displayName && b.displayName) {
									return a.displayName.localeCompare(b.displayName);
								}
							})
							?.map((client, i) => {
								return (
									<div
										key={client?.client_id}
										className='d-flex'
										style={{
											height: 'auto',
											width: '100%',
											borderRadius: '4px',
											borderTopRightRadius: i === 0 ? '0px' : '4px',
											borderTopLeftRadius: i === 0 ? '0px' : '4px',
											background: i % 2 === 0 ? '#FCFDFD' : '#F7F9FA',
											marginBottom: '24px',
											paddingBottom: '12px',
										}}
									>
										<ExpandableClientTasksView
											client={client}
											weekends={weekDaysOff}
											i={i}
											days={days}
											handleDragEnd={handleDragEnd}
											getTaskDetails={getTaskDetails}
											currentTaskDetails={currentTaskDetails}
											handleDragOver={handleDragOver}
											dragOverContainer={dragOverContainer}
										/>
									</div>
								);
							})}
				</div>
			</div>
			<TaskAlert
				message={taskDragError?.message}
				header={header}
				open={isTaskAlert}
				onDone={() => handleDoneTaskAlert(taskDragError?.error_code)}
				doneButton={doneButtonText}
				closeButton={cancelButtonText}
				closeHandler={() => {
					toggleTaskAlert();
					dispatch(Actions.resetTaskError());
				}}
			/>
			{/* {
				error && 
				<div style={{position:'absolute', top:'0', bottom:'0', right:'0', left:'0', justifyContent:'center', alignItems:'center', display:'flex', zIndex:'10'}}>
					<Alert error={error}/>
				</div>
			} */}
		</React.Fragment>
	);
};

export default memo(WeeklyCalendar);
