import { useDispatch, useSelector } from 'react-redux';
import Sidebar from '../sidebar/SideBar';
import { getUserSettings } from '../../hooks/getUserSettings';
import DropDown from '../Inputs/DropDown';
import { getTimeRange, TIME_RANGE_OPTIONS } from '../../utilities/TimeRangeUtils';
import React, { useEffect, useMemo, useState } from 'react';
import { roundUpConsumedTimeInDecimal, splitName } from '../../utilities/utilities';
import * as TimeReportsActions from '../../redux/actions/time-reports-actions-v2';
import * as workspaceActions from '../../redux/actions/dashboard/workspaces-actions';
import SkeletonLoading from '../Others/SkeletonLoading';
import { useHistory } from 'react-router';
import { CSVLink } from 'react-csv';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import NewDateRangePicker from '../Inputs/NewDateRangePicker';
import MultipleDropDown from '../Inputs/MultipleDropDown';
import * as userActions from '../../redux/actions/user-management.actions';
import * as teamActions from '../../redux/actions/team-management.actions';

const UserTableHeader = [
	{ label: 'First Name', key: 'firstName' },
	{ label: 'Last Name', key: 'lastName' },
	{ label: 'Logged Time', key: 'consumed_time_in_decimal' },
];

function UsersPayrollExport() {
	const history = useHistory();
	const dispatch = useDispatch();

	const [usersData, setUsersData] = useState([]);
	const [dateRanges, setDateRanges] = useState(null);
	const [dateRange, setDateRange] = useState(TIME_RANGE_OPTIONS[2].value);
	const [loadingUsersData, setLoadingUsersData] = useState(true);
	const [userOptions, setUserOptions] = useState([]);
	const [teamOptions, setTeamOptions] = useState([]);

	const userDetails = useSelector((state) => state.authReducer.userDetails);
	const { date_format } = getUserSettings(userDetails);

	const totalConsumedTimeSum = useMemo(() => {
		if (!usersData?.length) return 0;

		return roundUpConsumedTimeInDecimal(
			usersData.reduce((acc, userTimeData) => {
				return acc + userTimeData.consumed_time;
			}, 0) / 3600000,
		);
	}, [usersData]);

	const navigateToTimeReports = () => {
		history.push('/time-reports');
	};

	const onTimeChange = (event) => {
		setDateRange(event.target.value);
	};

	const loadData = (users = [], teams = []) => {
		setLoadingUsersData(true);
		dispatch(
			TimeReportsActions.getUserStats(
				dateRanges.from.format('YYYY-MM-DD'),
				dateRanges.to.format('YYYY-MM-DD'),
				{
					users: users?.filter((item) => item.is_checked)?.map(({ value }) => value),
					teams: teams?.filter((item) => item.is_checked)?.map(({ value }) => value),
				},
			),
		)
			.then((result) => {
				const formatData = result
					?.filter((item) => item.consumed_time >= 900000)
					?.map((timeData) => {
						const { firstName, lastName } = splitName(timeData?.full_name);
						return {
							...timeData,
							firstName,
							lastName,
							consumed_time_in_decimal: roundUpConsumedTimeInDecimal(
								timeData.consumed_time / 3600000,
							),
						};
					});
				setUsersData(formatData);
				setLoadingUsersData(false);
			})
			.catch((error) => {
				dispatch(workspaceActions.setSnackBarIsOpen(false, true, error?.message));
				setLoadingUsersData(false);
			});
	};

	useEffect(() => {
		if (dateRanges) loadData(userOptions, teamOptions);
	}, [dateRanges]);

	useEffect(() => {
		const selectedDateRanges = getTimeRange(dateRange);
		if (selectedDateRanges) setDateRanges(selectedDateRanges);
	}, [dateRange]);

	useEffect(() => {
		dispatch(userActions.getAllTenantUsers()).then((result) => {
			const users = result?.map((item) => {
				return {
					value: item.id,
					label: item.full_name,
					is_checked: false,
				};
			});
			setUserOptions(users);
		});
		dispatch(teamActions.getTeams()).then((result) => {
			const teams = result?.map((item) => {
				return {
					value: item.id,
					label: item.name,
					is_checked: false,
				};
			});
			setTeamOptions(teams);
		});
	}, []);

	return (
		<div className='AppBody'>
			<Sidebar title='Time Reports'>
				<div className='SidebarContentWrapper'>
					<div className='User-filters'></div>
				</div>
			</Sidebar>
			<div className='time-report-preview'>
				<div className='time-report-preview-content-wrapper'>
					<div className='py-2 d-flex flex-row align-items-center pointer primary-text'>
						<ArrowLeftIcon />
						<h2 onClick={navigateToTimeReports} className='HeadlineThreeBook text-left'>
							Time Reports
						</h2>
					</div>

					<div
						className='d-flex flex-column'
						style={{
							background: 'white',
							padding: '18px',
							borderRadius: '4px',
							gap: '24px',
						}}
					>
						<div className='w-100 d-flex justify-content-between'>
							<SkeletonLoading
								loading={loadingUsersData}
								variant='text'
								width='20%'
								height={40}
							>
								<span className='HeadlineThreeBold'>Payroll Export</span>
							</SkeletonLoading>

							<SkeletonLoading
								loading={loadingUsersData}
								variant='button'
								className='rounded-1'
								width='15%'
								height={40}
							>
								<button className='rounded-button-1'>
									<CSVLink
										data={usersData}
										headers={UserTableHeader}
										filename={`${'users-payroll-'}_${dateRanges?.from?.format(
											date_format,
										)} - ${dateRanges?.to?.format(date_format)}.csv`}
										className=' text-white text-decoration-none'
									>
										Download
									</CSVLink>
								</button>
							</SkeletonLoading>
						</div>

						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								borderRadius: '4px',
								padding: '18px',
								gap: '24px',
								background: '#F7F9FA',
								width: '100%',
							}}
						>
							<div
								className='w-100 d-flex'
								style={{ gap: '24px', background: '#F7F9FA' }}
							>
								<SkeletonLoading
									loading={loadingUsersData}
									variant='rectangular'
									className='rounded-1'
									width='25%'
									height='30px'
								>
									<div className='improved-input-designs text-left w-25'>
										<label>Date Range</label>
										<DropDown
											normalDropDown
											style={{
												height: '30px',
												borderRadius: '4px',
												minWidth: '200px',
											}}
											onChange={onTimeChange}
											options={TIME_RANGE_OPTIONS}
											value={dateRange}
											disableIcon
											disableNull
										/>
									</div>
								</SkeletonLoading>

								{dateRange === TIME_RANGE_OPTIONS[0].value && (
									<SkeletonLoading
										loading={loadingUsersData}
										variant='rectangular'
										className='rounded-1'
										width='25%'
										height='30px'
									>
										<div className='d-flex w-25' style={{ gap: '24px' }}>
											<NewDateRangePicker
												label='Select Date Ranges'
												dateRanges={dateRanges}
												applyDates={(dates) => {
													setDateRanges(dates);
												}}
											/>
										</div>
									</SkeletonLoading>
								)}

								<SkeletonLoading
									loading={loadingUsersData}
									variant='rectangular'
									className='rounded-1'
									width='25%'
									height='30px'
								>
									<div className='improved-input-designs text-left w-25'>
										<label>Teams</label>
										<MultipleDropDown
											selectStyle={{
												height: '30px',
											}}
											options={teamOptions}
											onApply={(teams) => {
												setTeamOptions(teams);
												loadData(userOptions, teams);
											}}
										/>
									</div>
								</SkeletonLoading>

								<SkeletonLoading
									loading={loadingUsersData}
									variant='rectangular'
									className='rounded-1'
									width='25%'
									height='30px'
								>
									<div className='improved-input-designs text-left w-25'>
										<label>User</label>
										<MultipleDropDown
											selectStyle={{
												height: '30px',
											}}
											options={userOptions}
											onApply={(users) => {
												setUserOptions(users);
												loadData(users, teamOptions);
											}}
										/>
									</div>
								</SkeletonLoading>
							</div>

							{dateRanges && (
								<div className='date-range-bar d-flex justify-content-between'>
									<SkeletonLoading
										loading={loadingUsersData}
										className='rounded-1'
										width='40%'
										height={34}
										variant='rectangular'
									>
										<div className='HeadlineTwoBold'>
											{`${dateRanges.from.format(date_format)} -
										${dateRanges.to.format(date_format)}`}
										</div>
									</SkeletonLoading>

									<SkeletonLoading
										loading={loadingUsersData}
										className='rounded-1'
										width='20%'
										height={34}
										variant='rectangular'
									>
										<div className='HeadlineTwoBold'>
											{`Total Hours ${totalConsumedTimeSum}`}
										</div>
									</SkeletonLoading>
								</div>
							)}

							<div
								style={{
									boxSizing: 'border-box',
								}}
								className='d-flex flex-grow-1'
							>
								<UsersTable data={usersData} loading={loadingUsersData} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default UsersPayrollExport;

const UsersTable = ({ data, loading = true }) => {
	return (
		<table className='TimeReportsPreviewTable'>
			<SkeletonLoading loading={loading} variant='text' width='100%' height={45}>
				<thead>
					<tr>
						{UserTableHeader.map((header) => (
							<React.Fragment key={header.key}>
								<th>{header.label}</th>
							</React.Fragment>
						))}
					</tr>
				</thead>
			</SkeletonLoading>

			<tbody>
				{data.length == 0 && (
					<SkeletonLoading loading={loading} variant='text' width='100%' height={45}>
						<tr>
							<td className='text-center light-text' colSpan={3}>
								No User Data in this period
							</td>
						</tr>
					</SkeletonLoading>
				)}
				{data?.map((timeData) => {
					return (
						<React.Fragment key={timeData?.id}>
							<SkeletonLoading
								loading={loading}
								variant='text'
								width='100%'
								height={45}
							>
								<tr>
									<td>{timeData?.firstName}</td>
									<td>{timeData?.lastName}</td>
									<td>{timeData?.consumed_time_in_decimal}</td>
								</tr>
							</SkeletonLoading>
						</React.Fragment>
					);
				})}
				<tr></tr>
			</tbody>
		</table>
	);
};
