/* eslint-disable no-mixed-spaces-and-tabs */
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from '../../../redux/actions/dashboard/workspaces-actions';
import Timer from 'react-compound-timer/build';
import moment from 'moment';
import useTimeZone from '../../../hooks/useTimeZone';
import { TRACKING_STATUS } from '../../../utilities/utilities';

const TimeTracker = ({ handleTaskTimerRef, id, timeLoggedRef, taskDetails, isTimeTracking }) => {
	const dispatch = useDispatch();

	const user = useSelector((state) => state.authReducer.user);
	const taskTimerData = useSelector((state) => state.workSpaceReducer.taskTimerData);
	const task = taskDetails?.assignee_users?.some((assignee) => assignee.user_id === user.userId)
		? taskDetails
		: null;

	var consumedTime = undefined;
	if (task) {
		const taskTimer = task?.task_timers?.find(
			(timer) =>
				timer?.user_id === user?.userId &&
				timer?.task_id === task?.id &&
				moment().format('MM:DD:YYYY') == moment(timer?.logged_date).format('MM:DD:YYYY'),
		);
		consumedTime = taskTimer ? taskTimer?.consumed_time : undefined;
	}
	const [timeLogged, setTimeLogged] = useState(timeLoggedRef.current);

	const timeFormat = (time) => time.toString().padStart(2, '0');

	const handleLoggedTime = (time) => {
		timeLoggedRef.current = time;
		setTimeLogged(time);
	};

	const compareTimeLoggedWithConsumedTime = () => {
		if (parseInt(consumedTime) <= parseInt(timeLogged)) return timeLogged;
		if (parseInt(consumedTime) > parseInt(timeLogged)) return consumedTime;
	};
	handleTaskTimerRef.current = useCallback(
		(tracking_status) => {
			const { formattedTimeZone } = useTimeZone();

			const loggedDate =
				tracking_status == TRACKING_STATUS.STARTED
					? moment().format('YYYY-MM-DD')
					: moment(taskTimerData?.logged_date).format('YYYY-MM-DD');

			if (isTimeTracking) {
				dispatch(
					Actions.handleTaskTimer(
						{
							task_id: taskDetails?.id,
							user_id: user?.userId,
							consumed_time: JSON.stringify(
								Math.floor(
									parseInt(
										consumedTime
											? compareTimeLoggedWithConsumedTime()
											: timeLogged,
									),
								),
							),
							logged_date: loggedDate,
							timezone: formattedTimeZone,
						},
						undefined,
						tracking_status,
					),
				);
			}
		},
		[dispatch, id, timeLogged, user, taskTimerData, isTimeTracking],
	);

	return (
		<div className='d-flex align-items-center'>
			{isTimeTracking && (
				<>
					<div>
						<span className='BodyTwoLight'>
							{
								<Timer
									initialTime={parseInt(
										consumedTime
											? compareTimeLoggedWithConsumedTime()
											: timeLogged,
									)}
								>
									{({ getTime }) => {
										handleLoggedTime(getTime());
										return (
											<div
												className='d-flex align-items-center justify-content-center'
												style={{
													width: '120px',
													height: '20px',
													background: '#FB7A03',
													borderRadius: '50px',
													padding: '2px 8px',
													marginLeft: '8px',
												}}
											>
												<span className='BodyOneBold text-white'>
													<Timer.Hours formatValue={timeFormat} /> :{' '}
													<Timer.Minutes formatValue={timeFormat} /> :{' '}
													<Timer.Seconds formatValue={timeFormat} />
												</span>
											</div>
										);
									}}
								</Timer>
							}
						</span>
					</div>
				</>
			)}
		</div>
	);
};

export default TimeTracker;
