import React from 'react';

// Components
import NavigationMenu from './NavigationMenu';


function Sidebar({children , title}) {
    return (
        <div className='SideBar'>
            <NavigationMenu
                title={title}
            />
            {children}
        </div>
    );
}

export default Sidebar;
