
function ProfileHeadingBar({ heading, navBack, children }) {
    return (
        <div>
            {navBack}
            <div className='workflow-bar'>
                <h1 className='HeadlineTwoBold dark-text '>{heading}</h1>
                <div className='d-flex flex-row' style={{ gap: '20px' }}>
                    {children}
                </div>
            </div>
        </div>

    );
}

export default ProfileHeadingBar;
