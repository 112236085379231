import React from 'react';
import { ReactComponent as Filter } from '../../assets/icons/Integrations/Frame 1497.svg';
import { ReactComponent as Mail } from '../../assets/icons/Integrations/Frame 1495.svg';
import { ReactComponent as Drive } from '../../assets/icons/Integrations/Frame 1496.svg';
import { ReactComponent as Icon1 } from '../../assets/icons/Integrations/Frame 1493.svg';
import { ReactComponent as Icon2 } from '../../assets/icons/Integrations/Frame 1494.svg';
import ZapierZapHeadSection from './ZapierZapHeadSection';

function ZapSection() {
	const icons = [Filter, Mail, Drive, Icon1, Icon2];
	const zaps = [
		{
			title: 'Save new Gmail attachments to Google Drive',
			subtitle: 'Filter by Zapier + Gmail + Google Drive',
			platforms: icons.slice(0, 3),
		},
		{
			title: 'Create new Trello cards from new Google Calendar events',
			subtitle: 'Google Calendar + Trello',
			platforms: icons.slice(3),
		},
	];

	const Zap = ({ title, platforms, subtitle }) => {
		return (
			<>
				<div
					className='zap-Section border m-auto d-flex flex-row align-items-center justify-content-between border-0'
					style={{
						height: '75px',
						width: '100%',
						backgroundColor: 'white',
						borderRadius: '4px',
						padding: '16px',
					}}
				>
					<div
						className='d-flex flex-row justify-content-between'
						style={{ width: 'auto', height: '39px' }}
					>
						<div className='d-flex '>
							{platforms.map((Icon, index) => (
								<div
									key={index}
									style={{ width: '24px', height: '24px', marginRight: '13px' }}
								>
									<Icon key={index} />
								</div>
							))}
						</div>
						<div className='Zaptext d-flex flex-column justify-content-start ml-3'>
							<h6 className='BodyTwoBold w-auto'>{title}</h6>
							<span className='BodyTwoLight d-flex'>{subtitle}</span>
						</div>
					</div>
					<button className='zap-button BodyOneBold'>Use this Zap</button>
				</div>
			</>
		);
	};

	return (
		<>
			<ZapierZapHeadSection name={'Zapier'} />
			<div className='Zap-Head-Section d-flex flex-column justify-content-evenly'>
				{/* {zaps?.map((zap, index) => (
					<Zap
						key={index}
						title={zap.title}
						platforms={zap.platforms}
						subtitle={zap.subtitle}
					/>
				))} */}
				<div className='zap-templates'>
					<zapier-zap-templates
						client-id='43cKyGg1HKYJxvRLFndThHKtQh3kCwUWCrdd6vSG'
						theme='light'
						ids='1641259,1718277,1648953'
						limit={5}
						use-this-zap='show'
					></zapier-zap-templates>
				</div>
			</div>
		</>
	);
}

export default ZapSection;
