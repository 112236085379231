/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import Autocomplete from 'react-google-autocomplete';
import { useSelector } from 'react-redux';

export const returnAddress = (place) => {
	let city;
	let state;
	let suite;
	let postcode;
	let address1;
	let address2;
	const fullAddress = place.address_components
		?.filter(
			(place) =>
				!(
					place.types?.includes('administrative_area_level_1') ||
					place.types?.includes('postal_code') ||
					place.types?.includes('sublocality_level_1') ||
					place.types?.includes('locality') ||
					place.types?.includes('country')
				),
		)
		?.map((place) => place.long_name)
		?.join(' ');
	console.info('aaaa', place?.address_components);
	// const suite = place.address_components?.find((add) =>
	// 	add.types?.includes('subpremise')
	// )?.long_name;
	// console.info('bbb', suite);
	for (const component of place.address_components) {
		//
		const componentType = component.types[0];
		switch (componentType) {
			case 'street_number': {
				address1 = `${component.long_name} `;
				break;
			}

			case 'route': {
				address2 = component.short_name;
				break;
			}
			case 'subpremise': {
				suite = component.long_name;
				break;
			}
			case 'postal_code': {
				postcode = `${component.long_name}`;
				break;
			}

			// case 'postal_code_suffix': {
			// 	postcode = `${component.long_name}`;
			// 	break;
			// }

			case 'locality':
				city = component.long_name;
				break;

			case 'sublocality_level_1':
				city = component.long_name;
				break;

			case 'administrative_area_level_1': {
				state = component.long_name;
				break;
			}
		}
	}
	const address = {
		zipCode: postcode,
		city: city,
		state: state,
		suite: suite || '',
		fullAddress:
			address1 === undefined || address2 === undefined
				? fullAddress
				: `${address1} ${address2}`,
	};
	return address;
};
export default function EditableLocationText({
	label,
	name,
	value,
	Class,
	onChange,
	Edit,
	style,
	type,
	pattern,
	title,
	required,
	placeholder,
	placeSelected,
	onBlur,
    countryAbbr
}) {
	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			event.preventDefault();
		}
	};
	return (
		<div className='ProfilePage d-flex flex-column w-100'>
			<label className='BodyTwoLight text-left m-0'>{label}</label>
			{Edit ? (
				<Autocomplete
					apiKey={window._env_?.REACT_APP_GOOGLE_MAPS_KEY}
					options={{
						types: ['address'],
						componentRestrictions: {
							country: [countryAbbr],
						},
					}}
					style={{
						width: '100%',
						// border: 'none',
						// borderBottom: '1px solid #333',
						padding: '6px',
					}}
					value={value}
					name={name}
					onChange={onChange}
					onBlur={onBlur}
					onKeyDown={handleKeyDown}
					placeholder={placeholder}
					onPlaceSelected={(place, inputRef, autocomplete) => {
						placeSelected(returnAddress(place));
					}}
				/>
			) : (
				<h3 className='BodyTwoLight m-0 text-left' name={name} style={style}>
					{value}
				</h3>
			)}
		</div>
	);
}
