/* eslint-disable no-mixed-spaces-and-tabs */
import { memo } from 'react';

function PickList({
	options,
	value,
	SelectRole,
	Name,
	require,
	selectDisabled,
	placeholder,
	requiredTitle,
	cypressId,
	selectClassName,
	customAction,
	customText,
}) {
	console.log(options, value, 'opval1232');
	return (
		<select
			className={`PickList d-flex flex-column w-100 ${selectClassName}`}
			name={Name}
			onChange={SelectRole}
			required={require && require}
			disabled={selectDisabled}
			onInvalid={
				require && !value
					? (e) => {
							e.target.setCustomValidity(
								requiredTitle || 'Please select an item in the list',
							);
					  }
					: (e) => e.target.setCustomValidity('')
			}
			onInput={(e) => e.target.setCustomValidity('')}
			data-cy={cypressId}
		>
			<option selected value=''>
				{placeholder || ''}
			</option>
			{customAction && (
				<option
					selected={value == 'callback' && true}
					value='callback'
					style={{ color: '#FB7A03' }}
				>
					&#65291; {customText}
				</option>
			)}
			{options.map((opt, i) => {
				return (
					<option
						selected={value == opt.id && true}
						key={i}
						value={parseInt(opt.id)}
						className={opt.is_active === false && 'not-active'}
					>
						{Name == 'role_id' && opt.role_name}
						{Name == 'team_id' ||
						Name == 'state' ||
						Name == 'industry' ||
						Name == 'business_type'
							? opt.name
							: opt.name}
					</option>
				);
			})}
		</select>
	);
}

export default memo(PickList);
