import React from 'react';

export default function SimpleText({
	label,
	name,
	style,
	type,
	value,
	onChange,
	id
}) {
	return (
		<div className='ProfilePage d-flex flex-column w-100'>
			<label className='BodyTwoLight text-left m-0'>{label}</label>
			<h3
				id={id}
				className='BodyTwoLight m-0 text-left'
				name={name}
				style={style}
				type={type}
				onChange={onChange}
			>
				{value}
			</h3>
		</div>
	);
}
