import React, { memo } from 'react';
import { ReactComponent as EditColor } from '../../assets/icons/PencilEdit.svg';
import { ReactComponent as ArrowsOut } from '../../assets/icons/ArrowsOutSimpleColor.svg';
import ReactHtmlParser from 'react-html-parser';
import { CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';

function NewTaskDetailsModalNotes({ title, content, editExpand, setExpandView, name }) {
	const loading = useSelector((state) => state.workSpaceReducer.resourceLoading);
	return (
		<div
			className='w-100 d-flex flex-column'
			style={{
				boxSizing: 'border-box',
				backgroundColor: '#FCFDFD',
				borderRadius: '4px',
				width: '100%',
				padding: '12px',
				gap: '8px',
				flex: 1,
			}}
		>
			<div className='d-flex justify-content-between'>
				<span className='HeadlineThreeBook'>{title}</span>
				<div className='d-flex' style={{ gap: 5 }}>
					{editExpand && (
						<span
							className='pointer'
							onClick={() =>
								setExpandView({
									isExpanded: true,
									content: content,
									title: title,
									name: name,
									isEdit: true,
								})
							}
						>
							<EditColor />
						</span>
					)}
					<span
						className='pointer'
						onClick={() => {
							setExpandView({
								isExpanded: true,
								content: content,
								title: title,
								name: name,
								isEdit: false,
							});
						}}
					>
						<ArrowsOut />
					</span>
				</div>
			</div>
			{loading ? (
				<div className='h-100 d-flex justify-content-center align-items-center'>
					<CircularProgress
						size={25}
						sx={{
							color: '#fb7a03',
						}}
						disableShrink
					/>
				</div>
			) : (
				<div
					className='d-flex task-details-notes w-100 h-100'
					style={{
						// backgroundColor: `${
						// 	content < 1 || content == '<p><br></p>' || content == null ? '' : '#FCFDFD'
						// }`,
						overflow: 'auto',
						minHeight: '80%',
					}}
				>
					<span className='BodyTwoLight'>{ReactHtmlParser(content)}</span>
				</div>
			)}
		</div>
	);
}

export default memo(NewTaskDetailsModalNotes);
