import React from 'react';
import { ReactComponent as Levvy } from '../../assets/icons/LevvyWordMark.svg';
import SignUpForm from '../../components/signup/SignupForm';

import { ReactComponent as LevvyLogo } from '../../assets/icons/Shape.svg';

// import StepBar from '../../components/signup/Stepbar';

export default function Signup() {
	return (
		<div style={{ overflowX: 'hidden' }}>
			<div
				className='sign-up-screen'
				style={{ display: 'flex', justifyContent: 'center' }}
			>
				<div className='' style={{ display: 'grid' }}>
					<div>
						<div className='dfCenter '>
							<LevvyLogo style={{ marginTop: '80px', marginBottom: '0px' }} />
						</div>
						{/* <div className='dfCenter'>
							<StepBar status={1} />
						</div> */}
					</div>
					<div className=''>
						<div className='dfCenter'>
							<SignUpForm />
						</div>
					</div>

					<div className='dfCenter'>
						<div className='' style={{ marginTop: 'auto' }}>
							<p
								className='dfCenter'
								style={{ marginBottom: '2px', marginTop: '72px' }}
							>
								Powered By
							</p>
							<p className='' style={{ marginBottom: '15px' }}>
								<Levvy />
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
