import React from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as InfoIcon } from '../../assets/icons/aboutInfo.svg';

const AboutSections = ({ workflow, companyLibrary }) => {
	const profileData = useSelector((state) => state.profileReducer.profileData);
	return (
		<div>
			<div className='icon-header' style={{ margin: '18px 0' }}>
				<div style={{ marginRight: '12px' }}>{<InfoIcon />}</div>
				<span className='HeadlineThreeBold mt-1'>About Workflow</span>
			</div>

			<div className='task-section h-auto'>
				<div className='d-flex flex-column' style={{ gap: '10px' }}>
					{workflow?.description ? (
						<>
							<span className='BodyTwoBold text-left'>
								Workflow Description:
							</span>
							<span className='BodyTwoLight'>
								{workflow?.description?.length > 120
									? workflow?.description?.substring(0, 120) + '...'
									: workflow?.description}
							</span>
						</>
					) : (
						<span className='BodyTwoLight w-100' style={{ color: '#696F6C' }}>
							No description available
						</span>
					)}
					<span className='BodyTwoBold'>
						Created by:
						<span className='BodyTwoLight pl-1 pr-1'>
							{companyLibrary ? profileData?.name : 'Levvy'}
						</span>
					</span>
				</div>
			</div>
		</div>
	);
};

export default AboutSections;
