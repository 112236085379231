import moment from 'moment';
import { secure_instance } from '../../axios/axios-config';
import { buildQueryString } from '../../utilities/utilities';

export const GET_INVOICES = '[Invoices] GET_INVOICES';
export const CREATE_INVOICE = '[Invoices] CREATE_INVOICES';
export const UPDATE_INVOICE = '[Invoices] UPDATE_INVOICE';
export const GET_SPECIFIC_INVOICE = '[Invoices] GET_SPECIFIC_INVOICE';
export const SUBMIT_INVOICE = '[Invoices] SUBMIT_INVOICE';
export const HANDLE_STATUS_CHECKBOX = '[Invoices] HANDLE_STATUS_CHECKBOX';
export const HANDLE_CLEAR_STATUS_CHECKBOX = '[Invoices] HANDLE_CLEAR_STATUS_CHECKBOX';
export const HANDLE_TYPE_CHECKBOX = '[Invoices] HANDLE_TYPE_CHECKBOX';
export const HANDLE_CLEAR_TYPE_CHECKBOX = '[Invoices] HANDLE_CLEAR_TYPE_CHECKBOX';
export const HANDLE_INVOICE_DATE_FILTER = '[Invoices] HANDLE_INVOICE_DATE_FILTER';
export const HANDLE_CLEAR_DATE = '[Invoices] HANDLE_CLEAR_DATE';

export const getInvoices = (filters) => {
	return async (dispatch) => {
		try {
			if (filters?.start_date && filters?.end_date) {
				filters.start_date = moment(filters?.start_date).format('MM-DD-YYYY');
                filters.end_date = moment(filters?.end_date).format('MM-DD-YYYY');
			}
			const queryString = buildQueryString(filters);

			const request = await secure_instance.request({
				url: `/v1/invoices/?${queryString}`,
				method: 'Get',
			});

			dispatch({
				type: GET_INVOICES,
				payload: request.data,
			});
		} catch (error) {
			//
		}
	};
};

export const createInvoice = (data) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: '/v1/invoices/generate',
				method: 'POST',
				data: data,
			});

			dispatch({
				type: CREATE_INVOICE,
				payload: request.data,
			});

			return request.data;
		} catch (e) {
			throw new Error(e?.response?.data?.message || e?.message || 'Something went Wrong');
		}
	};
};

export const updateInvoice = (id, data) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/invoices/${id}`,
				method: 'PATCH',
				data: data,
			});

			dispatch({
				type: UPDATE_INVOICE,
				payload: request.data,
			});
			return request.data;
		} catch (e) {
			throw new Error(e?.response?.data?.message || e?.message || 'Something went Wrong');
		}
	};
};

export const getSpecificInvoice = (id) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/invoices/${id}`,
				method: 'GET',
			});

			dispatch({
				type: GET_SPECIFIC_INVOICE,
				payload: request.data,
			});

			return request.data;
		} catch (e) {
			throw new Error(e?.response?.data?.message || e?.message || 'Something went Wrong');
		}
	};
};

export const deleteInvoice = (id) => {
	return async () => {
		try {
			const request = await secure_instance.request({
				url: `/v1/invoices/${id}`,
				method: 'Delete',
			});

			return request.data;
		} catch (e) {
			throw new Error(e?.response?.data?.message || e?.message || 'Something went Wrong');
		}
	};
};

export const submitInvoice = (id, data) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/invoices/submit/${id}`,
				method: 'PATCH',
				data: data,
			});

			dispatch({
				type: SUBMIT_INVOICE,
				payload: request.data,
			});
			return request.data;
		} catch (e) {
			throw new Error(e?.response?.data?.message || e?.message || 'Something went Wrong');
		}
	};
};

export const handleStatusCheckbox = (data) => {
	return {
		type: HANDLE_STATUS_CHECKBOX,
		payload: data,
	};
};

export const handleInvoiceDateFilter = (date) => {
	return {
		type: HANDLE_INVOICE_DATE_FILTER,
		payload: date,
	};
};

export const handleClearStatusCheckbox = () => {
	return {
		type: HANDLE_CLEAR_STATUS_CHECKBOX,
	};
};

export const handleTypeCheckbox = (data) => {
	return {
		type: HANDLE_TYPE_CHECKBOX,
		payload: data,
	};
};

export const handleClearTypeCheckbox = () => {
	return {
		type: HANDLE_CLEAR_TYPE_CHECKBOX,
	};
};

export const handleClearDate = () => {
	return {
		type: HANDLE_CLEAR_DATE,
	};
};
