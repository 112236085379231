import { useState, useEffect } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { stripePublicKey } from '../../../utils';
import { loadStripe } from '@stripe/stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import * as profileActions from '../../../redux/actions/company-profile';
import { Modal } from '@mui/material';
import useModal from '../../Modal/useModal';
import DummyModal from '../../Modal/DummyModal';
import AddCardModal from './AddCardModal';
// import { ReactComponent as EditIcon } from '../../../assets/icons/PencilEdit.svg';

const CompanyPaymentMethod = () => {
	const dispatch = useDispatch();
	const stripePromise = loadStripe(stripePublicKey);
	const [cardEdit, setCardEdit] = useState(false);
	// const [LoadingItems, setLoadingItems] = useState([]);
	const { modalIsOpen, toggleModal } = useModal();

	const cards = useSelector((state) => state.profileReducer.cards);

	useEffect(() => {
		dispatch(profileActions.getCards());
	}, [dispatch]);

	return (
		<>
			<div className='stats-container'>
				<div className='header-bar'>
					<span className='text-left w-75 d-flex align-items-center'>
						<h3 className='HeadlineThreeBold'>Payment Method</h3>
					</span>

					<span
						onClick={() => {
							toggleModal();
							setCardEdit(false);
						}}
						className='BodyTwoLight primary-text pointer'
					>
						Add New Card
					</span>
					{/* {cardEdit ? (
						<>
							<ButtonSquare
								clickHandler={() => {
									setCardEdit(!cardEdit);
								}}
								className='pr-3 pl-3 BodyTwoLight'
								style={{ whiteSpace: 'nowrap', textAlign: 'center', color:'#FB7A03' , backgroundColor:'white', border:'none'}}
							>
								Done{' '}
							</ButtonSquare>
						</>
					) : (
						<ButtonSquare
							clickHandler={() => {
								setCardEdit(!cardEdit);
							}}
							className='pr-3 pl-3 BodyTwoLight'
							style={{ whiteSpace: 'nowrap', textAlign: 'center', color:'#FB7A03' , backgroundColor:'white', border:'none'}}
						>
							Add New Card
						</ButtonSquare>
					)} */}
				</div>

				{cards?.length > 0 &&
					cards
						?.sort((a, b) => {
							if (a.customer.invoice_settings.default_payment_method === a.id) {
								return -1;
							} else if (
								b.customer.invoice_settings.default_payment_method === b.id
							) {
								return 1;
							} else {
								return 0;
							}
						})
						?.map((card, i) => {
							return (
								<div
									key={i}
									className='graph-container d-flex flex-column'
									style={{ padding: '18px', gap: '1em' }}
								>
									{card?.customer?.invoice_settings?.default_payment_method ===
										card?.id && (
										<div className='d-flex'>
											<span className='BodyOneBold'>Primary Card</span>
										</div>
									)}
									<div
										className='d-flex justify-content-between'
										style={{ gap: '5em' }}
									>
										<div
											className='d-flex flex-column'
											style={{ gap: '0.7em' }}
										>
											<div className='d-flex align-items-start'>
												<span
													className='BodyTwoLight'
													style={{ color: '#696F6C' }}
												>
													CardHolder name
												</span>
											</div>
											<div className='d-flex align-items-start'>
												<span className='BodyTwoLight'>
													{card?.billing_details?.name}
												</span>
											</div>
										</div>

										<div
											className='d-flex flex-column'
											style={{ gap: '0.7em' }}
										>
											<div className='d-flex align-items-start'>
												<span
													className='BodyTwoLight'
													style={{ color: '#696F6C' }}
												>
													Card Number
												</span>
											</div>
											<div className='d-flex align-items-start'>
												<span className='BodyTwoLight'>
													{'**** **** **** ' + card?.card.last4}
												</span>
											</div>
										</div>
										<div
											className='d-flex flex-column'
											style={{ gap: '0.7em' }}
										>
											<div className='d-flex align-items-start'>
												<span
													className='BodyTwoLight'
													style={{ color: '#696F6C' }}
												>
													Expiry (MM/YY)
												</span>
											</div>
											<div className='d-flex align-items-start'>
												<span className='BodyTwoLight'>
													{card?.card.exp_month +
														'/' +
														card?.card.exp_year}
												</span>
											</div>
										</div>
										<div
											className='d-flex flex-column'
											style={{ gap: '0.7em' }}
										>
											<div className='d-flex align-items-start'>
												<span
													className='BodyTwoLight'
													style={{ color: '#696F6C' }}
												>
													CVV
												</span>
											</div>
											<div className='d-flex align-items-start'>
												<span className='BodyTwoLight'>{'***'}</span>
											</div>
										</div>
										<div></div>
										{/* <div
										onClick={() => {
											setSelectedCard(card);
											toggleModal();
											setCardEdit(true);
										}}
										className='pointer'
									>
										<EditIcon />
									</div> */}
									</div>

									{/* {cardEdit && (
								<div
									className='d-flex flex-column'
									style={{
										gap: '0.8rem',
										marginLeft: 'auto',
										color: '',
									}}
								>
									{LoadingItems.includes(card?.id) ? (
										<IconButton id='basic-button'>
											<CircularProgress
												size={20}
												sx={{ color: '#fb7a03' }}
												disableShrink
											/>
										</IconButton>
									) : (
										<h4
											className='BodyTwoBold w-100 text-left primary-text pointer'
											onClick={() => {
												const array = [card?.id];
												setLoadingItems(array);
												if (
													card.customer.invoice_settings
														.default_payment_method === card.id
												) {
													setLoadingItems([]);
													alert("You can't delete default card");
												} else {
													dispatch(
														profileActions.detachCard(
															card?.id,
															setLoadingItems,
														),
													);
												}
											}}
										>
											Remove
										</h4>
									)}
								</div>
							)} */}
								</div>
							);
						})}

				{/* {cardEdit && (
					<div className='w-100 d-flex justify-content-center'>
						<div
							className='pointer'
							style={{
								height: '22px',
								width: '22px',
								borderRadius: '3px',
								margin: 0,
								padding: 0,
								position: 'relative',
								backgroundColor: '#fb7a03',
							}}
							onClick={() => {
								dispatch(profileActions.setError(''));
								toggleModal();
							}}
						>
							<AddIcon
								fontSize='small'
								sx={{
									color: 'white',
									fontSize: 21,
									position: 'absolute',
									margin: 0,
									padding: 0,
									top: 0,
									left: 0,
								}}
							/>
						</div>
					</div>
				)} */}
			</div>

			{modalIsOpen ? (
				<Modal
					onClose={toggleModal}
					open={modalIsOpen}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<DummyModal title={'Add Card'} onClose={toggleModal}>
						<Elements stripe={stripePromise}>
							<AddCardModal handleClose={toggleModal} edit={cardEdit} />
						</Elements>
					</DummyModal>
				</Modal>
			) : (
				<></>
			)}
		</>
	);
};
export default CompanyPaymentMethod;
