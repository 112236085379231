import React from 'react';
import NewExpandableWorkflowBar from './NewExpandableWorkflowBar';

function WorkflowCategories({ category, activeWorkflows, isChecked, handleCheckBoxes }) {
	return (
		<div>
			<div className='workflow-categories-header' id={category.name}>
				<span className='HeadlineTwoBold'>
					{category?.name || 'No Category'}
				</span>
			</div>
			<div className='workflow-categories-body'>
				{category?.workflows?.length > 0 ? (
					category?.workflows						
					?.sort((a, b) => a.name.localeCompare(b.name))
					?.map((workflow, index) => {
						return (
							<NewExpandableWorkflowBar
								key={index}
								workflow={workflow}
								handleCheckBoxes={handleCheckBoxes}
								isChecked={isChecked}
							/>
						);
					})
				) : (
					<div>
						<span className='BodyOneBold'>No Workflows</span>
					</div>
				)}
				{activeWorkflows?.length > 0 &&
					activeWorkflows?.filter(active => active?.is_active)?.filter(
						(item) => item?.workflow?.category?.id === category?.id,
					).length > 0 && (
						<div className='d-flex' style={{ paddingTop: '16px' }}>
							<span className='HeadlineThreeBold'>Activated</span>
						</div>
					)}
				{activeWorkflows?.length > 0 &&
					activeWorkflows?.filter(active => active?.is_active)?.map((activeWorkflow, index) => {
						if (activeWorkflow?.workflow?.category?.id === category?.id) {
							return (
								<div key={index}>
									<NewExpandableWorkflowBar
										key={index}
										workflow={activeWorkflow?.workflow}
										workflowSubmission={activeWorkflow?.workflow_submission}
										index={index}
										activated
										clientWorkflowId={activeWorkflow?.id}
									/>
								</div>
							);
						}
					})}
			</div>
		</div>
	);
}

export default WorkflowCategories;
