import { useCallback, useState } from 'react';
import Sidebar from '../components/sidebar/SideBar';
import ZapierIntegrationTopBar from '../components/Zapier/ZapierIntegrationTopBar';
import ZapSection from '../components/Zapier/ZapSection';
import NewSidebarItem from '../components/sidebar/NewSidebarItem';
import ConnectedApps from '../components/AccountSettings/ConnectedApps';
import Codat from '../components/Invoices/Codat';
import AlertBar from '../components/sub_components/AlertBar';
import { useSelector } from 'react-redux';
import Restricted from '../components/Permissions/Restricted';
import { Member } from '../utilities/utilities';

function ZapierIntegration() {
	const [activeTab, setActiveTab] = useState('slackapp');
	const isFeatureFlag = useSelector((state) => state.authReducer.isFeatureFlag);
	const user = useSelector((state) => state.authReducer.user);

	const navigationItems = [
		{ name: 'Slack', sectionId: 'slackapp' },
		{ name: 'Zapier', sectionId: 'zapier' },
		(window._env_?.REACT_APP_ENV !== 'production' &&
			isFeatureFlag &&
			user?.userRole?.id === 16) && { name: 'Accounting', sectionId: 'Accounting' },
	].filter(Boolean);
	

	const tabChangeHandler = useCallback(
		(tab) => () => {
			setActiveTab(tab);
		},
		[],
	);

	return (
		<div className='AppBody' style={{ backgroundColor: '#EEF2F3' }}>
			<Sidebar title='Integrations'>
				<div className='new-sidebar-content-wrapper'>
					<div className='User-filters'>
						<div className='new-side-menu BodyTwoLight-bold m-0 d-flex flex-column align-items-start'>
							{navigationItems?.map((item, index) => {
								const prevOfActive =
									navigationItems[index + 1]?.sectionId == activeTab;
								return (
									<>
										{index == 0 && (
											<div
												style={{ padding: '8px' }}
												className={`w-100 main-sidebar-item ${
													navigationItems[0].sectionId == activeTab
														? 'prev'
														: ''
												}`}
											></div>
										)}
										<NewSidebarItem
											key={item.sectionId}
											uniqueId={item.sectionId}
											label={item.name}
											isActive={activeTab == item.sectionId}
											setActiveTab={tabChangeHandler}
										/>
										<div
											style={{ padding: '8px' }}
											className={`w-100 main-sidebar-item ${
												prevOfActive ? 'prev' : ''
											}`}
										></div>
									</>
								);
							})}
						</div>
					</div>
				</div>
			</Sidebar>

			<div
				style={{
					width: 'calc(100vw - 260px)',
					height: '100vh',
				}}
			>
				<AlertBar />
				<div className='zapier-main zapierSection-main time-reports-main d-flex flex-column'>
					<ZapierIntegrationTopBar Title={'Integrations'} />

					{activeTab === 'zapier' && <ZapSection />}
					{activeTab === 'Accounting' &&
						window._env_?.REACT_APP_ENV !== 'production' &&
						isFeatureFlag && (
							<Restricted AllowedUsers={[Member.ownerId]}>
								{' '}
								<Codat />{' '}
							</Restricted>
						)}
					{activeTab === 'slackapp' && <ConnectedApps />}
				</div>
			</div>
		</div>
	);
}

export default ZapierIntegration;
