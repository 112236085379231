import { useHistory, useLocation } from 'react-router';

function useBreadCrumb() {
    const location = useLocation();
    const history = useHistory();

    const breadCrumbsHistory = location.state || [];

    const handleCrumbClick = (crumb, index) => {
        let from = location.state || [];
        if (from.length > 0) {
            index === 1 ? from.pop() : (from = []);
        }
        history.push(crumb.link, from);
    };

    const BreadCrumbs = ({ children }) => {
        if (breadCrumbsHistory?.length > 0) {
            return breadCrumbsHistory.map((crumb, index) => (
                <h2
                    onClick={() => handleCrumbClick(crumb, index)}
                    key={index}
                    className='HeadlineThreeBook text-left'
                >
                    {index === 1 ? '/' : ''}
                    {crumb.label}
                </h2>
            ));
        } else {
            return children; // Render the provided children if no breadcrumbs
        }
    };

    return {
        handleCrumbClick,
        BreadCrumbs,
    };
}

export default useBreadCrumb;
