import * as profileActions from '../../redux/actions/company-profile';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	getCumulativeGraphData,
	getGraphData,
	getTooltipData,
} from '../../redux/actions/cube-actions';
import BillingBar from './BillingBar';
const BarGraph = React.lazy(() => import('./BarGraph'));
import {
	graphVariations,
	varRelations,
	workflowCumulativeQueries,
	workflowVsPercentQueries,
	workflowVsTimeTrackingQueries,
	profitabilityByPercentageQueries,
} from '../../utilities/cubeUtils';
import getSymbolFromCurrency from 'currency-symbol-map';

const CompanyStats = () => {
	////////////Reducer states\\\\\\\\\\\\\\\\\\\\
	const workflowPercentageGraph = useSelector(
		(state) => state.cubeReducer[graphVariations.WORKFLOW_VS_PERCENTAGE],
	);
	// console.log(workflowPercentageGraph,'test');
	const { filtersData: workflowPercentGraphOptions } = useSelector(
		(state) => state.cubeReducer[graphVariations.WORKFLOW_VS_PERCENTAGE]?.options,
	);

	const workflowTimeTrackingGraph = useSelector(
		(state) => state.cubeReducer[graphVariations.WORKFLOW_VS_TIME_TRACKING_GRAPH],
	);

	const { filtersData: workflowTimeTrackingGraphOptions } = useSelector(
		(state) => state.cubeReducer[graphVariations.WORKFLOW_VS_TIME_TRACKING_GRAPH]?.options,
	);

	const workflowCumulativeGraph = useSelector(
		(state) => state.cubeReducer[graphVariations.WORKFLOW_CUMULATIVE_GRAPH],
	);
	const { filtersData: workflowCumulativeGraphOptions } = useSelector(
		(state) => state.cubeReducer[graphVariations.WORKFLOW_CUMULATIVE_GRAPH]?.options,
	);

	const profitabilityByPercentageGraph = useSelector(
		(state) => state.cubeReducer[graphVariations.PROFITABILITY_BY_PERCENTAGE_GRAPH],
	);
	const { filtersData: profitabilityByPercentageGraphOptions } = useSelector(
		(state) => state.cubeReducer[graphVariations.PROFITABILITY_BY_PERCENTAGE_GRAPH]?.options,
	);
	const user = useSelector((state) => state.authReducer.user);
	const companyProfileData = useSelector((state) => state.profileReducer.profileData);

	const currencySymbol = companyProfileData?.currency
		? getSymbolFromCurrency(companyProfileData?.currency)
		: '';

	let axisBottom = '';
	if (workflowPercentageGraph?.filters.groupBy === 'group-by-client') {
		axisBottom = 'Clients';
	} else if (workflowPercentageGraph?.filters.groupBy === 'group-by-teams') {
		axisBottom = 'Teams';
	}

	let axisBottomBudgeted = '';
	if (workflowTimeTrackingGraph?.filters.groupBy === 'group-by-teams') {
		axisBottomBudgeted = 'Teams';
	} else if (workflowTimeTrackingGraph?.filters.groupBy === 'group-by-employees') {
		axisBottomBudgeted = 'Employees';
	}

	function createOptionsForFilters(array, findIn) {
		const result = array?.map((item) => {
			if (findIn?.indexOf(JSON.stringify(item?.value)) !== -1) {
				return { ...item, is_checked: true };
			}
			return { ...item, is_checked: false };
		});
		return result;
	}
	const workflowPercentGraphClientOptions = createOptionsForFilters(
		workflowPercentGraphOptions?.clients,
		workflowPercentageGraph?.filters.appliedFilters.clientid,
	);
	const workflowPercentGraphTeamsOptions = createOptionsForFilters(
		workflowPercentGraphOptions?.teams,
		workflowPercentageGraph?.filters.appliedFilters.teamID,
	);
	const workflowPercentGraphEmployeeOptions = createOptionsForFilters(
		workflowPercentGraphOptions?.employees,
		workflowPercentageGraph?.filters.appliedFilters.userID,
	);
	const workflowPercentGraphWorkflowOptions = createOptionsForFilters(
		workflowPercentGraphOptions?.workflows,
		workflowPercentageGraph?.filters.appliedFilters.workflowID,
	);

	const workflowTimeGraphClientOptions = createOptionsForFilters(
		workflowTimeTrackingGraphOptions?.clients,
		workflowTimeTrackingGraph?.filters.appliedFilters.clientid,
	);
	const workflowTimeGraphTeamsOptions = createOptionsForFilters(
		workflowTimeTrackingGraphOptions?.teams,
		workflowTimeTrackingGraph?.filters.appliedFilters.teamID,
	);
	const workflowTimeGraphEmployeeOptions = createOptionsForFilters(
		workflowTimeTrackingGraphOptions?.employees,
		workflowTimeTrackingGraph?.filters.appliedFilters.userID,
	);
	const workflowTimeGraphWorkflowOptions = createOptionsForFilters(
		workflowTimeTrackingGraphOptions?.workflows,
		workflowTimeTrackingGraph?.filters.appliedFilters.workflowID,
	);

	const workflowCumulativeGraphClientOptions = createOptionsForFilters(
		workflowCumulativeGraphOptions?.clients,
		workflowCumulativeGraph?.filters.appliedFilters.clientid,
	);
	const workflowCumulativeGraphTeamsOptions = createOptionsForFilters(
		workflowCumulativeGraphOptions?.teams,
		workflowCumulativeGraph?.filters.appliedFilters.teamID,
	);
	const workflowCumulativeGraphEmployeeOptions = createOptionsForFilters(
		workflowCumulativeGraphOptions?.employees,
		workflowCumulativeGraph?.filters.appliedFilters.userID,
	);
	const workflowCumulativeGraphWorkflowOptions = createOptionsForFilters(
		workflowCumulativeGraphOptions?.workflows,
		workflowCumulativeGraph?.filters.appliedFilters.workflowID,
	);

	const profitabilityByPercentageGraphClientOptions = createOptionsForFilters(
		profitabilityByPercentageGraphOptions?.clients,
		profitabilityByPercentageGraph?.filters.appliedFilters.clientid,
	);
	const profitabilityByPercentageGraphTeamsOptions = createOptionsForFilters(
		profitabilityByPercentageGraphOptions?.teams,
		profitabilityByPercentageGraph?.filters.appliedFilters.teamID,
	);
	const profitabilityByPercentageGraphEmployeeOptions = createOptionsForFilters(
		profitabilityByPercentageGraphOptions?.employees,
		profitabilityByPercentageGraph?.filters.appliedFilters.userID,
	);
	const profitabilityByPercentageGraphWorkflowOptions = createOptionsForFilters(
		profitabilityByPercentageGraphOptions?.workflows,
		profitabilityByPercentageGraph?.filters.appliedFilters.workflowID,
	);

	////////////Declarations\\\\\\\\\\\\\\\\\\
	const dispatch = useDispatch();

	///////////////Functions\\\\\\\\\\\\\\\\\\\\\

	//////////////Hooks\\\\\\\\\\\\\\\\\\\\\

	useMemo(() => {
		dispatch(
			getGraphData({
				graphVariation: graphVariations.WORKFLOW_VS_PERCENTAGE,
				mainVariable: varRelations[graphVariations.WORKFLOW_VS_PERCENTAGE],
				query: workflowVsPercentQueries.graphQueries[
					workflowPercentageGraph?.filters.groupBy
				],
				graphType: workflowPercentageGraph?.filters.groupBy,
				filters: workflowPercentageGraph?.filters.appliedFilters,
				dateRange: workflowPercentageGraph?.filters.dateRange,
			}),
		);
		dispatch(
			getTooltipData({
				graphVariation: graphVariations.WORKFLOW_VS_PERCENTAGE,
				mainVariable: varRelations[graphVariations.WORKFLOW_VS_PERCENTAGE],
				query: workflowVsPercentQueries?.tooltipQueries[
					workflowPercentageGraph?.filters?.groupBy
				],
				graphType: workflowPercentageGraph?.filters.groupBy,
				filters: workflowPercentageGraph?.filters.appliedFilters,
				dateRange: workflowPercentageGraph?.filters.dateRange,
			}),
		);
	}, [
		workflowPercentageGraph?.filters?.appliedFilters,
		workflowPercentageGraph?.filters?.activeFilter,
		workflowPercentageGraph?.filters?.groupBy,
		workflowPercentageGraph?.filters?.dateRange,
	]);

	useMemo(() => {
		dispatch(
			getGraphData({
				graphVariation: graphVariations.WORKFLOW_VS_TIME_TRACKING_GRAPH,
				mainVariable: varRelations[graphVariations.WORKFLOW_VS_TIME_TRACKING_GRAPH],
				query: workflowVsTimeTrackingQueries.graphQueries[
					workflowTimeTrackingGraph?.filters.groupBy
				],
				graphType: workflowTimeTrackingGraph?.filters.groupBy,
				filters: workflowTimeTrackingGraph?.filters.appliedFilters,
				dateRange: workflowTimeTrackingGraph?.filters.dateRange,
			}),
		);
		dispatch(
			getTooltipData({
				graphVariation: graphVariations.WORKFLOW_VS_TIME_TRACKING_GRAPH,
				mainVariable: varRelations[graphVariations.WORKFLOW_VS_TIME_TRACKING_GRAPH],
				query: workflowVsTimeTrackingQueries.tooltipQueries[
					workflowTimeTrackingGraph?.filters.groupBy
				],
				graphType: workflowTimeTrackingGraph?.filters.groupBy,
				filters: workflowTimeTrackingGraph?.filters.appliedFilters,
				dateRange: workflowTimeTrackingGraph?.filters.dateRange,
			}),
		);
	}, [
		workflowTimeTrackingGraph?.filters?.appliedFilters,
		workflowTimeTrackingGraph?.filters?.groupBy,
		workflowTimeTrackingGraph?.filters?.dateRange,
	]);
	useMemo(() => {
		dispatch(
			getCumulativeGraphData({
				graphVariation: graphVariations.WORKFLOW_CUMULATIVE_GRAPH,
				mainVariable: varRelations[graphVariations.WORKFLOW_CUMULATIVE_GRAPH],
				query: workflowCumulativeQueries.graphQueries[
					workflowCumulativeGraph?.filters.groupBy
				],
				graphType: workflowCumulativeGraph?.filters.groupBy,
				filters: workflowCumulativeGraph?.filters.appliedFilters,
				dateRange: workflowCumulativeGraph?.filters.dateRange,
			}),
		);
		dispatch(
			getTooltipData({
				graphVariation: graphVariations.WORKFLOW_CUMULATIVE_GRAPH,
				mainVariable: varRelations[graphVariations.WORKFLOW_CUMULATIVE_GRAPH],
				query: workflowCumulativeQueries.tooltipQueries[
					workflowCumulativeGraph?.filters.groupBy
				],
				graphType: workflowCumulativeGraph?.filters.groupBy,
				filters: workflowCumulativeGraph?.filters.appliedFilters,
				dateRange: workflowCumulativeGraph?.filters.dateRange,
			}),
		);
	}, [
		workflowCumulativeGraph?.filters?.appliedFilters,
		workflowCumulativeGraph?.filters?.groupBy,
		workflowCumulativeGraph?.filters?.dateRange,
	]);

	useMemo(() => {
		dispatch(
			getCumulativeGraphData({
				graphVariation: graphVariations.PROFITABILITY_BY_PERCENTAGE_GRAPH,
				mainVariable: varRelations[graphVariations.PROFITABILITY_BY_PERCENTAGE_GRAPH],
				query: profitabilityByPercentageQueries.graphQueries[
					profitabilityByPercentageGraph?.filters.groupBy
				],
				graphType: profitabilityByPercentageGraph?.filters.groupBy,
				filters: profitabilityByPercentageGraph?.filters.appliedFilters,
				dateRange: profitabilityByPercentageGraph?.filters.dateRange,
			}),
		);
		dispatch(
			getTooltipData({
				graphVariation: graphVariations.PROFITABILITY_BY_PERCENTAGE_GRAPH,
				mainVariable: varRelations[graphVariations.PROFITABILITY_BY_PERCENTAGE_GRAPH],
				query: profitabilityByPercentageQueries.tooltipQueries[
					profitabilityByPercentageGraph?.filters.groupBy
				],
				graphType: profitabilityByPercentageGraph?.filters.groupBy,
				filters: profitabilityByPercentageGraph?.filters.appliedFilters,
				dateRange: profitabilityByPercentageGraph?.filters.dateRange,
			}),
		);
	}, [
		profitabilityByPercentageGraph?.filters?.appliedFilters,
		profitabilityByPercentageGraph?.filters?.groupBy,
		profitabilityByPercentageGraph?.filters?.dateRange,
	]);

	useEffect(() => {
		dispatch(profileActions.getTenantProfile(user?.tenantId));
	}, [dispatch]);

	return (
		<div className='background-shadow'>
			<BillingBar BarText='Insights' />
			<React.Suspense fallback={<div className='spinner-border'></div>}>
				<div className='stats-container'>
					<div className='header-bar'>
						<span className='HeadlineThreeBold'>Percent Complete by Workflow</span>
					</div>
					<div className='graph-container'>
						<BarGraph
							graph={workflowPercentageGraph}
							graphCategory={'Bar'}
							axisBottom={axisBottom}
							axisLeft={'Percentage of completion'}
							laggingByTitle={'Tasks'}
							graphVariation={graphVariations.WORKFLOW_VS_PERCENTAGE}
							clients={workflowPercentGraphClientOptions}
							teams={workflowPercentGraphTeamsOptions}
							employees={workflowPercentGraphEmployeeOptions}
							workflows={workflowPercentGraphWorkflowOptions}
							filtersQuery={workflowVsPercentQueries}
						/>
					</div>
					<div className='header-bar mt-5'>
						<span className='HeadlineThreeBold'>
							Comparison between Budgeted and Actual Hours Logged
						</span>
					</div>
					<div className='graph-container'>
						<BarGraph
							graph={workflowTimeTrackingGraph}
							graphCategory={'Bar'}
							axisBottom={axisBottomBudgeted}
							axisLeft={'Number of hours'}
							laggingByTitle={'Hours'}
							graphVariation={graphVariations.WORKFLOW_VS_TIME_TRACKING_GRAPH}
							clients={workflowTimeGraphClientOptions}
							teams={workflowTimeGraphTeamsOptions}
							employees={workflowTimeGraphEmployeeOptions}
							workflows={workflowTimeGraphWorkflowOptions}
							filtersQuery={workflowVsTimeTrackingQueries}
						/>
					</div>
					<div className='header-bar mt-5'>
						<span className='HeadlineThreeBold'>Profitability</span>
					</div>
					<div className='graph-container'>
						<BarGraph
							graph={workflowCumulativeGraph}
							graphCategory={'Bar'}
							axisBottom={'Clients'}
							axisLeft={`Revenue Generated ${currencySymbol}`}
							laggingByTitle={'Tasks'}
							graphVariation={graphVariations.WORKFLOW_CUMULATIVE_GRAPH}
							clients={workflowCumulativeGraphClientOptions}
							teams={workflowCumulativeGraphTeamsOptions}
							employees={workflowCumulativeGraphEmployeeOptions}
							workflows={workflowCumulativeGraphWorkflowOptions}
							filtersQuery={workflowCumulativeQueries}
							currencySymbol={currencySymbol}
						/>
					</div>
					<div className='header-bar mt-5'>
						<span className='HeadlineThreeBold'>Profitability By Percentage</span>
					</div>
					<div className='graph-container'>
						<BarGraph
							graph={profitabilityByPercentageGraph}
							graphCategory={'Bar'}
							axisBottom={'Clients'}
							axisLeft={'Revenue Generated %'}
							laggingByTitle={'Tasks'}
							graphVariation={graphVariations.PROFITABILITY_BY_PERCENTAGE_GRAPH}
							clients={profitabilityByPercentageGraphClientOptions}
							teams={profitabilityByPercentageGraphTeamsOptions}
							employees={profitabilityByPercentageGraphEmployeeOptions}
							workflows={profitabilityByPercentageGraphWorkflowOptions}
							filtersQuery={profitabilityByPercentageQueries}
						/>
					</div>
				</div>
			</React.Suspense>
		</div>
	);
};
export default CompanyStats;
