import ConfirmModal from './ConfirmModal';

const UserArchivedAlreadyModal = ({ onClose, onProceed }) => {
	return (
		<div className='ModalRoot fadeIn'>
			<div className='Content'>
				<div style={{ height: '5rem' }} className='Header px-3'>
					<h1 className='HeadlineTwoBold'>This user has been archived</h1>
				</div>
				<div className='Body'>
					<ConfirmModal
						title='Confirm'
						description={'The user associated with this email has been archived. Would you like to unarchive this user?'}
						OnCancel={onClose}
						Onsubmit={onProceed}
						descriptionClassName='text-left'
					/>
				</div>
			</div>
		</div>
	);
};

export default UserArchivedAlreadyModal;
