import { Tooltip } from '@mui/material';
import React, { memo } from 'react';

// const Link = styled.a`
//   color: ${lightGreen};

//   &:hover {
//     text-decoration: underline;
//   }
// `;

const TableRowItem = ({
    label,
    url,
    width,
    ClassName,
    bold,
    input,
    children,
    onClick,
    textStyle,
    isUrl,
    textClass,
    toolTipText,
}) => {
    if (input) {
        return (
            <div
                onClick={onClick}
                className={'TableRowItem ' + ClassName}
                style={{ width: `calc(${width})` }}
            >
                {children}
            </div>
        );
    } else {
        return (
            <div
                onClick={onClick}
                className={'TableRowItem ' + ClassName}
                style={{ width: `calc(${width})` }}
            >
                {bold ? (
                    <h3 className='BodyTwoBold' style={textStyle}>
                        {label}
                    </h3>
                ) : isUrl ? (
                    <a
                        href={label}
                        className='BodyTwoLight m-0 primary-text'
                        style={{}}
                        target='_blank'
                        rel='noreferrer'
                    >
                        {label}
                    </a>
                ) : toolTipText ? (
                    <Tooltip title={toolTipText}>
                        <h3
                            className={`BodyTwoLight dark-text m-0 ${textClass}`}
                            style={textStyle}
                        >
                            {label}
                        </h3>
                    </Tooltip>
                ) : (
                    <h3
                        className={`BodyTwoLight dark-text m-0 ${textClass}`}
                        style={textStyle}
                    >
                        {label}
                    </h3>
                )}
                {/* {url && rootUrl && rootUrl[1] && (
        <Link href={url} target="_blank">
          <h3 className='BodyTwoLight'>{rootUrl[1]}</h3>
        </Link>
      )} */}
            </div>
        );
    }
};

export default memo(TableRowItem);
