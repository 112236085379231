import axios from 'axios';
import { secure_instance } from '../../axios/axios-config';
import { setSnackBarIsOpen } from './dashboard/workspaces-actions';
export const GET_USERS_END = '[User Management] GET_USERS_END';
export const GET_USERS_FAILED = '[User Management] GET_USERS_FAILED';
export const GET_USERS_START = '[User Management] GET_USERS_START';
export const UPDATE_USER = '[User Management] UPDATE_USER';
export const ADD_USER = '[User Management] ADD_USER';
export const DELETE_USER_START = '[User Management] DELETE_USER_START';
export const DELETE_USER_END = '[User Management] DELETE_USER_END';
export const DELETE_USER_FAILED = '[User Management] DELETE_USER_FAILED';
export const GET_ROLES = '[User Management] GET_ROLES';
export const UPLOAD_USER_IMAGE = '[User Management] UPLOAD_USER_IMAGE';
export const CLEAR_IMAGE = '[User Management] CLEAR_IMAGE';
export const CLEAR_USERS = '[User Management] CLEAR_USERS';
export const GET_USERPROFILE_START = '[User Management] GET_USERPROFILE_START';
export const GET_USERPROFILE_END = '[User Management] GET_USERPROFILE_END';
export const GET_USERPROFILE_FAILED = '[User Management] GET_USERPROFILE_FAILED';
export const GET_USER_ROLES = '[User Management] GET_USER_ROLES';
export const USER_BILLING_LIST_START = '[User Management] USER_BILLING_LIST_START';
export const USER_BILLING_LIST_END = '[User Management] USER_BILLING_LIST_END';
export const USER_BILLING_LIST_FAILED = '[User Management] USER_BILLING_LIST_FAILED';
export const DELETE_MULTI_USERS = '[User Management] DELETE_MULTI_USERS';
export const FILTER_USER_PROFILE_TEAMS = '[User Management] FILTER_USER_PROFILE_TEAMS';
export const FILTER_TASK_STATUSES = '[User Management] FILTER_TASK_STATUSES';
export const RESET_USER_ADD_ERRORS = '[User Management] RESET_USER_ADD_ERRORS';
export const FILTER_USER_PROFILE_CLIENTS = '[User Management] FILTER_USER_PROFILE_CLIENTS';
export const GET_USER_PROFILE_MANAGEMENT = '[User Management] GET_USER_PROFILE_MANAGEMENT';
export const HANDLE_TEAM_CHECKBOXES = '[User Management] HANDLE_TEAM_CHECKBOXES';
export const HANDLE_LOCATION_CHECKBOXES = '[User Management] HANDLE_LOCATION_CHECKBOXES';
export const HANDLE_WORKMODE_CHECKBOXES = '[User Management] HANDLE_WORKMODE_CHECKBOXES';
export const HANDLE_ROLE_CHECKBOXES = '[User Management] HANDLE_ROLE_CHECKBOXES';
export const CLEAR_JOB_TITLES = '[User Management] CLEAR_JOB_TITLES';
export const CLEAR_LOCATION = '[User Management] CLEAR_LOCATION';
export const CLEAR_TEAM = '[User Management] CLEAR_TEAM';
export const CLEAR_WORK_MODE = '[User Management] CLEAR_WORK_MODE';
export const ADD_USER_FAILED = '[User Management] ADD_USER_FAILED';
export const SET_USER_TEAMS = '[User Management] SET_USER_TEAMS';
export const GET_FILTERED_USER_ROLES = '[User Management] GET_FILTERED_USER_ROLES';
export const PROFILE_CHANGE_HANDLER = '[User Management] PROFILE_CHANGE_HANDLER';
export const PROFILE_INFO_EDIT = '[User Management] PROFILE_INFO_EDIT';
export const HANDLE_DATE_CHANGE = '[User Management] HANDLE_DATE_CHANGE';
export const SET_PROFILE_LOADING = '[User Management] SET_PROFILE_LOADING';
export const UPLOAD_USER_IMAGE_START = '[User Management] UPLOAD_USER_IMAGE_START';
export const UPLOAD_USER_IMAGE_FAILED = '[User Management] UPLOAD_USER_IMAGE_FAILED';
export const GET_AFFECTED_CLIENTS = '[User Management] GET_AFFECTED_CLIENTS';
export const SET_USERS_BILLING_DETAILS = '[User Management] SET_USERS_BILLING_DETAILS';
export const SET_SPECIFIC_USERS_BILLING_DETAILS =
	'[User Management] SET_SPECIFIC_USERS_BILLING_DETAILS';
export const ADD_USER_BILLING = '[User Management] ADD_USER_BILLING';
export const GET_ALL_TENANT_USERS = '[User Management] GET_ALL_TENANT_USERS';
export const GET_ALL_ARCHIVED_USERS = '[User Management] GET_ALL_ARCHIVED_USERS';
export const UPDATE_USER_PROFILE = '[User Management] UPDATE_USER_PROFILE';
export const RESET_USER_PROFILE = '[User Management] RESET_USER_PROFILE';
export const UPDATE_USER_ROLES = '[User Management] UPDATE_USER_ROLES';
export const UNARCHIVE_USER = '[User Management] UNARCHIVE_USER';
const CancelToken = axios.CancelToken;

export const getUsers = (filters, id) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: GET_USERS_START,
			});

			const userRolesQuery = filters?.userRoles
				.map((arr, index) => {
					if (index === filters.userRoles.length - 1) {
						return 'user_roles[]=' + arr;
					} else {
						return 'user_roles[]=' + arr + '&';
					}
				})
				.join('');

			const workModeQuery = filters?.workMode
				.map((arr, index) => {
					if (index === filters.workMode.length - 1) {
						return 'work_mode[]=' + arr;
					} else {
						return 'work_mode[]=' + arr + '&';
					}
				})
				.join('');

			const locationQuery = filters?.location
				.map((arr, index) => {
					if (index === filters.location.length - 1) {
						return 'location[]=' + arr;
					} else {
						return 'location[]=' + arr + '&';
					}
				})
				.join('');

			const teamQuery = filters?.team
				.map((arr, index) => {
					if (index === filters.team.length - 1) {
						return 'team[]=' + arr;
					} else {
						return 'team[]=' + arr + '&';
					}
				})
				.join('');

			const request = await secure_instance.request({
				url: `/v1/user_profiles/?expand=user_teams(notDeleted).team${
					userRolesQuery ? '&' + userRolesQuery : ''
				}${workModeQuery ? '&' + workModeQuery : ''}${
					locationQuery ? '&' + locationQuery : ''
				}${teamQuery ? '&' + teamQuery : ''}&get_team_members=${true}`,
				method: 'Get',
			});

			const response = await secure_instance.request({
				url: '/v1/user_roles/?expand=role_scopes',
				method: 'Get',
			});

			dispatch({
				type: GET_USERS_END,
				payload: {
					users: request.data,
					user_roles: response.data,
					team_id: id,
				},
			});
		} catch (e) {
			dispatch({
				type: GET_USERS_FAILED,
			});
		}
	};
};

export const getAllArchivedUsers = () => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/user_profiles/`,
				method: 'Get',
				params: { get_deleted_users: true },
			});

			console.log('archived data', request);

			dispatch({
				type: GET_ALL_ARCHIVED_USERS,
				payload: request.data,
			});
		} catch (error) {
			dispatch({
				type: GET_ALL_ARCHIVED_USERS,
			});
		}
	};
};

export const getAllTenantUsers = () => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/user_profiles/all/users`,
				method: 'Get',
			});

			console.log(request, ' tenant users');

			dispatch({
				type: GET_ALL_TENANT_USERS,
				payload: request.data,
			});

			return request.data;
		} catch (e) {
			throw new Error(e?.response?.data?.message || e?.message || 'Something went Wrong');
			// dispatch({
			// 	type: GET_USERS_FAILED,
			// });
		}
	};
};

export const clearUsers = () => {
	return (dispatch) => {
		dispatch({
			type: CLEAR_USERS,
		});
	};
};

export const updateUser = (data, toggleModal, setLoad) => {
	return async (dispatch) => {
		try {
			dispatch(resetUserAddErrors());

			const request = await secure_instance.request({
				url: `v1/users/${data.user_id}`,
				method: 'Patch',
				data,
			});

			dispatch({
				type: UPDATE_USER,
				payload: request.data,
			});

			setTimeout(() => {
				setLoad(false);
				toggleModal();
			}, 2000);

			dispatch(getUsers());
		} catch (e) {
			setTimeout(() => {
				setLoad(false);
			}, 2000);

			dispatch({
				type: ADD_USER_FAILED,
				payload: e?.response?.data?.message,
			});
		}
	};
};

export const addUser = (
	data,
	toggleModal,
	setLoad,
	setExistingArchiveUserModalOpen,
	setUserId,
	ModalType,
) => {
	return async (dispatch) => {
		try {
			dispatch(resetUserAddErrors());

			await secure_instance.request({
				url: `v1/users/`,
				method: 'Post',
				data,
			});

			setTimeout(() => {
				setLoad(false);
				toggleModal();
				dispatch(getUsers());
			}, 2000);
		} catch (e) {
			setLoad(false);

			if (e?.response?.data?.error_code == 'USER_ARCHIVED') {
				setExistingArchiveUserModalOpen(true);
				setUserId(e?.response?.data?.user_id);
				ModalType('ARCHIVED_USER');
			}

			dispatch({
				type: ADD_USER_FAILED,
				payload: e?.response?.data,
			});
		}
	};
};

export const deleteUser = (id, callBack) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: DELETE_USER_START,
			});

			await secure_instance.request({
				url: `v1/users/${id}`,
				method: 'Delete',
			});

			dispatch({
				type: DELETE_USER_END,
			});

			dispatch(getUsers());

			callBack && callBack.success();
		} catch (e) {
			callBack && callBack.fail();

			dispatch({
				type: DELETE_USER_FAILED,
			});
		}
	};
};

export const deleteMultiUsers = (ids) => {
	return async (dispatch) => {
		try {
			await secure_instance.request({
				url: 'v1/users/',
				method: 'Delete',
				data: {
					ids,
				},
			});

			dispatch({
				type: DELETE_MULTI_USERS,
				payload: ids,
			});
		} catch (e) {
			//
		}
	};
};

export const getRoles = () => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: 'v1/roles/',
				method: 'Get',
			});

			dispatch({
				type: GET_ROLES,
				payload: request.data,
			});
		} catch (e) {
			//
		}
	};
};

export const getFilteredUserProfileManagement = () => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: 'v1/user_profiles/management_filter',
				method: 'Get',
			});

			dispatch({
				type: GET_USER_PROFILE_MANAGEMENT,
				payload: request.data,
			});
		} catch (e) {
			//
		}
	};
};

export const getFilteredUserRoles = () => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: 'v1/users/filter/management',
				method: 'Get',
			});

			dispatch({
				type: GET_FILTERED_USER_ROLES,
				payload: request.data.roles,
			});
		} catch (e) {
			//
		}
	};
};

export const getAffectedClients = (id) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `v1/user_profiles/affected_Clients/${id}`,
				method: 'Get',
			});

			console.log(request, 'req affected');
			dispatch({
				type: GET_AFFECTED_CLIENTS,
				payload: request.data,
			});
		} catch (e) {
			//
		}
	};
};

export const uploadImage = (image, toggleModal) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: UPLOAD_USER_IMAGE_START,
			});

			const formData = new FormData();
			formData.append('image', image);

			const request = await secure_instance.request({
				url: 'v1/user_profiles/upload',
				method: 'Post',
				data: formData,
			});

			dispatch({
				type: UPLOAD_USER_IMAGE,
				payload: request.data.data,
			});

			toggleModal();
		} catch (e) {
			dispatch({
				type: UPLOAD_USER_IMAGE_FAILED,
			});
		}
	};
};

export const clearImage = () => {
	return {
		type: CLEAR_IMAGE,
	};
};

export const resetUserAddErrors = () => {
	return {
		type: RESET_USER_ADD_ERRORS,
	};
};

export const getUserProfile = (id) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: GET_USERPROFILE_START,
			});

			dispatch(getUserRole(id));

			// const request = await secure_instance.request({
			// 	url: `/v1/user_profiles/${id}?expand=user_teams.team`,
			// 	method: 'Get',
			// });

			const request = await secure_instance.request({
				url: `/v1/user_profiles/${id}/?expand=[leave_requests.leaves,user_teams(notDeleted).team]`,
				method: 'Get',
			});

			// const clients = [];
			// request?.data.user_teams?.map(({ team }) => {
			// 	if (team.clients.length > 0) {
			// 		clients = team.clients;
			// 	}
			// });
			dispatch({
				type: GET_USERPROFILE_END,
				payload: {
					users: { ...request.data },
					// clients: clients,
				},
			});
		} catch (e) {
			dispatch({
				type: GET_USERPROFILE_FAILED,
			});
		}
	};
};

export const resetUserProfile = () => {
	return (dispatch) => {
		dispatch({
			type: RESET_USER_PROFILE,
		});
	};
};

export const updateUserProfile = (data) => {
	return async (dispatch) => {
		try {
			// dispatch(setProfileLoading(true));

			const request = await secure_instance.request({
				url: `v1/user_profiles/${data.id}`,
				method: 'Patch',
				data,
			});

			// await dispatch(authActions.getCurrentUserDetails(data.id));
			dispatch(setProfileLoading(false));
			return request.data;
		} catch (e) {
			dispatch(setProfileLoading(false));
			throw new Error(e?.response?.data?.message || e?.message || 'Something went Wrong');
		}
	};
};

export const getUserRole = (id) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: '/v1/user_roles/?expand=role_scopes',
				method: 'Get',
			});
			const roles = request.data.filter((item) => {
				if (item.user_id == id && !item.deleted) {
					return item;
				}
			});

			const FilterRoles = [];

			roles.map((role) => {
				if (role?.role_scopes?.length > 0) {
					role?.role_scopes?.map((teamRole) => {
						FilterRoles.push({
							role_id: role.role_id,
							team_id: teamRole?.team_id,
							user_role_id: teamRole?.user_role_id,
							user_scope_id: teamRole?.id,
						});
					});
				} else {
					FilterRoles.push({
						role_id: role.role_id,
					});
				}
			});

			dispatch({
				type: GET_USER_ROLES,
				payload: { userRoles: FilterRoles },
			});
		} catch (e) {
			//
		}
	};
};

export const handleTeamCheckboxes = (data) => {
	return {
		type: HANDLE_TEAM_CHECKBOXES,
		payload: data,
	};
};
export const handleLocationCheckboxes = (data) => {
	return {
		type: HANDLE_LOCATION_CHECKBOXES,
		payload: data,
	};
};
export const handleWorkModeCheckboxes = (data) => {
	return {
		type: HANDLE_WORKMODE_CHECKBOXES,
		payload: data,
	};
};
export const handleRoleCheckboxes = (checked, data) => {
	return {
		type: HANDLE_ROLE_CHECKBOXES,
		payload: { checked, data },
	};
};
export const filterUserProfileClients = (data) => {
	return {
		type: FILTER_USER_PROFILE_CLIENTS,
		payload: data,
	};
};
export const filterTaskStatuses = (data) => {
	return {
		type: FILTER_TASK_STATUSES,
		payload: data,
	};
};
export const filterUserProfileTeams = (data) => {
	return {
		type: FILTER_USER_PROFILE_TEAMS,
		payload: data,
	};
};

export const handleDateChange = (data) => {
	return {
		type: HANDLE_DATE_CHANGE,
		payload: data,
	};
};

export const clearJobTitleCheckboxes = () => {
	return {
		type: CLEAR_JOB_TITLES,
	};
};
export const clearLocationCheckboxes = () => {
	return {
		type: CLEAR_LOCATION,
	};
};
export const clearWorkModeCheckboxes = () => {
	return {
		type: CLEAR_WORK_MODE,
	};
};
export const clearTeamCheckboxes = () => {
	return {
		type: CLEAR_TEAM,
	};
};

export const profileChangeHandler = (obj) => {
	return (dispatch) => {
		dispatch({
			type: PROFILE_CHANGE_HANDLER,
			payload: { name: obj.name, value: obj.value },
		});
	};
};

export const toggleProfileInfoEdit = (show) => {
	return (dispatch) => {
		dispatch({
			type: PROFILE_INFO_EDIT,
			payload: show,
		});
	};
};

export const setUserTeams = (array) => {
	return (dispatch) => {
		dispatch({
			type: SET_USER_TEAMS,
			payload: array,
		});
	};
};

export const setProfileLoading = (state) => {
	return (dispatch) => {
		dispatch({
			type: SET_PROFILE_LOADING,
			payload: state,
		});
	};
};

let userBillingCancel = undefined;
export const getUsersBilling = () => {
	return async (dispatch) => {
		if (userBillingCancel) {
			userBillingCancel();
		}
		try {
			dispatch({ type: USER_BILLING_LIST_START });

			const request = await secure_instance.request({
				url: `/v1/user_billings/`,
				method: 'Get',
				cancelToken: new CancelToken((c) => {
					userBillingCancel = c;
				}),
			});

			dispatch({
				type: SET_USERS_BILLING_DETAILS,
				payload: request?.data,
			});

			dispatch({ type: USER_BILLING_LIST_END });
		} catch (error) {
			//
			dispatch({
				type: SET_USERS_BILLING_DETAILS,
				payload: [],
			});
			dispatch({ type: USER_BILLING_LIST_FAILED });
		}
	};
};

export const setSpecificUserBilling = (data) => {
	return (dispatch) => {
		dispatch({
			type: SET_SPECIFIC_USERS_BILLING_DETAILS,
			payload: data,
		});
	};
};

export const getSpecificUserBilling = (userId) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/user_billings/?user_id=${userId}`,
				method: 'Get',
			});

			dispatch(setSpecificUserBilling(request?.data));
		} catch (error) {
			dispatch({
				type: SET_SPECIFIC_USERS_BILLING_DETAILS,
				payload: [],
			});
		}
	};
};

export const addUserBilling = (data) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: '/v1/user_billings/',
				method: 'Post',
				data: data,
			});
			dispatch({
				type: ADD_USER_BILLING,
				payload: request.data,
			});

			return request.data;
		} catch (e) {
			throw new Error(e?.response?.data?.message || e?.message || 'Something went Wrong');
		}
	};
};

export const deleteTeamRole = (user_team_id) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `v1/user_teams/${user_team_id}`,
				method: 'Delete',
			});

			return request.data;
		} catch (e) {
			throw new Error(e?.response?.data?.message || e?.message || 'Something went Wrong');
		}
	};
};
export const updateUserRole = (data) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/user_roles/`,
				method: 'Patch',
				data,
			});
			return request.data;
		} catch (e) {
			throw new Error(e?.response?.data?.message || e?.message || 'Something went Wrong');
		}
	};
};
export const addUserToTeam = (data) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `v1/user_teams/`,
				method: 'Post',
				data,
			});
			return request.data;
		} catch (e) {
			throw new Error(e?.response?.data?.message || e?.message || 'Something went Wrong');
		}
	};
};

export const updateUserProfileInfo = (data) => {
	return (dispatch) => {
		dispatch({
			type: UPDATE_USER_PROFILE,
			payload: data,
		});
	};
};
export const updateUserRoles = (userRoles) => {
	return (dispatch) => {
		dispatch({
			type: UPDATE_USER_ROLES,
			payload: userRoles,
		});
	};
};

export const unarchiveUser = (userId, data) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/users/${userId}/unarchive`,
				method: 'Patch',
				data,
			});

			dispatch({
				type: UNARCHIVE_USER,
				payload: userId,
			});

			return request.data;
		} catch (e) {
			throw new Error(e?.response?.data?.message || e?.message || 'Something went Wrong');
		}
	};
};
