import { FormControl, MenuItem, Select } from '@mui/material';
import { useState, memo, useRef, useEffect } from 'react';
import Search from './Search';

function SearchDropDown({
    options,
    changeHandler,
    required,
    label,
    value,
    name,
}) {
    const [searchInput, setSearchInput] = useState('');
    const inputRef = useRef();

    useEffect(() => {
        inputRef?.current?.focus();
    }, [searchInput]);

    const stopImmediatePropagation = (e) => {
        e.stopPropagation();
        e.preventDefault();
    };

    return (
        <div className='multi-pick-dropdown-container mui-select-container'>
            <FormControl
                variant='standard'
                style={{
                    width: '100%',
                    background: '#f6f5f2',
                    height: '28px',
                }}
            >
                <Select
                    className='SearchDropdown dropdown-text w-100'
                    value={value || ''}
                    onChange={changeHandler}
                    name={name}
                    style={{
                        color: '#333333',
                    }}
                    required={required}
                >
                    <MenuItem
                        style={{
                            background: 'transparent',
                        }}
                        className='d-flex justify-content-center w-100 BodyTwoLight'
                        onClickCapture={stopImmediatePropagation}
                        onKeyDown={(e) => e.stopPropagation()}
                    >
                        <Search
                            ref={inputRef}
                            autoFocus={true}
                            value={searchInput}
                            onChange={(e) => {
                                setSearchInput(e.target.value);
                            }}
                            placeholder={`Search ${label}`}
                            sx={{
                                border: '1px solid #E9E7E1',
                                borderRadius: '3px',
                                width: '95%',
                                marginBottom: '5px',
                            }}
                        />
                    </MenuItem>

                    {options
                        ?.filter((item) => {
                            if (searchInput != '') {
                                return item?.label
                                    ?.toLowerCase()
                                    ?.includes(searchInput?.toLowerCase());
                            }
                            return true;
                        })
                        ?.map((opt, index) => {
                            return (
                                <MenuItem
                                    autoFocus={false}
                                    className='px-2 py-1 BodyTwoLight'
                                    key={index}
                                    value={opt.value}
                                    style={{
                                        background: 'transparent',
                                        width: '100%',
                                    }}
                                    data-cy={`option-${index+1}`}
                                >
                                    <span className='select-menu-item w-100'>
                                        {' ' + opt.label}
                                    </span>
                                </MenuItem>
                            );
                        })}
                </Select>
            </FormControl>
        </div>
    );
}

export default memo(SearchDropDown);
