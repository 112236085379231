import { CircularProgress } from '@mui/material';
import { memo } from 'react';


const SubmitButtonsField = ({ onCancel, title, child, children, onSubmit , rootClass, isLoading }) => {
	if (child == undefined) {
		return (
			<div className={`d-flex justify-content-between ${rootClass}`}>
				<button id='Element' className='secondary' onClick={onCancel}>
					Cancel
				</button>
				<button
					data-cy='confirm-btn'
					className='d-flex align-items-center'
					id='Element'
					onClick={onSubmit}
					disabled={isLoading}
				>
				{isLoading == true ? (
				<CircularProgress
					size={24}
					sx={{ color: 'white' }}
					disableShrink
				/>
				) : (
				title || 'Yes'
				)}
				</button>
			</div>
		);
	} else {
		return <div className='d-flex justify-content-between'>{children}</div>;
	}
};

export default memo(SubmitButtonsField);
