import { Modal, Tooltip } from '@mui/material';
import { ReactComponent as NotesIcon } from '../../assets/icons/client-notes.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/PencilEdit.svg';
import useModal from '../Modal/useModal';
import DummyModal from '../Modal/DummyModal';
import NotesModal from './Modals/NotesModal';
import { memo, useMemo, useState } from 'react';
import * as builderActions from '../../redux/actions/workflow-builder-actions';
import { useDispatch, useSelector } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import Restricted from '../Permissions/Restricted';
import { standardPermissions } from '../../utilities/utilities';

function ClientWorkflowNotes({ workflow_submission, display_name }) {
	const dispatch = useDispatch();
	const userTeamRole = useSelector((state) => state.clientReducer.userTeamRole);

	const { modalIsOpen, toggleModal, ModalName, ModalType } = useModal();
	const [formControls, setFormControls] = useState({
		laoding: false,
		error: '',
	});
	const data =
		workflow_submission?.data?.length > 0
			? workflow_submission?.data[0]
			: undefined;

	const workflowExistingNotes = useMemo(() => {
		return data?.props.sections?.find((item) => item.sectionName == 'NOTES')
			?.columns[0]?.columnComponents[0]?.value;
	}, [workflow_submission]);

	const handleSubmit = (notes) => {
		const updated_workflow_submission = workflow_submission?.data?.map(
			(data) => {
				data.props.sections.find(
					(section) => section.sectionName === 'NOTES',
				).columns[0].columnComponents[0].value = notes;

				return data;
			},
		)[0];

		setFormControls({
			error: '',
			laoding: true,
		});

		dispatch(
			builderActions.setAccountsComponentValue(
				updated_workflow_submission,
				workflow_submission?._id,
			),
		)
			.then(() => {
				toggleModal();
				setFormControls({
					error: '',
					laoding: false,
				});
			})
			.catch((error) => {
				setFormControls({
					error: error?.message,
					laoding: false,
				});
			});
	};

	const handleEditClick = () => {
		ModalType('EDIT_NOTES_MODAL');
		toggleModal();
	};

	return (
		<div
			className='d-flex flex-column w-100 align-items-center'
			style={{ gap: '1em' }}
		>
			<div
				style={{ padding: '12px 18px', background: 'white' }}
				className='text-left w-100 d-flex align-items-center justify-content-between'
			>
				<span className='d-flex' style={{ gap: '12px' }}>
					<NotesIcon />
					<h3 className='HeadlineThreeBold'>{display_name} Notes</h3>
				</span>
				<Restricted
					AllowedUsers={standardPermissions}
					userTeamRole={[userTeamRole]}
				>
					<Tooltip title='Edit Notes'>
						<EditIcon className='pointer' onClick={handleEditClick} />
					</Tooltip>
				</Restricted>
			</div>
			<div className='Profile-Content'>
				<div
					className='w-100'
					style={{ background: 'white', padding: '12px 24px 12px 24px' }}
				>
					{workflowExistingNotes ? (
						<p className='BodyTwoLight text-left'>
							{ReactHtmlParser(workflowExistingNotes)}
						</p>
					) : (
						<p className='text-left BodyTwoLight text-left light-text'>
							Write your notes ...
						</p>
					)}
				</div>
			</div>

			{modalIsOpen && (
				<Modal
					onClose={toggleModal}
					open={modalIsOpen}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					{ModalName == 'EDIT_NOTES_MODAL' && (
						<DummyModal
							title={'Edit Notes'}
							onClose={toggleModal}
							style={{ width: '900px' }}
							hideCrossIcon
						>
							<NotesModal
								edit={true}
								onCancel={toggleModal}
								isLoading={formControls.laoding}
								updateChanges={handleSubmit}
								initialNotes={workflowExistingNotes}
							/>
						</DummyModal>
					)}
				</Modal>
			)}
		</div>
	);
}

export default memo(ClientWorkflowNotes);
