import { ClickAwayListener } from '@mui/material';
import { useState } from 'react';

function DropDownActions({ dropdownIcon, actions = [], actionParams }) {
	const [dropdownOpen, setDropdownOpen] = useState(false);

	return (
		<>
			<ClickAwayListener onClickAway={() => setDropdownOpen(false)}>
				<div
					className='pointer'
					onClick={() => setDropdownOpen(!dropdownOpen)}
					style={{ position: 'relative' }}
				>
					{dropdownIcon}
					{dropdownOpen && (
						<div className='dropdown-box'>
							{actions?.map((item , index) => {
								return (
									<ActionMenu
										key={item.title + index}
										item={item}
										actionParams={actionParams}
									/>
								);
							})}
						</div>
					)}
				</div>
			</ClickAwayListener>
		</>
	);
}

export default DropDownActions;

const ActionMenu = ({ item, actionParams }) => {
	const [loading, setLoading] = useState(false);

	const handleClick = (event) => {
        event.stopPropagation();
        
        if(item.async){
            setLoading(true);
            item.action(actionParams)
                ?.then(() => setLoading(false))
                ?.catch(() => setLoading(false));
        }else{
            item.action(actionParams);
        }
	};

	return (
		<span
			onClick={loading ? undefined : handleClick}
			key={item.title}
			className='BodyTwoLight w-100'
			style={{
				padding: '6px 0px',
				textAlign: 'start',
				cursor: 'pointer',
				opacity: loading ? 0.5 : 1,
			}}
		>
			{item.title}
		</span>
	);
};
