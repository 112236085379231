import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingOverLay from '../Others/LoadingOverLay';
import TableHeader from '../Others/TableHeader';
import TableHeaderItem from '../Others/TableHeaderItem';
import TableRowItem from '../Others/TableRowItem';
import {
	TableItemWidth3,
	TableItemWidth5,
	TableItemWidth6,
} from '../TableSpacing/TableSpacingStyles';
import * as userActions from '../../redux/actions/user-management.actions';
import { getRoleName, Member } from '../../utilities/utilities';
import { useHistory, useLocation } from 'react-router';
import { routes } from '../../utilities/routes';
import { ReactComponent as TeamIcon } from '../../assets/icons/Team.svg';

function AccountTeams({ id, UserType }) {
	const history = useHistory();
	const location = useLocation();
	const user = useSelector((state) => state.authReducer.user);
	const edit = useSelector((state) => state.userReducer.profileInfoEdit); //User Profile
	const profile = useSelector((state) => state.userReducer.userProfile); //User Profile
	const Roles = useSelector((state) => state.userReducer.roles); // Total roles
	const userRoles = useSelector((state) => state.userReducer.userRoles); // Total User Roles
	const Teams = useSelector((state) => state.userReducer.userTeams);
	const TotalTeams = useSelector((state) => state.teamReducer.teams);

	const dispatch = useDispatch();
	const [Loading] = useState(false);

	const handleNavigateClick = (t) => {
		const from = location.state || [];
		from.push({
			link: location.pathname,
			label: profile.full_name,
		});
		if (from.length > 2) from.shift();
		history.push(`${routes['team-profile']}${t.team_id}`, from);
	};

	useEffect(() => {
		if (profile?.user_teams) {
			const userTeams = profile?.user_teams?.map((team) => {
				return {
					role_id: userRoles?.find((role) => role.team_id === team.team_id)
						?.role_id,
					team_id: team?.team_id,
					team_name: team?.team?.name,
					team_description: team?.team?.description,
					user_id: team?.user_id,
				};
			});

			dispatch(userActions.setUserTeams(userTeams));
		}

		if (userRoles?.some((item) => item.role_id === Member.ownerId)) {
			dispatch(
				userActions.setUserTeams(
					TotalTeams?.map((team) => {
						return {
							role_id: Member.ownerId,
							team_id: team?.id,
							team_name: team?.name,
							team_description: team?.description,
							user_id: team?.user_id,
						};
					}),
				),
			);
		}
	}, [profile, Roles.length, userRoles?.length]);

	return (
		<>
			<div
				className='UserProfile-Teams profile-section'
				style={{ gap: '12px' }}
				id={id}
			>
				<div className='Uprofile-sectionBar'>
					<div className='d-flex align-items-center' style={{ gap: '12px' }}>
						<TeamIcon />
						<h2 className='HeadlineThreeBold text-left'>Team & Roles</h2>
					</div>
				</div>
				<div className='Teams-Table' style={{ padding: '18px', backgroundColor: '#EEF2F3', borderRadius: '4px' }}>
					<TableHeader ClassName='TableHeader flex' border={false}>
						<TableHeaderItem label='Team Name' width={TableItemWidth3} />
						<TableHeaderItem label='Description' width={TableItemWidth6} />
						<TableHeaderItem label='Role' width={TableItemWidth5} />
					</TableHeader>

					{Loading ? (
						<div
							style={{
								minHeight: '25vh',
								position: 'relative',
								width: '100%',
							}}
						>
							<LoadingOverLay active={Loading} />
						</div>
					) : Teams?.length < 1 ? (
						<div className='TableRow h-100 w-100 d-flex align-items-center justify-content-center'>
							<h4 className='BodyTwoLight'>No Teams</h4>
						</div>
					) : (
						Teams?.map((t, i) => {
							return (
								<div key={i} className='TableRow d-flex align-items-center'>
									<TableRowItem
										ClassName=' text-left'
										label={t.team_name}
										width={TableItemWidth3}
										onClick={() => handleNavigateClick(t)}
									/>

									<TableRowItem
										ClassName=' text-left'
										label={t.team_description}
										width={TableItemWidth6}
									/>

									<TableRowItem
										ClassName=' text-left'
										label={
											Roles?.find((role) => role.id === t.role_id)?.role_name ||
											getRoleName(
												userRoles?.length > 0 && userRoles[0]?.role_id,
											)
										}
										width={TableItemWidth5}
									/>
								</div>
							);
						})
					)}
				</div>
			</div>
		</>
	);
}

export default AccountTeams;
