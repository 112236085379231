import { useState} from 'react';

// Material Ui Component

// Import Components
import CompanyProfileInformation from '../components/CompanyProfile/CompanyProfileInformation';

import Sidebar from '../components/sidebar/SideBar';

import CompanyTopBar from '../components/CompanyProfile/CompanyTopBar';

import { useSelector } from 'react-redux';
import CompanyProfileBilling from '../components/CompanyProfile/CompanyProfileBilling';
import CompanyStats from '../components/CompanyProfile/CompanyStats';
import FeatureFlag from '../components/Others/FeatureFlag';
import AlertBar from '../components/sub_components/AlertBar';


function CompanyProfile() {
	
	const [activeTab, setActiveTab] = useState(1);

	const ChangeActiveTab = (e) => {
		setActiveTab(parseInt(e.target.id));
	};

	const companyProfileData = useSelector(
		(state) => state.profileReducer.profileData
	);

	return (
		<div className='AppBody'>
			<Sidebar title='Company Profile'>
				<div className='SidebarContentWrapper'>
					<div className='User-filters'>
						{/* <SearchBar
							style={{
								color: 'white',
								backgroundColor: 'rgba(0, 0, 0, 0.25)',
							}}
						/> */}
						<div className='side-menu BodyTwoLight d-flex flex-column align-items-start'>
							
							<span
								className={
									activeTab === 1
									? 'active d-flex flex-row align-items-center'
									: 'side-menu-link d-flex flex-row align-items-center'
								}
								
								style={{ gap: '10px', paddingBlock:'10px'}}
							>
								<div className='dot'></div>
									<a onClick={ChangeActiveTab} href='#Profile' id="1" className=''>
										Company Details
									</a>
							</span>
							<FeatureFlag tenantIds={[10001, 10002, 10003, 10009]}>
								<span
									className={
										activeTab === 2
										? 'active d-flex flex-row align-items-center'
										: 'side-menu-link d-flex flex-row align-items-center'
									}
									
									style={{ gap: '10px', paddingBlock:'10px'}}
								>
									<div className='dot'></div>
										<a onClick={ChangeActiveTab} href='#Stats' id="2" className=''>
											Insights
										</a>
								</span>
							</FeatureFlag>
							<span
								className={
									activeTab === 3
									? 'active d-flex flex-row align-items-center'
									: 'side-menu-link d-flex flex-row align-items-center'
								}
								
								style={{ gap: '10px', paddingBlock:'10px'}}
							>
								<div className='dot'></div>
									<a onClick={ChangeActiveTab} href='#Billing' id="3" className=''>
										Billing
									</a>
							</span>
							</div>
						
					</div>
				</div>
			</Sidebar>
            <div
				style={{
					width: 'calc(100vw - 260px)',
                    height: '100vh', 
                    overflowY:'auto'
				}}
			>
				<AlertBar />
			<div className="Client-Profile d-flex flex-column" style={{backgroundColor:'#EEF2F3', padding:'32px'}}>
				<CompanyTopBar
					clientInfo={{
						name: companyProfileData?.name ? companyProfileData?.name : '',
						image: companyProfileData?.image_url
							? companyProfileData.image_url
							: null,
					}}
				/>
				{/* <CompanyNavbar /> */}

				<div className="Tabs-main">
					<section id="Profile">
						<CompanyProfileInformation />
					</section>
					<FeatureFlag tenantIds={[10001, 10002, 10003, 10009]}>
						<section id="Stats">
							<CompanyStats />
						</section>
					</FeatureFlag>
					<section id="Billing">
						<CompanyProfileBilling />
					</section>
				</div>
			</div>
            </div>
		</div>
	);
}

export default CompanyProfile;
