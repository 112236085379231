import moment from 'moment-timezone';

function useTimeZone() {
	const userTimeZone = moment.tz.guess();
	const offset = moment().tz(userTimeZone).format('Z');
	const offsetSign = offset[0] === '-' ? '-' : '+';
	const offsetWithoutSign = offset.substring(1);
	const abbreviation = moment.tz(userTimeZone).format('z');
	const formattedTimeZone = `(UTC${offsetSign}${offsetWithoutSign}) ${abbreviation}`;

	return { formattedTimeZone };
}

export default useTimeZone;
