export default function EditableText({
	id,
	label,
	name,
	value,
	Class,
	onChange,
	Edit,
	style,
	type,
	pattern,
	title,
	required,
	placeholder,
	min,
	rootClass,
	textClass,
	labelClass,
	barText,
	readOnly,
	toggleCustom,
	onClick,
	onBlur,
}) {
	return (
		<div
			className={`${
				barText ? 'barText' : 'ProfilePage'
			} d-flex flex-column w-100 ${rootClass}`}
		>
			<div
				className='d-flex flex-row'
				style={{ justifyContent: 'space-between' }}
			>
				<label
					className={`BodyTwoLight text-left m-0 ${labelClass} ${textClass}`}
				>
					{label}
				</label>

				{toggleCustom && Edit && (
					<label
						onClick={toggleCustom}
						style={{ fontSize: '10px' }}
						className='primary-text pointer'
					>
						choose
					</label>
				)}
			</div>
			{Edit ? (
				<div className='d-flex align-items-end' style={{ gap: '1em' }}>
					<input
						data-cy={name}
						className={`${Class} w-100`}
						name={name}
						style={style}
						type={type}
						value={value}
						onChange={onChange}
						pattern={pattern}
						title={title}
						required={required}
						placeholder={placeholder}
						min={min}
						id={id}
						onClick={onClick}
						readOnly={readOnly ? true : false}
						onBlur={onBlur}
					/>
				</div>
			) : (
				<h3
					data-cy={name}
					className={`BodyTwoLight m-0 text-left ${textClass}`}
					name={name}
					style={{ ...style, wordBreak: 'break-all' }}
				>
					{value}
				</h3>
			)}
		</div>
	);
}
