/* eslint-disable no-mixed-spaces-and-tabs */
import React, { memo, useEffect, useMemo, useState } from 'react';
import FormButtonOne from '../../Buttons/FormButtonOne';
import InputTaskDetails from '../../Inputs/InputTaskDetails';
import { useDispatch, useSelector } from 'react-redux';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Checkbox } from '@mui/material';
import moment from 'moment';
import DropDown from '../../Inputs/DropDown';
import { BillTypes, RepeatTypes } from '../../DemoData';
import {
	REPEAT_TYPE,
	endTimeOptions,
	startTimeOptions,
	weekOfMonth,
} from '../../../utilities/utilities';
import EditableRadioButton from '../../Inputs/EditableRadioButton';
import SelectMyTaskColor from './SelectMyTaskColor';
import NewMeetingGuests from './NewMeetingGuests';
import * as WorkSpaceActions from '../../../redux/actions/dashboard/workspaces-actions';
import * as clientActions from '../../../redux/actions/client-actions';
import UpdateMeetingTaskAlert from './UpdateMeetingTaskAlert';
import NewSearchDropdown from '../../Inputs/NewSearchDropdown';
import { ReactComponent as CalendarIcon } from '../../../assets/icons/Calendarcolor.svg';
import { ReactComponent as WarningIcon } from '../../../assets/icons/WarningCircle.svg';
import { getUserSettings } from '../../../hooks/getUserSettings';

const EditMeetingMyTaskModal = ({
	taskDetails,
	cancelButtonClick,
	setEdit,
	clientId,
	handleModalClose,
	modalStyle = {},
}) => {
	const dispatch = useDispatch();
	let allTenantUsers = useSelector((state) => state.userReducer.allTenantUsers);
	const taskTypes = useSelector((state) => state.workSpaceReducer.taskTypes);
	const [monthlySpecialDate, setMonthlySpecialDate] = useState(false);
	const clients = useSelector((state) => state.clientReducer.clients);
	const errors = useSelector((state) => state.workSpaceReducer.errors);
	const [open, setOpen] = useState(false);
	const [form, setForm] = useState({});
	const [notes, setNotes] = useState({});
	const [agenda, setAgenda] = useState({});
	const [search, setSearch] = useState({
		value: '',
		show: false,
	});
	const [actionLoading, setActionLoading] = useState(false);
	const userDetails = useSelector((state) => state.authReducer.userDetails);
	const { date_format } = getUserSettings(userDetails);

	const Style = {
		backgroundColor: 'white',
		border: 'none',
		borderBottom: '1px solid #28383C',
	};

	const toggleAlert = () => {
		setOpen(!open);
	};
	const handleChange = (e) => {
		const { name, value } = e.target;

		if (name == 'meeting_link') {
			setForm({
				...form,
				metadata: {
					...form?.metadata,
					[name]: value,
				},
			});
			return;
		}

		if (name == 'task_type_id' && value == 1) {
			setForm({
				...form,
				[name]: value,
				repeat_type: null,
				repeat_interval: null,
				my_task: {
					...form?.my_task,
					end_on: null,
					required_days: 1,
					is_monthly_on_day: false,
					recur_preference: null,
				},
			});
			return;
		}
		if (name == 'required_days') {
			setForm({
				...form,
				my_task: {
					...form?.my_task,
					required_days: Number(value),
				},
			});
			return;
		}
		setForm({
			...form,
			[name]: value,
		});
	};

	const handleClientChange = (client) => {
		const { label, value } = client;
		setForm({
			...form,
			client_id: value,
		});
	};

	const handleColorChange = (color) => {
		setForm({
			...form,
			metadata: {
				...form.metadata,
				color: color,
			},
		});
	};

	const timeRangeHandle = (event, indexName) => {
		const time = event.target.value;
		if (indexName == 'start_time') {
			const end_time = moment(time, 'hh:mm A').add(1, 'hour').format('hh:mm A');
			setForm({
				...form,
				end_time: end_time,
				[indexName]: time,
			});
		} else {
			setForm({
				...form,
				[indexName]: time,
			});
		}
	};

	const recurPreferenceChangeHandler = (event) => {
		let { name, value } = event.target;

		if (value === 'null') {
			setForm({
				...form,
				my_task: {
					...form.my_task,
					recur_preference: value,
				},
			});
		} else {
			value = Number(value);
			setForm({
				...form,
				my_task: {
					...form.my_task,
					recur_preference: value,
				},
			});
		}
	};

	const isOnMonthlyChangeHandler = (event) => {
		const { name, checked } = event.target;
		if (checked) {
			setMonthlySpecialDate(false);
			setForm({
				...form,
				my_task: {
					...form.my_task,
					recur_preference: null,
					is_monthly_on_day: checked,
				},
			});
		} else {
			if (
				weekOfMonth(moment(form?.start_date)) === 4 &&
				moment(form?.start_date).add(7, 'days').month() > moment(form?.start_date).month()
			) {
				setMonthlySpecialDate(true);
				setForm({
					...form,
					my_task: {
						...form.my_task,
						recur_preference: null,
						is_monthly_on_day: checked,
					},
				});
			} else {
				setMonthlySpecialDate(true);
				setForm({
					...form,
					my_task: {
						...form.my_task,
						recur_preference: null,
						is_monthly_on_day: checked,
					},
				});
				setMonthlySpecialDate(false);
			}
		}
	};

	const handleDateChange = (date) => {
		if (form?.repeat_type === REPEAT_TYPE.MONTHLY) {
			if (
				weekOfMonth(moment(date)) === 4 &&
				moment(date).add(7, 'days').month() > moment(date).month() &&
				!form?.is_monthly_on_day
			) {
				setMonthlySpecialDate(true);
				setForm({
					...form,
					start_date: date,
					my_task: {
						...form.my_task,
						recur_preference: null,
					},
				});
			} else {
				setForm({
					...form,
					start_date: date,
					my_task: {
						...form.my_task,
						recur_preference: null,
					},
				});
				setMonthlySpecialDate(false);
			}
		} else {
			setForm({
				...form,
				start_date: date,
			});
		}
		setForm({
			...form,
			start_date: date,
		});
	};

	const handleEndOnType = (event) => {
		const { name, value } = event.target;
		if (name == 'type') {
			setForm({
				...form,
				my_task: {
					...form?.my_task,
					end_on: {
						type: value,
						occurrence: null,
						end_date: null,
					},
				},
			});
		}

		if (name == 'occurrence') {
			setForm({
				...form,
				my_task: {
					...form?.my_task,
					end_on: {
						type: name,
						[name]: Number(value),
						end_date: null,
					},
				},
			});
		}
	};

	const onEndDateChange = (date) => {
		setForm({
			...form,
			my_task: {
				...form?.my_task,
				end_on: {
					type: 'end_date',
					occurrence: null,
					end_date: date,
				},
			},
		});
	};

	const handleRemoveGuest = (id) => {
		const tempGuests = form?.assignee_users?.filter((item) => {
			if (item.id !== id) {
				return item;
			}
		});

		setForm({
			...form,
			assignee_users: [...tempGuests],
		});
	};

	const handleSearch = (e) => {
		if (e.target.value !== '') {
			setSearch({ value: e.target.value, show: true });
		} else {
			setSearch({ value: e.target.value, show: false });
		}
	};

	const handleAddGuest = (user) => {
		setForm({
			...form,
			assignee_users: [...form?.assignee_users, user],
		});
	};

	const quillHandlerNotes = (content) => {
		setNotes(content);
	};

	const quillHandlerAgenda = (content) => {
		setAgenda(content);
	};

	const RECUR_PREFERENCES = [
		{
			value: 2,
			label: `Start the recurrence on last ${moment(form?.start_date).format(
				'dddd',
			)} of the month`,
		},
		{
			value: 1,
			label: `Start the recurrence on every 4th ${moment(form?.start_date).format(
				'dddd',
			)} of the month`,
		},
	];

	const END_ON = [
		{ value: 'occurrence', label: 'Occurrences' },
		{ value: 'end_date', label: 'Date' },
		{ value: 'never', label: 'Never' },
	];

	const [isRecurrenceUpdate, setIsRecurrenceUpdate] = useState(false);

	const checkRecurrenceUpdate = (event) => {
		event.preventDefault();
		const isRecurrenceUpdate =
			JSON.stringify(taskDetails?.my_task?.end_on) == JSON.stringify(form?.my_task?.end_on) &&
			taskDetails?.repeat_interval == form?.repeat_interval &&
			taskDetails?.repeat_type == form?.repeat_type &&
			taskDetails?.task_type_id == form?.task_type_id;

		setIsRecurrenceUpdate(!isRecurrenceUpdate);
		taskDetails?.task_type_id == 2 || (taskDetails?.task_type_id == 1 && !isRecurrenceUpdate)
			? toggleAlert()
			: onUpdateTask();
	};

	const onUpdateTask = (update_future = false) => {
		const date = moment(form?.start_date).format('YYYY-MM-DD');
		const startDateTime = moment(date + ' ' + form.start_time, 'YYYY-MM-DD HH:mm:ss a').utc();
		const endDateTime = moment(date + ' ' + form.end_time, 'YYYY-MM-DD HH:mm:ss a').utc();
		const task_date = moment(startDateTime).format('YYYY-MM-DD');

		let formDetails = {};
		setActionLoading(true);

		formDetails = {
			id: form?.id,
			type: form?.task_type_id == 1 ? 'ONE_TIME' : 'RECURRING',
			agenda: agenda,
			notes: notes,
			title: form?.title,
			client_id: form?.client_id == null ? null : Number(form?.client_id),
			assignee_list: form?.assignee_users?.map((assignee) => assignee.id) || [],
			start_date: task_date,
			is_monthly_on_day: form?.my_task?.is_monthly_on_day,
			metadata: {
				meeting_link: form?.metadata?.meeting_link,
				meeting_platform: form?.metadata?.meeting_platform,
			},
			start_time: moment(startDateTime, 'hh:mm A').format('hh:mm:ss a') || null,
			end_time: moment(endDateTime, 'hh:mm A').format('hh:mm:ss a') || null,
			is_billable: form?.is_billable == 'Billable' ? true : false,
			end_on: form?.my_task?.end_on,
			required_days: Number(form?.my_task?.required_days),
			repeat_type: form?.repeat_type,
			repeat_interval: form?.repeat_interval == null ? null : Number(form?.repeat_interval),
			recur_preference: form?.my_task?.recur_preference,
			task_type: 'MEETING',
			color: form?.metadata?.color,
		};

		dispatch(WorkSpaceActions.updateMeetingTaskDetails(formDetails, update_future))
			.then(() => {
				setActionLoading(false);
				onUpdateSuccess(update_future);
			})
			.catch((error) => {
				setActionLoading(false);
				dispatch(WorkSpaceActions.setSnackBarIsOpen(false, true, error.message));
			});
		open && toggleAlert();
	};

	const onUpdateSuccess = (update_future) => {
		const isStartDateDifferent = !moment(taskDetails?.start_date).isSame(form?.start_date);
		const shouldModalClose = Boolean(
			isRecurrenceUpdate || (update_future && isStartDateDifferent),
		);
		setEdit && setEdit(false);
		if (clientId) {
			handleModalClose();
			dispatch(clientActions.getMeetingMyTasks(clientId));
		}
		update_future && dispatch(WorkSpaceActions.getMeetings());
		shouldModalClose && handleModalClose && handleModalClose();
		dispatch(WorkSpaceActions.setSnackBarIsOpen(true, true, 'Updated'));
	};

	if (allTenantUsers?.length > 1) {
		allTenantUsers = allTenantUsers
			// .filter((a) => a.is_active)
			?.map((u) => {
				return {
					id: u.id,
					full_name: u.full_name,
				};
			});
	}

	useEffect(() => {
		dispatch(WorkSpaceActions.getTaskTypes());
		dispatch(clientActions.getClients());
	}, [dispatch]);

	useMemo(() => {
		if (taskDetails) {
			setNotes(taskDetails?.notes);
			setAgenda(taskDetails.agenda);
			setForm({
				...taskDetails,
				is_billable: taskDetails?.is_billable == true ? 'Billable' : 'Non-Billable',
				start_time: moment(taskDetails?.start_time, 'hh:mm A').format('hh:mm A'),
				end_time: moment(taskDetails?.end_time, 'hh:mm A').format('hh:mm A'),
				assignee_users: clientId
					? taskDetails?.assignee_users
					: taskDetails?.assignee_users?.map((assignee) => assignee?.user_profile),
			});
		}
		dispatch(WorkSpaceActions.setTaskCreatedError(''));
	}, [taskDetails]);

	return (
		<form
			onSubmit={checkRecurrenceUpdate}
			autoComplete='off'
			className='my-task-modal'
			style={ {  width: 1000 , ...modalStyle }}
		>
			<div style={{ height: '90%', paddingBottom: '20px' }} className='my-task-body'>
				<div className='my-task-body-child'>
					<div className='HeadlineTwoBold dark-text d-flex'>Edit Meeting</div>
					<div className='my-task-inner-body'>
						<div
							style={{
								height: '100%',
								boxSizing: 'border-box',
								overflow: 'auto',
							}}
						>
							<div
								className='UserAdd-Form d-flex flex-column'
								style={{ gap: '16px', overflowY: 'unset' }}
							>
								<div className='improved-input-designs pt-1'>
									<label className='require'>Name</label>
									<input
										className='w-100'
										type='text'
										name='title'
										onChange={handleChange}
										style={Style}
										required
										value={form?.title}
									/>
								</div>

								<div className='improved-input-designs pb-3'>
									<label>Agenda</label>
									<InputTaskDetails
										richText
										type='text'
										name='agenda'
										value={agenda}
										onChange={quillHandlerAgenda}
										placeholderText='Enter agenda'
									/>
								</div>
								<div className='improved-input-designs'>
									<label className='w-100'>Meeting Link</label>
									<input
										className='w-100'
										type='text'
										style={Style}
										value={form?.metadata?.meeting_link}
										name='meeting_link'
										onChange={handleChange}
										placeholder='Enter meeting link'
									/>
								</div>
								<NewMeetingGuests
									className='new-input-designs align-items-start w-100'
									search={search}
									handleSearch={handleSearch}
									handleRemoveGuest={handleRemoveGuest}
									handleAddGuest={handleAddGuest}
									assignees={allTenantUsers}
									form={form}
									taskDetails={taskDetails}
								/>
								<div className='improved-input-designs'>
									<label className='require'>Start Time</label>
									<DropDown
										name='start_time'
										normalDropDown
										style={{ height: '32px' }}
										value={form?.start_time}
										onChange={(event) => timeRangeHandle(event, 'start_time')}
										options={startTimeOptions()}
										required={true}
										disableIcon
										disableNull
									/>
								</div>

								<div className='improved-input-designs'>
									<label className='require'>End Time</label>
									<DropDown
										name='end_time'
										normalDropDown
										style={{ height: '32px' }}
										value={form?.end_time}
										onChange={(event) => timeRangeHandle(event, 'end_time')}
										options={endTimeOptions(form?.start_time)}
										disableIcon
										required={true}
										disableNull
									/>
								</div>
								<div
									className='d-flex flex-column'
									style={{
										backgroundColor: '#F7F9FA',
										padding: '12px',
										borderRadius: '4px',
										gap: '1rem',
									}}
								>
									<label className='BodyOneBold'>Recurrence</label>

									<div className='d-flex align-items-end'>
										<WarningIcon />
										<span className='BodyThreeLight pl-2'>
											Any changes will impact both current and future
											recurrences.
										</span>
									</div>

									<div className='improved-input-designs'>
										<label className='require'>Task Type</label>
									</div>
									<DropDown
										onChange={handleChange}
										normalDropDown
										name='task_type_id'
										style={{ height: '32px' }}
										selectDisabled={clientId ? true : false}
										disableNull
										required={true}
										value={
											taskTypes
												?.map((task) => {
													return {
														value: task.id,
														label: task.name,
													};
												})
												?.find((item) => item.value == form?.task_type_id)
												?.value || ''
										}
										options={
											taskTypes &&
											taskTypes?.map((task) => {
												return {
													value: task.id,
													label: task.name,
												};
											})
										}
									/>

									{form?.task_type_id == 1 && (
										<div className='improved-input-designs'>
											<label className='require'>Date</label>
											<KeyboardDatePicker
												autoOk
												disableToolbar
												value={form?.start_date}
												name='due_date'
												onChange={handleDateChange}
												keyboardIcon={<CalendarIcon />}
												format={date_format}
												disablePast
												minDate={moment()}
												style={{ padding: '2px' }}
												required
											/>
										</div>
									)}

									{form?.task_type_id == 2 && (
										<>
											<div className='improved-input-designs'>
												<label className='require'>Repeat Type</label>
												<DropDown
													onChange={handleChange}
													normalDropDown
													name='repeat_type'
													disableNull
													required={true}
													options={RepeatTypes}
													style={{ height: '32px' }}
													value={
														RepeatTypes.find(
															(item) =>
																item.value === form?.repeat_type,
														)?.value
													}
												/>
											</div>
											<div className='improved-input-designs'>
												<label className='require'>Date</label>
												<KeyboardDatePicker
													autoOk
													disableToolbar
													value={form?.start_date}
													name='due_date'
													onChange={handleDateChange}
													keyboardIcon={<CalendarIcon />}
													format={date_format}
													minDate={moment()}
													disablePast
													style={{ padding: '2px' }}
													required
												/>
											</div>

											{form?.repeat_type === REPEAT_TYPE.MONTHLY &&
												monthlySpecialDate && (
													<div className='improved-input-designs pb-3'>
														<label className=''>
															RECUR PREFERENCES
														</label>
														<DropDown
															onChange={recurPreferenceChangeHandler}
															normalDropDown
															style={{ height: '32px' }}
															disableNull
															value={
																RECUR_PREFERENCES.find(
																	(item) =>
																		item.value ===
																		form?.my_task
																			?.recur_preference,
																)?.value
															}
															name='recur_preference'
															options={RECUR_PREFERENCES}
														/>
													</div>
												)}
											<div className='improved-input-designs'>
												<label className='require'>Repeat every</label>
												<input
													className='w-100'
													type='number'
													name='repeat_interval'
													required
													onChange={handleChange}
													style={Style}
													value={form?.repeat_interval}
													min={1}
												/>
											</div>

											{form?.repeat_type === REPEAT_TYPE.MONTHLY && (
												<div className='pb-3 d-flex flex-row align-items-center'>
													<Checkbox
														className='input-style bg-white'
														checked={form?.my_task?.is_monthly_on_day}
														sx={{
															color: '#EAEAEA !important',
															'&.Mui-checked': {
																color: '#FB7A03 !important',
															},
															'&.MuiCheckbox-root': {
																'&:hover': {
																	boxShadow: 'none',
																},
															},
														}}
														style={{
															padding: 0,
														}}
														name={'is_monthly_on_day'}
														onChange={isOnMonthlyChangeHandler}
													/>
													<label
														style={{ color: '#333333' }}
														className='formText BodyTwoLight p-0 m-0 ml-1 pt-1'
													>
														Repeat on this day of the month
													</label>
												</div>
											)}

											<div className='improved-input-designs'>
												<label className='require'>Ends on</label>
												<DropDown
													onChange={handleEndOnType}
													normalDropDown
													name='type'
													disableNull
													required={true}
													options={END_ON}
													style={{ height: '32px' }}
													value={
														END_ON?.find(
															(item) =>
																item?.value ==
																form?.my_task?.end_on?.type,
														)?.value
													}
												/>
											</div>
											{form?.my_task?.end_on?.type === 'occurrence' ? (
												<div className='improved-input-designs pb-3'>
													<label className='w-100 require'>
														Number of Occurrences
													</label>
													<div
														className='d-flex flex-row align-items-center'
														style={{ gap: '5px' }}
													>
														<input
															className='w-50'
															type='number'
															name='occurrence'
															required={true}
															value={
																form?.my_task?.end_on?.occurrence ||
																null
															}
															onChange={handleEndOnType}
															min={1}
														/>
													</div>
												</div>
											) : (
												form?.my_task?.end_on?.type === 'end_date' && (
													<div className='improved-input-designs pb-3 d-flex flex-column'>
														<label className='w-100 require'>
															Ends On
														</label>
														<KeyboardDatePicker
															autoOk
															disableToolbar
															value={
																form?.my_task?.end_on?.end_date ||
																null
															}
															name='end_date'
															onChange={onEndDateChange}
															keyboardIcon={<CalendarIcon />}
															format={date_format}
															minDate={form?.start_date || moment()}
															required
														/>
													</div>
												)
											)}
										</>
									)}
								</div>

								<div className='improved-input-designs pb-3'>
									<label className='require'>Bill Type</label>
									<EditableRadioButton
										name='is_billable'
										value={form?.is_billable}
										onChange={handleChange}
										options={BillTypes}
										required
										disabled={clientId}
										Edit
									/>
								</div>

								<div className='improved-input-designs pb-3'>
									<label
										className={
											form?.is_billable === 'Billable' ? 'require' : ''
										}
									>
										Client
									</label>
									<NewSearchDropdown
										selectClassName={'w-100'}
										width={'380px'}
										value={
											clients
												?.map((client) => {
													{
														return {
															value: client.id,
															label:
																client?.dba || client.company_name,
														};
													}
												})
												.find((item) => item.value == form?.client_id)
												?.value
										}
										style={{
											...Style,
											backgroundColor: clientId ? '#f6f5f2' : 'white',
										}}
										placeholder='Please Select'
										selecDisabled={clientId ? true : false}
										changeHandler={handleClientChange}
										options={
											clientId
												? clients
														?.map((client) => {
															return {
																value: client.id,
																label:
																	client?.dba ||
																	client.company_name,
															};
														})
														?.filter(
															(client) => client?.value == clientId,
														)
												: clients?.map((client) => {
														return {
															value: client.id,
															label:
																client?.dba || client.company_name,
														};
												  })
										}
										name={'client_id'}
										label={'Client'}
										disableLetters
										required={form?.is_billable === 'Billable' ? true : false}
									/>
								</div>

								<div className='improved-input-designs pb-3'>
									<label>Notes</label>
									<InputTaskDetails
										richText
										type='text'
										name='notes'
										value={notes}
										onChange={quillHandlerNotes}
									/>
								</div>
								<div className='new-input-designs'>
									<SelectMyTaskColor
										form={form}
										handleColorChange={handleColorChange}
										requireClass='require'
									/>
								</div>
							</div>
						</div>
						<div className='pb-2 pt-2'>
							<span className='error-text BodyTwoLight'>{errors}</span>
						</div>
					</div>
				</div>
			</div>
			<div style={{ height: '10%' }} className='my-task-form-actions align-items-center'>
				<FormButtonOne
					onClick={cancelButtonClick}
					type='button'
					className='secondary-btn-light'
				>
					Back
				</FormButtonOne>

				<FormButtonOne loading={actionLoading} type='submit'>
					Save
				</FormButtonOne>
			</div>
			<UpdateMeetingTaskAlert
				open={open}
				isRecurrenceUpdate={isRecurrenceUpdate}
				closeHandler={toggleAlert}
				submitHandler={({ update_future }) => {
					onUpdateTask(update_future);
				}}
			/>
		</form>
	);
};

export default memo(EditMeetingMyTaskModal);
