import DummyModal from './DummyModal';
import { Modal } from '@mui/material';
import CreateFolder from '../sub_components/DocumentStorage/CreateFolder';

function CreateFolderModal({ modalIsOpen, toggleModal, item, title }) {
	return (
		<div>
			{modalIsOpen && (
				<Modal
					onClose={toggleModal}
					open={modalIsOpen}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<DummyModal title={title} onClose={toggleModal}>
						<CreateFolder item={item} onCancel={toggleModal} />
					</DummyModal>
				</Modal>
			)}
		</div>
	);
}

export default CreateFolderModal;
