import { Modal } from '@mui/material';
import { useRef, useState } from 'react';
import ClientPreviewSaveModal from './ClientPreviewSaveModal';
import { useHistory } from 'react-router';

function ClientProfilePreviewActionBar() {
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const client = location?.state;

	const modalRef = useRef();
	const history = useHistory();

	const toggleModal = () => {
		setModalIsOpen(!modalIsOpen);
	};

	return (
		<div className='heading-bar-0 d-flex justify-content-between w-100'>
			<div className='d-flex align-items-center' style={{ gap: '8px' }}>
				<div className='ai-input-circle'></div>

				<span className='BodyOneLight pt-1'>Automate Input</span>
			</div>

			<div className='d-flex' style={{ gap: '12px' }}>
				<button
					onClick={() => history.push(`client-profile/${client?.clientId}`)}
					className='rounded-button-1 secondary'
				>
					Discard
				</button>
				<button onClick={toggleModal} className='rounded-button-1'>
					Save
				</button>
			</div>

			<Modal className='material-ui-modal' open={modalIsOpen}>
				<ClientPreviewSaveModal onClose={toggleModal} ref={modalRef} />
			</Modal>
		</div>
	);
}

export default ClientProfilePreviewActionBar;
