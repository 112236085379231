/* eslint-disable no-useless-escape */
/* eslint-disable no-mixed-spaces-and-tabs */
// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SubmitButtonsField from '../../Buttons/SubmitButtonsField';
import * as clientFormActions from '../../../redux/actions/client-accounts-actions';
import { useParams } from 'react-router';
import { CircularProgress } from '@mui/material';
import CryptoJS from 'crypto-js';
import {
	formatPhoneNumber,
	Member,
	PROTECTION_KEY,
} from '../../../utilities/utilities';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import PickList from '../../Inputs/PickList';
import Restricted from '../../Permissions/Restricted';
import * as userActions from '../../../redux/actions/user-management.actions';
import * as clientAccountsActions from '../../../redux/actions/client-accounts-actions';
import SearchDropDown from '../../Inputs/SearchDropDown';

function AddPaymentPlatform({ onCancel, accountType }) {
	const dispatch = useDispatch();
	const { id } = useParams();

	const roles = useSelector((state) => state.userReducer.roles);

	const userTeamRole = useSelector((state) => state.clientReducer.userTeamRole);

	const Load = useSelector((state) => state.clientAccountsReducer.formLoad);

	const error = useSelector((state) => state.clientAccountsReducer.formError);

	const urls = useSelector(
		(state) => state.clientAccountsReducer.allUrls,
	)?.filter(({ type }) => type == accountType);

	const form = useSelector(
		(state) => state.clientAccountsReducer.paymentPortal,
	);

	const editForm = useSelector((state) => state.clientAccountsReducer.editForm);

	const [showPassword, setShowPassword] = useState(false);
	const [customUrl, setCustomUrl] = useState(false);

	const [FormFieldsErr, setFormFieldsErr] = useState({});

	const clientAccounts = useSelector(
		(state) => state.clientAccountsReducer.clientAccounts,
	);

	let softwareList = clientAccounts?.find(
		(acc) => acc.type === accountType,
	)?.softwares;

	const handleChange = (event) => {
		let { name, value } = event.target;

		if (name == 'name') {
			customUrl &&
				dispatch(
					clientFormActions.setForm(
						{
							...form,
							institution: {
								institution: value,
								url: form?.institution?.url,
							},
						},
						'paymentPortal',
					),
				);
		}

		if ((name === 'notes' || name === 'description') && value.length > 255) {
			setFormFieldsErr({
				...FormFieldsErr,
				[name]: 'Max limit is 255 characters',
			});
			return;
		}

		if (name == 'custom_url') {
			value =
				value.includes('https://') || value.includes('http://')
					? value
					: `https://${value}`;

			delete form.institution_url_id;
			dispatch(
				clientFormActions.setForm(
					{ ...form, custom_url: value },
					'paymentPortal',
				),
			);
			return 0;
		}

		if (name == 'role_id') {
			value = parseInt(value);

			if (isNaN(value)) {
				value = null;
			}
		}

		if (name === 'phone') {
			if (value.length > 14) {
				value = form?.phone;
			} else {
				value = formatPhoneNumber(value);
			}
		}

		if (name == 'institution_url_id') {
			value = parseInt(value);

			delete form.custom_url;
			dispatch(clientFormActions.setForm(form, 'paymentPortal'));
		}

		setFormFieldsErr({
			...FormFieldsErr,
			[name]: '',
		});

		dispatch(
			clientFormActions.formValueChangehandler('paymentPortal', name, value),
		);
	};

	const handleOnCancel = () => {
		dispatch(clientFormActions.resetFormValues('paymentPortal'));
		dispatch(clientFormActions.setError(''));
		onCancel();
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		// checking if there are any errors
		const hasErrors = Object.values(FormFieldsErr).some(
			(value) => value.trim().length > 0,
		);
		if (hasErrors) {
			return;
		}

		dispatch(clientFormActions.toggleLoad());

		const encryptPassword = CryptoJS.AES.encrypt(
			form.password,
			PROTECTION_KEY,
		).toString();

		if (customUrl) {
			const pattern = new RegExp(
				/^(https?|ftp):\/\/(www\.)?(([a-zA-Z0-9-]+\.){1,}[a-zA-Z]{2,})(\/[a-zA-Z0-9-_\.\/\?\&\%\#\=]+)?(\/)?(:[0-9]{1,5})?$/,
			);
			if (!pattern.test(form?.custom_url)) {
				dispatch(clientFormActions.toggleLoad());
				dispatch(clientFormActions.setError('Invalid Url'));
				return 0;
			}
		}

		if (form.id) {
			softwareList = softwareList.map((soft) => {
				if (soft.id === form.id) {
					return { ...form, password: encryptPassword };
				}
				return soft;
			});

			const updatedAccounts = clientAccounts.map((item) => {
				if (item.type === accountType) {
					return {
						...item,
						softwares: [...softwareList],
					};
				} else {
					return item;
				}
			});

			dispatch(
				clientAccountsActions.updateAllAcocuntsList(
					updatedAccounts,
					Number(id),
					handleOnCancel
				)
			);
		} else {
			const order = clientAccounts?.find((acc) => acc.type === accountType)
				?.softwares?.length;
			dispatch(
				clientFormActions.handleFormSubmit(
					{
						client_id: Number(id),
						type: accountType,
						softwares: [{ ...form, password: encryptPassword, order }],
					},
					handleOnCancel,
				),
			);
		}
	};

	const toggleCustomUrl = () => {
		dispatch(
			clientFormActions.setForm(
				{
					...form,
					institution_url_id: null,
					custom_url: '',
					name: '',
				},
				'paymentPortal',
			),
		);
		setCustomUrl(!customUrl);
	};

	useEffect(() => {
		dispatch(userActions.getRoles());

		if (editForm) {
			if (editForm?.institution_url_id) {
				setCustomUrl(false);
			} else {
				setCustomUrl(true);
			}
			dispatch(
				clientFormActions.setForm(
					{
						...editForm,
						password: CryptoJS.AES.decrypt(
							editForm.password,
							PROTECTION_KEY,
						).toString(CryptoJS.enc.Utf8),
					},
					'paymentPortal',
				),
			);
		} else {
			dispatch(clientFormActions.setForm(null, 'paymentPortal'));
		}
	}, [editForm]);

	const addSecurityQuestion = () => {
		dispatch(clientFormActions.addSecurityQuestion('paymentPortal'));
	};

	const otherQuestionChangeHandler = (
		e,
		arrayIndex,
		accountType = 'paymentPortal',
	) => {
		const { name, value } = e.target;
		dispatch(
			clientFormActions.otherQuestionChangeHandler({
				name,
				value,
				arrayIndex,
				accountType,
			}),
		);
	};

	const removeQuestion = (arrayIndex, accountType = 'paymentPortal') => {
		dispatch(
			clientFormActions.removeQuestion({
				arrayIndex,
				accountType,
			}),
		);
	};

	return (
		<form
			autoComplete='off'
			className='ContactAdd-Form'
			onSubmit={handleSubmit}
		>
			<div>
				<div
					className='p-3'
					style={{
						maxHeight: '75vh',
						height: 'fit-content',
						position: 'relative',
						overflowY: 'auto',
					}}
				>
					{customUrl ? (
						<div className='improved-input-designs pb-3'>
							<div
								className='d-flex flex-row'
								style={{ justifyContent: 'space-between' }}
							>
								<label className='require'>Name</label>
								<label
									onClick={toggleCustomUrl}
									className='primary-text pr-1 pointer'
								>
									Choose existing
								</label>
							</div>
							<input
								className='w-100'
								type='text'
								name='name'
								value={form?.name}
								required={true}
								onChange={handleChange}
							/>
						</div>
					) : (
						<div className='improved-input-designs pb-3'>
							<div
								className='d-flex flex-row'
								style={{ justifyContent: 'space-between' }}
							>
								<label className='require'>Name</label>
								<label
									onClick={toggleCustomUrl}
									className='primary-text pr-1 pointer'
								>
									Add custom
								</label>
							</div>

							<SearchDropDown
								value={form?.institution_url_id}
								changeHandler={handleChange}
								options={urls?.map((item) => {
									return {
										value: item?.id,
										label: item?.institution,
									};
								})}
								name='institution_url_id'
								required={true}
								label='Url'
							/>
						</div>
					)}

					{customUrl ? (
						<div className='improved-input-designs pb-3'>
							<div
								className='d-flex flex-row'
								style={{ justifyContent: 'space-between' }}
							>
								<label className='require'>Url</label>
							</div>
							<input
								className='w-100'
								type='text'
								name='custom_url'
								value={form?.custom_url
									?.replace('https://', '')
									?.replace('http://', '')}
								required={true}
								onChange={handleChange}
							/>
						</div>
					) : (
						<div className='improved-input-designs pb-3'>
							<div
								className='d-flex flex-row'
								style={{ justifyContent: 'space-between' }}
							>
								<label className='require'>URL</label>
							</div>
							<input
								disabled={true}
								className='w-100 bg-white'
								type='text'
								value={
									form?.institution_url_id
										? urls
												?.find((item) => item.id == form?.institution_url_id)
												?.url?.replace('https://', '')
												?.replace('http://', '')
										: ''
								}
								required={true}
							/>
						</div>
					)}

					<div className='improved-input-designs pb-3 text-capitalize'>
						<label className=' '>Description</label>
						<input
							className='w-100'
							type='text'
							name='description'
							value={form?.description}
							onChange={handleChange}
						/>
						{FormFieldsErr?.description && (
							<span className='err-text'>{FormFieldsErr.description}</span>
						)}
					</div>

                    <div className='improved-input-designs pb-3 text-capitalize'>
						<label className=' '>Notes</label>
						<input
							className='w-100'
							type='text'
							name='notes'
							value={form?.notes}
							onChange={handleChange}
						/>
						{FormFieldsErr?.notes && (
							<span className='err-text'>{FormFieldsErr.notes}</span>
						)}
					</div>

					<div className='improved-input-designs pb-3'>
						<label className='require'>Username</label>
						<input
							className='w-100'
							type='email'
							name='username'
							style={{ display: 'none' }}
						/>
						<input
							className='w-100'
							type='text'
							name='account'
							value={form?.account}
							required={true}
							onChange={handleChange}
						/>
					</div>

					<div className='improved-input-designs pb-3'>
						<label className='require'>Password</label>
						<div className='w-100 position-relative'>
							<input
								className='w-100'
								type='password'
								name='password'
								style={{ display: 'none' }}
							/>
							
                            <input
							    className={`w-100 ${showPassword ? '' : 'hidder-password-input'}`}
							    autoComplete='off'
							    name='password'
							    value={form?.password}
							    onChange={handleChange}
							    required={true}
						    />

							{showPassword ? (
								<VisibilityOffIcon
									className='pointer'
									fontSize='small'
									sx={{
										color: '#fb7a03',
										position: 'absolute',
										left: '93%',
										top: '18%',
									}}
									onClick={() => setShowPassword(!showPassword)}
								/>
							) : (
								<VisibilityIcon
									className='pointer'
									fontSize='small'
									sx={{
										color: '#fb7a03',
										position: 'absolute',
										left: '93%',
										top: '18%',
									}}
									onClick={() => setShowPassword(!showPassword)}
								/>
							)}
						</div>
					</div>

					<Restricted
					    AllowedUsers={[Member.ownerId, Member.supervisorId , Member.teamManagerId]}
                        userTeamRole={[userTeamRole]}
					>
						<div className='improved-input-designs pb-3'>
							<label className='require'>VISIBLE TO (ROLE)</label>
							<PickList
								value={form?.role_id}
								SelectRole={handleChange}
								options={roles.filter((role) => role.id != 15)}
								Name='role_id'
								require={true}
							/>
						</div>
					</Restricted>

					<div className='improved-input-designs pb-3 text-capitalize'>
						<label className=' '>Account Number</label>
						<input
							className='w-100'
							type='text'
							name='accountNumber'
							value={form?.accountNumber}
							onChange={handleChange}
						/>
					</div>

					{form?.otherQuestions?.map((item, i) => {
						return (
							<>
								<div className='improved-input-designs pb-3 text-capitalize'>
									<div
										className='d-flex flex-row'
										style={{ justifyContent: 'space-between' }}
									>
										<label className=' '>Security Question {i + 1}</label>
										<label
											onClick={
												item.default
													? () => addSecurityQuestion()
													: () => removeQuestion(i)
											}
											className='primary-text pr-1 pointer'
										>
											{item.default ? 'Add New Question' : 'Remove'}
										</label>
									</div>

									<input
										className='w-100'
										type='text'
										name='question'
										value={item.question}
										onChange={(e) => otherQuestionChangeHandler(e, i)}
									/>
								</div>

								<div className='improved-input-designs pb-3 text-capitalize'>
									<label className=' '>Answer</label>
									<input
										className='w-100'
										type='text'
										name='answer'
										value={item.answer}
										onChange={(e) => otherQuestionChangeHandler(e, i)}
									/>
								</div>
							</>
						);
					})}

					<div className='improved-input-designs pb-3 text-capitalize'>
						<label className=' '>PHONE (FOR SECURITY CODE) </label>
						<input
							className='w-100'
							type='phone'
							name='phone'
							value={form?.phone}
							onChange={handleChange}
						/>
					</div>

					<div className='improved-input-designs text-capitalize'>
						<label className=' '>EMAIL (FOR SECURITY CODE)</label>
						<input
							className='w-100'
							type='email'
							name='email'
							value={form?.email}
							onChange={handleChange}
						/>
					</div>
				</div>
			</div>

			<div className='px-3 py-2'>
				<span style={{ color: 'red', fontSize: '0.9em' }}>{error}</span>
			</div>

			<div className='improved-input-designs bg-white p-3 rounded-bottom'>
				<SubmitButtonsField child={true}>
					<button
						id='Element'
						type='button'
						className='secondary'
						onClick={handleOnCancel}
					>
						Cancel
					</button>

					<button
						type='submit'
						className='d-flex align-items-center'
						id='Element'
						disabled={Load}
					>
						{!Load ? (
							editForm ? (
								'Update'
							) : (
								'Add'
							)
						) : (
							<CircularProgress
								size={24}
								sx={{ color: '#fff' }}
								disableShrink
							/>
						)}
					</button>
				</SubmitButtonsField>
			</div>
		</form>
	);
}

export default AddPaymentPlatform;
