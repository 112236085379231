import React from 'react';
import { ReactComponent as Levvy } from '../../assets/icons/LevvyWordMark.svg';

import { ReactComponent as LevvyLogo } from '../../assets/icons/Shape.svg';
import LoginForm from '../../components/login/LoginForm';

export default function Login() {
	return (
		<div style={{ overflow: 'hidden', backgroundColor: '#f9faff' }}>
			<div
				className='login-screen'
				style={{ display: 'flex', justifyContent: 'center' }}
			>
				<div className='' style={{ justifyContent: 'center', display: 'grid' }}>
					<div className='tCenter'>
						<div>
							<LevvyLogo
								className='levvyLogo'
								style={{ marginTop: '80px', marginBottom: '72px' }}
							/>
						</div>
					</div>

					<div className=''>
						<div className='dfCenter'>
							<LoginForm />
						</div>
					</div>

					<div className='dfCenter'>
						<div className='' style={{ marginTop: 'auto' }}>
							<p
								style={{ marginTop: '70px', marginBottom: '2px' }}
								className='dfCenter'
							>
								Powered By
							</p>
							<p style={{ marginBottom: '15px' }}>
								<Levvy />
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
