import React, { useState } from 'react';
import FormButtonOne from '../Buttons/FormButtonOne';
import { setSnackBarIsOpen } from '../../redux/actions/dashboard/workspaces-actions';
import { useDispatch } from 'react-redux';
import * as teamActions from '../../redux/actions/team-management.actions';

const EditTeamDetailsModal = ({ onCancel, teamDetails }) => {
	const dispatch = useDispatch();
	const [details, setDetails] = useState(teamDetails);
	const [actionLoading, setActionLoading] = useState(false);
	const handleChange = (e) => {
		const { name, value } = e.target;
		setDetails(() => {
			return { ...details, [name]: value };
		});
	};

	const updateTeamProfile = (event) => {
		event.preventDefault();
		setActionLoading(true);
		const teamToEdit = { ...details };
		delete teamToEdit.user_teams;
		console.log(teamToEdit, details, 'TeamDetailsCheck');
		dispatch(teamActions.updateTeamDetails(teamToEdit))
			.then(() => {
				dispatch(teamActions.handleInputChange(details));
				setActionLoading(false);
				onCancel();
				dispatch(setSnackBarIsOpen(true, true, 'Successful!'));
			})
			.catch((error) => {
				setActionLoading(false);
				dispatch(setSnackBarIsOpen(true, true, error.message));
			});
	};

	return (
		<form onSubmit={updateTeamProfile} autoComplete='off'>
			<div className='p-3'>
				<div className='improved-input-designs pb-3'>
					<label className='require'>Name</label>
					<input
						className='w-100'
						type='text'
						name='name'
						onChange={handleChange}
						required={true}
						value={details?.name}
						placeholder='Enter Name'
						id='team-name'
					/>
				</div>
				<div className='improved-input-designs pb-3'>
					<label>Description</label>
					<textarea
						type='text'
						name='description'
						value={details?.description}
						onChange={handleChange}
						placeholder='Enter Description'
					/>
				</div>
			</div>

			<div className='VerticalSpacer bg-white p-3 rounded-bottom d-flex justify-content-between align-items-center'>
				<FormButtonOne
					onClick={onCancel}
					type='button'
					className='secondary-btn-light'
				>
					Cancel
				</FormButtonOne>
				<FormButtonOne
					className={actionLoading ? 'primary-btn opacity-50' : 'primary-btn'}
					loading={actionLoading}
					type='submit'
					data-cy='team-profile-done'
				>
					Update
				</FormButtonOne>
			</div>
		</form>
	);
};

export default EditTeamDetailsModal;
