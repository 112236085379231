import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { instance } from '../../axios/axios-config';
import { getCookie } from '../../utilities/utilities';

function HotRefreshPolling() {
	const user = useSelector((state) => state.authReducer.user);

	useEffect(() => {
		let refreshPolling = undefined;
		if (user?.accessToken) {
			refreshPolling = setInterval(async () => {
				const request = await instance.request({
					url: 'v1/auth/refresh',
					method: 'Post',
					data: {
						refresh_token: getCookie('refresh_token'),
					},
				});

				if (request?.data?._v != user._v) window.location.reload(true);
			}, 60000);
		}
		return () => {
			if (refreshPolling) clearInterval(refreshPolling);
		};
	}, [user?.accessToken]);

	return null;
}

export default HotRefreshPolling;
