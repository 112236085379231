/* eslint-disable no-mixed-spaces-and-tabs */
// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect, useMemo } from 'react';

import TableHeader from '../Others/TableHeader';
import TableHeaderItem from '../Others/TableHeaderItem';
import {
	TableItemWidth2,
	TableItemWidth3,
} from '../TableSpacing/TableSpacingStyles';
import useModal from '../Modal/useModal';
import DummyModal from '../Modal/DummyModal';
import AddContact from './AddContact';
import AddIcon from '@mui/icons-material/Add';
import { Modal } from '@mui/material';
import { useSelector } from 'react-redux';
import { standardPermissions } from '../../utilities/utilities';
import { ReactComponent as ClientContact } from '../../assets/icons/client-contacts.svg';
import Restricted from '../Permissions/Restricted';
import ClientContactRow from './ClientContactRow';

function ClientContacts() {
	const client = useSelector((state) => state.clientReducer.client);

	const userTeamRole = useSelector((state) => state.clientReducer.userTeamRole);

	const { modalIsOpen, toggleModal, ModalName, ModalType } = useModal();

	return (
		<>
			<div
				className='d-flex flex-column w-100 align-items-center'
				style={{ gap: '12px' }}
			>
				<span className='text-left w-100 d-flex align-items-center information-header'>
					<span className='d-flex' style={{ gap: '12px' }}>
						<ClientContact />
						<h3 className='HeadlineThreeBold'>Client Contacts</h3>
					</span>
				</span>

				<div className='w-100 d-flex flex-column overflow-auto shadow-border'>
					<div
						style={{
							minWidth: '70vw',
							width: '100%',
						}}
					>
						<TableHeader ClassName='TableHeader flex' border={false}>
							<TableHeaderItem label='Name' width={TableItemWidth3} />
							<TableHeaderItem label='Title' width={TableItemWidth3} />
							<TableHeaderItem label='Email' width={TableItemWidth3} />
							<TableHeaderItem label='Phone' width={TableItemWidth3} />
							<TableHeaderItem label='Mobile' width={TableItemWidth3} />
							<TableHeaderItem label='Address' width={TableItemWidth3} />
							<TableHeaderItem label='Extras' width={TableItemWidth3} />
							<TableHeaderItem label='Notes' width={TableItemWidth3} />

							<Restricted
								AllowedUsers={standardPermissions}
								userTeamRole={[userTeamRole]}
							>
								<TableHeaderItem
									ClassName='TProfile mr-2'
									label='Action'
									width={TableItemWidth2}
								/>
							</Restricted>
						</TableHeader>

						{client?.client_contact_list?.length < 1 ? (
							<div className='TableRow h-100 w-100 d-flex align-items-center justify-content-center'>
								<h4 className='BodyTwoLight m-0 placeholder-text'>
									The Client has no contacts
								</h4>
							</div>
						) : (
							client?.client_contact_list?.map((clientContact) => {
								return (
									<ClientContactRow
										userTeamRole={userTeamRole}
										clientContact={clientContact}
										key={clientContact.id}
									/>
								);
							})
						)}
					</div>

					<Restricted
						AllowedUsers={standardPermissions}
						userTeamRole={[userTeamRole]}
					>
						<div
							className='d-flex justify-content-center information-header d-flex align-items-center'
							style={{ minWidth: '70vw', width: '100%' }}
						>
							<div
								className='pointer d-flex flex-row'
								style={{
									height: '22px',
									width: '6px',
									borderRadius: '3px',
									marginRight: '1rem',
									padding: 0,
									position: 'relative',
								}}
								onClick={() => {
									ModalType('NEW_CONTACT_MODAL');
									toggleModal();
								}}
							>
								<AddIcon
									fontSize='small'
									sx={{
										color: '#fb7a03',
										fontSize: 21,
										position: 'absolute',
										margin: 0,
										padding: 0,
										top: 0,
										left: 0,
									}}
								/>
							</div>
							<h3
								data-cy='add-client-contact-btn'
								className='BodyTwoLight mt-2 dark-text'
								style={{ color: '#FB7A03', cursor: 'pointer' }}
								onClick={() => {
									ModalType('NEW_CONTACT_MODAL');
									toggleModal();
								}}
							>
								Add New Contact Details
							</h3>
						</div>
					</Restricted>
				</div>
			</div>

			{modalIsOpen && (
				<Modal
					onClose={toggleModal}
					open={modalIsOpen}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<>
						{ModalName == 'NEW_CONTACT_MODAL' && (
							<DummyModal title='Add client contact' onClose={toggleModal}>
								<AddContact onCancel={toggleModal} />
							</DummyModal>
						)}
					</>
				</Modal>
			)}
		</>
	);
}

export default ClientContacts;
