import { Avatar } from '@mui/material';
import { memo, useEffect, useState } from 'react';
import { getAvatarLabel } from '../../utilities/utilities';
import { ReactComponent as CalendarIcon } from '../../assets/icons/Calendarcolor.svg';
import { ReactComponent as StatusIcon } from '../../assets/icons/EllipseColor.svg';
import moment from 'moment';
import { Emoji } from 'emoji-picker-react';
import { Input } from '../Inputs/Input';
import { KeyboardDatePicker } from '@material-ui/pickers';
// import { set } from 'date-fns/esm';

const FilterMultiplePickList = ({
	className,
	label,
	options,
	onChange,
	onClick,
	expandable,
	placeholder,
	InputIcon,
	input,
}) => {
	const [listLimit, setListLimit] = useState(expandable ? 5 : options.length);
	const listDifference =
		options?.length - options?.filter((item, index) => index < listLimit)?.length;

	const handleListLimit = () => {
		listDifference > 0 ? setListLimit(options?.length) : setListLimit(5);
		// listDifference > 0
		//     ? setListLimit(listLimit + 4)
		//     : setListLimit(listLimit - 4);
	};

	useEffect(() => {
		if (expandable) {
			setListLimit(5);
		} else {
			setListLimit(options?.length);
		}
	}, [options?.length]);

	return (
		<div className='TeamMultiPickList'>
			<div className={`TeamMultiPickList ${className}`} label={label}>
				{input ? (
					<div
						className='d-flex align-items-center p-2'
						style={{ backgroundColor: 'rgba(0, 0, 0, 0.25)' }}
					>
						<Input
							style={{
								width: '100%',
								fontFamily: 'CentraLight',
								fontWeight: 'light',
								fontSize: '14px !important',
								lineHeight: '18px',
								color: 'white',
								backgroundColor: 'transparent',
								padding: '5px',
								marginTop: '-3px',
								border: 'none',
							}}
							size='small'
							placeholder={placeholder}
						/>
						{InputIcon}
					</div>
				) : (
					<div></div>
				)}
				{options
					?.filter((item, index) => index < listLimit)
					?.map((choice, index) => {
						return (
							<div key={index} className='ItemWrapper'>
								<div
									className='w-100 pointer d-flex align-items-center'
									onClick={onClick ? () => onClick(choice.value) : null}
									title={choice.label}
								>
									{choice.image !== undefined && (
										<div className='position-relative'>
											<Avatar
												sx={{
													bgcolor: `#FEF2E7`,
													color: `#333`,
													marginRight: '10px',
													height: '23px',
													width: '23px',
													fontSize: '0.8rem',
												}}
												alt={choice.label}
												src={choice.image ? choice.image : ''}
											>
												{getAvatarLabel(choice.label)}
											</Avatar>
											<div
												className='position-absolute'
												style={{ top: 6, right: 9 }}
											>
												{choice?.is_online && 
													<StatusIcon width={10} height={10} />}
											</div>
										</div>
									)}
									<h4
										data-toggle='tooltip'
										data-placement='top'
										style={{ marginRight: '7px' }}
										title={choice.label}
										className='BodyTwoLight'
									>
										{choice.label.length > 24
											? choice.label.substring(0, 24) + '...'
											: choice.label}
									</h4>
									{choice?.leave_requests?.length > 0 &&
										choice?.leave_requests?.filter(
											(request) =>
												request.status === 'APPROVED' &&
												request?.leaves?.find(
													(leave) =>
														moment(leave.leave_date)?.format(
															'YYYY-MM-DD',
														) === moment()?.format('YYYY-MM-DD'),
												),
										)?.length > 0 && (
											<div>
												<CalendarIcon />
											</div>
										)}
									{choice?.user_statuses?.length > 0 &&
										choice?.user_statuses
											?.filter((a) => a.is_away === true)
											?.map((item, key) => {
												return (
													<div
														key={key}
														data-toggle='tooltip'
														data-placement='top'
														title={item.status}
													>
														<Emoji unified={item.icon} size='20' />
													</div>
												);
											})}
								</div>
								<input
									type='checkbox'
									className='multi-select-checkbox'
									onChange={onChange}
									value={choice.value}
									checked={choice.is_checked}
									// style={{ opacity: choice.is_active === false && 0.5 }}
								/>
							</div>
						);
					})}
			</div>

			{expandable && (
				<div
					onClick={options?.length <= 5 ? null : handleListLimit}
					className='BodyThreeLight primary-text text-left w-100 pointer'
				>
					{options?.length <= 5 ? '' : listDifference > 0 ? 'Show More' : 'Show Less'}
				</div>
			)}
		</div>
	);
};

export default memo(FilterMultiplePickList);
