import TableHeader from '../../Others/TableHeader';
import TableHeaderItem from '../../Others/TableHeaderItem';
import { TableItemWidth5 } from '../../TableSpacing/TableSpacingStyles';
import TableRowItem from '../../Others/TableRowItem';
import FormButtonOne from '../../Buttons/FormButtonOne';
import moment from 'moment';
import { numberWithCommas } from '../../../utilities/utilities';
import { useSelector } from 'react-redux';
import { getUserSettings } from '../../../hooks/getUserSettings';

function UserBillingHistory({ onCancel, data, currencySymbol }) {
	const tableHeaders = [
		'Salary Terms',
		'Start Date',
		'End Date',
		'Benefits',
		'Salary',
		'Salary/Hour',
	];
    const userDetails = useSelector((state) => state.authReducer.userDetails);
    const { date_format }  = getUserSettings(userDetails);

	return (
		<div className='billing-history-modal'>
			<div style={{ height: '90%' }} className='table-body'>
				<div className='table-body-child'>
					<div style={{ padding: '16px 24px 0px 18px' }}>
						<span className='HeadlineTwoBold'>History </span>
					</div>
					<div className='profile-section-body'>
						<div className='billing-history-body'>
							<TableHeader ClassName='new-table-header' border={false}>
								{tableHeaders?.map((header, key) => {
									return (
										<TableHeaderItem
											key={key}
											label={header}
											width={TableItemWidth5}
										/>
									);
								})}
							</TableHeader>
							<div className='ListingLoading scroll'>
								{data?.map((user, key) => {
									return (
										<div
											className='new-table-row d-flex align-items-center'
											key={key}
										>
											<TableRowItem
												ClassName='text-left'
												label={user?.cost_mode}
												width={TableItemWidth5}
												input={false}
											/>
											<TableRowItem
												ClassName='text-left'
												label={moment(user?.bill_start_date).format(date_format)}
												width={TableItemWidth5}
												input={false}
											/>
											<TableRowItem
												ClassName='text-left'
												label={
													user?.bill_end_date
														? moment(user?.bill_end_date).format(date_format)
														: user?.bill_end_date
												}
												width={TableItemWidth5}
												input={false}
											/>
											<TableRowItem
												ClassName='text-left'
												label={
													currencySymbol +
													numberWithCommas(user?.job_benefits)
												}
												width={TableItemWidth5}
												input={false}
											/>
											<TableRowItem
												ClassName='text-left'
												label={
													currencySymbol +
													numberWithCommas(user?.annual_salary)
												}
												width={TableItemWidth5}
												input={false}
											/>
											<TableRowItem
												ClassName='text-left'
												label={
													currencySymbol +
													numberWithCommas(user?.cost_per_hour)
												}
												width={TableItemWidth5}
												input={false}
											/>
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div style={{ height: '10%' }} className='billing-history-actions'>
				<FormButtonOne onClick={onCancel} type='button' className='secondary-btn-light'>
					Close
				</FormButtonOne>
			</div>
		</div>
	);
}

export default UserBillingHistory;
