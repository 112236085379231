import React from 'react';

function SimpleButton({ clickHandler, children, text, ClassName, style, ...props }) {
	return (
		<button
			style={style}
			id='Element'
			className={ClassName + ' simple'}
			onClick={clickHandler}
			{...props}
		>
			{text}
		</button>
	);
}

export default SimpleButton;
