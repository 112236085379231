import React from 'react';
import Logo from '../../assets/icons/Group 107.svg';

export default function AppComponent({
	logo,
	appName,
	details,
	connected,
	onClick,
	handleRemoveAccess,
}) {
	return (
		<div
			className='d-flex align-items-center justify-content-between'
			style={{
				width: '100%',
				height: 'auto',
				padding: '16px',
				border: '1px solid #EAEAEA',
				backgroundColor: 'white',
			}}
		>
			<div className='d-flex align-items-center'>
				{/* <div> */}
				<img src={logo || Logo} alt='logo' style={{ width: '32px', height: '32px' }} />
				{/* </div> */}
				<div style={{ paddingLeft: '16px' }}>
					<div className='d-flex'>
						<span className='BodyOneBold'>{appName || 'Slack'}</span>
					</div>
					<div className='d-flex text-justify'>
						<span className='BodyTwoLight'>
							Connect with Slack to get notified when you are mentioned in a comment,
							when your task is approved and other relevant updates.{' '}
							<a
								className='primary-text'
								target='_blank'
								rel='noreferrer'
								href='https://help.levvy.com/en/articles/8638187-how-to-use-levvy-slack-integration'
							>
								Learn more.
							</a>
						</span>
					</div>
				</div>
			</div>
			<div className='d-flex' style={{ marginLeft: '80px', cursor: 'pointer' }}>
				{connected ? (
					<span
						className='BodyTwoLight'
						style={{ color: '#F05544' }}
						onClick={handleRemoveAccess}
					>
						Remove Access
					</span>
				) : (
					<span className='BodyTwoLight' style={{ color: '#F05544' }} onClick={onClick}>
						Connect
					</span>
				)}
			</div>
		</div>
	);
}
