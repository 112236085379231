import { Avatar } from '@mui/material';
import React from 'react';
import ProgressIcon from '../Icons/ProgressIcon';

function CompanyTopBar({ clientInfo }) {
	return (
		<div className='Profile-TopBar'>
			<div className='d-flex flex-row align-items-center'>
				{/* <Avatar
					sx={{
						bgcolor: `#FEF2E7`,
						color: `#333`,
						marginRight: '10px',
						height: '25px',
						width: '25px',
						fontSize: '0.8rem',
					}}
					alt={clientInfo.name}
					src={clientInfo.image}
				/> */}
				<div className='d-flex flex-column' style={{ gap: '1px' }}>
					<h6 className='HeadlineThreeBold font-weight-bolder light-dark w-100 d-flex justify-content-start'>
						{clientInfo.name}
					</h6>
					<h6 className='light-dark BodyThree text-left'>{clientInfo.team}</h6>
				</div>
			</div>
		</div>
	);
}

export default CompanyTopBar;
