import { memo, useState } from 'react';
import FormButtonOne from '../Buttons/FormButtonOne';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from '../../redux/actions/dashboard/workspaces-actions';
import { Member } from '../../utilities/utilities';
import RequestDocumentModal from './RequestDocumentModal';

function RequestDocumentInvite({
	parentFolderId,
	clientId,
	clientWorkflowName,
	isUserClientsTeamManager,
	isAdhoc,
}) {
	const dispatch = useDispatch();

	const [inviteClientLoading, setInviteClinetLoading] = useState(false);
	const [clientInviteLink, setClinetInviteLink] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);

	const user = useSelector((state) => state.authReducer.user);

	const handleInviteClient = () => {
		setInviteClinetLoading(true);
		const payload = {
			parent_folder_id: parentFolderId,
			client_id: clientId,
			client_workflow_name: clientWorkflowName,
			requested_url: `${window.location.origin}/upload-document`,
		};
		dispatch(Actions.getInviteClientLink(payload))
			.then((response) => {
				setIsModalOpen(true);
				setInviteClinetLoading(false);
				setClinetInviteLink(response);
			})
			.catch((error) => {
				setInviteClinetLoading(false);
				dispatch(Actions.setSnackBarIsOpen(false, true, error.message));
			});
	};
	return (
		<>
			{(user?.userRole?.id === Member.ownerId ||
				user?.userRole?.id === Member.supervisorId ||
				isUserClientsTeamManager) &&
				!isAdhoc && (
					<FormButtonOne
						className='primary-btn rounded-btn BodyTwoBold'
						style={{
							minWidth: '200px',
							height: '32px',
						}}
						onClick={handleInviteClient}
						disabled={inviteClientLoading || !parentFolderId}
						loading={inviteClientLoading}
					>
						Request Files from Clients
					</FormButtonOne>
				)}
			{isModalOpen && (
				<RequestDocumentModal
					open={isModalOpen}
					inviteLink={clientInviteLink}
					closeHandler={() => setIsModalOpen(false)}
				/>
			)}
		</>
	);
}

export default memo(RequestDocumentInvite);
