import { EventSourcePolyfill } from 'event-source-polyfill';
import store from '../index';
import * as workSpaceActions from '../redux/actions/dashboard/workspaces-actions';
import baseURL from '../utilities/BaseUrl';

const connect = () => {
	const state = store.getState();
	const user = state.authReducer.user;
	const eventSource = new EventSourcePolyfill(`${baseURL}/v1/workspace/stream`, {
		headers: {
			Authorization: `Bearer ${user.accessToken}`,
		},
	});
	store.dispatch(workSpaceActions.setEventSource(eventSource));
	return eventSource;
};

const disconnect = (event_source) => {
	store.dispatch(workSpaceActions.setEventSource(undefined));
	event_source.close();
};

const addListener = (event, eventListener, event_source) => {
	event_source.addEventListener(event, eventListener);
};

const removeListener = (event, eventListener, event_source) => {
	event_source.removeEventListener(event, eventListener);
};

export default { connect, disconnect, addListener, removeListener };
