import React from 'react';
import RichTextEditor from '../../Inputs/RichTextEditor';
import { CircularProgress } from '@mui/material';

function TaskDescriptionModal({
	OnCancel,
	Onsubmit,
	Edit,
	value,
	onChange,
	type,
	richTextStyle,
	isLoading
}) {
	return (
		<div className='d-flex flex-column' style={{ gap: '2em' }}>
			<div className='p-3'>
				<RichTextEditor
					type={type}
					Edit={Edit}
					value={value}
					taskDescription
					onChange={onChange}
					style={{
						...richTextStyle,
						wordWrap: 'break-word',
						textAlign: 'left',
						marginBottom: '3rem',
						borderRadius: '4px',
					}}
				/>
			</div>
			<div className='d-flex justify-content-between verticalSpacer bg-white p-3 rounded-bottom'>
				<button id='Element' className='secondary' onClick={OnCancel}>
					Cancel
				</button>
				{Edit && (
					<button
						data-cy='confirm-btn'
						className='d-flex align-items-center'
						id='Element'
						disabled={isLoading}
						onClick={Onsubmit}
					>
						{isLoading ? (
							<CircularProgress
								size={24}
								sx={{ color: 'white' }}
								disableShrink
							/>
						) : (
							'Done'
						)}
					</button>
				)}
			</div>
			{/* <SubmitButtonsField
			onCancel={OnCancel}
			onSubmit={Onsubmit}
            title='Done'
			/> */}
		</div>
	);
}

export default TaskDescriptionModal;
