import React, { useState, useEffect } from 'react';
const CommentsSection = ({ comment }) => {
  const [text, setText] = useState('');

  useEffect(() => {
    if (comment !== '') {
      const regex = /@\[.+?\]\(.+?\)/gm;
      const displayRegex = /@\[.+?\]/g;
      const idRegex = /\(.+?\)/g;
      const matches = comment?.match(regex);
      const arr = [];
      matches &&
        matches.forEach((m) => {
          const id = m.match(idRegex)[0].replace('(', ' ').replace(')', ' ');
          const display = m
            .match(displayRegex)[0]
            .replace('@[', ' ')
            .replace(']', ' ');

          arr.push({ id: id, display: display });
        });
      const newComment = comment?.split(regex);
      let output = '';
      for (let i = 0; i < newComment.length; i++) {
        const c = newComment[i];
        if (i === newComment.length - 1) output += c;
        else
          output += c + ` <a href="/user-profile/${arr[i].id}"> ${arr[i].display} </a>`;
      }
      setText(output);
    }
  }, [comment]);

  return (
    <div>
      <span
        className="d-inline BodyTwoLight"
        dangerouslySetInnerHTML={{
          __html: text.replace(/\n\r?/g, '<br />')
        }}
      />
    </div>
  );
};
export default CommentsSection;
