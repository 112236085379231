import React from 'react';
import { ReactComponent as ZapierIcon } from '../../assets/icons/ZapierIcon.svg';

function ZapierZapHeadSection({ name }) {
	return (
		<div>
			<div className='Zapier-Head-Section'>
				<div className='d-flex flex-row align-items-center '>
					<div
						className='d-flex flex-row justify-content-between'
						style={{ width: '120px' }}
					>
						<ZapierIcon />
						<h6 className='HeadlineTwoBold'>{name}</h6>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ZapierZapHeadSection;
