import { useCallback, useEffect, useState } from 'react';
import SubmitButtonsField from '../Buttons/SubmitButtonsField';
import { formatRateValue } from '../../utilities/utilities';

export default function AddLineItemModal({ title, OnCancel, Onsubmit, isLoading, selectedItem, activeClientbilling }) {
	const Style = {
		backgroundColor: 'white',
		border: 'none',
		borderBottom: '1px solid #28383C',
	};

	const [form, setForm] = useState({
		description: '',
		unit_amount: '',
		quantity: '',
	});


	const unitPrice = Number(String(form?.unit_amount || '0').replace(/,/g, '')) || 0;

	const onChange = useCallback(
		(e) => {
			const { value, name } = e.target;
			const updatedForm = { ...form };

			if (['description', 'quantity'].includes(name)) {
				updatedForm[name] = value;
			}

			if (name === 'unit_amount') {
				const valueString = value.toString();
				const formattedValue = formatRateValue(valueString);
				updatedForm[name] = formattedValue;
			}

			setForm(updatedForm);
		},
		[form, setForm],
	);

	const formSubmit = (event) => {
		event.preventDefault();

		const unitAmount = Number(String(form?.unit_amount || '0').replace(/,/g, '')) || 0;
		const quantity = form?.quantity || 0;

		const totalAmount = unitAmount * quantity;

		const updatedForm = {
			...form,
			unit_amount: unitAmount,
			quantity: quantity,
			total_amount: Number(totalAmount.toFixed(2)),
		};

		if (updatedForm._id === null) {
			delete updatedForm._id;
		}
		if (updatedForm._tempId === null) {
			delete updatedForm._tempId;
		}

		Onsubmit(updatedForm);

		setForm({
			description: '',
			unit_amount: '',
			quantity: '',
		});
	};

	useEffect(() => {
		if (selectedItem) {
			setForm({
				description: selectedItem.description || '',
				unit_amount: selectedItem.unit_amount || '',
				quantity: selectedItem.quantity || '',
				_id: selectedItem._id || null,
				_tempId: selectedItem._id ? null : selectedItem._tempId,
			});
		} else {
			setForm({
				description: '',
				unit_amount: activeClientbilling?.rate || '',
				quantity: 1,
				_id: null,
				_tempId: null,
			});
		}
	}, [selectedItem]);

	return (
		<div className='VerticalSpacer'>
			<div className='p-3'>
				<div className='improved-input-designs pb-3'>
					<label className='require w-100'>Service Name</label>
					<input
						id='description'
						className='w-100'
						type='text'
						name='description'
						onChange={onChange}
						style={Style}
						value={form?.description}
						required
					/>
				</div>

				<div className='improved-input-designs pb-3'>
					<label className='require'>Unit Price</label>
					<input
						id='unit_price'
						className='w-100'
						type='text'
						name='unit_amount'
						style={Style}
						onChange={onChange}
						value={`$${form?.unit_amount ? form?.unit_amount : 0}`}
						required
					/>
				</div>
				<div className='improved-input-designs pb-3'>
					<label className='require'>Quantity</label>
					<input
						id='quantity'
						className='w-100'
						type='number'
						name='quantity'
						style={Style}
						onChange={onChange}
						value={form.quantity}
						required
					/>
				</div>
				<div className='improved-input-designs pb-3'>
					<label className=''>Total Amount</label>
					<input
						id='total_amount'
						className='w-100'
						type='text'
						name='total_amount'
						style={Style}
						disabled
						value={`$${(unitPrice * form?.quantity).toFixed(2) || 0}`}
					/>
				</div>
			</div>
			<SubmitButtonsField
				rootClass='bg-white p-3'
				title={title}
				onCancel={OnCancel}
				onSubmit={formSubmit}
				isLoading={isLoading}
			/>
		</div>
	);
}
