import { instance, secure_instance } from '../../axios/axios-config';

export const HANDLE_GET_STRIPE_PRODUCTS =
	'[SUBSCRIPTION] HANDLE_GET_STRIPE_PRODUCTS';
export const HANDLE_LOADING_PRODUCTS = '[SUBSCRIPTION] HANDLE_LOADING_PRODUCTS';
export const HANDLE_LOADING_PRORATION =
	'[SUBSCRIPTION] HANDLE_LOADING_PRORATION';
export const HANDLE_SELECTED_PRICING = '[SUBSCRIPTION] HANDLE_SELECTED_PRICING';
export const SET_ERROR = '[SUBSCRIPTION] SET_ERROR';
export const SET_COUPON_DETAILS = '[SUBSCRIPTION] SET_COUPON_DETAILS';
export const SET_LOADING = '[SUBSCRIPTION] SET_LOADING';

export const getStripeProducts = () => {
	return async (dispatch) => {
		try {
			dispatch({
				type: HANDLE_LOADING_PRODUCTS,
				payload: true,
			});

			const request = await instance.request({
				url: '/v1/stripe_products/?expand=[stripe_pricings]',
				method: 'GET',
			});

			console.log(request.data);

			dispatch({
				type: HANDLE_GET_STRIPE_PRODUCTS,
				payload: request.data,
			});
		} catch (error) {
			console.log({ error });
			// window.location.href = '/';
		}
	};
};

export const setLoading = (state) => {
	return (dispatch) => {
		dispatch({
			type: SET_LOADING,
			payload: state,
		});
	};
};

export const setSelectedPricing = (pricing_id) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: HANDLE_SELECTED_PRICING,
				payload: pricing_id,
			});
		} catch (error) {
			console.log({ error });
			// window.location.href = '/';
		}
	};
};

export const applyCoupon = (priceId, couponCode) => {
	return async (dispatch) => {
		try {
			dispatch(setError(''));
			dispatch(setLoading(true));

			const request = await secure_instance.request({
				url: `v1/stripe_customers/coupon_details/${couponCode}`,
				method: 'get',
			});

			dispatch({
				type: SET_COUPON_DETAILS,
				payload: request.data,
			});
			dispatch(setLoading(false));
		} catch (error) {
			//
			dispatch({
				type: SET_COUPON_DETAILS,
				payload: null,
			});
			dispatch(setError(error?.response?.data?.message));
			dispatch(setLoading(false));
		}
	};
};

export const setError = (error) => {
	return (dispatch) => {
		dispatch({
			type: SET_ERROR,
			payload: error,
		});
	};
};

// export const getStripeSubscriptionProration = (stripe_pricing_id, coupon) => {
// 	return async (dispatch) => {
// 		try {

// 			dispatch({
// 				type: HANDLE_LOADING_PRORATION,
// 				payload: true,
// 			});

// 			const request = await secure_instance.request({
// 				url: '/v1/stripe_customers/invoice_proration/',
// 				method: 'POST',
// 				data: {
// 					stripe_pricing_id,
// 					coupon
// 				}
// 			});

// 			dispatch({
// 				type: HANDLE_STRIPE_SUBSCRIPTION_PRORATION,
// 				payload: request.data,
// 			});

// 		} catch (error) {
// 			console.log({ error });
// 			// window.location.href = '/';
// 		}
// 	};
// };
