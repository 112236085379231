import { CircularProgress, Input, InputAdornment } from '@mui/material';
import React from 'react';
import { ReactComponent as SearchIcon } from '../../assets/icons/MagnifyingGlass.svg';

const Search = React.forwardRef(({ onChange, value, loading, ...props }, ref) =>
(
    <Input
        inputRef={ref}
        onChange={onChange}
        value={value}
        autoComplete='off'
        className='pl-1'
        disableUnderline={true}
        id='input-with-icon-adornment'
        inputProps={{ style: { fontSize: 14 } }}
        startAdornment={
            <InputAdornment position='start'>
                {loading ? (
                    <CircularProgress
                        style={{
                            color: '#fa7b03',
                            height: '17px',
                            width: '17px',
                        }}
                    />
                ) : (
                    <SearchIcon />
                )}
            </InputAdornment>
        }
        {...props}
    />
)
);

Search.displayName = 'Search';

export default Search;
