import Dialog from '@material-ui/core/Dialog';
import { Zoom } from '@mui/material';
import React from 'react';
import FormButtonOne from '../Buttons/FormButtonOne';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Zoom in={true} ref={ref} {...props} style={{ transitionDelay: '50ms' }} />;
});
const TaskAlert = ({
	message,
	open,
	closeHandler,
	onDone,
	header,
	doneButton,
	closeButton,
}) => {
	return (
		<Dialog
			// PaperProps={{ style: { position: 'absolute', top: -30 } }}
			TransitionComponent={Transition}
			open={open}
			onClose={closeHandler}
            disableEnforceFocus
		>
			<div className='my-task-delete-alert'>
				<div className='content'>
					<div
						className='BodyOneBold dark-text form-title'
						style={{ lineHeight: '1.6' }}
					>
						{header}
					</div>
					<div className='d-flex flex-column w-100'>
						<div className='BodyTwoLight dark-text text-left'>{message}</div>
					</div>
				</div>
				<div className='d-flex action-buttons w-100 f-flex'>
					<FormButtonOne
						onClick={closeHandler}
						type='button'
						className={'secondary-btn-light'}
					>
						{closeButton}
					</FormButtonOne>
					{doneButton && doneButton?.length > 0 && (
						<FormButtonOne onClick={onDone} type='button'>
							{doneButton}
						</FormButtonOne>
					)}
				</div>
			</div>
		</Dialog>
	);
};

export default TaskAlert;
