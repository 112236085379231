import React, { useEffect, useRef } from 'react';
import { ReactComponent as LeftIcon } from '../../assets/icons/chevron-left-large.svg';
import { ReactComponent as RightIcon } from '../../assets/icons/chevron-right-large.svg';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from '../../redux/actions/dashboard/workspaces-actions';
import moment from 'moment';
import { generateWeekDatesFromDate, getMonthlyDate } from '../../utilities/utilities';
import TeamMembersAvailability from './Task Modal/TeamMembersAvailability';
import Popper from '@mui/material/Popper';

const WorkspaceDateNavigation = ({ dragOverContainer }) => {
	const dispatch = useDispatch();
	const monthlyView = useSelector((state) => state.workSpaceReducer.monthlyView);
	const today = moment().format('MM/DD/YYYY');

	const weekDate = useSelector((state) => state.workSpaceReducer.start_date);
	const weekData = useSelector((state) => state.workSpaceReducer.weekDate);
	const weekDaysOff = useSelector((state) => state.workSpaceReducer.weekDaysOff);
	const thisWeek = moment(today).startOf('isoWeek').isSame(weekDate);
    let hoverTimeout = undefined;

	const [anchorEl, setAnchorEl] = React.useState(null);
	const popperRelativeElement = useRef(null);

	const openPopper = (date) => (event) => {
        hoverTimeout && clearTimeout(hoverTimeout);
        popperRelativeElement.current = event.target;
        
		if (monthlyView) return;

        hoverTimeout = setTimeout(() => {
            setAnchorEl(date);
        }, 1000);
	};

	const closePopper = () => {
		setAnchorEl(null);
	};

    const handleMouseLeave = () => {
        hoverTimeout && clearTimeout(hoverTimeout);
    };

	function getDateFromLocalStorage() {
		const storedDate = localStorage.getItem(
			monthlyView ? 'WORKSPACE_MONTH_DATE' : 'WORKSPACE_DATE',
		);
		if (storedDate) {
			return storedDate;
		}
		const mondayDate = moment().isoWeekday(1);
		const currentDate = mondayDate.format('YYYY-MM-DD');
		if (monthlyView) {
			localStorage.setItem('WORKSPACE_MONTH_DATE', moment(mondayDate));
		} else {
			localStorage.setItem('WORKSPACE_DATE', currentDate);
		}
		return currentDate;
	}

	function getDates(date) {
		const start_date = monthlyView
			? moment(date).startOf('month').format('YYYY-MM-DD')
			: moment(date).startOf('isoWeek').format('YYYY-MM-DD');
		const end_date = monthlyView
			? moment(date).endOf('month').format('YYYY-MM-DD')
			: weekDaysOff
			? moment(date).endOf('isoWeek').format('YYYY-MM-DD')
			: moment(date).endOf('isoWeek').day(-2).format('YYYY-MM-DD');
		return { start_date, end_date };
	}

	const handleNavigation = (direction) => () => {
		// dispatch(Actions.resetWorkspaceTasks());
		if (direction == 'left') {
			if (monthlyView) {
				const newDate = moment(getDateFromLocalStorage()).clone().subtract(1, 'month');

				dispatch(Actions.setMonthlyDate(getMonthlyDate(newDate)));
				localStorage.setItem('WORKSPACE_MONTH_DATE', newDate);
			} else {
				const newDate = moment(weekDate)
					.subtract(1, monthlyView ? 'month' : 'week')
					.format('YYYY-MM-DD');
				const { start_date, end_date } = getDates(newDate);
				dispatch(Actions.setDates(start_date, end_date));
				localStorage.setItem('WORKSPACE_DATE', newDate);
			}
		}
		if (direction == 'right') {
			if (monthlyView) {
				const newDate = moment(getDateFromLocalStorage()).clone().add(1, 'month');
				dispatch(Actions.setMonthlyDate(getMonthlyDate(newDate)));
				localStorage.setItem('WORKSPACE_MONTH_DATE', newDate);
			} else {
				const newDate = moment(weekDate)
					.add(1, monthlyView ? 'month' : 'week')
					.format('YYYY-MM-DD');
				const { start_date, end_date } = getDates(newDate);
				dispatch(Actions.setDates(start_date, end_date));
				localStorage.setItem('WORKSPACE_DATE', newDate);
			}
		}
	};

	const handleThisWeek = () => {
		// dispatch(Actions.resetWorkspaceTasks());
		const newDate = moment().startOf('isoweek').toDate();
		const { start_date, end_date } = getDates(newDate);
		dispatch(Actions.setDates(start_date, end_date));
		localStorage.setItem('WORKSPACE_DATE', newDate);
	};

	useEffect(() => {
		const date = getDateFromLocalStorage();
		if (monthlyView) {
			dispatch(Actions.setMonthlyDate(getMonthlyDate(date)));
		} else {
			const { start_date, end_date } = getDates(date);
			dispatch(Actions.setDates(start_date, end_date));
		}
	}, [weekDaysOff]);

	useEffect(() => {
		const { endOfWorkWeek, endOfFullWeek, daysOfTheWeek, beginningOfWeek } =
			generateWeekDatesFromDate(moment(weekDate).startOf('isoWeek'), weekDaysOff);
		dispatch(
			Actions.setWeekData({
				endOfWorkWeek,
				endOfFullWeek,
				daysOfTheWeek,
				beginningOfWeek,
			}),
		);
	}, [weekDaysOff, monthlyView, weekDate]);

	return (
		<div
			className='d-flex flex-column'
			style={{
				position: 'sticky',
				top: 0.1,
				zIndex: 9,
				borderTopRightRadius: '4px',
				borderTopLeftRadius: '4px',
				backgroundColor: '#FCFDFD',
			}}
		>
			<div className='d-flex position-relative'>
				<div
					style={{ left: 0, zIndex: 1 }}
					className='h-100 d-flex flex-row justify-content-center align-items-center position-absolute pointer'
				>
					<LeftIcon onClick={handleNavigation('left')} />
				</div>
				{weekData?.daysOfTheWeek?.map((day, key) => {
					const open = Boolean(anchorEl == day.toString());

					return (
						<div
							className='d-flex pointer justify-content-center'
							key={key}
							style={{
								height: '',
								width: `${100 / weekData?.daysOfTheWeek?.length}%`,
							}}
						>
							<div
								onMouseLeave={handleMouseLeave}
								onMouseEnter={openPopper(day.toString())}
								key={day.format('X')}
								style={{
									borderTopRightRadius: '4px',
									borderTopLeftRadius: '4px',
									backgroundColor: '#FCFDFD',
									width: 'fit-content',
								}}
								className={`BodyOneBold pt-1 date-format ${
									day.isSame(today, 'day') ? ' primary-text' : ''
								} ${
									moment(dragOverContainer)?.isSame(day, 'day')
										? 'primary-text'
										: ''
								}`}
							>
								{monthlyView ? day.format('ddd') : day.format('ddd, MMM DD')}
							</div>

							<Popper
								open={open}
								anchorEl={popperRelativeElement.current}
								onClose={closePopper}
                                style={{zIndex: 9}}
							>
								<TeamMembersAvailability
									hoverOverDate={day}
									onClose={closePopper}
								/>
							</Popper>
						</div>
					);
				})}
				<div
					style={{ right: 0 }}
					className='h-100 d-flex flex-row justify-content-center align-items-center  position-absolute pointer'
				>
					<RightIcon onClick={handleNavigation('right')} />
				</div>
			</div>

			{!thisWeek && !monthlyView && (
				<div
					className='w-100 d-flex px-2 pointer'
					style={{
						justifyContent: moment(today).isBefore(weekDate, 'week')
							? 'start'
							: moment(today).isAfter(weekDate, 'week')
							? 'end'
							: '',
					}}
				>
					<button onClick={handleThisWeek} className='rounded-button-1'>
                        Go to this week 
					</button>
				</div>
			)}
		</div>
	);
};

export default WorkspaceDateNavigation;
