
function ModifyingAndTerminatingOurSite() {
	return (
		<p className='BodyTwoLight'>
			We reserve the right to modify our Site or to suspend or stop providing
			all or portions of our Site at any time. You also have the right to stop
			using our Site at any time. We are not responsible for any loss or harm
			related to your inability to access or use our Site.
		</p>
	);
}

export default ModifyingAndTerminatingOurSite;
