/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { Zoom } from '@mui/material';
import FormButtonOne from '../Buttons/FormButtonOne';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Zoom in={true} ref={ref} {...props} style={{ transitionDelay: '50ms' }} />;
});

const NewMyTaskDeleteAlert = ({ open, closeHandler, submitHandler, taskType, loading }) => {
	const [selected, setSelected] = useState(1);

	const options = [
		{ id: 1, value: 'Delete this meeting' },
		{ id: 2, value: 'Delete this and future meetings' },
	];

	const handleChange = (event) => {
		setSelected(event.target.value);
	};

	return (
		<Dialog TransitionComponent={Transition} open={open} onClose={closeHandler}>
			<div className='my-task-delete-alert'>
				<div className='content'>
					<div className='HeadlineTwoBold dark-text form-title'>
						{taskType == 1 ? 'Delete this meeting ' : 'Delete Recurring Meetings?'}
					</div>
					<div className='d-flex flex-column w-100' style={{ gap: 10 }}>
						<div
							style={{ gap: '0.5em' }}
							className='d-flex flex-column align-items-start justify-content-start'
						>{taskType !== 1 && <label className='BodyTwoLight approval-text align-self-center w-100 m-0'>
                        This will delete the meeting for all guests.
                    </label>}
							{taskType !== 1 ? (
								options?.map((opt, i) => {
									return (
										<div
											key={i}
											style={{ gap: '1em' }}
											className='d-flex flex-row align-items-center justify-content-center'
										>
											<input
												id={opt?.id}
												type='radio'
												value={opt?.id}
												checked={opt?.id == selected}
												onChange={handleChange}
											/>
											<label className='BodyTwoLight approval-text align-self-center w-100 m-0'>
												{opt.value}
											</label>
										</div>
									);
								})
							) : (
								<label className='BodyTwoLight approval-text align-self-center w-100 m-0'>
									This will delete the meeting for all guests. Are you sure you
									want to delete this meeting?
								</label>
							)}
						</div>
					</div>
				</div>
				<div className='action-buttons'>
					<FormButtonOne
						onClick={closeHandler}
						type='button'
						className='secondary-btn-light'
					>
						Cancel
					</FormButtonOne>

					<FormButtonOne
						onClick={() => submitHandler({ delete_future: selected == 2 })}
						type='button'
						loading={loading}
					>
						Confirm
					</FormButtonOne>
				</div>
			</div>
		</Dialog>
	);
};

export default NewMyTaskDeleteAlert;
