import React, { useState } from 'react';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	tableCellClasses,
	styled,
	Modal,
} from '@mui/material';
import { ReactComponent as Info } from '../../../assets/icons/Info.svg';
import RequireToolTip from '../../Others/RequireToolTip';
import { useSelector } from 'react-redux';
import { truncateString } from '../../../utilities/utilities';
import useModal from '../../Modal/useModal';
import ReactHtmlParser from 'react-html-parser';
import DummyModal from '../../Modal/DummyModal';
import WorkflowTaskDescriptinModal from '../Modals/TaskDescriptionModal';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: '#FCFDFD',
		color: '#7C7C7C',
		fontFamily: 'Centra',
		fontSize: 12,
		fontWeight: 300,
		height: '34px',
		lineHeight: '14px',
		borderBottom: '1px solid white',
		width: 'fit-content',
	},
	[`&.${tableCellClasses.body}`]: {
		backgroundColor: '#F7F9FA',
		color: '#333333',
		fontFamily: 'Centra',
		fontSize: 12,
		fontWeight: 300,
		height: '48px',
		borderBottom: '1px solid white',
		borderTop: '1px solid white',
		width: 'fit-content',
	},
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&:nth-of-type(odd)': {
		backgroundColor: theme.palette.action.hover,
	},
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0,
	},
}));
function TasksTemplateTable({ tasks }) {

	const { modalIsOpen, toggleModal, ModalName, ModalType } = useModal();
	const [expandModal, setExpandModal] = useState({
		value: '',
		title: '',
	});

	let teamMembers = useSelector((state) => state.teamReducer.teamMembers);
	if (teamMembers?.length > 0) {
		teamMembers = teamMembers.map((assignee) => {
			return {
				...assignee,
				value: assignee.user_id,
				label: assignee.name,
			};
		});
	}

	return (
		<div>
			<Paper sx={{ width: '100%', overflow: 'hidden', boxShadow: 'none' }}>
				<TableContainer sx={{ maxHeight: 304 }}>
					<Table stickyHeader aria-label='sticky table' sx={{ minWidth: 700 }}>
						<TableHead>
							<TableRow>
								<StyledTableCell>Task</StyledTableCell>
								<StyledTableCell>Instructions</StyledTableCell>
								<StyledTableCell>Budgeted Hours</StyledTableCell>
								<StyledTableCell>Assignee</StyledTableCell>
								<StyledTableCell>Dependency</StyledTableCell>
								<StyledTableCell>
									Require Approval{' '}
									<RequireToolTip
										title='Choose an option if this task needs approval from admin or team manager'
										placement='bottom'
									>
										<Info />
									</RequireToolTip>
								</StyledTableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{tasks?.length > 0 &&
								tasks?.map((item, index) => (
									<StyledTableRow key={index}>
										<StyledTableCell>{item?.title || '-'}</StyledTableCell>
										<StyledTableCell>
										{item?.description?.length < 1 ||
											item?.description == null ||
											item?.description?.replace(/<[^>]+>/g, '')?.trim()
												?.length == 0 ? (
												'-'
											) : (
												<div
													style={{ minHeight: '14px' }}
													className='d-flex flex-column justify-content-between position-relative'
												>
													<p className='BodyTwoLight dark-text m-0 text-break'>
														{ReactHtmlParser(
															truncateString(item?.description, 28),
														)}
													</p>
													{item?.description?.length > 28 && (
														<span
															onClick={() => {
																ModalType('TASK_DESCRIPTION_MODAL');
																toggleModal();
																setExpandModal({
																	value: item?.description,
																	title: 'Description',
																});
															}}
															className='primary-text BodyThreeLight pointer'
														>
															Read More
														</span>
													)}
												</div>
											)}
										</StyledTableCell>
										<StyledTableCell>
											{item?.budgeted_hours || '-'}
										</StyledTableCell>
										<StyledTableCell>
											{teamMembers.find(
												(member) => member.value == item.assignee_user_id,
											)?.label || '-'}
										</StyledTableCell>
										<StyledTableCell>
											{tasks.find((t) => t.id === item?.dependent_task_id)
												?.title || '-'}
										</StyledTableCell>
										<StyledTableCell>
											{item?.assignee_user_id || '-'}
										</StyledTableCell>
									</StyledTableRow>
								))}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
			{modalIsOpen && (
				<Modal
					onClose={toggleModal}
					open={modalIsOpen}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<>
						{ModalName == 'TASK_DESCRIPTION_MODAL' && (
							<DummyModal
								title='Instructions'
								onClose={toggleModal}
								height='400px'
							>
								<WorkflowTaskDescriptinModal
									type={'text'}
									value={expandModal.value}
									Edit={false}
									OnCancel={() => toggleModal()}
									richTextStyle={{ height: '250px' }}
								/>
							</DummyModal>
						)}
					</>
				</Modal>
			)}
		</div>
	);
}

export default TasksTemplateTable;
