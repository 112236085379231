// eslint-disable-next-line no-unused-vars
import React, { useState, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SubmitButtonsField from '../../Buttons/SubmitButtonsField';
import * as builderActions from '../../../redux/actions/workflow-builder-actions.js';
import { CircularProgress } from '@mui/material';

function DynamicModal({
	clientWorkflowId,
	onCancel,
	workflow,
	isWorkflowBrowser,
	onSubmit,
	beingEdited,
	isLoading
}) {
	const dispatch = useDispatch();
	const initialState = {
		...workflow,
		task: '',
		description: '',
		budgeted_hours: '',
		assignee: '',
		dependency: '',
	};
	const [taskForm, setTaskForm] = useState(workflow?.formName);

	const handleChange = (event) => {
		event.preventDefault();

		const { name, value } = event.target;
		setTaskForm({
			...taskForm,
			[name]: value,
		});
	};

	const handleOnCancel = () => {
		onCancel();
	};

	const handleSubmit = (event) => {
		event.preventDefault();

		onSubmit(
			workflow?.workflowName,
			workflow.sectionIndex,
			taskForm,
			clientWorkflowId,
			workflow.rowIndex
		);
	};

	return (
		<form
			autoComplete='off'
			className='ContactAdd-Form'
			onSubmit={handleSubmit}
		>
			<div className='p-3'>
				{workflow?.formLabel?.map(({ name }, i) => {
					return (
						<div key={i} className='improved-input-designs pb-3'>
							<label
								className={
									workflow.sectionName === 'LINKSSECTION'
										? name === 'Notes'
											? ''
											: 'require'
										: ''
								}
							>
								{name}
							</label>
							<input
								className='w-100'
								type='text'
								name={name.split(' ').join('_')}
								value={taskForm[name.split(' ').join('_')]}
								onChange={handleChange}
								required={
									workflow.sectionName === 'LINKSSECTION'
										? name === 'Notes'
											? false
											: true
										: false
								}
							/>
						</div>
					);
				})}
			</div>

			<div className='VerticalSpacer bg-white p-3 rounded-bottom'>
				<SubmitButtonsField child={true}>
					<button
						id='Element'
						type='button'
						className='secondary'
						onClick={handleOnCancel}
					>
						Cancel
					</button>

					<button
						type='submit'
						className='d-flex align-items-center'
						id='Element'
						disabled={isLoading && true}
					>
						{isLoading == true ? (
							<CircularProgress
								size={24}
								sx={{ color: 'white' }}
								disableShrink
							/>
						) : 
						!beingEdited ? 'Add' : 'Update'}
					</button>
				</SubmitButtonsField>
			</div>
		</form>
	);
}

export default memo(DynamicModal);
