import { forwardRef, useEffect, useRef, useState } from 'react';
import FormButtonOne from '../Buttons/FormButtonOne';
import Privacy from '../TermsAndConditionContent/Privacy';
import ProhibitedConductAndContent from '../TermsAndConditionContent/ProhibitedConductAndContent';
import OwnershipLimitedLicense from '../TermsAndConditionContent/OwnershipLimitedLicense';
import Trademarks from '../TermsAndConditionContent/Trademarks';
import Feedback from '../TermsAndConditionContent/Feedback';
import ThirdPartyContent from '../TermsAndConditionContent/ThirdPartyContent';
import Indemnification from '../TermsAndConditionContent/Indemnification';
import Disclaimers from '../TermsAndConditionContent/Disclaimers';
import LimitationOfLiability from '../TermsAndConditionContent/LimitationOfLiability';
import Release from '../TermsAndConditionContent/Release';
import TranferAndProcessingData from '../TermsAndConditionContent/TranferAndProcessingData';
import DisputeResolutionBindingArbitration from '../TermsAndConditionContent/DisputeResolutionBindingArbitration';
import GoverningLawAndVenue from '../TermsAndConditionContent/GoverningLawAndVenue';
import ModifyingAndTerminatingOurSite from '../TermsAndConditionContent/ModifyingAndTerminatingOurSite';
import Severability from '../TermsAndConditionContent/Severability';
import Miscellaneous from '../TermsAndConditionContent/Miscellaneous';
import Intro from '../TermsAndConditionContent/Intro';

const TermsAndCondistionsModal = forwardRef(function NewModal({ onClose, onSubmit }, ref) {
	const [bottomReached, setBottomReached] = useState(false);
	const scrollableDivRef = useRef(null);
	const bottomIdentifierRef = useRef(null);
	const TermsAndCondition = [
		{
			heading: 'Privacy',
			content: <Privacy />,
		},
		{
			heading: 'Prohibited Conduct and Content',
			content: <ProhibitedConductAndContent />,
		},
		{
			heading: 'Ownership; Limited License',
			content: <OwnershipLimitedLicense />,
		},
		{
			heading: 'Trademarks',
			content: <Trademarks />,
		},
		{
			heading: 'Feedback',
			content: <Feedback />,
		},
		{
			heading: 'Third-Party Content',
			content: <ThirdPartyContent />,
		},
		{
			heading: 'Indemnification',
			content: <Indemnification />,
		},
		{
			heading: 'Disclaimers',
			content: <Disclaimers />,
		},
		{
			heading: 'Limitation of Liability ',
			content: <LimitationOfLiability />,
		},
		{
			heading: 'Release',
			content: <Release />,
		},
		{
			heading: 'Transfer and Processing Data ',
			content: <TranferAndProcessingData />,
		},
		{
			heading: 'Dispute Resolution; Binding Arbitration ',
			content: <DisputeResolutionBindingArbitration />,
		},
		{
			heading: 'Governing Law and Venue ',
			content: <GoverningLawAndVenue />,
		},
		{
			heading: 'Modifying and Terminating our Site ',
			content: <ModifyingAndTerminatingOurSite />,
		},
		{
			heading: 'Severability',
			content: <Severability />,
		},
		{
			heading: 'Miscellaneous',
			content: <Miscellaneous />,
		},
	];

	useEffect(() => {
		function isInViewport(element) {
			if (!element) return false;
			const rect = element.getBoundingClientRect();
			return (
				rect.top >= 0 &&
				rect.left >= 0 &&
				rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
				rect.right <= (window.innerWidth || document.documentElement.clientWidth)
			);
		}

		const handleScroll = () => {
			if (bottomIdentifierRef.current) {
				isInViewport(bottomIdentifierRef.current) && setBottomReached(true);
			}
		};

		const scrollableDiv = scrollableDivRef.current;
		if (scrollableDiv) {
			scrollableDiv.addEventListener('scroll', handleScroll);
		}

		return () => {
			if (scrollableDiv) {
				scrollableDiv.removeEventListener('scroll', handleScroll);
			}
		};
	}, []);

	return (
		<div tabIndex={-1} ref={ref} className='TermsAndCondistionsModal'>
			<div className='content-area'>
				<h4 className='HeadlineOneBold'>Terms & Conditions</h4>

				<div ref={scrollableDivRef} className='content-container'>
					<Intro />
					<div className='d-flex flex-column' style={{ gap: '14px' }}>
						{TermsAndCondition?.map((item, index) => {
							return (
								<div
									key={index}
									className='d-flex flex-column'
									style={{ gap: '10px' }}
								>
									<div className='BodyOneBold'>{item.heading}</div>
									<div>{item.content}</div>
								</div>
							);
						})}
					</div>
					<div ref={bottomIdentifierRef}></div>
				</div>

				<p className='BodyTwoLight'>To agree, please scroll to the bottom</p>
			</div>
			<div className='VerticalSpacer bg-white p-3 rounded-bottom d-flex justify-content-between'>
				<FormButtonOne onClick={onClose} type='button' className='secondary-btn-light'>
					Disagree
				</FormButtonOne>
				<FormButtonOne onClick={onSubmit} disabled={!bottomReached} type='button'>
					Agree
				</FormButtonOne>
			</div>
		</div>
	);
});

export default TermsAndCondistionsModal;
