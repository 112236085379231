import React from 'react';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';

export default function ToggleSwitch({
	checked,
	onChange,
	style,
	size,
	label,
	labelPlacement,
	value,
	className,
	textClass,
	sx,
}) {
	const CustomToggle = styled(Switch)(() => ({
		width: 40,
		height: 19,
		padding: 7,
		transitionDuration: '500ms',
		'& .MuiSwitch-switchBase': {
			margin: 1,
			marginTop: 2,
			padding: 0,
			transform: 'translateX(5px)',
			'&.Mui-checked': {
				transform: 'translateX(21px)',
				'& + .MuiSwitch-track': {
					opacity: 1,
					backgroundColor: '#000000',
				},
			},
		},
		'& .MuiSwitch-thumb': {
			backgroundColor: '#FB7A03',
			width: 13,
			height: 13,
			'&:before': {
				content: "''",
				position: 'absolute',
				width: '100%',
				height: '100%',
				left: 0,
				top: 10,
				backgroundRepeat: 'no-repeat',
				backgroundPosition: 'center',
			},
		},
		'& .MuiSwitch-track': {
			opacity: 1,
			backgroundColor: '#000000',
			borderRadius: 20 / 2,
		},
	}));
	return (
		<FormGroup className='d-flex justify-content-between w-100'>
			<FormControlLabel
				control={
					<CustomToggle
						checked={checked}
						onChange={onChange}
						style={style}
						size={size}
						sx={sx}
					/>
				}
				style={{
					margin: '0px',
					display: 'flex',
					justifyContent: 'space-between',
				}}
				label={<span className={` BodyTwoLight ` + textClass}>{label}</span>}
				labelPlacement={labelPlacement}
				value={value}
				className={className}
			/>
		</FormGroup>
	);
}
