/* eslint-disable no-mixed-spaces-and-tabs */
import moment from 'moment';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion/dist/framer-motion';
import { ReactComponent as ArrowUp } from '../../assets/icons/up.svg';
import { ReactComponent as ArrowDown } from '../../assets/icons/down.svg';
import MyTaskCard from './MyTaskCard';
import SkeletonLoading from '../Others/SkeletonLoading';
import * as Actions from '../../redux/actions/dashboard/workspaces-actions';
import { routes } from '../../utilities/routes';
import { useHistory, useLocation } from 'react-router';
import { detailsModal } from '../../utilities/utilities';

function ExpanableClientMeetingsView({ client_name, client_id, tasks, days, weekends }) {
	const dispatch = useDispatch();
	const location = useLocation();
	const history = useHistory();
	const meetingTaskLoading = useSelector((state) => state.workSpaceReducer.meetingTaskLoading);
	const user = useSelector((state) => state.authReducer.user);
	const [expand, setExpand] = useState(true);

	const handleOnClick = useCallback(
		(task_id) => () => {
			dispatch(Actions.handleTaskClick({ taskId: task_id, modalType: detailsModal.MY_TASK }));
		},
		[],
	);

	const toggleExpand = () => {
		setExpand(!expand);
	};
	const clientProfileNavigation = (client_id) => {
		const from = location.state || [];
		from.push({
			link: location.pathname,
			label: 'Workspace',
		});
		if (from.length > 2) from.shift();
		history.push(`${routes['client-profile']}${client_id}`, from);
	};

	return (
		<>
			{days?.map((day, index) => {
				return (
					<div
						key={index}
						id={day.format()}
						className='d-flex flex-column justify-content-start align-items-start container'
						style={{
							width: `${100 / days.length}%`,
							height: 'auto',
							padding: '12px 12px 0px 12px',
						}}
					>
						{index === 0 ? (
							<div
								className='d-flex flex-column align-items-start w-100'
								style={{ cursor: 'pointer' }}
							>
								<SkeletonLoading
									loading={meetingTaskLoading}
									variant='text'
									width={200}
									height={45}
								>
									<div onClick={() => clientProfileNavigation(client_id)}>
										<div
											style={{ padding: '0px 6px 10px 6px' }}
											className='d-flex align-items-center text-left'
										>
											<h3 className='HeadlineThreeBook'>{client_name}</h3>
										</div>
									</div>
								</SkeletonLoading>
							</div>
						) : (index === 4 && !weekends) || (index === 6 && weekends) ? (
							<div
								className='d-flex w-100 justify-content-end'
								style={{ paddingRight: '8px', paddingBottom: '12px' }}
							>
								<div style={{ cursor: 'pointer' }} onClick={toggleExpand}>
									{expand ? <ArrowUp /> : <ArrowDown />}
								</div>
							</div>
						) : (
							<div className='' style={{ paddingBottom: '36px' }}></div>
						)}

						{expand && (
							<div className='w-100 d-flex justify-content-center'>
								<div
									style={{ gap: '24px', maxWidth: '250px' }}
									className='w-100 d-flex flex-column'
								>
									{tasks
										?.filter((t) =>
											t.extended_date === null
												? moment(t.start_date).format('MM-DD-YYYY') ===
												  moment(day).format('MM-DD-YYYY')
												: moment(t.extended_date).format('MM-DD-YYYY') ===
												  moment(day).format('MM-DD-YYYY'),
										)
										?.sort((a, b) => {
											const timeA = moment(a.start_time, 'hh:mm');
											const timeB = moment(b.start_time, 'hh:mm');

											return timeA.diff(timeB);
										})
										?.map((task, innerIndex) => {
											return (
												<SkeletonLoading
													key={innerIndex}
													loading={meetingTaskLoading}
													variant='text'
													width={175}
													height={100}
												>
													<motion.div
														key={innerIndex}
														className={`h-100 w-100`}
														whileHover={{
															scale: 1.1,
															transition: { duration: 0.1 },
														}}
													>
														<MyTaskCard
															taskId={task?.id}
															title={task?.title}
															guests={task?.task_assignees}
															startTime={task?.start_time}
															endTime={task?.end_time}
															clientDba={client_name}
															companyName={task?.client_name}
															onTaskClick={handleOnClick}
															color={task?.my_task?.color}
															inviteStatus={task?.task_status}
															creatorId={task?.my_task?.creator_id}
															userId={user?.userId}
															metadata={task?.metadata}
														/>
													</motion.div>
												</SkeletonLoading>
											);
										})}
								</div>
							</div>
						)}
					</div>
				);
			})}
		</>
	);
}

export default ExpanableClientMeetingsView;
