/* eslint-disable no-mixed-spaces-and-tabs */
import { useEffect, useState } from 'react';

// Styled Components
import { lightGreen, backgroundGrey } from '../Style.jsx';

// Components
import ButtonSquare from '../Buttons/ButtonSquare.jsx';

// Material Ui Components
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { Box, CircularProgress } from '@mui/material';

import { useSelector } from 'react-redux';
import { Member, standardPermissions } from '../../utilities/utilities.js';
import Restricted from '../Permissions/Restricted.jsx';
import SubmitButtonsField from '../Buttons/SubmitButtonsField.jsx';
import TopBar from './TopBar.jsx';
import { useHistory } from 'react-router-dom';
import useBreadCrumb from '../../hooks/useBreadCrumb.jsx';
import { routes } from '../../utilities/routes.js';

function ProfileBar({
	BarText,
	EditProfile,
	edit,
	loading,
	UpdateChanges,
	clientProfile,
	generateInvoice,
	editBtnid,
	hideEditBtn
}) {
	const { BreadCrumbs } = useBreadCrumb();
	const history = useHistory();

	const user = useSelector((state) => state.authReducer.user);
	const client = useSelector((state) => state.clientReducer.client);

	useEffect(() => {
		const queryParams = new URLSearchParams(window.location.search);
		if (queryParams.get('edit')) {
			EditProfile();
			queryParams.set('edit', false);
			window.history.pushState(null, '', queryParams.toString());
		}
	}, []);

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'flex-start',
			}}
		>
			{clientProfile && (
				<>
					<Box
						className='py-2'
						sx={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							':hover': {
								cursor: 'pointer',
							},
							color: `${lightGreen}`,
						}}
					>
						<ArrowLeftIcon />
						<BreadCrumbs>
							<h2
								onClick={() => history.push(routes.clients)}
								className='HeadlineThreeBook text-left'
							>
								Clients
							</h2>
						</BreadCrumbs>
					</Box>
					<div className=' w-100' style={{ paddingBottom: '32px' }}>
						<TopBar
							clientInfo={{
								name: client?.dba?.trim() == '' ? client?.company_name : client?.dba,
								team: client?.team_client_permissions[0]?.team?.name,
								id: client?.id,
							}}
							generateInvoice={generateInvoice}
						/>
					</div>
				</>
			)}
			<Box
				sx={{
					backgroundColor: `#F7F9FA`,
					width: '100%',
					height: '67px',
					padding: '1em',
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					gap: '10px',
					boxSizing: 'border-box',
					borderRadius: '4px 4px 0px 0px',
				}}
			>
				<Box>
					<h1 className='HeadlineOneBold m-0 dark-text '>{BarText}</h1>
				</Box>

				{!hideEditBtn && <Box
					sx={{
						width: 'fit-content',
						display: 'flex',
						gap: '0.5em',
					}}
				>
					{edit ? (
						<div className='VerticalSpacer'>
							<SubmitButtonsField child={true}>
								<button
									type='submit'
									className='d-flex align-items-center'
									id='Element'
									disabled={loading}
									onClick={UpdateChanges}
									data-cy='done-client-btn'
								>
									{loading ? (
										<CircularProgress
											size={24}
											sx={{ color: 'white' }}
											disableShrink
										/>
									) : (
										'Done'
									)}
								</button>
							</SubmitButtonsField>
						</div>
					) : BarText == 'My Tasks - Meeting' &&
					  user?.user_roles?.find((item) =>
							item?.role_scopes
								?.map((role) => role.team_id)
								?.includes(client?.team_client_permissions[0]?.team_id),
					  )?.role_id !== Member.externalCollaborator ? (
						<ButtonSquare
							data-cy={editBtnid || 'edit-client-btn'}
							clickHandler={EditProfile}
						>
							Edit {BarText}
						</ButtonSquare>
					) : (
						<Restricted
							AllowedUsers={standardPermissions}
							userTeamRole={[
								user?.user_roles?.find((item) =>
									item?.role_scopes
										?.map((role) => role.team_id)
										?.includes(client?.team_client_permissions[0]?.team_id),
								)?.role_id,
							]}
						>
							<ButtonSquare
								data-cy={editBtnid || 'edit-client-btn'}
								clickHandler={EditProfile}
							>
								Edit {BarText}
							</ButtonSquare>
						</Restricted>
					)}
				</Box>
				}
			</Box>
		</Box>
	);
}

export default ProfileBar;
