import SubmitButtonsField from '../../Buttons/SubmitButtonsField';

function AlertOverwriteModal({
	OnCancel,
	Onsubmit,
	description,
	note,
	descriptionClassName,
	isLoading,
    anotherNote,
}) {
	return (
		<div>
			<div className='VerticalSpacer'>
				<h4 className={descriptionClassName + ' BodyTwoLight p-3 '}>{description}</h4>
                <div className='d-flex'>
                    <div className='d-flex flex-column '>
                        <span className='BodyTwoBold'>Invoice #
                        </span> 
                        <span className='BodyTwoLight ' style={{color:'fb7a03'}}>3</span>
                        </div>
                    <div className='d-flex flex-column '>
                        <span className='BodyTwoBold'>Billing Period</span> 
                        <span className='BodyTwoLight '>January 1, 2024 - January 31, 2024</span>
                        </div>
                </div>
                <h4 className={descriptionClassName + ' BodyTwoLight pl-3 pr-3 '}>{note}</h4>
                <h4 className={descriptionClassName + ' BodyTwoLight pl-3 pr-3 '}>{anotherNote}</h4>

				<div className='VerticalSpacer bg-white p-3 rounded-bottom'>
					<SubmitButtonsField child={true}>
						<button id='Element' className='secondary' type='button' onClick={OnCancel}>
							Cancel
						</button>

						<div className='d-flex'>
							<button
								type='button'
								className='d-flex align-items-center mr-3'
								id='Element'
								data-cy='add-user-btn'
                                onClick={Onsubmit}
							>
								Overwrite
							</button>
							<button
								type='button'
								className='d-flex align-items-center'
								id='Element'
								data-cy='add-user-btn'
                                onClick={OnCancel}
							>
								Change Date
							</button>
						</div>
					</SubmitButtonsField>
				</div>
			</div>
		</div>
	);
}

export default AlertOverwriteModal;
