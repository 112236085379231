import * as teamActions from '../redux/actions/team-management.actions';
import * as userActions from '../redux/actions/user-management.actions';
import * as clientActions from '../redux/actions/client-actions';
import * as profileActions from '../redux/actions/company-profile';
/* eslint-disable no-mixed-spaces-and-tabs */
import { useCallback, useEffect, useState } from 'react';

// Material Ui Component
import { Avatar, Input, Modal } from '@mui/material';

// Import Components
import ButtonSquare from '../components/Buttons/ButtonSquare';
import SectionHeader from '../components/sidebar/SectionHeader';
import Sidebar from '../components/sidebar/SideBar';
import JobTitlePicker from '../components/sidebar/JobTitlePicker';
import LocationPicker from '../components/sidebar/LocationPicker';
import TeamPickList from '../components/sidebar/TeamPickList';
import Card from '../components/Others/Card';
import TableHeader from '../components/Others/TableHeader';
import TableHeaderItem from '../components/Others/TableHeaderItem';
import TableRowItem from '../components/Others/TableRowItem';
import ActionButton from '../components/Buttons/ActionButton';
import useModal from '../components/Modal/useModal';
import TableRowButton from '../components/Others/TableRowButton';
import DummyModal from '../components/Modal/DummyModal';
import UserAdd from '../components/UserManagement/UserAdd';
import {
	TableItemWidth1,
	TableItemWidth4,
	TableItemWidth5,
} from '../components/TableSpacing/TableSpacingStyles';
import getSymbolFromCurrency from 'currency-symbol-map';

// Demo Data
import LoadingOverLay from '../components/Others/LoadingOverLay';

import { useDispatch, useSelector } from 'react-redux';

import { useHistory, useLocation } from 'react-router-dom';
import Restricted from '../components/Permissions/Restricted';
import {
	convertDecimalToTime,
	countryStatesDummy,
	executivePermissions,
	getRoleName,
	getUniqueListBy,
	getUniqueObjectArray,
	Member,
	numberWithCommas,
	standardPermissions,
} from '../utilities/utilities';
import { Tooltip } from '@material-ui/core';
import { routes } from '../utilities/routes';
import AddTeam from '../components/TeamManagement/AddTeam';
import ConfirmModal from '../components/Modal/ConfirmModal';
import AlertBar from '../components/sub_components/AlertBar';
import DropDownActions from '../components/Others/DropDownActions';
import * as authActions from '../redux/actions/auth-actions';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

function UserManagement() {
	const history = useHistory();
	const location = useLocation();
	const dispatch = useDispatch();

	const userBillingList = useSelector((state) => state.userReducer.userBillingList);

	const user = useSelector((state) => state.authReducer.user);
	const companyProfileData = useSelector((state) => state.profileReducer.profileData);

	const currencySymbol = companyProfileData
		? getSymbolFromCurrency(companyProfileData?.currency)
		: '';

	useEffect(() => {
		dispatch(profileActions.getTenantProfile(user?.tenantId));
		dispatch(userActions.getUsersBilling());
	}, [dispatch]);

	const loading = useSelector((state) => state.userReducer.loading);
	let users = useSelector((state) => state.userReducer.users);
	const loggedInUser = useSelector((state) => state.authReducer.user);
	const userProfileManagement = useSelector((state) => state.userReducer.userProfileManagement);

	let filteredUserRoles = useSelector((state) => state.userReducer.filteredUserRole);

	const allTeams = useSelector((state) => state.teamReducer.teams);

	const filters = useSelector((state) => state.userReducer.filters);

	let teams = useSelector((state) => state.userReducer.userProfileManagement?.teams);

	users = getUniqueListBy(users, 'user_id');

	if (filteredUserRoles?.length > 0) {
		filteredUserRoles = filteredUserRoles?.map((obj) => {
			return {
				...obj,
				is_checked: filters?.userRoles?.includes(obj?.user_id[0]),
			};
		});
	}

	if (teams?.length > 0) {
		teams = teams.map((team) => {
			return {
				value: team.id,
				label: team.name,
				is_checked: filters?.team?.includes(team.id),
			};
		});
	}

	if (
		loggedInUser?.user_roles?.map((item) => item.role_id)?.includes(Member.teamManagerId) ||
		loggedInUser?.user_roles?.map((item) => item.role_id)?.includes(Member.teamMemberId)
	) {
		users = users?.filter((u) => !executivePermissions.includes(u.role_id));
		filteredUserRoles = filteredUserRoles?.filter(
			(u) => !executivePermissions.includes(u.value),
		);
	}

	const InitialTeamForm = {
		name: '',
		description: '',
	};

	const [teamModal, setTeamModal] = useState(false);

	const AddUser = () => {
		dispatch(userActions.clearImage());
		ModalType('Create User');
		toggleModal();
		dispatch(userActions.resetUserAddErrors());
	};

	const addNewTeam = () => {
		setTeamModal(true);
		toggleModal();
	};

	const InputClass = {
		width: '100%',
		fontFamily: 'CentraLight',
		fontWeight: 'light',
		fontSize: '14px !important',
		lineHeight: '18px',
		color: 'white',
		backgroundColor: 'rgba(0, 0, 0, 0.25)',
		padding: '8px 6px 2px 8px',
		borderRadius: '3px',
		marginTop: '8px',
	};

	const [Name, setName] = useState('');
	const resetFirst = () => {
		setName('');
	};
	const NameChange = (event) => {
		setName(event.target.value);
	};

	//   Modal
	const { modalIsOpen, toggleModal, ModalName, ModalType } = useModal();
	const [existingUserModal, setExistingUserModal] = useState(false);

	const OnTeamSubmit = (type, id, inputs) => {
		dispatch(
			teamActions.addTeam(inputs, () => {
				setTeamModal(false);
				toggleModal();
			}),
		);
	};

	const handleNavigateToProfile = (user) => {
		user.user_id === loggedInUser.userId
			? history.push('/account-settings')
			: history.push(`user-profile/${user.user_id}`);
	};

	const teamProfileNavigation = (teamId) => {
		const from = location.state || [];
		from.push({
			link: location.pathname,
			label: 'Users',
		});
		if (from.length > 2) from.shift();
		user?.user_roles?.map((item) => item.role_id)?.includes(Member.externalCollaborator)
			? null
			: history.push(`${routes['team-profile']}${teamId}`, from);
	};

	const validateDropDownActions = useCallback((user) => {
		const dropdownActions = [
			{
				title: 'Edit',
				action: async (user) => {
					handleNavigateToProfile(user);
					return;
				},
				async: false,
			},
			...(executivePermissions?.includes(loggedInUser?.userRole.id) && !user?.accepted
				? [
						{
							title: 'Resend Invite',
							action: async (user) => {
								return dispatch(authActions.sendSignUpInvite(user.email));
							},
							async: true,
						},
				  ]
				: []),
		];
        
		return (
			<DropDownActions
				actions={dropdownActions}
				actionParams={user}
				dropdownIcon={<MoreHorizIcon htmlColor='#333' />}
			/>
		);
	}, []);

	useEffect(() => {
		dispatch(userActions.getRoles());
		dispatch(userActions.getUsers(filters));
		dispatch(userActions.getFilteredUserProfileManagement());
		dispatch(userActions.getFilteredUserRoles());
		dispatch(teamActions.getTeams());
		dispatch(clientActions.getClients());
		// dispatch(userActions.getSpecificUserBilling(Profile));
	}, [dispatch, filters]);

	useEffect(() => {
		dispatch(profileActions.getTenantProfile(user?.tenantId));
		dispatch(userActions.getUsersBilling());
	}, [dispatch]);

	return (
		<div className='AppBody'>
			<Sidebar title='User Management'>
				<div className='SidebarContentWrapper'>
					<div className='User-filters'>
						<div>
							<SectionHeader
								title='Search Users'
								label='Clear'
								onClear={resetFirst}
							/>
							<Input
								sx={InputClass}
								disableUnderline={true}
								size='small'
								placeholder='User Name'
								name='first'
								value={Name}
								onChange={NameChange}
							/>
						</div>

						<div>
							<div className='.SidebarListWrapper'>
								<TeamPickList
									options={teams}
									onChange={(e) =>
										dispatch(
											userActions.handleTeamCheckboxes(
												parseInt(e.target.value),
											),
										)
									}
									onClear={() => dispatch(userActions.clearTeamCheckboxes())}
									onClick={(teamId) => teamProfileNavigation(teamId)}
								/>
							</div>
						</div>

						<div>
							<div className='.SidebarListWrapper'>
								<JobTitlePicker
									options={getUniqueObjectArray(filteredUserRoles)}
									onChange={(e) => {
										dispatch(
											userActions.handleRoleCheckboxes(
												e.target.checked,
												filteredUserRoles.filter(
													(r) => r.value === parseInt(e.target.value),
												)[0]?.user_id,
											),
										);
									}}
									onClear={() => dispatch(userActions.clearJobTitleCheckboxes())}
								/>
							</div>
						</div>

						<div>
							<div className='.SidebarListWrapper'>
								<LocationPicker
									options={getUniqueObjectArray(
										userProfileManagement?.location
											.filter((loc) => loc.value != null || loc.label != null)
											?.map((obj) => {
												return {
													...obj,
													is_checked: filters?.location?.includes(
														obj.value,
													),
												};
											}),
									)}
									onChange={(e) =>
										dispatch(
											userActions.handleLocationCheckboxes(e.target.value),
										)
									}
									onClear={() => dispatch(userActions.clearLocationCheckboxes())}
								/>
							</div>
						</div>
					</div>

					<Restricted AllowedUsers={standardPermissions}>
						<button
							data-cy='add-user'
							className='full-width rounded-button-1'
							onClick={AddUser}
						>
							Add New User
						</button>
					</Restricted>
				</div>
			</Sidebar>
			<div
				style={{
					width: 'calc(100vw - 260px)',
					height: '100vh',
				}}
			>
				<AlertBar />
				<div className='Listing-Main' style={{ backgroundColor: '#EEF2F3' }}>
					<div className='Listing-Content'>
						<Card
							title='Users'
							secondaryBtn={
								executivePermissions.includes(user?.userRole?.id)
									? 'Show Archive Users'
									: ''
							}
							secondaryBtnClick={() => {
								history.push('/archived-users');
							}}
						>
							<div className='card-body'>
								<TableHeader
									ClassName='TableHeader flex fixedHeader'
									border={false}
								>
									<TableHeaderItem
										ClassName='UManagement'
										label='User Name'
										width={TableItemWidth5}
									></TableHeaderItem>
									<TableHeaderItem
										ClassName='UManagement'
										label='Email'
										width={TableItemWidth5}
										margin='mb-0'
									/>
									<TableHeaderItem
										ClassName='UManagement'
										label='Role'
										width={TableItemWidth4}
										margin='mb-0'
									/>
									<TableHeaderItem
										ClassName={'UManagement'}
										label='Teams'
										width={TableItemWidth5}
										margin='mb-0'
									/>
									<Restricted
										AllowedUsers={[Member.ownerId, Member.superAdminId]}
									>
										<TableHeaderItem
											ClassName={'UManagement'}
											label='Available Hours/Week'
											width={TableItemWidth4}
											margin='mb-0'
										/>

										<TableHeaderItem
											ClassName={'UManagement'}
											label='Annual Benefits'
											width={TableItemWidth4}
											margin='mb-0'
										/>

										<TableHeaderItem
											ClassName={'UManagement'}
											label='Hourly Rate'
											width={TableItemWidth4}
											margin='mb-0'
										/>
										<TableHeaderItem
											ClassName={'UManagement'}
											label='Annual Salary'
											width={TableItemWidth4}
											margin='mb-0'
										/>
										<TableHeaderItem
											ClassName={'UManagement'}
											label='Employee Cost/Hour'
											width={TableItemWidth4}
											margin='mb-0'
										/>
									</Restricted>

									<TableHeaderItem
										ClassName='UManagement text-center'
										label='Action'
										width={TableItemWidth1}
										margin='mb-0'
									/>
								</TableHeader>

								<div
									data-cy='all-users-list'
									className='ListingLoading listing-scroll'
								>
									{loading ? (
										<LoadingOverLay active={loading} />
									) : users.length < 1 ? (
										<div className='TableRow w-100 d-flex align-items-center justify-content-center'>
											<h4 className='BodyTwoLight'>No Users</h4>
										</div>
									) : (
										users
											?.filter(
												(user) =>
													user?.user_name
														?.toLowerCase()
														.includes(Name?.toLowerCase()) ||
													user?.email
														?.toLowerCase()
														.includes(Name?.toLowerCase()) ||
													user?.role_name
														?.toLowerCase()
														.includes(Name?.toLowerCase()) ||
													user?.team_name
														?.toLowerCase()
														.includes(Name?.toLowerCase()) ||
													getRoleName(user?.role_id)
														?.toLowerCase()
														.includes(Name?.toLowerCase()),
											)
											?.map((user, i) => {
												const userBillingDetails = userBillingList.find(
													(userBilling) =>
														userBilling?.user_id == user.user_id,
												);

												return (
													<div
														data-cy={
															user.user_id === loggedInUser.userId
																? ''
																: 'user'
														}
														className='TableRow d-flex align-items-center h-auto'
														key={i}
													>
														<TableRowButton
															ClassName='UManagement'
															label={user.user_name}
															width={TableItemWidth5}
															clickHandler={() => {
																handleNavigateToProfile(user);
															}}
														>
															<>
																<Avatar
																	sx={{
																		bgcolor: `#FEF2E7`,
																		color: `#333`,
																		marginRight: '10px',
																		height: '24px',
																		width: '24px',
																		fontSize: '1rem',
																	}}
																	alt={user.user_name}
																	src={user.image}
																/>
															</>
														</TableRowButton>

														<TableRowItem
															label={user.email}
															width={TableItemWidth5}
															ClassName='UManagement text-left'
														/>

														<TableRowItem
															label={
																user.role_name
																	? user.role_name
																	: getRoleName(user?.role_id)
															}
															width={TableItemWidth4}
															ClassName='UManagement text-left'
														/>

														<TableRowItem
															input={true}
															width={TableItemWidth5}
															ClassName='UManagement text-left'
														>
															{user.role_id !== Member.ownerId ? (
																user?.user_teams?.length > 1 ? (
																	<>
																		<h3 className='BodyTwoLight m-0 dark-text'>
																			{user.team_name}
																		</h3>
																		<Tooltip
																			title={
																				<span>
																					{user?.user_teams
																						?.filter(
																							(
																								team,
																							) =>
																								team.team_id !==
																								user.team_id,
																						)
																						?.map(
																							(
																								team,
																								i,
																							) => (
																								<p
																									key={
																										i
																									}
																								>
																									{
																										team
																											.team
																											.name
																									}
																								</p>
																							),
																						)}
																				</span>
																			}
																		>
																			<h5 className='BodyTwoLight m-0'>{`+ ${
																				user?.user_teams
																					?.length - 1
																			} more`}</h5>
																		</Tooltip>
																	</>
																) : (
																	<h3 className='BodyTwoLight m-0 dark-text'>
																		{user.team_name}
																	</h3>
																)
															) : allTeams?.length > 1 ? (
																<>
																	<h3 className='BodyTwoLight m-0 dark-text'>
																		{allTeams[0].name}
																	</h3>
																	<Tooltip
																		title={
																			<span>
																				{allTeams
																					?.slice(
																						1,
																						allTeams?.length,
																					)
																					?.map(
																						(
																							team,
																							i,
																						) => (
																							<p
																								key={
																									i
																								}
																							>
																								{
																									team.name
																								}
																							</p>
																						),
																					)}
																			</span>
																		}
																	>
																		<h5 className='BodyTwoLight m-0'>{`+ ${
																			allTeams?.length - 1
																		} more`}</h5>
																	</Tooltip>
																</>
															) : (
																<h3 className='BodyTwoLight m-0 dark-text'>
																	{allTeams?.length > 0 &&
																		allTeams[0].name}
																</h3>
															)}
														</TableRowItem>

														<Restricted
															AllowedUsers={[
																Member.ownerId,
																Member.superAdminId,
															]}
														>
															<TableRowItem
																label={`${
																	userBillingDetails?.available_hours
																		? convertDecimalToTime(
																				userBillingDetails?.available_hours,
																		  )
																		: '00 : 00'
																}`}
																width={TableItemWidth4}
																ClassName='UManagement text-left'
															/>
															<TableRowItem
																label={
																	userBillingDetails?.job_benefits
																		? `${currencySymbol}${numberWithCommas(
																				userBillingDetails?.job_benefits,
																		  )}`
																		: '--'
																}
																width={TableItemWidth4}
																ClassName='UManagement text-left'
															/>
															<TableRowItem
																label={
																	userBillingDetails?.cost_mode ===
																	'annual'
																		? '--'
																		: userBillingDetails?.salary_per_hour
																		? `${currencySymbol}${numberWithCommas(
																				userBillingDetails?.salary_per_hour,
																		  )}`
																		: '--'
																}
																width={TableItemWidth4}
																ClassName='UManagement text-left'
															/>

															<TableRowItem
																label={
																	userBillingDetails?.cost_mode ===
																	'hourly'
																		? '--'
																		: userBillingDetails?.annual_salary
																		? `${currencySymbol}${numberWithCommas(
																				userBillingDetails?.annual_salary,
																		  )}`
																		: '--'
																}
																width={TableItemWidth4}
																ClassName='UManagement text-left'
															/>
															<TableRowItem
																label={
																	userBillingDetails?.cost_per_hour
																		? `${currencySymbol}${numberWithCommas(
																				userBillingDetails?.cost_per_hour,
																		  )}`
																		: '--'
																}
																width={TableItemWidth4}
																ClassName='UManagement text-left'
															/>
														</Restricted>
														<TableRowButton
															ClassName='UManagement'
															text='text-left'
															width={TableItemWidth1}
														>
															<Restricted
																AllowedUsers={
																	user?.role_id ===
																		Member.ownerId &&
																	loggedInUser?.userRole.id !==
																		user.role_id
																		? []
																		: user.role_id ==
																				Member.supervisorId &&
																		  loggedInUser?.userRole
																				?.id ==
																				Member.supervisorId
																		? [Member.ownerId]
																		: standardPermissions
																}
																userTeamRole={loggedInUser?.user_roles
																	?.filter((item) =>
																		user?.user_teams?.find(
																			(team) =>
																				item?.role_scopes[0]
																					?.team_id ===
																				team?.team_id,
																		),
																	)
																	.map((item) => item.role_id)}
																RestrictedComponent={
																	<ActionButton
																		disable={true}
																		ClassName='opacity-50'
																		title={'Not Authorized'}
																	/>
																}
															>
																{validateDropDownActions(user)}
															</Restricted>
														</TableRowButton>
													</div>
												);
											})
									)}
								</div>
							</div>
						</Card>

						{existingUserModal && (
							<Modal
								onClose={() => setExistingUserModal(false)}
								open={existingUserModal}
								sx={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								<DummyModal
									title={'This user has been archived'}
									onClose={() => setExistingUserModal(false)}
									hideCrossIcon
								>
									<ConfirmModal
										title='Unarchive Users'
										description={'Would you like to unarchive this user?'}
										OnCancel={() => setExistingUserModal(false)}
										Onsubmit={() => {
											history.push('/archived-users');
										}}
										descriptionClassName='text-left'
									/>
								</DummyModal>
							</Modal>
						)}
					</div>
				</div>
			</div>

			{modalIsOpen ? (
				<Modal
					onClose={toggleModal}
					open={modalIsOpen}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<DummyModal title={ModalName} onClose={toggleModal}>
						<UserAdd
							onCancel={toggleModal}
							// type={modalType}
							addNewTeam={addNewTeam}
							setExistingUserModal={setExistingUserModal}
						/>
					</DummyModal>
				</Modal>
			) : (
				<></>
			)}

			{teamModal && (
				<Modal
					onClose={() => setTeamModal(false)}
					open={teamModal}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<DummyModal title={'Create Team'} onClose={() => setTeamModal(false)}>
						<AddTeam
							onCancel={() => setTeamModal(false)}
							value={InitialTeamForm}
							submit={OnTeamSubmit}
							response={'Success'}
						/>
					</DummyModal>
				</Modal>
			)}
		</div>
	);
}

export default UserManagement;
