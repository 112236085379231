import { ReactComponent as Edit } from '../../assets/icons/edit.svg';
import { ReactComponent as Clock } from '../../assets/icons/ClockCounterClockwise.svg';

export default function CustomIconText({
	label,
	name,
	style,
	type,
	value,
	onChange,
	id,
	billingModal,
	editModal,
	edit,
}) {
	return (
		<div className='ProfilePage d-flex flex-column w-100'>
			{edit ? (
				<div>
					<div
						className='d-flex flex-row'
						style={{ justifyContent: 'space-between' }}
					>
						<label className='BodyTwoLight text-left m-0'>{label}</label>

						{edit && editModal && (
							<div
								className='d-flex align-items-center'
								style={{ marginTop: '-5px' }}
							>
								<Clock
									className='pr-2 pointer'
									width='23px'
									height='23px'
									onClick={billingModal}
								/>
								<Edit onClick={editModal} className='pointer' width='15px'
									height='15px' />
							</div>
						)}
					</div>
					<div className='d-flex white-bg align-items-center' style={{ gap: '1em', height:'30px' }}>
						<span
							id={id}
							className='BodyTwoLight m-0 text-left pl-2'
							name={name}
							type={type}
							onChange={onChange}
							style={{padding:'6px'}}
						>
							{value}
						</span>
					</div>
				</div>
			) : (
				<div className='d-flex flex-column align-items-start'>
					<label className='BodyTwoLight text-left text-capitalize'>{label}</label>

					<h3
						id={id}
						className={'BodyTwoLight m-0 text-left'}
						name={name}
						style={style}
						type={type}
						onChange={onChange}
					>
						{value}
					</h3>
				</div>
			)}
		</div>
	);
}
