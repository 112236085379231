import React, { useState } from 'react';
import ResetLinkSent from '../../components/login/ResetLinkSent';
import { ReactComponent as Levvy } from '../../assets/icons/LevvyWordMark.svg';
import { ReactComponent as LevvyLogo } from '../../assets/icons/Shape.svg';

export default function ResetLinkSentComponent() {
	// const dispatch = useDispatch();
	const [play, setPlay] = useState(false);
	const [shadow, setShadow] = useState(false);

	return (
		<div style={{ overflow: 'hidden' }}>
			<div
				className='login-screen'
				style={{ display: 'flex', justifyContent: 'center' }}
			>
				<div className='' style={{ justifyContent: 'center', display: 'grid' }}>
					<div className='tCenter'>
						<div className={play ? 'translateY' : ''}>
							<div className={play ? 'rotate' : ''}>
								<div className={play ? 'scale' : ''}>
									<LevvyLogo
										className='levvyLogo'
										style={{ marginTop: '80px', marginBottom: '72px' }}
									/>
								</div>
							</div>
							<div className={shadow ? 'shadowOfLogo' : ''}></div>
						</div>
					</div>

					<div className=''>
						<div className='dfCenter'>
							<ResetLinkSent />
						</div>
					</div>

					<div className='dfCenter'>
						<div className='' style={{ marginTop: 'auto' }}>
							<p
								style={{ marginTop: '70px', marginBottom: '2px' }}
								className='dfCenter'
							>
								Powered By
							</p>
							<p style={{ marginBottom: '15px' }}>
								<Levvy />
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
