import { ReactComponent as CheckCircle } from '../../assets/icons/Integrations/CheckCircle.svg';

function ZapierProns(props) {
	return (
		<div className='checkProns d-flex align-items-center mt-2'>
			<CheckCircle />
			<span className='ml-2 align-items-center BodyTwoLight'>{props.subtitle}</span>
		</div>
	);
}

export default ZapierProns;
