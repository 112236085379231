import React from 'react';

function EnchorText({ link, label }) {
	return (
		<div className='ProfilePage d-flex flex-column w-100'>
			<label className='text-left m-0'>{label}</label>

			<a
				className='BodyTwoLight primary-text text-left pt-1 pointer'
				href={link}
				target='_blank'
				rel='noreferrer'
			>
				{link}
			</a>
		</div>
	);
}

export default EnchorText;
