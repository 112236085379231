import { Avatar } from '@mui/material';
import React from 'react';
import { getAvatarLabel } from '../../utilities/utilities';
import FormButtonOne from '../Buttons/FormButtonOne';

function TaskGuestsList({guestList, users, toggleModal}) {
  return (
    <div className='my-task-modal'>
    <div
        style={{
            minHeight: '20%',
            maxHeight: '60%',
            paddingBottom: '20px',
        }}
        className='my-task-body'
    >
        <div className='my-task-body-child'>
            <div className='HeadlineTwoBold dark-text d-flex'>
                Guests List
            </div>
            <div className='my-task-inner-body'>
                <div
                className='d-flex flex-column'
                    style={{
                        height: '100%',
                        boxSizing: 'border-box',
                        overflow: 'auto',
                        gap:'20px'
                    }}
                >
                    {guestList?.map(
                        (user) => {
                            const currentMember = users?.find(
                                (item) => item.id == user,
                            );
                            return (
                                <div
                                    className='w-100 d-flex flex-row justify-content-start align-items-center'
                                    style={{ gap: '8px' }}
                                    key={user}
                                >
                                    <Avatar
                                        sx={{
                                            bgcolor: `#FEF2E7`,
                                            color: `#333`,
                                            marginRight: '10px',
                                            height: '23px',
                                            width: '23px',
                                            fontSize: '0.8rem',
                                        }}
                                        alt={currentMember?.full_name}
                                        src={currentMember?.image_url}
                                    >
                                        {getAvatarLabel(currentMember?.full_name)}
                                    </Avatar>

                                    <span className='BodyTwoLight pt-1 dark-text text-left'>
                                        {currentMember?.full_name}
                                    </span>
                                </div>
                            );
                        },
                    )}
                </div>
            </div>
        </div>
    </div>
    <div style={{ height: '10%' }} className='my-task-form-actions'>
        <div></div>
        <FormButtonOne
            onClick={toggleModal}
            type='button'
            className='secondary-btn-light'
        >
            Close
        </FormButtonOne>
    </div>
</div>
  );
}

export default TaskGuestsList;