const tableColWidth = 1.5;
const tableGutterWidth = 2.4;

export const TableItemWidth7 = `${tableColWidth * 15}rem + ${
  tableGutterWidth * 3
}rem`;
export const TableItemWidth6 = `${tableColWidth * 12}rem + ${
  tableGutterWidth * 3
}rem`;

export const TableItemWidth5 = `${tableColWidth * 5}rem + ${
  tableGutterWidth * 2.5
}rem`;

export const TableItemWidth4 = `${tableColWidth * 4}rem + ${
  tableGutterWidth * 2
}rem`;

export const TableItemWidth3 = `${tableColWidth * 3}rem + ${
  tableGutterWidth * 1.5
}rem`;

export const TableItemWidth2 = `${
  tableColWidth * 1
}rem + ${tableGutterWidth}rem`;

export const TableItemWidth1 = `${
  tableColWidth * 0.5
}rem + ${tableGutterWidth}rem`;
