import React, { useEffect, useState } from 'react';
import SearchInput from '../Inputs/SearchInput';
import AppComponent from './AppComponent';
import Logo from '../../assets/icons/Group 107.svg';
import { ReactComponent as ZapierIcon } from '../../assets/icons/ZapierIcon.svg';
import { useDispatch, useSelector } from 'react-redux';
import * as slackActions from '../../redux/actions/slack-actions';

export default function ConnectedApps() {
	const dispatch = useDispatch();

	const [searchItem, setSearchItem] = useState('');

	const user = useSelector((state) => state.authReducer.user);
	const connections = useSelector((state) => state.slackIntegrationReducer.slackConnection);

	const connectedApps = [
		{
			logo: Logo,
			name: 'Slack',
			details:
				'Connect with Slack to get notified when you are mentioned in a comment, when your task is approved and other relevant updates. Learn more.',
		},
	];

	const handleSearchChange = (e) => {
		setSearchItem(e.target.value);
	};

	useEffect(() => {
		dispatch(slackActions.checkSlackIntegration(user?.userId));
	}, [dispatch, user]);

	const handleRemoveAccess = () => {
		dispatch(slackActions.removeSlackAccess(user?.userId));
	};

	return (
		<div>
			<div className='d-flex flex-column' style={{ gap: '24px' }}>
				<div
					style={{
						background: 'white',
						borderRadius: '4px',
						padding: '10px',
						gap: '24px',
					}}
					className='d-flex flex-row'
				>
					<ZapierIcon />
					<h6 className='HeadlineTwoBold'>Slack</h6>
				</div>
				<div className='Zap-Head-Section'>
					<div>
						{connectedApps
							.filter((val) => {
								if (searchItem === '') {
									return val;
								} else if (
									val.name.toLowerCase().includes(searchItem.toLowerCase())
								) {
									return val;
								}
							})
							.map((app, key) => {
								return (
									<AppComponent
										onClick={() => window.location.replace('/slackintegration')}
										handleRemoveAccess={handleRemoveAccess}
										key={key}
										logo={app.logo}
										appName={app.name}
										details={app.details}
										connected={connections === 1 ? true : false}
									/>
								);
							})}
					</div>
				</div>
			</div>
		</div>
	);
}
