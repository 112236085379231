/* eslint-disable no-mixed-spaces-and-tabs */
// eslint-disable-next-line no-unused-vars
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SubmitButtonsField from '../Buttons/SubmitButtonsField';
import * as clientActions from '../../redux/actions/client-actions';
import * as profileActions from '../../redux/actions/company-profile';
import RichTextEditor from '../Inputs/RichTextEditor';
import { CircularProgress } from '@mui/material';
import Select from '../Inputs/Select';
import { Country, State } from 'country-state-city';
import EditableLocationText from '../Inputs/EditableLocationText';
import PhoneNumberInput from '../Inputs/PhoneNumberInput';

function AddContact({ onCancel, clientContact }) {
	const dispatch = useDispatch();

    const client = useSelector((state) => state.clientReducer.client);
	const [isDirectFormatted, setIsDirectFormatted] = useState(
		clientContact?.contact?.is_direct_formatted || false,
	);
	const [isGeneralFormatted, setIsGeneralFormatted] = useState(
		clientContact?.contact?.is_general_formatted || false,
	);
	const countries = useSelector((state) => state.profileReducer.countries);
	const states = useSelector((state) => state.profileReducer.states);
	const secondaryStates = useSelector((state) => state.profileReducer.secondaryStates);
	const companyProfileData = useSelector((state) => state.profileReducer.profileData);

	const emptyAdress = {
		country: companyProfileData?.country || 'US',
		city: '',
		street: '',
		suite: '',
		zip: '',
		state: '',
	};
	const initialForm = {
		first_name: '',
		last_name: '',
		address: [emptyAdress],
		contact: {
			email: [''],
			general: [''],
			direct: [''],
		},
	};
	const [form, setForm] = useState(initialForm);
	const [formControls, setFormControls] = useState({
		error: '',
		loading: false,
	});

	const handleChange = (event, index) => {
		event.preventDefault();

		let { name, value } = event.target;
		if (name === 'extension') {
			const pattern = /^\d+$/;
			if (!pattern.test(value)) {
				value = value.replace(/[^\d]/g, '');
			}
		}
		if (name === 'direct' || name === 'general') {
			if (value.length > 14) {
				value = form?.phone;
			} else {
				var val = value.replace(/\D/g, '');
				var newVal = '';

				if (val.length > 4) {
					value = val;
				}
				if (val.length > 3 && val.length < 6) {
					newVal += val.substr(0, 3) + '-';
					val = val.substr(3);
				}
				if (val.length > 6) {
					newVal += val.substr(0, 3) + '-';
					newVal += val.substr(3, 3) + '-';
					val = val.substr(6);
				}
				if (value.length > 10) {
					return;
				}
				newVal += val;
				value = newVal;
			}
		}

		setForm({
			...form,
			[name]: value,
		});
	};

	const quillChange = (value, delta, source) => {
		if (source == 'user') {
			setForm({
				...form,
				notes: value,
			});
		}
	};

	const handleOnCancel = () => {
		onCancel();
	};

	const handleEmailChange = (index) => (event) => {
		const formValues = { ...form };

		if (index == 0) {
			formValues.contact.email[0] = event.target.value;
		}
		if (index == 1) {
			formValues.contact.email[1] = event.target.value;
		}

		setForm({
			...formValues,
		});
	};

	const handleAdressChange = (index) => (event) => {
		const { name, value } = event.target;

		if (name === 'country') {
			setForm({
				...form,
				address: form?.address?.map((item, i) => {
					if (index == i) {
						return {
							...item,
							[name]: value,
							state: '',
							street: '',
							city: '',
							zip: '',
							suite: '',
						};
					}
					return item;
				}),
			});
		} else {
			setForm({
				...form,
				address: form?.address?.map((item, i) => {
					if (index == i) {
						return {
							...item,
							[name]: value,
						};
					}
					return item;
				}),
			});
		}
	};

	const onAddressChange = (index) => (prop) => {
		setForm((form) => {
			return {
				...form,
				address: form?.address?.map((item, i) => {
					if (index == i) {
						return {
							...item,
							street: prop.fullAddress,
							city: prop?.city || '',
							state: prop?.state ? prop?.state : form?.address[0]?.state,
							zip: prop?.zipCode || '',
							suit_floor: prop?.suite || '',
						};
					}
					return item;
				}),
			};
		});
	};

	const addNewGeneral = () => {
		const formValues = { ...form };

		formValues.contact.general[1] = '';

		setForm({
			...formValues,
		});
	};

	const removeGeneral = () => {
		const formValues = { ...form };

		formValues.contact.general.splice(1, 1);

		setForm({
			...formValues,
		});
	};

	const addNewTel = () => {
		const formValues = { ...form };

		formValues.contact.direct[1] = '';

		setForm({
			...formValues,
		});
	};

	const removeTel = () => {
		const formValues = { ...form };

		formValues.contact.direct.splice(1, 1);

		setForm({
			...formValues,
		});
	};

	const handleDirectChange = (event, country, e, PhoneNumber) => {
		const formValues = { ...form };
		if (isDirectFormatted) {
			if (e.target.name == 'direct0') {
				formValues.contact.direct[0] = PhoneNumber;
			}
			if (e.target.name == 'direct1') {
				formValues.contact.direct[1] = PhoneNumber;
			}
		} else {
			if (event.target.name == 'direct0') {
				formValues.contact.direct[0] = event.target.value;
			}
			if (event.target.name == 'direct1') {
				formValues.contact.direct[1] = event.target.value;
			}
		}
		setForm({
			...formValues,
		});
	};
	const handleGeneralChange = (event, country, e, PhoneNumber) => {
		const formValues = { ...form };
		if (isGeneralFormatted) {
			if (e.target.name == 'general0') {
				formValues.contact.general[0] = PhoneNumber;
			}
			if (e.target.name == 'general1') {
				formValues.contact.general[1] = PhoneNumber;
			}
		} else {
			if (event.target.name == 'general0') {
				formValues.contact.general[0] = event.target.value;
			}
			if (event.target.name == 'general1') {
				formValues.contact.general[1] = event.target.value;
			}
		}
		setForm({
			...formValues,
		});
	};

	const resetDirectNumber = () => {
		const formValues = { ...form };
		formValues.contact.direct[0] = '';
		if (form?.contact?.direct?.length == 2) {
			formValues.contact.direct[1] = '';
		}
		setForm({
			...formValues,
		});
	};

	const resetGeneralNumber = () => {
		const formValues = { ...form };
		formValues.contact.general[0] = '';
		if (form?.contact?.general?.length == 2) {
			formValues.contact.general[1] = '';
		}
		setForm({
			...formValues,
		});
	};

	const toggleDirectFormatting = (formatting) => {
		setIsDirectFormatted(formatting);
	};

	const toggleGeneralForrmatting = (formatting) => {
		setIsGeneralFormatted(formatting);
	};

	const addSecondaryAddress = () => {
		setForm({
			...form,
			address: [form?.address[0] || emptyAdress, emptyAdress],
		});
	};

	const removeSecondaryAddress = () => {
		setForm({
			...form,
			address: [form?.address[0]],
		});
	};

	const onClientAddSuccess = () => {
		setFormControls({
			error: '',
			loading: false,
		});
		onCancel();
	};

	const onClientAddFailure = (error) => {
		setFormControls({
			error: error?.message,
			loading: false,
		});
	};

	const handleSubmit = (event) => {
		event.preventDefault();

		setFormControls({
			error: '',
			loading: true,
		});

		if (!clientContact) {
			const contact = {
				email: form?.contact?.email?.filter((o, index) => {
					if (index == 1) return o;
					return true;
				}),
				direct: form?.contact?.direct?.filter((o, index) => {
					if (index == 1) return o;
					return true;
				}),
				general: form?.contact?.general?.filter((o, index) => {
					if (index == 1) return o;
					return true;
				}),
				is_direct_formatted: isDirectFormatted,
				is_general_formatted: isGeneralFormatted,
			};

			const filteredAddresses = form.address?.filter((add, index) => {
				if (index === 1) {
					return add.city || add.street || add.suite || add.zip || add.state;
				}
				return true;
			});

			const clientContact = {
				...form,
				client_id: client?.id,
				address: filteredAddresses,
				order: client?.client_contact_list?.length,
				contact,
			};

			dispatch(clientActions.addClientContact(clientContact))
				.then((data) => {
					onClientAddSuccess(data);
				})
				.catch((error) => {
					onClientAddFailure(error);
				});
		} else {
			const contact = {
				email: form?.contact?.email?.filter((o, index) => {
					if (index == 1) return o;
					return true;
				}),
				direct: form?.contact?.direct?.filter((o, index) => {
					if (index == 1) return o;
					return true;
				}),
				general: form?.contact?.general?.filter((o, index) => {
					if (index == 1) return o;
					return true;
				}),
				is_direct_formatted: isDirectFormatted,
				is_general_formatted: isGeneralFormatted,
			};
			console.info('idr', form);

			const filteredAddresses =
				form?.address?.length &&
				form?.address?.filter((add, index) => {
					if (index === 1) {
						return add.city || add.street || add.suite || add.zip || add.state;
					}
					return true;
				});

			delete form.country;
			delete form.team_client_permissions;

			const clientContact = {
				...form,
				address: filteredAddresses,
				contact,
			};

			dispatch(clientActions.updateClientContact(clientContact))
				.then((data) => {
					onClientAddSuccess(data);
				})
				.catch((error) => {
					onClientAddFailure(error);
				});
		}
	};

	useEffect(() => {
		dispatch(profileActions.getAllCountries(Country.getAllCountries()));
	}, [dispatch]);

	useEffect(() => {
		if (clientContact) {
			setForm({
				...clientContact,
				address: clientContact?.address?.length
					? clientContact?.address?.map((add) => {
							return {
								...add,
								country: add?.country ? add?.country : companyProfileData?.country,
							};
					  })
					: [emptyAdress],
				contact: {
					email: clientContact?.contact?.email?.length
						? [...clientContact.contact.email]
						: [''],
					general: clientContact?.contact?.general?.length
						? [...clientContact.contact.general]
						: [''],
					direct: clientContact?.contact?.direct?.length
						? [...clientContact.contact.direct]
						: [''],
				},
			});
		}
	}, [clientContact]);

	useEffect(() => {
		dispatch(
			profileActions.getCountryStates(State.getStatesOfCountry(form?.address[0]?.country)),
		);
	}, [form?.address[0]?.country]);

	useEffect(() => {
		dispatch(
			profileActions.getSecondaryCountryStates(
				State.getStatesOfCountry(form?.address[1]?.country),
			),
		);
	}, [form?.address[1]?.country]);


	return (
		<form autoComplete='off' onSubmit={handleSubmit}>
			<div style={{ maxHeight: '80vh', overflow: 'auto' }} className='p-3'>
				<div className='improved-input-designs pb-3'>
					<label className='require'>First Name</label>
					<input
						className='w-100'
						type='text'
						name='first_name'
						value={form?.first_name}
						onChange={handleChange}
						required
					/>
				</div>
				<div className='improved-input-designs pb-3'>
					<label className='require'>Last Name</label>
					<input
						className='w-100'
						type='text'
						name='last_name'
						value={form?.last_name}
						onChange={handleChange}
						required
					/>
				</div>
				<div className='improved-input-designs pb-3'>
					<label>Title</label>
					<input
						className='w-100'
						type='text'
						name='title'
						value={form?.title}
						onChange={handleChange}
					/>
				</div>

				<div className='improved-input-designs pb-3'>
					<label className='d-flex justify-content-between'>
						<span>Email</span>
					</label>
					<input
						className='w-100'
						type='email'
						name='email'
						value={(form?.contact?.email?.length ? form?.contact?.email[0] : '').trim()}
						onChange={handleEmailChange(0)}
					/>
				</div>

				<div className='improved-input-designs pb-3'>
					<label className='d-flex justify-content-between'>
						<span>Secondary Email</span>
					</label>
					<input
						className='w-100'
						type='email'
						name='email'
						value={(form?.contact?.email?.length > 1
							? form?.contact?.email[1]
							: ''
						).trim()}
						onChange={handleEmailChange(1)}
					/>
				</div>

				<div className='phone-number-container pb-3'>
					<label className='d-flex justify-content-between align-items-center'>
						Phone
						{form?.contact?.direct?.length < 2 && (
							<span
								className='primary-text pointer BodyThreeLight pt-1'
								onClick={addNewTel}
							>
								Add New Phone
							</span>
						)}
					</label>

					<PhoneNumberInput
						name={'direct0'}
						value={form?.contact?.direct?.length ? form?.contact?.direct[0] : ''}
						handleChange={handleDirectChange}
						resetNumber={resetDirectNumber}
						isPhoneFormatted={isDirectFormatted}
						toggleFormatting={toggleDirectFormatting}
					/>
				</div>

				{form?.contact?.direct?.length == 2 && (
					<div className='phone-number-container pb-3'>
						<label className='d-flex justify-content-between align-items-center'>
							Phone (Secondary)
							<span
								className='primary-text pointer BodyThreeLight pt-1'
								onClick={removeTel}
							>
								Remove Phone
							</span>
						</label>
						<PhoneNumberInput
							name={'direct1'}
							value={form?.contact?.direct[1] || ''}
							handleChange={handleDirectChange}
							resetNumber={resetDirectNumber}
							isPhoneFormatted={isDirectFormatted}
							toggleFormatting={toggleDirectFormatting}
							hideToolTip={true}
						/>
					</div>
				)}

				<div className='phone-number-container pb-3'>
					<label className='d-flex justify-content-between align-items-center'>
						Mobile
						{form?.contact?.general?.length < 2 && (
							<span
								className='primary-text pointer BodyThreeLight pt-1'
								onClick={addNewGeneral}
							>
								Add New Mobile
							</span>
						)}
					</label>
					<PhoneNumberInput
						name='general0'
						value={form?.contact?.general?.length ? form?.contact?.general[0] : ''}
						handleChange={handleGeneralChange}
						resetNumber={resetGeneralNumber}
						isPhoneFormatted={isGeneralFormatted}
						toggleFormatting={toggleGeneralForrmatting}
					/>
				</div>

				{form?.contact?.general?.length == 2 && (
					<div className='phone-number-container pb-3'>
						<label className='d-flex justify-content-between align-items-center'>
							Mobile (Secondary)
							<span
								className='primary-text pointer BodyThreeLight pt-1'
								onClick={removeGeneral}
							>
								Remove Mobile
							</span>
						</label>
						<PhoneNumberInput
							name='general1'
							value={form?.contact?.general[1] || ''}
							handleChange={handleGeneralChange}
							resetNumber={resetGeneralNumber}
							isPhoneFormatted={isGeneralFormatted}
							toggleFormatting={toggleGeneralForrmatting}
							hideToolTip={true}
						/>
					</div>
				)}

				<div
					className=' mb-3'
					style={{
						background: '#F7F9FA',
						padding: '16px 24px',
						borderRadius: '4px',
						gap: '16px',
					}}
				>
					<label className='d-flex justify-content-between align-items-center'>
						<span className='BodyOneBold dark-text'>Address</span>

						{!form?.address[1] && (
							<span
								className='primary-text pointer BodyThreeLight pt-1'
								onClick={addSecondaryAddress}
							>
								Add Secondary Address
							</span>
						)}
					</label>

					<div className='d-flex flex-column'>
						<div className='improved-input-designs pb-3'>
							<label>Country</label>
							<Select
								value={{ value: form?.address[0]?.country || '' }}
								options={countries}
								name='country'
								onChange={handleAdressChange(0)}
							/>
						</div>
						<div className='improved-input-designs pb-3'>
							<label>Street</label>

							<EditableLocationText
								name={`street`}
								placeSelected={onAddressChange(0)}
								value={form?.address[0]?.street}
								onChange={onAddressChange(0)}
								Edit={true}
								countryAbbr={form?.address[0]?.country || ''}
							/>
						</div>

						<div className='improved-input-designs pb-3'>
							<label>Floor/Suite</label>

							<input
								className='w-100'
								type='text'
								name={`suite`}
								value={form?.address[0]?.suite}
								onChange={handleAdressChange(0)}
							/>
						</div>

						<div className='improved-input-designs pb-3'>
							<label>City/Town</label>

							<input
								className='w-100'
								type='text'
								name={`city`}
								value={form?.address[0]?.city}
								onChange={handleAdressChange(0)}
							/>
						</div>

						<div className='improved-input-designs pb-3'>
							<label>State/Province/Region</label>
							<Select
								value={{ value: form?.address[0]?.state }}
								options={states}
								name='state'
								onChange={handleAdressChange(0)}
							/>
						</div>

						<div className='improved-input-designs'>
							<label>Zip Code/Postal Code</label>

							<input
								className='w-100'
								type='text'
								name={`zip`}
								value={form?.address[0]?.zip}
								onChange={handleAdressChange(0)}
							/>
						</div>
					</div>
				</div>

				{form?.address[1] && (
					<div
						className=' mb-3'
						style={{
							background: '#F7F9FA',
							padding: '16px 24px',
							borderRadius: '4px',
							gap: '16px',
						}}
					>
						<label className='d-flex justify-content-between align-items-center'>
							<span className='BodyOneBold dark-text'>Secondary Address</span>
							<span
								className='primary-text pointer BodyThreeLight pt-1'
								onClick={removeSecondaryAddress}
							>
								Remove Address
							</span>
						</label>

						<div className='d-flex flex-column mx-2'>
							<div className='improved-input-designs pb-3'>
								<label>Country</label>
								<Select
									value={{ value: form?.address[1]?.country || '' }}
									options={countries}
									name='country'
									onChange={handleAdressChange(1)}
								/>
							</div>
							<div className='improved-input-designs pb-3'>
								<label>Street</label>
								<EditableLocationText
									name={`street`}
									placeSelected={onAddressChange(1)}
									value={form?.address[1]?.street}
									onChange={onAddressChange(1)}
									Edit={true}
									countryAbbr={form?.address[1]?.country || ''}
								/>
							</div>

							<div className='improved-input-designs pb-3'>
								<label>Floor/Suite</label>
								<input
									className='w-100'
									type='text'
									name={`suite`}
									value={form?.address[1]?.suite}
									onChange={handleAdressChange(1)}
								/>
							</div>

							<div className='improved-input-designs pb-3'>
								<label>City/Town</label>
								<input
									className='w-100'
									type='text'
									name={`city`}
									value={form?.address[1]?.city}
									onChange={handleAdressChange(1)}
								/>
							</div>

							<div className='improved-input-designs pb-3'>
								<label>State/Province/Region</label>
								<Select
									value={{ value: form?.address[1]?.state }}
									options={secondaryStates}
									name='state'
									onChange={handleAdressChange(1)}
								/>
							</div>

							<div className='improved-input-designs'>
								<label>Zip Code/Postal Code</label>
								<input
									className='w-100'
									type='text'
									name={`zip`}
									value={form?.address[1]?.zip}
									onChange={handleAdressChange(1)}
								/>
							</div>
						</div>
					</div>
				)}

				<div className='improved-input-designs pb-3'>
					<label className=''>Notes</label>
					<RichTextEditor
						className='primary-text pointer'
						value={form?.notes}
						type='text'
						Edit={true}
						rows={1}
						style={{
							height: '50px',
							lineHeight: '1rem',
							marginBottom: '3.4rem',
						}}
						name='notes'
						onChange={quillChange}
					/>
				</div>
			</div>

			{formControls.error && (
				<div className=' px-3 py-2 rounded-bottom'>
					<span className='error-text BodyTwoLight'>{formControls?.error}</span>
				</div>
			)}

			<div className='VerticalSpacer bg-white p-3 rounded-bottom'>
				<SubmitButtonsField child={true}>
					<button
						id='Element'
						className='secondary'
						type='button'
						onClick={handleOnCancel}
					>
						Cancel
					</button>

					<button
						type='submit'
						className='d-flex align-items-center'
						id='Element'
						data-cy='client-contant-submit-btn'
						disabled={formControls.loading}
					>
						{formControls?.loading ? (
							<CircularProgress size={24} sx={{ color: 'white' }} disableShrink />
						) : clientContact ? (
							'Update'
						) : (
							'Add'
						)}
					</button>
				</SubmitButtonsField>
			</div>
		</form>
	);
}

export default AddContact;
