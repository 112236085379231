import TableHeader from '../../Others/TableHeader';
import TableHeaderItem from '../../Others/TableHeaderItem';
import { TableItemWidth5 } from '../../TableSpacing/TableSpacingStyles';
import TableRowItem from '../../Others/TableRowItem';
import FormButtonOne from '../../Buttons/FormButtonOne';
import { clientPaymentModes, clientPaymentTypes } from '../../../utilities/utilities';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getUserSettings } from '../../../hooks/getUserSettings';

function ClientBillingHistory({ onCancel, data,currencySymbol }) {
	const tableHeaders = [
		'Payment Frequency',
		'Rate Start Date',
		'Rate End Date',
		'Payment Type',
		'Rate',
	];
    const userDetails = useSelector((state) => state.authReducer.userDetails);
    const { date_format }  = getUserSettings(userDetails);

	return (
		<div className='billing-history-modal'>
			<div style={{ height: '90%' }} className='table-body'>
				<div className='table-body-child'>
					<div style={{ padding: '16px 24px 0px 18px' }}>
						<span className='HeadlineTwoBold'>Billing History </span>
					</div>
					<div className='profile-section-body'>
						<div className='billing-history-body'>
							<TableHeader ClassName='new-table-header' border={false}>
								{tableHeaders?.map((header, key) => {
									return (
										<TableHeaderItem
											key={key}
											label={header}
											width={TableItemWidth5}
										/>
									);
								})}
							</TableHeader>
							<div className='ListingLoading scroll'>
								{data?.map((client, key) => {
									return (
										<div
											className='new-table-row d-flex align-items-center'
											key={key}
										>
											<TableRowItem
												ClassName='text-left'
												label={
													clientPaymentModes?.find(
														(item) =>
															item.value == client?.payment_mode,
													)?.label || '----------'
												}
												width={TableItemWidth5}
												input={false}
											/>

											{console.log(client, 'clientCheck')}

											<TableRowItem
												ClassName='text-left'
												label={moment(client?.bill_start_date).format(date_format)}
												width={TableItemWidth5}
												input={false}
											/>
											<TableRowItem
												ClassName='text-left'
												label={
													client?.bill_end_date
														? moment(client?.bill_end_date).format(date_format)
														: client?.bill_end_date || '----------'
												}
												width={TableItemWidth5}
												input={false}
											/>
											<TableRowItem
												ClassName='text-left'
												label={
													clientPaymentTypes?.find(
														(item) =>
															item.value == client?.payment_type,
													)?.label
												}
												width={TableItemWidth5}
												input={false}
											/>
											<TableRowItem
												ClassName='text-left'
												label={`${currencySymbol}${client?.rate}`}
												width={TableItemWidth5}
												input={false}
											/>
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div style={{ height: '10%' }} className='billing-history-actions'>
				<FormButtonOne onClick={onCancel} type='button' className='secondary-btn-light'>
					Close
				</FormButtonOne>
			</div>
		</div>
	);
}

export default ClientBillingHistory;
