import { secure_instance } from '../../axios/axios-config';
import * as userActions from '../actions/user-management.actions';

export const SET_TEAM_MEMBERS = '[Team Management] SET_TEAM_MEMBERS';
export const RESET_NEW_TEAM_MEMBER_VALUES = '[Team Management] RESET_NEW_TEAM_MEMBER_VALUES';
export const GET_TEAMS_END = '[Team Management] GET_TEAMS_END';
export const GET_TEAMS_START = '[Team Management] GET_TEAMS_START';
export const GET_TEAMS_FAILED = '[Team Management] GET_TEAMS_FAILED';
export const ADD_TEAM_START = '[Team Management] ADD_TEAM_START';
export const ADD_TEAM_END = '[Team Management] ADD_TEAM_END';
export const ADD_TEAM_FAILED = '[Team Management] ADD_TEAM_FAILED';
export const UPDATE_TEAM_END = '[Team Management] UPDATE_TEAM_END';
export const DELETE_TEAM_END = '[Team Management] DELETE_TEAM_END';
export const DELETE_TEAM_START = '[Team Management] DELETE_TEAM_START';
export const DELETE_TEAM_FAILED = '[Team Management] DELETE_TEAM_FAILED';
export const DELETE_TEAMS_END = '[Team Management] DELETE_TEAMS_END';
export const DELETE_TEAMS_START = '[Team Management] DELETE_TEAMS_START';
export const DELETE_TEAMS_FAILED = '[Team Management] DELETE_TEAMS_FAILED';
export const SET_TEAM_ID = '[Team Management] SET_TEAM_ID';
export const HANDLE_INPUT_CHANGE = '[Team Management] HANDLE_INPUT_CHANGE';
export const UPDATE_TEAM_DETAILS = '[Team Management] UPDATE_TEAM_DETAILS';
export const GET_TEAM_MEMBERS = '[Team Management] GET_TEAM_MEMBERS';
export const SET_FILTERED_CLIENTS = '[Team Management] SET_FILTERED_CLIENTS';
export const RESET_CLIENTS_FILTER = '[Team Management] RESET_CLIENTS_FILTER';
export const GET_FILTERED_TEAM_MANAGEMENT = '[Team Management] GET_FILTERED_TEAM_MANAGEMENT';
export const ADD_TEAM_MEMBER_START = '[Team Management] ADD_TEAM_MEMBER_START';
export const ADD_TEAM_MEMBER_END = '[Team Management] ADD_TEAM_MEMBER_END';
export const HANDLE_NEW_TEAM_MEMBER_CHANGE = '[Team Management] HANDLE_NEW_TEAM_MEMBER_CHANGE';
export const HANDLE_TEAM_MEMBER_CHANGE = '[Team Management] HANDLE_TEAM_MEMBER_CHANGE';
export const DELETE_TEAM_MEMBER_START = '[Team Management] DELETE_TEAM_MEMBER_START';
export const DELETE_TEAM_MEMBER_END = '[Team Management] DELETE_TEAM_MEMBER_END';
export const UPDATE_TEAM_MEMBERS_START = '[Team Management] UPDATE_TEAM_MEMBERS_START';
export const UPDATE_TEAM_MEMBERS_END = '[Team Management] UPDATE_TEAM_MEMBERS_END';
export const ASSIGN_NEW_TEAM = '[Team Management] ASSIGN_NEW_TEAM';
export const UPDATE_TEAM_MEMBERS_FAILED = '[Team Management] UPDATE_TEAM_MEMBERS_FAILED';
export const SET_ARCHIVE_LOADING = 'SET_ARCHIVE_LOADING';
export const HANDLE_ALL_TEAM_MEMBER_CHANGE = 'HANDLE_ALL_TEAM_MEMBER_CHANGE';
export const SET_DATES = '[Team Management] SET_DATES';
export const SET_MONTHLY_VIEW = '[Team Management] SET_MONTHLY_VIEW';

export const setArchiveLoading = (state) => {
	return (dispatch) => {
		dispatch({
			type: SET_ARCHIVE_LOADING,
			payload: state,
		});
	};
};

export const getTeams = (filters) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: GET_TEAMS_START,
			});

			const queryString = filters
				?.map((arr, index) => {
					if (index === filters?.length - 1) {
						return 'userIds[]=' + arr;
					} else {
						return 'userIds[]=' + arr + '&';
					}
				})
				.join('');

			const request = await secure_instance.request({
				url: `v1/teams/?${queryString ? queryString : ''}`,
				method: 'Get',
			});

			console.log(request);
			dispatch({
				type: GET_TEAMS_END,
				payload: request.data,
			});

			return request.data;
		} catch (e) {
			dispatch({
				type: GET_TEAMS_FAILED,
			});
			throw new Error(e?.response?.data?.message || e?.message || 'Something went wrong');
		}
	};
};

export const setTeamId = (id) => {
	return {
		type: SET_TEAM_ID,
		payload: id,
	};
};

export const setTeamMembers = (array) => {
	return (dispatch) => {
		dispatch({
			type: SET_TEAM_MEMBERS,
			payload: array,
		});
	};
};

export const getFilteredTeamManagement = () => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: 'v1/teams/filter_management',
				method: 'Get',
			});

			dispatch({
				type: GET_FILTERED_TEAM_MANAGEMENT,
				payload: request.data,
			});
		} catch (e) {
			//
		}
	};
};

export const getTeamMembers = (id) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: GET_TEAMS_START,
			});

			const request = await secure_instance.request({
				url: `v1/teams/${id}/?expand=user_teams(notDeleted).user_profile`,
				method: 'Get',
			});

			const roles = await secure_instance.request({
				url: `v1/user_roles/?expand=[roles, role_scopes]`,
				method: 'Get',
			});

			dispatch({
				type: GET_TEAM_MEMBERS,
				payload: {
					teamDetails: request.data,
					userTeams: request.data.user_teams,
					roles: roles.data,
					team_id: id,
				},
			});

			dispatch({
				type: GET_TEAMS_FAILED,
			});
		} catch (e) {
			//
			dispatch({
				type: GET_TEAMS_FAILED,
			});
		}
	};
};

export const resetTeamProfileData = () => {
	return (dispatch) => {
		dispatch({
			type: GET_TEAMS_START,
		});

		dispatch({
			type: GET_TEAM_MEMBERS,
			payload: {
				teamDetails: null,
				userTeams: [],
			},
		});
	};
};

export const addTeam = (data, toggleModal) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: 'v1/teams/',
				method: 'Post',
				data,
			});

			dispatch({
				type: ADD_TEAM_END,
				payload: request.data,
			});

			toggleModal();
		} catch (e) {
			//
		}
	};
};

export const addTeamMember = (data, id) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: ADD_TEAM_MEMBER_START,
			});

			await secure_instance.request({
				url: 'v1/user_teams/',
				method: 'Post',
				data,
			});

			dispatch({
				type: ADD_TEAM_MEMBER_END,
			});

			id && dispatch(getTeamMembers(id));
		} catch (e) {
			//
		}
	};
};

export const updateTeam = (data, toggleModal) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `v1/teams/${data.id}`,
				method: 'Patch',
				data,
			});

			dispatch({
				type: UPDATE_TEAM_END,
				payload: request.data,
			});

			toggleModal();
		} catch (e) {
			//
		}
	};
};

export const deleteTeam = (id) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: DELETE_TEAM_START,
			});

			await secure_instance.request({
				url: `v1/teams/${id}`,
				method: 'Delete',
			});

			dispatch({
				type: DELETE_TEAM_END,
				payload: id,
			});
		} catch (e) {
			dispatch({
				type: DELETE_TEAM_FAILED,
			});
		}
	};
};

export const deleteTeams = (ids) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: DELETE_TEAMS_START,
			});

			await secure_instance.request({
				url: `v1/teams/`,
				method: 'Delete',
				data: {
					ids,
				},
			});

			dispatch({
				type: DELETE_TEAMS_END,
				payload: ids,
			});
		} catch (e) {
			dispatch({
				type: DELETE_TEAMS_FAILED,
			});
		}
	};
};

export const deleteTeamMember = (id, toggleModal) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: DELETE_TEAM_MEMBER_START,
			});

			await secure_instance.request({
				url: `v1/user_teams/${id}`,
				method: 'Delete',
			});

			dispatch({
				type: DELETE_TEAM_MEMBER_END,
				payload: id,
			});

			toggleModal();
		} catch (e) {
			//
		}
	};
};

export const updateTeamDetails = (data) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `v1/teams/${data.id}`,
				method: 'Patch',
				data,
			});

			// dispatch({
			// 	type: UPDATE_TEAM_DETAILS,
			// 	payload: request.data,
			// });
			return request.data;
		} catch (e) {
			throw new Error(e?.response?.data?.message || e?.message || 'Something went wrong');
		}
	};
};

export const updateTeamMembers = (data, id) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: UPDATE_TEAM_MEMBERS_START,
			});

			await secure_instance.request({
				url: 'v1/user_roles/team_update',
				method: 'Patch',
				data,
			});

			dispatch({
				type: UPDATE_TEAM_MEMBERS_END,
			});

			id && dispatch(getTeamMembers(id));
		} catch (e) {
			dispatch({
				type: UPDATE_TEAM_MEMBERS_FAILED,
			});
		}
	};
};

export const handleInputChange = (data) => {
	return {
		type: HANDLE_INPUT_CHANGE,
		payload: data,
	};
};

export const resetClientsFilter = (data) => {
	return {
		type: RESET_CLIENTS_FILTER,
		payload: data,
	};
};

export const handleNewTeamMemberChange = (data) => {
	return {
		type: HANDLE_NEW_TEAM_MEMBER_CHANGE,
		payload: data,
	};
};

export const resetNewTeamMemberValues = () => {
	return {
		type: RESET_NEW_TEAM_MEMBER_VALUES,
	};
};

export const handleTeamMemberChange = (data) => {
	return {
		type: HANDLE_TEAM_MEMBER_CHANGE,
		payload: data,
	};
};

export const handleAllMemberChange = (teamId, teamMembers) => {
	return {
		type: HANDLE_ALL_TEAM_MEMBER_CHANGE,
		payload: { teamId, teamMembers },
	};
};

export const setFilteredClients = (data) => {
	return {
		type: SET_FILTERED_CLIENTS,
		payload: data,
	};
};

export const assignTeamRole = (data, callBack) => {
	return async (dispatch) => {
		try {
			await secure_instance.request({
				url: `/v1/user_roles/`,
				method: 'post',
				data,
			});

			callBack();
		} catch (e) {
			//
		}
	};
};

export const removeTeamRole = (data, callBack) => {
	return async (dispatch) => {
		try {
			await secure_instance.request({
				url: `/v1/user_roles/role`,
				method: 'DELETE',
				data,
			});

			callBack();
		} catch (e) {
			//
		}
	};
};

export const updateTeamRole = (data) => {
	return async (dispatch) => {
		try {
			dispatch(userActions.setProfileLoading(true));

			await secure_instance.request({
				url: `/v1/user_roles/`,
				method: 'patch',
				data,
			});

			dispatch(userActions.setProfileLoading(false));
		} catch (e) {
			dispatch(userActions.setProfileLoading(false));
			//
		}
	};
};

export const archiveTeam = (archiveData, teamId, callBack) => {
	return async (dispatch) => {
		try {
			dispatch(setArchiveLoading(true));

			await secure_instance.request({
				url: `/v1/teams/archive_team/${teamId}`,
				method: 'patch',
				data: archiveData,
			});

			setTimeout(() => {
				callBack && callBack();

				dispatch(setArchiveLoading(false));
			}, 3000);
		} catch (e) {
			setTimeout(() => {
				dispatch(setArchiveLoading(false));

				if (e?.response) {
					alert(e?.response?.data?.message);
				}
			}, 3000);
		}
	};
};

export const setDates = (start_date, end_date) => {
	return {
		type: SET_DATES,
		payload: {
			start_date: start_date,
			end_date: end_date,
		},
	};
};

export const setMonthlyView = (monthlyView) => {
	return {
		type: SET_MONTHLY_VIEW,
		payload: monthlyView,
	};
};
