import React, { useState, useEffect } from 'react';

// Material Ui Components
import { CircularProgress, Modal } from '@mui/material';

// Importing Components
import SubmitButtonsField from '../Buttons/SubmitButtonsField';
import PickList from '../Inputs/PickList';
import { useDispatch, useSelector } from 'react-redux';
import * as teamActions from '../../redux/actions/team-management.actions';
import * as userActions from '../../redux/actions/user-management.actions';
import {
	capitalize,
	convertDecimalToTime,
	convertToDecimal,
	executivePermissions,
	Member,
} from '../../utilities/utilities';
import MultipleDropDown from '../Inputs/MultipleDropDown';
import { useHistory } from 'react-router';
import UserArchivedAlreadyModal from '../Modal/UserArchivedAlreadyModal';
import UnarchiveUserModal from './UnarchiveUserModal';
import useModal from '../Modal/useModal';
import ArchiveUserSuccessfullyModal from './ArchiveUserSuccessfullyModal';
import useEditTimeFormat from '../../hooks/useEditTimeFormat';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { ReactComponent as CalendarIcon } from '../../assets/icons/Calendarcolor.svg';
import { getUserSettings } from '../../hooks/getUserSettings';

function UserAdd({ onCancel, addNewTeam }) {
	const dispatch = useDispatch();
	const history = useHistory();

	const [multiSelectOpen, setMultiSelectOpen] = useState(false);
	const [existingArchiveUserModalOpen, setExistingArchiveUserModalOpen] =
		useState();
	const [userId, setUserId] = useState(null);
	const { ModalName, ModalType } = useModal();

	let roles = useSelector((state) => state.userReducer.roles);
	const teams = useSelector((state) => state.teamReducer.teams);
	const user = useSelector((state) => state.authReducer.user);
	const errors = useSelector((state) => state.userReducer.errors);
    const userDetails = useSelector((state) => state.authReducer.userDetails);
    const { date_format }  = getUserSettings(userDetails);

	const InitialForm = {
		name: '',
		fname: '',
		lname: '',
		email: '',
		role_id: 0,
		teams: [],
		image: '',
		hiring_date: '',
	};

	const [FormFields, setFormFields] = useState(InitialForm);
	const [FormFieldsErr, setFormFieldsErr] = useState({});
	const [totalTeams, setTotalTeams] = useState([]);

	if (user?.userRole?.id == Member.supervisorId) {
		roles = roles?.filter((role) => role.id !== Member.supervisorId);
	}

	const isAllowed = executivePermissions?.includes(user?.userRole?.id);

	const [Load, setLoad] = useState(false);

	const handleHiringDateChange = (date) => {
		setFormFields({
			...FormFields,
			hiring_date: date,
		});
	};

	const FormChange = (e) => {
		if (e.target.value == 'callback') {
			addNewTeam();
		}
		if (e.target.name == 'teams') {
			setFormFields({
				...FormFields,
				[e.target.name]: [parseInt(e.target.value)],
			});
		} else if (e.target.name == 'role_id') {
			setFormFields({
				...FormFields,
				[e.target.name]: parseInt(e.target.value),
			});
		} else {
			const name = e.target.name;
			if (
				(name === 'fname' || name === 'lname') &&
				e.target.value.length > 125
			) {
				setFormFieldsErr({
					...FormFieldsErr,
					[name]: 'Max limit is 125 characters',
				});
				return;
			}

			setFormFieldsErr({
				...FormFieldsErr,
				[name]: '',
			});
			setFormFields({
				...FormFields,
				[e.target.name]: e.target.value,
			});
		}
	};

	const setTeams = (teams) => {
		setFormFields({
			...FormFields,
			teams: teams,
		});
	};

	const FormSubmit = (e) => {
		e.preventDefault();
		// checking if there are any errors
		const hasErrors = Object.values(FormFieldsErr).some(
			(value) => value.trim().length > 0,
		);
		if (hasErrors) {
			return;
		}

		setLoad(true);
		FormFields.name = capitalize(FormFields.fname) + ' ' + FormFields.lname;
		console.log(FormFields, '123321');
		dispatch(
			userActions.addUser(
				FormFields,
				onCancel,
				setLoad,
				setExistingArchiveUserModalOpen,
				setUserId,
				ModalType,
			),
		);

		dispatch(userActions.clearImage());
	};

	const showUnArchiveUsersModal = () => {
		ModalType('UNARCHIVE_USER');
	};

	useEffect(() => {
		dispatch(teamActions.getTeams());
		dispatch(userActions.getRoles());
	}, [dispatch]);

	useEffect(() => {
		const ManagerTeams = [];
		user?.user_roles?.map((item) => {
			if (item?.role_id === Member.teamManagerId) {
				item?.role_scopes?.map((role) => {
					ManagerTeams.push(role.team_id);
					return role;
				});
			}
			return item;
		});
		if (ManagerTeams?.length > 0) {
			setFormFields({
				...FormFields,
				role_id: Member.teamMemberId,
			});
			setTotalTeams(teams?.filter((item) => ManagerTeams?.includes(item.id)));
		} else {
			setTotalTeams(teams);
		}
	}, [teams.length]);

	return (
		<>
			<form
				data-cy='add-user-form'
				autoComplete='off'
				onSubmit={FormSubmit}
				className='UserAdd-Form'
			>
				<div className='p-3'>
					<div className='improved-input-designs pb-3'>
						<label className='w-100 require'>First Name</label>
						<input
							className='w-100'
							type='text'
							name='fname'
							onChange={FormChange}
							value={FormFields.fname}
							data-cy='user-fname'
							required
						/>
						{FormFieldsErr?.fname && (
							<span className='err-text'>{FormFieldsErr.fname}</span>
						)}
					</div>

					<div className='improved-input-designs pb-3'>
						<label className='w-100 require'>Last Name</label>
						<input
							className='w-100'
							type='text'
							name='lname'
							onChange={FormChange}
							value={FormFields.lname}
							data-cy='user-lname'
							required
						/>
						{FormFieldsErr?.lname && (
							<span className='err-text'>{FormFieldsErr?.lname}</span>
						)}
					</div>

					<div className='improved-input-designs pb-3'>
						<label className='require'>Email</label>
						<input
							type='email'
							className='w-100'
							name='email'
							onChange={FormChange}
							value={FormFields.email}
							data-cy='user-email'
							required
						/>
					</div>

					<div className='improved-input-designs pb-3'>
						<label className='require'>Role</label>
						<PickList
							value={FormFields.role_id}
							SelectRole={FormChange}
							options={roles.filter((role) => role.id != 15)}
							Name='role_id'
							cypressId='user-role'
							selectDisabled={
								user?.user_roles
									?.map((item) => item.role_id)
									?.includes(Member.teamManagerId)
									? true
									: false
							}
							require={true}
						/>
					</div>

					<div className='improved-input-designs pb-3'>
						<label className='require'>Team</label>

						{FormFields?.role_id === Member.supervisorId ? (
							<MultipleDropDown
								label={'Teams'}
								toggleSelect={() => setMultiSelectOpen(!multiSelectOpen)}
								isOpen={multiSelectOpen}
								options={totalTeams?.map((item) => {
									return {
										label: item.name,
										value: item.id,
										is_checked: FormFields?.teams?.find(
											({ value }) => value === item.value,
										)?.is_checked
											? true
											: false,
									};
								})}
								changeHandler={(teams) => {
									setTeams(
										teams
											?.filter((item) => item.is_checked)
											?.map(({ value }) => value),
									);
								}}
								required={true}
							/>
						) : (
							<PickList
								value={FormFields.team_id}
								SelectRole={FormChange}
								options={totalTeams}
								Name='teams'
								require={true}
								customAction={isAllowed}
								customText='Add New Team'
								multiple={
									FormFields?.role_id === Member.supervisorId ? true : false
								}
								cypressId='user-team'
							/>
						)}
					</div>
					<div className='improved-input-designs pb-3'>
						<label className='w-100'>Hiring Date</label>
						<KeyboardDatePicker
							autoOk
							value={FormFields?.hiring_date || null}
							name='date'
							onChange={handleHiringDateChange}
							format={date_format}
							keyboardIcon={<CalendarIcon />}
							disablePast={false}
                           
						/>
					</div>

					<div>
						{errors?.message  && (
							<span style={{ color: 'red', fontSize: '0.9em' }}>
								{errors?.message}
							</span>
						)}
					</div>
				</div>

				<div className='VerticalSpacer bg-white p-3 rounded-bottom'>
					<SubmitButtonsField child={true}>
						<button
							id='Element'
							className='secondary'
							type='button'
							onClick={() => {
								dispatch(userActions.clearImage());
								onCancel();
							}}
						>
							Cancel
						</button>

						<button
							type='submit'
							className='d-flex align-items-center'
							id='Element'
							disabled={Load}
							data-cy='add-user-btn'
						>
							{Load ? (
								<CircularProgress
									size={24}
									sx={{ color: 'white' }}
									disableShrink
								/>
							) : (
								'Create'
							)}
						</button>
					</SubmitButtonsField>
				</div>
			</form>

			{existingArchiveUserModalOpen && (
				<Modal
					onClose={() => setExistingArchiveUserModalOpen(false)}
					open={existingArchiveUserModalOpen}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<>
						{ModalName == 'ARCHIVED_USER' && (
							<UserArchivedAlreadyModal
								onClose={() => setExistingArchiveUserModalOpen(false)}
								onProceed={showUnArchiveUsersModal}
							/>
						)}

						{ModalName == 'UNARCHIVE_USER' && (
							<UnarchiveUserModal
								FormFields={FormFields}
								user={{ id: userId }}
								toggleModal={() => setExistingArchiveUserModalOpen(false)}
								title='Select a team and role to unarchive the user'
								setUnarchiveSuccessfully={() => {
									ModalType('UNARCHIVE_SUCESS');
								}}
							/>
						)}

						{ModalName == 'UNARCHIVE_SUCESS' && (
							<ArchiveUserSuccessfullyModal
								onClose={() => {
									setExistingArchiveUserModalOpen(false);
									onCancel();
									dispatch(userActions.getUsers());
								}}
							/>
						)}
					</>
				</Modal>
			)}
		</>
	);
}

export default UserAdd;
