/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState } from 'react';
import Timer from 'react-compound-timer';

function TimeTracking({ consumedTime, timeLoggedRef }) {
	const [timeLogged, setTimeLogged] = useState(timeLoggedRef.current);

	const timeFormat = (time) => time.toString().padStart(2, '0');

	const handleLoggedTime = (time) => {
		timeLoggedRef.current = time;
		setTimeLogged(time);
	};

	const compareTimeLoggedWithConsumedTime = () => {
		if (parseInt(consumedTime) <= parseInt(timeLogged)) return timeLogged;
		if (parseInt(consumedTime) > parseInt(timeLogged)) return consumedTime;
	};

	return (
		<Timer
			initialTime={parseInt(consumedTime ? compareTimeLoggedWithConsumedTime() : timeLogged)}
		>
			{({ getTime }) => {
				handleLoggedTime(getTime());
				return (
					<div
						className='d-flex align-items-center justify-content-center'
						style={{
							width: '120px',
							height: '20px',
							background: '#FB7A03',
							borderRadius: '50px',
							padding: '2px 8px',
							marginLeft: '8px',
						}}
					>
						<span className='BodyOneBold text-white'>
							<Timer.Hours formatValue={timeFormat} /> :{' '}
							<Timer.Minutes formatValue={timeFormat} /> :{' '}
							<Timer.Seconds formatValue={timeFormat} />
						</span>
					</div>
				);
			}}
		</Timer>
	);
}

export default TimeTracking;
