import  { memo } from 'react';

const TeamMultiPickList = (props) => {
	const { className, label, options, children, onChange, onClick } = props;

	return (
		<div className='TeamMultiPickList'>
			<div className={`TeamMultiPickList ${className}`} label={label}>
				{options.map((choice, index) => {
					return (
						<div key={index} className='ItemWrapper  the-item-wrapper'>
							<div className='w-100 pointer' onClick={() => onClick(choice.value)}>
								<h4 data-toggle="tooltip" style={{textOverflow:'ellipsis', width:'110px', whiteSpace:'nowrap', overflow:'hidden'}} data-placement="top" title={choice.label} className='BodyTwoLight m-0'>{choice.label}</h4>
							</div>
							<input
								type='checkbox'
								className='multi-select-checkbox'
								onChange={onChange}
								value={choice.value}
								checked={choice.is_checked}
							/>
						</div>
					);
				})}
				{children}
			</div>
		</div>
	);
};

export default memo(TeamMultiPickList);
