import React from 'react';

import Icon from './Icon';

const ProgressIcon = (props) => {

  const { fill = '#000' } = props;

  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd" transform="translate(2.5 3)">
        <path
          className="fill-target"
          d="M7 14H5V7h2v7zm4 0H9V4h2v10zm4 0h-2v-4h2v4zm2.5 2.1h-15V2h15v14.1zm0-16.1h-15C1.4 0 .5.9.5 2v14c0 1.1.9 2 2 2h15c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2z"
          fill={fill}
        />
        <path d="M-2-3h24v24H-2z" />
      </g>
    </Icon>
  );
};

export default ProgressIcon;
