import { Box } from '@mui/material';
import React, { useState } from 'react';
import { backgroundGrey } from '../Style';

const AccountSettingsHeader = () => {
	const [activeTab, setActiveTab] = useState(1);

	const ChangeActiveTab = (e) => {
		setActiveTab(parseInt(e.target.id));
	};

	return (
		<>
			<Box
				sx={{
					backgroundColor: `#FCFDFD`,
					width: '100%',
					height: '60px',
					display: 'flex',
					alignItems: 'center',
					gap: '1.5em',
					boxSizing: 'border-box',
					paddingLeft: '32px',
					marginTop: '10px' 
				}}
			>
				<Box>
					<h1 className='HeadlineOneBold light-dark' >Profile Settings</h1>
				</Box>
			</Box>
			{/* <div className='topBar' style={{paddingLeft:'0px'}}>
				<div  className='d-flex align-items-center w-100' style={{backgroundColor:'#f6f5f2', paddingLeft:'45px', paddingBlock:'10px',borderBottom: '1px solid #eaeaea',height:'60px'}}>
					<h6 className='HeadlineOneBold font-weight-bolder light-dark'>Profile Settings</h6>
				</div> */}
			{/* <div className='bar light-dark ' style={{paddingLeft:'50px', marginBlock:'5px', borderBottom: '1px solid #eaeaea'}}>
					<div className='BodyOneBold tab'>
						<div
							className='tabLabel mt-2 mb-1 pb-2'
							onClick={() => {
								setActiveTab(1);
							}}
						>
							<a onClick={ChangeActiveTab}
								id='1'
								className={
									activeTab == '1'
										? 'BodyOneBold font-weight-bold active'
										: 'BodyOneBold font-weight-bold'
								} href='#profile'
							>
							Profile</a>
						</div>

						<div
							className={activeTab == 1 ? 'companyTabActive' : 'd-none'}
						></div>
					</div>

					<div className='BodyOneBold tab'>
						<div
							className='tabLabel mt-2 mb-1 pb-2'
							onClick={() => {
								setActiveTab(2);
							}}
						>
							<a onClick={ChangeActiveTab}
								id='2'
								className={
									activeTab == '2'
										? 'BodyOneBold font-weight-bold active'
										: 'BodyOneBold font-weight-bold'
								} href='#leaves'
							>Leaves</a>
						</div>

						<div
							className={activeTab == 2 ? 'companyTabActive' : 'd-none'}
						></div>
					</div>

					<div className='BodyOneBold tab'>
						<div
							className='tabLabel mt-2 mb-1 pb-2'
							onClick={() => {
								setActiveTab(3);
							}}
						>
							<a onClick={ChangeActiveTab}
								id='3'
								className={
									activeTab == '3'
										? 'BodyOneBold font-weight-bold active'
										: 'BodyOneBold font-weight-bold'
								} href='#security'
							>Security</a>
						</div>

						<div
							className={activeTab == 3 ? 'companyTabActive' : 'd-none'}
						></div>
					</div>

					<div className='BodyOneBold tab '>
						<div
							className='tabLabel mt-2 mb-1 pb-2'
							onClick={() => {
								setActiveTab(4);
							}}
						>
							<a onClick={ChangeActiveTab}
								id='4'
								className={
									activeTab == '4'
										? 'BodyOneBold font-weight-bold active'
										: 'BodyOneBold font-weight-bold'
								} href='#integration'
							>Integration</a>
						</div>

						<div
							className={activeTab == 4 ? 'companyTabActive' : 'd-none'}
						></div>
					</div>
				</div> */}
			{/* </div>
			<div></div> */}
		</>
	);
};
export default AccountSettingsHeader;
