import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getUserSettings } from '../../hooks/getUserSettings';

export default function EditableDate({
	label,
	name,
	value,
	onChange,
	Edit,
	style,
	disablePast,
	helperText,
	labelClass,
	minDate,
    required,
    disabled
}) {
    const userDetails = useSelector((state) => state.authReducer.userDetails);
    const { date_format }  = getUserSettings(userDetails);

	return (
		<div className='ProfilePage d-flex flex-column w-100'>
			<label className={`BodyTwoLight text-left m-0 ${labelClass}`}>{label}</label>
			{Edit ? (
				<KeyboardDatePicker
					autoOk
					value={value || null}
					name='date'
					onChange={onChange}
					format={date_format}
					disablePast={disablePast || false}
					helperText={helperText}
					minDate={minDate}
                    required={required}
                    disabled={disabled}
				/>
			) : (
				<h3 className='BodyTwoLight m-0 text-left' name={name} style={style}>
					{value && moment(value).format(date_format)}
				</h3>
			)}
		</div>
	);
}
