import axios from 'axios';
import baseURL from '../utilities/BaseUrl';
const API_URL = baseURL;
import store from '../index';

export const noAuth = (URL, method, DATA) => {
	console.log(`${API_URL}`);
	return axios({
		method: method,
		url: API_URL + URL,
		data: DATA,
		headers: {
			'Content-Type': 'application/json',
		},
	})
		.then((response) => {
			console.log('response', response);

			return response;
		})
		.catch((err) => {
			console.log('error', JSON.stringify(err.response));
			return err;
		});
};

export const Auth = async (URL, method, DATA) => {
	const state = store.getState();
	const user = state.authReducer.user;

	const access_token = user.accessToken;
	// const tokenDetails = localStorage.getItem('persist:root');
	// const token = JSON.parse(tokenDetails);
	// const access_token = JSON.parse(token.Token).access_token;
	// const expires_at = JSON.parse(token.Token).expires_at;
	// const refresh_token = JSON.parse(token.Token).refresh_token;
	// const refresh_token_expires = JSON.parse(token.Token).refresh_token_expires;
	// var isExpired = false;
	// const tokenToCheck = refresh_token;
	// var decodedToken: any = jwt.decode(token, { complete: true });
	// var dateNow = new Date();

	// if (decodedToken.exp < dateNow.getTime()) isExpired = true;
	const BearerToken = async () => {
		return access_token;
	};
	const result = await BearerToken();
	return axios({
		method: method,
		url: API_URL + URL,
		data: DATA,
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${result}`,
			user: result,
		},
	})
		.then((response) => {
			// console.log(
			//   `'response',${JSON.stringify(result)}'and.....'${expires_at}`,
			// );

			return response;
		})
		.catch((response) => {
			console.log('error', JSON.stringify(response));
			return response;
		});
};
