import React, { useState, useEffect, useCallback } from 'react';

// Components
import ProfileBar from './ProfileBar';
import PersonalDetails from './PerosnalDetails';
import Teams from './Teams';
import Sidebar from '../sidebar/SideBar';

import { useParams, useHistory } from 'react-router-dom';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import * as teamActions from '../../redux/actions/team-management.actions';
import * as userActions from '../../redux/actions/user-management.actions';

import { Member } from '../../utilities/utilities';
import UserBilling from '../AccountSettings/UserBilling';
import NewSidebarItem from '../sidebar/NewSidebarItem';
import AlertBar from '../sub_components/AlertBar';

function UserProfile() {
	const dispatch = useDispatch();
	const history = useHistory();
	const { id } = useParams();
	const user = useSelector((state) => state.authReducer.user);

	let sidemenu = [
		{ name: 'User Details', sectionId: 'User' },

		{ name: 'Teams & Roles', sectionId: 'Teams' },
	];

	if (user?.userRole?.id == Member.ownerId || user?.userRole?.id == Member?.superAdminId) {
		sidemenu = [...sidemenu, { name: 'User Billing', sectionId: 'user-billing' }];
	}

	const [activeTab, setActiveTab] = useState('User');
	const Profile = useSelector((state) => state.userReducer.userProfile);
	const specificUserBilling = useSelector((state) => state.userReducer.specificUserBilling);

	const tabChangeHandler = useCallback(
		(tab) => () => {
			setActiveTab(tab);
		},
		[],
	);

	useEffect(() => {
		dispatch(userActions.getUserProfile(id));
		return () => {
			dispatch(userActions.resetUserProfile());
			dispatch(userActions.setSpecificUserBilling([]));
		};
	}, [id]);

    useEffect(() => {
		if ((id || user?.userId) && specificUserBilling?.length < 1) {
			dispatch(userActions.getSpecificUserBilling(id || user?.userId));
		}
	}, [dispatch, id]);

	useEffect(() => {
		dispatch(teamActions.getTeams());
	}, []);

	return (
		<div className='AppBody'>
			<Sidebar title='User Management'>
				<div className='new-sidebar-content-wrapper'>
					<div className='User-filters'>
						<div className='new-side-menu BodyTwoLight-bold m-0 d-flex flex-column align-items-start'>
							{sidemenu?.map((item, index) => {
								const prevOfActive = sidemenu[index + 1]?.sectionId == activeTab;
								return (
									<>
										{index == 0 && (
											<div
												style={{ padding: '8px' }}
												className={`w-100 main-sidebar-item ${
													sidemenu[0].sectionId == activeTab ? 'prev' : ''
												}`}
											></div>
										)}
										<NewSidebarItem
											key={item.sectionId}
											uniqueId={item.sectionId}
											label={item.name}
											isActive={activeTab == item.sectionId}
											setActiveTab={tabChangeHandler}
										/>
										<div
											style={{ padding: '8px' }}
											className={`w-100 main-sidebar-item ${
												prevOfActive ? 'prev' : ''
											}`}
										></div>
									</>
								);
							})}
						</div>
					</div>
				</div>
			</Sidebar>
            <div
				style={{
					width: 'calc(100vw - 260px)',
					height: '100vh',
				}}
			>
                <AlertBar/>
                <div className='UserProfile-main user-profile-main'>
                    <div className='UserProfile-content'>
                        <ProfileBar
                            BarText={`${Profile?.full_name}`}
                            backToList={() => history.push('/users')}
                        />

                        {activeTab === 'User' && <PersonalDetails />}

                        {activeTab === 'Teams' && <Teams />}

                        {activeTab === 'user-billing' && <UserBilling userData={Profile} />}
                    </div>
                </div>
			</div>
		</div>
	);
}

export default UserProfile;
