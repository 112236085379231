import React, { ReactNode } from 'react';


const Icon = (props) => {
  const {
    fill = '#000',
    children,
    ariaLabel,
    viewBox,
    size = '24px',
    ...rest
  } = props;

  return (
    <svg
      fill={fill}
      width={size}
      height={size}
      viewBox={viewBox}
      aria-label={ariaLabel}
      preserveAspectRatio="xMidYMid meet"
      {...rest}
    >
      {children}
    </svg>
  );
};

export default Icon;
