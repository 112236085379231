import { memo } from 'react';
import SectionHeader from './SectionHeader';
import TeamMultiPickList from '../Inputs/TeamMultiPickList';

const TeamPickList = ({ options, onChange, onClear , onClick }) => {
	return (
		<div className='FilterSpacing-1'>
			<SectionHeader label='Clear All' title='Teams' onClear={onClear} />
			<TeamMultiPickList
				className='high'
				options={options ? options : []}
				onChange={onChange}
				onClick={onClick}
			/>
		</div>
	);
};

export default memo(TeamPickList);
