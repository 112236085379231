import * as Actions from '../actions/slack-actions';

const initialState = {
    redirectUrl: null,
    slackConnection: 0,
};

const SlackIntegrationReducer = (state = initialState, action) => {
    const {type, payload} = action;
    switch(type){
        case Actions.GET_SLACK_URL:
            return state = {
                ...state, 
                redirectUrl: payload.redirect_url
            };
        case Actions.CHECK_SLACK_INTEGRATION:
            return state = {
                ...state,
                slackConnection: payload
            };
        case Actions.REMOVE_SLACK_ACCESS:
            return state = {
                ...state,
                slackConnection: payload
            };
        default:
            return state;
    }
};

export default SlackIntegrationReducer;