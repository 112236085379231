import { memo, useState } from 'react';
import { ReactComponent as TaskIcon } from '../../assets/icons/ClipboardText.svg';
import { Avatar, Modal, Tooltip } from '@mui/material';
import { ReactComponent as EditIcon } from '../../assets/icons/PencilEdit.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/Trash-Light.svg';
import { ReactComponent as HoursIcon } from '../../assets/icons/ApprovalClock.svg';
import { ReactComponent as DependencyIcon } from '../../assets/icons/Stack.svg';
import { ReactComponent as ApprovalIcon } from '../../assets/icons/CheckCircle.svg';
import { ReactComponent as WarningIcon } from '../../assets/icons/WarningCircle.svg';

import ReactHtmlParser from 'react-html-parser';
import {
	convertDecimalToTime,
	getAvatarLabel,
	standardPermissions,
	truncateString,
} from '../../utilities/utilities';
import Restricted from '../Permissions/Restricted';
import useModal from '../Modal/useModal';
import WorkflowTaskModal from './Modals/WorkflowTaskModal';
import DummyModal from '../Modal/DummyModal';
import ConfirmModal from '../Modal/ConfirmModal';
import * as builderActions from '../../redux/actions/workflow-builder-actions';
import { useDispatch } from 'react-redux';
import TaskDescriptionModal from './Modals/TaskDescriptionModal';

function ClientWorkflowTask({
	task,
	user_team_role,
	dependent_task_name,
	assignee_user,
	disableActions = false,
}) {
	const dispatch = useDispatch();
	const [deleteLoading, setDeleteLoading] = useState(false);
	const { modalIsOpen, toggleModal, ModalName, ModalType } = useModal();
	const {
		title,
		description,
		budgeted_hours,
		is_approval_required,
		dependent_task_id,
		id,
		assignee_user_id,
	} = task;

	const onEdit = () => {
		toggleModal();
		ModalType('CLIENT_WORKFLOW_TASK');
	};

	const handleDeleteTask = () => {
		toggleModal();
		ModalType('DELETE_TASK_ALERT');
	};

	const onSuccess = () => {
		setDeleteLoading(false);
	};

	const onFailed = () => {
		setDeleteLoading(false);
	};

	const handleDeleteConfirm = () => {
		setDeleteLoading(true);
		dispatch(builderActions.deleteClientWorkflowTask(id))
			.then(() => {
				onSuccess();
			})
			.catch(() => {
				onFailed();
			});
	};
	return (
		<div className='client-workflow-task'>
			<div
				className='w-100 text-left d-flex flex-column align-items-center justify-content-between'
				style={{ gap: '24px' }}
			>
				<div
					className='d-flex align-items-center w-100 justify-content-between'
					style={{ gap: '24px' }}
				>
					<span className='d-flex align-items-center' style={{ gap: '24px' }}>
						<span className='d-flex' style={{ gap: '12px' }}>
							<TaskIcon />
							<h3 className='HeadlineThreeBold'>{title}</h3>
						</span>

						<span className='d-flex align-items-center' style={{ gap: '8px' }}>
							{assignee_user && (
								<Avatar
									sx={{
										bgcolor: `#FEF2E7`,
										color: `#333`,
										marginRight: '10px',
										height: '23px',
										width: '23px',
										fontSize: '0.8rem',
									}}
									alt={assignee_user?.full_name}
									src={assignee_user?.image_url ? assignee_user?.image_url : ''}
								>
									{getAvatarLabel(assignee_user?.full_name)}
								</Avatar>
							)}

							{assignee_user?.full_name ? (
								<p className='BodyTwoLight p-0 m-0 light-text pt-1'>
									{assignee_user?.full_name}
								</p>
							) : (
								<div className='pending-task-assingee'>
									<WarningIcon style={{ stroke: '#fb7a03' }} />
									<p className='BodyTwoLight p-0 m-0 primary-text pt-1'>
										Unassigned
									</p>
								</div>
							)}
						</span>
					</span>

					{!disableActions && (
						<>
							<Restricted
								AllowedUsers={standardPermissions}
								userTeamRole={[user_team_role]}
							>
								<span
									className='d-flex justify-content-between align-items-center'
									style={{ gap: '12px' }}
								>
									<Tooltip title='Delete Task'>
										<DeleteIcon
											className='pointer'
											onClick={handleDeleteTask}
										/>
									</Tooltip>
									<Tooltip title='Edit Task'>
										<EditIcon className='pointer' onClick={onEdit} />
									</Tooltip>
								</span>
							</Restricted>
						</>
					)}
				</div>

				<div className='client-workflow-task-details'>
					<div className='BodyTwoLight light-text'>
						<span>
							{description?.length <= 500
								? ReactHtmlParser(description)
								: ReactHtmlParser(truncateString(description, 500))}
						</span>
						{description?.length > 500 && (
							<span
								onClick={() => {
									ModalType('TASK_MEETING_DESCRIPTION');
									toggleModal();
								}}
								className='primary-text BodyThreeLight m-0 ml-1 pointer'
							>
								Read More
							</span>
						)}
					</div>
					<div className='task-sub-details'>
						<span
							className='d-flex align-items-center justify-content-center'
							style={{ gap: '8px' }}
						>
							<HoursIcon />
							<span className='BodyTwoBold pt-1'>Budgeted Hours:</span>
							<span className='BodyTwoLight pt-1'>
								{convertDecimalToTime(budgeted_hours)}
							</span>
						</span>
						<span
							className='d-flex align-items-center justify-content-center'
							style={{ gap: '8px' }}
						>
							<DependencyIcon />
							<span className='BodyTwoBold pt-1'>Dependency:</span>
							<span className='BodyTwoLight pt-1'>
								{dependent_task_id ? dependent_task_name : 'None'}
							</span>
						</span>

						{is_approval_required && (
							<span
								className='d-flex align-items-center justify-content-center'
								style={{ gap: '8px' }}
							>
								<ApprovalIcon />
								<span className='BodyTwoBold pt-1'>Requires Approval</span>
							</span>
						)}
					</div>
				</div>

				{modalIsOpen && (
					<Modal
						onClose={toggleModal}
						open={modalIsOpen}
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<>
							{ModalName == 'CLIENT_WORKFLOW_TASK' && (
								<DummyModal
									style={{ width: '900px' }}
									hideCrossIcon
									title='Task Details'
									onClose={toggleModal}
								>
									<WorkflowTaskModal task={task} onCancel={toggleModal} />
								</DummyModal>
							)}
							{ModalName == 'DELETE_TASK_ALERT' && (
								<DummyModal title={'Alert'} onClose={toggleModal}>
									<ConfirmModal
										description={'Are you sure you want to remove this task?'}
										OnCancel={toggleModal}
										Onsubmit={handleDeleteConfirm}
										descriptionClassName='text-left'
										isLoading={deleteLoading}
									/>
								</DummyModal>
							)}
							{ModalName == 'TASK_MEETING_DESCRIPTION' && (
								<DummyModal title='Agenda' onClose={toggleModal} height='400px'>
									<TaskDescriptionModal
										type={'text'}
										value={description}
										Edit={false}
										OnCancel={toggleModal}
										richTextStyle={{ height: '250px' }}
									/>
								</DummyModal>
							)}
						</>
					</Modal>
				)}
			</div>
		</div>
	);
}

export default memo(ClientWorkflowTask);
