
function ProhibitedConductAndContent() {
	return (
		<div className='BodyTwoLight'>
			You will not violate any applicable law, contract, intellectual property
			right, or other third-party right or commit a tort, and you are solely
			responsible for your conduct while using or visiting our Site. You will
			not:
			<br /> <br />
			<ul className='list-disc ml-8 pl-4'>
				<li>
					Misrepresent your identity or affiliation with a person or entity;
				</li>

				<br />

				<li>
					Copy, reproduce, distribute, publicly perform, or publicly display all
					or portions of our Site, except as expressly permitted by us or our
					licensors;{' '}
				</li>

				<br />

				<li>
					Modify our Site, remove any proprietary rights notices or markings, or
					otherwise make any derivative works based upon our Site;
				</li>
				<br />
				<li>
					Use our Site other than for its intended purpose and in any manner
					that could interfere with, disrupt, negatively affect or inhibit other
					users from fully enjoying our Site, or that could damage, disable,
					overburden or impair the functioning of our Site in any manner;
				</li>
				<br />
				<li>
					Reverse engineer any aspect of our Services or do anything that might
					discover source code or bypass or circumvent measures employed to
					prevent or limit access to any part of our Services;
				</li>
				<br />
				<li>
					Use any data mining, robots, or similar data gathering or extraction
					methods designed to scrape or extract data from our Site;
				</li>
				<br />
				<li>
					Develop or use any applications that interact with our Site without
					our prior written consent;
				</li>
				<br />
				<li>
					Send, distribute or post spam, unsolicited or bulk commercial
					electronic communications, chain letters, or pyramid schemes; or
				</li>
				<br />
				<li>
					Use our Site for any illegal or unauthorized purpose, or engage in,
					encourage, or promote any activity that violates these Terms.
				</li>
			</ul>
			<br />
			Enforcement of this Section 2 is solely at Levvy’s discretion, and failure
			to enforce this section in some instances does not constitute a waiver of
			our right to enforce it in other instances. In addition, this Section 2
			does not create any private right of action on the part of any third party
			or any reasonable expectation that the Site will not contain any content
			that is prohibited by such rules.
		</div>
	);
}

export default ProhibitedConductAndContent;
