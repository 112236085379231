/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useMemo } from 'react';
import InputTaskDetails from '../../Inputs/InputTaskDetails';
import DropDown from '../../Inputs/DropDown';
import { Checkbox } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import { RepeatTypes } from '../../DemoData';
import { BillTypes } from '../../DemoData';
import TextBox from '../../Inputs/TextBox';
import * as WorkSpaceActions from '../../../redux/actions/dashboard/workspaces-actions';
import * as clientActions from '../../../redux/actions/client-actions';
import FormButtonOne from '../../Buttons/FormButtonOne';
import { ReactComponent as Calendar } from '../../../assets/icons/Calendarcolor.svg';
import { KeyboardDatePicker } from '@material-ui/pickers';
import MeetingGuest from './MeetingGuest';
import useEditTimeFormat from '../../../hooks/useEditTimeFormat';
import {
	convertDecimalToTime,
	convertToDecimal,
} from '../../../utilities/utilities';

function EditWorkflowMyTaskModal({
	myTaskDetails,
	onCancel,
	assignees,
}) {
	const dispatch = useDispatch();
	const taskTypes = useSelector((state) => state.workSpaceReducer.taskTypes);
	const clients = useSelector((state) => state.clientReducer.clients);
	const user = useSelector((state) => state.authReducer.user);
	const [repeatTime, setRepeatTime] = useState(null);
	const [form, setForm] = useState({});
	const [search, setSearch] = useState({
		value: '',
		show: false,
	});
	const [isLoading, setIsLoading] = useState(false);
	const { inputValue, setInputValue, handleInputChange } = useEditTimeFormat(
		form?.budgeted_hours,
	);

	const Style = {
		backgroundColor: 'white',
		border: 'none',
		borderBottom: '1px solid #28383C',
	};

	const handleChange = (event) => {
		let { name, value } = event.target;

		if (name == 'is_approval_required') {
			value = event.target.checked;
		}

		if (name == 'assignee_user_id') {
			(value = Number(value)),
				setForm({
					...form,
					approval_from: null,
				});
		}

		if (name == 'approval_from') {
			value = Number(value);
		}

		if (name === 'client_id') {
			setForm({
				...form,
				assignee_user_id: null,
			});
		}

		setForm({
			...form,
			[name]: value,
		});
	};

	const quillHandler = (name,content) => {
		setForm({
			...form,
			[name]: content,
		});
	};

	const handleMeeting = (e) => {
		const { name, value } = e.target;
		setForm({
			...form,
			metadata: {
				...form?.metadata,
				[name]: value,
			},
		});
	};
	const handleRemoveGuest = (id) => {
		const tempGuests = form?.metadata?.meeting_guests?.filter((item) => {
			if (item !== id) {
				return item;
			}
		});

		setForm({
			...form,
			metadata: {
				...form.metadata,
				meeting_guests: tempGuests,
			},
		});
	};

	const handleSearch = (e) => {
		if (e.target.value !== '') {
			setSearch({ value: e.target.value, show: true });
		} else {
			setSearch({ value: e.target.value, show: false });
		}
	};

	const handleAddGuest = (user) => {
		setForm({
			...form,
			metadata: {
				...form.metadata,
				meeting_guests: [...form.metadata.meeting_guests, user?.id],
			},
		});
	};

	const handleRepeatChange = (e) => {
		setRepeatTime(e.target.value);
	};
	
	const handleInputBlur = () => {
		let value = 0;
		const name = 'budgeted_hours';

		if (inputValue === '') {
			return;
		}

		if (inputValue.includes(':')) {
			value = convertToDecimal(inputValue);
		} else if (!isNaN(inputValue)) {
			value = parseFloat(inputValue);
		}

		const formattedValue = convertDecimalToTime(value);
		setInputValue(formattedValue);
			setForm({
				...form,
				[name]: value,
			});
	};

	const toggleLoader = (state) => {
		setIsLoading(state)
	}

	const onUpdateTask = (event) => {
		event.preventDefault();
		toggleLoader(true)
		let formDetails = { ...form };
		if (formDetails?.type === 'WORKFLOW') {
			formDetails = {
				...formDetails,
				metadata: {
					meeting_platform: formDetails?.metadata?.meeting_platform || null,
					meeting_link: formDetails?.metadata?.meeting_link || null,
					meeting_guests: formDetails?.metadata?.meeting_guests || [],
				},
				is_billable: formDetails?.is_billable == 1 ? true : false,
			};
		}
		dispatch(clientActions.updateWorkflowMyTask(repeatTime, formDetails, toggleLoader, onCancel));
	};

	useEffect(() => {
		dispatch(WorkSpaceActions.getTaskTypes());
		if (form?.client_id) {
			dispatch(WorkSpaceActions.getSpecificUsers(form?.client_id));
		} else {
			dispatch(WorkSpaceActions.getSpecificUsers());
		}
		dispatch(clientActions.getClients());
	}, [dispatch, form?.client_id]);

	if (clients?.length === 0) {
		assignees = assignees.filter((item) => item.id === user.userId);
	}
	useMemo(() => {
		myTaskDetails && setForm(myTaskDetails);
	}, [myTaskDetails]);

	return (
		<form onSubmit={onUpdateTask} className='my-task-modal' autoComplete='off'>
			<div
				style={{ height: '90%', paddingBottom: '20px' }}
				className='my-task-body'
			>
				<div className='my-task-body-child'>
					<div className='HeadlineTwoBold dark-text d-flex'>
						Edit Task Details
					</div>
					<div className='my-task-inner-body'>
						<div
							style={{
								height: '100%',
								boxSizing: 'border-box',
								overflow: 'auto',
							}}
						>
							<div
								className='UserAdd-Form d-flex flex-column pr-2'
								style={{ gap: '16px', height: 'inherit' }}
							>
								<div className='new-input-designs pt-1'>
									<label className='require'>Task</label>
									<input
										className='w-100'
										type='text'
										name='title'
										onChange={handleChange}
										style={Style}
										required
										value={form?.title}
									/>
								</div>
								<div className='new-input-designs pb-3'>
									<label>Description</label>
									<InputTaskDetails
										richText
										type='text'
										name='description'
										onChange={(e) => quillHandler('description', e)}
										value={form?.description}
									/>
								</div>
								<div className='new-input-designs'>
									<label className='require w-100'>Budgeted Hours</label>
									<input
										className='w-100'
										type='text'
										style={Style}
										value={inputValue}
										name='budgeted_hours'
										onChange={handleInputChange}
										required={true}
										onBlur={handleInputBlur}
										placeholder='HH : MM'
									/>
								</div>

								<div className='new-input-designs pb-3'>
									<label>Notes</label>
									<InputTaskDetails
										richText
										type='text'
										name='notes'
										value={form?.notes}
										onChange={(e) => quillHandler('notes',e)}
									/>
								</div>
								<div className='new-input-designs pt-1'>
									<label className='require'>Task Type</label>
									<input
										className='w-100'
										type='text'
										name='title'
										onChange={handleChange}
										style={{...Style, backgroundColor: '#f6f5f2'}}
										required
										disabled
										value={
											taskTypes?.find((task) => task.id == form?.task_type_id)
												?.name
										}
									/>
								</div>
								<div className='new-input-designs w-100 align-items-start d-flex flex-column'>
									<label>Date</label>
									<KeyboardDatePicker
										autoOk
										disableToolbar
										value={form?.start_date || null}
										name='start_date'
										onChange={(e) =>
											handleChange({
												target: {
													name: 'start_date',
													value: e,
												},
											})
										}
										format='MM/DD/yyyy'
										keyboardIcon={<Calendar />}
										disablePast
										required={true}
										helperText={null}
									/>
								</div>
								{form?.task_type_id === 2 && (
									<>
										<DropDown
											rootClassName='new-input-designs'
											onChange={handleChange}
											label='Repeat Type'
											name='repeat_type'
											className='require'
											required={true}
											options={RepeatTypes}
											style={Style}
											value={RepeatTypes.find(
												(item) => item.value === form?.repeat_type,
											)}
										/>
										<div className='new-input-designs'>
											<label className='require'>Repeat every</label>
											<input
												className='w-100'
												type='number'
												name='repeat_interval'
												required
												onChange={handleChange}
												style={Style}
												value={form?.repeat_interval}
												min={0}
											/>
										</div>
										<div className='new-input-designs'>
											<label className='require'>Repeat time</label>
											<input
												className='w-100'
												type='number'
												name='repeat_time'
												required
												onChange={handleRepeatChange}
												style={Style}
												value={repeatTime}
												min={2}
											/>
										</div>
									</>
								)}

								<DropDown
									rootClassName='new-input-designs'
									onChange={handleChange}
									label='Bill Type'
									name='is_billable'
									className='require'
									required={true}
									style={Style}
									options={BillTypes}
									value={BillTypes.find((item) =>
										item.value === form?.is_billable ? 1 : 2,
									)}
								/>
								<div className='new-input-designs pt-1'>
									<label className='require'>Client</label>
									<input
										className='w-100'
										type='text'
										name='title'
										onChange={handleChange}
										style={{...Style, backgroundColor: '#f6f5f2'}}
										required
										disabled
										value={
											clients
												?.map((client) => {
													{
														return {
															value: client.id,
															label: client.company_name,
														};
													}
												})
												.find((item) => item.value == form?.client_id)?.label
										}
									/>
								</div>
								<div className='new-input-designs align-items-start w-100'>
									<label>Meeting Link</label>
									<input
										className='w-100'
										type='text'
										style={Style}
										value={form?.metadata?.meeting_link}
										name='meeting_link'
										onChange={handleMeeting}
									/>
								</div>
								{form?.metadata?.meeting_guests && (
									<MeetingGuest
										className='new-input-designs align-items-start w-100'
										search={search}
										handleSearch={handleSearch}
										handleRemoveGuest={handleRemoveGuest}
										handleAddGuest={handleAddGuest}
										assignees={assignees}
										form={form}
									/>
								)}
								<DropDown
									rootClassName='new-input-designs'
									onChange={handleChange}
									label='Assignee'
									name='assignee_user_id'
									className='require'
									style={Style}
									required={true}
									value={{ value: form?.assignee_user_id }}
									options={
										form?.client_id
											? assignees?.map((assignee) => {
													return {
														value: assignee?.id,
														label: assignee?.full_name,
														is_active: assignee?.is_active,
													};
											  })
											: assignees
													?.filter((item) => item.id === user.userId)
													?.map((assignee) => {
														return {
															value: assignee?.id,
															label: assignee?.full_name,
															is_active: assignee?.is_active,
														};
													})
									}
								/>

								<div className='new-input-designs'>
									<div>
										<Checkbox
											sx={{
												color: '#b4b4b4 !important',
												'&.Mui-checked': {
													color: '#FB7A03 !important',
												},
												'&.MuiCheckbox-root': {
													'&:hover': {
														boxShadow: 'none',
														backgroundColor: 'transparent !important',
													},
												},
											}}
											style={{
												paddingLeft: 0,
											}}
											disabled={!form?.assignee_user_id}
											checked={form?.is_approval_required ? form?.is_approval_required: false}
											onChange={handleChange}
											name='is_approval_required'
										/>
										<label
											style={{ paddingTop: '10px !important' }}
											className='approval-text align-self-center w-50 pt-4'
										>
											Require approval{' '}
										</label>
									</div>
								</div>

								{form?.is_approval_required && (
									<>
										<DropDown
											label='Approval From'
											rootClassName='new-input-designs'
											onChange={handleChange}
											className='w-100 require'
											style={Style}
											type='text'
											value={{value: form?.approval_from}}
											name='approval_from'
											options={assignees
												?.filter(
													(assignee) =>
														assignee.id !== user?.userId &&
														Number(form?.assignee_user_id) != assignee.id,
												)
												?.map((assignee) => {
													return {
														value: assignee.id,
														label: assignee.full_name,
														is_active: assignee.is_active,
													};
												})}
											required={true}
										/>

										{(form?.approval_from || !isNaN(form?.approval_from)) && (
											<div className='new-input-designs'>
												<TextBox
													style={{
														backgroundColor: '#ededed',
													}}
													className='p-2 approval-text'
												>
													{
														assignees?.find(
															(assignee) =>
																Number(assignee.id) ==
																Number(form?.approval_from),
														)?.full_name
													}{' '}
													will be required to approve the task to mark this task
													complete.
												</TextBox>
											</div>
										)}
									</>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div style={{ height: '10%' }} className='my-task-form-actions'>
				<FormButtonOne
					onClick={onCancel}
					type='button'
					className='secondary-btn-light'
				>
					Close
				</FormButtonOne>
				<FormButtonOne type='submit' loading={isLoading}>Save</FormButtonOne>
			</div>
		</form>
	);
}

export default EditWorkflowMyTaskModal;
