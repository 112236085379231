import { useEffect } from 'react';
import ZapierProns from '../Others/ZapierProns';
import * as profileActions from '../../redux/actions/company-profile';
import { useDispatch, useSelector } from 'react-redux';
import { getAvatarLabel } from '../../utilities/utilities';
import { CircularProgress } from '@mui/material';

function ZapierAccessLogin() {
	const dispatch = useDispatch();
	const profileData = useSelector((state) => state.profileReducer.profileData);
	const loading = useSelector((state) => state.profileReducer.loading);
	const user = useSelector((state) => state.authReducer.user);
	console.log(profileData, 'profileData');

	const ZapProns = [
		{
			subtitle: 'Access your account information',
		},
		{
			subtitle: 'Access and manage your data',
		},
		{
			subtitle: 'Access associated information to your account',
		},
	];

	useEffect(() => {
		dispatch(profileActions.getTenantProfile(user.tenantIds[0]));
	}, [dispatch, user]);

	return (
		<div className='zapier-access-login d-flex flex-column align-items-center justify-content-center'>
			<div className='comapany-section '>
				<span className='BodyOneBold'>Company Details</span>
				<div className='company d-flex align-items-center'>
					{loading ? (
						<CircularProgress size={20} sx={{ color: '#fb7a03' }} disableShrink />
					) : (
						<>
							<div
								style={{
									width: '24px',
									height: '24px',
									borderRadius: '50%',
									backgroundColor: '#FED0A5',
									padding: '10px',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									color: 'black',
									fontSize: '13px',
									fontWeight: '300',
								}}
							>
								{getAvatarLabel(profileData?.name)}
							</div>
							<span className='ml-2 BodyTwoLight' style={{ minWidth: '' }}>
								{profileData?.name}
							</span>
						</>
					)}
				</div>
			</div>
			<div className='prons'>
				<span className='BodyOneBold d-flex justify-content-start'>
					Zapier will be able to:
				</span>
				{ZapProns.map((zapProns, index) => (
					<ZapierProns key={index} subtitle={zapProns.subtitle} />
				))}
			</div>
			<div className='BodyThreeLight privacy mt-3'>
				By clicking on allow access, you authorize Zapier to use your information in
				accordance with its
				<span className='BodyThreeBold' style={{ color: '#fb7a03', marginLeft: '4px' }}>
					privacy policy
				</span>
				. You can stop it at any time on the{' '}
				<span className='BodyThreeBold' style={{ color: '#fb7a03', marginRight: '4px' }}>
					integrations
				</span>
				page of your Levvy account.
			</div>
		</div>
	);
}

export default ZapierAccessLogin;
