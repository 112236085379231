/* eslint-disable no-mixed-spaces-and-tabs */
import { forwardRef, useState } from 'react';
import FormButtonOne from '../Buttons/FormButtonOne';
import MultipleDropDown from '../Inputs/MultipleDropDown';
import { useSelector } from 'react-redux';

const SaveWorkspaceFilterModal = forwardRef(function FiltersModal(
	{ onClose, saveFilter, selectedFilter, teams, clients, workflows, status, people },
	ref,
) {
	const appliedFilters = useSelector((state) => state.workSpaceReducer.filters);

	const [filterName, setFilterName] = useState(selectedFilter?.name || '');
	const [filters, setFilters] = useState(
		selectedFilter ? selectedFilter?.filters : appliedFilters,
	);

	const peopelOptions = people?.map((item) => ({
		...item,
		is_checked: filters?.users?.includes(item.value),
	}));
	const statusOptions = status?.map((item) => ({
		...item,
		is_checked: filters?.taskStatuses?.includes(item.value),
	}));
	const teamOptions = teams?.map((item) => ({
		...item,
		is_checked: filters?.teams?.includes(item.value),
	}));
	const clientOptions = clients?.map((item) => ({
		...item,
		is_checked: filters?.clients?.includes(item.value),
	}));
	const workflowOptions = workflows?.map((item) => ({
		...item,
		is_checked: filters?.workflows?.includes(item.value),
	}));

	const filtersChangeHandler = (name, list) => {
		setFilters({
			...filters,
			[name]: list?.filter((item) => item.is_checked)?.map(({ value }) => value),
		});
	};

	const handleChange = (event) => {
		setFilterName(event.target.value);
	};

	const validateName = (event) => {
		event.preventDefault();

		saveFilter(filterName, filters, selectedFilter?.id);
	};

	return (
		<form
			tabIndex={-1}
			ref={ref}
			onSubmit={validateName}
			autoComplete='off'
			className='my-task-modal'
		>
			<div className='SaveFilterModal'>
				<h2 className='HeadlineTwoBold'>Save Filter</h2>

				<div className='improved-input-designs pt-1'>
					<label className='require'>Filter Name</label>
					<input
						className='w-100'
						type='text'
						name='name'
						onChange={handleChange}
						required
						value={filterName || ''}
					/>
				</div>

				<div className='improved-input-designs pt-1'>
					<label className=''>People</label>
					<MultipleDropDown
						options={peopelOptions}
						changeHandler={(people) => filtersChangeHandler('users', people)}
					/>
				</div>

				<div className='improved-input-designs pt-1'>
					<label className=''>Teams</label>
					<MultipleDropDown
						options={teamOptions}
						changeHandler={(teams) => filtersChangeHandler('teams', teams)}
					/>
				</div>

				<div className='improved-input-designs pt-1'>
					<label className=''>Clients</label>
					<MultipleDropDown
						options={clientOptions}
						changeHandler={(clients) => filtersChangeHandler('clients', clients)}
					/>
				</div>

				<div className='improved-input-designs pt-1'>
					<label className=''>Status</label>
					<MultipleDropDown
						options={statusOptions}
						changeHandler={(status) => filtersChangeHandler('taskStatuses', status)}
					/>
				</div>

				<div className='improved-input-designs pt-1'>
					<label className=''>Workflows</label>
					<MultipleDropDown
						options={workflowOptions}
						changeHandler={(workflows) => filtersChangeHandler('workflows', workflows)}
					/>

					{/* <div className='d-flex flex-row align-items-center py-2 w-100'>
						<input
							name='default'
							type='checkbox'
							className='multi-select-checkbox'
							checked={filterDetails?.default}
							onChange={handleDefaultCheck}
						/>
						<span className='BodyTwoLight pt-1 dark-text text-left pl-2'>
							Set Default
						</span>
					</div> */}
				</div>
			</div>
			<div className='VerticalSpacer bg-white p-3 rounded-bottom d-flex justify-content-between'>
				<FormButtonOne onClick={onClose} type='button' className='secondary-btn-light'>
					Cancel
				</FormButtonOne>
				<FormButtonOne type='submit'>{selectedFilter ? 'Update' : 'Create'}</FormButtonOne>
			</div>
		</form>
	);
});

export default SaveWorkspaceFilterModal;
