import React from 'react';
import { useHistory, useLocation } from 'react-router';

import styled from 'styled-components';

import { ReactComponent as Levvy } from '../assets/icons/Levvy_Wordmark_RGB_Black.svg';

const Root = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: #f2f5f6;

	width: 100vw;
	height: 100vh;

	svg {
		width: 116px;
		height: 38px;
	}
`;

const ButtonControls = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 15px;
	width: 10rem;
`;

export default function AppComponent() {
	const history = useHistory();
	const search = useLocation().search;

	return (
		<Root>
			<Levvy />
			<ButtonControls>
				<button
					className='btn btn-md'
					style={{ background: '#fb7a03', color: '#ffffff' }}
					onClick={
						() => history.push('/login')
						// loginWithRedirect({
						//   redirect_uri: `${window.location.origin}/workspace`,
						// })
					}
				>
					Log In
				</button>
				<button
					className='btn btn-md'
					style={{ background: '#fb7a03', color: '#ffffff' }}
					onClick={
						() => history.push(`/signup${search}`)
						// loginWithRedirect({
						//   redirect_uri: `${window.location.origin}/workspace`,
						// })
					}
				>
					Sign Up
				</button>
			</ButtonControls>
		</Root>
	);
}

// 	// const auth = useSelector(state => state.auth);
// 	// const dispatch = useDispatch();
// 	const history=useHistory();
// 	return (
// 		<div className="App">
// 			<button className="btn btn-md btn warning" onClick={()=>{
// 				history.push('/login')
// 			}}>
// 				Login
// 			</button>
// 			<button className="btn btn-md btn warning" onClick={()=>{
// 				history.push('/signup')
// 			}}>
// 				Signup
// 			</button>
// 		</div>
// 	);
// }
