import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LevvyLogo from '../../assets/icons/Default.svg';
import * as WorkSpaceActions from '../../redux/actions/dashboard/workspaces-actions';
import {
	NOTIFICATION_STATUS,
	executivePermissions,
	isTeamManager,
} from '../../utilities/utilities';

export default function ActionCenterLogo() {
	const dispatch = useDispatch();

	const [bounce, setBounce] = useState(false);

	const openActionCenterModal = useSelector(
		(state) => state.workSpaceReducer.openActionCenterModal,
	);
	const notifications = useSelector((state) => state.actionCenterReducer.notifications);
	const approvalPendingTasks = useSelector(
		(state) => state.actionCenterReducer.approvalPendingTasks,
	);
	const user = useSelector((state) => state.authReducer.user);

	const isUserManagerAndAbove =
		executivePermissions?.includes(user?.userRole?.id) ||
		isTeamManager(user?.user_roles?.map((item) => item.role_id));
        
	useEffect(() => {
		if (
			notifications?.some(
				(notification) => notification.status == NOTIFICATION_STATUS.UNREAD,
			) ||
			(isUserManagerAndAbove && approvalPendingTasks?.length > 0)
		) {
			setBounce(true);
		}
		return () => {
			setBounce(false);
		};
	}, [notifications, approvalPendingTasks]);

	return (
		<div>
			<img
				src={LevvyLogo}
				alt=''
				className={`action-logo ${openActionCenterModal ? '' : bounce ? 'pulse' : ''}`}
				id='action-center-logo'
				style={{
					width: openActionCenterModal && '70px',
					height: openActionCenterModal && '70px',
					cursor: 'pointer',
				}}
				onClick={() => {
					openActionCenterModal === false
						? dispatch(WorkSpaceActions.setOpenActionCenterModal(true))
						: dispatch(WorkSpaceActions.setOpenActionCenterModal(false));
				}}
			/>
		</div>
	);
}
