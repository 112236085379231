import React from 'react';
import { ReactComponent as ArrowRight } from '../../assets/icons/ArrowRightWhite.svg';

function NavigatorSection({ title, handleClick }) {
	return (
		<section className='client-profile'>
			<div className='text-left d-flex align-items-center justify-content-end information-header'>
				<button
					style={{
						gap: '6px',
					}}
					onClick={handleClick}
					className='primary-btn rounded-btn d-flex align-items-center justify-content-between'
				>
					<span className='BodyTwoBold'>{title}</span>
					<ArrowRight />
				</button>
			</div>
		</section>
	);
}

export default NavigatorSection;
