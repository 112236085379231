import {
	getCurrentWeekRange,
	getCurrentMonthRange,
	getCurrentQuarterRange,
	getCurrentYearRange,
	getLastWeekRange,
	getLastMonthRange,
	getLastQuarterRange,
	getLastYearRange,
} from './utilities';

export const TIME_RANGE_OPTIONS = [
	{ label: 'Custom', value: 'custom' },
	{ label: 'This Week', value: 'thisWeek' },
	{ label: 'This Month', value: 'thisMonth' },
	{ label: 'This Quarter', value: 'thisQuarter' },
	{ label: 'This Year', value: 'thisYear' },
	{ label: 'Last Week', value: 'lastWeek' },
	{ label: 'Last Month', value: 'lastMonth' },
	{ label: 'Last Quarter', value: 'lastQuarter' },
	{ label: 'Last Year', value: 'lastYear' },
];

const TIME_RANGE_FUNCTIONS = {
	thisWeek: getCurrentWeekRange,
	thisMonth: getCurrentMonthRange,
	thisQuarter: getCurrentQuarterRange,
	thisYear: getCurrentYearRange,
	lastWeek: getLastWeekRange,
	lastMonth: getLastMonthRange,
	lastQuarter: getLastQuarterRange,
	lastYear: getLastYearRange,
	custom: () => undefined,
};

export const getTimeRange = (rangeValue) => {
	const getRangeFunction = TIME_RANGE_FUNCTIONS[rangeValue];
	return getRangeFunction ? getRangeFunction() : null;
};
