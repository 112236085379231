import { useEffect } from 'react';
import { syncTimeTrackingPendingRequests } from '../../utilities/TimeTrackingOfflineSyncHelpers';

function DetectOnlineStatus() {
	useEffect(() => {
		const handleOnline = () => {
			console.info('debug , online');
			syncTimeTrackingPendingRequests();
		};

		const handleOffline = () => {
			console.info('debug , offline');
		};

		window.addEventListener('online', handleOnline);
		window.addEventListener('offline', handleOffline);

		return () => {
			window.removeEventListener('online', handleOnline);
			window.removeEventListener('offline', handleOnline);
		};
	}, []);
	return null;
}

export default DetectOnlineStatus;
