import { useState, useEffect } from 'react';
import ProgressBar from '../sub_components/DocumentStorage/ProgressBar';
import { useSelector } from 'react-redux';
import { ReactComponent as MaximizeIcon } from '../../assets/icons/CaretUp.svg';
import { ReactComponent as MinimizeIcon } from '../../assets/icons/Minus.svg';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';

function ProgressBarModal({ modalIsOpen, files, onClose }) {
    const [isMinimized, setIsMinimized] = useState(false);
    const upload_status = useSelector((state) => state.clientReducer.upload_status);
    const [uploadCount, setUploadCount] = useState(0);

    const handleMinimize = () => {
        setIsMinimized(true);
    };

    const handleMaximize = () => {
        setIsMinimized(!isMinimized);
    };

    const overallStatus = () => {
        const statuses = Object.values(upload_status);

        const completed = statuses.filter((status) => status?.status === 1).length;
        const failed = statuses.filter((status) => status?.status === -1).length;
        const total = completed + failed;

        if (total === uploadCount) {
            if (failed > 0) {
                return `Upload Completed (${failed} Failed)`;
            } else {
                return 'Upload Completed';
            }
        } else {
            return `Upload in Progress (${total}/${uploadCount})`;
        }
    };

    useEffect(() => {
        setUploadCount(files?.length || 0);
    }, [files]);

    return (
        <>
            {modalIsOpen && (
                <div className='progress-bar-modal-container'>
                    <div className='progress-bar-modal'>
                        <div className='progress-bar-header'>
                            <h5 className='HeadlineThreeBold'>{overallStatus()}</h5>
                            <div className='d-flex'>
                                <h5
                                    onClick={isMinimized ? handleMaximize : handleMinimize}
                                    style={{ cursor: 'pointer', marginTop: '8px' }}
                                >
                                    {isMinimized ? <MaximizeIcon /> : <MinimizeIcon />}
                                </h5>
                                <IconButton className='CloseButton transparent' onClick={onClose}>
                                    <CloseIcon sx={{ color: '#333' }} />
                                </IconButton>
                            </div>
                        </div>
                    </div>
                    <div className='progress-bar-body'>
                        {!isMinimized &&
                            files?.map((file, index) => {
                                return <ProgressBar key={index} file={file} />;
                            })}
                    </div>
                </div>
            )}
        </>
    );
}

export default ProgressBarModal;
