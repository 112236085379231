import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import posthog from 'posthog-js';
import { Member } from '../utilities/utilities';

function PostHogGroupIdentification() {
	const user = useSelector((state) => state.authReducer.user);
	const companyProfileData = useSelector((state) => state.profileReducer.profileData);
	const userDetails = useSelector((state) => state.authReducer.userDetails);

	useEffect(() => {
		if (user?.accessToken && companyProfileData?.id && userDetails?.email) {
			if (
				!window.location.host.includes('localhost') &&
				user?.userRole?.id == Member.ownerId
			) {
				posthog.group('tenant', String(companyProfileData?.id), {
					name: companyProfileData?.name,
					id: companyProfileData?.id,
					onboarding_status: companyProfileData?.onboarding_status,
					created_at: companyProfileData?.created_at,
					owner_email: userDetails?.email,
				});
			}
		}
	}, [user?.accessToken, companyProfileData?.id, userDetails?.email]);

	return null;
}

export default PostHogGroupIdentification;
