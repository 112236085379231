
function Release() {
	return (
		<p className='BodyTwoLight'>
			To the fullest extent permitted by applicable law, you release Levvy and
			the other Levvy Parties from responsibility, liability, claims, demands,
			and/or damages (actual and consequential) of every kind and nature, known
			and unknown (including, but not limited to, claims of negligence), arising
			out of or related to disputes between users and the acts or omissions of
			third parties. If you are a consumer who resides in California, you hereby
			waive your rights under California Civil Code § 1542, which provides: “A
			general release does not extend to claims which the creditor does not know
			or suspect to exist in his or her favor at the time of executing the
			release, which if known by him or her must have materially affected his or
			her settlement with the debtor.”
		</p>
	);
}

export default Release;
