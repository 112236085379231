/* eslint-disable no-mixed-spaces-and-tabs */
import { useMemo, useState } from 'react';

// Import Material Ui Components
import { Modal, Tooltip } from '@mui/material';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

// Styled Components
import { ReactComponent as PlusIcon } from '../../assets/icons/plusIcon.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/Trash-Light.svg';

// Import Components
import TableHeader from '../Others/TableHeader';
import TableHeaderItem from '../Others/TableHeaderItem';
import TableRowItem from '../Others/TableRowItem';
import { Input } from '../Inputs/Input';
import useModal from '../Modal/useModal';
import DummyModal from '../Modal/DummyModal';

// Import Table Spacing Styles
import {
	TableItemWidth3,
	TableItemWidth4,
	TableItemWidth5,
} from '../TableSpacing/TableSpacingStyles';
import ConfirmModal from '../Modal/ConfirmModal';
import { useDispatch, useSelector } from 'react-redux';
import * as teamActions from '../../redux/actions/team-management.actions';
import * as userActions from '../../redux/actions/user-management.actions';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { EditableDropdown } from '../Inputs/EditableDropDown';
import {
	executivePermissions,
	Member,
	standardPermissions,
} from '../../utilities/utilities';
import AddUserToTeam from './AddUserToTeam';
import Restricted from '../Permissions/Restricted';
import SectionBar from '../Others/SectionBar';
import SkeletonLoading from '../Others/SkeletonLoading';
import { routes } from '../../utilities/routes';
import { setSnackBarIsOpen } from '../../redux/actions/dashboard/workspaces-actions';
import { ReactComponent as TeamMembersIcon } from '../../assets/icons/UserCircleGearColor.svg';

function TeamMembers() {
	const dispatch = useDispatch();
	const location = useLocation();
	const { id } = useParams();
	const history = useHistory();
	const [anchorEl, setAnchorEl] = useState(null);
	const [userId, setUserId] = useState(null);
	const loading = useSelector((state) => state.teamReducer.loading);
	const teamDetails = useSelector((state) => state.teamReducer.teamDetails);

	const teamMembers = useSelector((state) => state.teamReducer.teamMembers);
	const newTeamMembers = useSelector(
		(state) => state.teamReducer.newTeamMemberDetails,
	);

	let roles = useSelector((state) => state.userReducer.roles);
	let users = useSelector((state) => state.userReducer.users);
	const allUsers = useSelector((state) => state.userReducer.users);
	const user = useSelector((state) => state.authReducer.user);

	const newUsers = users;

	const [error, setError] = useState({
		error: '',
		actionLoading: false,
	});

	if (users.length > 0) {
		users = users.filter(
			(user) => !executivePermissions.includes(user.role_id),
		);
		users = users.map((user) => {
			return {
				value: user.user_id,
				label: user.user_name,
				...user,
			};
		});
	}

	if (roles.length > 0) {
		roles = roles.filter(
			(role) => role.id !== Member.ownerId && role.id !== 15,
		);
		roles = roles.map((role) => {
			return {
				value: role.id,
				label: role.role_name,
				...role,
			};
		});
	}

	const open = Boolean(anchorEl);
	const handleClick = (event, id) => {
		setUserId(id);
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};
	const ModalOpener = () => {
		toggleModal();
		handleClose();
	};

	//   Modal
	const { modalIsOpen, toggleModal, ModalName, ModalType } = useModal();

	const originalUsersFromCurrentTeam = useMemo(() => {
		// Finding users that belong to the team profile we are currently at.
		return allUsers.filter((user) =>
			user.user_teams.some((userTeam) => userTeam.team_id === parseInt(id)),
		);
	}, [id, allUsers]);

	const addTeamMember = () => {
		setError({
			error: '',
			actionLoading: true,
		});
		if (newTeamMembers.user_id && id && newTeamMembers.role_id) {
			newTeamMembers.role_id = parseInt(newTeamMembers.role_id);
			newTeamMembers.is_active = users?.find(
				(user) => user.user_id === parseInt(newTeamMembers.user_id),
			)?.is_active;
			newTeamMembers.user_id = parseInt(newTeamMembers.user_id);

			// If user we are adding again already belongs to the same team
			const userAlreadyMemberId = originalUsersFromCurrentTeam
				.find((member) => member.user_id === newTeamMembers.user_id)
				?.user_teams.find(
					(team) =>
						team.team_id == id && team.user_id == newTeamMembers.user_id,
				)?.id;

			// If User belongs to this team already, pass the team id to it
			if (userAlreadyMemberId) {
				newTeamMembers.id = parseInt(userAlreadyMemberId);
			}
			dispatch(
				userActions.addUserToTeam({
					user_id: newTeamMembers?.user_id,
					team_id: parseInt(id),
					role_id: newTeamMembers?.role_id,
				}),
			)
				.then((response) => {
					console.log(response, 'responseCheck');
					dispatch(
						teamActions.setTeamMembers([
							...teamMembers,
							{ ...newTeamMembers, user_team_id: response.id },
						]),
					);
					dispatch(setSnackBarIsOpen(true, true, 'Added Succesfully'));
					setError({
						error: '',
						actionLoading: false,
					});
					toggleModal();
				})
				.catch((error) => {
					setError({
						error: error.message,
						actionLoading: false,
					});
					dispatch(setSnackBarIsOpen(false, true, error.message));
				});
		}
	};

	const deleteUser = () => {
		setError({
			error: '',
			actionLoading: true,
		});
		console.log(teamMembers, 'teamMembers');
		const userToRemove = teamMembers?.find(
			(member) => member.user_id === userId,
		);
		console.log(userToRemove, 'userToRemove');
		dispatch(userActions.deleteTeamRole(userToRemove?.user_team_id))
			.then(() => {
				dispatch(
					teamActions.setTeamMembers(
						teamMembers.filter((member) => member.user_id !== userId),
					),
				);
				dispatch(setSnackBarIsOpen(true, true, 'Deleted Succesfully'));
				toggleModal();
				setError({
					error: '',
					actionLoading: false,
				});
			})
			.catch((error) => {
				setError({
					error: error.message,
					actionLoading: false,
				});
				dispatch(setSnackBarIsOpen(false, true, error.message));
			});
	};

	const handleNavigateClick = (member) => {
		const from = location.state || [];
		from.push({
			link: location.pathname,
			label: teamDetails.name,
		});
		if (from.length > 2) from.shift();
		history.push(`${routes['user-profile']}${member.user_id}`, from);
	};

	const handleChange = (event) => {
		const { name, value } = event.target;

		dispatch(teamActions.handleNewTeamMemberChange({ name, value }));

		if (name === 'user_id') {
			dispatch(
				teamActions.handleNewTeamMemberChange({
					name: 'name',
					value: newUsers.find((t) => t.user_id === parseInt(value))?.user_name,
				}),
			);
			dispatch(
				teamActions.handleNewTeamMemberChange({
					name: 'email',
					value: newUsers.find((t) => t.user_id === parseInt(value))?.email,
				}),
			);
		}
	};

	return (
		<>
			<div className='profile-section' style={{ gap: '12px' }}>
				<SectionBar
					header={
						<SkeletonLoading
							loading={loading}
							variant='text'
							width={150}
							height={45}
						>
							<div
								className='d-flex align-items-center'
								style={{ gap: '12px' }}
							>
								<TeamMembersIcon />
								<h2 className='HeadlineThreeBold text-left'>Team Members</h2>
							</div>
						</SkeletonLoading>
					}
				/>

				<div className='profile-section-body border'>
					<div className='team-members-body'>
						<SkeletonLoading
							loading={loading}
							variant='text'
							width='100%'
							height={45}
						>
							<TableHeader ClassName='new-table-header' border={false}>
								<TableHeaderItem
									labelClass='BodyTwoLight'
									label='Name'
									width={TableItemWidth3}
								/>
								<TableHeaderItem
									labelClass='BodyTwoLight'
									label='Email'
									width={TableItemWidth5}
								/>
								<TableHeaderItem
									labelClass='BodyTwoLight'
									label='Role'
									width={TableItemWidth5}
								/>
								<Restricted
									AllowedUsers={standardPermissions}
									userTeamRole={[
										user?.user_roles?.find((item) =>
											item?.role_scopes
												?.map((role) => role.team_id)
												?.includes(teamDetails?.id),
										)?.role_id,
									]}
								>
									<TableHeaderItem
										labelClass='BodyTwoLight'
										label='Action'
										width={TableItemWidth4}
									/>
								</Restricted>
							</TableHeader>
						</SkeletonLoading>
						{teamMembers?.filter(
							(teamMember) => teamMember.role_id !== Member.ownerId,
						)?.length > 0 ? (
							teamMembers
								?.filter((teamMember) => teamMember.role_id !== Member.ownerId)
								?.sort((a, b) => a.name.localeCompare(b.name))
								?.map((member, index) => {
									return (
										<SkeletonLoading
											loading={loading}
											variant='text'
											width='100%'
											height={45}
											key={index}
										>
											<div
												className='new-table-row d-flex align-items-center pointer'
												title={
													member.is_active === false
														? 'This user has not accepted invite yet'
														: ''
												}
												style={{
													opacity: member.is_active === false ? 0.5 : 1,
												}}
											>
												<TableRowItem
													ClassName='text-left'
													textClass='BodyTwoLight'
													label={member.name}
													width={TableItemWidth3}
													input={false}
													onClick={() => {
														if (
															(user?.user_roles
																?.map((item) => item.role_id)
																?.includes(Member.teamManagerId) ||
																user?.user_roles
																	?.map((item) => item.role_id)
																	?.includes(Member.teamMemberId)) &&
															member.role_id == Member.supervisorId
														) {
															() => {};
														} else handleNavigateClick(member);
													}}
												/>

												<TableRowItem
													ClassName=' text-left'
													textClass='BodyTwoLight'
													label={member.email}
													width={TableItemWidth5}
													input={false}
												/>

												<TableRowItem
													ClassName=' text-left'
													textClass='BodyTwoLight'
													label={
														roles.find((r) => r.id === member.role_id)
															?.role_name
													}
													width={TableItemWidth5}
													input={false}
												/>
												<Restricted
													AllowedUsers={standardPermissions}
													userTeamRole={[
														user?.user_roles?.find((item) =>
															item?.role_scopes
																?.map((role) => role.team_id)
																?.includes(teamDetails?.id),
														)?.role_id,
													]}
												>
													<Restricted
														AllowedUsers={
															member.role_id === Member.teamManagerId
																? executivePermissions
																: member.role_id == Member.supervisorId
																? // ? [Member.ownerId]
																  // : member.role_id == Member.supervisorId &&
																  //   user?.userRole?.id == Member.supervisorId
																  [Member.ownerId]
																: standardPermissions
														}
														userTeamRole={[
															user?.user_roles?.find((item) =>
																item?.role_scopes
																	?.map((role) => role.team_id)
																	?.includes(parseInt(id)),
															)?.role_id,
														]}
														RestrictedComponent={
															<TableRowItem
																ClassName=' text-left'
																textClass='BodyTwoLight'
																input={true}
																width={TableItemWidth4}
															>
																<Tooltip title='Not Authorised'>
																	<DeleteIcon className='pointer' />
																</Tooltip>
															</TableRowItem>
														}
													>
														<TableRowItem
															ClassName=' text-left'
															textClass='BodyTwoLight'
															input={true}
															width={TableItemWidth4}
														>
															<Tooltip title='Remove'>
																<DeleteIcon
																	onClick={(e) => {
																		handleClick(e, member.user_id);
																		ModalType('CONFIRM_DELETE');
																		ModalOpener();
																	}}
																	className='pointer'
																/>
															</Tooltip>
														</TableRowItem>
													</Restricted>
												</Restricted>
											</div>
										</SkeletonLoading>
									);
								})
						) : (
							<div className='TableRow d-flex align-items-center w-100'>
								<SkeletonLoading
									loading={loading}
									variant='text'
									width='100%'
									height={45}
								>
									<span className='BodyTwoLight placeholder-text'>
										This team has no members
									</span>
								</SkeletonLoading>
							</div>
						)}

						<Restricted
							AllowedUsers={standardPermissions}
							userTeamRole={[
								user?.user_roles?.find((item) =>
									item?.role_scopes
										?.map((role) => role.team_id)
										?.includes(teamDetails?.id),
								)?.role_id,
							]}
						>
							<div className='w-100 d-flex justify-content-center'>
								<div
									className='pointer BodyTwoLight primary-text p-2 w-100'
									style={{
										backgroundColor: 'white',
										borderRadius: '0px 0px 4px 4px',
									}}
									onClick={() => {
										ModalType('ADD_USER_IN_TEAM');
										toggleModal();
										dispatch(teamActions.resetNewTeamMemberValues());
									}}
								>
									<PlusIcon />
									Add New Members
								</div>
							</div>
						</Restricted>
					</div>
				</div>
			</div>

			{modalIsOpen ? (
				<Modal
					onClose={toggleModal}
					open={modalIsOpen}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<>
						{ModalName === 'CONFIRM_DELETE' && (
							<DummyModal
								title={'Remove this user from a team?'}
								onClose={toggleModal}
							>
								<ConfirmModal
									OnCancel={toggleModal}
									Onsubmit={deleteUser}
									description={'Are you sure you want to remove this user?'}
									descriptionClassName='text-left'
									isLoading={error.actionLoading}
								/>
							</DummyModal>
						)}

						{ModalName === 'ADD_USER_IN_TEAM' && (
							<DummyModal title={'Add User'} onClose={toggleModal}>
								<AddUserToTeam
									newTeamMembers={newTeamMembers}
									handleChange={handleChange}
									onCancel={toggleModal}
									teamMembers={teamMembers}
									onSubmit={addTeamMember}
									actionLoading={error.actionLoading}
								/>
							</DummyModal>
						)}
					</>
				</Modal>
			) : (
				<></>
			)}
		</>
	);
}

export default TeamMembers;
