import { memo } from 'react';
import CloseIcon from '@mui/icons-material/Close';

import { IconButton } from '@mui/material';
import ClientSectionHeader from '../Others/ClientSectionHeader';

function DummyModal({ onClose, children, warningIcon, title, style, hideCrossIcon, sectionhead, subheader, subHeaderStyle }) {
	return (
		<div className='ModalRoot fadeIn'>
			<div className='Content' style={style}>
				{!sectionhead ? (
					<div className='Header'>
						<ClientSectionHeader
							title={title}
							border={false}
							warningIcon={warningIcon}
                            subheader={subheader}
                            style={subHeaderStyle}
						/>
						<div style={{ display: 'flex', alignItems: 'center' }}>
							{!hideCrossIcon && (
								<IconButton className='CloseButton transparent' onClick={onClose}>
									<CloseIcon sx={{ color: '#333' }} />
								</IconButton>
							)}
						</div>
					</div>
				) : (
					<div></div>
				)}
				<div className='Body'>{children}</div>
			</div>
			<div className='Mask' onClick={onClose} />
		</div>
	);
}

export default memo(DummyModal);
