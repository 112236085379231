
function Intro() {
	return (
		<span className='BodyTwoLight'>
			These Terms of Use (“<strong>Terms</strong>”) apply to your access to and
			use of the website located at [www.levvy.com] (the “<strong>Site</strong>
			”) provided by Levvy Inc., a Delaware corporation (“<strong>Levvy</strong>
			” or “<strong>we</strong>”).{' '}
			<strong>
				By using or visiting our Site, you agree to these Terms, including the
				mandatory arbitration provision and class action waiver in Section 16.
				If you do not agree to these Terms, do not use or visit our Site.
			</strong>{' '}
			<br />
			<br /> These Terms do not apply to your use of Levvy’s internet-based
			software system and related services (the “<strong>Levvy Services</strong>
			”). Use of the Levvy Services is governed by the services agreement,
			product terms, or other terms governing the use of the Levvy Services.
			<br />
			<br /> We may make changes to these Terms from time to time. If we make
			changes, we will provide you with notice of such changes, such as by
			sending an email, providing a notice through our Site or updating the date
			at the top of these Terms. Unless we say otherwise in our notice, the
			amended Terms will be effective immediately, and your continued use of our
			Site after we provide such notice will confirm your acceptance of the
			changes. If you do not agree to the amended Terms, you must stop using or
			visiting our Site. <br />
			<br /> If you have any questions about these Terms or our Site, please
			contact us at <span className='primary-text'>hello@levvy.com</span>
		</span>
	);
}

export default Intro;
