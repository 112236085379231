import { ReactComponent as FolderIcon } from '../../assets/icons/Folder.svg';
import { ReactComponent as UploadIcon } from '../../assets/icons/CloudArrowUp.svg';
import { useRef, useState } from 'react';
import useOutsideClick from '../../hooks/useOutsideClick';

function DocumentStorageHeader({ disabled, handleModalOpen, hideText }) {
	const dropdownRef = useRef();

	const [isBoxOpen, setIsBoxOpen] = useState(false);
	useOutsideClick(dropdownRef, () => setIsBoxOpen(!isBoxOpen));

	return (
		<>
			<div
				className='text-left w-100 d-flex align-items-center justify-content-between document-header '
				style={{ opacity: disabled ? 0.5 : 1 }}
			>
				<div className='d-flex flex-column align-items-start'>
					<span className='d-flex' style={{ gap: '12px' }}>
						<FolderIcon />
						<h3 className='HeadlineThreeBold'>Folder & Files</h3>
					</span>
					<div className='d-flex flex-column'>
						{!hideText && (
							<span className='BodyTwoLight'>
								Choose which folders should be made available to this workflow
							</span>
						)}
						<span
							className='BodyTwoLight flex align-items-center '
							style={{ color: '#696f6c' }}
						>
							<UploadIcon style={{ width: '15px', height: '15px' }} /> Upload or move
							a file by dragging and dropping the file onto a folder
						</span>
					</div>
				</div>
				<div className='d-flex align-items-center'>
					<div style={{ marginInline: '15px' }}>
						<button
							onClick={disabled ? null : handleModalOpen('CREATE_FOLDER')}
							className='curved primary-btn d-flex'
						>
							<span className='BodyTwoBold pt-1 pb-1'>Create Folder</span>
						</button>
					</div>

					<div>
						<button
							onClick={disabled ? null : handleModalOpen('UPLOAD_FILES')}
							className='curved primary-btn d-flex align-items-center pb-1'
						>
							<span className='BodyTwoBold pt-1 pr-1 pl-1'>Upload File</span>
						</button>
					</div>
				</div>
			</div>
		</>
	);
}

export default DocumentStorageHeader;
