export const routes = {
	clients: '/clients',
	'client-profile': '/client-profile/',
	users: '/users',
	'user-profile': '/user-profile/',
	'archived-users':'/archived-users',
	teams: '/teams',
	'team-profile': '/team-profile/',
	workspace: '/workspace',
	leaves: '/leaves',
	'account-settings': '/account-settings',
	'time-reports': '/time-reports',
	'client-invoice': '/client-invoice',
	'company-profile': '/company-profile',
	'Workflow-Builder': '/Workflow-Builder',
	'archieved-workflow': '/archieved-workflow',
	oauth:'/oauth'
};