// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from 'react';

// Material Ui
import AddIcon from '@mui/icons-material/Add';
import SearchBar from './SearchBar';

function EditablePickList({
	Edit,
	label,
	value,
	options,
	onChange,
	withSearch,
	placeholder,
}) {
	const [isCheck, setIsCheck] = useState(value);
	const [search, setSearch] = useState(null);
	const handleClick = (e) => {
		const { id, checked } = e.target;
		if (checked && id) {
			onChange([...isCheck, parseInt(id)]);
		} else {
			onChange(isCheck.filter((item) => item !== parseInt(id)));
		}
	};

	useEffect(() => {
		setIsCheck(value);
	}, [value]);
	
	if (withSearch) {
		return (
			<>
				{Edit ? (
					<>
						<div
							className='w-100 h-100 TeamMultiPickList'
							style={{
								display: 'flex',
								flexDirection: 'column',
							}}
						>
							<label className='w-100 m-0 custom-label text-left'>
								{label}
							</label>
							<div
								style={{
									border: '1px solid black',
									backgroundColor: 'rgb(246, 245, 242)',
								}}
							>
								<div style={{ margin: '12px 5px 0 5px' }}>
									<SearchBar
										disabled={options?.length === 0}
										placeholder={placeholder || `Search ${label}`}
										type={'filterSearchBar'}
										filterInputValue={search}
										filterOnChange={(e) => setSearch(e.target.value)}
										style={{
											color: 'black',
											border: '1px solid #E9E7E1',
											borderRadius: '3px',
											// backgroundColor: 'rgba(0, 0, 0, 0.25)',
										}}
									/>
								</div>

								<div
									style={{
										// backgroundColor: '#ffffff',
										maxHeight: '100px',
										overflowY: 'auto',
									}}
									className='p-1 pt-0'
									label={label}
								>
									{options
										?.filter((opt) => {
											if (search)
												return opt?.label
													?.toLowerCase()
													?.includes(search.toLowerCase());
											return true;
										})
										?.map((choice, index) => {
											return (
												<div
													key={index}
													className='ItemWrapper  the-item-wrapper my-2 p-1 mx-1'
												>
													<input
														id={choice.value}
														name={index}
														type='checkbox'
														className='multi-select-checkbox'
														onChange={handleClick}
														checked={isCheck.includes(choice.value)}
													/>
													<div className='w-100'>
														<h4 className='BodyTwoLight text-dark ml-2'>
															{choice.label}
														</h4>
													</div>
												</div>
											);
										})}

									{options?.length === 0 && (
										<h4 className='BodyTwoLight placeholder-text'>no list</h4>
									)}
								</div>
							</div>
						</div>
					</>
				) : (
					<div className='ProfilePage d-flex flex-column w-100'>
						<label className='text-left m-0'>{label}</label>
						<div
							style={{
								maxHeight: '125px',
								overflow: 'auto',
								boxSizing: 'border-box',
								paddingTop: '1px',
								paddingBottom: '1px',
							}}
						>
							{options?.map((o, i) => {
								return (
									<h3 key={i} className='BodyOneSherif text-left'>
										{value.find((v) => v === o?.value) && o?.label}
									</h3>
								);
							})}
						</div>
					</div>
				)}
			</>
		);
	} else {
		return (
			<>
				{Edit ? (
					<>
						<div
							className='w-100 h-100 TeamMultiPickList'
							style={{
								display: 'flex',
								flexDirection: 'column',
							}}
						>
							<label className='w-100 m-0 custom-label text-left'>
								{label}
							</label>
							<div
								style={{
									backgroundColor: '#F6F5F2',
									maxHeight: '100px',
									overflowY: 'auto',
									border: options?.length && '1px solid #002E2E',
									padding: '5px',
								}}
								className='p-1'
								label={label}
							>
								{options?.map((choice, index) => {
									return (
										<div
											key={index}
											className='ItemWrapper  the-item-wrapper p-1 m-1'
										>
											<input
												id={choice.value}
												name={index}
												type='checkbox'
												className='multi-select-checkbox'
												onChange={handleClick}
												checked={isCheck.includes(choice.value)}
											/>
											<div className='w-100'>
												<h4 className='BodyTwoLight text-dark ml-2'>
													{choice.label}
												</h4>
											</div>
										</div>
									);
								})}

								{options?.length === 0 && (
									<h4 className='BodyTwoLight placeholder-text'>no list</h4>
								)}
							</div>
						</div>
					</>
				) : (
					<div className='ProfilePage d-flex flex-column w-100'>
						<label className='text-left m-0'>{label}</label>
						<div
							style={{
								maxHeight: '125px',
								overflow: 'auto',
								boxSizing: 'border-box',
								paddingTop: '1px',
								paddingBottom: '1px',
							}}
						>
							{options?.map((o, i) => {
								return (
									<h3 key={i} className='BodyOneSherif text-left'>
										{value.find((v) => v === o?.value) && o?.label}
									</h3>
								);
							})}
						</div>
					</div>
				)}
			</>
		);
	}
}

export default EditablePickList;
