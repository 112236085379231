/* eslint-disable no-mixed-spaces-and-tabs */
import { Checkbox, Tooltip } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DropDown from '../../Inputs/DropDown';
import * as WorkSpaceActions from '../../../redux/actions/dashboard/workspaces-actions';
import * as clientActions from '../../../redux/actions/client-actions';
import * as builderActions from '../../../redux/actions/workflow-builder-actions';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { BillTypes, RepeatTypes } from '../../DemoData';
import TextBox from '../../Inputs/TextBox';
import * as teamActions from '../../../redux/actions/team-management.actions';
import InputTaskDetails from '../../Inputs/InputTaskDetails';
import { Member, convertDecimalToTime, convertToDecimal } from '../../../utilities/utilities';
import useEditTimeFormat from '../../../hooks/useEditTimeFormat';
import EditableRadioButton from '../../Inputs/EditableRadioButton';
import NewSearchDropdown from '../../Inputs/NewSearchDropdown';
import { getUserSettings } from '../../../hooks/getUserSettings';

function WorkflowTaskModalInputs({ clientId }) {
	const dispatch = useDispatch();
	let assignees = useSelector((state) => state.teamReducer.teamMembers);
	const form = useSelector((state) => state.workSpaceReducer.createTaskForm);
	const clients = useSelector((state) => state.clientReducer.clients);
	const activeClientWorkflows = useSelector(
		(state) => state.WorkflowBuilder.activeClientWorkflows,
	);
	const user = useSelector((state) => state.authReducer.user);
	const repeatTime = useSelector((state) => state.workSpaceReducer.repeatTime);
	const loggedInUser = useSelector((state) => state.authReducer.user);
    const userDetails = useSelector((state) => state.authReducer.userDetails);
	const { date_format } = getUserSettings(userDetails);
	const { inputValue, setInputValue, handleInputChange } = useEditTimeFormat(
		form?.budgeted_hours,
	);

	const Style = {
		backgroundColor: 'white',
		border: 'none',
		borderBottom: '1px solid #28383C',
	};

	const quillHandlerNotes = (content) => {
		dispatch(
			WorkSpaceActions.handleChange({
				name: 'notes',
				value: content,
			}),
		);
	};

	const quillHandlerDescription = (content) => {
		dispatch(
			WorkSpaceActions.handleChange({
				name: 'description',
				value: content,
			}),
		);
	};

	assignees = assignees?.filter((assignee) => assignee?.is_active == true);
	const client = clients?.find((client) => client.id == form?.client_id);
	useEffect(() => {
		client?.team_client_permissions &&
			dispatch(teamActions.getTeamMembers(client?.team_client_permissions[0]?.team_id));
		form?.client_id && dispatch(builderActions.getActiveClientWorkflows(form?.client_id));
	}, [dispatch, form?.client_id]);

	const handleChange = (event) => {
		let { name, value } = event.target;

		if (name == 'is_billable') {
			value = BillTypes?.find((BillType) => BillType.value == value)?.id;
		}

		if (name == 'assignee_user_id') {
			dispatch(
				WorkSpaceActions.handleChange({
					name: 'approval_from',
					value: null,
				}),
			);
		}

		if (name == 'approval_from') {
			value = Number(value);
		}

		dispatch(WorkSpaceActions.handleChange({ name, value }));
	};

	const handleClientChange = (client) => {
		const { label, value } = client;
		dispatch(WorkSpaceActions.handleChange({ name: 'client_id', value }));
		dispatch(WorkSpaceActions.handleChange({ name: 'assignee_user_id', value: null }));
		dispatch(
			WorkSpaceActions.handleChange({
				name: 'client_workflow_id',
				value: null,
			}),
		);
	};

	const handleCheck = (event) => {
		let { name, value } = event.target;
		value = event.target.checked;
		if (!value) {
			dispatch(WorkSpaceActions.handleChange({ name, value }));
			dispatch(WorkSpaceActions.handleChange({ name: 'approval_from', value: null }));
		} else {
			dispatch(WorkSpaceActions.handleChange({ name, value }));
		}
	};

	const handleMultipleGuestSelection = (selecteds) => {
		dispatch(
			WorkSpaceActions.handleChange({
				name: 'meeting_guests',
				value: selecteds,
			}),
		);
	};

	const handleRepeatChange = (e) => {
		dispatch(WorkSpaceActions.handleRepeatChange(e.target.value));
	};

	const handleDateChange = (date) => {
		dispatch(WorkSpaceActions.handleDateChange(date));
	};

	const handleInputBlur = () => {
		let value = 0;
		const name = 'budgeted_hours';

		if (inputValue === '') {
			return;
		}

		if (inputValue.includes(':')) {
			value = convertToDecimal(inputValue);
		} else if (!isNaN(inputValue)) {
			value = parseFloat(inputValue);
		}

		const formattedValue = convertDecimalToTime(value);
		setInputValue(formattedValue);
		dispatch(WorkSpaceActions.handleChange({ name, value }));
	};

	useEffect(() => {
		dispatch(WorkSpaceActions.getTaskTypes());
		dispatch(clientActions.getClients());
	}, []);

	useEffect(() => {
		if (clientId) {
			{
				dispatch(WorkSpaceActions.handleChange({ name: 'task_type_id', value: 2 }));
				dispatch(WorkSpaceActions.handleChange({ name: 'client_id', value: clientId }));
				dispatch(WorkSpaceActions.handleChange({ name: 'is_billable', value: 1 }));
			}
		} else {
			dispatch(WorkSpaceActions.handleChange({ name: 'task_type_id', value: 1 }));
			dispatch(WorkSpaceActions.handleChange({ name: 'required_days', value: 1 }));
		}
	}, []);

	if (clients?.length === 0) {
		assignees = assignees.filter((item) => item.id === user.userId);
	}

	const filteredAssignees =
		loggedInUser?.userRole?.id == Member.externalCollaborator
			? assignees.filter((user) => user?.user_id === loggedInUser?.userId)
			: assignees;

	return (
		<div
			className='UserAdd-Form d-flex flex-column pr-2'
			style={{ gap: '16px', height: 'inherit' }}
		>
			<div className='improved-input-designs pt-1'>
				<label className='require'>Task</label>
				<input
					className='w-100'
					type='text'
					name='title'
					onChange={handleChange}
					style={Style}
					required
					value={form?.title}
				/>
			</div>

			<div className='improved-input-designs pb-3'>
				<label>Instructions</label>
				<InputTaskDetails
					richText
					type='text'
					name='description'
					onChange={quillHandlerDescription}
					value={form?.description}
				/>
			</div>

			<div className='improved-input-designs'>
				<label className='require w-100'>Budgeted Hours</label>
				<Tooltip
					title='Entered value will be converted to hh:mm format'
					placement='bottom-start'
				>
					<input
						className='w-100'
						type='text'
						style={Style}
						value={inputValue}
						name='budgeted_hours'
						onChange={handleInputChange}
						required={true}
						onBlur={handleInputBlur}
						placeholder='HH : MM'
					/>
				</Tooltip>
			</div>

			<div className='improved-input-designs pb-3'>
				<label>Notes</label>
				<InputTaskDetails
					richText
					type='text'
					name='notes'
					value={form?.notes}
					onChange={quillHandlerNotes}
				/>
			</div>

			{/* <DropDown
				rootClassName='improved-input-designs'
				onChange={handleChange}
				label='Task Type'
				name='task_type_id'
				style={{ ...Style, backgroundColor: clientId ? '#f6f5f2' : 'white' }}
				selectDisabled={true}
				className='require'
				required={true}
				value={taskTypes
					?.map((task) => {
						return {
							value: task.id,
							label: task.name,
						};
					})
					?.find((item) => item.value == form?.task_type_id)}
				options={
					taskTypes && clientId
						? taskTypes
								?.map((task) => {
									return {
										value: task.id,
										label: task.name,
									};
								})
								?.filter((taskType) => taskType?.value == 2)
						: taskTypes &&
						  taskTypes?.map((task) => {
								return {
									value: task.id,
									label: task.name,
								};
						  })
				}
			/> */}

			<div className='improved-input-designs flex-column d-flex'>
				<label className='require'>Start Date</label>
				<KeyboardDatePicker
					autoOk
					disableToolbar
					value={form?.due_date || null}
					name='due_date'
					onChange={handleDateChange}
					format={date_format}
					disablePast
					className='p-1'
					required
				/>
			</div>
			<div className='improved-input-designs'>
				<label>Days to complete</label>
				<input
					className='w-100'
					type='number'
					name='required_days'
					// required
					onChange={handleChange}
					style={Style}
					value={form?.required_days}
					min={1}
				/>
			</div>
			{form?.task_type_id === 2 && (
				<>
					<DropDown
						rootClassName='improved-input-designs'
						onChange={handleChange}
						label='Repeat Type'
						name='repeat_type'
						className='require'
						required={true}
						options={RepeatTypes}
						style={Style}
						value={RepeatTypes.find((item) => item.value === form?.repeat_type)}
					/>
					<div className='improved-input-designs'>
						<label className='require'>Repeat every</label>
						<input
							className='w-100'
							type='number'
							name='repeat_interval'
							required
							onChange={handleChange}
							style={Style}
							value={form?.repeat_interval}
							min={0}
						/>
					</div>
					<div className='improved-input-designs'>
						<label className='require'>Repeat time</label>
						<input
							className='w-100'
							type='number'
							name='repeat_time'
							required
							onChange={handleRepeatChange}
							style={Style}
							value={repeatTime}
							min={2}
						/>
					</div>
				</>
			)}

			<div className='improved-input-designs'>
				<label className='require'>Bill Type</label>
				<EditableRadioButton
					name='is_billable'
					value={clientId ? 'Billable' : form?.is_billable}
					onChange={handleChange}
					options={BillTypes}
					required
					disabled={clientId}
					Edit
				/>
			</div>

			<div className='improved-input-designs'>
				<label className='require'>Client</label>
				<NewSearchDropdown
					selectClassName={'w-100'}
					width={'380px'}
					value={
						clients
							?.map((client) => {
								{
									return {
										value: client.id,
										label:
											client?.dba?.trim() == ''
												? client.company_name
												: client.dba,
									};
								}
							})
							.find((item) => item.value == form?.client_id)?.value
					}
					placeholder='Please Select'
					disableLetters
					changeHandler={handleClientChange}
					options={
						clientId
							? clients
									?.map((client) => {
										return {
											value: client.id,
											label:
												client?.dba?.trim() == ''
													? client.company_name
													: client.dba,
										};
									})
									?.filter((client) => client?.value == clientId)
							: clients?.map((client) => {
									return {
										value: client.id,
										label:
											client?.dba?.trim() == ''
												? client.company_name
												: client.dba,
									};
							  })
					}
					name={'client_id'}
					label={'Client'}
					selecDisabled={clientId ? true : false}
					required={true}
					disableNull={true}
				/>
			</div>

			{/* <div className='new-input-designs'>
				<label className='w-100'>Meeting Link</label>
				<input
					className='w-100'
					type='text'
					style={Style}
					value={form?.meeting_link}
					name='meeting_link'
					onChange={handleChange}
				/>
			</div> */}

			{/* <div className='new-input-designs'>
				<label className=' w-100'>Add Guests</label>
				<MultipleDropDown
					name='meeting_guests'
					toggleSelect={() => setMultiSelectOpen(!multiSelectOpen)}
					isOpen={multiSelectOpen}
					options={
						form?.client_id
							? assignees?.map((assignee) => {
									return {
										value: assignee?.user_id,
										label: assignee?.name,
										is_checked: form?.teams?.find(
											({ value }) => value === assignee.value,
										)?.is_checked
											? true
											: false,
									};
							  })
							: []
					}
					changeHandler={(people) => {
						handleMultipleGuestSelection(
							people
								?.filter((item) => item.is_checked)
								?.map(({ value }) => value),
						);
					}}
				/>
			</div> */}

			{form?.client_id && (
				<div className='improved-input-designs'>
					<label>Workflows</label>
					<DropDown
						onChange={handleChange}
						normalDropDown
						name='client_workflow_id'
						style={{ height: '32px' }}
						options={activeClientWorkflows?.map((clientWorkflow) => {
							return {
								label: clientWorkflow?.display_name,
								value: clientWorkflow?.id,
							};
						})}
						// disableNull
						value={form?.client_workflow_id}
					/>
				</div>
			)}

			<div className='improved-input-designs'>
				<label >Assignee</label>
				<DropDown
					onChange={handleChange}
					normalDropDown
					name='assignee_user_id'
					style={{ height: '32px' }}
					options={
						form?.client_id
							? filteredAssignees
									?.filter(
										(assignee) =>
											assignee?.user_id != Number(form?.approval_from),
									)
									?.map((assignee) => {
										return {
											value: assignee?.user_id,
											label: assignee?.name,
											is_active: assignee?.is_active,
										};
									})
							: []
					}
					value={form?.assignee_user_id || ''}
				/>
			</div>

			<div className='improved-input-designs'>
				<div>
					<Checkbox
						sx={{
							color: '#b4b4b4 !important',
							'&.Mui-checked': {
								color: '#FB7A03 !important',
							},
							'&.MuiCheckbox-root': {
								'&:hover': {
									boxShadow: 'none',
									backgroundColor: 'transparent !important',
								},
							},
						}}
						style={{
							paddingLeft: 0,
						}}
						disabled={!form?.assignee_user_id}
						checked={form?.is_approval_required}
						onChange={handleCheck}
						name='is_approval_required'
					/>
					<label
						style={{ paddingTop: '10px !important' }}
						className='approval-text align-self-center w-50 pt-4'
					>
						Require approval{' '}
					</label>
				</div>
			</div>

			{form?.is_approval_required && (
				<>
					<div className='improved-input-designs'>
						<label className='require'>Approval From</label>
						<DropDown
							onChange={handleChange}
							normalDropDown
							name='approval_from'
							style={{ height: '32px' }}
							required={true}
							options={assignees
								?.filter(
									(assignee) =>
										Number(form?.assignee_user_id) != assignee.user_id &&
										assignee?.role_id !== Member.teamMemberId &&
										assignee?.role_id !== Member.externalCollaborator,
								)
								?.map((assignee) => {
									return {
										value: assignee.user_id,
										label: assignee.name,
										is_active: assignee.is_active,
									};
								})}
							disableNull
							value={form?.approval_from}
						/>
					</div>

					{form?.approval_from && (
						<div className='improved-input-designs'>
							<TextBox
								style={{
									backgroundColor: '#ededed',
								}}
								className='p-2 approval-text'
							>
								{
									assignees?.find(
										(assignee) =>
											Number(assignee.user_id) == Number(form?.approval_from),
									)?.name
								}{' '}
								will be required to approve the task to mark this task complete.
							</TextBox>
						</div>
					)}
				</>
			)}
		</div>
	);
}

export default WorkflowTaskModalInputs;
