import { ReactComponent as RestrictedImage } from '../assets/icons/Restricted.svg';

export default function RestrictedAccess() {
	return (
		<div className='ErrorFound'>
			<div className='restricted-access-container'>
				<div>
					<RestrictedImage />
				</div>
				<div>
					<span className='BodyOneLight'>
						We regret to inform you that your access has been restricted as the payment
						due date has passed. To restore access, please update your payment details.
					</span>
				</div>
			</div>
		</div>
	);
}
