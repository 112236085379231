import ModalHeader from '../../Others/ModalHeader';
import { ReactComponent as Warning } from '../../../assets/icons/WarningBlack.svg';
import { useDispatch, useSelector } from 'react-redux';
import ArchieveTeamUsers from './ArchieveTeamUsers';
import ArchieveTeamClient from './ArchieveTeamClient';
import { CircularProgress } from '@mui/material';
import { Member } from '../../../utilities/utilities';
import { useHistory } from 'react-router';
import * as teamActions from '../../../redux/actions/team-management.actions';
import { useEffect } from 'react';

function ArchieveTeamModal({ onArchieve, onCancel, teamId }) {
	const dispatch = useDispatch();
	const history = useHistory();

	const Loading = useSelector((state) => state.teamReducer.archiveLoading);
	const teamDetails = useSelector((state) => state.teamReducer.teamDetails);
	const teamMembers = useSelector(
		(state) => state.teamReducer.teamMembers,
	)?.filter((teamMember) => teamMember.role_id !== Member.ownerId);
	const clients = useSelector((state) => state.clientReducer.clients);

	const archiveTeam = (event) => {
		event.preventDefault();

		const archiveTeamObj = {
			user_teams: teamMembers
				?.filter(({ role_id }) => role_id != Member.ownerId)
				?.map((member) => {
					return {
						id: member.user_team_id,
						team_id: member.team_id,
						user_id: member.user_id,
					};
				}),
			clients: clients?.map((client) => {
				return {
					...client,
					team_client_permissions: client?.team_client_permissions?.map(
						(item) => {
							const obj = item;
							delete item.team;
							return obj;
						},
					),
				};
			}),
		};

		console.info('archiveTeamObj', archiveTeamObj);
		dispatch(teamActions.archiveTeam(archiveTeamObj, teamId, archiveCallBack));
	};

	const archiveCallBack = () => {
		history.push('/teams');
	};

	return (
		<form onSubmit={archiveTeam} className='archieve-team-modal'>
			<ModalHeader title='Archive Team' closeHandler={onCancel}>
				<Warning />
			</ModalHeader>

			<div className='archieve-team-modal-body'>
				<div className='warning-message BodyOneLight'>
					<div>
						The following team members and clients are assigned to{' '}
						<span className='BodyTwoBold'> {teamDetails?.name}</span>. Please
						archive or reassign the team members and the clients in order to
						archive this team.
					</div>
					<br />
					<div>
						<span className='BodyTwoBold'>Note: </span>
						You will not be able to archive team members who are assigned to
						multiple active teams. Following list contains the users which are
						only part of this team and need to be reassigned a team.
					</div>
				</div>

				<ArchieveTeamUsers teamId={teamId} />

				<ArchieveTeamClient teamId={teamId} />
			</div>

			<div className='VerticalSpacer bg-white p-3 rounded-bottom d-flex justify-content-between'>
				<button type='button' onClick={onCancel} className='secondary-btn'>
					Cancel
				</button>
				
				<button
					data-cy='archive-submit'
					disabled={Loading}
					type='submit'
					className='primary-btn'
				>
					{Loading ? (
						<CircularProgress size={24} sx={{ color: 'white' }} disableShrink />
					) : (
						'Archive'
					)}
				</button>

			</div>
		</form>
	);
}

export default ArchieveTeamModal;
