import React, { memo, ReactNode } from 'react';




const TableHeader = ({ children, border = true , ClassName}) => {
  return <div className={border ? 'with-border' : ClassName }>{children}</div>;
};

export default memo(TableHeader);
