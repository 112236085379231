import { CircularProgress, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

function LoadingOverLay({ active, children }) {
	return (
		<>
			<Box
				sx={{
					display: active ? 'block' : 'none',
					position: 'absolute',
					width: '100%',
					height: '100%',
					backgroundColor: 'White',
					zIndex: 2,
				}}
			>
				<Box
					sx={{
						height: '100%',
						width: '100%',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						flexDirection: 'column',
						gap: '1em',
						color: '#28383C',
					}}
				>
					<CircularProgress size={40} sx={{ color: 'lightgrey' }} />
					<Typography
						sx={{
							position: 'relative',
							textAlign: 'center',
							fontSize: '1em',
							fontFamily: 'Calibre',
						}}
					>
						Loading...
					</Typography>
				</Box>
			</Box>
			{children}
		</>
	);
}

export default LoadingOverLay;
