import { secure_instance } from '../../axios/axios-config';
import { setSnackBarIsOpen } from './dashboard/workspaces-actions';

export const GET_CLIENT_BILLING = '[CLIENT_BILLING] GET_CLIENT_BILLING';
export const ADD_CLIENT_BILLING = '[CLIENT_BILLING] ADD_CLIENT_BILLING';
export const UPDATE_CLIENT_BILLING = '[CLIENT_BILLING] UPDATE_CLIENT_BILLING';

export const getClientBilling = (clientId) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `v1/client_billings/?client_id=${clientId}`,
				method: 'GET',
			});

			dispatch({
				type: GET_CLIENT_BILLING,
				payload: request.data,
			});
		} catch (error) {
			//err
		}
	};
};

export const addClientBilling = (data, toggleModal, setLoading) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `v1/client_billings/`,
				method: 'POST',
				data: data,
			});

			dispatch({
				type: ADD_CLIENT_BILLING,
				payload: request.data,
			});

			data && dispatch(getClientBilling(data?.client_id));
			setSnackBarIsOpen(true, true, 'Update client billing successfully added');
			toggleModal && toggleModal();
		} catch (error) {
			dispatch(setSnackBarIsOpen(false, true, error?.response?.data?.message));
		}

		setLoading && setLoading(false);
	};
};

export const updateClientBilling = (id, data, toggleModal, setLoading) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `v1/client_billings/${id}`,
				method: 'PATCH',
				data: data,
			});

			console.log(request, 'request1234');
			dispatch({
				type: UPDATE_CLIENT_BILLING,
				payload: request.data,
			});
			dispatch(setSnackBarIsOpen(true, true, 'Update client billing successfull'));
			toggleModal && toggleModal();

			// request.data && dispatch(getClientBilling(request.data?.client_id));
		} catch (error) {
			//   alert(error?.response?.data?.message);

			dispatch(setSnackBarIsOpen(false, true, error?.response?.data?.message));
		}

		setLoading && setLoading(false);
	};
};
