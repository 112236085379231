import React from 'react';
import { useSelector } from 'react-redux';

const ResetLinkSent = () => {
	const form = useSelector((state) => state.authReducer.forgotPasswordForm);

	return (
		<>
			<div className='LoginBox591'>
				<div className=' '>
					<h1 className='titleBold'>Reset Link Sent</h1>
				</div>
				<div className='dfCenter'>
					<p className='' style={{ fontSize: '14px' }}>
						A reset link has been send to &nbsp;
						<span style={{ color: '#FB7A03' }}>{form.email}</span>. Please check
						your email to reset your password.
					</p>
				</div>
			</div>
		</>
	);
};

const ResetLink = () => {
	return (
		<>
			<ResetLinkSent />
		</>
	);
};
export default ResetLink;
