import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { noAuth } from './Authapi';
import { Route, Redirect } from 'react-router-dom';
import { Auth } from './Authapi';
import { isAuthenticated } from '../redux/actions/authactions';
import CircularProgress from '@mui/material/CircularProgress';
const ProtectedRoutes = async (setIsActive,setIsSubscribed,setAuth,setLoading) => {
  const tokenDetails = localStorage.getItem('persist:root');
  const token = JSON.parse(tokenDetails);
  const refresh_token = JSON.parse(token.Token).refresh_token;
  const refresh_token_expires = JSON.parse(token.Token).refresh_token_expires;

  // if (Date.now() > refresh_token_expires) {
  //   // alert('Need to login Again')
  //   // return false;
  // } else {
    return await noAuth('/v1/auth/refresh', 'post', { refresh_token }).then(
      response => {
         if (response.status==200) {
           setAuth(true)
          const user = localStorage.getItem('persist:root');
          const userId = JSON.parse(user);
          const IdInUrl = JSON.parse(userId.userId).userId;
          const URL='/v1/users/'+IdInUrl;
          Auth(URL,'get').then((res)=>{
            if(res.data.is_active){
              setIsActive(true)
              Auth('/v1/subscriptions/','get').then((response)=>{
                if(response.status<=299){
                  setIsSubscribed(true)
                  setLoading(false);
                }
                else{
                  setIsSubscribed(false)
                  setLoading(false);
                }
            })
            }else{
              setIsActive(false)
              setLoading(false);
            }
          })
         
        } else {
          // alert('not authenticated')
          setAuth(false)
          setLoading(false);
        }
      },
    );
  // }
};
const PrivateRoute = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [isSubscribed,setIsSubscribed]=useState(false)
  const [isActive,setIsActive]=useState(false)
  const [Auth, setAuth] = useState(false);
  
  useEffect(() => {
    ProtectedRoutes(setIsActive,setIsSubscribed,setAuth,setLoading).then(data => {
     
      dispatch(isAuthenticated(Auth));
      // setAuth(data);
      
    });
  }, []);
  const isAuth = useSelector((state) => {
    return JSON.stringify(state.isAuthenticated.isAuthenticated);
  });
  if (loading) {
    // alert('called')
    return (
      // <div>

      // </div>
      <CircularProgress style={{color:'#fa7b03',height:'50px',width:'50px',marginTop:'47vh'}} />
    )
  } else {
    return (
      <>
     
        <Route
          {...rest}
          render={props =>
            (Auth)? (isActive)?(isSubscribed)?(
              <Component {...props} />
            ) :(<Redirect
              to={{
                pathname: '/subscription',
              }}
            />): (
              <Redirect
                to={{
                  pathname: '/activation',
                }}
              />
            ): (
              <Redirect
                to={{
                  pathname: '/',
                }}
              />
            )
          }
        />
      </>
    );
  }
};

export default PrivateRoute;
