import { useEffect, useState } from 'react';
import Select from '../Inputs/Select';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, Modal } from '@mui/material';
import SubmitButtonsField from '../Buttons/SubmitButtonsField';
import * as userActions from '../../redux/actions/user-management.actions';
import * as teamActions from '../../redux/actions/team-management.actions';
import { setSnackBarIsOpen } from '../../redux/actions/dashboard/workspaces-actions';

const UnarchiveUserModal = ({
	user,
	toggleModal,
	setUnarchiveSuccessfully,
	title,
	descrption,
	FormFields,
}) => {
	const dispatch = useDispatch();

	const [unarchivedFormVales, setUnarchivedFormValues] = useState({
		team_id: FormFields?.teams[0],
		role_id: FormFields?.role_id,
	});

	console.log(unarchivedFormVales, 'unarchivedFormVales');
	const [formControls, setFormControls] = useState({
		error: '',
		loading: false,
	});

	const { teams } = useSelector((state) => state.teamReducer);
	const { roles } = useSelector((state) => state.userReducer);

	const updatedRoleList = roles?.filter((item) => item.role_name !== 'Client');

	const onUnarchivedSubmitHandler = (e) => {
		e.preventDefault();

		setFormControls({
			error: '',
			loading: true,
		});
		const data = {
			role_id: parseInt(unarchivedFormVales.role_id),
			team_id: parseInt(unarchivedFormVales.team_id),
		};

		dispatch(userActions.unarchiveUser(user?.id, data))
			.then(() => {
				setFormControls({
					error: '',
					loading: false,
				});
				setUnarchivedFormValues({
					team_id: '',
					role_id: '',
				});
				setUnarchiveSuccessfully();
			})
			.catch((error) => {
				setFormControls({
					error: error?.message,
					loading: false,
				});

				dispatch(setSnackBarIsOpen(false, true, error?.message || 'Something went Wrong'));
			});
	};

	useEffect(() => {
		dispatch(userActions.getRoles());
		dispatch(teamActions.getTeams());
		dispatch(userActions.getAllArchivedUsers());
	}, [dispatch]);

	return (
		<>
			<div className='ModalRoot fadeIn'>
				<div className='Content'>
					<div style={{ height: '5.7rem' }} className='Header'>
						<h1 className='HeadlineTwoBold' style={{ lineHeight: '26px' }}>
							{title}
						</h1>
					</div>
					<div className='Body'>
						<form autoComplete='off' onSubmit={onUnarchivedSubmitHandler}>
							<div className='px-4'>
								<div className='BodyTwoLight  w-100'>
									<p>
										{descrption}
										<strong>{user?.full_name}</strong>
									</p>
								</div>
								<div className='improved-input-designs pb-3'>
									<label className='require'>Team</label>
									<Select
										name='team_id'
										value={teams
											.map((role) => {
												return {
													label: role?.role_name,
													value: role?.id,
													...role,
												};
											})
											.find(
												(item) =>
													item?.id ===
													parseInt(unarchivedFormVales?.team_id),
											)}
										selectClassName='w-100'
										options={teams.map((item) => {
											return {
												label: item?.name,
												value: item?.id,
												...item,
											};
										})}
										required={true}
										onChange={(e) => {
											setUnarchivedFormValues({
												...unarchivedFormVales,
												team_id: e.target.value,
											});
										}}
									/>
								</div>

								<div className='improved-input-designs pb-3'>
									<label className='require'>Role</label>
									<Select
										name='role_id'
										value={updatedRoleList
											?.map((role) => {
												return {
													label: role?.role_name,
													value: role?.id,
													...role,
												};
											})
											?.find(
												(role) =>
													role.id ===
													parseInt(unarchivedFormVales?.role_id),
											)}
										options={updatedRoleList?.map((item) => {
											return {
												label: item?.role_name,
												value: item?.id,
												...item,
											};
										})}
										selectClassName='w-100'
										required={true}
										onChange={(e) => {
											setUnarchivedFormValues({
												...unarchivedFormVales,
												role_id: e.target.value,
											});
										}}
									/>
								</div>
							</div>

							<div className='VerticalSpacer bg-white py-3 px-4 rounded-bottom'>
								<SubmitButtonsField child={true}>
									<button
										id='Element'
										type='button'
										className='secondary'
										onClick={() => toggleModal()}
									>
										Cancel
									</button>

									<button
										data-cy='submit-recurrence'
										disabled={formControls.loading}
										type='submit'
										className='d-flex align-items-center'
										id='Element'
									>
										{formControls.loading ? (
											<CircularProgress
												size={24}
												sx={{ color: 'white' }}
												disableShrink
											/>
										) : (
											'Unarchive'
										)}
									</button>
								</SubmitButtonsField>
							</div>
						</form>
					</div>
				</div>
				<div className='Mask' onClick={() => toggleModal()} />
			</div>
		</>
	);
};

export default UnarchiveUserModal;
