/* eslint-disable no-mixed-spaces-and-tabs */
import * as Actions from '../actions/time-reports-actions';

const initialState = {
	loading: true,
	userDetails: {},
	users: [],
	clients: [],
	userProfiles: [],
	clientProfiles: [],
	userRole: [],
	clientDeatils: {},
	user_budgeted_hours: 0,
	monthlyView: false,
	tasksLoading: false,
	tasks:[],
	initialLoading: true
	// loggedTime: '',
};

const TimeReports = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case Actions.GET_USERS_START:
			return (state = {
				...state,
				users:[],
				loading: true,
			});
		case Actions.GET_USERS_END:
			return (state = {
				...state,
				loading: false,
				users: payload,
			});
		case Actions.GET_USERS_FAILED:
			return (state = {
				...state,
				loading: false,
			});
		case Actions.GET_CLIENTS_START:
			return (state ={
				...state,
				client:[],
				loading:true
			});
		case Actions.GET_CLIENTS_END:
			return (state = {
				...state,
				loading: false,
				clients: payload,
			});
		case Actions.GET_USERS_PROFILES_START:
			return (state = {
				...state,
				loading: true,
			});
		case Actions.GET_USERS_PROFILES_END:
			return (state = {
				...state,
				userProfiles: payload,
			});
		case Actions.GET_USERS_PROFILES_FAILED:
			return (state = {
				...state,
				loading: false,
			});
		case Actions.GET_CLIENT_PROFILES_START:
			return (state = {
				...state,
				loading: true,
			});
		case Actions.GET_CLIENT_PROFILES_END:
			return (state = {
				...state,
				clientProfiles: payload,
			});
		case Actions.GET_CLIENT_PROFILES_FAILED:
			return (state = {
				...state,
				loading: false,
			});
		case Actions.GET_USER_DETAILS_START:
			return (state = {
				...state,
				loading: payload,
			});
		case Actions.GET_USER_DETAILS_END:
			return (state = {
				...state,
				userDetails: {
					user_id: payload?.id,
					full_name: payload?.full_name,
					user_budgeted_hours: payload.budgeted_hours,
					meeting_tasks:
						payload?.meeting_my_tasks?.length < 1
							? payload.meeting_my_tasks
							: payload.meeting_my_tasks?.map((meeting_my_task) => {
									return {
										budgeted_hours: Number(meeting_my_task.budgeted_hours),
										consumed_time: parseFloat(
											meeting_my_task.consumed_time / 3600000,
										).toFixed(2),
									};
							}),
					clients: payload.teams
						?.map((team) =>
							team.clients?.map((client) => {
								return {
									...client,
									team_id: team.id,
									workflow_my_tasks:
										client?.workflow_my_tasks?.length < 1
											? client?.workflow_my_tasks
											: client?.workflow_my_tasks?.map((workflow_my_task) => {
												return {
													budgeted_hours: parseFloat(
														workflow_my_task?.budgeted_hours,
													),
													consumed_time: parseFloat(
															workflow_my_task?.consumed_time / 3600000,
														).toFixed(2),
													};
											}),
									client_workflows: client?.client_workflows?.map(
										(client_workflow) => {
											return {
												client_workflow_id: client_workflow?.client_workflow_id,
												display_name: client_workflow?.display_name,
												budgeted_hours:
													client_workflow?.client_workflow_tasks?.reduce(
														(total, client_workflow_task) =>
															total +
															client_workflow_task.tasks.reduce(
																(subTotal, task) =>
																	subTotal + Number(task.budgeted_hours),
																0,
															),
														0,
													),
												consumed_time: parseFloat(
													client_workflow?.client_workflow_tasks?.reduce(
														(total, client_workflow_task) =>
															total +
															client_workflow_task.tasks.reduce(
																(subTotal, task) =>
																	parseFloat(subTotal) +
																	parseFloat(task.consumed_time / 3600000),
																0,
															),
														0,
													),
												).toFixed(2),
											};
										},
									),
								};
							}),
						)
						?.flat()
						?.sort?.((a, b) =>
							(a.dba || a.company_name).localeCompare(b.dba || b.company_name),
						),
				},
				loading: false,
			});
		case Actions.GET_USER_DETAILS_FAILED:
			return (state = {
				...state,
				loading: false,
			});
		case Actions.UPDATE_USER_DETAILS:
			return (state = {
				...state,
				userDetails: {
					...state.userDetails,
					clients: state.userDetails?.clients?.map((client) => {
						if (client.client_id === payload.clientId) {
							if (payload.name === 'workflow_my_tasks') {
								return {
									...client,
									workflow_my_tasks: client?.workflow_my_tasks?.map(
										(workflow_my_task) => {
											return {
												...workflow_my_task,
												consumed_time: payload.value,
											};
										},
									),
								};
							}
							if (payload.name === 'client_workflows') {
								return {
									...client,
									client_workflows: client?.client_workflows?.map(
										(workflow) => {
											if (
												workflow.client_workflow_id === payload.clientWorkflowId
											) {
												return {
													...workflow,
													consumed_time: payload.value,
												};
											}
											return workflow;
										},
									),
								};
							}
						}
						return client;
					}),
				},
			});
		case Actions.UPDATE_MEETING_TASK_LOGGED_HOURS:
			return (state = {
				...state,
				userDetails: {
					...state.userDetails,
					meeting_tasks: state.userDetails.meeting_tasks?.map(
						(meeting_task) => {
							return {
								...meeting_task,
								consumed_time: payload,
							};
						},
					),
				},
			});
		case Actions.GET_CLIENT_DETAILS_START:
			return (state = {
				...state,
				loading: payload,
			});
		case Actions.GET_CLIENT_DETAILS_FAILED:
			return (state = {
				...state,
				loading: false,
			});
		case Actions.GET_CLIENT_DETAILS_END:
			return (state = {
				...state,
				loading: false,
				clientDeatils: {
					client_id: payload?.data?.id,
					dba: payload?.data?.dba,
					company_name: payload?.data?.company_name,
					users: payload?.data?.teams
						?.map((team) => team.users)
						?.flat()
						?.map((user) => {
							return {
								...user,
								user_roles: payload?.user_roles
									?.filter((item) => {
									if (item.user_id == user.user_id) {
										return item;
									}
								})
								?.reduce(
									(acc, role) => {
										acc.user_id = role.user_id;
										acc.scopes.push(
											...(role.role_scopes?.map((scope) => ({
												role_id: role?.role_id,
												team_id: scope?.team_id,
											})) || []),
										);
										return acc;
									},
									{ user_id: null, scopes: [] },
								),
								role_id: payload?.user_roles.find(
									(role) => role.user_id === user.user_id,
								)?.role_id,
								workflow_my_tasks:
									user?.workflow_my_tasks?.length < 1
										? user?.workflow_my_tasks
										: user?.workflow_my_tasks?.map((workflow_my_task) => {
												return {
													budgeted_hours: parseFloat(
														workflow_my_task?.budgeted_hours,
													),
													consumed_time: parseFloat(
														workflow_my_task?.consumed_time / 3600000,
													).toFixed(2),
												};
										}),
								workflows: user.workflows?.map((workflow) => {
									return {
										...workflow,
										budgeted_hours: parseFloat(workflow?.budgeted_hours),
										consumed_time: parseFloat(
											workflow?.consumed_time / 3600000,
										).toFixed(2),
									};
								}),
							};
						})
						?.sort((a, b) => a.full_name.localeCompare(b.full_name)),
				},
			});
		case Actions.UPDATE_CLIENT_DETAILS:
			return (state = {
				...state,
				clientDeatils: {
					...state.clientDeatils,
					users: state.clientDeatils?.users?.map((user) => {
						if (user.user_id === payload.userId) {
							if (payload.name === 'workflow_my_tasks') {
								return {
									...user,
									workflow_my_tasks: user.workflow_my_tasks.map(
										(workflow_my_task) => {
											return {
												...workflow_my_task,
												consumed_time: payload.value,
											};
										},
									),
								};
							}
							if (payload.name === 'workflows') {
								return {
									...user,
									workflows: user?.workflows?.map((workflow) => {
										if (workflow.workflow_id === payload.clientWorkflowId) {
											return {
												...workflow,
												consumed_time: payload.value,
											};
										}
										return workflow;
									}),
								};
							}
						}
						return user;
					}),
				},
			});

		case Actions.GET_USER_ROLES_END:
			return (state = {
				...state,
				userRole: payload,
				loading: false,
			});
		case Actions.SET_USER_BUDGETED_HOURS:
			return (state = {
				...state,
				user_budgeted_hours: payload,
			});

		case Actions.SET_DATES:
			return (state = {
				...state,
				start_date: payload.start_date,
				end_date: payload.end_date,
			});
		case Actions.SET_MONTHLY_VIEW:
			return (state ={
				...state,
				monthlyView: payload
			});
		case Actions.SET_TASKS_LOADING:
			return (state ={
				...state,
				tasksLoading: payload
			});
		case Actions.GET_TASKS_END:
			return (state ={
				...state,
				tasks: payload.map(task => {
					return {
						...task,
						budgeted_hours: parseFloat(task.budgeted_hours),
						consumed_time: parseFloat(task.consumed_time / 3600000).toFixed(2),
					};
				}),
			});
		case Actions.UPDATE_LOGGED_TIME:
			return (state ={
				...state,
				tasks: state.tasks.map(task => {
					if(task.id == payload.task_id) {
						return {
							...task,
							consumed_time:payload.value
						};
					}
					else return task;
				})
			});
		case Actions.SET_INITIAL_LOADING:
			return (state = {
				...state,
				initialLoading:payload
			});
		// case Actions.SET_LOOGED_TIME:
		// 	return (state) ={
		// 		...state,
		// 		loggedTime:payload
		// 	};		
		default:
			return state;
	}
};
export default TimeReports;
