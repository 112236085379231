import React from 'react';

function ToggleButton({ checked, onChange }) {
	return (
		<div className={`toggle-button ${checked ? 'active' : ''}`} onClick={onChange}>
			<div className='thumb'></div>
		</div>
	);
}

export default ToggleButton;
