/* eslint-disable no-mixed-spaces-and-tabs */
import { useEffect, useRef, useState } from 'react';
import { ReactComponent as MoreIcon } from '../../assets/icons/More.svg';
import { ReactComponent as WorkflowIcon } from '../../assets/icons/Package.svg';
import { ReactComponent as VerifiedIcon } from '../../assets/icons/CircleWavyCheck.svg';
import { Modal } from '@mui/material';
import NewWorkflowDetailModal from '../Modal/NewWorkflowDetailModal';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from '../../redux/actions/workflow-builder-actions';
import { useHistory, useLocation } from 'react-router';
import { executivePermissions } from '../../utilities/utilities';

export default function NewWorkflowCard({
	workflow,
	profileData,
	companyLibrary,
	isChecked,
	categoryId,
	categoryName,
	handleCheckBoxes,
	moveWorkflowsToDraft,
	publishWorkflows,
	archivePublishedWorkflows,
	handleSelectClient,
	handleAddToClient,
	unArchiveSingleWorkflow,
	archiveView,
}) {
	const dispatch = useDispatch();
	const dropdownRef = useRef();
	const history = useHistory();
	const location = useLocation();

	const [isBoxOpen, setIsBoxOpen] = useState(false);
	const [workflowModal, setWorkflowModal] = useState(false);
	const user = useSelector((state) => state.authReducer.user);
	const clientForbuilder = location?.state;
	const workflowChecked = isChecked?.map((workflow) => workflow?.id)?.includes(workflow.id);

	const handleWorkflowModal = () => {
		setWorkflowModal(!workflowModal);
	};

	const handleDone = () => {
		if (workflow?.status === 'DRAFT') {
			setWorkflowModal(!workflowModal);
			publishWorkflows(workflow?.id);
			return;
		}
		if (companyLibrary) {
			clientForbuilder
				? handleAddToClient(clientForbuilder?.clientId, workflow)
				: handleSelectClient(workflow);
			return;
		}
		if (archiveView) {
			unArchiveSingleWorkflow(workflow?.id);
			setWorkflowModal(!workflowModal);
			return;
		}
		setWorkflowModal(!workflowModal);
		moveWorkflowsToDraft({ id: workflow?.id, name: workflow.name });
	};

	const handleMoreIcon = (e) => {
		e.stopPropagation();
		setIsBoxOpen(!isBoxOpen);
	};

	const openWorkflowEditor = () => {
		dispatch(
			Actions.setWorkflowForm({
				name: workflow.name,
				category_id: categoryId,
				description: workflow.description,
				color: workflow.color,
			}),
		);
		history.push(`/workflow-editor/${workflow.id}`, clientForbuilder);
	};

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				setIsBoxOpen(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	return (
		<>
			<div
				className={`new-workflow-card pointer ${
					isChecked?.length == 10 && !workflowChecked ? 'new-workflow-card-disabled' : ''
				}`}
				style={{ margin: '0px 0px 18px 18px' }}
				onClick={handleWorkflowModal}
			>
				<div className='d-flex justify-content-between align-items-start w-100'>
					<div>
						{workflow?.status && (
							<div
								className='d-flex align-items-center'
								style={{
									height: '24px',
									padding: '4px 8px',
									width: 'fit-content',
									borderRadius: '4px',
									backgroundColor:
										workflow?.status === 'PUBLISHED' ? '#18C58D' : '#Fb7a03',
								}}
							>
								<span className='BodyThreeBold' style={{ color: 'white' }}>
									{workflow?.status === 'PUBLISHED' ? 'Published' : 'Draft'}
								</span>
							</div>
						)}
						<div className='d-flex align-items-center' style={{ padding: '8px 0px' }}>
							{workflow?.status !== 'DRAFT' && (
								<input
									data-cy={`workflow-browser-${workflow.id}`}
									type='checkbox'
									className='multi-select-checkbox'
									onChange={(e) => {
										handleCheckBoxes(workflow);
									}}
									onClick={(event) => event.stopPropagation()}
									checked={workflowChecked}
								/>
							)}
							<div style={{ padding: '0px 4px' }}>
								<WorkflowIcon />
							</div>
							<div className='d-flex flex-column align-items-start'>
								<span className='BodyOneBold'>
									{workflow?.name
										? workflow?.name?.length > 28
											? workflow.name.substring(0, 28) + '...'
											: workflow?.name
										: 'No Task Title'}
								</span>
								{categoryName && (
									<span className='BodyThreeLight'>{categoryName}</span>
								)}
							</div>
						</div>
					</div>
					<div
						className='pointer'
						onClick={handleMoreIcon}
						style={{ position: 'relative' }}
					>
						<MoreIcon />
						{isBoxOpen && (
							<div className='dropdown-box' ref={dropdownRef}>
								{workflow?.status === 'DRAFT' && (
									<>
										<span
											className='BodyTwoLight w-100 text-left'
											style={{ padding: '6px 0px', cursor: 'pointer' }}
											onClick={() => {
												publishWorkflows(workflow?.id);
											}}
										>
											Publish
										</span>
										<span
											className='BodyTwoLight w-100 text-left'
											style={{ padding: '6px 0px', cursor: 'pointer' }}
											onClick={openWorkflowEditor}
										>
											Edit in Builder
										</span>
										<span
											className='BodyTwoLight w-100 text-left'
											style={{ padding: '6px 0px', cursor: 'pointer' }}
											onClick={() => archivePublishedWorkflows(workflow?.id)}
										>
											Delete
										</span>
									</>
								)}
								{workflow?.status === 'PUBLISHED' && !workflow?.deleted && (
									<>
										{executivePermissions?.includes(user?.userRole?.id) && (
											<span
												className='BodyTwoLight w-100 text-left'
												style={{ padding: '6px 0px', cursor: 'pointer' }}
												onClick={openWorkflowEditor}
											>
												Edit in Builder
											</span>
										)}
										<span
											className='BodyTwoLight w-100 text-left'
											style={{ padding: '6px 0px', cursor: 'pointer' }}
											onClick={handleDone}
										>
											Add To Client
										</span>
										<span
											className='BodyTwoLight w-100 text-left'
											style={{ padding: '6px 0px', cursor: 'pointer' }}
											onClick={() => archivePublishedWorkflows(workflow?.id)}
										>
											Archive Workflow
										</span>
									</>
								)}
								{workflow?.status !== 'DRAFT' &&
									workflow?.status !== 'PUBLISHED' && (
										<span
											className='BodyTwoLight w-100 text-left'
											style={{
												padding: '6px 0px',
												textAlign: 'start',
												cursor: 'pointer',
											}}
											onClick={() =>
												moveWorkflowsToDraft({
													id: workflow?.id,
													name: workflow.name,
												})
											}
										>
											Save as Draft
										</span>
									)}
								{workflow?.deleted && (
									<>
										<span
											className='BodyTwoLight w-100 text-left'
											style={{ padding: '6px 0px', cursor: 'pointer' }}
											onClick={() => unArchiveSingleWorkflow(workflow?.id)}
										>
											Unarchive
										</span>
									</>
								)}
							</div>
						)}
					</div>
				</div>
				<div className='description-container text-left' style={{ position: 'relative' }}>
					<span className='BodyTwoLight' style={{ color: '#696F6C' }}>
						{workflow?.description
							? workflow?.description?.length > 120
								? workflow?.description?.substring(0, 120) + '...'
								: workflow?.description
							: 'No description available'}
					</span>
					<div
						className='d-flex align-items-center'
						style={{ paddingTop: '0px', position: 'absolute', bottom: '5px' }}
					>
						<span className='BodyTwoBold'>
							Created by:
							<span className='BodyTwoLight pl-1 pr-1'>
								{companyLibrary || archiveView ? profileData?.name : 'Levvy'}
							</span>
						</span>
						{!companyLibrary && !archiveView && <VerifiedIcon />}
					</div>
				</div>
			</div>
			{workflowModal && (
				<Modal
					onClose={handleWorkflowModal}
					open={workflowModal}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<>
						<NewWorkflowDetailModal
							workflow={workflow}
							companyLibrary={companyLibrary}
							editWorkflow={openWorkflowEditor}
							onClose={handleWorkflowModal}
							onDone={handleDone}
							archiveView={archiveView}
						/>
					</>
				</Modal>
			)}
		</>
	);
}
