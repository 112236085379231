import { CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-multi-date-picker';
import InputIcon from 'react-multi-date-picker/components/input_icon';
import { useDispatch, useSelector } from 'react-redux';
// import 'react-datepicker/dist/react-datepicker.css';
import SubmitButtonsField from '../Buttons/SubmitButtonsField';
import DropDown from '../Inputs/DropDown';
import ToggleSwitch from '../sub_components/ToggleSwitch';
import * as Actions from '../../redux/actions/action-center';
import * as userActions from '../../redux/actions/user-management.actions';
import moment from 'moment';


export default function LeavesModal({
  onCancel, setLeavesModal, setLeaveFormSubmitted
}) {
  
  const dispatch = useDispatch();
  const [halfDay, setHalfDay] = useState(false);

  const leaveForm = useSelector(state => state.actionCenterReducer.leaveForm); 
  const loading = useSelector(state => state.actionCenterReducer.loading);
  let leaveTypes = useSelector(state => state.actionCenterReducer.leaveTypes);
  const user = useSelector((state) => state.authReducer.user);
	const profile = useSelector((state) => state.userReducer.userProfile);
  
  const dates = [];

  if(profile?.leave_requests?.length > 0){ 
    profile?.leave_requests?.filter(req => req.user_id === user?.userId && req.status !== 'DECLINED')
    ?.map(a => a.leaves.map(leave =>  dates.push(moment(leave.leave_date).date())));
  }

  leaveTypes = leaveTypes.map(leave => {
    return {
      label: leave.name,
      value: leave.id
    };
  });
  
  
	const Style = {
		backgroundColor: 'white',
		border: 'none',
		borderBottom: '1px solid #28383C',
	};

  

  const selectedDates = leaveForm?.dates?.map(v => {
    return {'leave_date' :  v.toLocaleString()}; 
  });
    

  const handleChange = (e) => {
    let {name, value} = e.target;
    if(name === 'leave_type'){
      value = parseInt(value);
      dispatch(Actions.handleLeaveChange({name, value}));
    }
    dispatch(Actions.handleLeaveChange({name, value}));
  };

  const handleDatesChange = (dates) => {
    dispatch(Actions.handleDatesChange(dates));
  };

  const onSubmit = () => {
    dispatch(Actions.submitLeaveForm(setLeavesModal, setLeaveFormSubmitted,{
      user_id: user?.userId,
      tenant_id: user?.tenantId,
      leave_type: leaveForm?.leave_type,
      leaves: selectedDates,
      is_half_day: halfDay,
      reason: leaveForm?.reason
    }));
  };

  useEffect(() => {
    dispatch(Actions.getLeaveTypes());
    dispatch(userActions.getUserProfile(user?.userId));
  }, [dispatch]);
  

  
  return (
    <div style={{height:'fit-content'}}>
      <div className='p-3'>

        <div className='improved-input-designs pb-3'>
					<DropDown
						onChange={handleChange}
						label='Leave Type'
						name='leave_type'
						className='require'
						required={true}
            value={{value: leaveForm.leave_type}}
						options={leaveTypes}
            />
				</div>
        <div className='improved-input-designs pb-3'>
					<label >Reason (Optional)</label>
					<input
						className='w-100'
						type='text'
						name='reason'
						onChange={handleChange}
            value={leaveForm.reason}
            style={Style}
					/>
				</div>
        <div className='improved-input-designs pb-3'>
        <label className='require'>Period</label>
        <DatePicker 
          containerStyle={{
            width: '100%'
          }}
          minDate={moment().toDate()}
          multiple
          mapDays={({ date }) => {
            const isDisabled = dates.includes(date.day);
            if (isDisabled) return {
              disabled: true,
              style: { color: '#ccc' },
              onClick: () => alert('You have already applied for leave on this date.')
            };
          }}
          name='dates'
          value={leaveForm.dates} 
          onChange={handleDatesChange}
          style={{
            backgroundColor: 'white',
            border: 'none',
            borderBottom: '1px solid #28383C',
            borderRadius:'0px',
            width:'100%',
            height:'max-content',
            outline:'none',
            boxShadow:'none'
          }}
          />
        </div>
          <div className='w-100 d-flex align-items-center improved-input-designs pb-3'>
            <ToggleSwitch
              textClass='dark-text'
              label='Half-day Leave'
              labelPlacement='start'
              checked={halfDay}
              style={{ color: '#FB7A03' }}
              size='small'
              className='text-left w-100'
              onChange={() => setHalfDay(!halfDay)}
              />
          </div>
              </div>
        <div className='VerticalSpacer bg-white p-3 rounded-bottom'>
          <SubmitButtonsField child={true}>
            <button
              id='Element'
              className='secondary'
              type='button'
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              type='submit'
              className='d-flex align-items-center'
              id='Element'
              onClick={onSubmit}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress
                  size={24}
                  sx={{ color: 'white' }}
                  disableShrink
                />
              ) : (
                'Save'
              )}
            </button>
          </SubmitButtonsField>
        </div>
    </div>
  
  );
}









