import{ useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ActionButton from '../components/Buttons/ActionButton';
import SearchBar from '../components/Inputs/SearchBar';
import Card from '../components/Others/Card';
import LoadingOverLay from '../components/Others/LoadingOverLay';
import TableHeader from '../components/Others/TableHeader';
import TableHeaderItem from '../components/Others/TableHeaderItem';
import TableRow from '../components/Others/TableRow';
import TableRowButton from '../components/Others/TableRowButton';
import TableRowItem from '../components/Others/TableRowItem';
import Sidebar from '../components/sidebar/SideBar';
import {
	TableItemWidth2,
	TableItemWidth3,
} from '../components/TableSpacing/TableSpacingStyles';
import * as Actions from '../redux/actions/action-center';
import { Modal } from '@mui/material';
import DummyModal from '../components/Modal/DummyModal';
import ConfirmModal from '../components/Modal/ConfirmModal';
import moment from 'moment';
import { getUserSettings } from '../hooks/getUserSettings';
import AlertBar from '../components/sub_components/AlertBar';

export default function LeavesScreen() {
	const dispatch = useDispatch();

	const [approvalConfirmationModal, setApprovalConfirmationModal] =
		useState(false);
	const [rejectConfirmationModal, setRejectConfirmationModal] = useState(false);
	const [leave, setLeave] = useState(null);

	const leaves = useSelector((state) => state.actionCenterReducer.leaves);
	const leaveTypes = useSelector(
		(state) => state.actionCenterReducer.leaveTypes,
	);
	const loading = useSelector((state) => state.actionCenterReducer.loading);
	const user = useSelector((state) => state.authReducer.user);
    const userDetails = useSelector((state) => state.authReducer.userDetails);
    const { date_format } = getUserSettings(userDetails);

	// const approvedById = user?.userRole?.id === 16 && user.userId;

	const approvedById = user?.userId;


	useEffect(() => {
		dispatch(Actions.getLeaves());
		dispatch(Actions.getLeaveTypes());
	}, [dispatch]);

	const handleRejectRequest = (leave) => {
		setLeave(leave);
		setRejectConfirmationModal(true);
	};
	const handleApproveRequest = (leave) => {
		setLeave(leave);
		setApprovalConfirmationModal(true);
	};

	// const handleContactSlack = (id) => {
	// 	console.log(id);
	// 	<a href={`https://slack.com/app_redirect?channel=${1}`}/>;
	// };

	return (
		<div className='AppBody'>
			<Sidebar title='Leaves'>
				<div className='SidebarContentWrapper'>
					<div className='User-filters'>
						<SearchBar
							style={{
								color: 'white',
								backgroundColor: 'rgba(0, 0, 0, 0.25)',
							}}
						/>{' '}
						{/* {isCheck.length > 0 && (
							<DeleteSelected>
								<h5 onClick={multipleDelete} className='BodyThreeB'>
									Delete Selected Teams
								</h5>
							</DeleteSelected>
						)} */}
					</div>
				</div>
			</Sidebar>
            <div 
            style={{
                width: 'calc(100vw - 260px)',
                height: '100vh',
            }}>
                <AlertBar/>
			<div className='Listing-Main '>
				<div className='Listing-Content'>
					<Card title='Leave Requests'>
						<div className='card-body'>
						<TableHeader
							ClassName='TableHeader flex fixedHeader'
							border={false}>
							<TableHeaderItem
								ClassName='UManagement'
								label='Name'
								width={TableItemWidth3}
								margin='ml-4'></TableHeaderItem>

							<TableHeaderItem
								ClassName='UManagement mr-20'
								label='Team'
								width={TableItemWidth3}
							/>
							<TableHeaderItem
								ClassName='UManagement mr-20'
								label='Leave Type'
								width={TableItemWidth3}
							/>
							<TableHeaderItem
								ClassName='UManagement mr-20'
								label='Reason'
								width={TableItemWidth3}
							/>
							<TableHeaderItem
								ClassName='UManagement mr-20'
								label='Half Day'
								width={TableItemWidth3}
							/>
							<TableHeaderItem
								ClassName='UManagement mr-20'
								label='Dates'
								width={TableItemWidth3}
							/>
							<TableHeaderItem
								ClassName='UManagement mr-20'
								label='Status'
								width={TableItemWidth3}
							/>

							<TableHeaderItem
								ClassName='UManagement text-center'
								label='Action'
								width={TableItemWidth2}
							/>
						</TableHeader>

						<div className='ListingLoading listing-scroll'>
							{loading ? (
								<LoadingOverLay active={loading} />
							) : leaves.length < 1 ? (
								<div className='TableRow h-100 w-100 d-flex align-items-center justify-content-center'>
									<h4 className='BodyTwoLight'>No Leaves</h4>
								</div>
							) : (
								leaves?.filter(leave => leave.user_id !== user.userId)?.map((leave, i) => {
									return (
										<TableRow key={i}>
											<TableRowButton
												ClassName='UManagement'
												label={leave?.user_profile?.full_name}
												width={TableItemWidth3}
												margin='ml-4'></TableRowButton>

											<TableRowItem
												label={leave?.user_profile?.teams[0]?.name}
												width={TableItemWidth3}
												ClassName='UManagement text-left mr-20'
											/>
											<TableRowItem
												label={
													leaveTypes.find(
														(type) => type.id === leave.leave_type,
													)?.name
												}
												width={TableItemWidth3}
												ClassName='UManagement text-left mr-20'
											/>
											<TableRowItem
												label={leave.reason}
												width={TableItemWidth3}
												ClassName='UManagement text-left mr-20'
											/>
											<TableRowItem
												label={leave.is_half_day ? 'yes' : 'no'}
												width={TableItemWidth3}
												ClassName='UManagement text-left mr-20'
											/>
											<TableRowItem
												ClassName=' text-left'
												label={leave.leaves.map((date, index) => {
													// var tempDate = new Date(date.leave_date);
													// var currDate = tempDate.getDate();
													// var day = tempDate.getDay();
													// var month = tempDate.getMonth();
													// var year = tempDate.getFullYear();
													// if (index + 1 === arr.length) {
													// 	return currDate + '/' + month + '/' + year;
													// }
													// return currDate + '/' + month + '/' + year + ', ';
													return <div key={index}>{moment(date?.leave_date).format(date_format)}</div>;
												})}
												width={TableItemWidth3}
												input={false}></TableRowItem>

											<TableRowItem
												label={leave.status}
												width={TableItemWidth3}
												ClassName='UManagement text-left mr-20'
												textStyle={{
													color: leave.status === 'PENDING' && '#fb7a03',
												}}
											/>

											<TableRowButton
												ClassName='UManagement'
												text='text-left'
												width={TableItemWidth2}
												margin='m-0'>
												{leave.status === 'PENDING' && (
													<ActionButton
														leaveFlow
														key={i}
														ApproveRequest={() => handleApproveRequest(leave)}
														RejectRequest={() => handleRejectRequest(leave)}
														slackId={leave.user_profile.slack}
													/>
												)}
											</TableRowButton>
										</TableRow>
									);
								})
							)}
						</div>
						</div>
					</Card>
				</div>
			</div>
            </div>

			{approvalConfirmationModal && leave && (
				<Modal
					onClose={() => setApprovalConfirmationModal(false)}
					open={approvalConfirmationModal}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}>
					<DummyModal
						title={'Leave Request Confirmation'}
						onClose={() => setApprovalConfirmationModal(false)}>
						<ConfirmModal
							description='Do you really want to approve this leave request?'
							Onsubmit={() => {
								setApprovalConfirmationModal(false),
									dispatch(
										Actions.updateStatus(leave.id, {
											id: leave.id,
											status: 'APPROVED',
											approved_by: approvedById,
											user_id: leave.user_id,
											tenant_id: leave.tenant_id,
											leave_type: leave.leave_type,
											is_half_day: leave.is_half_day,
										}),
									);
							}}
							OnCancel={() => setApprovalConfirmationModal(false)}
						/>
					</DummyModal>
				</Modal>
			)}
			{rejectConfirmationModal && leave && (
				<Modal
					onClose={() => setRejectConfirmationModal(false)}
					open={rejectConfirmationModal}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}>
					<DummyModal
						title={'Leave Request Confirmation'}
						onClose={() => setRejectConfirmationModal(false)}>
						<ConfirmModal
							description='Do you really want to decline this leave request?'
							Onsubmit={() => {
								setRejectConfirmationModal(false),
									dispatch(
										Actions.updateStatus(leave.id, {
											id: leave.id,
											status: 'DECLINED',
											approved_by: approvedById,
											user_id: leave.user_id,
											tenant_id: leave.tenant_id,
											leave_type: leave.leave_type,
											is_half_day: leave.is_half_day,
										}),
									);
							}}
							OnCancel={() => setRejectConfirmationModal(false)}
						/>
					</DummyModal>
				</Modal>
			)}
		</div>
	);
}
