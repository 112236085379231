import { secure_instance } from '../../axios/axios-config';
export const HANDLE_LEAVE_CHANGE = '[ACTIONCENTER] HANDLE_LEAVE_CHANGE';
export const HANDLE_DATES_CHANGE = '[ACTIONCENTER] HANDLE_DATES_CHANGE';
export const SUBMIT_LEAVES_FORM = '[ACTIONCENTER] SUBMIT_LEAVES_FORM';
export const GET_LEAVE_TYPES = '[ACTIONCENTER] GET_LEAVE_TYPES';
export const SUBMIT_LEAVES_START = '[ACTIONCENTER] SUBMIT_LEAVES_START';
export const GET_LEAVES = '[ACTIONCENTER] GET_LEAVES';
export const UPDATE_LEAVE_STATUS = '[ACTIONCENTER] UPDATE_LEAVE_STATUS';
export const GET_USER_LEAVES = '[ACTIONCENTER] GET_USER_LEAVES';
export const RESET_LEAVES_MODAL = '[ACTIONCENTER] RESET_LEAVES_MODAL';
export const GET_ONBOARDING = '[ACTIONCENTER] GET_ONBOARDING';
export const SET_YOURSELF_AWAY = '[ACTIONCENTER] SET_YOURSELF_AWAY';
export const SET_YOURSELF_AWAY_START = '[ACTIONCENTER] SET_YOURSELF_AWAY_START';
export const GET_USER_STATUS = '[ACTIONCENTER] GET_USER_STATUS';
export const DELETE_USER_STATUS = '[ACTIONCENTER] DELETE_USER_STATUS';
export const SET_STATUS = '[ACTIONCENTER] SET_STATUS';
export const SET_SELECTED_TIME = '[ACTIONCENTER] SET_SELECTED_TIME';
export const SET_PAUSE_NOTIFICATIONS = '[ACTIONCENTER] SET_PAUSE_NOTIFICATIONS';
export const UPDATE_YOURSELF_AWAY_START = '[ACTIONCENTER] UPDATE_YOURSELF_AWAY_START';
export const UPDATE_YOURSELF_AWAY = '[ACTIONCENTER] UPDATE_YOURSELF_AWAY';
export const UPDATE_YOURSELF_AWAY_FAILED = '[ACTIONCENTER] UPDATE_YOURSELF_AWAY_FAILED';
export const SET_YOURSELF_AWAY_FAILED = '[ACTIONCENTER] SET_YOURSELF_AWAY_FAILED';

export const SUBMIT_LEAVES_FAILED = '[ACTIONCENTER] SUBMIT_LEAVES_FAILED';
export const GET_APPROVAL_PENDING = '[ACTIONCENTER] GET_APPROVAL_PENDING';
export const GET_NOTIFICATIONS = '[ACTIONCENTER] GET_NOTIFICATIONS';
export const MARK_ALL_READ = '[ACTIONCENTER] MARK_ALL_READ';
export const MARK_NOTIFICATIONS = '[ACTIONCENTER] MARK_NOTIFICATIONS';
export const UPDATE_NOTIFICATIONS = '[ACTIONCENTER] UPDATE_NOTIFICATIONS';
export const SET_EMOJI = '[ACTIONCENTER] SET_EMOJI';
export const GET_USER_STATUS_START = '[ACTIONCENTER] GET_USER_STATUS_START';

export const handleLeaveChange = (data) => {
	return {
		type: HANDLE_LEAVE_CHANGE,
		payload: data,
	};
};

export const handleDatesChange = (data) => {
	return {
		type: HANDLE_DATES_CHANGE,
		payload: data,
	};
};

export const resetLeavesModal = () => {
	return {
		type: RESET_LEAVES_MODAL,
	};
};

export const submitLeaveForm = (setLeavesModal, setLeaveFormSubmitted, data) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: SUBMIT_LEAVES_START,
			});
			const request = await secure_instance.request({
				url: `/v1/leave_requests/`,
				method: 'Post',
				data: data,
			});
			dispatch({
				type: SUBMIT_LEAVES_FORM,
				payload: request.data,
			});

			setLeaveFormSubmitted(true);
			setLeavesModal(false);
		} catch (e) {
			//
			dispatch({
				type: SUBMIT_LEAVES_FAILED,
			});
			alert(e?.response?.data?.message);
		}
	};
};

export const getLeaveTypes = () => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: '/v1/leave_types/',
				method: 'Get',
			});
			dispatch({
				type: GET_LEAVE_TYPES,
				payload: request.data,
			});
		} catch (e) {
			//
		}
	};
};

export const getLeaves = () => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/leave_requests/?expand=[leaves,user_profile.teams]`,
				method: 'Get',
			});

			dispatch({
				type: GET_LEAVES,
				payload: request.data,
			});
		} catch (e) {
			//
		}
	};
};

export const getOnboarding = () => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/tenant_profiles/onboarding`,
				method: 'Get',
			});
			console.log(request);
			dispatch({
				type: GET_ONBOARDING,
				payload: request.data,
			});
		} catch (e) {
			//
		}
	};
};

export const getUserLeaves = (userId) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/leave_requests/user_leave/${userId}?expand=leaves`,
				method: 'Get',
			});
			dispatch({
				type: GET_USER_LEAVES,
				payload: request.data,
			});
		} catch (e) {
			//
		}
	};
};

export const setStatus = (data) => {
	return {
		type: SET_STATUS,
		payload: data,
	};
};

export const setEmoji = (data) => {
	console.log(data, 'Data12');
	return {
		type: SET_EMOJI,
		payload: data,
	};
};

export const setSelectedTime = (data) => {
	return {
		type: SET_SELECTED_TIME,
		payload: data,
	};
};
export const setIsPauseNotifications = (data) => {
	return {
		type: SET_PAUSE_NOTIFICATIONS,
		payload: data,
	};
};

export const updateStatus = (leaveId, data) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/leave_requests/${leaveId}`,
				method: 'Patch',
				data: data,
			});
			dispatch({
				type: UPDATE_LEAVE_STATUS,
				payload: request.data,
			});
		} catch (e) {
			//
		}
	};
};

export const getUserStatus = () => {
	return async (dispatch) => {
		try {
			dispatch({
				type: GET_USER_STATUS_START,
			});
			const request = await secure_instance.request({
				url: `/v1/user_status/my_status`,
				method: 'Get',
			});
			dispatch({
				type: GET_USER_STATUS,
				payload: request.data,
			});
		} catch (e) {
			//
		}
	};
};

export const getApprovalPending = () => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/tasks/approval_pending`,
				method: 'Get',
			});
			dispatch({
				type: GET_APPROVAL_PENDING,
				payload: request.data,
			});
		} catch (e) {
			//
		}
	};
};

export const deleteStatus = (id) => {
	return async (dispatch) => {
		try {
			await secure_instance.request({
				url: `/v1/user_status/${id}`,
				method: 'Delete',
			});
			// dispatch({
			// 	type: DELETE_USER_STATUS,
			// });
		} catch (e) {
			//
		}
	};
};

export const setYourselfAway = (data, setStatusModal) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: SET_YOURSELF_AWAY_START,
			});

			const request = await secure_instance.request({
				url: `/v1/user_status/`,
				method: 'Post',
				data: data,
			});

			dispatch({
				type: SET_YOURSELF_AWAY,
				payload: request.data,
			});

			setStatusModal && setStatusModal(false);
			dispatch(getUserStatus());
		} catch (e) {
			dispatch({
				type: SET_YOURSELF_AWAY_FAILED,
			});
		}
	};
};
export const updateUserStatus = (data, setStatusModal) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: UPDATE_YOURSELF_AWAY_START,
			});

			const request = await secure_instance.request({
				url: `/v1/user_status/${data.id}`,
				method: 'Patch',
				data: data,
			});

			dispatch({
				type: UPDATE_YOURSELF_AWAY,
				payload: request.data,
			});

			setStatusModal && setStatusModal(false);
			dispatch(getUserStatus());
		} catch (e) {
			dispatch({
				type: UPDATE_YOURSELF_AWAY_FAILED,
			});
		}
	};
};

export const getNotifications = () => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/notifications/`,
				method: 'Get',
			});
			dispatch({
				type: GET_NOTIFICATIONS,
				payload: request.data,
			});
		} catch (e) {
			//
		}
	};
};

export const markAllRead = () => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/notifications/mark_all`,
				method: 'Patch',
			});
			console.log(request, 'mark All notifications');
			dispatch({
				type: MARK_ALL_READ,
				payload: request.data,
			});
		} catch (e) {
			//
		}
	};
};

export const markNotification = (ids, status) => {
	return async () => {
		try {
			const request = await secure_instance.request({
				url: `/v1/notifications/`,
				method: 'Patch',
				data: { ids, status: status },
			});
			return request.data;
		} catch (error) {
			throw new Error(
				error?.response?.data?.message || error.message || 'Something went wrong',
			);
		}
	};
};

export const deleteNotification = (notification_id) => {
	return async () => {
		try {
			const request = await secure_instance.request({
				url: `/v1/notifications/${notification_id}`,
				method: 'DELETE',
			});
			return request.data;
		} catch (error) {
			throw new Error(
				error?.response?.data?.message || error.message || 'Something went wrong',
			);
		}
	};
};

export const updateNotifications = (notifications) => {
	return {
		type: UPDATE_NOTIFICATIONS,
		payload: notifications,
	};
};

export const getInvitedTasks = (my_task_id, start_date) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/my_tasks/upcoming/${my_task_id}?start_date=${start_date}`,
				method: 'GET',
			});
			return request.data;
		} catch (error) {
			throw new Error(
				error?.response?.data?.message || error?.message || 'Something went wrong!',
			);
		}
	};
};
