
function GoverningLawAndVenue() {
	return (
		<p className='BodyTwoLight'>
			Any dispute arising from these Terms and your use of the Site will be
			governed by and construed and enforced in accordance with the laws of the
			State of New York, except to the extent preempted by U.S. federal law,
			without regard to conflict of law rules or principles (whether of the
			State of New York or any other jurisdiction) that would cause the
			application of the laws of any other jurisdiction. Any dispute between the
			parties that is not subject to arbitration or cannot be heard in small
			claims court will be resolved in the state or federal courts of the State
			of New York and the United States, respectively, sitting in New York, New
			York.
		</p>
	);
}

export default GoverningLawAndVenue;
