import { memo } from 'react';

function NewSidebarWorkflowItem({
	isActive,
	uniqueId,
	label,
	setActiveTab,
	isDraft,
	selectableItems = false,
	checkBoxId,
	handleCheckbox,
    isItemChecked
}) {
	return (
		<div
			onClick={setActiveTab(uniqueId)}
			className={`main-workflow-sidebar-item w-100 d-flex ${isActive && ' active'}`}
		>
			{isActive && (
				<span
					className={`${isDraft ? 'nav-item-side-line-draft' : 'nav-item-side-line'}`}
				></span>
			)}
			<span
				className={`new-sidebar-item text-left d-flex flex-row align-items-center  
    ${isActive && ' active'}
    `}
				style={{ gap: '8px' }}
			>
				{selectableItems ? (
					<input
						type='checkbox'
						className='multi-select-checkbox'
						onChange={handleCheckbox}
						value={checkBoxId}
						onClick={(event) => event.stopPropagation()}
                        checked={isItemChecked}
					/>
				) : (
					<div className={`${isDraft ? 'dot-draft' : 'dot'}`}></div>
				)}

				<a className='BodyTwoLight pt-1 w-100'>{label}</a>
			</span>
		</div>
	);
}

export default memo(NewSidebarWorkflowItem);
