let baseURL = window._env_?.REACT_APP_GW_URL;
let stripePublicKey = window._env_?.REACT_APP_STRIPE_PUBLIC_KEY;
let stripePricingKey = window._env_?.REACT_APP_STRIPE_PRICE_KEY;
if (!baseURL) {
    baseURL = 'http://localhost:8080';
	// baseURL='https://pr365-api.levvy.net';
}
if (!stripePublicKey) {
	stripePublicKey =
		'pk_test_51KBzY8AyXQIqWcsjY3SWXMlqZpSmZcMwEzvqIJr0tgMESturQCnoZoDdTm5aagiPfmNwySXr9370VaHinvNBEBRW00NMHLXb22';
}
if (!stripePricingKey) {
	stripePricingKey = 'price_1KEVS5AyXQIqWcsjKotkkhWK';
}
export { stripePricingKey, stripePublicKey };
