import React, { useState } from 'react';
import FormButtonOne from '../../Buttons/FormButtonOne';
import { Dialog, Zoom } from '@mui/material';
import { MEETING_ACCEPT_STATUS } from '../../../utilities/utilities';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Zoom in={true} ref={ref} {...props} style={{ transitionDelay: '50ms' }} />;
});

function RecurringMeetingInviteModal({
	open,
	inviteType,
	closeHandler,
	submitHandler,
	loading,
}) {
	const [selected, setSelected] = useState('false');
	const options = [
		{ id: 1, label: 'This meeting', value: 'false' },
		{ id: 2, label: 'This and following meetings', value: 'future' },
		{ id: 3, label: 'All meetings', value: 'true' },
	];
	const handleChange = (event) => {
		setSelected(event.target.value);
	};
	return (
		<Dialog TransitionComponent={Transition} open={open} onClose={closeHandler}>
			<div className='my-task-delete-alert'>
				<div className='content'>
					<div className='HeadlineTwoBold dark-text form-title'>
						{inviteType == MEETING_ACCEPT_STATUS.ACCEPTED
							? 'Accept Invitation'
							: 'Decline Invitation'}
					</div>
					<div className='d-flex flex-column w-100' style={{ gap: 10 }}>
						<div
							style={{ gap: '0.5em' }}
							className='d-flex flex-column align-items-start justify-content-start'
						>
							{options?.map((opt, i) => {
								return (
									<div
										key={i}
										style={{ gap: '1em' }}
										className='d-flex flex-row align-items-center justify-content-center'
									>
										<input
											id={opt?.id}
											type='radio'
											value={opt?.value}
											checked={opt?.value == selected}
											onChange={handleChange}
										/>
										<label className='BodyTwoLight approval-text align-self-center w-100 m-0'>
											{opt.label}
										</label>
									</div>
								);
							})}
						</div>
					</div>
				</div>
				<div className='action-buttons'>
					<FormButtonOne
						onClick={closeHandler}
						type='button'
						className='secondary-btn-light'
					>
						Cancel
					</FormButtonOne>

					<FormButtonOne
						onClick={() => submitHandler(inviteType, selected)}
						type='button'
						loading={loading}
					>
						Confirm
					</FormButtonOne>
				</div>
			</div>
		</Dialog>
	);
}

export default RecurringMeetingInviteModal;
