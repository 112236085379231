import { ReactComponent as ClipBoardIcon } from '../../assets/icons/ClipboardText.svg';
import WorkflowTaskCard from '../sub_components/WorkflowTaskCard';

export default function NewTaskSections({ tasks }) {
	return (
		<div>
			<div className='icon-header' style={{ marginBottom: '18px' }}>
				<div style={{ marginRight: '12px' }}>
					<ClipBoardIcon />
				</div>
				<span className='HeadlineThreeBold mt-1'>Workflow Tasks</span>
			</div>
			{tasks?.length > 0 ? (
				tasks?.map((task, index) => {
					return <WorkflowTaskCard key={index} task={task} tasks={tasks} />;
				})
			) : (
				<div className='workflow-bar d-flex justify-content-center mt-4'>
					<span className='BodyTwoLight' style={{ color: '#696F6C' }}>
						No Task in this Workflow
					</span>
				</div>
			)}
		</div>
	);
}
