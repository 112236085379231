/* eslint-disable no-mixed-spaces-and-tabs */
import { useEffect, useMemo, useState } from 'react';

// Material Ui Component
import { Avatar, Input, Modal } from '@mui/material';

// Import Components
import ButtonSquare from '../components/Buttons/ButtonSquare';
import SectionHeader from '../components/sidebar/SectionHeader';
import Sidebar from '../components/sidebar/SideBar';
import JobTitlePicker from '../components/sidebar/JobTitlePicker';
import WorkModePicker from '../components/sidebar/WorkModePicker';
import LocationPicker from '../components/sidebar/LocationPicker';
import TeamPickList from '../components/sidebar/TeamPickList';
import Card from '../components/Others/Card';
import useModal from '../components/Modal/useModal';
import DummyModal from '../components/Modal/DummyModal';
import UserAdd from '../components/UserManagement/UserAdd';

// Demo Data
import * as userActions from '../redux/actions/user-management.actions';
import * as clientActions from '../redux/actions/client-actions';
import * as teamActions from '../redux/actions/team-management.actions';

import { useDispatch, useSelector } from 'react-redux';

// Routes
import { useHistory, useLocation } from 'react-router-dom';
import Restricted from '../components/Permissions/Restricted';
import {
	executivePermissions,
	getUniqueListBy,
	getUniqueObjectArray,
	Member,
	standardPermissions,
} from '../utilities/utilities';
import { routes } from '../utilities/routes';
import AddTeam from '../components/TeamManagement/AddTeam';
import ShowArchivedUsersTable from '../components/UserManagement/ShowArchivedUsersTable';

function ArchivedUsers() {
	const history = useHistory();
	const location = useLocation();
	const dispatch = useDispatch();

	const [archiveModalIsOpen, setArchiveModalIsOpen] = useState(false);
	const [userId, setUserId] = useState(null);

	const user = useSelector((state) => state.authReducer.user);
	const { roles } = useSelector((state) => state.userReducer);

	const roleList = useMemo(() => {
		return roles.map((role) => {
			return {
				label: role?.role_name,
				value: role?.id,
				...role,
			};
		});
	}, []);

	useEffect(() => {
		dispatch(userActions.getUsersBilling());
	}, [dispatch]);

	let users = useSelector((state) => state.userReducer.users);
	const loggedInUser = useSelector((state) => state.authReducer.user);
	const userProfileManagement = useSelector(
		(state) => state.userReducer.userProfileManagement,
	);
	let filteredUserRoles = useSelector(
		(state) => state.userReducer.filteredUserRole,
	);

	const filters = useSelector((state) => state.userReducer.filters);

	let teams = useSelector(
		(state) => state.userReducer.userProfileManagement?.teams,
	);

	users = getUniqueListBy(users, 'user_id');

	if (filteredUserRoles?.length > 0) {
		filteredUserRoles = filteredUserRoles?.map((obj) => {
			return {
				...obj,
				is_checked: filters?.userRoles?.includes(obj?.user_id[0]),
			};
		});
	}

	if (teams?.length > 0) {
		teams = teams.map((team) => {
			return {
				value: team.id,
				label: team.name,
				is_checked: filters?.team?.includes(team.id),
			};
		});
	}

	if (
		loggedInUser?.user_roles
			?.map((item) => item.role_id)
			?.includes(Member.teamManagerId) ||
		loggedInUser?.user_roles
			?.map((item) => item.role_id)
			?.includes(Member.teamMemberId)
	) {
		users = users?.filter((u) => !executivePermissions.includes(u.role_id));
		filteredUserRoles = filteredUserRoles?.filter(
			(u) => !executivePermissions.includes(u.value),
		);
	}

	const InitialTeamForm = {
		name: '',
		description: '',
	};

	const [teamModal, setTeamModal] = useState(false);
	const [existingUserModal, setExistingUserModal] = useState(false);

	const AddUser = () => {
		dispatch(userActions.clearImage());
		ModalType('Create User');
		toggleModal();
		dispatch(userActions.resetUserAddErrors());
	};

	const addNewTeam = () => {
		setTeamModal(true);
		toggleModal();
	};

	// Name Input style
	const InputClass = {
		width: '100%',
		fontFamily: 'CentraLight',
		fontWeight: 'light',
		fontSize: '14px !important',
		lineHeight: '18px',
		color: 'white',
		backgroundColor: 'rgba(0, 0, 0, 0.25)',
		padding: '8px 6px 2px 8px',
		borderRadius: '3px',
		marginTop: '8px',
	};

	//   Name State
	const [Name, setName] = useState('');
	const resetFirst = () => {
		setName('');
	};
	const NameChange = (event) => {
		setName(event.target.value);
	};

	const { modalIsOpen, toggleModal, ModalName, ModalType } = useModal();

	const OnTeamSubmit = (type, id, inputs) => {
		dispatch(
			teamActions.addTeam(inputs, () => {
				setTeamModal(false);
				toggleModal();
			}),
		);
	};

	useEffect(() => {
		dispatch(userActions.getRoles());
		dispatch(userActions.getUsers(filters));
		dispatch(userActions.getFilteredUserProfileManagement());
		dispatch(userActions.getFilteredUserRoles());
		dispatch(teamActions.getTeams());
		dispatch(clientActions.getClients());
	}, [dispatch, filters]);

	const teamProfileNavigation = (teamId) => {
		const from = location.state || [];
		from.push({
			link: location.pathname,
			label: 'Users',
		});
		if (from.length > 2) from.shift();
		user?.user_roles
			?.map((item) => item.role_id)
			?.includes(Member.externalCollaborator)
			? null
			: history.push(`${routes['team-profile']}${teamId}`, from);
	};

	return (
		<div className='AppBody'>
			<Sidebar title='User Management'>
				<div className='SidebarContentWrapper'>
					<div className='User-filters'>
						<div>
							<SectionHeader
								title='Search Users'
								label='Clear'
								onClear={resetFirst}
							/>
							<Input
								sx={InputClass}
								disableUnderline={true}
								size='small'
								placeholder='User Name'
								name='first'
								value={Name}
								onChange={NameChange}
							/>
						</div>

						{/* <div>
							<div className='.SidebarListWrapper'>
								<TeamPickList
									options={teams}
									onChange={(e) =>
										dispatch(
											userActions.handleTeamCheckboxes(
												parseInt(e.target.value),
											),
										)
									}
									onClear={() => dispatch(userActions.clearTeamCheckboxes())}
									onClick={(teamId) => teamProfileNavigation(teamId)}
								/>
							</div>
						</div> */}

						{/* <div>
							<div className='.SidebarListWrapper'>
								<JobTitlePicker
									options={getUniqueObjectArray(filteredUserRoles)}
									onChange={(e) => {
										dispatch(
											userActions.handleRoleCheckboxes(
												e.target.checked,
												filteredUserRoles.filter(
													(r) => r.value === parseInt(e.target.value),
												)[0]?.user_id,
											),
										);
									}}
									onClear={() =>
										dispatch(userActions.clearJobTitleCheckboxes())
									}
								/>
							</div>
						</div> */}

						{/* <div>
							<div className='.SidebarListWrapper'>
								<WorkModePicker
									options={getUniqueObjectArray(
										userProfileManagement?.work_mode
											.filter(
												(mode) => mode.value != null || mode.label != null,
											)
											?.map((obj) => {
												return {
													...obj,
													is_checked: filters?.workMode?.includes(obj.value),
												};
											}),
									)}
									onChange={(e) =>
										dispatch(
											userActions.handleWorkModeCheckboxes(e.target.value),
										)
									}
									onClear={() =>
										dispatch(userActions.clearWorkModeCheckboxes())
									}
								/>
							</div>
						</div> */}

						{/* <div>
							<div className='.SidebarListWrapper'>
								<LocationPicker
									options={getUniqueObjectArray(
										userProfileManagement?.location
											.filter((loc) => loc.value != null || loc.label != null)
											?.map((obj) => {
												return {
													...obj,
													is_checked: filters?.location?.includes(obj.value),
												};
											}),
									)}
									onChange={(e) =>
										dispatch(
											userActions.handleLocationCheckboxes(e.target.value),
										)
									}
									onClear={() =>
										dispatch(userActions.clearLocationCheckboxes())
									}
								/>
							</div>
						</div> */}
					</div>

					{/* <Restricted AllowedUsers={standardPermissions}>
						<ButtonSquare
							data-cy='add-user'
							className='full-width BodyOneBold'
							onClick={AddUser}
						>
							Add New User
						</ButtonSquare>
					</Restricted> */}
				</div>
			</Sidebar>

			<div className='Listing-Main' style={{ backgroundColor: '#EEF2F3' }}>
				<div className='Listing-Content'>
					<Card
						title='Users'
						secondaryBtn={'Show Users'}
						secondaryBtnClick={() => {
							history.push('/users');
						}}
					>
						<ShowArchivedUsersTable
							Name={Name}
							existingUserModal={existingUserModal}
							setExistingUserModal={setExistingUserModal}
						/>
					</Card>
				</div>
			</div>

			{modalIsOpen ? (
				<Modal
					onClose={toggleModal}
					open={modalIsOpen}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<DummyModal title={ModalName} onClose={toggleModal}>
						<UserAdd onCancel={toggleModal} addNewTeam={addNewTeam} />
					</DummyModal>
				</Modal>
			) : (
				<></>
			)}

			{teamModal && (
				<Modal
					onClose={() => setTeamModal(false)}
					open={teamModal}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<DummyModal title={'Create Team'} onClose={() => setTeamModal(false)}>
						<AddTeam
							onCancel={() => setTeamModal(false)}
							value={InitialTeamForm}
							submit={OnTeamSubmit}
							response={'Success'}
						/>
					</DummyModal>
				</Modal>
			)}
		</div>
	);
}

export default ArchivedUsers;
