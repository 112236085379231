import { memo } from 'react';
import { ReactComponent as TaskIcon } from '../../assets/icons/Chats.svg';
import { ReactComponent as NoteIcon } from '../../assets/icons/NotePencil.svg';
import ReactHtmlParser from 'react-html-parser';
import {
	convertDecimalToTime,
	getAvatarLabel,
	truncateString,
} from '../../utilities/utilities';
import { ReactComponent as LinkIcon } from '../../assets/icons/External Link.svg';
import { ReactComponent as HoursIcon } from '../../assets/icons/Clock primary.svg';
import { ReactComponent as GuestIcon } from '../../assets/icons/Users.svg';
import { Avatar, AvatarGroup, Modal } from '@mui/material';
import useModal from '../Modal/useModal';
import TaskGuestsList from './TaskGuestsList';
import DummyModal from '../Modal/DummyModal';
import TaskDescriptionModal from './Modals/TaskDescriptionModal';

const avatarStyle = {
	bgcolor: `#FEF2E7`,
	color: `#333`,
	height: '23px',
	width: '23px',
	fontSize: '0.8rem',
};

function ClientAdHocTask({ task, assignee_user }) {
	const { modalIsOpen, toggleModal, ModalName, ModalType } = useModal();

	return (
		<div className='client-meeting-task'>
			<div className='w-100 text-left d-flex align-items-center justify-content-between'>
				<span className='d-flex align-items-center' style={{ gap: '24px' }}>
					<span className='d-flex' style={{ gap: '12px' }}>
						<TaskIcon />
						<h3 className='HeadlineThreeBold'>{task.title}</h3>
					</span>

					<span className='d-flex align-items-center' style={{ gap: '8px' }}>
						{assignee_user && (
							<Avatar
								sx={{
									bgcolor: `#FEF2E7`,
									color: `#333`,
									marginRight: '10px',
									height: '23px',
									width: '23px',
									fontSize: '0.8rem',
								}}
								alt={assignee_user?.full_name}
								src={assignee_user?.image_url ? assignee_user?.image_url : ''}
							>
								{getAvatarLabel(assignee_user?.full_name)}
							</Avatar>
						)}

						<p className='BodyTwoLight p-0 m-0 light-text pt-1'>
							{assignee_user?.full_name
								? assignee_user?.full_name
								: 'Not assigned'}
						</p>
					</span>
				</span>
			</div>
			<div className='meeting-task-details text-left'>
				<span className='d-flex' style={{ gap: '8px' }}>
					<NoteIcon />
					<span className='BodyTwoBold pt-1'>Notes:</span>
					<span className='BodyTwoLight pt-1'>
						{task?.notes?.length < 1 ||
						task?.notes == null ||
						task?.notes?.replace(/<[^>]+>/g, '')?.trim()?.length == 0 ? (
							'-'
						) : (
							<div
								// style={{ minHeight: '14px' }}
								className='d-flex justify-content-between position-relative'
							>
								<p className='BodyTwoLight dark-text m-0 text-break'>
									{ReactHtmlParser(truncateString(task?.notes, 500))}
								</p>

								{task?.notes?.length > 500 && (
									<span
										onClick={() => {
											ModalType('TASK_NOTES');
											toggleModal();
										}}
										className='primary-text BodyThreeLight m-0 ml-1 pointer'
									>
										Read More
									</span>
								)}
							</div>
						)}
					</span>
				</span>
				<span className='d-flex' style={{ gap: '8px' }}>
					<NoteIcon />
					<span className='BodyTwoBold pt-1'>Description:</span>
					<span className='BodyTwoLight pt-1'>
						{task?.description?.length < 1 ||
						task?.description == null ||
						task?.description?.replace(/<[^>]+>/g, '')?.trim()?.length == 0 ? (
							'-'
						) : (
							<div
								// style={{ minHeight: '14px' }}
								className='d-flex justify-content-between position-relative'
							>
								<p className='BodyTwoLight dark-text m-0 text-break'>
									{ReactHtmlParser(truncateString(task?.description, 500))}
								</p>

								{task?.notes?.length > 500 && (
									<span
										onClick={() => {
											ModalType('TASK_DESCRIPTION');
											toggleModal();
										}}
										className='primary-text BodyThreeLight m-0 ml-1 pointer'
									>
										Read More
									</span>
								)}
							</div>
						)}
					</span>
				</span>
				<span className='d-flex' style={{ gap: '8px' }}>
					<LinkIcon />
					<span className='BodyTwoBold pt-1'>Meeting Link:</span>
					<a
						className='BodyTwoLight pt-1 primary-text'
						target='_blank'
						rel='noreferrer'
						href={task?.metadata?.meeting_link}
						style={{
							display: 'inline-block',
							maxWidth: '150px',
							whiteSpace: 'nowrap',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							// border: '2px solid red'
						}}
					>
						{task?.metadata?.meeting_link ? task?.metadata?.meeting_link : '-'}
					</a>
				</span>
				<span className='d-flex' style={{ gap: '8px' }}>
					<HoursIcon />
					<span className='BodyTwoBold pt-1'>Budgeted Hours:</span>
					<span className='BodyTwoLight pt-1'>
						{convertDecimalToTime(task.budgeted_hours)}
					</span>
				</span>
				<span
					className='d-flex align-items-center justify-content-center'
					style={{ gap: '8px' }}
				>
					<GuestIcon />
					<span className='BodyTwoBold pt-1'>Guests:</span>
					{task.metadata?.meeting_guests?.lenth == 0 ? (
						<span className='BodyTwoLight pt-1'>No Guests</span>
					) : (
						<span className='BodyTwoLight pt-1 d-flex align-items-center'>
							<AvatarGroup max={2}>
								{task?.metadata?.meeting_guests?.length > 0 && (
									<Avatar
										sx={avatarStyle}
										alt={task?.metadata?.meeting_guests[0]?.full_name}
										src={`${task?.metadata?.meeting_guests[0]?.image_url}`}
									>
										{getAvatarLabel(
											task?.metadata?.meeting_guests[0]?.full_name,
										)}
									</Avatar>
								)}
								{task?.metadata?.meeting_guests?.length > 1 && (
									<Avatar
										sx={avatarStyle}
										alt={task?.metadata?.meeting_guests[1]?.full_name}
										src={`${task?.metadata?.meeting_guests[1]?.image_url}`}
									>
										{getAvatarLabel(
											task?.metadata?.meeting_guests[1]?.full_name,
										)}
									</Avatar>
								)}
							</AvatarGroup>

							{task?.metadata?.meeting_guests?.length > 2 && (
								<span
									className='BodyTwoLight m-0 primary-text ml-2 pointer'
									onClick={() => {
										ModalType('MY_TASK_GUEST_MODAL');
										toggleModal();
									}}
								>
									View all
								</span>
							)}
						</span>
					)}
				</span>
			</div>

			{modalIsOpen && (
				<Modal
					onClose={toggleModal}
					open={modalIsOpen}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<>
						{ModalName == 'MY_TASK_GUEST_MODAL' && (
							<TaskGuestsList
								guestList={task?.metadata?.meeting_guests
									?.sort((a, b) => a.full_name.localeCompare(b.full_name))
									?.map((assignee) => assignee?.id)}
								users={task?.metadata?.meeting_guests}
								toggleModal={toggleModal}
							/>
						)}
						{ModalName == 'TASK_DESCRIPTION' && (
							<DummyModal
								title={task.title}
								onClose={toggleModal}
								height='400px'
							>
								<TaskDescriptionModal
									type={'text'}
									value={task.description}
									Edit={false}
									OnCancel={() => toggleModal()}
									richTextStyle={{ height: '250px' }}
								/>
							</DummyModal>
						)}
						{ModalName == 'TASK_NOTES' && (
							<DummyModal
								title={task.title}
								onClose={toggleModal}
								height='400px'
							>
								<TaskDescriptionModal
									type={'text'}
									value={task.motes}
									Edit={false}
									OnCancel={() => toggleModal()}
									richTextStyle={{ height: '250px' }}
								/>
							</DummyModal>
						)}
					</>
				</Modal>
			)}
		</div>
	);
}

export default memo(ClientAdHocTask);
