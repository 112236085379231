import { useState } from 'react';

export const useLocalStorage = (key) => {
	const [name] = useState(key);

	const getLocalStorage = () => {
		const local = localStorage.getItem(name);
		if (local != null) {
			return JSON.parse(local);
		}
		return null;
	};

	const setLocalStorage = (item) => {
		localStorage.setItem(name, JSON.stringify(item));
	};

	const removeLocalStorage = () => {
		return localStorage.removeItem(name);
	};

	return { getLocalStorage, setLocalStorage, removeLocalStorage };
};
