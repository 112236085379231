// eslint-disable-next-line no-unused-vars
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as clientActions from '../../redux/actions/client-actions';
import { ReactComponent as WorkflowsIcon } from '../../assets/icons/BookOpen.svg';
import Sidebar from '../sidebar/SideBar';
import Profile from './Profile';
import * as builderActions from '../../redux/actions/workflow-builder-actions.js';
import { standardPermissions } from '../../utilities/utilities';
import { useSelector } from 'react-redux';
import NewSidebarItem from '../sidebar/NewSidebarItem';
import NewSidebarWorkflowItem from '../sidebar/NewSidebarWorkflowItem';
import Restricted from '../Permissions/Restricted';
import AlertBar from '../sub_components/AlertBar.jsx';

function ClientProfile() {
	const { id } = useParams();
	const dispatch = useDispatch();
	const history = useHistory();

	const user = useSelector((state) => state.authReducer.user);
	const client = useSelector((state) => state.clientReducer.client);
	const allClientWorkflows = useSelector((state) => state.WorkflowBuilder.allWorkflowTasks);
	const isFeatureFlag = useSelector((state) => state.authReducer.isFeatureFlag);
	const activeTab = useSelector((state) => state.clientReducer.clientProfileActiveTab);
	const clientWorkflow = useSelector((state) => state.WorkflowBuilder.selectedClientWorkflow);
	const userTeamRole = useSelector((state) => state.clientReducer.userTeamRole);

	function setActiveTab(tab) {
		dispatch(clientActions.setClientProfileActiveTab(tab));
	}

	const user_team_role = useMemo(() => {
		return (
			user?.user_roles?.find((item) =>
				item?.role_scopes
					?.map((role) => role.team_id)
					?.includes(client?.team_client_permissions[0]?.team_id),
			)?.role_id || user?.userRole?.id
		);
	}, [user?.user_roles, client?.team_client_permissions]);

	const allClientWorkflowsNav = allClientWorkflows
		?.sort((a, b) => a?.display_name.localeCompare(b?.display_name))
		?.filter((clientworkflow) => {
			if (standardPermissions.includes(userTeamRole)) {
				return true;
			} else {
				return clientworkflow?.status !== 'draft' && clientworkflow?.is_active !== false;
			}
		})
		?.map((clientworkflow) => {
			return {
				name: clientworkflow?.display_name,
				sectionId: clientworkflow?.id,
				is_active: clientworkflow?.is_active,
				status: clientworkflow?.status,
			};
		});

	const draftWorkflowsNav = allClientWorkflows
		?.sort((a, b) => a?.display_name.localeCompare(b?.display_name))
		?.filter((clientworkflow) => {
			return clientworkflow?.status == 'draft' && clientworkflow?.is_active == false;
		})
		?.map((clientworkflow) => {
			return {
				name: clientworkflow?.display_name,
				sectionId: clientworkflow?.id,
				is_active: clientworkflow?.is_active,
				status: clientworkflow?.status,
			};
		});

	const activeWorkfowsNav = allClientWorkflows
		?.sort((a, b) => a?.display_name.localeCompare(b?.display_name))
		?.filter((clientworkflow) => {
			return clientworkflow?.status !== 'draft' && clientworkflow?.is_active !== false;
		})
		?.map((clientworkflow) => {
			return {
				name: clientworkflow?.display_name,
				sectionId: clientworkflow?.id,
				is_active: clientworkflow?.is_active,
				status: clientworkflow?.status,
			};
		});

	const navigationItems = [
		{ name: 'Client Information', sectionId: 'PROFILE' },
		{ name: 'Resources', sectionId: 'RESOURCES' },
		{ name: 'Folders and Files', sectionId: 'FOLDERS_AND_FILES' },
		{ name: 'Engagement Overview', sectionId: 'ENGAGEMENT_OVERVIEW' },
		isFeatureFlag && { name: 'Ad hoc Tasks', sectionId: 'AD_HOC_TASKS' },
		{ name: 'Meetings', sectionId: 'MEETING_TASKS' },
	]?.filter((item) => item);

	const navigateToBuilder = () => {
		history.push(`/workflow-builder`, {
			clientName: client?.dba?.trim() == '' ? client?.company_name : client?.dba,
			clientId: id,
		});
	};

	const tabChangeHandler = useCallback(
		(tab) => () => {
			setActiveTab(tab);
		},
		[],
	);

	const workflowTabChangeHandler = useCallback(
		(tab) => () => {
			setActiveTab(tab);
			dispatch(builderActions.setSelectedClientWorkflow(tab));
		},
		[],
	);

	useEffect(() => {
		dispatch(clientActions.setUserTeamRole(user_team_role));

		return () => {
			dispatch(clientActions.setUserTeamRole(undefined));
		};
	}, [user?.user_roles, client?.team_client_permissions]);

	useEffect(() => {
		dispatch(builderActions.getAllWorkflowTasks(id));
		dispatch(clientActions.setClientProfileActiveTab('PROFILE'));
	}, [dispatch, id]);

	useMemo(() => {
		if (clientWorkflow?.id) {
			setActiveTab(clientWorkflow?.id);
		}
	}, [clientWorkflow?.id]);

	return (
		<div className='AppBody'>
			<Sidebar title='Client Management'>
				<div className='new-sidebar-content-wrapper'>
					<div className='User-filters'>
						<div className='new-side-menu BodyTwoLight-bold m-0 d-flex flex-column align-items-start'>
							{navigationItems?.map((item, index) => {
								const prevOfActive =
									navigationItems[index + 1]?.sectionId == activeTab;
								return (
									<>
										{index == 0 && (
											<div
												style={{ padding: '8px' }}
												className={`w-100 main-sidebar-item ${
													navigationItems[0].sectionId == activeTab
														? 'prev'
														: ''
												}`}
											></div>
										)}
										<NewSidebarItem
											key={item.sectionId}
											uniqueId={item.sectionId}
											label={item.name}
											isActive={activeTab == item.sectionId}
											setActiveTab={tabChangeHandler}
										/>
										<div
											style={{ padding: '8px' }}
											className={`w-100 main-sidebar-item ${
												prevOfActive ? 'prev' : ''
											}`}
										></div>
									</>
								);
							})}

							<div className='new-side-menu'>
								<span
									className='text-left d-flex flex-row align-items-center new-sidebar-item-header'
									style={{ gap: '4px', background: '#364E55' }}
								>
									<div>
										<WorkflowsIcon />
									</div>
									<div className='BodyOneBold text-white'>Workflows</div>
								</span>

								{allClientWorkflowsNav?.length == 0 && (
									<div
										style={{ gap: '8px' }}
										className={`main-workflow-sidebar-item new-sidebar-item-header w-100 d-flex flex-column text-left`}
									>
										<span className='BodyTwoLight w-100 text-white'>
											You haven’t added a workflow to this client.
										</span>

										<Restricted
											AllowedUsers={[...standardPermissions]}
											userTeamRole={[userTeamRole]}
										>
											<span
												onClick={navigateToBuilder}
												className='BodyTwoLight w-100 primary-text pointer'
											>
												Add workflow
											</span>
										</Restricted>
									</div>
								)}

								{standardPermissions?.includes(userTeamRole) &&
									draftWorkflowsNav?.length > 0 && (
										<>
											<div
												className='w-100 text-left'
												style={{
													backgroundColor: '#364e55',
													padding: '8px 0px 0px 26px',
												}}
											>
												<span className='text-white BodyTwoBold'>
													Drafts
												</span>
											</div>
											{draftWorkflowsNav?.map((workflow, index) => {
												const prevOfActive =
													draftWorkflowsNav[index + 1]?.sectionId ==
													activeTab;
												return (
													<>
														{index == 0 && (
															<div
																style={{ padding: '8px' }}
																className={`w-100 main-workflow-sidebar-item ${
																	draftWorkflowsNav[0]
																		.sectionId == activeTab
																		? 'prev'
																		: ''
																}`}
															></div>
														)}
														<NewSidebarWorkflowItem
															key={workflow.sectionId}
															uniqueId={workflow.sectionId}
															label={`${workflow.name} (draft)`}
															isActive={
																activeTab == workflow.sectionId
															}
															setActiveTab={workflowTabChangeHandler}
															isDraft={true}
														/>
														<div
															style={{ padding: '8px' }}
															className={`w-100 main-workflow-sidebar-item ${
																prevOfActive ? 'prev' : ''
															}`}
														></div>
													</>
												);
											})}
										</>
									)}
								{activeWorkfowsNav?.length > 0 && (
									<>
										<div
											className='w-100 text-left'
											style={{
												backgroundColor: '#364e55',
												padding: '8px 0px 0px 26px',
											}}
										>
											<span className='text-white BodyTwoBold'>Active</span>
										</div>
										{activeWorkfowsNav?.map((workflow, index) => {
											const prevOfActive =
												activeWorkfowsNav[index + 1]?.sectionId ==
												activeTab;
											return (
												<>
													{index == 0 && (
														<div
															style={{ padding: '8px' }}
															className={`w-100 main-workflow-sidebar-item ${
																activeWorkfowsNav[0].sectionId ==
																activeTab
																	? 'prev'
																	: ''
															}`}
														></div>
													)}
													<NewSidebarWorkflowItem
														key={workflow.sectionId}
														uniqueId={workflow.sectionId}
														label={`${workflow.name}`}
														isActive={activeTab == workflow.sectionId}
														setActiveTab={workflowTabChangeHandler}
														isDraft={false}
													/>
													<div
														style={{ padding: '8px' }}
														className={`w-100 main-workflow-sidebar-item ${
															prevOfActive ? 'prev' : ''
														}`}
													></div>
												</>
											);
										})}
									</>
								)}
							</div>
						</div>
					</div>
				</div>
			</Sidebar>
			<div
				style={{
					width: 'calc(100vw - 260px)',
					height: '100vh',
					overflowY: 'auto',
				}}
			>
				<AlertBar />
				<div className='Client-Profile d-flex flex-column'>
					<Profile activeTab={activeTab} />
				</div>
			</div>
		</div>
	);
}

export default ClientProfile;
