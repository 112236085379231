import MeetingMyTasksTable from './MeetingMyTasksTable';

const MeetingMyTasks = () => {
	return (
		<section className='w-100 d-flex flex-column' style={{ gap: '12px' }}>
			<div
				style={{ padding: '12px 18px', background: 'white' }}
				className='text-left w-100 d-flex align-items-center justify-content-between'
			>
				<span className='d-flex' style={{ gap: '12px' }}>
					<h3 className='HeadlineThreeBold'>Meetings</h3>
				</span>
			</div>
			<div className='Profile-Content'>
				<MeetingMyTasksTable />
			</div>
		</section>
	);
};

export default MeetingMyTasks;
