import * as searchActions from '../actions/search-actions';

const intialState = {
	search: '',
	searchResult: undefined,
	loading: false,
	isSearchModalOpen: false,
};

export const searchReducer = (state = intialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case searchActions.SEARCH_CHANGE_HANDLER:
			return (state = {
				...state,
				search: payload,
			});

		case searchActions.SET_SEARCH_RESULT:
			return (state = {
				...state,
				searchResult: payload,
			});

		case searchActions.SET_LOADING:
			return (state = {
				...state,
				loading: payload,
			});

		case searchActions.SET_MODAL_STATE:
			return (state = {
				...state,
				isSearchModalOpen: payload,
			});

		default:
			return state;
	}
};

export default searchReducer;
