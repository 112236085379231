import * as Actions from '../../actions/integrations/accounting-actions';

const initialState = {
    integration: null,
    codatCustomers: []
};

const AccountingReducer = (state = initialState, action) => {
    const {type, payload} = action;
    switch(type){
        case Actions.GET_INTEGRATION: 
            return state = {
                ...state,
                integration: payload
            };
        case Actions.GET_CODAT_CUSTOMERS:
            return state = {
                ...state,
                codatCustomers: payload?.results
            };
        default: 
            return state;
    }
};

export default AccountingReducer;