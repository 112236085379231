/* eslint-disable no-mixed-spaces-and-tabs */

import { useMemo } from 'react';
import WorkflowProgressBarSection from './WorkflowProgressBarSection';
import { useSelector } from 'react-redux';
import {
	getAccountAccessOfRoles,
	getAccountComponentsWithSharedAccounts,
} from '../../utilities/utilities';

function WorkflowProgressBar({ repeat_type, isTasksAssigned, workflow_submission }) {
	// states
	const data = workflow_submission?.data?.length > 0 ? workflow_submission?.data[0] : undefined;
	const user = useSelector((state) => state.authReducer.user);
	const client = useSelector((state) => state.clientReducer.client);
	const urls = useSelector((state) => state.clientAccountsReducer.allUrls);

	const userRole =
		user?.user_roles?.find((item) =>
			item?.role_scopes
				?.map((role) => role.team_id)
				?.includes(client?.team_client_permissions[0]?.team_id),
		)?.role_id || user?.userRole?.id;

	const clientAccounts = useSelector((state) => state.clientAccountsReducer.clientAccounts)?.map(
		(item) => {
			return {
				...item,
				softwares: item?.softwares
					?.map((soft) => {
						return {
							...soft,
							institution:
								urls?.find((url) => url.id == soft.institution_url_id)
									?.institution || soft?.name,
							login:
								urls?.find((url) => url.id == soft.institution_url_id)?.url ||
								soft?.custom_url,
						};
					})
					?.filter((software) =>
						getAccountAccessOfRoles(software?.role_id)?.includes(userRole),
					),
			};
		},
	);

	const isResources = useMemo(() => {
		// Handling Accounts

		const isAccount = clientAccounts?.find((account) => {
			let values = [];
			data?.props?.sections[0]?.columns?.map((column) => {
				column?.columnComponents?.map((col_comp) => {
					if (
						getAccountComponentsWithSharedAccounts(account.type) ===
						col_comp?.componentType
					) {
						values = col_comp.value;
					}
				});
			});

			return account?.softwares?.find((software) => values?.includes(software?.id));
		});
		// Handling Links

		const workflowLinks = data?.props?.sections[0]?.columns?.filter((col) =>
			col.columnComponents?.find((compo) => compo.componentType === 'links-pick-list'),
		)[0]?.columnComponents[0]?.value;

		const isLink =
			client?.links && client?.links?.find((link) => workflowLinks?.includes(link?.id));

		// Handling Contacts

		const clientContacts = data?.props?.sections[0]?.columns?.filter((col) =>
			col.columnComponents?.find((compo) => compo.componentType === 'contact-pick-list'),
		)[0]?.columnComponents[0]?.value;

		const isContact =
			client?.client_contact_list &&
			client?.client_contact_list?.find((contact) => clientContacts?.includes(contact?.id));

		return Boolean(isAccount || isLink || isContact);
	}, [data, clientAccounts, client]);

	const progressBarSectionTitles = useMemo(() => {
		return [
			{ title: 'Set Recurrence', condition: repeat_type },
			{ title: 'Assign all Tasks', condition: isTasksAssigned },
			{ title: 'Add Resources', condition: isResources },
		];
	}, [repeat_type, isTasksAssigned, isResources]);

	return (
		<div className='w-100 progress-bar position-sticky' style={{ height: '102px', top: 65 }}>
			<div
				className='d-flex align-items-center justify-content-between'
				style={{ width: '600px', gap: '12px' }}
			>
				{progressBarSectionTitles?.map((section, index, arr) => {
					return (
						<WorkflowProgressBarSection
							key={index}
							title={section.title}
							condition={section.condition}
							hideBar={index == arr?.length - 1}
						/>
					);
				})}
			</div>
		</div>
	);
}

export default WorkflowProgressBar;
