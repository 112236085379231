import React, { useState, useEffect } from 'react';

export default function EditableRadioButton({
	label,
	name,
	value,
	Class,
	onChange,
	Edit,
	style,
	radioLabel,
	required,
	options,
	disabled
}) {
	const [selected, setSelected] = useState(value);

	const ChangeHandler = (e) => {
		setSelected(e.target.value);
		onChange(e);
	};

	return (
		<div className='ProfilePage d-flex flex-column w-100'>
			<label className='text-left m-0'>{label}</label>
			{Edit ? (
				<div style={{ gap: '0.5em' }} className='d-flex flex-row '>
					{options?.map((opt, i) => {
						return (
							<div
								key={i}
								style={{ gap: '0.5em' }}
								className='d-flex flex-row align-content-center justify-content-center'
							>
								<input
									className={Class}
									name={name}
									id={opt?.id}
									style={style}
									type='radio'
									required={required}
									value={opt?.value}
									checked={opt?.value == selected}
									onChange={ChangeHandler}
									disabled={disabled}
								/>
								<label className='text-left m-0' htmlFor={opt.value}>
									{opt.value}
								</label>
							</div>
						);
					})}
				</div>
			) : (
				<h3 className='BodyOneSherif text-left' name={name} style={style}>
					{value}
				</h3>
			)}
		</div>
	);
}
