import React from 'react';
import NewWorkflowCard from './NewWorkflowCard';
import moment from 'moment';

function ArchivedWorkflowsList({
	archivedWorkflows,
	profileData,
	handleCheckBoxes,
	isChecked,
	groupByCategory,
    unArchiveSingleWorkflow
}) {
	let companyLibraryWorkflows = [];

	if (archivedWorkflows?.length > 0) {
		companyLibraryWorkflows = archivedWorkflows.filter((category) => category !== null);
	}

	if (companyLibraryWorkflows?.length > 0 && !groupByCategory) {
		companyLibraryWorkflows = companyLibraryWorkflows
			.flatMap((category) =>
				category?.workflows?.map((workflow) => ({
					...workflow,
					category_name: category?.name,
				})),
			)
			.sort((a, b) => moment(b.updated_at).diff(moment(a.updated_at)));
	}
	return (
		<>
			{companyLibraryWorkflows?.length > 0 ? (
				groupByCategory ? (
					companyLibraryWorkflows?.map((category, index) => {
						return (
							<div key={index} style={{ marginTop: '24px' }}>
								<div
									className='workflow-categories-header'
									style={{ marginBottom: '18px' }}
								>
									<span className='HeadlineThreeBold'>{category?.name}</span>
								</div>
								<div className='workflow-categories-body container-fluid'>
									<div className='row w-100 '>
										{category?.workflows?.length > 0 &&
											category?.workflows?.map((workflow, innerIndex) => {
												return (
													<NewWorkflowCard
														key={innerIndex}
														workflow={workflow}
														profileData={profileData}
														handleCheckBoxes={handleCheckBoxes}
														isChecked={isChecked}
														categoryId={category?.id}
														categoryName={category?.name}
                                                        unArchiveSingleWorkflow={unArchiveSingleWorkflow}
                                                        archiveView
													/>
												);
											})}
									</div>
								</div>
							</div>
						);
					})
				) : (
					<div className='d-flex flex-wrap' style={{ marginTop: '24px' }}>
						{companyLibraryWorkflows?.map((workflow, innerIndex) => {
							return (
								<NewWorkflowCard
									key={innerIndex}
									workflow={workflow}
									profileData={profileData}
									handleCheckBoxes={handleCheckBoxes}
									isChecked={isChecked}
									categoryId={workflow?.category_id}
									categoryName={workflow?.category_name}
                                    unArchiveSingleWorkflow={unArchiveSingleWorkflow}
                                    archiveView
								/>
							);
						})}
					</div>
				)
			) : (
				<div className='workflow-bar d-flex justify-content-center mt-4'>
					<span className='BodyTwoLight' style={{ color: '#696F6C' }}>
						No Archived Workflow{' '}
					</span>
				</div>
			)}
		</>
	);
}

export default ArchivedWorkflowsList;
