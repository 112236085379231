/* eslint-disable no-mixed-spaces-and-tabs */
import { useSelector } from 'react-redux';
import { executivePermissions } from '../../utilities/utilities';

function Restricted({
	AllowedUsers,
	userTeamRole,
	RestrictedComponent,
	children,
}) {
	const user = useSelector((state) => state.authReducer.user);
	return (
		<>
			{!executivePermissions.includes(user?.userRole?.id) && userTeamRole
				? userTeamRole?.filter((role) => AllowedUsers?.includes(role))?.length >
					0
					? children
					: RestrictedComponent
				: AllowedUsers?.includes(user?.userRole?.id)
					? children
					: RestrictedComponent}
		</>
	);
}

export default Restricted;
