
function ThirdPartyContent() {
	return (
		<p className='BodyTwoLight'>
			We may provide information about third-party products, services,
			activities, or events, or we may allow third parties to make their content
			and information available on or through the Site (collectively, “
			<strong>Third- Party Content</strong>”). We provide Third-Party Content as
			a service to those interested in such content. Your dealings or
			correspondence with third parties and your use of or interaction with any
			Third-Party Content are solely between you and the third party. Levvy does
			not control or endorse, and makes no representations or warranties
			regarding, any Third-Party Content, and your access to and use of such
			Third-Party Content is at your own risk.
		</p>
	);
}

export default ThirdPartyContent;
