import { CircularProgress, Modal } from '@mui/material';
import { useRef, useState } from 'react';
import SubmitButtonsField from '../Buttons/SubmitButtonsField';
import DummyModal from '../Modal/DummyModal';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Upload from '../../assets/icons/Profilepictureupload.svg';
import Image from '../../assets/icons/Image.svg';
import { ReactComponent as PlusIcon } from '../../assets/icons/FramePlus.svg';

export default function EditableFileInput({
	label,
	value,
	onChange,
	Edit,
	onSubmit,
	clickHandler,
	toggleModal,
	isModalOpen,
	src,
	setCroppedImage,
	loading,
}) {
	const imageRef = useRef();
	// const [isModalOpen, setIsModalOpen] = useState(false);
	const [croppedImageUrl, setCroppedImageUrl] = useState(null);
	// const [croppedImage, setCroppedImage] = useState(null);
	// const [src, setSrc] = useState(null);
	const [crop, setCrop] = useState({
		unit: 'px',
		width: 85,
		height: 85,
		aspect: 1,
	});

	const onCropChange = (crop) => {
		setCrop(crop);
	};

	function dataURLtoFile(dataurl, filename) {
		const arr = dataurl.split(',');
		const mime = arr[0].match(/:(.*?);/)[1];
		const bstr = atob(arr[1]);
		let n = bstr.length;
		const u8arr = new Uint8Array(n);

		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}
		const croppedImage = new File([u8arr], filename, { type: mime });
		setCroppedImage(croppedImage);
	}

	function getCroppedImg(image, crop) {
		const canvas = document.createElement('canvas');
		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;
		canvas.width = crop.width;
		canvas.height = crop.height;
		const ctx = canvas.getContext('2d');

		ctx.drawImage(
			image,
			crop.x * scaleX,
			crop.y * scaleY,
			crop.width * scaleX,
			crop.height * scaleY,
			0,
			0,
			crop.width,
			crop.height,
		);

		const reader = new FileReader();
		canvas.toBlob((blob) => {
			reader.readAsDataURL(blob);
			reader.onloadend = () => {
				dataURLtoFile(reader.result, 'cropped.jpg');
			};
		});
	}

	const onCropComplete = (crop) => {
		if (imageRef.current && crop.width && crop.height) {
			const croppedImageUrl = getCroppedImg(imageRef.current, crop);
			setCroppedImageUrl({ croppedImageUrl });
		}
	};

	return (
		<div className='ProfilePage d-flex flex-column w-100'>
			<label className='text-left m-0'>{label}</label>

			<div>
				{Edit ? (
					<div className='d-flex'>
						<div style={{ cursor: 'pointer', position: 'relative' }}>
							<div
								style={{ position: 'absolute', right: '0px', top: '0px' }}
								onClick={clickHandler}
							>
								<PlusIcon />
							</div>
							<img
								src={value ? value : Upload}
								alt=''
								onClick={clickHandler}
								style={{ width: '75px', height: '75px', borderRadius: '50%' }}
							/>
						</div>
					</div>
				) : (
					<div className='d-flex'>
						{value ? (
							<img
								src={value}
								alt=''
								style={{ width: '75px', height: '75px', borderRadius: '50%' }}
							/>
						) : (
							<div
								style={{
									width: '75px',
									height: '75px',
									borderRadius: '50%',
									backgroundColor: '#FED0A5',
								}}
								className='d-flex justify-content-center align-items-center'
							>
								<img src={Image} alt='' />
							</div>
						)}
					</div>
				)}
			</div>
			{isModalOpen && (
				<Modal
					onClose={toggleModal}
					open={isModalOpen}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<DummyModal title='Upload Image' onClose={toggleModal}>
						<div className='d-flex flex-column w-100 p-3' style={{ gap: '0.7em' }}>
							<input
								placeholder='Pick a file'
								type='file'
								className='FileInput w-100'
								name='image'
								onChange={onChange}
								style={{ border: 'none' }}
							/>
							<div className='d-flex align-items-center justify-content-center pb-4'>
								{src && (
									<ReactCrop
										aspect={1}
										minWidth={30}
										minHeight={30}
										maxHeight={400}
										circularCrop
										crop={crop}
										onComplete={onCropComplete}
										onChange={onCropChange}
									>
										<img
											ref={imageRef}
											src={src}
											alt=''
											style={{
												width: '100%',
												maxHeight: '400px',
												objectFit: 'contain',
											}}
										/>
									</ReactCrop>
								)}
							</div>
						</div>

						<div className='VerticalSpacer bg-white p-3 rounded-bottom'>
							<SubmitButtonsField child={true}>
								<button
									id='Element'
									className='secondary'
									type='button'
									onClick={toggleModal}
								>
									Cancel
								</button>

								<button
									type='submit'
									className='d-flex align-items-center'
									id='Element'
									onClick={onSubmit}
								>
									{loading ? (
										<CircularProgress
											size={24}
											sx={{ color: 'white' }}
											disableShrink
										/>
									) : (
										'Submit'
									)}
								</button>
							</SubmitButtonsField>
						</div>
					</DummyModal>
				</Modal>
			)}
		</div>
	);
}
