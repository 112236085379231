/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable linebreak-style */
import React, { useEffect, useState, useRef, useMemo } from 'react';

// Import Material Ui Components
import { Modal } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

// Import Components
import TableHeader from '../Others/TableHeader';
import TableHeaderItem from '../Others/TableHeaderItem';
import DummyModal from '../Modal/DummyModal';
import ConfirmModal from '../Modal/ConfirmModal';
import useModal from '../Modal/useModal';

// Import Table Spacing Styles
import {
	TableItemWidth2,
	TableItemWidth3,
	TableItemWidth5,
	TableItemWidth6,
} from '../TableSpacing/TableSpacingStyles';

// Redux
import * as userActions from '../../redux/actions/user-management.actions';
import { useDispatch, useSelector } from 'react-redux';
import {
	getRoleName,
	Member,
	standardPermissions,
} from '../../utilities/utilities';
import { useHistory, useLocation } from 'react-router-dom';
import AddUserToTeam from './AddUserToTeam';
import Restricted from '../Permissions/Restricted';
import { routes } from '../../utilities/routes';
import { ReactComponent as TeamIcon } from '../../assets/icons/Team.svg';
import EditUserTeamRole from './EditUserTeamRole';
import UserTeamRole from './UserTeamRole';
import { setSnackBarIsOpen } from '../../redux/actions/dashboard/workspaces-actions';

function Teams() {
	const history = useHistory();
	const location = useLocation();
	const user = useSelector((state) => state.authReducer.user);
	// const edit = useSelector((state) => state.userReducer.profileInfoEdit); //User Profile
	const profile = useSelector((state) => state.userReducer.userProfile); //User Profile
	const Roles = useSelector((state) => state.userReducer.roles); // Total roles
	const TotalTeams = useSelector((state) => state.teamReducer.teams); // Total Teams
	// const userRoles = useSelector((state) => state.userReducer.userRoles); // Total User Roles
	const list = useSelector((state) => state.userReducer.users);
	const Teams = useSelector((state) => state.userReducer.userTeams);
	const TeamRoles = useSelector((state) => state.userReducer.userRoles); // Total User Roles
	const dispatch = useDispatch();

	// const [Loading, setLoading] = useState(false);
	const [newTeam, setnewTeam] = useState({
		description: '',
		user_id: profile?.id,
	});
	const [error, setError] = useState({
		error: '',
		actionLoading: false,
	});
	const [AddTeams, setAddTeams] = useState([]);
	const deleteRole = useRef({});
	const editTeamRole = useRef({});

	const TeamChangeHandler = (teamRole) => {
		setError({
			error: '',
			actionLoading: true,
		});
		var array = Teams?.map((team) => {
			console.log(team, 'teamCheck');
			if (team.team_id == teamRole.team_id) {
				return {
					...team,
					role_id: parseInt(teamRole.role_id),
				};
			} else {
				return team;
			}
		});
		dispatch(
			userActions.updateUserRole({ ...teamRole, tenant_id: user?.tenantId }),
		)
			.then(() => {
				dispatch(userActions.setUserTeams(array));
				toggleModal();
				setError({
					error: '',
					actionLoading: false,
				});
				dispatch(setSnackBarIsOpen(true, true, 'Updated Succesfully'));
			})
			.catch((error) => {
				setError({
					error: error.message,
					actionLoading: false,
				});
				dispatch(setSnackBarIsOpen(false, true, error.message));
			});
	};

	const onInputChange = (e) => {
		if (e.target.name === 'team_id') {
			setnewTeam({
				...newTeam,
				team_name: e.target.options[e.target.selectedIndex].text,
				[e.target.name]: parseInt(e.target.value),
				description: TotalTeams.find(
					({ id }) => id === parseInt(e.target.value),
				)?.description,
			});
		} else {
			setnewTeam({
				...newTeam,
				[e.target.name]: parseInt(e.target.value),
			});
		}
	};

	const AddTeam = () => {
		setError({
			error: '',
			actionLoading: true,
		});
		newTeam.team_description = AddTeams?.find(
			(t) => t.id == newTeam.team_id,
		)?.description;

		dispatch(
			userActions.addUserToTeam({
				user_id: newTeam?.user_id,
				team_id: newTeam?.team_id,
				role_id: newTeam?.role_id,
			}),
		)
			.then((response) => {
				setAddTeams(AddTeams.filter(({ id }) => id !== newTeam.team_id));
				// dispatch(
				// 	userActions.setUserTeams([
				// 		...Teams,
				// 		{ ...newTeam, id: response.id },
				// 	]),
				// );
				dispatch(
					userActions.updateUserProfileInfo({
						...profile,
						user_teams: [
							...profile?.user_teams,
							{
								id: response?.id,
								team: {
									name: newTeam?.team_name,
									description: newTeam?.team_description,
								},
								team_id: newTeam?.team_id,
								user_id: newTeam?.user_id,
							},
						],
						leave_requests: profile.leave_requests,
					}),
				);
				dispatch(
					userActions.updateUserRoles([
						...TeamRoles,
						{
							role_id: newTeam?.role_id,
							team_id: newTeam?.team_id,
						},
					]),
				);
				dispatch(setSnackBarIsOpen(true, true, 'Added Succesfully'));
				setError({
					error: '',
					actionLoading: false,
				});
				toggleModal();
			})
			.catch((error) => {
				setError({
					error: error.message,
					actionLoading: false,
				});
				dispatch(setSnackBarIsOpen(false, true, error.message));
			});
	};

	const deleteTeamRole = () => {
		setError({
			error: '',
			actionLoading: true,
		});
		const teamToRemoveID = Teams?.find(
			(team) => team?.team_id === deleteRole?.current?.team_id,
		);
		const teamToRemove = Teams.find(
			(team) => team.team_id === deleteRole.current.team_id,
		);
		dispatch(userActions.deleteTeamRole(teamToRemoveID?.id))
			.then(() => {
				setAddTeams([
					...AddTeams,
					{
						description: teamToRemove.team_description,
						name: teamToRemove.team_name,
						id: teamToRemove.team_id,
					},
				]);
				// dispatch(
				// 	userActions.setUserTeams(
				// 		Teams.filter((team) => team.team_id !== deleteRole.current.team_id),
				// 	),
				// );
				dispatch(userActions.getUserRole(deleteRole?.current?.user_id));
				dispatch(
					userActions.updateUserProfileInfo({
						...profile,
						user_teams: profile?.user_teams?.filter(
							(team) => team?.team_id !== deleteRole.current.team_id,
						),
					}),
				);
				dispatch(setSnackBarIsOpen(true, true, 'Deleted Succesfully'));
				toggleModal();
				setError({
					error: '',
					actionLoading: false,
				});
			})
			.catch((error) => {
				setError({
					error: error.message,
					actionLoading: false,
				});
				console.log(error.message, 'error');
				dispatch(setSnackBarIsOpen(false, true, error.message));
			});
	};

	const { modalIsOpen, toggleModal, ModalName, ModalType } = useModal();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);

	const handleClick = (DeleteRole) => (event) => {
		setAnchorEl(event.currentTarget);
		deleteRole.current = DeleteRole;
		ModalType('CONFIRM_DELETE');
		ModalOpener();
	};

	const handleEditClick = (role) => (event) => {
		setAnchorEl(event.currentTarget);
		editTeamRole.current = role;
		ModalType('EDIT_USER_TEAM');
		ModalOpener();
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const ModalOpener = () => {
		toggleModal();
		handleClose();
	};

	const handleNavigateClick = (team_id) => (event) => {
		const from = location.state || [];
		from.push({
			link: location.pathname,
			label: profile.full_name,
		});
		if (from.length > 2) from.shift();
		history.push(`${routes['team-profile']}${team_id}`, from);
	};

	useEffect(() => {
		if (profile?.user_teams) {
			const userTeams = profile?.user_teams?.map((team) => {
				return {
					role_id: TeamRoles?.find((role) => role.team_id === team.team_id)
						?.role_id,
					team_id: team?.team_id,
					team_name: team?.team?.name,
					team_description: team?.team?.description,
					user_id: team?.user_id,
					id: team?.id,
				};
			});

			dispatch(userActions.setUserTeams(userTeams));

			setAddTeams(
				TotalTeams.filter(
					(team) =>
						!profile?.user_teams
							?.map((team) => team?.team_id)
							?.includes(team?.id),
				),
			);
		}

		if (TeamRoles?.some((item) => item.role_id === Member.ownerId)) {
			dispatch(
				userActions.setUserTeams(
					TotalTeams?.map((team) => {
						return {
							role_id: Member.ownerId,
							team_id: team?.id,
							team_name: team?.name,
							team_description: team?.description,
							user_id: profile.id,
						};
					}),
				),
			);
		}
	}, [profile, Roles.length, TeamRoles?.length]);

	const allowdUsers = useMemo(() => {
		return TeamRoles?.some((item) => item.role_id === Member.ownerId)
			? []
			: TeamRoles?.some((item) => item.role_id === Member.supervisorId)
			? [Member.ownerId]
			: standardPermissions;
	}, [TeamRoles?.length]);

	const userTeamRole = useMemo(() => {
		profile?.user_teams?.map((team) => {
			return user?.user_roles?.find((item) =>
				item.role_scopes.map((role) => role.team_id).includes(team.team_id),
			)?.role_id;
		});
	}, [profile]);

	useEffect(() => {
		dispatch(userActions.getRoles());

		if (
			user?.user_roles?.find((role) => role.role_id === Member.teamManagerId)
		) {
			setnewTeam({
				...newTeam,
				role_id: Member.teamMemberId,
			});
		}
	}, [list?.length, Teams?.length]);

	return (
		<>
			<div
				className='UserProfile-Teams profile-section'
				style={{ gap: '12px' }}
			>
				<div className='Uprofile-sectionBar'>
					<div className='d-flex align-items-center' style={{ gap: '12px' }}>
						<TeamIcon />
						<h2 className='HeadlineThreeBold text-left'>Team & Roles</h2>
					</div>
				</div>

				<div
					className='Teams-Table'
					style={{
						padding: '18px',
						backgroundColor: '#EEF2F3',
						borderRadius: '4pxs',
					}}
				>
					<TableHeader ClassName='TableHeader flex' border={false}>
						<TableHeaderItem label='Team Name' width={TableItemWidth3} />
						<TableHeaderItem label='Description' width={TableItemWidth6} />
						<TableHeaderItem label='Role' width={TableItemWidth5} />
						<Restricted AllowedUsers={allowdUsers} userTeamRole={userTeamRole}>
							<TableHeaderItem
								ClassName='TProfile d-flex justify-content-center'
								label='Action'
								width={TableItemWidth2}
							/>
						</Restricted>
					</TableHeader>

					{Teams?.length < 1 ? (
						<div className='TableRow h-100 w-100 d-flex align-items-center justify-content-center'>
							<h4 className='BodyTwoLight'>No Teams</h4>
						</div>
					) : (
						Teams?.map((t, i) => {
							const loggedInUserTeamRole = user?.user_roles?.find((item) => {
								return item?.role_scopes
									?.map((role) => role.team_id)
									?.includes(t.team_id);
							})?.role_id;

							const role_name =
								Roles?.find((role) => role.id === t.role_id)?.role_name ||
								getRoleName(TeamRoles?.length > 0 && TeamRoles[0]?.role_id);

							return (
								<UserTeamRole
									key={t.team_id}
									team_name={t.team_name}
									team_description={t.team_description}
									role_name={role_name}
									handleNavigateClick={handleNavigateClick}
									team_id={t.team_id}
									role_id={t.role_id}
									loggedInUserTeamRole={loggedInUserTeamRole}
									handleEditClick={handleEditClick}
									handleClick={handleClick}
									user_id={t.user_id}
									user={user}
									allowdUsers={allowdUsers}
									userTeamRole={userTeamRole}
								/>
							);
						})
					)}
					<Restricted AllowedUsers={allowdUsers} userTeamRole={userTeamRole}>
						<div
							className='d-flex justify-content-center information-header d-flex align-items-center mt-2 w-100'
							// style={{ minWidth: '70vw',width: '100%' }}
						>
							<div
								className='pointer d-flex flex-row'
								style={{
									height: '22px',
									width: '22px',
									borderRadius: '3px',
									// margin: '1rem',
									padding: 0,
									position: 'relative',
									// backgroundColor: '#fb7a03',
								}}
								onClick={() => {
									ModalType('ADD_USER_IN_TEAM');
									toggleModal();
									setnewTeam({
										...newTeam,
										team_id: null,
										description: '',
										user_id: profile?.id,
									});
								}}
							>
								<AddIcon
									fontSize='small'
									sx={{
										color: '#fb7a03',
										fontSize: 21,
										position: 'absolute',
										margin: 0,
										padding: 0,
										top: 0,
										left: 0,
									}}
								/>
							</div>
							<h3
								data-cy='add-client-contact-btn'
								className='BodyTwoLight mt-2 dark-text'
								style={{ color: '#FB7A03', cursor: 'pointer' }}
								onClick={() => {
									ModalType('ADD_USER_IN_TEAM');
									toggleModal();
									setnewTeam({
										...newTeam,
										team_id: null,
										description: '',
										user_id: profile?.id,
									});
								}}
							>
								Assign New Team
							</h3>
						</div>
					</Restricted>
				</div>
			</div>

			{modalIsOpen ? (
				<Modal
					onClose={toggleModal}
					open={modalIsOpen}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<>
						{ModalName === 'CONFIRM_DELETE' && (
							<DummyModal
								title={'Remove this user from a team?'}
								onClose={toggleModal}
							>
								<ConfirmModal
									OnCancel={toggleModal}
									Onsubmit={deleteTeamRole}
									description={`Are you sure you want to remove this user from ${deleteRole.current.team_name}?`}
									descriptionClassName='text-left'
									isLoading={error.actionLoading}
								/>
							</DummyModal>
						)}
						{ModalName === 'ADD_USER_IN_TEAM' && (
							<DummyModal title={'Add Team'} onClose={toggleModal}>
								<AddUserToTeam
									onInputChange={(e) => onInputChange(e)}
									Roles={Roles}
									newTeam={newTeam}
									AddTeams={AddTeams}
									onCancel={toggleModal}
									onSubmit={AddTeam}
									actionLoading={error.actionLoading}
								/>
							</DummyModal>
						)}
						{ModalName === 'EDIT_USER_TEAM' && (
							<DummyModal title={'Edit Team Role'} onClose={toggleModal}>
								<EditUserTeamRole
									Roles={Roles}
									TeamRoles={TeamRoles}
									team={editTeamRole.current}
									onCancel={toggleModal}
									onSubmit={TeamChangeHandler}
									actionLoading={error.actionLoading}
								/>
							</DummyModal>
						)}
					</>
				</Modal>
			) : (
				<></>
			)}
		</>
	);
}

export default Teams;
