// eslint-disable-next-line no-unused-vars
import * as clientActions from '../../redux/actions/client-actions';
import * as profileActions from '../../redux/actions/company-profile';
import { CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import SubmitButtonsField from '../Buttons/SubmitButtonsField';
import { useDispatch, useSelector } from 'react-redux';
import Select from '../Inputs/Select';
import EditableLocationText from '../Inputs/EditableLocationText';
import { Country , State }  from 'country-state-city';

function AddContact(props) {
	const dispatch = useDispatch();

	const form = useSelector((state) => state.clientReducer.addNewOffice);
	const editLocation = useSelector((state) => state.clientReducer.officeToEdit);
	const client = useSelector((state) => state.clientReducer.client);
	const countries = useSelector((state) => state.profileReducer.countries);
	const states = useSelector((state) => state.profileReducer.states);
	const companyProfileData = useSelector((state) => state.profileReducer.profileData);

	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);


	const handleChange = (event) => {
		event.preventDefault();
		const { name, value } = event.target;
		if (name == 'country') {
			if (!props?.beingEdited) {
				dispatch(clientActions.newOfficeFormChange({ name, value: value }));
				dispatch(clientActions.newOfficeFormChange({ name: 'state', value: '' }));
				dispatch(clientActions.newOfficeFormChange({ name: 'hq', value: '' }));
				dispatch(clientActions.newOfficeFormChange({ name: 'city', value: '' }));
				dispatch(clientActions.newOfficeFormChange({ name: 'zip', value: '' }));
				dispatch(clientActions.newOfficeFormChange({ name: 'street', value: '' }));
			} else {
				dispatch(clientActions.editOfficeFormChange({ name, value: value }));
				dispatch(clientActions.editOfficeFormChange({ name: 'state', value: '' }));
				dispatch(clientActions.editOfficeFormChange({ name: 'hq', value: '' }));
				dispatch(clientActions.editOfficeFormChange({ name: 'city', value: '' }));
				dispatch(clientActions.editOfficeFormChange({ name: 'zip', value: '' }));
				dispatch(clientActions.editOfficeFormChange({ name: 'street', value: '' }));
			}
			// const country = countryStatesDummy.filter((state) => state.value === value);
			// setStateData(country[0]);
			// dispatch(clientActions.editOfficeFormChange({ name:'country', value }));
		} else {
			if (!props?.beingEdited) {
				dispatch(clientActions.newOfficeFormChange({ name, value }));
			} else {
				if (!props?.beingEdited) {
					dispatch(clientActions.newOfficeFormChange({ name, value }));
				} else {
					dispatch(clientActions.editOfficeFormChange({ name, value }));
				}
			}
		}
	};

	const onAddressChange = (prop) => {
		if (!props?.beingEdited) {
			dispatch(clientActions.newOfficeFormChange({ name: 'hq', value: prop?.fullAddress }));
			dispatch(clientActions.newOfficeFormChange({ name: 'city', value: prop?.city }));
			dispatch(clientActions.newOfficeFormChange({ name: 'zip', value: prop?.zipCode }));
			dispatch(clientActions.newOfficeFormChange({ name: 'street', value: prop?.suite }));
			dispatch(clientActions.newOfficeFormChange({ name: 'state', value: prop?.state }));
		} else {
			dispatch(clientActions.editOfficeFormChange({ name: 'hq', value: prop?.fullAddress }));
			dispatch(clientActions.editOfficeFormChange({ name: 'city', value: prop?.city }));
			dispatch(clientActions.editOfficeFormChange({ name: 'zip', value: prop?.zipCode }));
			dispatch(clientActions.editOfficeFormChange({ name: 'street', value: prop?.suite }));
			dispatch(clientActions.editOfficeFormChange({ name: 'state', value: prop?.state }));
		}
	};

	const handleOnCancel = () => {
		props?.beingEdited
			? dispatch(clientActions.resetEditOfficeForm())
			: dispatch(clientActions.resetNewOfficeForm());
		props.onCancel();
	};

	const onLocationAddSuccess = () => {
		setError(null);
		setIsLoading(false);
		dispatch(clientActions.resetNewOfficeForm());
		props.onCancel();
	};
	const onLocationAddFailed = (error) => {
		setIsLoading(false);
		setError(error);
	};


	useEffect(() => {
        const clientCountry = editLocation?.country ? editLocation?.country : companyProfileData?.country;
        if (!props?.beingEdited) {
			dispatch(clientActions.newOfficeFormChange({ name: 'country', value: clientCountry }));
		} else {
			dispatch(clientActions.editOfficeFormChange({ name: 'country', value: clientCountry }));
		}
		
	}, [companyProfileData?.country]);

	useEffect(() => {
		dispatch(profileActions.getCountryStates(State.getStatesOfCountry(props.beingEdited ? editLocation?.country : form?.country)));
	}, [editLocation?.country , form?.country]);

	useEffect(() => {
        dispatch(profileActions.getAllCountries(Country.getAllCountries()));

        return(() => {
            dispatch(clientActions.resetEditOfficeForm());
			dispatch(clientActions.resetNewOfficeForm());
        });
	}, []);

	const handleSubmit = (event) => {
		event.preventDefault();
		setIsLoading(true);
		if (
			(form.hq,
			form.street,
			form.country,
			form.city,
			form.state,
			form.zip,
			!props?.beingEdited)
		) {
			form.client_id = client?.id;
			form.order = client?.client_office_locations?.length;
			dispatch(
				clientActions.addClientLocation(form, {
					onLocationAddSuccess,
					onLocationAddFailed,
				}),
			);
		} else if (
			(props?.beingEdited,
			editLocation.hq,
			editLocation.city,
			editLocation.street,
			editLocation.country,
			editLocation.zip,
			editLocation.state)
		) {
			const data = {
				id: editLocation?.id,
				client_id: editLocation?.client_id,
				hq: editLocation?.hq,
				city: editLocation?.city,
				street: editLocation?.street,
				country: editLocation?.country,
				zip: editLocation?.zip,
				state: editLocation?.state,
				order: editLocation?.length,
			};

			dispatch(
				clientActions.updateClientLocation(data, {
					onLocationAddSuccess,
					onLocationAddFailed,
				}),
			);
		}
	};

	return (
		<form autoComplete='off' className='ContactAdd-Form' onSubmit={handleSubmit}>
			<div className='p-3'>
				<div className='improved-input-designs pb-3'>
					<label className='require'>Country</label>
					<Select
						value={{ value: props?.beingEdited ? editLocation.country : form.country }}
						options={countries}
						name='country'
						onChange={handleChange}
						required
					/>
				</div>
				<div className='improved-input-designs pb-3'>
					<label className=''>Street</label>
					<EditableLocationText
						name='hq'
						placeSelected={onAddressChange}
						value={props?.beingEdited ? editLocation?.hq : form?.hq}
						onChange={handleChange}
						Edit={true}
						countryAbbr={props?.beingEdited ? editLocation?.country : form?.country}
					/>
				</div>
				<div className='improved-input-designs pb-3'>
					<label className=''>Suite/Floor</label>
					<input
						className='w-100'
						type='text'
						name='street'
						value={props?.beingEdited ? editLocation.street : form.street}
						onChange={handleChange}
					/>
				</div>
				<div className='improved-input-designs pb-3'>
					<label className=''>City/Town</label>
					<input
						className='w-100'
						type='text'
						name='city'
						value={props?.beingEdited ? editLocation.city : form.city}
						onChange={handleChange}
						// required
					/>
				</div>
				<div className='improved-input-designs pb-3'>
					<label className=''>State/Province/Region</label>
					<Select
						value={{ value: props?.beingEdited ? editLocation?.state : form?.state }}
						options={states}
						name='state'
						onChange={handleChange}
						// required={true}
					/>
				</div>
				<div className='improved-input-designs pb-3'>
					<label className=''>Zip Code/Postal Code</label>
					<input
						className='w-100'
						type='text'
						name='zip'
						value={props?.beingEdited ? editLocation.zip : form.zip}
						onChange={handleChange}
						// required
					/>
				</div>
			</div>
			{error && (
				<div className=' px-3 py-2 rounded-bottom'>
					<span className='error-text BodyTwoLight'>
						{error || 'Something went wrong'}
					</span>
				</div>
			)}
			<div className='VerticalSpacer bg-white p-3 rounded-bottom'>
				<SubmitButtonsField child={true}>
					<button
						id='Element'
						className='secondary'
						type='button'
						onClick={handleOnCancel}
					>
						Cancel
					</button>

					<button
						data-cy='submit-office-location'
						type='submit'
						className='d-flex align-items-center'
						id='Element'
						disabled={isLoading}
					>
						{isLoading ? (
							<CircularProgress size={24} sx={{ color: 'white' }} disableShrink />
						) : props?.beingEdited ? (
							'Update'
						) : (
							'Add'
						)}
					</button>
				</SubmitButtonsField>
			</div>
		</form>
	);
}

export default AddContact;
