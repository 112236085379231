import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
	clientPaymentModes,
	clientPaymentTypes,
	numberWithCommas,
	paymentTypesConstants,
} from '../../utilities/utilities';
import ButtonSquare from '../Buttons/ButtonSquare';
import SimpleButton from '../Buttons/SimpleButton';
import ProfileHeadingBar from '../Others/ProfileHeadingBar';
import Sidebar from '../sidebar/SideBar';
import InvoiceTable from './InvoiceTable';
import { CSVLink } from 'react-csv';
import { useEffect, useState } from 'react';
import * as clientActions from '../../redux/actions/client-actions';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { Box, CircularProgress } from '@mui/material';
import { lightGreen } from '../Style';
import useBreadCrumb from '../../hooks/useBreadCrumb';
import { routes } from '../../utilities/routes';
import { useHistory } from 'react-router';

function ClientInvoice() {
	const dispatch = useDispatch();
	const history = useHistory();
	const { BreadCrumbs } = useBreadCrumb();

	const [edit, setEdit] = useState(false);
	const client = useSelector((state) => state.clientReducer.client);
	const Loading = useSelector((state) => state.clientReducer.switchClientLoad);
	const billingDetails = useSelector(
		(state) => state.clientReducer.billingWorkflowTaskDetails,
	);

	const queryParams = new URLSearchParams(window.location.search);
	const fromParam = queryParams.get('from');
	const toParam = queryParams.get('to');
	const clientId = queryParams.get('client_id');

	const userTypes = JSON.parse(localStorage.getItem('tempArr') || '[]');

	const handleProfileClick = (item, index) => {
		const tempArr = [];
		userTypes.map((a, i) => {
			if (i < index) {
				tempArr.push(a);
			}
		});
		localStorage.setItem('tempArr', JSON.stringify(tempArr));

		const newtempArr = [{ url: `/clients`, name: 'Clients' }];
		localStorage.setItem('tempArr', JSON.stringify(newtempArr));

		history.push(`${item.url}`);
	};

	const calculateSubTotal = (billingDetail) => {
		let subTotal = 0;

		billingDetail.workflows?.map((workflow) => {
			const workflow_amount = workflow?.workflow_users
				?.filter((user) => user.is_selected)
				?.map((user) => parseFloat(user.charged_amount))
				?.reduce((total, current) => total + current, 0);
			subTotal = parseFloat(parseFloat(subTotal + workflow_amount).toFixed(2));
		});

		return subTotal;
	};

	const headers = [
		{ label: 'Workflow', key: 'workflow_name' },
		{ label: 'User', key: 'user_name' },
		{ label: 'Hours Spent', key: 'hours_spent' },
		{ label: 'Cumultative Sum', key: 'total_sum' },
	];

	const data = [];
	billingDetails?.length &&
		billingDetails?.forEach((billingDetail) => {
			billingDetail.workflows?.map((workflow) => {
				const workflow_amount = workflow?.workflow_users
					?.filter((user) => user.is_selected)
					?.map((user) => user.charged_amount)
					?.reduce((total, current) => total + current, 0);

				data.push({
					workflow_name: workflow?.workflow,
					user_name: '-',
					hours_spent:
						billingDetail?.payment_type === paymentTypesConstants.HOURLY
							? workflow.workflow_hours
							: '',
					total_sum:
						billingDetail?.payment_type === paymentTypesConstants.HOURLY
							? `$${workflow_amount}`
							: '',
				});

				workflow?.workflow_users
					?.filter((user) => user.is_selected)
					?.map((user) => {
						data.push({
							workflow_name: '',
							user_name: user?.full_name,
							hours_spent:
								billingDetail?.payment_type === paymentTypesConstants.HOURLY
									? user?.total_hours
									: '',
							total_sum:
								billingDetail?.payment_type === paymentTypesConstants.HOURLY
									? `$${user?.charged_amount}`
									: '',
						});
					});
			});

			data.push({
				workflow_name: 'Subtotal',
				user_name: '',
				hours_spent: '',
				total_sum:
					billingDetail?.payment_type === paymentTypesConstants.HOURLY
						? `$${calculateSubTotal(billingDetail)}`
						: `$${billingDetail?.total_amount}`,
			});
		});

	const csv_file_name =
		client?.dba?.trim() == ''
			? client?.company_name?.split(' ').join('_')
			: client?.dba?.split(' ').join('_');

	const updateChanges = () => {
		setEdit(false);
		dispatch(clientActions.clientUserInvoiceChangesDone());
	};

	const isValidDate = (date) => {
		return moment(date, 'MM-DD-YYYY', true).isValid();
	};

	const clientProfileNavigation = (clientId) => {
		history.push(`${routes['client-profile']}${clientId}`);
	};

	useEffect(() => {
		const areDatesValid = isValidDate(fromParam) && isValidDate(toParam);

		if (areDatesValid && clientId) {
			dispatch(clientActions.getClient(clientId));
			dispatch(
				clientActions.getClientInvoices(clientId, {
					from: fromParam,
					to: toParam,
				}),
			);
		}
		else {
			history.push(`/client-profile/${clientId}`);
			alert('Date is invalid!');
		}
		return () => {
			dispatch(clientActions.resetBillingWorkflowTaskDetails());
		};

	}, [dispatch, clientId]);


	return (
		<div className='AppBody'>
			<Sidebar title='Client Management'></Sidebar>

			<div className='Client-Profile d-flex flex-column'>
				<div
					className='w-100 d-flex flex-column'
					style={{ padding: '8px 32px', gap: '16px' }}
				>
					<ProfileHeadingBar
						navBack={
							<div>
								<Box
									className='py-2'
									sx={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
										':hover': {
											cursor: 'pointer',
										},
										color: `${lightGreen}`,
									}}
								>
									<ArrowLeftIcon />
									<BreadCrumbs>
										<h2
											className='HeadlineThreeBook text-left'
											onClick={() => clientProfileNavigation(client.id)}
										>
											{client?.dba?.trim() == '' ? client?.company_name : client?.dba}
										</h2>
									</BreadCrumbs>
								</Box>
							</div>
						}
						heading={'Generate Invoice'}
					>
						{!edit && (
							<SimpleButton
								text={
									<CSVLink
										data={data}
										headers={headers}
										filename={`${csv_file_name}.csv`}
										className='csv-link'
									>
										Export Csv
									</CSVLink>
								}
							/>
						)}
						<ButtonSquare onClick={edit ? updateChanges : () => setEdit(true)}>
							{edit ? 'Done' : 'Edit'}
						</ButtonSquare>
					</ProfileHeadingBar>
					{billingDetails?.length ? (
						billingDetails?.map((billing, index) => {
							return (
								<>
									<div className='d-flex justify-content-between pt-2'>
										<div
											className='d-flex flex-column'
											style={{ padding: '0 16px 0 16px' }}
										>
											{billing?.payment_type != 'hourly' && (
												<div className='d-flex justify-content-start'>
													<span className=' text-left'>
														<span className='BodyTwoBold'>Payment Mode: </span>
														<span className='BodyTwoLight text-left'>
															{
																clientPaymentModes?.find(
																	(item) => item.value == billing?.payment_mode,
																)?.label
															}
														</span>
													</span>
												</div>
											)}
											<div className='d-flex justify-content-start'>
												<span className=' text-left'>
													<span className='BodyTwoBold'>Payment Type: </span>
													<span className='BodyTwoLight text-left'>
														{
															clientPaymentTypes?.find(
																(item) => item.value == billing?.payment_type,
															)?.label
														}
													</span>
												</span>
											</div>
											<div
												className='d-flex justify-content-start'
												style={{ gap: '16px' }}
											>
												<span className=' text-left'>
													<span className='BodyTwoBold'>From: </span>
													<span className='BodyTwoLight text-left'>
														{moment
															.utc(billing?.from_date)
															.format('MMMM DD, YYYY')}
													</span>
												</span>

												<span className=' text-left'>
													<span className='BodyTwoBold'>To: </span>
													<span className='BodyTwoLight text-left'>
														{moment
															.utc(billing?.to_date)
															.format('MMMM DD, YYYY')}
													</span>
												</span>
											</div>{' '}
										</div>
									</div>
									<InvoiceTable
										edit={edit}
										billingDetails={billing}
										billingIndex={index}
										headers={[
											'Workflow',
											'User',
											'Hours Spent',
											'Cumultative Sum',
										]}
									/>

									<div className='total-amount'>
										<div
											className='d-flex flex-column align-items-start'
											style={{ gap: '4px' }}
										>
											<span className='HeadlineThreeBold dark-text'>
												Services Subtotal
											</span>
											<span className='errorMessage light-text '>
												{`${
													billing?.payment_type ==
													paymentTypesConstants.RETAINER
														? clientPaymentModes?.find(
																(item) => item.value == billing?.payment_mode,
														)?.label
														: 'Hourly'
												} rate for this client is $${numberWithCommas(billing?.rate)} USD`}
											</span>
										</div>

										<div className='BodyTwoBold'>{`$${
											billing?.payment_type == paymentTypesConstants.HOURLY
												? numberWithCommas(calculateSubTotal(billing))
												: numberWithCommas(billing?.total_amount)
										}`}</div>
									</div>
								</>
							);
						})
					) : (
						<div>
							<span className='BodyOneBold'>No billing data</span>
						</div>
					)}
				</div>
				{Loading && (
					<div
						style={{
							height: '100%',
							width: 'inherit',
							backgroundColor: 'rgba(0, 0, 0, 0.05)',
							position: 'fixed',
							top: 0,
							bottom: 0,
						}}
					>
						<CircularProgress
							style={{
								color: '#fa7b03',
								height: '50px',
								width: '50px',
								marginTop: '47vh',
							}}
						/>
					</div>
				)}
			</div>
		</div>
	);
}

export default ClientInvoice;
