import { useSelector } from 'react-redux';
import { TASK_STATUSES, convertDecimalToTime } from '../../utilities/utilities';
import moment from 'moment';
import { Tooltip } from '@mui/material';
import { useMemo } from 'react';
import { getUserSettings } from '../../hooks/getUserSettings';

function TimeTrackingTab({ taskDetails }) {
	const user = useSelector((state) => state.authReducer.user);
	const userDetails = useSelector((state) => state.authReducer.userDetails);
	const { date_format } = getUserSettings(userDetails);

	const headers = ['User Name', 'Date', 'Logged Time (HH:MM)', 'Time Zone'];

	const totalTime = useMemo(() => {
		let sum = 0;

		for (let i = 0; i < taskDetails?.task_timers?.length; i++) {
			const timeStr = taskDetails?.task_timers[i].consumed_time;
			const timeValue = parseInt(timeStr);
			sum += timeValue;
		}

		return sum;
	}, [taskDetails?.task_timers]);

	return (
		<div className='w-100 h-100' style={{ padding: '10px', overflow: 'hidden' }}>
			{(taskDetails &&
				// !taskDetails?.is_tentative &&
				taskDetails?.task_timers?.length > 0 &&
				taskDetails?.status !== TASK_STATUSES?.APPROVAL_PENDING &&
				taskDetails?.status !== TASK_STATUSES?.BLOCKED) ||
			taskDetails?.status === 'APPROVAL_PENDING' ||
			(taskDetails?.status == TASK_STATUSES?.COMPLETED &&
				user.userId === taskDetails?.approval_from) ? (
				<div
					style={{ gap: '10px', height: '100%' }}
					className='d-flex flex-column align-items-start w-100 h-100'
				>
					<span
						className='BodyTwoLight'
						style={{
							color: '#7C7C7C',
						}}
					>
						{taskDetails?.task_timers?.length > 0 ? (
							<>
								Total time spent on this task:{' '}
								<span className='BodyTwoBold pt-1'>
									{convertDecimalToTime(totalTime / 3600000)}
								</span>
								{/* <span className='BodyTwoBold pt-1'>
									{`${totalConsumedTimeHours} : ${totalRoundedMinutes
										.toString()
										.padStart(2, '0')}`}
								</span> */}
							</>
						) : (
							<>No time logged yet</>
						)}
					</span>
					<div
						style={{
							width: '100%',
							overflowY: 'scroll',
							// border: '2px solid red',
							height: 'calc(100%)',
						}}
					>
						<table style={{ width: '100%' }}>
							<thead className='tracking-table-header'>
								<tr>
									{headers?.map((header, index) => {
										return (
											<th
												key={index}
												className='BodyTwoLight m-0 text-left light-text'
											>
												{header}
											</th>
										);
									})}
								</tr>
							</thead>
							<tbody className='tracking-table-body'>
								{taskDetails?.task_timers &&
									taskDetails?.task_timers?.map((timer, key) => {
										const consumedTimeHours = Math.floor(
											timer?.consumed_time / 3600000,
										)
											.toString()
											.padStart(2, '0');
										const consumedTimeMinutes = Math.floor(
											(timer?.consumed_time % 3600000) / 60000,
										);
										const seconds = Math.floor(
											(timer?.consumed_time % 60000) / 1000,
										);
										let roundedMinutes = consumedTimeMinutes;

										if (consumedTimeMinutes >= 1) {
											roundedMinutes += Math.round(seconds / 60);
										}

										return (
											<tr key={key} className=''>
												<td className='BodyTwoLight text-left'>
													{timer?.user_profile?.full_name}
												</td>
												<td className='BodyTwoLight text-left'>
													{moment(timer?.logged_date).format(date_format)}
												</td>

												<td className='BodyTwoLight text-left'>
													<Tooltip
														title={`${consumedTimeHours} : ${consumedTimeMinutes
															.toString()
															.padStart(2, '0')} : ${seconds
															.toString()
															.padStart(2, '0')} `}
														placement='right'
													>
														{convertDecimalToTime(
															timer?.consumed_time / 3600000,
														)}{' '}
													</Tooltip>
												</td>
												<td className='BodyTwoLight text-left'>
													{timer.timezone}
												</td>
											</tr>
										);
									})}
							</tbody>
						</table>
					</div>
				</div>
			) : (
				<div className='BodyTwoLight'>No time logged yet</div>
			)}
		</div>
	);
}

export default TimeTrackingTab;
