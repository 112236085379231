/* eslint-disable no-mixed-spaces-and-tabs */
import React from 'react';
import NewWorkflowCard from './NewWorkflowCard';
import { useSelector } from 'react-redux';
import { isTeamManager } from '../../utilities/utilities';
import moment from 'moment';

export default function CompanyLibrary({
	companyWorkflows,
	profileData,
	handleCheckBoxes,
	isChecked,
	setActiveTab,
	publishWorkflows,
	handleSelectClient,
	archivePublishedWorkflows,
	handleAddToClient,
	groupByCategory,
}) {

	const user = useSelector((state) => state.authReducer.user);
	let companyLibraryWorkflows = [];

	if (companyWorkflows?.length > 0) {
		companyLibraryWorkflows = companyWorkflows
			.map((category) => {
				const filteredWorkflows = category?.workflows?.filter(
					(workflow) => workflow?.status !== 'DRAFT',
				);

				if (!isTeamManager(user?.user_roles?.map((item) => item.role_id))) {
					return category;
				}

				return filteredWorkflows.length > 0
					? { ...category, workflows: filteredWorkflows }
					: null;
			})
			.filter((category) => category !== null);
	}

	if (companyLibraryWorkflows?.length > 0 && !groupByCategory) {
		companyLibraryWorkflows = companyLibraryWorkflows
			.flatMap((category) =>
				category?.workflows?.map((workflow) => ({
					...workflow,
					category_name: category?.name,
				})),
			)
			.sort((a, b) => moment(b.updated_at).diff(moment(a.updated_at)));
	}
    
	return (
		<>
			{companyLibraryWorkflows?.length > 0 ? (
				groupByCategory ? (
					companyLibraryWorkflows?.map((category, index) => {
						return (
							<div key={index} style={{ marginTop: '24px' }}>
								<div
									className='workflow-categories-header'
									style={{ marginBottom: '18px' }}
								>
									<span className='HeadlineThreeBold'>{category?.name}</span>
								</div>
								<div className='workflow-categories-body container-fluid'>
									<div className='row w-100 '>
										{category?.workflows?.length > 0 &&
											category?.workflows?.map((workflow, innerIndex) => {
												return (
													<NewWorkflowCard
														key={innerIndex}
														workflow={workflow}
														profileData={profileData}
														companyLibrary={true}
														handleCheckBoxes={handleCheckBoxes}
														isChecked={isChecked}
														categoryId={category?.id}
														categoryName={category?.name}
														publishWorkflows={publishWorkflows}
														archivePublishedWorkflows={
															archivePublishedWorkflows
														}
														handleSelectClient={handleSelectClient}
														handleAddToClient={handleAddToClient}
													/>
												);
											})}
									</div>
								</div>
							</div>
						);
					})
				) : (
					<div className='d-flex flex-wrap' style={{ marginTop: '24px' }}>
						{companyLibraryWorkflows?.map((workflow, innerIndex) => {
							return (
								<NewWorkflowCard
									key={innerIndex}
									workflow={workflow}
									profileData={profileData}
									companyLibrary={true}
									handleCheckBoxes={handleCheckBoxes}
									isChecked={isChecked}
									categoryId={workflow?.category_id}
									categoryName={workflow?.category_name}
									publishWorkflows={publishWorkflows}
									archivePublishedWorkflows={archivePublishedWorkflows}
									handleSelectClient={handleSelectClient}
									handleAddToClient={handleAddToClient}
								/>
							);
						})}
					</div>
				)
			) : (
				<div className='workflow-bar d-flex justify-content-center mt-4'>
					<span className='BodyTwoLight' style={{ color: '#696F6C' }}>
						No workflow in Company Library yet. Select a workflow template from the{' '}
						<span
							onClick={() => setActiveTab('PREBUILT_LIBRARY')}
							style={{ color: '#fb7a03', cursor: 'pointer' }}
						>
							{'Levvy Prebuilt Library'}
						</span>
					</span>
				</div>
			)}
		</>
	);
}
