import { Dialog, Zoom } from '@mui/material';
import React, { useState } from 'react';
import { Input } from '../../Inputs/Input';
import FormButtonOne from '../../Buttons/FormButtonOne';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Zoom in={true} ref={ref} {...props} style={{ transitionDelay: '50ms' }} />;
});

function DeclineInviteModal({ open, closeHandler, declineInvite, loading }) {
	const [reason, setReason] = useState('');

	const handleChange = (event) => {
		const value = event.target.value;
		setReason(value);
	};
	return (
		<Dialog
			PaperProps={{ style: { width: 448, height: 280 } }}
			TransitionComponent={Transition}
			open={open}
			onClose={closeHandler}
		>
			<div className='my-task-delete-alert d-flex flex-column justify-content-between h-100'>
				<div className='content d-flex flex-column' style={{ padding: 24, gap: 24 }}>
					<div
						className='HeadlineTwoBold dark-text form-title'
						style={{ lineHeight: '1.6' }}
					>
						Decline Invite
					</div>
					<div className='d-flex flex-column w-100'>
						<div className='BodyTwoLight dark-text text-left pb-1'>
							Reason for declining the meeting
						</div>
						<textarea
							rows={3}
							value={reason}
							onChange={handleChange}
                            className='BodyTwoLight'
                            style={{
                                border: 'none',
                                resize: 'none',
                                padding: '8px'
                            }}
							placeholder='Type your reason here...'
						/>
					</div>
				</div>
				<div className='action-buttons'>
					<FormButtonOne
						onClick={closeHandler}
						type='button'
						className='secondary-btn-light rounded-btn'
					>
						Cancel
					</FormButtonOne>

					<FormButtonOne
						onClick={() => declineInvite(reason)}
						type='button'
						loading={loading}
                        className='primary-btn rounded-btn'
					>
						Confirm
					</FormButtonOne>
				</div>
			</div>
		</Dialog>
	);
}

export default DeclineInviteModal;
