/* eslint-disable no-mixed-spaces-and-tabs */
import { useEffect, useState } from 'react';

// Styled Components
import { lightGreen, backgroundGrey } from '../Style.jsx';

// Components
import ButtonSquare from '../Buttons/ButtonSquare.jsx';
import SimpleButton from '../Buttons/SimpleButton.jsx';

// Material Ui Components
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { Box, CircularProgress, Modal } from '@mui/material';

import {
	executivePermissions,
	Member,
	standardPermissions,
} from '../../utilities/utilities.js';

import { useDispatch, useSelector } from 'react-redux';
import * as userActions from '../../redux/actions/user-management.actions';
import SubmitButtonsField from '../Buttons/SubmitButtonsField.jsx';
import Restricted from '../Permissions/Restricted.jsx';
import { useHistory } from 'react-router';
import DummyModal from '../Modal/DummyModal.jsx';
import ArchiveModal from '../Modal/ArchiveModal.jsx';
import SkeletonLoading from '../Others/SkeletonLoading.jsx';
import HeadingTwo from '../TypoGraphy/HeadingTwo.jsx';
import useBreadCrumb from '../../hooks/useBreadCrumb.jsx';
import { routes } from '../../utilities/routes.js';

function ProfileBar({ BarText, accountSettings, backToList }) {
	const dispatch = useDispatch();
	const history = useHistory();
	const { BreadCrumbs } = useBreadCrumb();

	const [archiveModalIsOpen, setArchiveModalIsOpen] = useState(false);
	const [archiveUser, setArchiveUser] = useState();

	const user = useSelector((state) => state.authReducer.user);
	const Profile = useSelector((state) => state.userReducer.userProfile); //User Profile
	const edit = useSelector((state) => state.userReducer.profileInfoEdit); //User ProfileEdit
	const Teams = useSelector((state) => state.userReducer.userTeams);
	const loading = useSelector((state) => state.userReducer.profileLoading);
	const TeamRoles = useSelector((state) => state.userReducer.userRoles); // Total User Roles

	const clients = useSelector((state) => state.clientReducer.clients);

	const affectedTasks = useSelector((state) => state.userReducer.affectedTasks);

	let affectedClients = clients?.filter((client) =>
		affectedTasks?.find((task) => task.client_id === client.id),
	);

	affectedClients = affectedClients?.map((client) => {
		return client?.company_name;
	});

	const toggleArchiveModal = (user) => {
		user?.id && dispatch(userActions.getAffectedClients(user?.id));
		setArchiveModalIsOpen(!archiveModalIsOpen);
		setArchiveUser(user?.full_name);
	};

	const onDelete = () => {
		dispatch(userActions.deleteUser(Profile.id));
		dispatch(userActions.toggleProfileInfoEdit(false));
		toggleArchiveModal(false);
		backToList();
	};

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'flex-start',
				width: accountSettings && '100%',
			}}
		>
			{!accountSettings && (
				<Box
					className='py-2'
					sx={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						':hover': {
							cursor: 'pointer',
						},
						color: `${lightGreen}`,
					}}
				>
					<ArrowLeftIcon />
					<BreadCrumbs>
						<h2
							onClick={() => history.push(routes.users)}
							className='HeadlineThreeBook text-left'
						>
							Users
						</h2>
					</BreadCrumbs>
				</Box>
			)}
			<Box
				sx={{
					backgroundColor: '#FCFDFD',
					width: '100%',
					padding: '20px 18px',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
					gap: '1.5em',
					boxSizing: 'border-box',
					borderRadius: '4px 4px 0px 0px',
				}}
			>
				<Box>
					<SkeletonLoading
						loading={loading}
						variant='text'
						width={100}
						height={45}
					>
						<HeadingTwo>{BarText}</HeadingTwo>
					</SkeletonLoading>
				</Box>
				<Box
					sx={{
						width: 'fit-content',
						display: 'flex',
						alignItems: 'end',
						gap: '0.5em',
					}}
				>
					{!accountSettings && (
						<Restricted
							AllowedUsers={
								TeamRoles?.some((item) => item.role_id === Member.ownerId)
									? []
									: TeamRoles?.some(
											(item) => item.role_id === Member.supervisorId,
									  )
									? [Member.ownerId]
									: executivePermissions
							}
						>
							<SkeletonLoading
								loading={loading}
								variant='text'
								width={100}
								height={45}
							>
								<span
									className='BodyTwoLight primary-text pointer'
									data-cy='archive-user-opener'
									onClick={() => toggleArchiveModal(Profile)}
								>
									Archive User
								</span>
							</SkeletonLoading>
						</Restricted>
					)}
					{/* {edit ? (
						<SubmitButtonsField child={true}>
							<button
								type='submit'
								className='d-flex align-items-center'
								id='Element'
								disabled={loading}
								onClick={UpdateChanges}
								data-cy='user-profile-done'
							>
								{loading ? (
									<CircularProgress
										size={24}
										sx={{ color: 'white' }}
										disableShrink
									/>
								) : (
									'Done'
								)}
							</button>
						</SubmitButtonsField>
					) : (
						<Restricted
							AllowedUsers={
								TeamRoles?.some((item) => item.role_id === Member.ownerId)
									? []
									: TeamRoles?.some(
										(item) => item.role_id === Member.supervisorId,
									)
										? [Member.ownerId]
										: standardPermissions
							}
							userTeamRole={Profile?.user_teams?.map((team) => {
								return user?.user_roles?.find((item) =>
									item.role_scopes
										.map((role) => role.team_id)
										.includes(team.team_id),
								)?.role_id;
							})}
						>
							<ButtonSquare clickHandler={EditProfile}>
								Edit User
							</ButtonSquare>
						</Restricted>
					)} */}
				</Box>
			</Box>
			{archiveModalIsOpen && (
				<Modal
					onClose={toggleArchiveModal}
					open={archiveModalIsOpen}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<DummyModal
						title='Archive User'
						onClose={toggleArchiveModal}
						warningIcon={true}
					>
						<ArchiveModal
							archiveUser={archiveUser}
							title='Affected Clients List'
							totalTasks={affectedTasks?.length}
							list={affectedClients}
							onCancel={toggleArchiveModal}
							onArchive={onDelete}
						/>
					</DummyModal>
				</Modal>
			)}
		</Box>
	);
}

export default ProfileBar;
