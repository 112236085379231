import { ReactComponent as SaveArrowDown } from '../../assets/icons/SaveArrowDown.svg';
import { Box, CircularProgress } from '@mui/material';
import useBreadCrumb from '../../hooks/useBreadCrumb';
import { useHistory } from 'react-router';
import { lightGreen } from '../Style';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';

function InvocieTopBar({
	Title,
	btnText,
	btnText2,
	save,
	InvoiceNo,
	GenerateOnClick,
	textTransparent,
	onSave,
	isLoading,
	Draft,
	disabled,
}) {
	const { BreadCrumbs } = useBreadCrumb();
	const history = useHistory();

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'flex-start',
				// width: accountSettings && '100%',
			}}
		>
			{!textTransparent && <Box
				className='py-2'
				sx={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					':hover': {
						cursor: 'pointer',
					},
					color: `${lightGreen}`,
				}}
			>
				<ArrowLeftIcon />
				<BreadCrumbs>
					<h2
						onClick={() => history.push('/invoices')}
						className='HeadlineThreeBook text-left'
					>
						Invoices
					</h2>
				</BreadCrumbs>
			</Box>}
			<div className='Zapier-Integration-TopBar' style={{ width: '100%' }}>
				<div className='d-flex align-items-center'>
					<span className='HeadlineTwoBold'>{InvoiceNo}</span>
					{Draft && (
						<div
							className='ml-3'
							style={{
								backgroundColor: '#F2BE1B',
								height: '32px',
								width: 'auto',
								gap: '24px',
								borderRadius: '20px',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								padding: '0px 10px',
								minWidth: '80px',
							}}
						>
							<h6 className='HeadlineThreeBold' style={{ color: 'white' }}>
								{Title || ''}
							</h6>
						</div>
					)}
				</div>
				<div className='d-flex'>
					{save ? (
						<div
							className='d-flex'
							style={{
								width: '400px',
								alignItems: 'center',
								gap: '24px',
								justifyContent: 'end',
							}}
						>
							<button
								className='d-flex justify-content-center'
								style={{
									background: textTransparent ? 'transparent' : '#FB7A03',
									color: textTransparent ? '#FB7A03' : 'white',
									justifyContent: 'space-between',
									alignItems: 'center',
									border: 'none',
									borderRadius: '40px',
									padding: '5px 20px',
									width: '88px',
                                    opacity: isLoading ? 0.5 : 1
								}}
								onClick={onSave}
								disabled={isLoading}
							>
								{isLoading ? (
									<CircularProgress
										size={22}
										sx={{ color: 'white' }}
										disableShrink
									/>
								) : (
									<>
										<p className='BodyTwoBold pt-1'>{btnText}</p>
										{/* <div style={{ marginLeft: '5px' }}>
											<SaveArrowDown />
										</div> */}
									</>
								)}
							</button>
							<button
								className='rounded-button d-flex'
								onClick={GenerateOnClick}
								disabled={disabled || isLoading}
								style={{ opacity: (disabled || isLoading) ? 0.5 : 1 }}
							>
								<p className='BodyTwoBold pt-1'>{btnText2}</p>
							</button>
						</div>
					) : (
						<></>
					)}
				</div>
			</div>
		</Box>
	);
}

export default InvocieTopBar;
