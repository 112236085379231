import React from 'react';

export function Input({
	label,
	name,
	style,
	type,
	value,
	onChange,
	Class,
	disabled,
	placeholder,
	labelClass,
	required,
	id
}) {
	return (
		<div className='ProfilePage d-flex flex-column w-100'>
			<label className={`text-left ${labelClass} ${required && 'require'}`}>{label}</label>
			<input
				id={id}
				className={Class}
				name={name}
				style={style}
				type={type}
				value={value}
				onChange={onChange}
				placeholder={placeholder}
				disabled={disabled && disabled}
			/>
		</div>
	);
}
