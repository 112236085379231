import * as Actions from '../actions/subscription-actions';

const initialState = {
	stripe_products: [],
	subscription_proration: null,

	selected_pricing_id: null,

	loading_products: false,
	loading_proration: false,
	error: '',
	couponDetails: null,
	laoding: true,
};

const SubscriptionReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case Actions.HANDLE_LOADING_PRODUCTS:
			return (state = {
				...state,
				loading_products: payload,
			});
		case Actions.HANDLE_LOADING_PRORATION:
			return (state = {
				...state,
				loading_proration: payload,
			});
		case Actions.SET_ERROR:
			return (state = {
				...state,
				error: payload,
			});
		case Actions.SET_LOADING:
			return (state = {
				...state,
				loading: payload,
			});
		case Actions.SET_COUPON_DETAILS:
			return (state = {
				...state,
				couponDetails: payload,
			});
		case Actions.HANDLE_GET_STRIPE_PRODUCTS:
			return (state = {
				...state,
				loading_products: false,
				stripe_products: payload,
			});

		// case Actions.HANDLE_STRIPE_SUBSCRIPTION_PRORATION:
		// 	return (state = {
		// 		...state,
		// 		loading_proration: false,
		// 		subscription_proration: payload
		// 	});

		case Actions.HANDLE_SELECTED_PRICING:
			return (state = {
				...state,
				selected_pricing_id: payload,
			});

		// case Actions.LOGIN_FORM_CHANGE:
		// 	return (state = {
		// 		...state,
		// 		loginForm: {
		// 			...state.loginForm,
		// 			[payload.name]: payload.value,
		// 		},
		// 	});

		default:
			return state;
	}
};

export default SubscriptionReducer;
