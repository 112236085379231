import { ReactComponent as CloseIcon } from '../../assets/icons/close-primary.svg';
import { IconButton } from '@mui/material';

function ModalHeader({ title, closeHandler, children }) {
    return (
        <div className='new-modal-header'>
            <div className='header-title'>
                {children}
                <h2 className='HeadlineTwoBold'>{title}</h2>
            </div>
            <IconButton onClick={closeHandler} className='CloseButton transparent'>
                <CloseIcon />
            </IconButton>
        </div>
    );
}

export default ModalHeader;
