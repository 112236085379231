import React, { useEffect, useState, memo } from 'react';

// Material Ui Components
import { Box } from '@mui/material';
import { CircularProgress } from '@mui/material';

// Importing Components
import SubmitButtonsField from '../Buttons/SubmitButtonsField';
import { capitalize } from '../../utilities/utilities';

function UserAdd({
	onCancel,
	value,
	submit,
	type,
	selectedID,
	response,
	handleEnterKey,
}) {
	const InputBgColor = '#F6F5F2';
	const Style = {
		backgroundColor: 'white',
		border: 'none',
		borderBottom: '1px solid #28383C',
	};

	const [FormFields, setFormFields] = useState(value);
	const FormChange = (e) => {
		setFormFields({
			...FormFields,
			[e.target.name]: e.target.value,
		});
	};

	const [Load, setLoad] = useState(false);
	const [Require, setRequire] = useState(false);

	const checkInputs = (type, selectedID, event) => {
		event.preventDefault();

		setLoad(true);

		if (FormFields.name == '') {
			setRequire(true);
			setLoad(false);
		} else {
			setRequire(false);
			FormFields.name = capitalize(FormFields.name);
			FormFields.description = capitalize(FormFields.description);
			submit(type, selectedID, FormFields);
		}
	};

	useEffect(() => {
		setLoad(false);
	}, [response]);

	return (
		<form
			autoComplete='off'
			onSubmit={(event) => checkInputs(type, selectedID, event)}
		>
			<div className='p-3'>
				<div className='improved-input-designs pb-3'>
					<label className='require w-100'>Team Name</label>
					<input
						id='name'
						className='w-100'
						type='text'
						name='name'
						onChange={FormChange}
						value={FormFields.name}
						style={Style}
						required
					/>
				</div>

				<div className='improved-input-designs pb-3'>
					<label className=''>Description</label>
					<input
						id='description'
						className='w-100'
						type='text'
						name='description'
						style={Style}
						onChange={FormChange}
						value={FormFields.description}
					/>
				</div>

				{Require && (
					<div className='improved-input-designs'>
						<span className='error-text BodyTwoLight'>
							Team Name is required
						</span>
					</div>
				)}
			</div>

			<div className='VerticalSpacer bg-white p-3 rounded-bottom'>
				<SubmitButtonsField child={true}>
					<button
						id='Element'
						type='button'
						className='secondary'
						onClick={onCancel}
					>
						Cancel
					</button>

					<button
						className='d-flex align-items-center'
						id='Element'
						type='submit'
						disabled={Load}
					>
						{Load ? (
							<CircularProgress
								size={24}
								sx={{ color: 'white' }}
								disableShrink
							/>
						) : (
							'Submit'
						)}
					</button>
				</SubmitButtonsField>
			</div>
		</form>
	);
}

export default memo(UserAdd);
