/* eslint-disable no-mixed-spaces-and-tabs */
import moment from 'moment';
import store from '../index';
import * as workSpaceActions from '../redux/actions/dashboard/workspaces-actions';
import * as authActions from '../redux/actions/auth-actions';
import { SERVER_SENT_EVENTS, TASK_TYPE } from '../utilities/utilities';

export const evnetParseHandler = (res) => {
	const response = JSON.parse(res.data);
	const { event, data } = response;
	switch (event) {
		case SERVER_SENT_EVENTS.COMMENT_CREATED:
			commentCreatedHandler(data);
			break;
		case SERVER_SENT_EVENTS.TASK_UPDATED:
			taskUpdateHandler(data);
			break;
		case SERVER_SENT_EVENTS.TASK_ASSIGNEE_UPDATED:
			taskAssigneeHandler(data);
			break;
		case SERVER_SENT_EVENTS.TASK_ASSIGNEE_DELETED:
			taskAssigneeRemoveHandler(data);
			break;
		case SERVER_SENT_EVENTS.TASK_ACTIVITIES:
			taskActivityHandler(data);
			break;
		case SERVER_SENT_EVENTS.TASK_STATUS_UPDATED:
			taskStatusUpdate(data);
			break;
		case SERVER_SENT_EVENTS.TASK_DELETED:
			taskDeleteHandler(data);
			break;
		case SERVER_SENT_EVENTS.USER_ACTIVITY:
			userActivityHandler(data);
			break;
		case SERVER_SENT_EVENTS.TASK_TIMER_UPDATED:
			updateTaskTimer(data);
			break;
		default:
			break;
	}
};

export const commentCreatedHandler = (data) => {
	store.dispatch(workSpaceActions.postComment(data));
};

export const taskUpdateHandler = (data) => {
	store.dispatch(workSpaceActions.updateTaskInfo(data));
	if (data[0]?.task_type == TASK_TYPE.MEETING) {
		store.dispatch(workSpaceActions.updateMeetingTasks(data));
	} else {
		store.dispatch(workSpaceActions.updateWeeklyTaskInfo(data));
	}
};

export const taskDeleteHandler = (data) => {
	if (data?.my_task?.task_type == TASK_TYPE.MEETING) {
		store.dispatch(workSpaceActions.filterMeetingTaskFromWeeklyArray(data));
	} else store.dispatch(workSpaceActions.filterTaskFromWeeklyArray(data));
};

export const taskAssigneeHandler = (data) => {
	store.dispatch(workSpaceActions.updateTaskAssignee(data));
	store.dispatch(workSpaceActions.updateWeeklyClientTaskAssignee(data));
};

export const taskAssigneeRemoveHandler = (data) => {
	store.dispatch(workSpaceActions.removeTaskAssignee(data));
	store.dispatch(workSpaceActions.removeWeeklyClientTaskAssignee(data));
};

export const taskActivityHandler = (data) => {
	const clientId = data?.task?.client_id;
	const [task_id, user_ids] = Object.entries(data?.data)[0] || [];
	let parsedUserIds = [];
	if (user_ids) {
		if (typeof user_ids === 'string') {
			parsedUserIds = Object.keys(JSON.parse(user_ids)).map(Number);
		} else {
			parsedUserIds = Object.keys(user_ids).map(Number);
		}
	}
	const result = {
		task_id: task_id,
		user_ids: parsedUserIds,
		client_id: clientId,
	};
	store.dispatch(workSpaceActions.setActiveUser(result));
};

export const taskStatusUpdate = (data) => {
	store.dispatch(workSpaceActions.setTaskStatus(data));
};

export const userActivityHandler = (data) => {
	const [tenant_id, user_ids] = Object.entries(data?.data)[0] || [];
	let parsedUserIds = [];
	let parsedUserTimestamps = [];
	if (user_ids) {
		if (typeof user_ids === 'string') {
			const usersData = JSON.parse(user_ids);
			parsedUserIds = Object.keys(usersData).map(Number);
			parsedUserTimestamps = Object.values(usersData).map((timestampString) =>
				moment(timestampString),
			);
		} else {
			parsedUserIds = Object.keys(user_ids).map(Number);
			parsedUserTimestamps = Object.values(user_ids).map((timestampString) =>
				moment(timestampString),
			);
		}
	}
	const result = {
		tenant_id: Number(tenant_id),
		users: parsedUserIds.map((user_id, index) => ({
			user_id: user_id,
			timeStamp: parsedUserTimestamps[index],
		})),
	};
	store.dispatch(authActions.setUserOnlineStatus(result));
};

export const updateTaskTimer = (data) => {
	store.dispatch(workSpaceActions.updateTaskTimer(data));
};
