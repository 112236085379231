import SimpleText from '../TextViews/SimpleText';
import NewSearchDropdown from './NewSearchDropdown';

export function EditableSearchDropDown({
	Edit,
	label,
	name,
	options,
	value,
	onChange,
	required,
	tooltipTitle,
	searchLabel,
	selectClassName,
	placeholder,
	toggleCustom,
	disableNull,
	width
}) {
	return (
		<div
			className='ProfilePage'
			data-toggle='tooltip'
			data-placement='top'
			title={tooltipTitle}
		>
			{Edit ? (
				<div className='VerticalSpacer'>
					<div
						className='d-flex flex-row'
						style={{ justifyContent: 'space-between' }}
					>
						<label
							className={`${
								required ? 'require' : ''
							} BodyTwoLight text-capitalize`}
						>
							{label}
						</label>

						{toggleCustom && (
							<span
								onClick={toggleCustom}
								className='BodyTwoLight primary-text pointer text-capitalize'
							>
								Add Custom
							</span>
						)}
					</div>

					<NewSearchDropdown
						width={width}
						selectClassName={selectClassName}
						value={value?.value}
						placeholder={placeholder}
						changeHandler={onChange}
						options={options}
						name={name}
						label={searchLabel}
						required={required}
						disableNull={disableNull}
					/>
				</div>
			) : (
				<SimpleText
					label={label}
					name={name}
					value={
						value
							? options?.find((item) => item.value == value?.value)?.label
							: ''
					}
				/>
			)}
		</div>
	);
}
