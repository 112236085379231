// eslint-disable-next-line no-unused-vars
import { CircularProgress } from '@mui/material';
import React, { memo, useEffect, useState } from 'react';

function WorkflowAccountsPickList({ edit, value, options = [], onChange , disabled }) {
	const [isCheck, setIsCheck] = useState(value);
	const handleClick = (e) => {
		const { id, checked } = e.target;
		if (checked && id) {
			onChange([...isCheck, parseInt(id)], parseInt(id));
		} else {
			onChange(
				isCheck.filter((item) => item !== parseInt(id)),
				parseInt(id),
			);
		}
	};

	useEffect(() => {
		setIsCheck(value);
	}, [value?.length]);

	return (
		<>
			{edit ? (
				<>
					<div className='ProfilePage d-flex flex-column w-100'>
						<div
							className='d-flex flex-column'
							style={{
								maxHeight: '125px',
								overflow: 'auto',
								boxSizing: 'border-box',
								gap: '10px',
								padding: '4px'
							}}
						>
							{options?.map((choice, index) => {
								return (
									<div
										className='d-flex flex-row align-items-center pl-2'
										style={{ gap: '12px' }}
										key={index}
									>
										{choice?.loading ? (
											<CircularProgress
												size={18}
												sx={{ color: '#fb7a03' }}
												disableShrink
											/>
										) : (
											<input
												disabled={disabled}
												type='checkbox'
												className='multi-select-checkbox'
												id={choice.value}
												name={index}
												onChange={handleClick}
												checked={isCheck.includes(choice.value)}
											/>
										)}
										<span className='BodyTwoLight m-0 primary-text pt-1'>
											{choice.label}
										</span>
									</div>
								);
							})}

							{options?.length === 0 && (
								<h4
									style={{ overflow: 'hidden' }}
									className='BodyTwoLight placeholder-text'
								>
									no list
								</h4>
							)}
						</div>
					</div>
				</>
			) : (
				<div className='ProfilePage d-flex flex-column w-100'>
					<div
						className='d-flex flex-column'
						style={{
							maxHeight: '125px',
							overflow: 'auto',
							boxSizing: 'border-box',
							gap: '10px',
						}}
					>
						{options
							?.filter((o) => isCheck.includes(o.value))
							?.map((o, i) => {
								return (
									<h3
										key={i}
										className='BodyTwoLight m-0 primary-text text-left'
									>
										{o?.label}
									</h3>
								);
							})}
						{options?.filter((o) => isCheck.includes(o.value))?.length ===
							0 && (
							<h4
								style={{ overflow: 'hidden' }}
								className='BodyTwoLight placeholder-text'
							>
								no list
							</h4>
						)}
					</div>
				</div>
			)}
		</>
	);
}

export default memo(WorkflowAccountsPickList);
