import React, { useEffect } from 'react';
import moment from 'moment';
import { ReactComponent as LeftIcon } from '../../assets/icons/chevron-left-large.svg';
import { ReactComponent as RightIcon } from '../../assets/icons/chevron-right-large.svg';
import * as TimeReportsActions from '../../redux/actions/time-reports-actions-v2';
import { useDispatch, useSelector } from 'react-redux';
import SkeletonLoading from '../Others/SkeletonLoading';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { ReactComponent as CalendarIcon } from '../../assets/icons/Calendarcolor.svg';
import { useParams } from 'react-router';

function TimeReportsDateV2() {
	const dispatch = useDispatch();
	const loading = useSelector((state) => state.TimeReportsV2.loading);
	const start_date = useSelector((state) => state.TimeReportsV2.start_date);
	const end_date = useSelector((state) => state.TimeReportsV2.end_date);
	const monthlyView = useSelector((state) => state.TimeReportsV2.monthlyView);
	const dailyView = useSelector((state) => state.TimeReportsV2.dailyView);
	const { id } = useParams();

	function getDateFromLocalStorage() {
		const storedDate = localStorage.getItem(
			monthlyView ? 'TIME_REPORTS_MONTH_DATE' : 'TIME_REPORTS_DATE',
		);
		if (storedDate) {
			return dailyView ? moment(storedDate).isoWeekday(1) : storedDate;
		}
		const mondayDate = moment().isoWeekday(1);
		const currentDate = mondayDate.format('YYYY-MM-DD');
		localStorage.setItem(
			monthlyView ? 'TIME_REPORTS_MONTH_DATE' : 'TIME_REPORTS_DATE',
			currentDate,
		);
		return currentDate;
	}

	function getDates(date) {
		const start_date = dailyView
			? moment(date).format('YYYY-MM-DD')
			: monthlyView
			? moment(date).startOf('month').format('YYYY-MM-DD')
			: moment(date).startOf('isoWeek').format('YYYY-MM-DD');
		const end_date = dailyView
			? moment(date).format('YYYY-MM-DD')
			: monthlyView
			? moment(date).endOf('month').format('YYYY-MM-DD')
			: moment(date).endOf('isoWeek').format('YYYY-MM-DD');
		return { start_date, end_date };
	}

	const handleNavigation = (direction, date) => {
		if (direction == 'left') {
			const newDate = moment(date)
				.subtract(1, dailyView ? 'day' : monthlyView ? 'month' : 'week')
				.format('YYYY-MM-DD');
			const { start_date, end_date } = getDates(newDate);
			dispatch(TimeReportsActions.setDates(start_date, end_date));
			localStorage.setItem(
				monthlyView ? 'TIME_REPORTS_MONTH_DATE' : 'TIME_REPORTS_DATE',
				newDate,
			);
		}
		if (direction == 'right') {
			const newDate = moment(date)
				.add(1, dailyView ? 'day' : monthlyView ? 'month' : 'week')
				.format('YYYY-MM-DD');
			const { start_date, end_date } = getDates(newDate);
			dispatch(TimeReportsActions.setDates(start_date, end_date));
			localStorage.setItem(
				monthlyView ? 'TIME_REPORTS_MONTH_DATE' : 'TIME_REPORTS_DATE',
				newDate,
			);
		}
	};

	const handleDatePicker = (date) => {
		const { start_date, end_date } = getDates(date.format('YYYY-MM-DD'));
		dispatch(TimeReportsActions.setDates(start_date, end_date));
	};

	useEffect(() => {
		const date = getDateFromLocalStorage();
		const { start_date, end_date } = getDates(date);
		dispatch(TimeReportsActions.setDates(start_date, end_date));
	}, [dailyView, monthlyView]);

	return (
		<div
			className='d-flex align-items-center justify-content-between w-100'
			style={{
				backgroundColor: 'white',
				padding: '20px 18px',
				borderRadius: '4px 4px 0px 0px',
				gap: '1.5em',
				boxSizing: 'border-box',
			}}
		>
			<div className='d-flex justify-content-between w-100'>
				<SkeletonLoading
					loading={loading}
					variant='text'
					width='25%'
					height={45}
				>
					<div className='d-flex align-items-center' style={{ gap: '2em' }}>
						<div className='d-flex' style={{ gap: '1em' }}>
							{id && (
								<button
									onClick={() => {
										dispatch(TimeReportsActions.setMonthlyView(false));
										dispatch(TimeReportsActions.setDailyView(true));
									}}
									className={dailyView ? 'primary-btn' : 'secondary-btn'}
								>
									Daily
								</button>
							)}
							<button
								onClick={() => {
									dispatch(TimeReportsActions.setMonthlyView(false));
									dispatch(TimeReportsActions.setDailyView(false));
								}}
								className={
									!monthlyView && !dailyView ? 'primary-btn' : 'secondary-btn'
								}
							>
								Weekly
							</button>
							<button
								onClick={() => {
									dispatch(TimeReportsActions.setMonthlyView(true));
									dispatch(TimeReportsActions.setDailyView(false));
								}}
								className={monthlyView ? 'primary-btn' : 'secondary-btn'}
							>
								Monthly
							</button>
						</div>
					</div>
				</SkeletonLoading>
				<SkeletonLoading
					loading={loading}
					variant='text'
					width='25%'
					height={45}
				>
					<div className='d-flex align-items-center'>
						<div
							className='d-flex align-items-center'
							onClick={() => !dailyView && handleNavigation('left', start_date)}
						>
							<LeftIcon
								onClick={() =>
									dailyView && handleNavigation('left', start_date)
								}
								className='pointer'
							/>
							<p className='m-0 BodyTwoLight'>
								<span className='m-0 BodyTwoLight-light pointer'>
                                    {moment(start_date).format('MMMM D YYYY')}{' '}
								</span>
							</p>
						</div>
						{!dailyView && (
							<p className='m-0 BodyTwoLight'>
								<span className='m-0 BodyTwoLight-light'>
									&nbsp;&nbsp;to&nbsp;&nbsp;
								</span>{' '}
							</p>
						)}
						<div
							className='d-flex align-items-center'
							onClick={() => handleNavigation('right', start_date)}
						>
							{!dailyView && (
								<p className='m-0 BodyTwoLight'>
									<span
										className='m-0 BodyTwoLight-light pointer'
										onClick={() => handleNavigation('right', start_date)}
									>
										{moment(end_date).format('MMMM D YYYY')}
									</span>
								</p>
							)}
							<RightIcon className='pointer' />
						</div>
						{dailyView && (
							<div className='improved-input-desigms'>
								<KeyboardDatePicker
									autoOk
									disableToolbar
									name='date'
									onChange={handleDatePicker}
									keyboardIcon={<CalendarIcon />}
									className='styledKeyboardDatePicker'
									variant='inline'
									// minDate={moment()}
                                    
									// views={[monthlyView ? 'month' : 'date']}
								/>
							</div>
						)}
					</div>
				</SkeletonLoading>
			</div>
		</div>
	);
}

export default TimeReportsDateV2;
