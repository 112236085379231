import { secure_instance } from '../../axios/axios-config';

export const GET_SLACK_URL = '[Slack Integration] GET_SLACK_URL';
export const CHECK_SLACK_INTEGRATION = '[Slack Integration] CHECK_SLACK_INTEGRATION';
export const REMOVE_SLACK_ACCESS = '[Slack Integration] REMOVE_SLACK_ACCESS';

export const getSlackUrl = () => {
    return async (dispatch) => {
        try {
            const request = await secure_instance.request({
				url: '/v1/slack/get_url',
				method: 'Get',
				
			});
            
            dispatch({
                type: GET_SLACK_URL,
                payload: request.data
            });
        } catch (error) {
            //
        }
    };
};

export const checkSlackIntegration = (userId) => {
    return async (dispatch) => {
        try {
            const request = await secure_instance.request({
				url: `/v1/slack/check_integration/${userId}`,
				method: 'Get',
				
			});
            
            dispatch({
                type: CHECK_SLACK_INTEGRATION,
                payload: request.data
            });
            console.log(request);
        } catch (error) {
            //
        }
    };
};

export const removeSlackAccess = (userId) => {
    return async (dispatch) => {
        try {
            const request = await secure_instance.request({
				url: `/v1/slack/remove_access/${userId}`,
				method: 'Delete',
				
			});
            
            dispatch({
                type: REMOVE_SLACK_ACCESS,
                payload: request.data
            });
            console.log(request);
        } catch (error) {
            //
        }
    };
};