import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function RichTextEditor({richText,
    label,
    name,
    value,
    Class,
    onChange,
    Edit,
    style,
    type,
    pattern,
    title,
    required,
    column,
    rows,
    backgroundColor,
    placeholder,
    containerClass,
  }) {
          
      RichTextEditor.modules = {
              toolbar: [
                ['bold', 'italic', 'underline', 'strike'],
                [{ color: [] }, { background: [] }],
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                [
                  { list: 'ordered' },
                  { list: 'bullet' },
                  // { indent: "-1" },
                  // { indent: "+1" },
                ],
                [{ 'align': [] }],
                // ["link"],
              ],
              clipboard: {
                // toggle to add extra line breaks when pasting HTML:
                matchVisual: false,
              },
            };
  return (
    <div className={`${containerClass} ProfilePage d-flex flex-column w-100`}>
        <label className="text-left m-0">{label}</label>

        {Edit ? (
          <ReactQuill
            theme="snow"
            modules={RichTextEditor.modules}
            className={Class}
            style={style}
            type={type}
            value={value}
            onChange={onChange}
            pattern={pattern}
            title={title}
            required={required}
            rows={rows ? rows : 4}
            cols={1}
            placeholder={placeholder}
          />
        ) : (
          <h3 className="w-100 BodyTwoLight text-left" name={name} style={{...style, wordBreak:'break-word', overflow: 'auto'}}>
            {ReactHtmlParser(value)}
          </h3>
        )}
      </div>
  );
}

export default RichTextEditor;