import * as userActions from '../../redux/actions/user-management.actions';
import * as profileActions from '../../redux/actions/company-profile';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SubmitButtonsField from '../Buttons/SubmitButtonsField';
import { ReactComponent as CalendarIcon } from '../../assets/icons/Calendarcolor.svg';
import {
	DATE_FORMATS_OPTIONS,
	executivePermissions,
	formatPhoneNumber,
} from '../../utilities/utilities';
import { KeyboardDatePicker } from '@material-ui/pickers';
import DropDown from '../Inputs/DropDown';
import { Departments, timeZones } from '../DemoData';
import EditableText from '../Inputs/EditableText';
import EditableLocationText from '../Inputs/EditableLocationText';
import EditableFileInput from '../Inputs/EditableFileInput';

import { CircularProgress } from '@mui/material';
import { setSnackBarIsOpen } from '../../redux/actions/dashboard/workspaces-actions';
import Select from '../Inputs/Select';
import { Country, State } from 'country-state-city';
import PhoneNumberInput from '../Inputs/PhoneNumberInput';
import * as authActions from '../../redux/actions/auth-actions';
import { getUserSettings } from '../../hooks/getUserSettings';

const EditUserModal = ({ closeModal }) => {
	const Style = {
		backgroundColor: 'white',
		border: 'none',
		borderBottom: '1px solid #28383C',
	};
	const dispatch = useDispatch();
	const Profile = useSelector((state) => state.userReducer.userProfile);
	const image = useSelector((state) => state.userReducer.image);
	const imageLoading = useSelector((state) => state.userReducer.imageLoading);
	const actionLoading = useSelector((state) => state.userReducer.profileLoading);
	const companyProfileData = useSelector((state) => state.profileReducer.profileData);
	const countries = useSelector((state) => state.profileReducer.countries);
	const states = useSelector((state) => state.profileReducer.states);

	const [userForm, setUserForm] = useState(null);
	const user = useSelector((state) => state.authReducer.user);
	const [formFieldsError, setForFieldsError] = useState('');
	const [src, setSrc] = useState(null);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [croppedImage, setCroppedImage] = useState(null);
	const [isPhoneFormatted, setIsPhoneFormatted] = useState(Profile?.is_phone_number_formated);
	const ownAccountSettings = user?.userId == Profile?.id;
	const userDetails = useSelector((state) => state.authReducer.userDetails);
	const { date_format } = getUserSettings(userDetails);

	const handleChange = (event) => {
		let { name, value } = event.target;
		if (name === 'phone') {
			if (value.length > 14) {
				value = userForm?.phone;
			} else {
				value = formatPhoneNumber(value);
			}
		}
		if (name == 'image') {
			const fileReader = new FileReader();
			fileReader.onloadend = () => {
				setSrc(fileReader.result);
			};
			fileReader.readAsDataURL(event.target.files[0]);
		} else {
			setUserForm({
				...userForm,
				[name]: value,
			});
		}
	};

	const addressHandleChange = (e) => {
		const { name, value } = e.target;

		if (name == 'country') {
			setUserForm({
				...userForm,
				address: [
					{
						...userForm.address[0],
						[name]: value || '',
						state: '',
						street: '',
						zip: '',
						city: '',
						suit_floor: '',
					},
				],
			});
		} else {
			setUserForm({
				...userForm,
				address: [
					{
						...userForm.address[0],
						[name]: value,
					},
				],
			});
			const updatedForm = {
				...userForm,
				address: userForm?.address?.map((item) => {
					return {
						...item,
						[name]: value,
					};
				}),
			};
			setUserForm(updatedForm);
		}
	};

	const onBlur = (event) => {
		event.preventDefault();
	};

	const handlePhoneChange = (event, country, e, PhoneNumber) => {
		let phone;
		if (isPhoneFormatted) {
			phone = PhoneNumber;
		} else {
			phone = event.target.value;
		}
		setUserForm({
			...userForm,
			['phone']: phone,
		});
	};

	const resetNumber = () => {
		setUserForm({
			...userForm,
			['phone']: '',
		});
	};

	const toggleFormatting = (formatting) => {
		setIsPhoneFormatted(formatting);
	};

	const handleBirthdayChange = (date) => {
		setUserForm({
			...userForm,
			birthday: date,
		});
	};

	const handleHiringDateChange = (date) => {
		setUserForm({
			...userForm,
			hiring_date: date,
		});
	};

	const onAddressChange = (prop) => {
		setUserForm((userForm) => {
			return {
				...userForm,
				address: userForm?.address.map((item) => {
					return {
						...item,
						street: prop.fullAddress,
						zip: prop.zipCode ? prop.zipCode : '',
						state: prop.state ? prop.state : '',
						city: prop.city ? prop.city : '',
					};
				}),
			};
		});
	};
	const toggleModal = () => {
		setIsModalOpen(!isModalOpen);
	};

	useEffect(() => {
		const userCountry = userForm?.address[0]?.country
			? userForm?.address[0]?.country
			: companyProfileData?.country;
		setUserForm({
			...userForm,
			address: userForm?.address?.map((item, i) => {
				return {
					...item,
					['country']: userCountry,
				};
			}),
		});
	}, [dispatch]);

	useEffect(() => {
		userForm?.address?.length > 0 &&
			dispatch(
				profileActions.getCountryStates(
					State.getStatesOfCountry(userForm?.address[0]?.country),
				),
			);
	}, [userForm?.address[0]?.country]);

	useEffect(() => {
		if (image) {
			setUserForm((userForm) => {
				return {
					...userForm,
					['image_url']: image,
				};
			});
		}
	}, [image]);

	const onSubmit = () => {
		dispatch(userActions.uploadImage(croppedImage, toggleModal));
	};
	const UpdateUser = (event) => {
		event.preventDefault();

		dispatch(userActions.setProfileLoading(true));

		const profileToUpdate = { ...userForm, is_phone_number_formated: isPhoneFormatted };
		delete profileToUpdate.role;
		delete profileToUpdate.user_teams;
		delete profileToUpdate.leave_requests;
		delete profileToUpdate.image;
		delete profileToUpdate.country;


		dispatch(userActions.updateUserProfile(profileToUpdate))
			.then((response) => {
				dispatch(
					userActions.updateUserProfileInfo({
						...response,
						user_teams: userForm.user_teams,
						leave_requests: userForm.leave_requests,
					}),
				);
				ownAccountSettings && dispatch(authActions.getCurrentUserDetails(user?.userId));
				dispatch(userActions.clearUsers());
				closeModal();
				dispatch(setSnackBarIsOpen(true, true, 'Updated Succesfully'));
			})
			.catch((error) => {
				setForFieldsError(error);
				dispatch(setSnackBarIsOpen(false, true, error?.message));
			});
	};

	useMemo(() => {
		Profile && setUserForm(Profile);
	}, []);

	useEffect(() => {
		return () => {
			dispatch(userActions.clearImage());
		};
	}, []);

	return (
		<>
			<form
				data-cy='add-user-form'
				autoComplete='off'
				onSubmit={UpdateUser}
				className='UserAdd-Form'
			>
				<div className='p-3 scroll'>
					<EditableFileInput
						value={userForm?.image_url}
						Edit={true}
						name='image'
						onChange={(e) => handleChange(e)}
						clickHandler={() => setIsModalOpen(!isModalOpen)}
						onSubmit={onSubmit}
						toggleModal={toggleModal}
						src={src}
						isModalOpen={isModalOpen}
						setCroppedImage={setCroppedImage}
						loading={imageLoading}
					/>

					<div className='improved-input-designs py-3'>
						<label className='w-100 require'>Name</label>
						<input
							className='w-100'
							type='text'
							name='full_name'
							onChange={handleChange}
							value={userForm?.full_name}
							style={Style}
							data-cy='user-fname'
							required
							placeholder=''
						/>
					</div>
					<div className='improved-input-designs pb-3'>
						<label className='w-100'>Birthday</label>
						<KeyboardDatePicker
							autoOk
							value={userForm?.birthday || null}
							name='date'
							onChange={handleBirthdayChange}
							format={date_format}
							keyboardIcon={<CalendarIcon />}
							disablePast={false}
						/>
					</div>

					{ownAccountSettings && (
						<div className='improved-input-designs pb-3'>
							<label className='w-100'>Date Format</label>
							<DropDown
								onChange={handleChange}
								name='date_format'
								value={{
									label: userForm?.date_format,
									value: userForm?.date_format,
								}}
								options={DATE_FORMATS_OPTIONS}
								disableNull={true}
							/>
						</div>
					)}

					{executivePermissions.includes(user?.userRole?.id) && (
						<div className='improved-input-designs pb-3'>
							<EditableText
								onChange={handleChange}
								label='Job Title'
								name='job_title'
								value={userForm?.job_title}
								Edit={
									executivePermissions.includes(user?.userRole?.id) ? true : false
								}
							/>
						</div>
					)}

					{executivePermissions.includes(user?.userRole?.id) && (
						<div className='improved-input-designs pb-3'>
							<label>Department</label>
							<Select
								name='department'
								value={Departments.find(
									(department) => department?.value == userForm?.department,
								)}
								options={Departments}
								selectClassName='w-100'
								onChange={handleChange}
							/>
						</div>
					)}
					<div className='phone-number-container pb-3'>
						<label className='pb-2'>Phone</label>
						<PhoneNumberInput
							value={userForm?.phone}
							handleChange={handlePhoneChange}
							resetNumber={resetNumber}
							isPhoneFormatted={isPhoneFormatted}
							toggleFormatting={toggleFormatting}
                            dataCy='user-phone'
						/>
					</div>
					<div className='improved-input-designs pb-3'>
						<label className='w-100'>Hiring Date</label>
						<KeyboardDatePicker
							autoOk
							value={userForm?.hiring_date || null}
							name='date'
							onChange={handleHiringDateChange}
							format={date_format}
							keyboardIcon={<CalendarIcon />}
						/>
					</div>
					<div className='px-2 py-2 rounded' style={{ backgroundColor: '#F7F9FA' }}>
						<h1 className='BodyOneBold px-2'>Address</h1>
						{userForm?.address?.map((item, index) => {
							return (
								<div className='px-2' key={index}>
									<div
										style={{ marginTop: '10px' }}
										className='improved-input-designs pb-3'
									>
										<label>Country</label>
										<Select
											value={{ value: item?.country || '' }}
											options={countries}
											name='country'
											onChange={addressHandleChange}
										/>
									</div>
									<div
										style={{ marginTop: '10px' }}
										className='improved-input-designs pb-3'
									>
										<EditableLocationText
											Edit={true}
											label='Street'
											name='street'
											placeSelected={onAddressChange}
											onChange={addressHandleChange}
											onBlur={onBlur}
											value={item?.street}
											placeholder=''
											countryAbbr={item?.country}
										/>
									</div>
									<div className='improved-input-designs pb-3'>
										<EditableText
											label='Floor/Suite'
											name='suit_floor'
											Edit={true}
											value={item?.suit_floor}
											onChange={addressHandleChange}
										/>
									</div>
									<div className='improved-input-designs pb-3'>
										<EditableText
											label='City/Town'
											name='city'
											Edit={true}
											value={item?.city}
											onChange={addressHandleChange}
										/>
									</div>

									<div className='improved-input-designs pb-3'>
										<label>State/Province/Region</label>
										<Select
											name='state'
											value={{ value: item?.state }}
											options={states}
											selectClassName='w-100'
											onChange={addressHandleChange}
										/>
									</div>
									<div className='improved-input-designs pb-3'>
										<EditableText
											onChange={addressHandleChange}
											label='Zip Code/Postal Code'
											name='zip'
											value={item?.zip}
											Edit={true}
										/>
									</div>
								</div>
							);
						})}
					</div>
				</div>

				<div className='VerticalSpacer bg-white p-3 rounded-bottom'>
					<SubmitButtonsField child={true}>
						<button
							id='Element'
							className='secondary'
							type='button'
							onClick={() => {
								closeModal();
							}}
						>
							Cancel
						</button>

						<button
							type='submit'
							className='d-flex align-items-center'
							id='Element'
							disabled={actionLoading}
						>
							{' '}
							{actionLoading ? (
								<CircularProgress size={24} sx={{ color: 'white' }} disableShrink />
							) : (
								'Update'
							)}
						</button>
					</SubmitButtonsField>
				</div>
			</form>
		</>
	);
};

export default EditUserModal;
