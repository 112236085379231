import DummyModal from './DummyModal';
import { CircularProgress, Modal } from '@mui/material';
import DeleteFolder from '../sub_components/DocumentStorage/DeleteFolder';

function DeleteModal({ modalIsOpen, toggleModal, isFileTrue, item, isFolderDefault, isLoading }) {
	return (
		<div>
			{modalIsOpen && (
				<Modal
					onClose={toggleModal}
					open={modalIsOpen}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<>
						{isLoading ? (
							<CircularProgress size={44} sx={{ color: '#fb7a03' }} disableShrink />
						) : (
							<DummyModal
								title={
									isFolderDefault
										? 'Restricted'
										: `Delete this ${isFileTrue ? 'file' : 'folder'}?`
								}
								onClose={toggleModal}
							>
								<DeleteFolder
									onCancel={toggleModal}
									item={item}
									isFileTrue={isFileTrue}
									isFolderDefault={isFolderDefault}
									isLoading={isLoading}
								/>
							</DummyModal>
						)}
					</>
				</Modal>
			)}
		</div>
	);
}

export default DeleteModal;
