/* eslint-disable no-mixed-spaces-and-tabs */
import { Checkbox, CircularProgress, Modal } from '@mui/material';
import React, { useState, useEffect, useMemo } from 'react';
import { REPEAT_TYPE, ordinal_suffix_of, weekOfMonth } from '../../../utilities/utilities';
import SubmitButtonsField from '../../Buttons/SubmitButtonsField';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { getWeeksInMonth } from 'date-fns/esm';
import DummyModal from '../../Modal/DummyModal';
import ConfirmModal from '../../Modal/ConfirmModal';
import useModal from '../../Modal/useModal';
import { KeyboardDatePicker } from '@material-ui/pickers';
import DropDown from '../../Inputs/DropDown';
import Select from '../../Inputs/Select';
import * as builderActions from '../../../redux/actions/workflow-builder-actions';
import { getUserSettings } from '../../../hooks/getUserSettings';

function RecurrenceModal({ recurrence, onCancel, draft }) {
	const recurrences = [
		{ type: REPEAT_TYPE.DAILY, name: 'Day' },
		{ type: REPEAT_TYPE.WEEKLY, name: 'Week' },
		{ type: REPEAT_TYPE.MONTHLY, name: 'Month' },
		{ type: REPEAT_TYPE.YEARLY, name: 'Year' },
	];
	const dispatch = useDispatch();
	const [formControls, setFormControls] = useState({
		error: '',
		loading: false,
	});
	const [alertModal, setAlertModal] = useState(false);
	// const [recurrenceAlert, setRecurrenceAlert] = useState(false);
	const [monthlySpecialDate, setMonthlySpecialDate] = useState(false);
	const [CurrentRecurrence, setCurrentRecurrence] = useState({});
	const [currentDate, setCurrentDate] = useState();
	const userDetails = useSelector((state) => state.authReducer.userDetails);
	const { date_format } = getUserSettings(userDetails);
	//   Modal
	const { modalIsOpen, toggleModal } = useModal();

	const recurPreferenceChangeHandler = (event) => {
		const { name, value } = event.target;

		setCurrentRecurrence({
			...CurrentRecurrence,
			[name]: Number(value),
		});
	};

	const changeHandler = (event) => {
		let { name, value } = event.target;

		if (name === 'repeat_interval') {
			value = parseInt(value);
		}

		if (name == 'required_days' && value != '') {
			value = parseInt(value);
		}

		setCurrentRecurrence({
			...CurrentRecurrence,
			[name]: value,
		});
	};

	const endOnChangeHandler = (e) => {
		const { name, value } = e.target;

		if (name === 'type') {
			setCurrentRecurrence({
				...CurrentRecurrence,
				end_on: {
					type: value,
					occurrence: null,
					end_date: null,
				},
			});
		}

		if (name === 'occurrence') {
			setCurrentRecurrence({
				...CurrentRecurrence,
				end_on: {
					type: name,
					[name]: Number(value),
					end_date: null,
				},
			});
		}
	};

	const onEndDateChange = (date) => {
		// if (date < CurrentRecurrence?.start_date) {
		// 	alert("End date can't be less than start date");
		// }
		// else {
		setCurrentRecurrence({
			...CurrentRecurrence,
			end_on: {
				type: 'end_date',
				occurrence: null,
				end_date: date,
			},
		});
		// }
	};

	const onDateChange = (date) => {
		// if (draft) {
		// 	if (CurrentRecurrence?.repeat_type === REPEAT_TYPE.MONTHLY) {
		// 		if (
		// 			weekOfMonth(moment(date)) === 4 &&
		// 			moment(date).add(7, 'days').month() > moment(date).month() &&
		// 			!CurrentRecurrence?.is_monthly_on_day
		// 		) {
		// 			setMonthlySpecialDate(true);
		// 			setCurrentRecurrence({
		// 				...CurrentRecurrence,
		// 				start_date: date,
		// 				recur_preference: null,
		// 			});
		// 		} else {
		// 			setCurrentRecurrence({
		// 				...CurrentRecurrence,
		// 				recur_preference: null,
		// 				start_date: date,
		// 			});
		// 			setMonthlySpecialDate(false);
		// 		}
		// 	} else {
		// 		setCurrentRecurrence({
		// 			...CurrentRecurrence,
		// 			start_date: date,
		// 		});
		// 	}
		// } else {
		if (CurrentRecurrence?.repeat_type === REPEAT_TYPE.MONTHLY) {
			if (
				weekOfMonth(moment(date)) === 4 &&
				moment(date).add(7, 'days').month() > moment(date).month()
				// && !CurrentRecurrence?.is_monthly_on_day
			) {
				setMonthlySpecialDate(true);
				setCurrentRecurrence({
					...CurrentRecurrence,
					start_date: date,
					// recur_preference: null,
				});
			} else {
				setCurrentRecurrence({
					...CurrentRecurrence,
					recur_preference: 0,
					start_date: date,
				});
				setMonthlySpecialDate(false);
			}
		} else {
			setCurrentRecurrence({
				...CurrentRecurrence,
				start_date: date,
			});
		}
		// }
	};

	const validateMonthlyType = () => {
		if (CurrentRecurrence.repeat_type) {
			recurrenceFormSubmit();
		} else {
			alert('Please Select Recurrence Type');
		}
	};

	const toggleAlertModal = () => {
		setAlertModal(!alertModal);
	};

	const onKeepTasks = () => {
		toggleAlertModal();
		validateMonthlyType();
	};

	const onDeleteTasks = () => {
		toggleAlertModal();
		validateMonthlyType();
	};

	// is_monthly_on_day option true:
	// 1- day date

	// is_monthly_on_day false and special_date true
	// 2- that day in current week
	// 3- that day in last day

	// is_monthly_on_day option false and special_date false:
	// 1- that day in current week

	const recur_preferences = useMemo(() => {
		const options = [
			{
				value: 0,
				label: `Monthly on day ${moment(CurrentRecurrence.start_date).format('DD')}`,
			},
			{
				value: 1,
				label: `Monthly on the ${ordinal_suffix_of(
					weekOfMonth(moment(CurrentRecurrence?.start_date)),
				)} ${moment(CurrentRecurrence?.start_date).format('dddd')}`,
			},
		];

		if (monthlySpecialDate) {
			options.push({
				value: 2,
				label: `Monthly on the last ${moment(CurrentRecurrence?.start_date).format(
					'dddd',
				)}`,
			});
		}

		return options;
	}, [CurrentRecurrence.start_date, monthlySpecialDate]);

	const END_ON = [
		{ value: 'occurrence', label: 'Occurrences' },
		{ value: 'end_date', label: 'Date' },
		{ value: 'never', label: 'Never' },
	];

	const recurrenceFormSubmit = () => {
		setFormControls({
			loading: true,
			error: '',
		});

		const recurrenceObj = {
			// ...clientWorkflow,
			id: CurrentRecurrence.id,
			start_date: moment(CurrentRecurrence.start_date).format('MM-DD-YYYY'),
			repeat_interval: CurrentRecurrence.repeat_interval,
			repeat_type: CurrentRecurrence.repeat_type,
			// client_workflow_tasks: allWorkflowTasks,
			is_monthly_on_day: monthlySpecialDate
				? CurrentRecurrence.recur_preference == 0
					? true
					: false
				: CurrentRecurrence.recur_preference == 0
				? true
				: false,
			recur_preference: monthlySpecialDate
				? CurrentRecurrence.recur_preference == 0
					? null
					: CurrentRecurrence.recur_preference
				: null,
			end_on: CurrentRecurrence.end_on,
			required_days: parseInt(CurrentRecurrence.required_days) || 0,
		};
		if (CurrentRecurrence.repeat_type !== REPEAT_TYPE.MONTHLY) {
			delete recurrenceObj.is_monthly_on_day;
			recurrenceObj.recur_preference = null;
		}

		const data = {
			...recurrenceObj,
		};
		delete data.workflow_builder;
		delete data.workflow_submission;

		// if (draft) {
		// dispatch(
		// 	builderActions.setAllWorkflowTasks(
		// 		{
		// 			...recurrenceObj,
		// 		},
		// 		{ ...data },
		// 		// onSubmitComplete,
		// 	),
		// );
		// } else {

		dispatch(builderActions.updateClientWorkflow(recurrenceObj))
			.then(() => {
				setFormControls({
					error: '',
					loading: false,
				});
				onCancel();
			})
			.catch((error) => {
				setFormControls({
					error: error?.message,
					loading: false,
				});
			});

		// }
	};

	useEffect(() => {
		const currentDate = moment()?.format('MM/DD/YYYY');
		const startDate = moment(recurrence?.start_date);
		if (!startDate.isSameOrAfter(currentDate)) {
			recurrence.start_date = currentDate;
		}
		setCurrentRecurrence(recurrence);
		if (
			weekOfMonth(moment(recurrence?.start_date)) === 4 &&
			moment(recurrence?.start_date).add(7, 'days').month() >
				moment(recurrence?.start_date).month()
			//     &&
			// !recurrence?.is_monthly_on_day
		) {
			setMonthlySpecialDate(true);
			setCurrentRecurrence({
				...recurrence,
				recur_preference: recurrence?.is_monthly_on_day ? 0 : 1,
			});
		} else if (recurrence?.is_monthly_on_day) {
			setMonthlySpecialDate(false);
			setCurrentRecurrence({
				...recurrence,
				recur_preference: 0,
			});
		} else {
			setMonthlySpecialDate(false);
			setCurrentRecurrence({
				...recurrence,
				recur_preference: 1,
			});
		}
		setCurrentDate(recurrence?.start_date);
	}, [recurrence]);

	// useEffect(() => {
	// 	if (
	// 		moment(
	// 			moment(CurrentRecurrence?.start_date).format('YYYY-MM-DD'),
	// 		).isBefore(moment(recurrence?.start_date?._i).format('YYYY-MM-DD'))
	// 	) {
	// 		setRecurrenceAlert(true);
	// 	} else {
	// 		setRecurrenceAlert(false);
	// 	}
	// }, [CurrentRecurrence, setRecurrenceAlert]);

	const validateStartEndDate = () => {
		const startDate = moment(CurrentRecurrence?.start_date).startOf('day').toISOString(); // Start Date
		const endDate = moment(CurrentRecurrence?.end_on?.end_date).startOf('day').toISOString(); // End Date

		const currentDate = moment().startOf('day').toISOString(); // Current date to ISO String format, ignoring time

		const isDateGreaterOrSameCurrent = moment(startDate).isSameOrAfter(currentDate); // If start date is after or the same as the current date
		const isDateGreaterOrSameStart = moment(endDate).isSameOrAfter(startDate); // If end date is after or the same as the start date

		if (isDateGreaterOrSameCurrent && (endDate ? isDateGreaterOrSameStart : true)) {
			toggleModal();
		}
	};

	return (
		<>
			<form
				autoComplete='off'
				className='ContactAdd-Form'
				onSubmit={(e) => {
					e.preventDefault();
					draft
						? validateMonthlyType()
						: // : recurrenceAlert
						  // ? toggleAlertModal()
						  validateStartEndDate();
				}}
			>
				<div className='p-3'>
					<div className='improved-input-designs pb-3'>
						<label className=' '>Repeats</label>
						<div className='TabWrapper'>
							{recurrences.map((item, i) => {
								return (
									<span
										key={i}
										className={
											item.type == CurrentRecurrence.repeat_type
												? 'Tab selected'
												: 'Tab'
										}
										onClick={() =>
											setCurrentRecurrence({
												...CurrentRecurrence,
												repeat_type: item.type,
											})
										}
										data-cy={`recurrence-${item.type}`}
									>
										{item.type}
									</span>
								);
							})}
						</div>
					</div>

					<div className='improved-input-designs pb-3 d-flex flex-column'>
						<label className='w-100 require'>Starting on</label>
						<KeyboardDatePicker
							autoOk
							disableToolbar
							value={CurrentRecurrence?.start_date || null}
							name='start_date'
							onChange={onDateChange}
							format={date_format}
							minDate={moment()}
							minDateMessage='Date should not be before current date'
							// helperText={null}
							required
							// InputProps={{
							// 	readOnly: true
							// }}
						/>
					</div>

					<div className='improved-input-designs pb-3'>
						<label className='w-100 require'>Every</label>
						<div className='d-flex flex-row align-items-center' style={{ gap: '5px' }}>
							<input
								className='w-50 input-style'
								type='number'
								name='repeat_interval'
								required={true}
								value={CurrentRecurrence?.repeat_interval}
								onChange={changeHandler}
								min={1}
							/>
							<h6 style={{ color: '#333333' }} className='formText p-0 m-0 ml-1'>
								{
									recurrences.find(
										(item) => item.type === CurrentRecurrence.repeat_type,
									)?.name
								}
							</h6>
						</div>
					</div>

					{CurrentRecurrence.repeat_type === REPEAT_TYPE.MONTHLY && (
						<div className='improved-input-designs pb-3'>
							<label className=''>Recur Preferences</label>
							<DropDown
								onChange={recurPreferenceChangeHandler}
								className='w-100 '
								value={recur_preferences.find(
									(item) => item.value === CurrentRecurrence?.recur_preference,
								)}
								name='recur_preference'
								// options={RECUR_PREFERENCES}
								options={recur_preferences}
								disableNull
							/>
						</div>
					)}

					<div className='improved-input-designs pb-3'>
						<label className='require'>Ends on</label>
						<Select
							onChange={endOnChangeHandler}
							selectClassName='w-100'
							value={END_ON.find(
								(item) => item.value === CurrentRecurrence?.end_on?.type,
							)}
							name='type'
							options={END_ON}
							required={true}
						/>
					</div>

					{CurrentRecurrence?.end_on?.type === 'occurrence' ? (
						<div className='improved-input-designs pb-3'>
							<label className='w-100 require'>Number of Occurrences</label>
							<div
								className='d-flex flex-row align-items-center'
								style={{ gap: '5px' }}
							>
								<input
									className='w-50'
									type='number'
									name='occurrence'
									required={true}
									value={CurrentRecurrence?.end_on?.occurrence || null}
									onChange={endOnChangeHandler}
									min={1}
								/>
							</div>
						</div>
					) : (
						CurrentRecurrence?.end_on?.type === 'end_date' && (
							<div className='improved-input-designs pb-3 d-flex flex-column'>
								<label className='w-100 require'>Ends On</label>
								<KeyboardDatePicker
									autoOk
									disableToolbar
									value={CurrentRecurrence?.end_on?.end_date || null}
									name='end_date'
									onChange={onEndDateChange}
									format={date_format}
									minDate={CurrentRecurrence?.start_date || moment()}
									required
								/>
							</div>
						)
					)}

					<div className='improved-input-designs pb-3'>
						<label className='require'>Days to complete</label>
						<input
							data-cy='required_days'
							className='w-100'
							type='number'
							name='required_days'
							required={true}
							value={CurrentRecurrence?.required_days}
							onChange={changeHandler}
							min={1}
						/>
					</div>

					{CurrentRecurrence?.repeat_type && (
						<div className='improved-input-designs'>
							<label className='w-100'> </label>
							<h3 className='BodyTwoLight text-center w-100 Recurrence-text'>
								{/* For Daily Recurrence */}
								{CurrentRecurrence.repeat_type === REPEAT_TYPE.DAILY &&
									`This workflow will recur every ${
										CurrentRecurrence.repeat_interval != 1
											? ordinal_suffix_of(
													CurrentRecurrence.repeat_interval || 1,
											  )
											: ''
									} day starting from ${moment(
										CurrentRecurrence.start_date,
									).format('MMMM DD, YYYY')}`}

								{/* For Weekly Recurrence */}
								{CurrentRecurrence.repeat_type === REPEAT_TYPE.WEEKLY &&
									`This workflow will recur on ${moment(
										CurrentRecurrence?.start_date,
									).format('dddd')} of every ${
										CurrentRecurrence.repeat_interval != 1
											? ordinal_suffix_of(
													CurrentRecurrence.repeat_interval || 1,
											  )
											: ''
									} week starting from ${moment(
										CurrentRecurrence.start_date,
									).format('MMMM DD, YYYY')}`}

								{/* For Monthly Recurrence */}
								{CurrentRecurrence.repeat_type === REPEAT_TYPE.MONTHLY && (
									<>
										This workflow will recur on{' '}
										{CurrentRecurrence.recur_preference == 0 &&
											ordinal_suffix_of(
												moment(CurrentRecurrence.start_date).format('DD'),
											)}{' '}
										{CurrentRecurrence.recur_preference == 1 &&
											`${ordinal_suffix_of(
												weekOfMonth(moment(CurrentRecurrence?.start_date)),
											)} ${moment(CurrentRecurrence?.start_date).format(
												'dddd',
											)}`}{' '}
										{CurrentRecurrence.recur_preference == 2 &&
											`last ${moment(CurrentRecurrence?.start_date).format(
												'dddd',
											)}`}{' '}
										of every{' '}
										{CurrentRecurrence.repeat_interval != 1
											? ordinal_suffix_of(
													CurrentRecurrence.repeat_interval || 1,
											  )
											: ''}{' '}
										month starting from{' '}
										{moment(CurrentRecurrence.start_date).format(
											'MMMM DD, YYYY',
										)}
									</>
								)}

								{/* For Yearly Recurrence */}
								{CurrentRecurrence.repeat_type === REPEAT_TYPE.YEARLY &&
									`This workflow will recur  on ${moment(
										CurrentRecurrence.start_date,
									).format('MMMM DD')} of every ${
										CurrentRecurrence.repeat_interval != 1
											? ordinal_suffix_of(
													CurrentRecurrence.repeat_interval || 1,
											  )
											: ''
									}  year starting from ${moment(
										CurrentRecurrence.start_date,
									).format('MMMM DD, YYYY')}`}
							</h3>
						</div>
					)}
				</div>

				<div className='VerticalSpacer bg-white p-3 rounded-bottom'>
					<SubmitButtonsField child={true}>
						<button id='Element' type='button' className='secondary' onClick={onCancel}>
							Cancel
						</button>

						<button
							data-cy='submit-recurrence'
							disabled={formControls.loading}
							type='submit'
							className='d-flex align-items-center'
							id='Element'
						>
							{formControls.loading ? (
								<CircularProgress size={24} sx={{ color: 'white' }} disableShrink />
							) : (
								'Submit'
							)}
						</button>
					</SubmitButtonsField>
				</div>
			</form>

			{modalIsOpen && !draft && (
				<Modal
					onClose={toggleModal}
					open={modalIsOpen}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<DummyModal title={'Alert'} onClose={toggleModal}>
						<ConfirmModal
							description={
								'You’re about to change workflow recurrence. Changes of future tasks will override. Are you sure to want to continue?'
							}
							OnCancel={toggleModal}
							Onsubmit={validateMonthlyType}
							descriptionClassName='text-left'
							isLoading={formControls.loading}
							error={formControls.error}
						/>
					</DummyModal>
				</Modal>
			)}
			{/* {alertModal && !draft && (
				<Modal
					onClose={toggleAlertModal}
					open={alertModal}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<DummyModal title={'Alert'} onClose={toggleAlertModal}>
						<div>
							<div className='pb-3'>
								<span className='BodyTwoLight pt-1 pb-4'>
									You are setting the start date of this workflow before the end
									of the current workflow cycle. You can either keep your
									existing tasks or delete them. Deleting the current tasks will
									remove them along with any time tracked or comments. What do
									you want to do?
								</span>
							</div>
							<div className='d-flex justify-content-between'>
								<button
									id='Element'
									className='secondary'
									onClick={onKeepTasks}
								>
									Keep Tasks
								</button>
								<button
									className='d-flex align-items-center'
									id='Element'
									onClick={onDeleteTasks}
								>
									Delete Tasks
								</button>
							</div>
						</div>
					</DummyModal>
				</Modal>
			)} */}
		</>
	);
}

export default RecurrenceModal;
