import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default function InputTaskDetails({
  inputField,
  textArea,
  type,
  name,
  title,
  value,
  onChange,
  min,
  richText,
	onBlur,
  placeholderText,
  style
}) {
  const [hasFocus, setFocus] = useState(false);

  InputTaskDetails.modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'color': [] }, { 'background': [] }], 
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        // { indent: "-1" },
        // { indent: "+1" },
      ],
      [{ 'align': [] }],
      // ["link"],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };
  if (inputField) {
    return (
      <div className="w-100">
        <div className="d-flex">
          <span className="BodyTwoLight" style={{ color: '#7C7C7C' }}>
            {title}
          </span>
        </div>
        <input
          type={type}
          name={name}
          className="input-field BodyTwoLight"
          value={value}
          min={min}
          onFocus={() => setFocus(true)}
					onBlur={() => {
						setFocus(false);
						onBlur && onBlur();
					}}
          placeholder={placeholderText}
          onChange={onChange}
          style={{
            borderBottom: hasFocus ? '1px solid #FB7A03' : '1px solid white',
          }}
        />
      </div>
    );
  }
  if (textArea) {
    return (
      <div className="w-100">
        <div className="d-flex">
          <span className="BodyTwoLight" style={{ color: '#7C7C7C' }}>
            {title}
          </span>
        </div>
        <textarea
          type={type}
          name={name}
          value={value}
          className="text-area-field BodyTwoLight"
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          onChange={onChange}
          style={{
            borderBottom: hasFocus ? '1px solid #FB7A03' : '1px solid white',
          }}
        />
      </div>
    );
  }
  if (richText) {
    return (
			<div className='w-100'>
				<div className='d-flex'>
					<span className='BodyTwoLight' style={{ color: '#7C7C7C' }}>
            {title}
          </span>
        </div>
        <ReactQuill
					theme='snow'
          value={value}
          placeholder={placeholderText}
          // className="text-area-field BodyTwoLight"
          onChange={onChange}
					style={{
						display: 'flex',
						flexDirection: 'column',
                        justifyContent: 'space-between',
						gap: 9,
						textAlign: 'left',
						height: '130px',
						// marginBottom: '50px',
                        ...style,
            borderRadius:4
					}}
          modules={InputTaskDetails.modules}
        />
      </div>
    );
  }
}
