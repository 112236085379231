import React from 'react';

export default function TaskModalContainer({
	children,
	title,
	style,
	className,
}) {
	return (
		<div
			className={'task-modal-container ' + className}
			style={style}
		>
			<div className='d-flex'>
				<span className='HeadlineThreeBook'>{title}</span>
			</div>
			<div className='task-modal-children'>{children}</div>
		</div>
	);
}
