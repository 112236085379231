
const Trademarks = () => {
	return (
		<p className='BodyTwoLight'>
			The Levvy name and our logos, our product or service names, our slogans,
			and the look and feel of the Site are trademarks of Levvy and may not be
			copied, imitated or used, in whole or in part, without our prior written
			permission. All other trademarks, registered trademarks, product names and
			company names or logos mentioned on the Site are the property of their
			respective owners. Reference to any products, services, processes or other
			information by trade name, trademark, manufacturer, supplier or otherwise
			does not constitute or imply endorsement, sponsorship or recommendation by
			us.
		</p>
	);
};

export default Trademarks;
