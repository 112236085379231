import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PickList from '../Inputs/PickList';
import { Member } from '../../utilities/utilities';
import SubmitButtonsField from '../Buttons/SubmitButtonsField';
import { CircularProgress } from '@mui/material';

function AddUserToTeam({
	onInputChange,
	AddTeams,
	newTeam,
	Roles,
	onSubmit,
	onCancel,
	actionLoading,
}) {
	const user = useSelector((state) => state.authReducer.user);
	const TeamRoles = useSelector((state) => state.userReducer.userRoles); // Total User Roles

	const [teams, setTeams] = useState(AddTeams);

	if (user?.userRole?.id == Member.supervisorId) {
		Roles = Roles?.filter((role) => role.id !== Member.supervisorId);
	}

	if (
		TeamRoles?.some(
			(item) =>
				item.role_id === Member.teamManagerId ||
				TeamRoles?.some((item) => item.role_id === Member.teamMemberId),
		)
	) {
		Roles = Roles?.filter(
			(role) =>
				role.id === Member.teamManagerId || role.id === Member.teamMemberId,
		);
	}

	useEffect(() => {
		if (
			user?.user_roles?.find((role) => role.role_id === Member.teamManagerId)
		) {
			const ManagerTeams = [];
			user?.user_roles?.map((item) => {
				if (item?.role_id === Member.teamManagerId) {
					item?.role_scopes?.map((role) => {
						ManagerTeams.push(role.team_id);
						return role;
					});
				}
				return item;
			});

			setTeams(
				AddTeams?.filter((item) => {
					return ManagerTeams?.includes(item.id);
				}),
			);

			Roles = Roles?.filter(
				(role) =>
					role.id == Member.teamManagerId || role.id == Member.teamMemberId,
			);
		}

		if (TeamRoles?.some((item) => item.role_id === Member.supervisorId)) {
			onInputChange({
				target: {
					name: 'role_id',
					value: `${Member.supervisorId}`,
				},
			});
		}

		if (
			TeamRoles?.some((item) => item.role_id === Member.externalCollaborator)
		) {
			onInputChange({
				target: {
					name: 'role_id',
					value: `${Member.externalCollaborator}`,
				},
			});
		}
	}, [AddTeams]);

	const InputBgColor = '#F6F5F2';
	const Style = {
		backgroundColor: InputBgColor,
		border: 'none',
		borderBottom: '1px solid #28383C',
	};

	return (
		<form
			onSubmit={(e) => {
				e.preventDefault();
				onSubmit();
			}}
		>
			<div className='p-3'>
				<div className='improved-input-designs pb-3'>
					<label className='require'>Team</label>
					<PickList
						options={teams?.map((opt) => {
							return {
								id: opt.id,
								name: opt.name,
							};
						})}
						Name='team_id'
						require={true}
						SelectRole={onInputChange}
					/>
				</div>

				<div className='improved-input-designs pb-3'>
					<label className=''>Description</label>
					<input
						className='w-100 bg-white'
						value={newTeam.description}
						style={Style}
						label='Description'
						name='description'
						disabled={true}
					/>
				</div>

				<div className='improved-input-designs pb-3'>
					<label className='require'>Role</label>
					<PickList
						require={true}
						Name='role_id'
						className={'text-left'}
						options={Roles?.filter((role) => role.id !== 15)}
						value={newTeam?.role_id}
						SelectRole={onInputChange}
						selectDisabled={
							user?.userRole.id === Member.teamManagerId ||
							TeamRoles?.some((item) => item.role_id === Member.supervisorId) ||
							TeamRoles?.some(
								(item) => item.role_id === Member.externalCollaborator,
							)
								? true
								: false
						}
					/>
				</div>
			</div>

			<div className='VerticalSpacer bg-white p-3 rounded-bottom'>
				<SubmitButtonsField child={true}>
					<button
						id='Element'
						className='secondary'
						type='button'
						onClick={() => {
							onCancel();
						}}
					>
						Cancel
					</button>

					<button
						type='submit'
						className='d-flex align-items-center'
						id='Element'
						disabled={actionLoading}
					>
						{actionLoading ? (
							<CircularProgress
								size={24}
								sx={{ color: 'white' }}
								disableShrink
							/>
						) : (
							'Add'
						)}
					</button>
				</SubmitButtonsField>
			</div>
		</form>
	);
}

export default AddUserToTeam;
