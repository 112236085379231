import { useState } from 'react';
import SubmitButtonsField from '../../Buttons/SubmitButtonsField';
import * as builderActions from '../../../redux/actions/workflow-builder-actions';
import { useDispatch, useSelector } from 'react-redux';

function ClientWorkflowModal({ clientWorkflowName, closeModal }) {
	const dispatch = useDispatch();
	const [workflowName, setWorkflowName] = useState(clientWorkflowName);
	const clientWorkflow = useSelector(
		(state) => state.WorkflowBuilder.selectedClientWorkflow,
	);
	const [formControl, setFormControl] = useState({
		loading: false,
		error: '',
	});

	const onSubmit = () => {
		setFormControl({
			loading: true,
			error: '',
		});
		const payload = {
			...clientWorkflow,
		};

		delete payload.client_workflow_tasks;
		delete payload.workflow_submission;
		delete payload.repeat_type;
		delete payload.repeat_interval;

		dispatch(
			builderActions.updateClientWorkflow({
				...payload,
				display_name: workflowName,
			}),
		)
			.then((newClientWorkflow) => {
				dispatch(builderActions.updateClientWorkflowList(newClientWorkflow));

				setFormControl({
					error: '',
					loading: false,
				});
				closeModal();
			})
			.catch((error) => {
				setFormControl({
					error: error?.message,
					loading: false,
				});
			});
	};

	return (
		<div>
			<div className='VerticalSpacer'>
				<div className='improved-input-designs p-3'>
					<label>Workflow Name</label>
					<input
						className='w-100'
						type='text'
						value={workflowName}
						onChange={(e) => setWorkflowName(e.target.value)}
					/>
				</div>

				<div className='px-3 pb-3'>
					<span className='error-text BodyTwoLight'>
						{formControl?.error || ''}{' '}
					</span>
				</div>
				<SubmitButtonsField
					rootClass='bg-white p-3'
					title={'Save'}
					onCancel={closeModal}
					onSubmit={onSubmit}
					isLoading={formControl.loading}
				/>
			</div>
		</div>
	);
}

export default ClientWorkflowModal;
