import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ButtonSquare from '../../Buttons/ButtonSquare';
import WorkflowCategories from './WorkflowCategories';
import * as builderActions from '../../../redux/actions/workflow-builder-actions';
import * as teamActions from '../../../redux/actions/team-management.actions';
import * as clientActions from '../../../redux/actions/client-actions';
import { useHistory, useParams } from 'react-router-dom';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import Sidebar from '../../sidebar/SideBar';
import ContentLoader from 'react-content-loader';
import NavLink from '../../sidebar/NavLink';
import { routes } from '../../../utilities/routes';
import useBreadCrumb from '../../../hooks/useBreadCrumb';

function WorkflowBrowser() {
	const dispatch = useDispatch();
	const history = useHistory();
	const { id } = useParams();
	const { BreadCrumbs } = useBreadCrumb();

	const [activeTab, setActiveTab] = useState(0);
	const [isChecked, setIsChecked] = useState([]);

	const workflowsToManage = useSelector(
		(state) => state.WorkflowBuilder.workflowsToManage,
	);
	let workflowCategories = useSelector(
		(state) => state.WorkflowBuilder.workflowCategories,
	);

	let activeWorkflows = useSelector(
		(state) => state.WorkflowBuilder.activeWorkflows,
	);
	workflowCategories = workflowCategories
		.map((category) => {
			// Sort workflows within each category by name
			category.workflows.sort((x, y) => {
				return x.name.localeCompare(y.name);
			});
			return category;
		})
		.sort((a, b) => {
			// Sort categories by name
			if (a.name === null || a.name === '') {
				return -1;
			} else if (b.name === null || b.name === '') {
				return 1;
			} else {
				return a.name.localeCompare(b.name);
			}
		});

	activeWorkflows = activeWorkflows.sort((a, b) => {
		return a.workflow.name.localeCompare(b.workflow.name);
	});

	const client = useSelector((state) => state.clientReducer.client);
	const loading = useSelector((state) => state.WorkflowBuilder.loading);

	let workflows = useSelector((state) => state.WorkflowBuilder.workflows);

	workflows = workflows.map((workflow) => {
		return {
			name: workflow.displayName,
			sectionId: workflow.componentName,
		};
	});

	const handleCheckBoxes = (workflow) => {
		if (isChecked?.includes(workflow)) {
			setIsChecked(isChecked?.filter((item) => item !== workflow));
		} else {
			setIsChecked([...isChecked, workflow]);
		}
	};

	const ChangeActiveTab = (e) => {
		setActiveTab(parseInt(e.target.id));
	};

	const clientProfileNavigation = (clientId) => {
		history.push(`${routes['client-profile']}${clientId}`);
	};

	const handleMultipleWorkflows = () => {
		let workflowArr = [...isChecked];

		workflowArr = workflowArr?.map((workflow) => {
			return {
				...workflow,
				status: 'draft',
				task_templates: workflow.task_templates.map((task) => {
					return {
						...task,
						budgeted_hours: parseFloat(task.budgeted_hours) || 0.1,
					};
				}),
			};
		});
		dispatch(builderActions.activateWorkflow(id, workflowArr, null, history));
	};

	useEffect(() => {
		dispatch(clientActions.getClient(id));
		dispatch(builderActions.getWorkflowsList(client?.id ? client.id : id));
		dispatch(
			builderActions.getActiveWorkflowsList(client?.id ? client.id : id),
		);
		dispatch(builderActions.getWorkflowCategories());
		dispatch(builderActions.getAssignedWorkflowCategories());
	}, [dispatch]);

	useEffect(() => {
		client?.team_client_permissions[0]?.team_id &&
			dispatch(
				teamActions.getTeamMembers(client?.team_client_permissions[0]?.team_id),
			);
	}, [dispatch, client?.team_client_permissions[0]?.team_id]);

	return (
		<div className='AppBody'>
			<Sidebar title='Client Management'>
				<div className='SidebarContentWrapper'>
					<div className='User-filters'>
						<div className='side-menu BodyTwoLight d-flex flex-column align-items-start'>
							{/* {workflowCategories
								?.sort((a, b) => (a.id > b.id ? 1 : 0))
								?.map((workflow, i) => {
									return (
										<span
											key={i}
											id={i}
											className={
												activeTab === workflow.name
													? 'active d-flex flex-row align-items-center'
													: 'side-menu-link d-flex flex-row align-items-center'
											}
											style={{ gap: '10px', paddingBlock: '10px' }}
										>
											<div className='dot'></div>
											<NavLink
												initial={workflow[0]?.name}
												setActiveTab={(tab) => setActiveTab(tab)}
												href={workflow.name}
												name={workflow.name}
											/>
										</span>
									);
								})} */}
							{workflowCategories?.map((workflow, i) => {
								return (
									<span
										key={i}
										id={i}
										className={
											Number(activeTab) === i
												? 'active d-flex flex-row align-items-center'
												: 'side-menu-link d-flex flex-row align-items-center'
										}
										style={{
											gap: '10px',
											paddingBlock: '10px',
										}}
									>
										<div className='dot'></div>
										<a
											onClick={ChangeActiveTab}
											href={`#${workflow?.name}`}
											id={i}
											className='BodyTwoLight text-left'
										>
											{workflow?.name}
										</a>
									</span>
								);
							})}
						</div>
					</div>
				</div>
			</Sidebar>
			<div className='workflow-browser d-flex flex-column'>
				<div
					className='py-2'
					style={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						cursor: 'pointer',
						color: '#FB7A03',
					}}
				>
					<ArrowLeftIcon />
					<BreadCrumbs>
						<h2
							className='HeadlineThreeBook text-left'
							onClick={() => clientProfileNavigation(client.id)}
						>
							{client?.dba == '' ? client?.company_name : client?.dba}
						</h2>
					</BreadCrumbs>
				</div>
				<div className='workflow-browser-header'>
					<span className='HeadlineOneBold'>Workflow Browser</span>
					<div>
						<ButtonSquare
							className='full-width'
							disabled={!isChecked.length || loading}
							onClick={handleMultipleWorkflows}
						>
							Save as Draft
						</ButtonSquare>
					</div>
				</div>
				<div>
					{loading ? (
						<ContentLoader
							speed={2}
							width={1366}
							height={900}
							viewBox='0 0 1366 900'
							backgroundColor='#e6e6e6'
							foregroundColor='#C0BFBF'
						>
							<rect x='0' y='19' rx='4' ry='4' width='1366' height='56' />
							<rect x='0' y='87' rx='4' ry='4' width='2' height='1' />
							<rect x='0' y='90' rx='4' ry='4' width='1366' height='300' />
							<rect x='0' y='420' rx='4' ry='4' width='1366' height='56' />
							<rect x='0' y='491' rx='4' ry='4' width='1366' height='300' />
						</ContentLoader>
					) : (
						<>
							{workflowCategories?.length > 0 &&
								workflowCategories?.map((category, index) => {
									return (
										<WorkflowCategories
											key={index}
											category={category}
											workflowsToManage={workflowsToManage}
											activeWorkflows={activeWorkflows}
											isChecked={isChecked}
											handleCheckBoxes={handleCheckBoxes}
										/>
									);
								})}
						</>
					)}
				</div>
			</div>
		</div>
	);
}

export default WorkflowBrowser;
