import { memo } from 'react';

function NewSidebarItem({ isActive, uniqueId, label, setActiveTab }) {
	return (
		<div
			className={`main-sidebar-item w-100 d-flex ${isActive && ' active'}`}
			key={uniqueId}
			onClick={setActiveTab(uniqueId)}
		>
			{isActive && <span className='nav-item-side-line'></span>}
			<span
				className={`new-sidebar-item text-left d-flex flex-row align-items-center  
        ${isActive && ' active'}
        `}
				style={{ gap: '8px' }}
			>
				<div className='dot'></div>

				<a className='BodyTwoLight pt-1 w-100'>{label}</a>
			</span>
		</div>
	);
}

export default memo(NewSidebarItem);
