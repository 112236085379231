import { secure_instance } from '../../../axios/axios-config';

export const HANDLE_CHANGE = '[ZAPIER] HANDLE_CHANGE';

export const oauthAuthorized = (data) => {
	return async () => {
		try {
			const params = new URLSearchParams(data);

			const request = await secure_instance.request({
				url: `v1/oauth/authorize?client_id=${params.get(
					'client_id',
				)}&response_type=${params.get('response_type')}&state=${params.get(
					'state',
				)}&redirect_uri=${params.get('redirect_uri')}`,
				method: 'POST',
			});

			window.location.href = request.data.redirect_uri;
		} catch (error) {
			//
		}
	};
};
