import React, { useState } from 'react';
import { Zoom } from '@mui/material';
import Dialog from '@material-ui/core/Dialog';
import FormButtonOne from '../../Buttons/FormButtonOne';

const Transition = React.forwardRef(function Transition(props, ref) {
	return (
		<Zoom in={true} ref={ref} {...props} style={{ transitionDelay: '50ms' }} />
	);
});

const UpdateMeetingTaskAlert = ({
	closeHandler,
	submitHandler,
	isRecurrenceUpdate,
	open,
}) => {
	const [selected, setSelected] = useState(1);

	const options = [
		{ id: 1, value: 'Edit this meeting' },
		{ id: 2, value: 'Edit this and future meetings' },
	];

	const handleChange = (event) => {
		setSelected(event.target.value);
	};

	return (
		<Dialog TransitionComponent={Transition} open={open} onClose={closeHandler}>
			<div className='my-task-delete-alert'>
				<div className='content'>
					<div className='HeadlineTwoBold dark-text form-title'>
						Edit Recurring Meetings?
					</div>
					<div className='d-flex flex-column w-100' style={{ gap: 10 }}>
						<div
							style={{ gap: '0.5em' }}
							className='d-flex flex-column align-items-start justify-content-start'
						>
							{isRecurrenceUpdate ? (
								<label className='BodyTwoLight approval-text align-self-center w-100 m-0'>
									This will impact both current and future recurrences.
								</label>
							) : (
								options?.map((opt, i) => {
									return (
										<div
											key={i}
											style={{ gap: '1em' }}
											className='d-flex flex-row align-items-center justify-content-center'
										>
											<input
												id={opt?.id}
												type='radio'
												value={opt?.id}
												checked={opt?.id == selected}
												onChange={handleChange}
											/>
											<label className='BodyTwoLight approval-text align-self-center w-100 m-0'>
												{opt.value}
											</label>
										</div>
									);
								})
							)}
						</div>
					</div>
				</div>
				<div className='action-buttons'>
					<FormButtonOne
						onClick={closeHandler}
						type='button'
						className='secondary-btn-light'
					>
						Cancel
					</FormButtonOne>

					<FormButtonOne
						onClick={() =>
							submitHandler({
								update_future: isRecurrenceUpdate
									? isRecurrenceUpdate
									: selected == 2,
							})
						}
						type='button'
					>
						Confirm
					</FormButtonOne>
				</div>
			</div>
		</Dialog>
	);
};

export default UpdateMeetingTaskAlert;
