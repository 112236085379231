import React, { useState, useEffect } from 'react';

function EditableSelectGroup({ Edit, label, value, options, onChange }) {
	// CheckBox
	const [isCheck, setIsCheck] = useState(value);

	const handleClick = (e) => {
		const { id, checked } = e.target;
		if (checked && id) {
			console.log([...isCheck, id]);
			onChange([...isCheck, id]);
		} else {
			onChange(isCheck.filter((item) => item !== id));
			console.log(isCheck.filter((item) => item !== id));
		}
	};

	useEffect(() => {
		setIsCheck(value);
	}, [value]);

	return (
		<div style={{ maxHeight: '8em', minHeight: 'fit-content' }}>
			{Edit ? (
				<div
					className='w-100 h-100 TeamMultiPickList'
					style={{
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<label className='w-100 m-0 custom-label text-left'>{label}</label>
					<div
						className='w-100 h-100'
						style={{
							display: 'grid',
							gridTemplateColumns: 'auto auto',
							backgroundColor: '#f6f5f2',
							padding: '0.3em',
							overflowX: 'hidden',
							overflowY: 'auto',
							boxSizing: 'border-box',
						}}
					>
						{options.map((choice, index) => {
							return (
								<div
									style={{ height: 'fit-content' }}
									key={index}
									className='ItemWrapper  the-item-wrapper'
								>
									<input
										id={choice.value}
										name={index}
										type='checkbox'
										className='multi-select-checkbox'
										onChange={handleClick}
										checked={isCheck.includes(choice.value)}
									/>
									<div className='w-100'>
										<h4 className='BodyTwoLight input-font text-dark ml-2'>
											{choice.label}
										</h4>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			) : (
				<div className='ProfilePage d-flex flex-column w-100'>
					<label className='text-left m-0'>{label}</label>
					{options.map((o, i) => {
						return (
							<h3 key={i} className='BodyOneSherif text-left'>
								{value.find((v) => v === o?.value) && o?.label}
							</h3>
						);
					})}
				</div>
			)}
		</div>
	);
}

export default EditableSelectGroup;
