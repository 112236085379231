import { ReactComponent as NoCheck } from '../../assets/icons/circle.svg';
import { ReactComponent as Check } from '../../assets/icons/circleCheck.svg';
import { LinearProgress } from '@mui/material';

function WorkflowProgressBarSection({ key, title, condition, hideBar }) {
	return (
		<div key={key} className='progress-bar-section'>
			<div
				className='d-flex align-items-center w-100'
				style={{
					gap: '12px',
				}}
			>
				{condition ? <Check /> : <NoCheck />}
				{!hideBar && (
					<div className='w-100'>
						<LinearProgress
							sx={{
								backgroundColor: '#FED0A5',
								borderRadius: '50px',
								'& .MuiLinearProgress-bar': {
									backgroundColor: '#FB7A03',
								},
							}}
							variant='determinate'
							value={condition ? 100 : 0}
						/>
					</div>
				)}
			</div>

			<div className='d-flex flex-column align-items-start' style={{ gap: '4px' }}>
				<span className='BodyTwoBold dark-text'>{title}</span>
				<span className='BodyThreeLight dark-text'>
					{condition ? 'Completed' : 'Pending'}
				</span>
			</div>
		</div>
	);
}

export default WorkflowProgressBarSection;
