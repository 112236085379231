import React from 'react';

const Card = ({
	children,
	id,
	className,
	draggable,
	clickHanlder,
	withinColumnIndex,
	style,
}) => {
	const dragOver = (e) => {
		e.stopPropagation();
	};

	const dragStart = (e) => {
		const target = e.target;
		e.dataTransfer.setData('card_id', target.id);
		e.effectAllowed = 'copyMove';
	};

	return (
		<div
			className={className}
			id={id}
			data-element-index={withinColumnIndex}
			onDragStart={dragStart}
			onDragOver={dragOver}
			draggable={draggable}
			onClick={clickHanlder}
			style={style}
		>
			{children}
		</div>
	);
};

export default Card;
