/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useState } from 'react';
import useEditTimeFormat from '../../hooks/useEditTimeFormat';
import {
	Member,
	TASK_STATUSES,
	convertDecimalToTime,
	convertToDecimal,
	executivePermissions,
} from '../../utilities/utilities';
import { CircularProgress, Tooltip } from '@mui/material';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { ReactComponent as Check } from '../../assets/icons/Check.svg';
import { ReactComponent as Edit } from '../../assets/icons/PencilSimpleColor.svg';
import { ReactComponent as Calendar } from '../../assets/icons/Calendarcolor.svg';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import EditableText from '../Inputs/EditableText';
import Restricted from '../Permissions/Restricted';
import DropDown from '../Inputs/DropDown';
import { getUserSettings } from '../../hooks/getUserSettings';

function TaskScheduling({
	task_id,
	title,
	budgeted_hours,
	start_date,
	due_date,
	required_days,
	isAdhoc,
	extended_date,
	team_id,
	task_status,
	task_repeat_type,
	approval_from,
	handleSaveDetails,
}) {
	// States
	const [form, setForm] = useState({
		title: title,
		required_days: required_days,
		budgeted_hours: budgeted_hours,
		start_date: start_date,
		due_date: due_date,
		approval_from: approval_from,
	});
	const [edit, setEdit] = useState(false);
	const user = useSelector((state) => state.authReducer.user);
	const [actionLoading, SetActionLoading] = useState(false);
	let teamMembers = useSelector((state) => state.teamReducer.teamMembers);
	const taskAssignees = useSelector((state) => state.workSpaceReducer.taskAssignees);
    const userDetails = useSelector((state) => state.authReducer.userDetails);
    const { short_date_format }  = getUserSettings(userDetails);

	const assignee = taskAssignees?.length > 0 ? taskAssignees[0] : null;

	if (assignee !== null && !teamMembers?.some((member) => member.user_id == assignee.user_id)) {
		teamMembers = [
			...teamMembers,
			{
				...assignee?.user_profile,
				user_id: assignee?.user_id,
				name: assignee?.user_profile?.full_name,
			},
		];
	}

	// Hooks
	const { inputValue, setInputValue, handleInputChange } = useEditTimeFormat(budgeted_hours);

	// Event Handlers

	const handleEditClick = () => {
		setEdit(!edit);
	};

	const handleInputBlur = () => {
		let value = 0;
		const name = 'budgeted_hours';

		if (inputValue === '') {
			value = 0.1;
		} else if (inputValue.includes(':')) {
			value = convertToDecimal(inputValue);
		} else if (!isNaN(inputValue)) {
			value = parseFloat(inputValue);
		}

		const formattedValue = convertDecimalToTime(value);
		setInputValue(formattedValue);
		setForm({
			...form,
			budgeted_hours: value,
		});
	};

	const handleFormChange = (event) => {
		let { name, value } = event.target;
        console.log(value, 'valueCheck');
		if (name == ('assignee_user_id' || 'approval_from')) {
			value = value !== null ? Number(value) : null;
		}
		setForm({
			...form,
			[name]: value,
		});
	};

	const handleRequiredDays = (event) => {
		const { name, value } = event.target;
		const due_date = moment(form.start_date).add(value, 'days').subtract(1, 'day');
		setForm({
			...form,
			[name]: Number(value),
			due_date: due_date,
		});
	};

	const handleDateChange = (name) => (value) => {
		// if (isAdhoc) {
		// 	if (name == 'start_date') {
		// 		const due_date = moment(value).add(form.required_days, 'days').subtract(1, 'day');
		// 		setForm({
		// 			...form,
		// 			[name]: value,
		// 			due_date: due_date,
		// 		});
		// 	}
		// } else
		setForm({
			...form,
			[name]: moment(value).format('YYYY-MM-DD'),
		});
	};

	const handleSaveTaskDetails = () => {
		handleEditClick();
		let taskDetails = {};
		let temp_extended_date = null;
		if (extended_date) {
			if (moment(extended_date).isBefore(form?.start_date)) {
				temp_extended_date = form.start_date;
			} else if (moment(extended_date).isAfter(form?.due_date)) {
				temp_extended_date = form.due_date;
			} else {
				temp_extended_date = form.extended_date;
			}
		} else {
			temp_extended_date = null;
		}

		taskDetails = {
			id: task_id,
			budgeted_hours: form.budgeted_hours,
			start_date: form.start_date,
			due_date: form.due_date,
			extended_date: temp_extended_date,
			approval_from: form.approval_from ? Number(form.approval_from) : null,
			is_approval_required: form.approval_from ? true : false,
			assignee_user_id: form.assignee_user_id,
		};

		SetActionLoading(true);
		handleSaveDetails(taskDetails, assignee, SetActionLoading);
	};

	const saveAdhocTaskDetails = () => {
		handleEditClick();
		let taskDetails = null;
		let temp_extended_date;
		const due_date = moment(form.start_date).add(form.required_days, 'days').subtract(1, 'day');
		if (extended_date) {
			if (moment(extended_date).isBefore(form.start_date)) {
				temp_extended_date = form.start_date;
			} else if (moment(extended_date).isAfter(due_date)) {
				temp_extended_date = due_date;
			} else {
				temp_extended_date = extended_date;
			}
		} else {
			temp_extended_date = null;
		}
		taskDetails = {
			id: task_id,
			title: form.title,
			budgeted_hours: form.budgeted_hours,
			start_date: form.start_date,
			due_date: form.due_date,
			extended_date: temp_extended_date,
			approval_from: form.approval_from ? Number(form.approval_from) : null,
			required_days: form?.required_days ? form.required_days : Number(form.required_days),
			assignee_user_id: form.assignee_user_id,
		};
		SetActionLoading(true);
		handleSaveDetails(taskDetails, assignee, SetActionLoading);
	};
	useEffect(() => {
		setForm({
			...form,
			assignee_user_id: taskAssignees?.length > 0 ? taskAssignees[0]?.user_id : null,
		});
	}, [taskAssignees]);

	return (
		<div className='edit-task-details-container flex-row justify-content-between w-100 h-100 p-0'>
			<div className='w-100'>
				{isAdhoc && (
					<div className='new-edit-input-field w-100'>
						<span className='BodyTwoLight' style={{ color: '#7C7C7C' }}>
							Task Title
						</span>
						<EditableText
							type='text'
							name='title'
							style={{ borderBottom: edit ? '1px solid #cfcfcf' : '', padding: 0 }}
							Edit={edit}
							title='Task Title'
							onChange={handleFormChange}
							value={form?.title}
						/>
					</div>
				)}
				<div className='new-edit-input-field w-100'>
					<span className='BodyTwoLight' style={{ color: '#7C7C7C' }}>
						Assignee:{' '}
					</span>
					{edit ? (
						<div className='new-edit-input-field m-0' style={{ width: '180px' }}>
							<DropDown
								edittable
								onChange={handleFormChange}
								name='assignee_user_id'
								defaultValue={Number(form?.assignee_user_id)}
								options={teamMembers
									?.filter(
										(teamMember) => teamMember.user_id != form?.approval_from,
									)
									?.map((teamMember) => {
										return {
											value: teamMember?.user_id,
											label: teamMember?.name,
											is_active: teamMember?.is_active,
										};
									})}
								value={Number(form?.assignee_user_id)}
								style={{ borderBottom: '1px solid #cfcfcf' }}
								className={'BodyTwoLight'}
							/>
						</div>
					) : (
						<span className='BodyTwoLight'>
							{assignee?.user_profile?.full_name || 'No'}
						</span>
					)}
				</div>
				<div className='new-edit-input-field w-100'>
					<span className='BodyTwoLight' style={{ color: '#7C7C7C' }}>
						Require Approval:{' '}
					</span>
					{edit ? (
						<div className='new-edit-input-field m-0' style={{ width: '180px' }}>
							<DropDown
								edittable
								onChange={handleFormChange}
								name='approval_from'
								defaultValue={Number(form?.approval_from)}
								options={teamMembers
									?.filter(
										(teamMember) =>
											teamMember.user_id != form?.assignee_user_id &&
											teamMember.role_id !== Member.teamMemberId &&
											teamMember.role_id !== Member.externalCollaborator,
									)
									?.map((teamMember) => {
										return {
											value: teamMember?.user_id,
											label: teamMember?.name,
											is_active: teamMember?.is_active,
										};
									})}
								value={Number(form?.approval_from)}
								style={{ borderBottom: '1px solid #cfcfcf' }}
								className={'BodyTwoLight'}
							/>
						</div>
					) : (
						<span className='BodyOneLight'>
							{teamMembers?.find(
								(teamMember) => teamMember?.user_id == form?.approval_from,
							)?.name || 'No'}
						</span>
					)}
				</div>
				<Tooltip
					title='Entered value will be converted to hh:mm format'
					placement='bottom-start'
				>
					<div className='new-edit-input-field' style={{ width: '180px' }}>
						<span className='BodyTwoLight' style={{ color: '#7C7C7C' }}>
							Budgeted Hours
						</span>
						<EditableText
							type='text'
							name='budgeted_hours'
							style={{ borderBottom: edit ? '1px solid #cfcfcf' : '', padding: 0 }}
							// min={0}
							Edit={edit}
							title='Budgeted Hours'
							onChange={handleInputChange}
							value={inputValue}
							onBlur={handleInputBlur}
							placeholderText='HH : MM'
						/>
					</div>
				</Tooltip>
				<div className='new-edit-input-field w-100'>
					<div className='d-flex'>
						<span className='BodyTwoLight' style={{ color: '#7C7C7C' }}>
							Start Date
						</span>
					</div>
					{edit ? (
                            <KeyboardDatePicker
							autoOk
							disableToolbar
							name='start_date'
							value={form.start_date || null}
							onChange={handleDateChange('start_date')}
							keyboardIcon={<Calendar />}
							InputProps={{
								style: {
									fontSize: 14,
									height: 18,
									fontFamily: 'CentraLight',
									fontWeight: 'normal',
									background: 'white',
								},
							}}
							style={{ width: '180px' }}
							format={short_date_format}
							className='BodyTwoLight'
							maxDate={form.due_date}
						/>
					) : (
						<span className='BodyTwoLight'>
							{moment(form.start_date).format(short_date_format)}
						</span>
					)}
				</div>
				{isAdhoc ? (
					<div className='new-edit-input-field'>
						<span className='BodyTwoLight' style={{ color: '#7C7C7C' }}>
							Required Days
						</span>
						<EditableText
							type='number'
							name='required_days'
							style={{ borderBottom: edit ? '1px solid #cfcfcf' : '', padding: 0 }}
							min={1}
							Edit={edit}
							title='Days to complete'
							onChange={handleRequiredDays}
							value={form.required_days}
						/>
					</div>
				) : (
					<div className='new-edit-input-field w-100'>
						<div className='d-flex'>
							<span className='BodyTwoLight' style={{ color: '#7C7C7C' }}>
								Due Date
							</span>
						</div>
						{edit ? (
                                <KeyboardDatePicker
								autoOk
								disableToolbar
								name='start_date'
								value={form.due_date || null}
								onChange={handleDateChange('due_date')}
								keyboardIcon={<Calendar />}
								InputProps={{
									style: {
										fontSize: 14,
										height: 18,
										fontFamily: 'CentraLight',
										fontWeight: 'normal',
										background: 'white',
									},
								}}
								style={{ width: '180px' }}
								format={short_date_format}
								className='BodyTwoLight'
								minDate={form.start_date || null}
							/>
						) : (
							<span className='BodyTwoLight'>
								{moment(form.due_date).format(short_date_format)}
							</span>
						)}
					</div>
				)}
			</div>
			<Restricted
				AllowedUsers={[...executivePermissions, Member.teamManagerId]}
				userTeamRole={
					team_id
						? [
								user?.user_roles?.find((item) =>
									item?.role_scopes
										?.map((role) => role.team_id)
										?.includes(team_id),
								)?.role_id,
						  ]
						: []
				}
			>
				{task_status !== TASK_STATUSES?.COMPLETED && !(isAdhoc && task_repeat_type) && (
					<div
						onClick={
							edit
								? isAdhoc
									? saveAdhocTaskDetails
									: handleSaveTaskDetails
								: handleEditClick
						}
						className='position-sticky'
						style={{ cursor: 'pointer', top: '0' }}
					>
						{edit ? (
							<Check width={16} height={16} />
						) : actionLoading ? (
							<CircularProgress size={20} sx={{ color: '#fb7a03' }} disableShrink />
						) : (
							<Edit />
						)}
					</div>
				)}
			</Restricted>
		</div>
	);
}

export default TaskScheduling;
