import { Fragment, useState, useRef, useEffect } from 'react';
import { DatePicker } from '@material-ui/pickers';
import { MenuItem, Select } from '@mui/material';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getUserSettings } from '../../hooks/getUserSettings';

function DateRangePicker({ value, onChange, style, className, headerTitle }) {
  const [open, setOpen] = useState(false);
  const selectRef = useRef(null);
  const userDetails = useSelector((state) => state.authReducer.userDetails);
  const { date_format }  = getUserSettings(userDetails);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				selectRef.current &&
				!selectRef.current.contains(event.target) &&
				!event.target.classList.contains('MuiPickersCalendar-week') &&
				!event.target.classList.contains(
					'MuiPickersCalendarHeader-switchHeader',
				)
			) {
				handleClose();
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [selectRef]);

	return (
		<Fragment>
			<Select
				disableUnderline
				displayEmpty
				variant='standard'
				sx={{
					borderRadius: '4px',
					background: '#EEF2F3',
					color: 'black',
					height: '22px',
					padding: '5px 5px 0 5px',
                    ...style
				}}
                className={className}
				MenuProps={{
					onClose: (event) => {
						event.stopPropagation();
					},
				}}
				renderValue={() => (
					<div className='BodyTwoLight' style={{ marginTop: '5px', display:'flex' }}>
						<span style={{ color: '#696F6C' }} className='pr-1'>{headerTitle && 'Date Range:' }From</span>
						{moment(value.from).format(date_format)}{' '}
						<span style={{ color: '#696F6C' }} className='px-1'>To</span>
						{moment(value.to).format(date_format)}
					</div>
				)}
				ref={selectRef}
				onClick={handleOpen}
				onBlur={handleClose}
				open={open}
			>
				<MenuItem>
					<div className='d-flex flex-column'>
						{ headerTitle && <div className='px-3 BodyTwoLight'>From :</div>}
						<CustomDatePicker
							autoOk
							maxDate={value.to}
							value={value.from}
							onChange={(e) => {
								onChange(e, 'from');
							}}
							format={date_format}
							inputVariant='outlined'
							variant='static'
							onClose={handleClose}
                            disableToolbar
						/>
					</div>
					<div className='d-flex flex-column'>
						{headerTitle &&<div className='px-3 BodyTwoLight'>To :</div>}
						<CustomDatePicker
							autoOk
							minDate={value.from}
							value={value.to}
							onChange={(e) => {
								onChange(e, 'to');
							}}
							format={date_format}
							inputVariant='outlined'
							variant='static'
							onClose={handleClose}
                            disableToolbar
						/>
					</div>
				</MenuItem>
			</Select>
		</Fragment>
	);
}

export default DateRangePicker;

function CustomDatePicker(props) {
	return <DatePicker {...props} />;
}
