import * as Actions from '../actions/client-billing-actions';

const inititalState = {
	clientBilling: [],
};

const ClientBillingReducer = (state = inititalState, action) => {
	const { payload, type } = action;

	switch (type) {
		case Actions.GET_CLIENT_BILLING:
			return (state = {
				...state,
				clientBilling: payload,
			});

		case Actions.ADD_CLIENT_BILLING:
			console.log(state.clientBilling, '123456');
			return (state = {
				...state,
				clientBilling: [...state.clientBilling, payload],
			});
		case Actions.UPDATE_CLIENT_BILLING:
			return (state = {
				...state,
				clientBilling: state.clientBilling.map((clientbilling) => {
					if (clientbilling._id === payload._id) {
						return payload;
					}
					return clientbilling;
				}),
			});

		default:
			return state;
	}
};

export default ClientBillingReducer;
