
function DisputeResolutionBindingArbitration() {
	return (
		<p className='BodyTwoLight'>
			<strong>
				Please read the following section carefully because it requires you to
				arbitrate certain disputes and claims with Levvy and limits the manner
				in which you can seek relief from us, unless you opt out of arbitration
				by following the instructions set forth below. No class or
				representative actions or arbitrations are allowed under this
				arbitration agreement. In addition, arbitration precludes you from suing
				in court or having a jury trial. <br />
				<br /> No Representative Actions. You and Levvy agree that any dispute
				arising out of or related to these Terms or our Site is personal to you
				and Levvy and that any dispute will be resolved solely through
				individual action, and will not be brought as a class arbitration, class
				action or any other type of representative proceeding.
			</strong>
			<br /> <br />
			<strong>Arbitration of Disputes.</strong> Except for small claims disputes
			in which you or Levvy seeks to bring an individual action in small claims
			court located in the county of your billing address or disputes in which
			<strong>
				{' '}
				you or Levvy seeks injunctive or other equitable relief for the alleged
				unlawful use of intellectual property, you and Levvy waive your rights
				to a jury trial and to have any dispute arising out of or related to
				these Terms or our Site resolved in court.
			</strong>{' '}
			Instead, for any dispute or claim that you have against Levvy or relating
			in any way to the Site, you agree to first contact Levvy and attempt to
			resolve the claim informally by sending a written notice of your claim (“
			<strong>Notice</strong>”) to Levvy by email at{' '}
			<span className='primary-text'>hello@levvy.com</span> or by certified mail
			addressed to Levvy Inc. 388 Bridge Street #49B, Brooklyn, NY 11201. The
			Notice must (a) include your name, residence address, email address, and
			telephone number; (b) describe the nature and basis of the claim; and (c)
			set forth the specific relief sought. Our notice to you will be similar in
			form to that described above. If you and Levvy cannot reach an agreement
			to resolve the claim within thirty (30) days after such Notice is
			received, then either party may submit the dispute to binding arbitration
			administered by JAMS or, under the limited circumstances set forth above,
			in court. All disputes submitted to JAMS will be resolved through
			confidential, binding arbitration before one arbitrator. Arbitration
			proceedings will be held in New York, New York, unless you are a consumer,
			in which case you may elect to hold the arbitration in your county of
			residence in accordance with the JAMS Streamlined Arbitration Rules and
			Procedures (“<strong>JAMS Rules</strong>
			”). For purposes of this section, you will be deemed a “
			<strong>consumer</strong>” if you use the Site for your personal, family,
			or household purposes. The most recent version of the JAMS Rules are
			available on the{' '}
			<a
				href='https://www.jamsadr.com/rules-streamlined-arbitration/'
				target='_blank'
				className='primary-text'
                rel="noreferrer"
			>
				JAMS website
			</a>{' '}
			and are hereby incorporated by reference. You either acknowledge and agree
			that you have read and understand the JAMS Rules or waive your opportunity
			to read the JAMS Rules and waive any claim that the JAMS Rules are unfair
			or should not apply for any reason. <br />
			<br /> You and Levvy agree that these Terms affect interstate commerce and
			that the enforceability of this Section 12 will be substantively and
			procedurally governed by the Federal Arbitration Act, 9 U.S.C. § 1, et
			seq. (the “<strong>FAA</strong>”), to the maximum extent permitted by
			applicable law. As limited by the FAA, these Terms and the JAMS Rules, the
			arbitrator will have exclusive authority to make all procedural and
			substantive decisions regarding any dispute and to grant any remedy that
			would otherwise be available in court, including the power to determine
			the question of arbitrability. The arbitrator may conduct only an
			individual arbitration and may not consolidate more than one individual’s
			claims, preside over any type of class or representative proceeding, or
			preside over any proceeding involving more than one individual. <br />
			<br />
			The arbitrator, Levvy, and you will maintain the confidentiality of any
			arbitration proceedings, judgments and awards, including, but not limited
			to, all information gathered, prepared, and presented for purposes of the
			arbitration or related to the dispute(s) therein. The arbitrator will have
			the authority to make appropriate rulings to safeguard confidentiality,
			unless the law provides to the contrary. The arbitration will allow for
			the discovery or exchange of non-privileged information relevant to the
			dispute. The duty of confidentiality does not apply to the extent that
			disclosure is necessary to prepare for or conduct the arbitration hearing
			on the merits, in connection with a court application for a preliminary
			remedy or in connection with a judicial challenge to an arbitration award
			or its enforcement, or to the extent that disclosure is otherwise required
			by law or judicial decision. <br />
			<br /> You and Levvy agree that for any arbitration you initiate, you will
			pay the filing fee (up to a maximum of $250 if you are a consumer), and
			Levvy will pay the remaining JAMS fees and costs. For any arbitration
			initiated by Company, Levvy will pay all JAMS fees and costs. If, however,
			the arbitrator finds that either the substance of your claim or the relief
			sought is frivolous or brought for an improper purpose (as measured by the
			standards in Federal Rule of Civil Procedure 11(b)), then the payment of
			all JAMS fees shall be governed by the JAMS Rules. In such cases, the
			arbitrator may direct you to reimburse Levvy for amounts that Levvy paid
			on your behalf. You and Levvy agree that the state or federal courts of
			the State of New York and the United States sitting in New York, New York
			have exclusive jurisdiction over any appeals and the enforcement of an
			arbitration award.
			<br />
			<br />
			<strong>
				{' '}
				Any claim arising out of or related to these Terms or our Site must be
				filed within one year after such claim arose; otherwise, the claim is
				permanently barred, which means that you and Levvy will not have the
				right to assert the claim.
			</strong>{' '}
			<br />
			<br />
			If any portion of this Section 12 is found to be unenforceable or unlawful
			for any reason, (a) the unenforceable or unlawful provision shall be
			severed from these Terms; (b) severance of the unenforceable or unlawful
			provision shall have no impact whatsoever on the remainder of this Section
			12 or the parties’ ability to compel arbitration of any remaining claims
			on an individual basis pursuant to this Section 12; and (c) to the extent
			that any claims must therefore proceed on a class, collective,
			consolidated, or representative basis, such claims must be litigated in a
			civil court of competent jurisdiction and not in arbitration, and the
			parties agree that litigation of those claims shall be stayed pending the
			outcome of any individual claims in arbitration. Further, if any part of
			this Section 12 is found to prohibit an individual claim seeking public
			injunctive relief, that provision will have no effect to the extent such
			relief is allowed to be sought out of arbitration, and the remainder of
			this Section 12 will be enforceable.
		</p>
	);
}

export default DisputeResolutionBindingArbitration;
