
import { lightGreen } from '../Style.jsx';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { Box } from '@mui/material';
import { executivePermissions } from '../../utilities/utilities.js';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Restricted from '../Permissions/Restricted.jsx';
import HeadingTwo from '../TypoGraphy/HeadingTwo.jsx';
import SkeletonLoading from '../Others/SkeletonLoading.jsx';
import useBreadCrumb from '../../hooks/useBreadCrumb.jsx';
import { routes } from '../../utilities/routes.js';

function ProfileBar({ BarText, accountSettings, onArchieve }) {
	const history = useHistory();
	const { BreadCrumbs } = useBreadCrumb();
	const loading = useSelector((state) => state.teamReducer.loading);

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'flex-start',
				width: accountSettings && '100%',
			}}
		>
			{!accountSettings && (
				<Box
					className='py-2'
					sx={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						':hover': {
							cursor: 'pointer',
						},
						color: `${lightGreen}`,
					}}
				>
					<ArrowLeftIcon />
					<BreadCrumbs>
						<h2
							className='HeadlineThreeBook text-left'
							onClick={() => history.push(routes.teams)}
						>
							Teams
						</h2>
					</BreadCrumbs>
				</Box>
			)}
			<Box
				sx={{
					backgroundColor: 'white',
					width: '100%',
					padding: '20px 18px',
					borderRadius: '4px 4px 0px 0px',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
					gap: '1.5em',
					boxSizing: 'border-box',
				}}
			>
				<Box>
					<SkeletonLoading
						loading={loading}
						variant='text'
						width={100}
						height={45}
					>
						<HeadingTwo>{BarText}</HeadingTwo>
					</SkeletonLoading>
				</Box>
				<Box
					sx={{
						width: 'fit-content',
						display: 'flex',
						alignItems: 'end',
						gap: '0.5em',
					}}
				>
					<Restricted AllowedUsers={executivePermissions}>
						<SkeletonLoading
							loading={loading}
							variant='text'
							width={100}
							height={50}
						>
							<span
								data-cy='archive-team-opener'
								className='BodyTwoLight primary-text pointer'
								onClick={onArchieve}
							>
								Archive Team
							</span>
						</SkeletonLoading>
					</Restricted>
				</Box>
			</Box>
		</Box>
	);
}

export default ProfileBar;
