// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from 'react';

// Components
import TableHeader from '../Others/TableHeader';
import TableHeaderItem from '../Others/TableHeaderItem';
import {
	TableItemWidth1,
	TableItemWidth2,
	TableItemWidth3,
	TableItemWidth4,
	TableItemWidth5,
} from '../TableSpacing/TableSpacingStyles';
import LoadingOverLay from '../Others/LoadingOverLay';
import TableRowItem from '../Others/TableRowItem';
import useModal from '../Modal/useModal';
import DummyModal from '../Modal/DummyModal';
import AddOfficeLocation from './AddOfficeLocation';

// Material UI
import AddIcon from '@mui/icons-material/Add';
import { Modal } from '@mui/material';
import TableRowButton from '../Others/TableRowButton';
import { ReactComponent as TrashIcon } from '../../assets/icons/Trash-Light.svg';
import { useDispatch, useSelector } from 'react-redux';
import * as clientActions from '../../redux/actions/client-actions';
import { states } from '../DemoData';
import {
	objectBeingDragged,
	standardPermissions,
} from '../../utilities/utilities';
import { ReactComponent as ClientAddress } from '../../assets/icons/client-address.svg';

import { Tooltip } from '@mui/material';
import ConfirmModal from '../Modal/ConfirmModal';
import { ReactComponent as EditIcon } from '../../assets/icons/PencilEdit.svg';
import Restricted from '../Permissions/Restricted';
import { Country }  from 'country-state-city';

function OfficeLocations() {
	const dispatch = useDispatch();

	const { modalIsOpen, toggleModal, ModalName, ModalType } = useModal();
	const [loading, setLoading] = useState(false);
	const { dragValues } = useSelector((state) => state.clientReducer);
	const [indexToDelete, setIndexToDelete] = useState(null);

	const client = useSelector((state) => state.clientReducer.client);
	const userTeamRole = useSelector((state) => state.clientReducer.userTeamRole);


	const removeItem = (index) => {
		ModalType('CONFIRM_MODAL');
		toggleModal();
		setIndexToDelete(index);
	};

	const toggleLoader = (state) => {
		setLoading(state);
	};

	const removeLocation = () => {
		toggleLoader(true);

		const locationToRemove = client?.client_office_locations?.find(
			(c, index) => index == indexToDelete,
		);

		dispatch(
			clientActions.deleteClientLocation(locationToRemove, indexToDelete),
		).then(() => {
			toggleLoader(false);
			toggleModal();
		});
	};

	useEffect(() => {
		if (
			dragValues.beingDragged !== null &&
			dragValues.dragTo !== null &&
			dragValues.beingDragged !== dragValues.dragTo &&
			dragValues.objectBeingDragged === objectBeingDragged.OFFICE_LOCATION
		) {
			dispatch(
				clientActions.changeClientOfficeLocationIndex({
					location: client?.client_office_locations,
					beingDragged: dragValues.beingDragged,
					dragTo: dragValues.dragTo,
				}),
			);
		}
	}, [dragValues]);

    console.log(client, 'client');
	return (
		<>
			<div
				className='d-flex flex-column w-100 align-items-center '
				style={{ gap: '12px' }}
			>
				<span className='text-left w-100 d-flex align-items-center information-header'>
					<span className='d-flex' style={{ gap: '12px' }}>
						<ClientAddress />
						<h3 className='HeadlineThreeBold'>Business Locations</h3>
					</span>
				</span>

				<div className='w-100 d-flex flex-column overflow-auto shadow-border'>
					<div
						style={{
							minWidth: '70vw',
							width: '100%',
						}}
					>
						<TableHeader ClassName='TableHeader flex' border={false}>
                            <TableHeaderItem label='Country' width={TableItemWidth3} />
							<TableHeaderItem label='Street' width={TableItemWidth3} />
							<TableHeaderItem label='Suite / Floor' width={TableItemWidth4} />
							<TableHeaderItem label='City/Town' width={TableItemWidth3} />
							<TableHeaderItem label='State/Province/Region' width={TableItemWidth3} />
							<TableHeaderItem label='Zip Code/Postal Code' width={TableItemWidth4} />

							<Restricted
								AllowedUsers={standardPermissions}
								userTeamRole={[userTeamRole]}
							>
								<TableHeaderItem
									ClassName='TProfile'
									label='Action'
									width={TableItemWidth1}
								/>
							</Restricted>
						</TableHeader>

						{loading ? (
							<div
								style={{
									minHeight: '15vh',
									position: 'relative',
									width: '100%',
								}}
							>
								<LoadingOverLay active={loading} />
							</div>
						) : client?.client_office_locations?.length < 1 ? (
							<div className='TableRow h-100 w-100 d-flex align-items-center justify-content-center'>
								<h4 className='BodyTwoLight m-0 placeholder-text'>
									The Client has no locations
								</h4>
							</div>
						) : (
							client?.client_office_locations?.map((officeLocation, i) => {
								return (
									<div
										key={i}
										data-cy={`client-location-${i + 1}`}
										className='TableRow d-flex align-items-center'
										draggable={true}
										onDragStart={() => {
											dispatch(
												clientActions.setDragValues({
													dragTo: null,
													beingDragged: null,
													objectBeingDragged:
														objectBeingDragged.OFFICE_LOCATION,
												}),
											);
										}}
										onDragEnd={() => {
											dispatch(
												clientActions.setDragValues({ beingDragged: i }),
											);
										}}
										onDragEnter={() => {
											dispatch(clientActions.setDragValues({ dragTo: i }));
										}}
									>
                                        <TableRowItem
											ClassName='text-left'
											label={Country.getCountryByCode(officeLocation.country)?.name || ''}
											width={TableItemWidth3}
											// input={shouldEdit[i]}
										/>
										<TableRowItem
											ClassName='text-left'
											label={officeLocation.hq}
											width={TableItemWidth3}
											// input={shouldEdit[i]}
										>
											{/* <Input
												onChange={(e) => handleOnChange(e, i)}
												label='Street'
												name='hq'
												value={officeLocation.hq}
											/> */}
										</TableRowItem>
										<TableRowItem
											ClassName=' text-left'
											label={officeLocation.street}
											width={TableItemWidth4}
											// input={shouldEdit[i]}
										>
											{/* <Input
												onChange={(e) => handleOnChange(e, i)}
												label='Suite / Floor'
												name='street'
												value={officeLocation.street}
											/> */}
										</TableRowItem>
										<TableRowItem
											ClassName=' text-left'
											label={officeLocation.city}
											width={TableItemWidth3}
											// input={shouldEdit[i]}
										>
											{/* <>
												<Input
													onChange={(e) => handleOnChange(e, i)}
													label='City'
													name='city'
													value={officeLocation.city}
												/>
											</> */}
										</TableRowItem>
										<TableRowItem
											ClassName=' text-left'
											// label={
											// 	states.find(
											// 		(s) =>
											// 			s.value ===
											// 			client?.client_office_locations[i]?.state,
											// 	)?.label
											// }
                                            label={officeLocation?.state}
											width={TableItemWidth3}
											// input={shouldEdit[i]}
										>
											{/* <EditableDropdown
												Edit={edit}
												onChange={(e) => handleOnChange(e, i)}
												label='State'
												name='state'
												className={'text-left'}
												value={states.find(
													(s) =>
														s.value ===
														parseInt(clientOfficeLocations[i]?.state),
												)}
												options={states}
											/> */}
										</TableRowItem>
										<TableRowItem
											ClassName=' text-left'
											label={officeLocation.zip}
											width={TableItemWidth4}
											// input={shouldEdit[i]}
										>
											{/* <Input
												onChange={(e) => handleOnChange(e, i)}
												label='Zip'
												name='zip'
												value={officeLocation.zip}
											/> */}
										</TableRowItem>

										<Restricted
											AllowedUsers={standardPermissions}
											userTeamRole={[userTeamRole]}
										>
											<TableRowButton
												ClassName='d-flex align-items-center justify-content-center mr-0'
												width={TableItemWidth1}
											>
												<div
													className='d-flex flex-row align-items-center'
													style={{
														gap: '7px',
													}}
												>
													<Tooltip title='Edit Location'>
														<EditIcon
															data-cy='client-location-edit'
															fontSize='small'
															style={{
																color: '#fb7a03',
															}}
															onClick={() => {
																// setLocationToEdit({...officeLocation,index:i});
																ModalType('EDIT_LOCATION_MODAL');
																dispatch(
																	clientActions.officeLocationToEdit({
																		...officeLocation,
																		index: i,
																	}),
																);
																toggleModal();
															}}
														/>
													</Tooltip>
													<Tooltip title='Remove Location'>
														<TrashIcon
															data-cy='client-location-delete'
															fontSize='small'
															sx={{ color: '#fb7a03' }}
															onClick={() => {
																removeItem(i);
															}}
															className='pointer'
														/>
													</Tooltip>
												</div>
											</TableRowButton>
										</Restricted>
									</div>
								);
							})
						)}
					</div>
					<Restricted
						AllowedUsers={standardPermissions}
						userTeamRole={[userTeamRole]}
					>
						<div
							className='d-flex justify-content-center information-header d-flex align-items-center'
							style={{ width: '100%' }}
						>
							<div
								data-cy='add-client-location'
								className='pointer d-flex flex-row'
								style={{
									height: '22px',
									width: '6px',
									borderRadius: '3px',
									marginRight: '1rem',
									padding: 0,
									position: 'relative',
									// backgroundColor: '#fb7a03',
								}}
								onClick={() => {
									ModalType('NEW_LOCATION_MODAL');
									toggleModal();
									// handleAddress();
								}}
							>
								<AddIcon
									fontSize='small'
									sx={{
										color: '#fb7a03',
										fontSize: 21,
										position: 'absolute',
										margin: 0,
										padding: 0,
										top: 0,
										left: 0,
									}}
								/>
							</div>
							<h3
								className='BodyTwoLight mt-2 dark-text'
								style={{ color: '#FB7A03', cursor: 'pointer' }}
								onClick={() => {
									ModalType('NEW_LOCATION_MODAL');
									toggleModal();
									// handleAddress();
								}}
							>
								Add New Business Locations
							</h3>
						</div>
					</Restricted>
				</div>
			</div>

			{modalIsOpen ? (
				<Modal
					onClose={toggleModal}
					open={modalIsOpen}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<>
						{ModalName == 'CONFIRM_MODAL' && (
							<DummyModal title={'Delete Location'} onClose={toggleModal}>
								<ConfirmModal
									description={
										'You’re about to delete this location. Are you sure to want to continue?'
									}
									OnCancel={() => toggleModal()}
									Onsubmit={() => {
										removeLocation();
									}}
									descriptionClassName='text-left'
									isLoading={loading}
								/>
							</DummyModal>
						)}
						{ModalName == 'NEW_LOCATION_MODAL' && (
							<DummyModal title='Add Location' onClose={toggleModal}>
								<AddOfficeLocation onCancel={toggleModal} />
							</DummyModal>
						)}
						{ModalName == 'EDIT_LOCATION_MODAL' && (
							<DummyModal title='Edit Location' onClose={toggleModal}>
								<AddOfficeLocation onCancel={toggleModal} beingEdited={true} />
							</DummyModal>
						)}
					</>
				</Modal>
			) : (
				<></>
			)}
		</>
	);
}

export default OfficeLocations;
