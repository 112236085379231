import React from 'react';
import 'react-phone-input-2/lib/plain.css';
import PhoneInput from 'react-phone-input-2';
import { useSelector } from 'react-redux';
import { Country } from 'country-state-city';
import { ReactComponent as Infoicon } from '../../assets/icons/Info.svg';
import { Tooltip, styled, tooltipClasses } from '@mui/material';
import { PhoneNumberUtil } from 'google-libphonenumber';

const CustomTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: '#fb7a03',
		color: 'white',
		fontSize: 11,
	},
}));

const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

function PhoneNumberInput({
	value,
	handleChange,
	resetNumber,
	isPhoneFormatted,
	toggleFormatting,
	hideToolTip,
	name,
    dataCy
}) {
	const inputStyle = {
		width: 'calc(100% - 42px)',
		border: 'none',
		marginLeft: '42px',
		paddingLeft: '6px',
		height: 30,
		fontFamily: 'CentraLight',
		fontWeight: 'light',
		fontSize: '14px',
		lineHeight: '18px',
		textTransform: 'initial',
		padding: '8px 6px 4px 6px',
		borderRadius: '4px',
		backgroundColor: 'white',
	};
	const buttonStyle = {
		border: 'none',
		borderRadius: '4px',
		backgroundColor: 'white',
	};

	const dropdownStyle = {
		maxHeight: 150,
		marginTop: '5px',
		border: 'none',
		boxShadow: '0px 2px 10px -4px #cdddd8',
		textAlign: 'left',
	};

    const isValid = isPhoneValid(value);
    const companyProfileData = useSelector((state) => state.profileReducer.profileData);
    const country = (Country.getCountryByCode(companyProfileData?.country)?.isoCode)?.toLowerCase();    

	return (
		<>
			{isPhoneFormatted ? (
				<div className='d-flex flex-column' style={{
                    gap: '7px'
                }}>
					<PhoneInput
						country={country || 'us'}
						specialLabel=''
						inputClass='phone-input'
						value={value}
						onChange={handleChange}
						inputStyle={inputStyle}
						buttonStyle={buttonStyle}
						dropdownStyle={dropdownStyle}
						dropdownClass='scroll'
						inputProps={{
							['data-cy']: dataCy,
							name: name,
						}}
                        enableSearch={true}
                        disableSearchIcon={true}
					/>
                    {!isValid && <span className='BodyThreeLight text-left' style={{ color: 'red' }}>Invalid phone number</span>}
				</div>
			) : (
				<div className='improved-input-designs'>
					<input
						type='text'
						name={name}
						className='w-100'
						onChange={handleChange}
						value={value}
						data-cy={dataCy}
					/>
				</div>
			)}
			{!hideToolTip &&
				(isPhoneFormatted ? (
					<div className='d-flex pointer pt-1' onClick={() => toggleFormatting(false)}>
						<span className='primary-text BodyTwoLight mr-2'>
							Remove number formatting
						</span>
						<CustomTooltip title='This will remove all formatting and requirement in the field. You will be able to enter any characters without auto-formatting being applied'>
							<Infoicon />
						</CustomTooltip>
					</div>
				) : (
					<div
						className='d-flex pointer pt-2'
						onClick={() => {
							toggleFormatting(true);
							resetNumber();
						}}
					>
						<span className='primary-text BodyTwoLight mr-2'>
							Apply number formatting
						</span>
						<CustomTooltip
							title={
								'This will apply number formatting according to the area code of the country. You have to re-enter the phone number'
							}
						>
							<Infoicon />
						</CustomTooltip>
					</div>
				))}
		</>
	);
}

export default PhoneNumberInput;
