/* eslint-disable no-mixed-spaces-and-tabs */
import * as profileActions from '../../redux/actions/company-profile';
import React, { memo, useEffect } from 'react';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import { Member, standardPermissions } from '../../utilities/utilities';
import { ReactComponent as UserIcon } from '../../assets/icons/User-colored.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/PencilEdit.svg';

import Restricted from '../Permissions/Restricted';
import { Modal, Tooltip } from '@mui/material';
import useModal from '../Modal/useModal';
import DummyModal from '../Modal/DummyModal';
import EditUserModal from './EditUserModal';
import Upload from '../../assets/icons/default-image.png';
import SkeletonLoading from '../Others/SkeletonLoading';
import moment from 'moment';
import useTimeZone from '../../hooks/useTimeZone';
import { Country } from 'country-state-city';
import { getUserSettings } from '../../hooks/getUserSettings';

function PersonalDetails() {
	const loading = useSelector((state) => state.userReducer.profileLoading);
	const TeamRoles = useSelector((state) => state.userReducer.userRoles);
	const Profile = useSelector((state) => state.userReducer.userProfile);
    const userDetails = useSelector((state) => state.authReducer.userDetails);
    const { date_format }  = getUserSettings(userDetails);
	const { modalIsOpen, toggleModal, ModalName, ModalType } = useModal();
	const user = useSelector((state) => state.authReducer.user);
	const ownAccountSettings = user?.userId == Profile?.id;
	const { formattedTimeZone } = useTimeZone();

	const dispatch = useDispatch();

	if ((Profile && !Profile?.address) || Profile?.address?.length === 0) {
		Profile.address = [{ street: '', suit_floor: '', city: '', state: '', zip: '' }];
	}

	useEffect(() => {
		dispatch(profileActions.getAllCountries(Country.getAllCountries()));
	}, []);

	return (
		<div className='profile-section' style={{ gap: '12px' }}>
			<div className='Uprofile-sectionBar'>
				<SkeletonLoading loading={loading} variant='text' width={300} height={45}>
					<div className='d-flex align-items-center' style={{ gap: '12px' }}>
						<UserIcon />
						<h2 className='HeadlineThreeBold text-left'>User Details</h2>
					</div>
				</SkeletonLoading>

				{ownAccountSettings ? (
					<SkeletonLoading loading={loading} variant='text' width={100} height={45}>
						<Tooltip title='Edit User Info'>
							<EditIcon
								style={{
									color: '#fb7a03',
									cursor: 'pointer',
								}}
								onClick={() => {
									ModalType('EDIT_USER');
									toggleModal();
								}}
							/>
						</Tooltip>
					</SkeletonLoading>
				) : (
					<Restricted
						AllowedUsers={
							TeamRoles?.some((item) => item.role_id === Member.ownerId)
								? [Member.ownerId]
								: TeamRoles?.some((item) => item.role_id === Member.supervisorId)
								? [Member.ownerId]
								: standardPermissions
						}
						userTeamRole={Profile?.user_teams?.map((team) => {
							return user?.user_roles?.find((item) =>
								item?.role_scopes
									?.map((role) => role.team_id)
									.includes(team.team_id),
							)?.role_id;
						})}
					>
						<SkeletonLoading loading={loading} variant='text' width={100} height={45}>
							<Tooltip title='Edit User Info'>
								<EditIcon
									style={{
										color: '#fb7a03',
										cursor: 'pointer',
									}}
									onClick={() => {
										ModalType('EDIT_USER');
										toggleModal();
									}}
								/>
							</Tooltip>
						</SkeletonLoading>
					</Restricted>
				)}
			</div>

			<div
				style={{
					padding: '18px',
					backgroundColor: '#EEF2F3',
					borderRadius: '4px',
				}}
			>
				<div className='PersonalDetails'>
					<div className='Main-child'>
						{/* <SkeletonLoading loading={loading} variant='text' width={150} height={30}>
							<h4 className='BodyTwoBold m-0'>Personal Details</h4>
						</SkeletonLoading> */}
						<div className='Input-Container'>
							<SkeletonLoading
								loading={loading}
								variant='circular'
								width={150}
								height={150}
							>
								<img
									src={Profile?.image_url ? Profile?.image_url : Upload}
									alt=''
									style={{ width: '150px', height: '150px', borderRadius: '50%' }}
								/>
							</SkeletonLoading>
							<SkeletonLoading
								loading={loading}
								variant='text'
								width={100}
								height={30}
							>
								<div className='text-left ProfilePage'>
									<label className='label BodyTwoLight m-0 text-capitalize'>
										Name{' '}
									</label>
									<h3
										className='BodyTwoLight m-0'
										style={{ wordBreak: 'break-all' }}
									>
										{Profile?.full_name}
									</h3>
								</div>
							</SkeletonLoading>
							<SkeletonLoading
								loading={loading}
								variant='text'
								width={100}
								height={30}
							>
								<div className='text-left ProfilePage'>
									<label className='label BodyTwoLight m-0 text-capitalize'>
										Birthday{' '}
									</label>
									<h3
										className='BodyTwoLight m-0'
										style={{ wordBreak: 'break-all' }}
									>
										{Profile?.birthday
											? moment(Profile?.birthday).format(date_format)
											: ''}
									</h3>
								</div>
							</SkeletonLoading>
							{/* <div className='text-left ProfilePage'>
								<label className='label BodyTwoLight m-0 text-capitalize'>
									Work Mode{' '}
								</label>
								<h3
									className='BodyTwoLight m-0'
									style={{ wordBreak: 'break-all' }}
								>
									{Profile?.work_mode}
								</h3>
							</div> */}

							{/* {ownAccountSettings && ( */}
							<SkeletonLoading
								loading={loading}
								variant='text'
								width={100}
								height={30}
							>
								<div className='text-left ProfilePage'>
									<label className='label BodyTwoLight m-0 text-capitalize'>
										Time Zone{' '}
									</label>
									<h3
										className='BodyTwoLight m-0'
										style={{ wordBreak: 'break-all' }}
									>
										{ownAccountSettings ? formattedTimeZone : Profile?.timezone}
									</h3>
								</div>
							</SkeletonLoading>
							{/* )} */}

                            {ownAccountSettings && (
								<SkeletonLoading
									loading={loading}
									variant='text'
									width={100}
									height={30}
								>
									<div className='text-left ProfilePage'>
										<label className='label BodyTwoLight m-0 text-capitalize'>
											Date Format
										</label>
										<h3
											className='BodyTwoLight m-0'
											style={{ wordBreak: 'break-all' }}
										>
											{Profile?.date_format}
										</h3>
									</div>
								</SkeletonLoading>
							)}
						</div>
					</div>
                  
					<div className='Main-child'>
						<SkeletonLoading loading={loading} variant='text' width={150} height={30}>
							<h4 className='BodyTwoBold m-0'>Work Details</h4>
						</SkeletonLoading>
						<div className='Input-Container'>
							<SkeletonLoading
								loading={loading}
								variant='text'
								width={100}
								height={30}
							>
								<div className='text-left ProfilePage'>
									<label className='label BodyTwoLight m-0 text-capitalize'>
										Job Title{' '}
									</label>
									<h3
										className='BodyTwoLight m-0'
										style={{ wordBreak: 'break-all' }}
									>
										{Profile?.job_title}
									</h3>
								</div>
							</SkeletonLoading>
							{/* <div className='text-left ProfilePage'>
								<label className='label BodyTwoLight m-0 text-capitalize'>
									Role{' '}
								</label>
								<h3
									className='BodyTwoLight m-0'
									style={{ wordBreak: 'break-all' }}
								>
									{(TeamRoles?.length > 0 &&
										roles?.find((role) => role.id == TeamRoles[0]?.role_id)
											?.role_name) ||
										getRoleName(TeamRoles?.length > 0 && TeamRoles[0]?.role_id)}
								</h3>
							</div> */}
							<SkeletonLoading
								loading={loading}
								variant='text'
								width={100}
								height={30}
							>
								<div className='text-left ProfilePage'>
									<label className='label BodyTwoLight m-0 text-capitalize'>
										Department{' '}
									</label>
									<h3
										className='BodyTwoLight m-0'
										style={{ wordBreak: 'break-all' }}
									>
										{Profile?.department}
									</h3>
								</div>
							</SkeletonLoading>
							<SkeletonLoading
								loading={loading}
								variant='text'
								width={100}
								height={30}
							>
								<div className='text-left ProfilePage'>
									<label className='label BodyTwoLight m-0 text-capitalize'>
										Hiring date{' '}
									</label>
									<h3
										className='BodyTwoLight m-0'
										style={{ wordBreak: 'break-all' }}
									>
										{Profile?.hiring_date
											? moment(Profile?.hiring_date).format(date_format)
											: ''}
									</h3>
								</div>
							</SkeletonLoading>
						</div>
					</div>

					<div className='Main-child'>
						<SkeletonLoading loading={loading} variant='text' width={150} height={30}>
							<h4 className='BodyTwoBold m-0'>Contact</h4>
						</SkeletonLoading>
						<div className='Input-Container'>
							<SkeletonLoading
								loading={loading}
								variant='text'
								width={100}
								height={30}
							>
								<div className='text-left ProfilePage'>
									<label className='label BodyTwoLight m-0 text-capitalize'>
										Email{' '}
									</label>
									<h3
										className='BodyTwoLight m-0'
										style={{ wordBreak: 'break-all' }}
									>
										{Profile?.email}
									</h3>
								</div>
							</SkeletonLoading>
							{/* <div className='text-left ProfilePage'>
								<label className='label BodyTwoLight m-0 text-capitalize'>
									Slack{' '}
								</label>
								<h3
									className='BodyTwoLight m-0'
									style={{ wordBreak: 'break-all' }}
								>
									{Profile?.slack}
								</h3>
							</div> */}
							<SkeletonLoading
								loading={loading}
								variant='text'
								width={100}
								height={30}
							>
								<div className='text-left ProfilePage'>
									<label className='label BodyTwoLight m-0 text-capitalize'>
										Phone{' '}
									</label>
									<h3
										className='BodyTwoLight m-0'
										style={{ wordBreak: 'break-all' }}
									>
										{Profile?.phone}
									</h3>
								</div>
							</SkeletonLoading>
						</div>
					</div>
					<div className='Main-child'>
						<SkeletonLoading loading={loading} variant='text' width={150} height={30}>
							<h4 className='BodyTwoBold m-0'>Address</h4>
						</SkeletonLoading>

						{Profile?.address?.map((item, index) => {
							return (
								<div className='Input-Container' key={index}>
									<SkeletonLoading
										loading={loading}
										variant='text'
										width={100}
										height={30}
									>
										<div className='text-left ProfilePage'>
											<label className='label BodyTwoLight m-0 text-capitalize'>
												Country
											</label>
											<h3
												className='BodyTwoLight m-0'
												style={{ wordBreak: 'break-all' }}
											>
												{Country.getCountryByCode(item?.country)?.name}
											</h3>
										</div>
									</SkeletonLoading>
									<SkeletonLoading
										loading={loading}
										variant='text'
										width={100}
										height={30}
									>
										<div className='text-left ProfilePage'>
											<label className='label BodyTwoLight m-0 text-capitalize'>
												Street
											</label>
											<h3
												className='BodyTwoLight m-0'
												style={{ wordBreak: 'break-all' }}
											>
												{item?.street}
											</h3>
										</div>
									</SkeletonLoading>

									<SkeletonLoading
										loading={loading}
										variant='text'
										width={100}
										height={30}
									>
										<div className='text-left ProfilePage'>
											<label className='label BodyTwoLight m-0 text-capitalize'>
												Floor/Suite
											</label>
											<h3
												className='BodyTwoLight m-0'
												style={{ wordBreak: 'break-all' }}
											>
												{item?.suit_floor}
											</h3>
										</div>
									</SkeletonLoading>
									<SkeletonLoading
										loading={loading}
										variant='text'
										width={100}
										height={30}
									>
										<div className='text-left ProfilePage'>
											<label className='label BodyTwoLight m-0 text-capitalize'>
												City/Town
											</label>
											<h3
												className='BodyTwoLight m-0'
												style={{ wordBreak: 'break-all' }}
											>
												{item?.city}
											</h3>
										</div>
									</SkeletonLoading>
									<SkeletonLoading
										loading={loading}
										variant='text'
										width={100}
										height={30}
									>
										<div className='text-left ProfilePage'>
											<label className='label BodyTwoLight m-0 text-capitalize'>
												State/Province/Region
											</label>
											<h3
												className='BodyTwoLight m-0'
												style={{ wordBreak: 'break-all' }}
											>
												{item?.state}
											</h3>
										</div>
									</SkeletonLoading>
									<SkeletonLoading
										loading={loading}
										variant='text'
										width={100}
										height={30}
									>
										<div className='text-left ProfilePage'>
											<label className='label BodyTwoLight m-0 text-capitalize'>
												Zip Code/Postal Code
											</label>
											<h3
												className='BodyTwoLight m-0'
												style={{ wordBreak: 'break-all' }}
											>
												{item?.zip}
											</h3>
										</div>
									</SkeletonLoading>
								</div>
							);
						})}
					</div>
				</div>
			</div>
			{modalIsOpen && (
				<>
					<Modal
						onClose={toggleModal}
						open={modalIsOpen}
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						{ModalName == 'EDIT_USER' && (
							<DummyModal title='Edit User' onClose={toggleModal}>
								<EditUserModal closeModal={toggleModal} />
							</DummyModal>
						)}
					</Modal>
				</>
			)}
		</div>
	);
}

export default memo(PersonalDetails);
