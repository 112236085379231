import React, { useEffect, useState } from 'react';
import Sidebar from '../components/sidebar/SideBar';
import TimeReportsUsersV2 from '../components/TimeReportsV2/TimeReportsUsersV2';
import TimeReportsClientsV2 from '../components/TimeReportsV2/TimeReportsClientsV2';
import TimeReportsTopBarV2 from '../components/TimeReportsV2/TimeReportsTopBarV2';
import TimeReportsDateV2 from '../components/TimeReportsV2/TimeReportsDateV2';
import * as TimeReportsActions from '../redux/actions/time-reports-actions-v2';
import { useDispatch } from 'react-redux';
import AlertBar from '../components/sub_components/AlertBar';

function TimeReportsV2() {
	const [activeTab, setActiveTab] = useState(1);
	const dispatch = useDispatch();

	const ChangeActiveTab = (e) => {
		setActiveTab(e.target.id);
	};

	useEffect(() => {
		dispatch(TimeReportsActions.setDailyView(false));
	}, []);
	
    return (
		<div className='AppBody'>
			<Sidebar title='Time Reports'>
				<div className='SidebarContentWrapper'>
					<div className='User-filters'>
						<div
							className='side-menu BodyTwoLight d-flex flex-column align-items-start'
							style={{ gap: '8px' }}
						>
							<span
								className={
									activeTab == 1
										? 'active d-flex flex-row align-items-center'
										: 'side-menu-link d-flex flex-row align-items-center'
								}
								style={{ gap: '10px', paddingBlock: '10px' }}
							>
								<div className='dot'></div>
								<a onClick={ChangeActiveTab} href={'#users'} id='1' className=''>
									Users
								</a>
							</span>
							<span
								className={
									activeTab == 2
										? 'active d-flex flex-row align-items-center'
										: 'side-menu-link d-flex flex-row align-items-center'
								}
								style={{ gap: '10px', paddingBlock: '10px' }}
							>
								<div className='dot'></div>
								<a onClick={ChangeActiveTab} href={'#clients'} id='2' className=''>
									Clients
								</a>
							</span>
						</div>
					</div>
				</div>
			</Sidebar>
			<div
				style={{
					width: 'calc(100vw - 260px)',
                    height: '100vh', 
                    overflowY:'auto'
				}}
			>
				<AlertBar />
				<div className='timeReports-main time-reports-main'>
					<div className='time-reports-content'>
						<TimeReportsTopBarV2 timereports={'Time Reports'} />
						<TimeReportsDateV2 />
						<div
							className='d-flex flex-column'
							style={{
								gap: '3em',
							}}
						>
							<TimeReportsUsersV2 sectionId='users' />
							<TimeReportsClientsV2 sectionId='clients' />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default TimeReportsV2;
