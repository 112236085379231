import { Checkbox, FormControl, Input, InputAdornment, Select } from '@mui/material';
import { useState, useMemo } from 'react';
import { ReactComponent as SearchIcon } from '../../assets/icons/MagnifyingGlassSecondary.svg';
import { emptyFunction } from '../../utilities/utilities';

function MultipleDropDown({
	required,
	options = [],
	selectStyle = {},
	onApply,
	changeHandler = emptyFunction,
}) {
	const [initalState, setInitialState] = useState([]);
	const [search, setSearch] = useState('');

	useMemo(() => {
		setInitialState(options || []);
	}, [options?.length]);

	const handleChange = (value) => {
		const tempArr = initalState?.map((item) => {
			if (item.value === value) {
				return { ...item, is_checked: !item.is_checked };
			}
			return item;
		});
		setInitialState([...tempArr]);
		changeHandler([...tempArr]);
	};

	const onSearchChangeHandler = (event) => {
		const { value } = event.target;
		setSearch(value);
	};

	const ApplyClickHandler = () => {
		onApply(initalState);
	};

	return (
		<div className='multi-pick-dropdown-container'>
			<FormControl
				variant='standard'
				style={{
					width: '100%',
					borderBottom: '1px solid #28383c',
					background: '#f6f5f2',
				}}
			>
				<Select
					className='PickList'
					required={required}
					multiple
					IconComponent={''}
					disableUnderline
					displayEmpty
					MenuProps={{
						onClick: (e) => {
							e.preventDefault();
						},
						PaperProps: {
							style: {
								padding: '6px',
							},
						},
					}}
					SelectDisplayProps={{ style: { padding: 0 } }}
					sx={{
						padding: '8px 6px 4px 6px !important',
						...selectStyle,
					}}
					value={initalState
						?.filter((item) => item?.is_checked)
						?.map((item) => item.value)}
					renderValue={(selected) => {
						return (
							<span className='dropdown-text'>
								{selected
									?.map(
										(item) =>
											options?.find(({ value }) => value == item)?.label,
									)
									?.join(',')}
							</span>
						);
					}}
				>
					<div
						id='searchinput'
						name='searchinput'
						style={{
							background: 'transparent',
						}}
						className='d-flex justify-content-center pb-2'
						onKeyDown={(e) => e.stopPropagation()}
					>
						<Input
							autoFocus={true}
							onChange={onSearchChangeHandler}
							value={search}
							autoComplete='off'
							className='pl-1 BodyTwoLight'
							disableUnderline={true}
							inputProps={{ style: { paddingTop: '8px', color: '#333333' } }}
							startAdornment={
								<InputAdornment style={{ paddingLeft: '6px' }} position='start'>
									<SearchIcon />
								</InputAdornment>
							}
							placeholder={`Search`}
							sx={{
								borderRadius: '4px',
								width: '100%',
								background: '#EEF2F3',
							}}
						/>
					</div>

					<div className='multi-select-options-container'>
						{initalState
							?.filter((item) =>
								item?.label?.toLowerCase()?.includes(search?.toLowerCase()),
							)
							?.map(({ value, label }) => (
								<span
									className='d-flex align-items-start w-100 multi-select-option'
									onClick={() => handleChange(value)}
									key={value}
									value={label}
								>
									<Checkbox
										sx={{
											padding: 0,
											color: '#EAEAEA !important  ',
											'&.Mui-checked': {
												color: '#FB7A03 !important',
											},
											'&.MuiCheckbox-root': {
												'&:hover': {
													boxShadow: 'none !important',
													backgroundColor: 'transparent !important',
												},
											},
										}}
										id={String(value)}
										checked={
											initalState?.find((item) => item.value === value)
												?.is_checked
										}
									/>
									<span
										className='multi-select-option-text d-inline-flex'
										style={{ whiteSpace: 'pre-wrap' }}
									>
										{label}
									</span>
								</span>
							))}
					</div>
					{onApply && (
						<div className='d-flex w-100 pt-3'>
							<button onClick={ApplyClickHandler} className='rounded-button-2 w-100'>
								Apply
							</button>
						</div>
					)}
				</Select>
			</FormControl>
		</div>
	);
}

export default MultipleDropDown;
