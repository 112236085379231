import { useSelector } from 'react-redux';
import SectionBar from '../Others/SectionBar';
import SkeletonLoading from '../Others/SkeletonLoading';
import Restricted from '../Permissions/Restricted';
import { standardPermissions } from '../../utilities/utilities';
import { ReactComponent as EditIcon } from '../../assets/icons/PencilEdit.svg';
import { ReactComponent as TeamIcon } from '../../assets/icons/Team.svg';
import useModal from '../Modal/useModal';
import { Modal, Tooltip } from '@mui/material';
import DummyModal from '../Modal/DummyModal';
import EditTeamDetailsModal from './EditTeamDetailsModal';

function TeamInformation() {
	const teamDetails = useSelector((state) => state.teamReducer.teamDetails);
	const user = useSelector((state) => state.authReducer.user);
	const loading = useSelector((state) => state.teamReducer.loading);
	const { modalIsOpen, toggleModal, ModalName, ModalType } = useModal();

	return (
		<div className='profile-section' style={{ gap: '12px' }}>
			<SectionBar
				header={
					<SkeletonLoading
						loading={loading}
						variant='text'
						width={150}
						height={45}
					>
						<div className='d-flex align-items-center' style={{ gap: '12px' }}>
							<TeamIcon />
							<h2 className='HeadlineThreeBold text-left'>Team Details</h2>
						</div>
					</SkeletonLoading>
				}
				headerActions={
					<Restricted
						AllowedUsers={standardPermissions}
						userTeamRole={[
							user?.user_roles?.find((item) =>
								item?.role_scopes
									?.map((role) => role.team_id)
									?.includes(teamDetails?.id),
							)?.role_id,
						]}
					>
						<SkeletonLoading
							loading={loading}
							variant='text'
							width={100}
							height={50}
						>
							<Tooltip title='Edit Team Deatils'>
								<EditIcon
									style={{
										color: '#fb7a03',
										cursor: 'pointer',
									}}
									data-cy='edit-profile'
									onClick={() => {
										ModalType('EDIT_TEAM_DETAILS');
										toggleModal();
									}}
								/>
							</Tooltip>
						</SkeletonLoading>
					</Restricted>
				}
			/>

			<div className='profile-section-body border'>
				<div className='team-details-body'>
					<div>
						<SkeletonLoading
							loading={loading}
							variant='text'
							width={100}
							height={45}
						>
							<p className='BodyTwoBold text-left mb-1'>Team Overview</p>
						</SkeletonLoading>
					</div>
					<div className='text-left'>
						<SkeletonLoading
							loading={loading}
							variant='text'
							width={100}
							height={45}
						>
							<label className='label BodyTwoLight m-0 text-capitalize'>
								Name{' '}
							</label>
							<h3
								className='BodyTwoLight m-0'
								style={{ wordBreak: 'break-all' }}
							>
								{teamDetails?.name}
							</h3>
						</SkeletonLoading>
					</div>
					<div className='text-left'>
						<SkeletonLoading
							loading={loading}
							variant='text'
							width={100}
							height={45}
						>
							<label className='label BodyTwoLight m-0 text-capitalize text-left'>
								Description{' '}
							</label>
							<h3
								className='BodyTwoLight m-0 text-left'
								style={{ wordBreak: 'break-all' }}
							>
								{teamDetails?.description}
							</h3>
						</SkeletonLoading>
					</div>
				</div>
			</div>
			{modalIsOpen && (
				<>
					<Modal
						onClose={toggleModal}
						open={modalIsOpen}
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						{ModalName == 'EDIT_TEAM_DETAILS' && (
							<DummyModal title='Edit Team' onClose={toggleModal}>
								<EditTeamDetailsModal
									onCancel={toggleModal}
									teamDetails={teamDetails}
								/>
							</DummyModal>
						)}
					</Modal>
				</>
			)}
		</div>
	);
}

export default TeamInformation;
