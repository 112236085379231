/* eslint-disable no-mixed-spaces-and-tabs */
import { memo, useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as userActions from '../../redux/actions/user-management.actions';
import { costMode } from '../DemoData';
import useEditTimeFormat from '../../hooks/useEditTimeFormat';
import { convertDecimalToTime, convertToDecimal } from '../../utilities/utilities';
import moment from 'moment';
import { useParams } from 'react-router';
import { setSnackBarIsOpen } from '../../redux/actions/dashboard/workspaces-actions';
import { CircularProgress } from '@mui/material';
import Select from '../Inputs/Select';
import { KeyboardDatePicker } from '@material-ui/pickers';
import AlertOverwriteModal from '../ClientManagement/Modals/AlertOverwriteModal';
import DummyModal from '../Modal/DummyModal';
import { Modal } from '@mui/material';
import { getUserSettings } from '../../hooks/getUserSettings';

function UserBillingModal({ onCancel, selectedUser, title, isCreate, currencySymbol }) {
	const dispatch = useDispatch();
	const { id } = useParams();

	const [form, setForm] = useState(selectedUser);
	const [actionLoading, setActionLoading] = useState(false);
	const [startDateError, setStartDateError] = useState(false);
	const [alertModal, setAlertModal] = useState(false);
	const [isFormChanged, setIsFormChanged] = useState(false);

	const Profile = useSelector((state) => state.userReducer.userProfile);
	const { inputValue, setInputValue, handleInputChange } = useEditTimeFormat(
		form?.available_hours,
	);
	// const activeStartDate = specificUserBilling?.find(
	// 	(item) => item?.is_active === true,
	// )?.bill_start_date;

    const userDetails = useSelector((state) => state.authReducer.userDetails);
	const { date_format } = getUserSettings(userDetails);

	const initialForm = () => {
		const defaultFormValues = {
			available_hours: '',
			cost_mode: '',
			salary_per_hour: '',
			annual_salary: '',
			job_benefits: '',
			bill_start_date: null,
		};

		setForm(defaultFormValues);
		setInputValue(defaultFormValues.available_hours);
	};

	// const toggleAlertModal = () => {
	// 	setAlertModal(!alertModal);
	// };

	// onChange Functions

	const handleInputBlur = () => {
		let value = 0;
		const name = 'available_hours';

		if (inputValue === '') {
			return;
		}

		if (inputValue.includes(':')) {
			value = convertToDecimal(inputValue);
		} else if (!isNaN(inputValue)) {
			value = parseFloat(inputValue);
		}

		const formattedValue = convertDecimalToTime(value);
		setInputValue(formattedValue);
		const updatedForm = {
			...form,
			[name]: value,
		};
		setForm(updatedForm);
		setIsFormChanged(true);
	};

	const handleChange = useCallback(
		(event) => {
			const { name, value } = event.target;

			const trimmedValue = value.replace(/[^0-9.]/g, '');
			const withoutDollarSign = trimmedValue.startsWith('$')
				? trimmedValue.slice(1)
				: trimmedValue;

			const hasDecimal = withoutDollarSign.includes('.');

			const parsedValue =
				hasDecimal && withoutDollarSign.startsWith('.')
					? parseFloat(`${withoutDollarSign.replace(/,/g, '')}`)
					: parseFloat(withoutDollarSign.replace(/,/g, ''));

			let formattedValue;
			if (!isNaN(parsedValue)) {
				if (hasDecimal) {
					const decimalPart = withoutDollarSign.split('.')[1];
					formattedValue =
						decimalPart.length <= 2
							? withoutDollarSign.replace(/^\s+/, '')
							: parsedValue.toFixed(2).replace(/^\s+/, '');
				} else {
					formattedValue = parsedValue
						.toLocaleString('en-US', {
							minimumFractionDigits: 0,
							maximumFractionDigits: 2,
						})
						.replace(/^\s+/, '');
				}
			} else {
				formattedValue = withoutDollarSign.replace(/^\s+/, '');
			}

			const updatedForm = {
				...form,
				[name]: formattedValue,
			};
			setForm(updatedForm);
			setIsFormChanged(true);
		},
		[form, setIsFormChanged],
	);

	const onStartDateChange = (date) => {
		setForm({
			...form,
			bill_start_date: date,
		});
		setIsFormChanged(true);
		setStartDateError(!date);
	};

	const paymentHandler = (e) => {
		const { name, value } = e.target;

		setForm({ ...form, [name]: value });
	};

	// submit form
	const parseAndSetValues = (inputForm) => {
		const parsedForm = {
			...inputForm,
			job_benefits:
				typeof inputForm?.job_benefits === 'string'
					? parseFloat(inputForm?.job_benefits.replace(/,/g, '')) || 0.0
					: inputForm?.job_benefits || 0.0,
			salary_per_hour:
				typeof inputForm?.salary_per_hour === 'string'
					? parseFloat(inputForm?.salary_per_hour.replace(/,/g, '')) || 0.0
					: inputForm?.salary_per_hour || 0.0,
			annual_salary:
				typeof inputForm?.annual_salary === 'string'
					? parseFloat(inputForm?.annual_salary.replace(/,/g, '')) || 0.0
					: inputForm?.annual_salary || 0.0,
		};

		return parsedForm;
	};

	const onSave = (event) => {
		event?.preventDefault();

		if (isFormChanged) {
			setActionLoading(true);

			const parsedForm = parseAndSetValues(form);
			setForm(parsedForm);

			const userBilling = {
				user_id: Profile?.id,
				available_hours:
					typeof parsedForm?.available_hours === 'string'
						? parseFloat(parsedForm?.available_hours) || 0.0
						: parsedForm?.available_hours || 0.0,
				job_benefits:
					typeof parsedForm?.job_benefits === 'string'
						? parseFloat(parsedForm?.job_benefits) || 0.0
						: parsedForm?.job_benefits || 0.0,
				salary_per_hour: form?.cost_mode
					? typeof parsedForm?.salary_per_hour === 'string'
						? parseFloat(parsedForm?.salary_per_hour) || 0.0
						: parsedForm?.salary_per_hour || 0.0
					: 0.0,
				annual_salary: form?.cost_mode
					? typeof parsedForm?.annual_salary === 'string'
						? parseFloat(parsedForm?.annual_salary) || 0.0
						: parsedForm?.annual_salary || 0.0
					: 0.0,
				cost_mode: form?.cost_mode,
				bill_start_date: moment(parsedForm?.bill_start_date).format('MM-DD-YYYY'),
			};
			userBilling?.cost_mode == '' && delete userBilling?.cost_mode;

			const successMessage = isCreate
				? 'User billing created successfully'
				: 'User Billing updated successfully';
			dispatch(userActions.addUserBilling(userBilling))
				.then((response) => {
					dispatch(userActions.getSpecificUserBilling(Profile?.id));
					dispatch(setSnackBarIsOpen(true, true, successMessage));
					setActionLoading(false);
					onCancel();
				})
				.catch((error) => {
					setActionLoading(false);
					dispatch(setSnackBarIsOpen(false, true, error?.message));
				});
		} else {
			onCancel();
		}
	};

	useEffect(() => {
		if (isCreate) {
			initialForm();
		} else {
			setForm(selectedUser);
			setInputValue(selectedUser?.available_hours || '');
		}
	}, [isCreate, selectedUser]);

	return (
		<>
			<form
				key={isCreate ? 'create' : 'update'}
				autoComplete='off'
				onSubmit={onSave}
				className='UserAdd-Form'
				style={{ overflow: 'visible' }}
			>
				<div className='p-3' style={{ overflow: 'visible' }}>
					<div className='improved-input-designs pb-3'>
						<label className='w-100 require'>Available Hours/Week</label>
						<input
							className='w-100 form-input'
							type='text'
							required
							name='available_hours'
							onChange={handleInputChange}
							onBlur={handleInputBlur}
							value={inputValue}
							placeholder='HH : MM'
						/>
					</div>
					<div className='improved-input-designs pb-3'>
						<label className='require'>Salary Terms</label>
						<Select
							value={costMode.find((i) => i.value == form?.cost_mode)}
							options={costMode}
							name='cost_mode'
							onChange={paymentHandler}
							required={true}
						/>
					</div>

					{form?.cost_mode === 'hourly' ? (
						<div className='improved-input-designs pb-3'>
							<label className={`w-100 ${form?.cost_mode ? 'require' : ''} `}>
								Salary/Hour
							</label>
							<input
								className='w-100 form-input'
								type='text'
								maxLength={17}
								required
								name='salary_per_hour'
								onChange={handleChange}
								value={`${
									form?.salary_per_hour
										? `${currencySymbol}${form?.salary_per_hour}`
										: ''
								}`}
							/>
						</div>
					) : (
						form?.cost_mode === 'annual' && (
							<div className='improved-input-designs pb-3'>
								<label className={`w-100 ${form?.cost_mode ? 'require' : ''} `}>
									Annual Salary
								</label>
								<input
									className='w-100 form-input'
									type='text'
									maxLength={17}
									name='annual_salary'
									required
									onChange={handleChange}
									value={`${
										form?.annual_salary
											? `${currencySymbol}${form?.annual_salary}`
											: ''
									}`}
								/>
							</div>
						)
					)}

					<div className='improved-input-designs pb-3'>
						<label className='w-100'>Annual Benefits</label>
						<input
							className='w-100 form-input'
							type='text'
							name='job_benefits'
							onChange={handleChange}
							maxLength={17}
							value={`${
								form?.job_benefits ? `${currencySymbol}${form?.job_benefits}` : ''
							}`}
							onBlur={() => {
								if (!form.job_benefits) {
									setForm((preState) => {
										return { ...preState, job_benefits: 0 };
									});
								}
							}}
						/>
					</div>

					{/* <div className='improved-input-designs  pb-3'>
						<KeyboardDatePicker
							
							label='Hiring Date'
						
						/>
					</div> */}
					<div className='improved-input-designs flex-column d-flex'>
						<label className='require'>Rate Start Date</label>
						<KeyboardDatePicker
							onChange={onStartDateChange}
							name='bill_start_date'
							value={form?.bill_start_date}
							Edit={true}
							helperText={null}
							// minDate={PreviousBillingDate}
							required
							format={date_format}
							autoOk
							disableToolbar
							className='p-1'
						/>
					</div>
				</div>

				<div className='VerticalSpacer bg-white p-3 rounded-bottom d-flex justify-content-between'>
					<button
						type='button'
						onClick={() => {
							onCancel();
						}}
						className='secondary-btn'
					>
						Close
					</button>
					<button type='submit' className='primary-btn' disabled={actionLoading}>
						{actionLoading ? (
							<CircularProgress size={24} sx={{ color: 'white' }} disableShrink />
						) : (
							<span>{title}</span>
						)}
					</button>
				</div>
			</form>

			<Modal
				// onClose={toggleAlertModal}
				open={alertModal}
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<DummyModal
					title='New rate affects invoice that has already been generated'
					// onClose={toggleAlertModal}
					style={{ width: '40%' }}
				>
					<AlertOverwriteModal
						description={'You’ve selected a rate start date that affects the following invoice(s) that have already been generated.Click on the invoice to edit it with the new rate.'}
						note={'Click Update Rate, if you want to update the rate for previous billing period. You can delete the invoices that have been generated and regenerate them with a new date.'}
                        anotherNote={'If you don’t want to change the rate to a billing period that has already been invoiced, select a rate start date after January 31, 2024.'}
						descriptionClassName='text-left'
						// OnCancel={toggleAlertModal}
						Onsubmit={onSave}
					/>
				</DummyModal>
			</Modal>
		</>
	);
}

export default memo(UserBillingModal);
