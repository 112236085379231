
const OwnershipLimitedLicense = () => {
	return (
		<p className='BodyTwoLight'>
			The Site, including the text, graphics, images, photographs, videos,
			illustrations, and other content contained therein, is owned by Levvy or
			our licensors and is protected under both United States and foreign laws.
			Except as explicitly stated in these Terms, all rights in and to the Site
			are reserved by us or our licensors. Subject to your compliance with these
			Terms, you are hereby granted a limited, nonexclusive, nontransferable,
			non-sublicensable, revocable license to access and use our Site solely for
			its intended purposes and in accordance with its permitted functionality.
			Any use of the Site other than as specifically authorized herein, without
			our prior written permission, is strictly prohibited, will terminate the
			license granted herein and violate our intellectual property rights.
		</p>
	);
};

export default OwnershipLimitedLicense;
