import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as profileActions from '../../redux/actions/company-profile';
import ProfileBar from '../ClientManagement/ProfileBar';
import EditableText from '../Inputs/EditableText';
import EditableFileInput from '../Inputs/EditableFileInput';
import { EditableDropdown } from '../Inputs/EditableDropDown';
import { business_types2, industries2 } from '../DemoData';
import { Member, formatPhoneNumber } from '../../utilities/utilities';
import getSymbolFromCurrency from 'currency-symbol-map';
import { Country } from 'country-state-city';
import PhoneNumberInput from '../Inputs/PhoneNumberInput';
import SimpleText from '../TextViews/SimpleText';

const CompanyProfileInformation = ({ companyRef }) => {
	const dispatch = useDispatch();
	const user = useSelector((state) => state.authReducer.user);
	const companyProfileData = useSelector((state) => state.profileReducer.profileData);
	const loading = useSelector((state) => state.profileReducer.loading);
	const countries = useSelector((state) => state.profileReducer.countries);
	const [isPhoneFormatted, setIsPhoneFormatted] = useState(
		companyProfileData?.is_phone_number_formated || false,
	);

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [croppedImage, setCroppedImage] = useState(null);
	const [src, setSrc] = useState(null);
	const [edit, setEdit] = useState(false);

	const toggleModal = () => {
		setIsModalOpen(!isModalOpen);
	};

	const currencies = Object.keys(getSymbolFromCurrency.currencySymbolMap);

	const CurrenciesISO2 = currencies.map((currency) => {
		return {
			label: currency,
			value: currency,
		};
	});

	const handleChange = (event) => {
		event.preventDefault();
		let { name, value } = event.target;

		if (name === 'companyPhoneNumber') {
			if (value.length > 14) {
				value = companyProfileData?.companyPhoneNumber;
			} else {
				value = formatPhoneNumber(value);
			}
		}

		dispatch(profileActions.handleChange({ name, value }));
	};

	const handlePhoneChange = (event, country, e, PhoneNumber) => {
		let phone;
		if (isPhoneFormatted) {
			phone = PhoneNumber;
		} else {
			phone = event.target.value;
		}
		dispatch(profileActions.handleChange({ name: 'companyPhoneNumber', value: phone }));
	};

	const resetNumber = () => {
		dispatch(profileActions.handleChange({ name: 'companyPhoneNumber', value: '' }));
	};

	const toggleFormatting = (formatting) => {
		setIsPhoneFormatted(formatting);
	};

	const handleIndustryChange = (event) => {
		dispatch(profileActions.handleIndustryChange(event.target.value));
	};

	const handleBusinessChange = (event) => {
		dispatch(profileActions.handleBusinessChange(event.target.value));
	};

	const handleCountryChange = (event) => {
		const { value } = event.target;
		dispatch(profileActions.handleCountryChange(value));
		dispatch(profileActions.handleCurrencyChange(Country.getCountryByCode(value)?.currency));
	};

	const handleCurrencyChange = (event) => {
		dispatch(profileActions.handleCurrencyChange(event.target.value));
	};

	const handleInputFileChange = (e) => {
		const fileReader = new FileReader();
		fileReader.onloadend = () => {
			setSrc(fileReader.result);
		};
		fileReader.readAsDataURL(e.target.files[0]);
	};

	const onSubmit = () => {
		dispatch(profileActions.uploadImage(croppedImage, toggleModal));
	};

	const handleSubmit = () => {
		// e.preventDefault();

		dispatch(
			profileActions.updateProfile(companyProfileData?.id, {
				name: companyProfileData.name,
				phone: companyProfileData.companyPhoneNumber,
				dba: companyProfileData.dba,
				industry: companyProfileData.industry,
				country: companyProfileData.country,
				currency: companyProfileData.currency,
				business_type: companyProfileData.businessType,
				logo_url: companyProfileData.image_url,
				is_phone_number_formated: isPhoneFormatted,
			}),
		);
	};

	const DoneClickListener = () => {
		handleSubmit();
		setEdit(false);
	};

	useEffect(() => {
		dispatch(profileActions.getTenantProfile(user.tenantIds[0]));
		dispatch(profileActions.getAllDefaultWorkflow());
		dispatch(profileActions.getAllCountries(Country.getAllCountries()));
	}, [dispatch]);

	return (
		<div className='background-shadow'>
			<ProfileBar
				BarText='Company Details'
				edit={edit}
				loading={loading}
				EditProfile={() => setEdit(true)}
				UpdateChanges={DoneClickListener}
			/>

			<div className='Profile-Content' ref={companyRef}>
				<div className='d-flex flex-column w-100 align-items-center ProfileSection'>
					{/* <h2 className='HeadlineTwoEmphasizedSerif text-left w-100'>
						Company Information
					</h2> */}

					<div className='d-flex row w-100 pt-2' style={{ gap: '5em' }}>
						<div
							className='d-flex flex-column'
							style={{ width: '20%', minHeight: '200px', gap: '0.8rem' }}
						>
							{/* <h4 className='BodyTwoEmphasized w-100 text-left'>Identify</h4> */}
							<div className='d-flex flex-column' style={{ gap: '0.7em' }}>
								<EditableFileInput
									value={companyProfileData?.image_url}
									// label='Company Logo'
									Edit={edit}
									onChange={(e) => handleInputFileChange(e)}
									clickHandler={() => setIsModalOpen(!isModalOpen)}
									onSubmit={onSubmit}
									toggleModal={toggleModal}
									src={src}
									isModalOpen={isModalOpen}
									setCroppedImage={setCroppedImage}
									loading={loading}
								/>
							</div>
						</div>

						<div
							className='d-flex flex-column'
							style={{ width: '20%', minHeight: '200px', gap: '0.8rem' }}
						>
							{/* <h4 className='BodyTwoEmphasized w-100 text-left'>Company</h4> */}
							<div className='d-flex flex-column' style={{ gap: '0.7em' }}>
								<EditableText
									onChange={handleChange}
									label='name'
									name='name'
									type='text'
									textClass='BodyTwoLight'
									Edit={edit}
									value={companyProfileData.name}
								/>

								<EditableText
									onChange={handleChange}
									type='text'
									id='dba'
									label='dba'
									name='dba'
									value={companyProfileData.dba}
									Edit={edit}
								/>

								<EditableDropdown
									value={{
										value: companyProfileData?.country || '',
										label:
											Country.getCountryByCode(companyProfileData?.country)
												?.name || '',
									}}
									options={countries}
									Edit={edit}
									onChange={handleCountryChange}
									label='Country'
									name='country'
									className={'text-left'}
									selectDisabled={
										user?.userRole?.id == Member.ownerId ? false : true
									}
									disableNull
								/>

								<EditableDropdown
									Edit={edit}
									onChange={handleCurrencyChange}
									label='Currency'
									name='currency'
									className={'text-left'}
									value={{
										value: companyProfileData?.currency || '',
										label: companyProfileData?.currency || '',
									}}
									options={CurrenciesISO2}
									selectDisabled={
										user?.userRole?.id == Member.ownerId ? false : true
									}
									disableNull
								/>

								<EditableDropdown
									Edit={edit}
									onChange={handleIndustryChange}
									label='Industry'
									name='industry'
									className={'text-left'}
									value={industries2.find(
										(i) => i.value == companyProfileData?.industry,
									)}
									options={industries2}
								/>

								<EditableDropdown
									Edit={edit}
									onChange={handleBusinessChange}
									label='Business Type'
									name='business_type'
									className={'text-left'}
									value={business_types2.find(
										(b) => b.value == companyProfileData.businessType,
									)}
									options={business_types2}
								/>
								{edit ? (
									<div className='phone-number-container pb-3'>
										<label className='BodyTwoLight text-left m-0'>
											COMPANY PHONE
										</label>
										<PhoneNumberInput
											value={companyProfileData?.companyPhoneNumber}
											handleChange={handlePhoneChange}
											resetNumber={resetNumber}
											isPhoneFormatted={isPhoneFormatted}
											toggleFormatting={toggleFormatting}
										/>
									</div>
								) : (
									<SimpleText
										label={'Company Phone'}
										name='companyPhoneNumber'
										value={companyProfileData?.companyPhoneNumber}
									/>
								)}
							</div>
						</div>

						{/* <div
							className='d-flex flex-column'
							style={{ width: '20%', minHeight: '200px', gap: '0.8rem' }}
						>
							<h4 className='BodyTwoEmphasized w-100 text-left'>Stats</h4>
							<div className='d-flex flex-column' style={{ gap: '0.7em' }}>
								<EditableText label='Teams' value={teams.length} Edit={false} />
								<EditableText
									label='workflows'
									value={defaultWorkflows?.length}
									Edit={false}
								/>
								<EditableText
									label='tasks'
									value={defaultWorkflows
										?.map((workflow) => {
											return workflow?.client_workflow_tasks?.length;
										})
										?.reduce((a, b) => a + b, 0)}
									Edit={false}
								/>
							</div>
						</div> */}
					</div>
				</div>
			</div>

			{/* <div id='profile'>
				<form onSubmit={handleSubmit}>
					<div className='heading align-items-center'>
						<div className='text HeadlineOneBold align-self-center'>
							Profile
						</div>

						<div className=''>
							<button
								className='button82'
								type='submit'
								onClick={() => {
									setEdit(!edit);
									setButtonText(`${edit ? 'Edit' : 'Done'}`);
								}}
							>
								{buttonText}
							</button>
						</div>
					</div>

					<div className='body row'>
						<div className='HeadlineThreeBold mt-2 mb-1 col-12 justify-content-start d-flex ml-0'>
							Company Information
						</div>
						<div className='col-12'>
							<hr className='mt-0 pt-0'></hr>
						</div>
						<div className='col-12 row font-calibre-Semibold mb-1 '>
							<div className='col-3 justify-content-start d-flex'>Identity</div>

							<div className=' col-3  justify-content-start d-flex'>
								Company
							</div>

							<div className=' col-3  justify-content-start d-flex '>Stats</div>
						</div>
						<div className='col-12 row'>
							<div
								className=' col-3 text-align-start'
								style={{ height: 'fit-content' }}
							>
								<div className='lightText mt-2  justify-content-start d-flex'>
									Company Logo
								</div>
								<div className='mt-2 justify-content-start d-flex fileInput'>
									{edit && (
										<div className='d-flex'>
											<div
												style={{
													width: '79px',
													height: '24px',
													borderRadius: '3px',
													backgroundColor: '#FEF2E7',
													cursor: 'pointer',
													marginRight: '5px',
												}}
												onClick={() => {
													document.getElementById('uploadCompanyLogo').click();
												}}
											>
												<span>
													<b>Pick File</b>
												</span>
											</div>
											{!companyProfileData?.image_url && (
												<div>
													<span style={{ color: '#B4B4B4' }}>
														No file selected
													</span>
												</div>
											)}

											<input
												type='file'
												style={{ display: 'none' }}
												id='uploadCompanyLogo'
												onChange={(e) =>
													handleInputFileChange(e.target.files[0])
												}
											/>
										</div>
									)}
								</div>
								<div className='mt-2 justify-content-start d-flex '>
									<div
										className={
											companyProfileData?.companyLogoExists
												? 'avatar'
												: 'avatarText'
										}
									>
										{companyProfileData?.image_url ? (
											<img
												style={{
													width: '100%',
													height: '100%',
													borderRadius: '50%',
												}}
												src={companyProfileData?.image_url}
												alt=''
											/>
										) : (
											<div
												className={
													companyProfileData?.companyLogoExists ? 'd-none' : ''
												}
											>
												{companyProfileData?.name[0]}
											</div>
										)}
									</div>
								</div>
							</div>

							<div className='justify-content-start d-flex  col-3 align-items-start'>
								<div className='row'>
									<div className='lightText col-12 d-flex justify-content-start mt-2'>
										NAME
									</div>
									<div className='font-calibre col-11 d-flex justify-content-start '>
										{!edit ? (
											companyProfileData.name
										) : (
											<div className='w-100'>
												<input
													type='text'
													id='name'
													name='name'
													className='w-100 input'
													value={companyProfileData.name}
													onChange={handleChange}
												/>
											</div>
										)}
									</div>
									<div className='lightText col-12 d-flex justify-content-start mt-2'>
										DBA
									</div>
									<div className='font-calibre col-11 d-flex justify-content-start'>
										{!edit ? (
											companyProfileData.dba
										) : (
											<div className='w-100'>
												<input
													type='text'
													id='dba'
													name='dba'
													className='w-100 input'
													value={companyProfileData.dba}
													onChange={handleChange}
												/>
											</div>
										)}
									</div>
									<div className='lightText col-12 d-flex justify-content-start mt-2'>
										INDUSTRY
									</div>
									<div className='font-calibre col-11 d-flex justify-content-start '>
										{!edit ? (
											companyProfileData.industry
										) : (
											<div className='w-100 '>
												<DropDown
													items={industryList}
													valueObj={companyProfileData.industry}
													handleChange={(e) => handleIndustryChange(e)}
												/>
											</div>
										)}
									</div>
									<div className='lightText col-12 d-flex justify-content-start mt-2'>
										BUSINESS TYPE
									</div>
									<div className='font-calibre col-11 d-flex justify-content-start'>
										{!edit ? (
											companyProfileData.businessType
										) : (
											<div className='w-100'>
												<DropDown
													items={businessList}
													valueObj={companyProfileData.businessType}
													handleChange={(e) => handleBusinessChange(e)}
												/>
											</div>
										)}
									</div>
									<div className='lightText col-12 d-flex justify-content-start mt-2'>
										COMPANY PHONE NUMBER
									</div>
									<div className='font-calibre col-11 d-flex justify-content-start'>
										{!edit ? (
											companyProfileData?.companyPhoneNumber
										) : (
											<div className='w-100'>
												<input
													type='number'
													id='companyPhoneNumber'
													name='companyPhoneNumber'
													className='w-100 input'
													value={companyProfileData?.companyPhoneNumber}
													onChange={handleChange}
												/>
											</div>
										)}
									</div>
								</div>
							</div>
							<div
								className='justify-content-start d-flex col-3 '
								style={{ height: 'fit-content' }}
							>
								<div className='row'>
									<div className='lightText col-12 d-flex justify-content-start mt-2'>
										TEAMS
									</div>
									<div className='font-calibre col-12 d-flex justify-content-start'>
										{teams?.length}
									</div>

									<div className='lightText col-12 d-flex justify-content-start mt-2'>
										WORKFLOWS
									</div>
									<div className='font-calibre col-12 d-flex justify-content-start'>
										{companyProfileData.workflows || 0}
									</div>
									<div className='lightText col-12 d-flex justify-content-start mt-2'>
										TASKS
									</div>
									<div className='font-calibre col-12 d-flex justify-content-start'>
										{companyProfileData.tasks || 0}
									</div>
								</div>
							</div>
						</div>
					</div>
				</form>
			</div> */}
		</div>
	);
};
export default CompanyProfileInformation;
