import { Dialog, Zoom } from '@mui/material';
import React from 'react';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Zoom in={true} ref={ref} {...props} style={{ transitionDelay: '50ms' }} />;
});

function InviteConfirmModal({ open, closeHandler }) {
	return (
		<Dialog
			PaperProps={{ style: { width: 448, height: 200 } }}
			TransitionComponent={Transition}
			open={open}
			onClose={closeHandler}
		>
			<div className='my-task-delete-alert d-flex flex-column justify-content-between align-items-center h-100'>
				<div className='content d-flex flex-column' style={{ padding: 24, gap: 24 }}>
					<div
						className='HeadlineTwoBold dark-text form-title text-center'
						style={{ lineHeight: '1.6' }}
					>
						Invitation Accepted
					</div>
					<div className='d-flex flex-column w-100'>
						<div className='BodyTwoLight dark-text text-left'>
							Meeting has been added to your workspace
						</div>
					</div>
				</div>
				<div
					className='d-flex justify-content-center action-buttons w-100 f-flex'
					style={{ height: '64px' }}
				>
					<button
						id='Element'
						type='button'
						style={{
							borderRadius: '50px',
							width: '125px',
							height: '32px',
						}}
						onClick={closeHandler}
					>
						Okay
					</button>
				</div>
			</div>
		</Dialog>
	);
}

export default InviteConfirmModal;
