import React, { useCallback, useEffect, useState } from 'react';
import { convertDecimalToTime } from '../utilities/utilities';

const useEditTimeFormat = (initialValue) => {
	const [inputValue, setInputValue] = useState(initialValue);

	const handleInputChange = useCallback((event) => {
		let { value } = event.target;
		value = value.replace(/[^0-9.:]/g, '');
		setInputValue(value);
	}, []);

	useEffect(() => {
		const formattedValue =
			initialValue !== undefined
				? convertDecimalToTime(initialValue)
				: '';
		setInputValue(formattedValue);
	}, [initialValue]);

	return { inputValue, setInputValue, handleInputChange };
};

export default useEditTimeFormat;
