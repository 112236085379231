
function LimitationOfLiability() {
	return (
		<p className='BodyTwoLight'>
			<strong>
				To the fullest extent permitted by applicable law, Levvy and the other
				Levvy Parties will not be liable to you under any theory of
				liability—whether based in contract, tort, negligence, strict liability,
				warranty, or otherwise—for any indirect, consequential, exemplary,
				incidental, punitive, or special damages or lost profits, even if Levvy
				or the other Levvy Parties have been advised of the possibility of such
				damages. <br /> <br /> The total liability of Levvy and the other Levvy
				Parties for any claim arising out of or relating to these Terms or our
				Site, regardless of the form of the action, is limited to USD $50.00.{' '}
				<br />
				<br />
				The limitations set forth in this Section 9 will not limit or exclude
				liability for the gross negligence, fraud or intentional misconduct of
				Levvy or the other Levvy Parties or for any other matters in which
				liability cannot be excluded or limited under applicable law.
				Additionally, some jurisdictions do not allow the exclusion or
				limitation of incidental or consequential damages, so the above
				limitations or exclusions may not apply to you.
			</strong>
		</p>
	);
}

export default LimitationOfLiability;
