import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { executivePermissions, Member } from '../../utilities/utilities';
import SubmitButtonsField from '../Buttons/SubmitButtonsField';
import PickList from '../Inputs/PickList';
import * as teamActions from '../../redux/actions/team-management.actions';
import { useState } from 'react';
import { CircularProgress } from '@mui/material';

function AddUserToTeam({
	onCancel,
	onSubmit,
	handleChange,
	newTeamMembers,
	teamMembers,
	actionLoading,
}) {
	const user = useSelector((state) => state.authReducer.user);
	let roles = useSelector((state) => state.userReducer.roles);
	const users = useSelector((state) => state.userReducer.users);
	const { id } = useParams();
	const dispatch = useDispatch();
	const [availableUsers, setAvailableUsers] = useState([]);

	const InputBgColor = '#F6F5F2';
	const Style = {
		backgroundColor: InputBgColor,
		border: 'none',
		borderBottom: '1px solid #28383C',
	};

	useEffect(() => {
		if (
			user?.user_roles?.find((item) => {
				return item?.role_scopes
					?.map((role) => role.team_id)
					?.includes(parseInt(id));
			})?.role_id === Member.teamManagerId
		) {
			dispatch(
				teamActions.handleNewTeamMemberChange({
					name: 'role_id',
					value: '14',
				}),
			);

			setAvailableUsers(
				users?.filter((u) =>
					u?.user_teams?.find(
						(team) =>
							user?.user_roles?.find((item) => {
								return item?.role_scopes
									?.map((role) => role.team_id)
									?.includes(team.team_id);
							})?.role_id === Member.teamManagerId,
					),
				),
			);
		} else {
			setAvailableUsers(users);
		}
	}, []);

	roles = roles?.filter((role) => role.id != Member.clientId);
	const selectedUserRole = users.find(
		(user) => user.user_id === parseInt(newTeamMembers?.user_id),
	)?.role_id;

	useEffect(() => {
		if (selectedUserRole == Member.supervisorId) {
			handleChange({
				target: {
					value: Member.supervisorId,
					name: 'role_id',
				},
			});
		}

		if (selectedUserRole == Member.externalCollaborator) {
			handleChange({
				target: {
					value: Member.externalCollaborator,
					name: 'role_id',
				},
			});
		}
	}, [selectedUserRole]);

	return (
		<form
			onSubmit={(e) => {
				e.preventDefault();
				onSubmit();
			}}
		>
			<div className='p-3'>
				<div className='improved-input-designs pb-3'>
					<label className='require'>Name</label>
					<PickList
						value={availableUsers.find(
							(user) => user.user_id === parseInt(newTeamMembers?.user_id),
						)}
						options={availableUsers
							?.filter(
								(u) =>
									u.user_id !==
										teamMembers.find((m) => m.user_id == u.user_id)?.user_id &&
									Member.ownerId != u.role_id,
							)
							?.map((opt) => {
								return {
									id: opt.user_id,
									name: opt.user_name,
									is_active: opt.is_active,
								};
							})}
						Name='user_id'
						require={true}
						SelectRole={handleChange}
					/>
				</div>
				<div className='improved-input-designs pb-3'>
					<label className='require'>Email</label>
					<input
						className='w-100 bg-white'
						name='email'
						value={newTeamMembers?.email}
						style={Style}
						required
						disabled={true}
					/>
				</div>
				<div className='improved-input-designs pb-3'>
					<label className='require'>Role</label>
					<PickList
						require={true}
						Name='role_id'
						value={parseInt(newTeamMembers?.role_id)}
						className={'text-left'}
						options={roles?.filter((role) => {
							if(!selectedUserRole) {
								return true;
							}
							else if (
								selectedUserRole == Member.supervisorId ||
								selectedUserRole == Member.externalCollaborator
							) {
								return (
									role.id == Member.supervisorId ||
									role.id == Member.externalCollaborator
								);
							} else {
								return (
									role.id !== Member.supervisorId &&
									role.id !== Member.externalCollaborator
								);
							}
						})}
						SelectRole={handleChange}
						selectDisabled={
							user?.user_roles
								?.map((item) => item.role_id)
								?.includes(Member.teamManagerId) ||
							selectedUserRole == Member.supervisorId ||
							selectedUserRole == Member.externalCollaborator
						}
					/>
				</div>
			</div>

			<div className='VerticalSpacer bg-white p-3 rounded-bottom'>
				<SubmitButtonsField child={true}>
					<button
						id='Element'
						className='secondary'
						type='button'
						onClick={() => {
							onCancel();
						}}
					>
						Cancel
					</button>

					<button
						type='submit'
						className='d-flex align-items-center'
						id='Element'
						disabled={actionLoading}
					>
						{actionLoading ? (
							<CircularProgress
								size={24}
								sx={{ color: 'white' }}
								disableShrink
							/>
						) : (
							'Add'
						)}
					</button>
				</SubmitButtonsField>
			</div>
		</form>
	);
}

export default AddUserToTeam;
