import React, { useState } from 'react';
import { areArraysEqual } from '../../utilities/utilities';
import { ReactComponent as Delete } from '../../assets/icons/Trash-Light.svg';
import { ReactComponent as Edit } from '../../assets/icons/PencilSimpleColor.svg';

function SavedFilters({
	onSave,
	title,
	storedFilters = [],
	onChange,
	appliedFilters,
	onEditFilter,
	onDeleteFilter,
}) {
	const findExisitng = (filters) => {
		const newfilterKeys = Object.keys(appliedFilters || {});

		return Boolean(
			newfilterKeys.every((newfilterKey) => {
				return Boolean(areArraysEqual(filters[newfilterKey], appliedFilters[newfilterKey]));
			}),
		);
	};

	const [isExpanded, setIsExpanded] = useState(false);
	const toggleExpand = () => setIsExpanded(!isExpanded);
	const selectedFilters = storedFilters
		?.filter((item) => findExisitng(item.filters))
		?.map((item) => item.id);

	return (
		<div className='FilterSpacing-1'>
			<div className='SectionHeader'>
				<h2 className={'HeadlineThreeBold NameHeading'}>{title}</h2>
				<div
					className='d-flex'
					style={{ gap: '4px', height: '100%', alignItems: 'center' }}
				>
					<button className='clearButton p-0 m-0' onClick={onSave}>
						<h4 className='text-white BodyTwoBold m-0 pt-1 primary-text'>Create</h4>
					</button>
				</div>
			</div>

			<div className='TeamMultiPickList'>
				<div className={`TeamMultiPickList`}>
					{storedFilters?.length == 0 && (
						<div className='ItemWrapper'>
							<div className='w-100 pointer d-flex align-items-center'>
								<h4
									data-toggle='tooltip'
									data-placement='top'
									style={{ marginRight: '7px' }}
									className='BodyTwoLight'
								>
									No saved filters
								</h4>
							</div>
						</div>
					)}
					{storedFilters
						?.sort((a, b) => {
							// If both filters are selected or both are unselected, sort alphabetically
							if (
								(selectedFilters?.includes(a.id) &&
									selectedFilters?.includes(b.id)) ||
								(!selectedFilters?.includes(a.id) &&
									!selectedFilters?.includes(b.id))
							) {
								return a.name.localeCompare(b.name);
							}
							// If a is selected and b is unselected, prioritize a
							else if (
								selectedFilters?.includes(a.id) &&
								!selectedFilters?.includes(b.id)
							) {
								return -1;
							}
							// If b is selected and a is unselected, prioritize b
							else {
								return 1;
							}
						})
                        ?.filter((_, index) => (isExpanded ? true : Boolean(index < 5)))
						?.map((filter, index) => {
							return (
								<div key={index} className='ItemWrapper'>
									<div className='w-100 pointer d-flex align-items-center'>
										<h4
											data-toggle='tooltip'
											data-placement='top'
											style={{ marginRight: '7px' }}
											title={filter.name}
											className='BodyTwoLight'
										>
											{filter.name.length > 24
												? filter.name.substring(0, 24) + '...'
												: filter.name}
										</h4>
									</div>

									<div
										style={{
											display: 'flex',
											gap: '4px',
											alignItems: 'center',
										}}
									>
										<Edit className='pointer' onClick={onEditFilter(filter)} />
										<Delete
											className='pointer'
											onClick={onDeleteFilter(filter)}
										/>

										<input
											value={filter.id}
											onChange={onChange}
											checked={selectedFilters?.includes(filter.id)}
											type='checkbox'
											className='multi-select-checkbox'
										/>
									</div>
								</div>
							);
						})}
				</div>

				{storedFilters?.length > 5 && (
					<div
						onClick={toggleExpand}
						className='BodyThreeLight primary-text text-left w-100 pointer'
					>
						{isExpanded ? 'Show less' : 'Show more'}
					</div>
				)}
			</div>
		</div>
	);
}

export default React.memo(SavedFilters);
