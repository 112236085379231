import { useEffect, useState } from 'react';
// import SectionHeader from '../sidebar/SectionHeader';
// import NewToggleSwitch from '../sub_components/NewToggleSwitch';
import Sidebar from '../sidebar/SideBar';
import InvocieBar from './InvocieBar';
import InvocieTopBar from './InvocieTopBar';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Input } from '../Inputs/Input';
import { ReactComponent as CalendarIcon } from '../../assets/icons/Calendarcolor.svg';
import InvociePaymentDetails from './InvociePaymentDetails';
import InvoicePreview from './InvoicePreviewModal';
import useModal from '../Modal/useModal';
import { Modal, Tooltip } from '@mui/material';
import { ReactComponent as DeleteIcon } from '../../assets/icons/Delete.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/PencilEdit.svg';
import { useDispatch, useSelector } from 'react-redux';
import * as profileActions from '../../redux/actions/company-profile';
import * as clientActions from '../../redux/actions/client-actions';
import * as invoiceActions from '../../redux/actions/invoices-actions';
import * as clientBillingActions from '../../redux/actions/client-billing-actions';

import { useParams } from 'react-router';
import moment from 'moment';
import { ReactComponent as AddIcon } from '../../assets/icons/add.svg';
import DummyModal from '../Modal/DummyModal';
import AddLineItemModal from '../Modal/AddLineItemModal';
import {
	calculateDueDate,
	calculatePaymentTerm,
	clientPaymentTerms,
	numberWithCommas,
} from '../../utilities/utilities';
import { EditableDropdown } from '../Inputs/EditableDropDown';
import { setSnackBarIsOpen } from '../../redux/actions/dashboard/workspaces-actions';
import SkeletonLoading from '../Others/SkeletonLoading';
import RichTextEditor from '../Inputs/RichTextEditor';

function InvoiceDetails() {
	const dispatch = useDispatch();
	const { id } = useParams();

	const [invoiceDetails, setInvoiceDetails] = useState({});
	const [originalInvoiceDetails, setOriginalInvoiceDetails] = useState({});
	const [error, setError] = useState('');
	const [activeBilling, setActiveBilling] = useState();
	const [selectedLineItem, setSelectedLineItem] = useState(null);
	const [loading, setIsLoading] = useState(false);
	const invoice = useSelector((state) => state.invoicesReducer.invoice);
	const companyProfileData = useSelector((state) => state.profileReducer.profileData);
	const companyProfileLoading = useSelector((state) => state.profileReducer.loading);
	const user = useSelector((state) => state.authReducer.user);
	const client = useSelector((state) => state.clientReducer.client);
	const clientSectionLoading = useSelector((state) => state.clientReducer.clientSectionLoading);

	const clientBilling = useSelector((state) => state.clientBillingReducer.clientBilling);
	const activeClientbilling = clientBilling?.find((item) => item?.is_active === true);
	const amount_due =
		Number(invoiceDetails?.total_amount) +
		invoiceDetails?.total_tax_amount -
		invoiceDetails?.total_discount;

	const { modalIsOpen, toggleModal, ModalName, ModalType } = useModal();
	const handleModalOpen = (type) => {
		ModalType(type);
		toggleModal();
	};

	const handleChange = (e) => {
		const { value, name } = e.target;
		setInvoiceDetails({
			...invoiceDetails,
			[name]: value,
		});
	};

	const quillChange = (value, delta, source) => {
		if (source == 'user') {
			setInvoiceDetails({
				...invoiceDetails,
				notes: value,
			});
		}
	};

	const handleEditItem = (item) => {
		setSelectedLineItem(item);
		handleModalOpen('ADD_LINE_ITEM');
	};

	const handleDeleteItem = (item) => {
		const updatedArr = invoiceDetails?.line_items?.filter((lineItem) => {
			return (
				(item?._id && lineItem?._id !== item?._id) ||
				(item?._tempId && lineItem?._tempId !== item?._tempId)
			);
		});
		setInvoiceDetails({
			...invoiceDetails,
			line_items: updatedArr || [],
		});
	};

	const handleDateChange = (date, name) => {
		setInvoiceDetails({
			...invoiceDetails,
			[name]: date,
		});
	};

	const handleDataChange = (e) => {
		const { value, name } = e.target;

		setActiveBilling({
			...activeBilling,
			[name]: value,
		});
		setError(false);
	};

	const updateInvoice = () => {
		setIsLoading(true);
		dispatch(invoiceActions.updateInvoice(id, { ...invoiceDetails, amount_due: amount_due }))
			.then(() => {
				dispatch(setSnackBarIsOpen(true, true, 'Invoice details updated successfully!'));
				setOriginalInvoiceDetails(JSON.stringify(invoiceDetails));
			})
			.catch((error) => {
				console.error('Failed to update the invoice:', error);
				dispatch(setSnackBarIsOpen(false, true, 'Failed to update the invoice'));
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	useEffect(() => {
		if (invoice) {
			setInvoiceDetails(invoice);
			setOriginalInvoiceDetails(JSON.stringify(invoice));
		}

		setActiveBilling(activeClientbilling);

		return () => {
			setActiveBilling(null);
			setInvoiceDetails(null);
		};
	}, [invoice, activeClientbilling]);

	useEffect(() => {
		if (invoiceDetails && activeBilling?.payment_terms) {
			const currentPaymentTerms = activeBilling.payment_terms;
			const currentDueDate = invoiceDetails.due_date;
			const newDueDate = calculateDueDate(currentPaymentTerms, invoiceDetails?.issue_date);

			if (newDueDate !== currentDueDate) {
				setInvoiceDetails((prevDetails) => ({
					...prevDetails,
					due_date: newDueDate,
				}));
			}
		}
	}, [invoiceDetails, activeBilling]);

	useEffect(() => {
		if (invoiceDetails?.issue_date && invoiceDetails?.due_date) {
			const calculatedPaymentTerm = calculatePaymentTerm(
				invoiceDetails?.issue_date,
				invoiceDetails?.due_date,
			);
			if (calculatedPaymentTerm && calculatedPaymentTerm !== activeBilling?.payment_terms) {
				setActiveBilling((prevBilling) => ({
					...prevBilling,
					payment_terms: calculatedPaymentTerm,
				}));
			}
		}
	}, [invoiceDetails?.issue_date, invoiceDetails?.due_date]);

	useEffect(() => {
		const totalAmountSum = invoiceDetails?.line_items?.reduce((accumulator, currentItem) => {
			return accumulator + currentItem.total_amount;
		}, 0);

		const roundedTotalAmount = Number(totalAmountSum?.toFixed(2));

		if (invoiceDetails?.total_amount !== roundedTotalAmount) {
			setInvoiceDetails((prevDetails) => ({
				...prevDetails,
				total_amount: roundedTotalAmount,
			}));
		}
	}, [invoiceDetails?.line_items]);

	useEffect(() => {
		dispatch(profileActions.getTenantProfile(user?.tenantIds[0]));
		dispatch(invoiceActions.getSpecificInvoice(id))
			.then((res) => {
				dispatch(clientActions.getClient(res?.client_id));
				dispatch(clientBillingActions.getClientBilling(res?.client_id));
			})
			.catch((err) => {
				console.log(err, 'err');
			});

		return () => {
			dispatch(clientActions.resetClientState());
		};
	}, [dispatch]);

	useEffect(() => {}, [id, clientBilling]);

	const handleGenerateClick = () => {
		if (!activeBilling?.payment_terms) {
			setError(true);
		} else {
			setError(false);
			updateInvoice();
			handleModalOpen('INVOICE_PREVIEW');
		}
	};

	console.log(invoiceDetails, 'invoiceDetails123', activeClientbilling, clientBilling);

	return (
		<div className='AppBody'>
			<Sidebar title='Invoices'>
				<div className='SidebarContentWrapper'>
					<div className='User-filters'>
						{/* <div className='SidebarListWrapper'>
							<div className='FilterSpacing-1'>
								<SectionHeader title='Customize Invoice' />

								<div className='TeamMultiPickList w-100'>
									<div className='ItemWrapper pb-1 pt-1  the-item-wrapper align-items-center justify-content-center w-100'>
										<div className='w-100 d-flex align-items-center w-100'>
											<NewToggleSwitch
												label='Invoice no'
												labelPlacement='start'
												// checked={weekDaysOff}
												style={{ color: '#FB7A03' }}
												size='small'
												className=' white-text text-left w-100'
											/>
										</div>
									</div>

									<div className='ItemWrapper pb-1 pt-1  the-item-wrapper align-items-center justify-content-center'>
										<div className='w-100 d-flex align-items-center '>
											<NewToggleSwitch
												label='Invoice Date'
												labelPlacement='start'
												// checked={weekDaysOff}
												style={{ color: '#FB7A03' }}
												size='small'
												className=' white-text text-left w-100'
												// onChange={toggleWeekends}
											/>
										</div>
									</div>
									<div className='ItemWrapper pb-1 pt-1  the-item-wrapper align-items-center justify-content-center w-100'>
										<div className='w-100 d-flex align-items-center w-100'>
											<NewToggleSwitch
												label='Payment Terms'
												labelPlacement='start'
												// checked={weekDaysOff}
												style={{ color: '#FB7A03' }}
												size='small'
												className=' white-text text-left w-100'
												// onChange={toggleWeekends}
											/>
										</div>
									</div>

									<div className='ItemWrapper pb-1 pt-1  the-item-wrapper align-items-center justify-content-center w-100'>
										<div className='w-100 d-flex align-items-center w-100'>
											<NewToggleSwitch
												label='Due Date'
												labelPlacement='start'
												// checked={weekDaysOff}
												style={{ color: '#FB7A03' }}
												size='small'
												className=' white-text text-left w-100'
												// onChange={toggleWeekends}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className='FilterSpacing-1 mt-5'>
								<SectionHeader title='Services' />

								<div className='TeamMultiPickList w-100'>
									<div className='ItemWrapper pb-1 pt-1  the-item-wrapper align-items-center justify-content-center w-100'>
										<div className='w-100 d-flex align-items-center w-100'>
											<NewToggleSwitch
												label='Task Name'
												labelPlacement='start'
												// checked={weekDaysOff}
												style={{ color: '#FB7A03' }}
												size='small'
												className=' white-text text-left w-100'
												// onChange={toggleWeekends}
											/>
										</div>
									</div>
									<div className='ItemWrapper pb-1 pt-1  the-item-wrapper align-items-center justify-content-center w-100'>
										<div className='w-100 d-flex align-items-center w-100'>
											<NewToggleSwitch
												label='Users'
												labelPlacement='start'
												// checked={weekDaysOff}
												style={{ color: '#FB7A03' }}
												size='small'
												className=' white-text text-left w-100'
												// onChange={toggleWeekends}
											/>
										</div>
									</div>

									<div className='ItemWrapper pb-1 pt-1  the-item-wrapper align-items-center justify-content-center'>
										<div className='w-100 d-flex align-items-center '>
											<NewToggleSwitch
												label='No. of Hours'
												labelPlacement='start'
												// checked={weekDaysOff}
												style={{ color: '#FB7A03' }}
												size='small'
												className=' white-text text-left w-100'
												// onChange={toggleWeekends}
											/>
										</div>
									</div>
									<div className='ItemWrapper pb-1 pt-1  the-item-wrapper align-items-center justify-content-center w-100'>
										<div className='w-100 d-flex align-items-center w-100'>
											<NewToggleSwitch
												label='Hourly Rate'
												labelPlacement='start'
												// checked={weekDaysOff}
												style={{ color: '#FB7A03' }}
												size='small'
												className=' white-text text-left w-100'
												// onChange={toggleWeekends}
											/>
										</div>
									</div>

									<div className='ItemWrapper pb-1 pt-1  the-item-wrapper align-items-center justify-content-center w-100'>
										<div className='w-100 d-flex align-items-center w-100'>
											<NewToggleSwitch
												label='Due Date'
												labelPlacement='start'
												// checked={weekDaysOff}
												style={{ color: '#FB7A03' }}
												size='small'
												className=' white-text text-left w-100'
												// onChange={toggleWeekends}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className='FilterSpacing-1 mt-5'>
								<SectionHeader title='Discounts' />

								<div className='TeamMultiPickList w-100'>
									<div className='ItemWrapper pb-1 pt-1  the-item-wrapper align-items-center justify-content-center w-100'>
										<div className='w-100 d-flex align-items-center w-100'>
											<NewToggleSwitch
												label='Discount'
												labelPlacement='start'
												// checked={weekDaysOff}
												style={{ color: '#FB7A03' }}
												size='small'
												className=' white-text text-left w-100'
												// onChange={toggleWeekends}
											/>
										</div>
									</div>
								</div>
							</div>
						</div> */}
					</div>
				</div>
			</Sidebar>
			<div className='UserProfile-main user-profile-main'>
				<div className='UserProfile-content ' style={{ width: '81vw' }}>
					{/* <InvocieBar /> */}
					<InvocieTopBar
						save={true}
						Title={invoice?.status}
						textTransparent={false}
						InvoiceNo={`Invoice #${invoice?.invoice_no + '-' + invoice?.invoice_seq || 0}`}
						btnText='Save'
						btnText2='Preview & Send'
						GenerateOnClick={handleGenerateClick}
						onSave={updateInvoice}
						isLoading={loading}
						disabled={amount_due == 0 && true}
						Draft={true}
					/>
					<div
						className='Teams-Table border'
						style={{
							padding: '18px',
							backgroundColor: '#EEF2F3',
							borderRadius: '4pxs',
							boxShadow: '0px 7px 29px 0px rgba(100, 100, 111, 0.20)',
						}}
					>
						<div style={{ display: 'flex', flexDirection: 'column', gap: '32px' }}>
							<div>
								<SkeletonLoading
									loading={!invoiceDetails?.invoice_no}
									variant='text'
									width={150}
									height={45}
								>
									<span className='HeadlineTwoBold d-flex justify-content-start'>
										Invoice #{invoice?.invoice_no + '-' + invoice?.invoice_seq}
									</span>
								</SkeletonLoading>
								<div
									style={{
										width: '100%',
										display: 'flex',
										justifyContent: 'space-between',
									}}
									className='mt-3'
								>
									<div
										className='d-flex flex-column text-left'
										style={{
											width: '18%',
											minHeight: '125px',
											gap: '0.8rem',
										}}
									>
										<span className='BodyOneBold'>From</span>
										<SkeletonLoading
											loading={companyProfileLoading}
											variant='text'
											width={150}
											height={70}
										>
											<div className='d-flex flex-column'>
												<div className='text-left ProfilePage'>
													<h3
														className='BodyTwoLight'
														style={{
															wordBreak: 'break-all',
														}}
													>
														{companyProfileData?.name}
														<span className='d-block mt-1'>
															{/* {business.address} */}
														</span>
														<span className='d-block mt-1'>
															{companyProfileData?.country}
														</span>
														<span className='d-block mt-1'>
															{/* {business.email} */}
														</span>
													</h3>
												</div>
											</div>
										</SkeletonLoading>
									</div>
									{companyProfileData?.image_url && (
										<SkeletonLoading
											loading={companyProfileLoading}
											variant='text'
											width={150}
											height={150}
										>
											<div style={{ width: '125px', height: '125px' }}>
												<img
													src={companyProfileData?.image_url}
													alt=''
													style={{ width: '125px', height: '125px' }}
												/>
											</div>
										</SkeletonLoading>
									)}
								</div>
								<div
									className='mt-4'
									style={{
										width: '100%',
										padding: '12px',
										borderRadius: '8px',
										justifyContent: 'space-between',
										backgroundColor: '#F7F9FA',
									}}
								>
									<div
										className='d-flex'
										style={{
											justifyContent: 'space-between',
										}}
									>
										<div
											className='d-flex flex-column text-left'
											style={{
												width: '18%',
												minHeight: '125px',
												gap: '0.8rem',
											}}
										>
											<span className='BodyOneBold'>Invoice For</span>
											<SkeletonLoading
												loading={clientSectionLoading || client == null}
												variant='text'
												width={150}
												height={80}
											>
												<div className='d-flex flex-column'>
													<div className='text-left ProfilePage'>
														<h3
															className='BodyTwoLight'
															style={{
																wordBreak: 'break-all',
															}}
														>
															{client?.dba || client?.company_name}
															<span className='d-block mt-1'>
																{client?.suit_floor &&
																	client?.address && (
																		<>
																			{client.suit_floor},{' '}
																			{client.address}
																		</>
																	)}
																{!client?.suit_floor &&
																	client?.address && (
																		<>{client.address}</>
																	)}
															</span>
															<span className='d-block mt-1'>
																{client?.state && (
																	<>
																		{client.state}
																		{client?.zip &&
																			`, ${client.zip}`}
																	</>
																)}
																{!client?.state && client?.zip && (
																	<>{client.zip}</>
																)}
															</span>
														</h3>
													</div>
												</div>
											</SkeletonLoading>
										</div>
										<div
											className=' d-flex justify-content-between align-items-baseline'
											style={{ width: '55vw' }}
										>
											<div
												className='improved-input-designs '
												style={{ width: '24%' }}
											>
												<label className=' text-left'>Invoice No</label>
												<Input
													value={
														invoice?.invoice_no + '-' + invoice?.invoice_seq
													}
													disabled
													style={{ opacity: 0.5 }}
												/>
											</div>

											<div
												className='improved-input-designs pb-3 mt-1'
												style={{ width: '24%' }}
											>
												<label className='w-100 text-left'>
													Invoice Date
												</label>
												<KeyboardDatePicker
													autoOk
													disableToolbar
													name='issue_date'
													onChange={(date) =>
														handleDateChange(date, 'issue_date')
													}
													format='MM/DD/yyyy'
													value={invoiceDetails?.issue_date}
													className='p-1'
													required
													keyboardIcon={<CalendarIcon />}
												/>
											</div>
											<div
												className='improved-input-designs'
												style={{ width: '24%' }}
											>
												<label className='require text-left'>
													Payment Terms
												</label>
												<EditableDropdown
													Edit={true}
													name='payment_terms'
													selectClassName='white-bg'
													className='text-left text-capitalize'
													value={clientPaymentTerms?.find(
														(item) =>
															item.value ===
															activeBilling?.payment_terms,
													)}
													required={true}
													options={clientPaymentTerms}
													onChange={handleDataChange}
												/>
												{error && (
													<div>
														<span
															style={{ color: 'red' }}
															className='BodyTwoLight'
														>
															Please select a payment term
														</span>
													</div>
												)}
											</div>

											<div
												className='improved-input-designs pb-3 mt-1'
												style={{ width: '24%' }}
											>
												<label className='w-100 text-left'>Due Date</label>
												<KeyboardDatePicker
													autoOk
													disableToolbar
													disabled
													style={{ opacity: 0.5 }}
													name='due_date'
													onChange={(date) =>
														handleDateChange(date, 'due_date')
													}
													format='MM/DD/yyyy'
													value={invoiceDetails?.due_date}
													className='p-1'
													required
													keyboardIcon={<CalendarIcon />}
												/>
											</div>
										</div>
									</div>
								</div>
								<div
									className=' d-flex justify-content-between mt-4 p-3'
									style={{ width: '100%' }}
								>
									<div
										style={{
											width: '80%',
											display: 'flex',
											justifyContent: 'space-between',
										}}
									>
										<span className='HeadlineThreeBold text-left Invoice-Tabel-headerWidth'>
											Services
										</span>
										<span className='HeadlineThreeBold text-left Invoice-Tabel-headerWidth'>
											Unit Price
										</span>
										<span className='HeadlineThreeBold text-left Invoice-Tabel-headerWidth'>
											Quantity
										</span>
										<span className='HeadlineThreeBold text-left Invoice-Tabel-headerWidth'>
											Amount
										</span>
									</div>
									<div>
										<span className='ml-2 BodyTwoLight'>Billing Period</span>
										<span className='ml-2 BodyTwoLight'>
											{moment(
												invoiceDetails?.billing_period?.start_date,
											).format('MM/DD/YYYY')}
										</span>
										<span className='ml-2 BodyTwoLight'>-</span>
										<span className='ml-2 BodyTwoLight'>
											{moment(
												invoiceDetails?.billing_period?.end_date,
											).format('MM/DD/YYYY')}
										</span>
									</div>
								</div>
								<div
									className=' d-flex justify-content-between'
									style={{
										width: '100%',
										height: '1px',
										backgroundColor: '#b4b4b4',
									}}
								></div>
								<div>
									{invoiceDetails?.line_items?.length > 0 ? (
										invoiceDetails?.line_items?.map((data, index) => (
											<div
												key={index}
												className='d-flex justify-content-between mt-4 p-3'
												style={{ width: '100%', backgroundColor: 'white' }}
											>
												<div
													style={{
														width: '80%',
														display: 'flex',
														justifyContent: 'space-between',
													}}
												>
													<span className='BodyOneLight text-left Invoice-Tabel-headerWidth'>
														{data?.description}
													</span>
													<span className='BodyOneLight text-left  Invoice-Tabel-headerWidth'>
														${numberWithCommas(data?.unit_amount)}
													</span>
													<span className='BodyOneLight text-left  Invoice-Tabel-headerWidth'>
														{data?.quantity}
													</span>
													<span className='BodyOneLight text-left  Invoice-Tabel-headerWidth'>
														${numberWithCommas(data?.total_amount)}
													</span>
												</div>
												<div
													className=' d-flex justify-content-between'
													style={{ width: '48px' }}
												>
													<Tooltip title='Edit'>
														<div onClick={() => handleEditItem(data)}>
															<EditIcon
																style={{
																	color: '#fb7a03',
																	cursor: 'pointer',
																}}
															/>
														</div>
													</Tooltip>
													<Tooltip title='Delete'>
														<div onClick={() => handleDeleteItem(data)}>
															<DeleteIcon
																style={{
																	color: '#fb7a03',
																	cursor: 'pointer',
																}}
															/>
														</div>
													</Tooltip>
												</div>
											</div>
										))
									) : (
										<div
											className='d-flex align-items-center justify-content-center mt-4 p-3'
											style={{ width: '100%', backgroundColor: 'white' }}
										>
											<span
												className='BodyTwoLight'
												style={{ color: '#696f6c' }}
											>
												There is currently no item.
											</span>
										</div>
									)}
								</div>
								<div
									className='mt-3 d-flex flex-column justify-content-between'
									style={{ minHeight: '70px' }}
								>
									<div
										className=' d-flex justify-content-between'
										style={{
											width: '100%',
											height: '1px',
											backgroundColor: '#b4b4b4',
										}}
									></div>
									<div>
										<button
											className='rounded-button d-flex '
											onClick={() => {
												handleModalOpen('ADD_LINE_ITEM');
												setSelectedLineItem(null);
											}}
										>
											<AddIcon />
											<span className='BodyTwoBold p-1'>Add an item</span>
										</button>
									</div>
									<div
										className=' d-flex justify-content-between'
										style={{
											width: '100%',
											height: '1px',
											backgroundColor: '#b4b4b4',
										}}
									></div>
								</div>
							</div>
						</div>
						<div
							className='d-flex gap-[10px] justify-content-between'
							style={{ minHeight: '250px' }}
						>
							<div className='d-flex flex-column align-items-start mt-2 improved-input-designs' style={{ minHeight: '112px', minWidth:'560px' }}>
                            <span className='BodyOneBold'>Notes</span>
                            <textarea
								className='w-100'
                                style={{minHeight:'112px', maxHeight:'220px'}}
								name='notes'
								value={invoiceDetails?.notes || ''}
								onChange={handleChange}
							/>
								{/* <RichTextEditor
									className='primary-text pointer '
									value={invoiceDetails?.notes}
									type='text'
									Edit={true}
									rows={1}
									style={{
										height: '112px',
                                        width: '564px',
										lineHeight: '1rem',
										marginBottom: '3.4rem',                                        
									}}
									name='notes'
									onChange={quillChange}
								/> */}
								{/* <InvociePaymentDetails
									title='Notes'
									value={invoiceDetails?.notes}
									onChange={handleChange}
									name='notes'
								/> */}
								{/* <InvociePaymentDetails attachment={true} title='Attachment' /> */}
							</div>
							<div
								style={{
									width: '286px',
									height: '172px',
									backgroundColor: '#F7F9FA',
									padding: '18px',
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'space-evenly',
								}}
								className='mt-4'
							>
								<div
									style={{
										width: '100%',
										display: 'flex',
										justifyContent: 'space-between',
									}}
								>
									<span className='BodyOneLight'>Subtotal</span>
									<span className='BodyOneLight'>
										${numberWithCommas(invoiceDetails?.total_amount) || 0}
									</span>
								</div>
								{/* <div
									style={{
										width: '100%',
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
									}}
								>
									<span className='BodyOneLight'>Discount</span>
									<div className='d-flex align-items-center'>
										<span className='BodyOneLight'>$</span>
										<input
											value={invoiceDetails?.total_discount}
											style={{
												width: '75px',
												height: '28px',
												backgroundColor: 'white',
												display: 'flex',
												justifyContent: 'space-between',
												outline: 'none',
												border: 'none',
											}}
											className='BodyOneLight px-1'
											type='number'
											onChange={handleChange}
											name='total_discount'
										/>
									</div>
								</div> */}
								{/* <div
									style={{
										width: '100%',
										display: 'flex',
										justifyContent: 'space-between',
									}}
								>
									<span className='BodyOneLight'>Sales tax</span>
									<span className='BodyOneLight'>
										${invoiceDetails?.total_tax_amount || 0}
									</span>
								</div> */}
								<div
									style={{
										width: '250px',
										height: '1px',
										backgroundColor: '#b4b4b4',
									}}
								></div>
								<div
									style={{
										width: '100%',
										display: 'flex',
										justifyContent: 'space-between',
									}}
								>
									<span className='BodyOneBold'>Invoice total</span>
									<span className='BodyOneBold'>
										${numberWithCommas(amount_due) || 0}
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{ModalName === 'ADD_LINE_ITEM' && (
				<Modal
					onClose={toggleModal}
					open={modalIsOpen}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<DummyModal title={'Add Item'} onClose={toggleModal}>
						<AddLineItemModal
							title={selectedLineItem ? 'Update' : 'Add'}
							Onsubmit={(updatedItem) => {
								setInvoiceDetails((prevDetails) => {
									const updatedItems = [...prevDetails.line_items];

									const itemIndex = updatedItems.findIndex(
										(item) =>
											(item._id &&
												updatedItem._id &&
												item._id === updatedItem._id) ||
											(item._tempId &&
												updatedItem._tempId &&
												item._tempId === updatedItem._tempId),
									);

									if (itemIndex !== -1) {
										updatedItems[itemIndex] = updatedItem;
									} else {
										if (!updatedItem._id && !updatedItem._tempId) {
											updatedItem._tempId = new Date().getTime().toString();
										}
										updatedItems.push(updatedItem);
									}

									return { ...prevDetails, line_items: updatedItems };
								});
								toggleModal();
							}}
							selectedItem={selectedLineItem}
							activeClientbilling={activeClientbilling}
						/>
					</DummyModal>
				</Modal>
			)}
			{ModalName == 'INVOICE_PREVIEW' && (
				<InvoicePreview modalIsOpen={modalIsOpen} toggleModal={toggleModal} />
			)}
		</div>
	);
}

export default InvoiceDetails;
