import { useEffect } from 'react';
import TransactionHistoryTable from './TransactionHistoryTable';
import { useDispatch, useSelector } from 'react-redux';

import * as profileActions from '../../../redux/actions/company-profile';
function CompanyTransactionHistory() {
	const dispatch = useDispatch();

	const customerInvoices = useSelector((state) => state.profileReducer.customerInvoices);

	useEffect(() => {
		dispatch(profileActions.getCustomerInvoices());
	}, [dispatch]);

	return (
		<>
			{customerInvoices?.data?.length > 0 ? (
				<div className='stats-container'>
					<span className='text-left w-100 d-flex align-items-center header-bar'>
						<h3 className='HeadlineThreeBold'>Transaction History</h3>
					</span>
					<div className='graph-container d-flex'>
						<TransactionHistoryTable customerInvoices={customerInvoices}/>
					</div>
				</div>
			) : (
				<></>
			)}
		</>
	);
}

export default CompanyTransactionHistory;
