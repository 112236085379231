import React, { memo } from 'react';

// Components
import SectionHeader from './SectionHeader';
import TeamMultiPickList from '../Inputs/TeamMultiPickList';
import ProjectMultiPickList from '../Inputs/ProjectMultiPickList';

const JobTitlePicker = ({ options, onChange, onClear }) => {
	return (
		<div className='FilterSpacing-1'>
			<SectionHeader label='Clear All' title='Roles' onClear={onClear} />
			<TeamMultiPickList
				className='high'
				options={options ? options : []}
				onChange={onChange}
			/>
		</div>
	);
};

export default memo(JobTitlePicker);
