import React from 'react';
import { ReactComponent as SecurityIcon } from '../../assets/icons/LockKey.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/PencilEdit.svg';
import { Modal, Tooltip } from '@mui/material';
import useModal from '../Modal/useModal';
import DummyModal from '../Modal/DummyModal';
import ChangePasswordModal from './ChangePasswordModal';

export default function ChangePassword() {
	const { modalIsOpen, toggleModal, ModalName, ModalType } = useModal();

	return (
		<>
			<div className='profile-section' style={{ gap: '12px' }}>
				<div className='Uprofile-sectionBar'>
					<div className='d-flex align-items-center' style={{ gap: '12px' }}>
						<SecurityIcon />
						<h2 className='HeadlineThreeBold text-left'>Security</h2>
					</div>
					<Tooltip title='Edit Client Info'>
						<EditIcon
							style={{
								color: '#fb7a03',
								cursor: 'pointer',
							}}
							onClick={() => {
								ModalType('CHANGE_PASSWORD');
								toggleModal();
							}}
						/>
					</Tooltip>
				</div>

				<div
					style={{
						padding: '18px',
						backgroundColor: '#EEF2F3',
						borderRadius: '4px',
					}}
				>
					<div className='PersonalDetails'>
						<div>
							<p className='BodyTwoLight mb-1 text-left'>Password</p>
							<span className='BodyTwoLight'>*****************</span>
						</div>
					</div>
				</div>
				{modalIsOpen && (
					<>
						<Modal
							onClose={toggleModal}
							open={modalIsOpen}
							sx={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							{ModalName == 'CHANGE_PASSWORD' && (
								<DummyModal title='Change Password' onClose={toggleModal}>
									<ChangePasswordModal onCancel={toggleModal} />
								</DummyModal>
							)}
						</Modal>
					</>
				)}
			</div>
		</>
	);
}
