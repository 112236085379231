/* eslint-disable no-mixed-spaces-and-tabs */
import { useEffect, useMemo, useRef, useState } from 'react';
import { ReactComponent as Headset } from '../assets/icons/Headset.svg';
// import { ReactComponent as Eraser } from '../assets/icons/Eraser.svg';
import { ReactComponent as Bell } from '../assets/icons/Bell.svg';
import { ReactComponent as Warning } from '../assets/icons/Warning.svg';
import { ReactComponent as Clock } from '../assets/icons/ApprovalClock.svg';
import { ReactComponent as Shield } from '../assets/icons/ShieldCheck.svg';
import { ReactComponent as UpArrow } from '../assets/icons/upArrow.svg';
import { ReactComponent as DownArrow } from '../assets/icons/downArrow.svg';
import { ReactComponent as Leaves } from '../assets/icons/Door.svg';
import { ReactComponent as Cross } from '../assets/icons/XCircle.svg';
import { ReactComponent as Complete } from '../assets/icons/icon/complete.svg';
import { ReactComponent as DeleteIcon } from '../assets/icons/Trash-Light.svg';
import { CircularProgress, IconButton, Modal } from '@mui/material';
import EmojiPicker, { Emoji } from 'emoji-picker-react';
import DummyModal from '../components/Modal/DummyModal';
// import ConfirmModal from '../components/Modal/ConfirmModal';
// import useModal from '../components/Modal/useModal';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from '../redux/actions/dashboard/workspaces-actions';
import * as ActionCenterActions from '../redux/actions/action-center';
import * as clientActions from '../redux/actions/client-actions';
import * as teamActions from '../redux/actions/team-management.actions';
import * as userActions from '../redux/actions/user-management.actions';
import * as builderActions from '../redux/actions/workflow-builder-actions';

import { useHistory, useLocation } from 'react-router';
import LeavesModal from '../components/WorkSpace/LeavesModal';
import Restricted from '../components/Permissions/Restricted';
import AddTeam from '../components/TeamManagement/AddTeam';
import UserAdd from '../components/UserManagement/UserAdd';
import AddClient from '../components/ClientManagement/AddClient';
import {
	EVENT_NOTIFICATIONS,
	Member,
	NOTIFICATION_STATUS,
	TRACKING_STATUS,
	detailsModal,
	standardPermissions,
	statuses,
	timeList,
} from '../utilities/utilities';
// import ToggleSwitch from '../components/sub_components/ToggleSwitch';
import CloseIcon from '@mui/icons-material/Close';
import { ReactComponent as Smiley } from '../assets/icons/Smiley.svg';
import SubmitButtonsField from '../components/Buttons/SubmitButtonsField';
import { KeyboardDatePicker } from '@material-ui/pickers';
// import { KeyboardTimePicker } from '@material-ui/pickers';
import moment from 'moment';
import DropDown from '../components/Inputs/DropDown';
import ConfirmModal from '../components/Modal/ConfirmModal';
import useModal from '../components/Modal/useModal';
import MeetingMyTaskDetailsModal from '../components/WorkSpace/Task Modal/MeetingMyTaskDetailsModal';
import DeclineConfirmModal from '../components/WorkSpace/Task Modal/DeclineConfirmModal';
import { getUserSettings } from '../hooks/getUserSettings';
import posthog from 'posthog-js';

export default function ActionCenterModal() {
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const ref = useRef();
	const statusRef = useRef();

	const handleTaskTimerRef = useRef();

	const InitialForm = {
		name: '',
		description: '',
	};

	const InitialClientForm = {
		company_name: '',
		dba: '',
		industry: '',
		ssn: '',
		ein: '',
		business_type: '',
		date: '',
		state: 0,
		team_client_permissions: {
			team_id: 0,
		},
	};

	const InitialUserForm = {
		fname: '',
		lname: '',
		email: '',
		role_id: 0,
		team_id: 0,
		image: '',
	};

	const [FormUserFields, setFormUserFields] = useState(InitialUserForm);
	const [ClientFormFields, setClientFormFields] = useState(InitialClientForm);
	const [openUnassignedTasks, setOpenUnAssignedTasks] = useState(false);
	const [openApprovalBox, setOpenApprovalBox] = useState(false);
	const [addTeamModal, setAddTeamModal] = useState(false);
	const [addUserModal, setAddUserModal] = useState(false);
	const [inviteDeclineModal, setInviteDeclineModal] = useState(false);
	const [isClientSection, setIsClientSection] = useState(false);
	const [selectedClient, setSelectedClient] = useState({ index: null });
	const [leavesModal, setLeavesModal] = useState(false);
	const [statusModal, setStatusModal] = useState(false);
	const [leaveFormSubmitted, setLeaveFormSubmitted] = useState(false);
	const [FormFields, setFormFields] = useState(InitialForm);
	const [SelectedID, setSelectedID] = useState();
	const [addTeamConfirmationModal, setTeamConfirmationModal] = useState(false);
	const [addUserConfirmationModal, setUserConfirmationModal] = useState(false);
	const [addClientModal, setAddClientModal] = useState(false);
	const [timesDropdown, setTimesDropdown] = useState(false);
	const [selectedDate, setSelectedDate] = useState();
	const [selectedTimePicker, setSelectedTimePicker] = useState();
	const [taskModal, setTaskModal] = useState(false);
	const [myTaskModal, setMyTaskModal] = useState(false);
	const [selectedTask, setSelectedTask] = useState();
	const [selectedTaskId, setSelectedTaskId] = useState();
	const [openNotifications, setOpenNotifications] = useState(false);
	const [showEmojiPicker, setShowEmojiPicker] = useState(false);
	const [taskId, setTaskId] = useState(null);
	const [declineReason, setDeclineReason] = useState(false);

	const statusTimes = [
		{ id: 0, time: 'Clear Selection', value: null },
		{
			id: 1,
			time: '30 minutes',
			value: moment(moment().add(30, 'm').toDate()).format('YYYY-MM-DD h:mm A'),
		},
		{
			id: 2,
			time: '1 hour',
			value: moment(moment().add(1, 'h').toDate()).format('YYYY-MM-DD h:mm A'),
		},
		{
			id: 3,
			time: '4 hour',
			value: moment(moment().add(4, 'h').toDate()).format('YYYY-MM-DD h:mm A'),
		},
		{
			id: 4,
			time: 'Today',
			value: moment(moment().add(1, 'd').toDate()).format('YYYY-MM-DD h:mm A'),
		},
		{
			id: 5,
			time: 'This week',
			value: moment(moment().add(7, 'd').toDate()).format('YYYY-MM-DD h:mm A'),
		},
		{
			id: 6,
			time: 'Choose date and time',
			value: moment(selectedDate).format('YYYY-MM-DD') + ' ' + selectedTimePicker?.value,
		},
	];

	const AddUser = () => {
		setFormUserFields(InitialUserForm);
		ModalType('Invite Team Members');
		setmodalType('add');
		setAddUserModal(true);
		dispatch(userActions.resetUserAddErrors());
		setUserConfirmationModal(false);
	};

	const unAssignedClientsTasks = useSelector(
		(state) => state.workSpaceReducer.unassignedClientTasks,
	);
	const onboarding = useSelector((state) => state.actionCenterReducer.onboarding);

	const user = useSelector((state) => state.authReducer.user);
	const loading = useSelector((state) => state.actionCenterReducer.loading);
	const initialLoading = useSelector((state) => state.actionCenterReducer.initialLoading);

	const userDetails = useSelector((state) => state.authReducer.userDetails);
	const userStatus = useSelector((state) => state.actionCenterReducer.userStatus);
	const status = useSelector((state) => state.actionCenterReducer.status);
	const emoji = useSelector((state) => state.actionCenterReducer.icon);
	const { date_format } = getUserSettings(userDetails);

	const selectedTime = useSelector((state) => state.actionCenterReducer.selectedTime);
	const isPauseNotifications = useSelector(
		(state) => state.actionCenterReducer.is_pause_notifications,
	);
	const approvalPendingTasks = useSelector(
		(state) => state.actionCenterReducer.approvalPendingTasks,
	);
	const notifications = useSelector((state) => state.actionCenterReducer.notifications);

	const openActionCenterModal = useSelector(
		(state) => state.workSpaceReducer.openActionCenterModal,
	);

	const { ModalName, ModalType } = useModal();
	const [modalType, setmodalType] = useState('');

	const toggleTeamModal = () => {
		setAddTeamModal(!addTeamModal);
		setTeamConfirmationModal(true);
	};

	const toggleUserModal = () => {
		setAddUserModal(!addUserModal);
		setUserConfirmationModal(true);
	};

	const toggleClientModal = () => {
		setAddClientModal(!addClientModal);
	};
	const toggleDeclineModal = () => {
		setInviteDeclineModal(!inviteDeclineModal);
	};

	const CreateTeam = () => {
		setFormFields(InitialForm);
		ModalType('Create Team');
		setmodalType('add');
		setAddTeamModal(true);
		setTeamConfirmationModal(false);
	};

	const AddClientForm = () => {
		dispatch(clientActions.setError(''));
		setClientFormFields(InitialClientForm);
		ModalType('Create Client');
		setmodalType('add');
		setAddClientModal(true);
	};

	const OnSubmit = (type, id, inputs) => {
		if (type == 'add') {
			dispatch(teamActions.addTeam(inputs, toggleTeamModal));
		} else {
			dispatch(teamActions.updateTeam(inputs, toggleTeamModal));
		}
	};

	const onUserSubmit = (type, id, inputs, setLoad) => {
		inputs.name = inputs.fname + ' ' + inputs.lname;

		if (type == 'add') {
			dispatch(userActions.addUser(inputs, toggleUserModal, setLoad));
		} else {
			dispatch(userActions.updateUser(inputs, toggleUserModal, setLoad));
		}
	};

	const onClientSubmit = (type, id, form, setLoading) => {
		if (type === 'add') {
			dispatch(
				clientActions.addClient(form, toggleClientModal, setLoading, history, location),
			);
		} else {
			dispatch(clientActions.updateClient(form, toggleClientModal, setLoading));
		}
	};

	const OnDateChange = (date) => {
		date &&
			setClientFormFields({
				...ClientFormFields,
				date: date?.format(),
			});
	};

	const FormChange = (e) => {
		if (e.target.name === 'ein') {
			delete ClientFormFields.ssn;
			var einVal = e.target.value.replace(/\D/g, '');
			var newEinVal = '';

			if (einVal.length > 3) {
				e.target.value = einVal;
			}
			if (einVal.length > 2) {
				newEinVal += einVal.substr(0, 2) + '-';
				einVal = einVal.substr(2);
			}
			if (e.target.value.length > 9) {
				return;
			}

			newEinVal += einVal;
			e.target.value = newEinVal;
		} else if (e.target.name === 'ssn') {
			delete ClientFormFields.ein;
			var val = e.target.value.replace(/\D/g, '');
			var newVal = '';

			if (val.length > 4) {
				e.target.value = val;
			}
			if (val.length > 3 && val.length < 6) {
				newVal += val.substr(0, 3) + '-';
				val = val.substr(3);
			}
			if (val.length > 5) {
				newVal += val.substr(0, 3) + '-';
				newVal += val.substr(3, 2) + '-';
				val = val.substr(5);
			}
			if (e.target.value.length > 9) {
				return;
			}
			newVal += val;
			e.target.value = newVal;
		}

		if (modalType === 'add') {
			if (e.target.name === 'team_id') {
				setClientFormFields({
					...ClientFormFields,
					team_client_permissions: {
						[e.target.name]: parseInt(e.target.value),
					},
				});
			} else {
				setClientFormFields({
					...ClientFormFields,
					[e.target.name]: e.target.value,
				});
			}
		} else {
			if (e.target.name === 'team_id') {
				ClientFormFields.team_client_permissions[0].team_id = parseInt(e.target.value);
				delete ClientFormFields.team_client_permissions[0].team;
				setClientFormFields({
					...ClientFormFields,
				});
			} else {
				setClientFormFields({
					...ClientFormFields,
					[e.target.name]: e.target.value,
				});
			}
		}
	};

	const handleUnassignedTasks = () => {
		setOpenUnAssignedTasks(!openUnassignedTasks);
	};

	const handleSetStatus = (data) => {
		dispatch(ActionCenterActions.setStatus(data));
	};

	const handleSetSelectedTime = (data) => {
		dispatch(ActionCenterActions.setSelectedTime(data));
	};

	const handleDateChange = (date) => {
		setSelectedDate(date);
		handleSetSelectedTime({
			id: 6,
			time: 'Choose date and time',
			value: moment(date).format('YYYY-MM-DD') + ' ' + selectedTimePicker?.value,
		});
	};

	const onPauseNotifications = (e) => {
		dispatch(ActionCenterActions.setIsPauseNotifications(e.target.checked));
	};

	const handleTimePickerChange = (e) => {
		setSelectedTimePicker({ value: e.target.value, label: e.target.value });
		handleSetSelectedTime({
			id: 6,
			time: 'Choose date and time',
			value: moment(selectedDate).format('YYYY-MM-DD') + ' ' + e.target.value,
		});
	};
	// const handleEraseDummyTask = () => {
	// 	setEraseDummyTaskModal(!eraseDummyTaskModal);
	// };
	const handleApprovalPendingBox = () => {
		setOpenApprovalBox(!openApprovalBox);
	};
	const openClientSection = (index) => {
		setIsClientSection(!isClientSection);
		setSelectedClient({ index: index });
	};

	const handleLeavesModal = () => {
		setLeavesModal(!leavesModal);
	};

	const handleStatusModal = () => {
		setStatusModal(!statusModal);
		dispatch(Actions.setOpenActionCenterModal(false));
	};

	// const closeModal = () => {
	// 	handleSetStatus(null);
	// 	setStatusModal(false);
	// 	dispatch(ActionCenterActions.deleteStatus(userStatus?.id));
	// };

	const handleTimesDropdown = () => {
		setTimesDropdown(!timesDropdown);
	};

	const handleWorkflowClick = (client, clientWorkflow) => {
		dispatch(clientActions.setClientProfileActiveTab(clientWorkflow?.id));
		dispatch(builderActions.setSelectedClientWorkflow(clientWorkflow?.id));
		history.push(`/client-profile/${client?.id}`);
	};

	const handleChange = (e) => {
		if (e.target.value === '') {
			handleSetStatus(null);
			dispatch(ActionCenterActions.setEmoji(null));
		} else {
			handleSetStatus({ text: e.target.value });
			if (emoji === null) {
				dispatch(ActionCenterActions.setEmoji('1f4ac'));
			}
		}
	};

	const onCancel = () => {
		setStatusModal(false);
		// setIsAway(false);
		// handleSetStatus(null);
		// handleSetSelectedTime();
	};

	const handleApprovalPendingTask = (task) => {
		setTaskModal(true);
		dispatch(Actions.setOpenActionCenterModal(false));
		setSelectedTask(task);
		if (task?.id) {
			dispatch(Actions.handleTaskClick({ taskId: task?.id, modalType: detailsModal.CLIENT }));
		}
		setOpenApprovalBox(false);
	};

	const getTaskDetails = (task_id, notification) => {
		if (
			notification.event === EVENT_NOTIFICATIONS.MY_TASK_INVITED ||
			notification.event === EVENT_NOTIFICATIONS.MEETING_INVITE ||
			(notification?.metadata?.task &&
				notification?.metadata?.task?.my_task &&
				notification?.metadata?.task?.my_task?.task_type == 'MEETING')
		) {
			setMyTaskModal(true);
			dispatch(Actions.getTaskDetailsById(task_id))
				.then(() => {
					dispatch(Actions.setOpenActionCenterModal(false));
					setSelectedTaskId(notification.metadata.task_id);
				})
				.catch(() => {
					setTaskId(null);
					setTaskModal(false);
					setMyTaskModal(false);
				});
		} else {
			dispatch(Actions.handleTaskClick({ taskId: task_id, modalType: detailsModal.CLIENT }));
			dispatch(Actions.setOpenActionCenterModal(false));
		}
	};

	const handleNotificationClick = (notification) => {
		handleMarkNotification([notification?.id], NOTIFICATION_STATUS.READ);
		if (notification.event == EVENT_NOTIFICATIONS.LEAVE_REQUEST_CREATED) {
			if (location?.pathname == '/leaves') {
				dispatch(ActionCenterActions.getLeaves());
			} else history.push('/leaves');

			dispatch(Actions.setOpenActionCenterModal(false));

			return;
		}
		if (notification.event == EVENT_NOTIFICATIONS.MY_TASK_INVITED) {
			return;
		} else if (notification.event == EVENT_NOTIFICATIONS.MEETING_INVITE) {
			dispatch(
				ActionCenterActions.getInvitedTasks(
					notification?.metadata?.id,
					moment().format('YYYY-MM-DD'),
				),
			)
				.then((res) => {
					const task_id = res[0].tasks[0]?.task_id;
					getTaskDetails(task_id, notification);
				})
				.catch((error) => {
					dispatch(Actions.setSnackBarIsOpen(true, true, error.message));
				});
		} else if (
			notification?.metadata &&
			(notification.metadata?.task_id || notification.metadata.id)
		) {
			getTaskDetails(
				notification.metadata?.task_id || notification.metadata?.id,
				notification,
			);
		}
		if (
			notification.event == EVENT_NOTIFICATIONS.MEETING_INVITE_REJECTED ||
			notification.event == EVENT_NOTIFICATIONS.BULK_MEETING_INVITE_REJECTED
		) {
			toggleDeclineModal();
			setDeclineReason(notification?.metadata?.taskAssignee?.reason);
			setTaskId(notification?.metadata?.task?.id);
		}
		if (
			notification.event == EVENT_NOTIFICATIONS.MEETING_INVITE_ACCEPTED ||
			notification.event == EVENT_NOTIFICATIONS.BULK_MEETING_INVITE_ACCEPTED
		) {
			setMyTaskModal(true);
			dispatch(Actions.getTaskDetailsById(notification?.metadata?.task?.id))
				.then(() => {
					dispatch(Actions.setOpenActionCenterModal(false));
					setSelectedTaskId(notification.metadata.task_id);
				})
				.catch(() => {
					setTaskId(null);
					setMyTaskModal(false);
				});
		}
	};

	const handleEmojiClick = (emojiObject) => {
		dispatch(ActionCenterActions.setEmoji(emojiObject.unified));
		setShowEmojiPicker(!showEmojiPicker);
	};
	const handleModalClose = () => {
		// if (notifications?.metadata?.task?.type === 'WORKFLOW') {
		// 	handleTaskTimerRef && handleTaskTimerRef.current();
		// }
		// if (isFeatureFlag) {
		handleTaskTimerRef && handleTaskTimerRef.current(TRACKING_STATUS.ENDED);
		// }
		setTaskModal(false);
		setMyTaskModal(false);
		// history.push('/workspace');
	};

	const handleMarkNotification = (notification, status) => {
		dispatch(ActionCenterActions.markNotification(notification, status))
			.then((response) => {
				const notifiactionIds = response;
				const updatedNotification = notifications?.map((notification) => {
					if (notifiactionIds?.includes(notification?.id)) {
						return {
							...notification,
							status: status,
						};
					} else return notification;
				});
				dispatch(ActionCenterActions.updateNotifications(updatedNotification));
			})
			.catch((error) => {
				dispatch(Actions.setSnackBarIsOpen(true, true, error.message));
			});
	};

	const handleDeleteNotification = (notification_id) => {
		dispatch(ActionCenterActions.deleteNotification(notification_id))
			.then((resposne) => {
				const updatedNotification = notifications?.filter(
					(notification) => notification?.id !== resposne,
				);
				dispatch(ActionCenterActions.updateNotifications(updatedNotification));
			})
			.catch((error) => {
				dispatch(Actions.setSnackBarIsOpen(true, true, error.message));
			});
	};

	const onSubmit = () => {
		if (selectedTime?.time === 'Choose date and time') {
			if (!moment(selectedTime?.value).isBefore(moment())) {
				dispatch(
					ActionCenterActions.setYourselfAway(
						{
							user_id: user?.userId,
							tenant_id: user?.tenantId,
							status: status ? status?.text : null,
							icon: emoji,
							is_pause_notifications: isPauseNotifications,
							is_away: status ? true : false,
							start_date: moment().format('YYYY-MM-DD hh:mm A'),
							end_date: moment(selectedTime?.value),
							time_text: selectedTime?.time,
						},
						setStatusModal,
					),
				);
			} else {
				alert('Please select future date and time');
			}
		} else {
			dispatch(
				ActionCenterActions.setYourselfAway(
					{
						user_id: user?.userId,
						tenant_id: user?.tenantId,
						status: status ? status?.text : null,
						icon: emoji,
						is_pause_notifications: isPauseNotifications,
						is_away: status ? true : false,
						start_date: moment().format('YYYY-MM-DD hh:mm A'),
						end_date: selectedTime?.value,
						time_text: selectedTime?.time,
					},
					setStatusModal,
				),
			);
		}
	};

	const onUpdate = () => {
		if (selectedTime?.time === 'Choose date and time') {
			if (!moment(selectedTime?.value).isBefore(moment())) {
				dispatch(
					ActionCenterActions.updateUserStatus(
						{
							id: userStatus?.id,
							user_id: user?.userId,
							tenant_id: user?.tenantId,
							status: status ? status?.text : null,
							icon: emoji,
							is_pause_notifications: isPauseNotifications,
							is_away: status ? true : false,
							start_date: moment().format('YYYY-MM-DD hh:mm A'),
							end_date: moment(selectedTime?.value),
							time_text: selectedTime?.time,
						},
						setStatusModal,
					),
				);
			} else {
				alert('Please select future date and time');
			}
		} else {
			dispatch(
				ActionCenterActions.updateUserStatus(
					{
						id: userStatus?.id,
						user_id: user?.userId,
						tenant_id: user?.tenantId,
						status: status ? status?.text : null,
						icon: emoji,
						is_pause_notifications: isPauseNotifications,
						is_away: status ? true : false,
						start_date: moment().format('YYYY-MM-DD hh:mm A'),
						end_date: selectedTime?.value,
						time_text: selectedTime?.time,
					},
					setStatusModal,
				),
			);
		}
	};

	const handleNotifications = () => {
		setOpenNotifications(!openNotifications);
		// dispatch(ActionCenterActions.markAllRead());
	};

	const handleClientClick = (id) => {
		history.push(`/client-profile/${id}`);
	};

	const handleTaskModal = () => {
		setMyTaskModal(true);
		setInviteDeclineModal(false);
		dispatch(Actions.getTaskDetailsById(taskId))
			.then(() => {
				dispatch(Actions.setOpenActionCenterModal(false));
				setSelectedTaskId(taskId);
			})
			.catch(() => {
				setTaskId(null);
				setMyTaskModal(false);
			});
	};

	useEffect(() => {
		dispatch(Actions.getUnassignedClientsTasks());
		// dispatch(ActionCenterActions.getOnboarding());
		dispatch(ActionCenterActions.getApprovalPending());
		dispatch(ActionCenterActions.getNotifications());
	}, [dispatch, openActionCenterModal]);

	useEffect(() => {
		const checkIfClickedOutside = (e) => {
			if (openActionCenterModal && ref.current && !ref.current.contains(e.target)) {
				dispatch(Actions.setOpenActionCenterModal(false));
				setOpenUnAssignedTasks(false);
				setOpenApprovalBox(false);
			}
		};

		document.addEventListener('mousedown', checkIfClickedOutside);

		return () => {
			document.removeEventListener('mousedown', checkIfClickedOutside);
		};
	}, [openActionCenterModal]);

	useEffect(() => {
		dispatch(userActions.getUserProfile(user?.userId));
		dispatch(ActionCenterActions.getUserStatus());
	}, [dispatch, statusModal]);

	useMemo(() => {
		handleSetStatus(
			userStatus !== undefined &&
				(statuses?.find((a) => a.text === userStatus?.status)
					? statuses?.find((a) => a.text === userStatus?.status)
					: { text: userStatus?.status }),
		);
		dispatch(ActionCenterActions.setEmoji(userStatus?.icon));
		handleSetSelectedTime(statusTimes?.find((b) => b.time === userStatus?.time_text));
		dispatch(ActionCenterActions.setIsPauseNotifications(userStatus?.is_pause_notifications));
		setSelectedDate(moment(userStatus?.end_date));
		setSelectedTimePicker(
			timeList?.find(
				(t) =>
					moment(
						`${moment(userStatus?.end_date).format('YYYY-MM-DD')} ${t.value}`,
					).format('HH:mm') === moment(userStatus?.end_date).format('HH:mm'),
			),
		);
	}, [userStatus]);

	useMemo(() => {
		const pickerModal = document?.getElementsByClassName('MuiPickersModal-dialogRoot');
		const checkIfClickedOutside = (e) => {
			if (
				statusModal &&
				statusRef.current &&
				!statusRef.current.contains(e.target) &&
				pickerModal?.length === 0
			) {
				setStatusModal(false);
			}
		};

		document.addEventListener('mousedown', checkIfClickedOutside);

		return () => {
			document.removeEventListener('mousedown', checkIfClickedOutside);
		};
	}, [statusModal]);

	useMemo(() => {
		if (userDetails?.id) {
			window.Intercom('boot', {
				app_id: window._env_?.REACT_APP_INTERCOM_APP_ID,
				email: userDetails?.email,
				name: userDetails?.full_name,
				user_id: userDetails?.id,
				custom_launcher_selector: '#helpCenter',
			});
		}
	}, [userDetails?.id]);

	return (
		<div>
			{openActionCenterModal && (
				<div className='action-center-modal-container'>
					<div
						className='action-center-modal'
						ref={ref}
						style={{ minHeight: 'auto', maxHeight: '650px', overflow: 'auto' }}
					>
						{/* <div
							className='d-flex align-items-center pb-2 pointer'
							style={{ padding: '20px 22px 12px 22px' }}
							onClick={() => {}}>
							<Eraser />
							<span className='body1Emphasized' style={{ marginLeft: '12px' }}>
								Erase Dummy Data
							</span>
						</div> */}
						<div
							className='d-flex justify-content-between align-items-center pb-2'
							style={{ padding: '12px 22px', cursor: 'pointer' }}
							onClick={handleNotifications}
						>
							<div className='d-flex align-items-center '>
								<Bell />
								<span className='BodyTwoBold' style={{ marginLeft: '12px' }}>
									Notifications
								</span>
							</div>

							<div className='d-flex align-items-center '>
								{notifications?.some(
									(notification) =>
										notification.status == NOTIFICATION_STATUS.UNREAD,
								) && (
									<div
										style={{
											width: '8px',
											height: '8px',
											background: '#FB7A03',
											borderRadius: '50%',
											marginTop: '3px',
										}}
									></div>
								)}
								{openNotifications ? <UpArrow /> : <DownArrow />}
							</div>
						</div>
						<div style={{ maxHeight: '300px', overflow: 'auto' }}>
							{openNotifications &&
								notifications?.length > 0 &&
								notifications
									?.map((notification) => ({
										...notification,
										createdAt: moment(notification?.createdAt).format(
											'YYYY-MM-DD',
										),
									}))
									?.sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt)))
									?.map((notification, index) => {
										return (
											<div
												key={index}
												className='d-flex text-left justify-content-between notification-class'
												style={{
													padding: '10px 20px 10px 20px',
													gap: '10px',
												}}
											>
												<div
													className='d-flex pointer align-items-center'
													style={{
														width: '196px',
														gap: '10px',
													}}
													onClick={() =>
														handleNotificationClick(notification)
													}
												>
													<div
														style={{
															padding: '4px',
															background:
																notification?.status ==
																NOTIFICATION_STATUS.UNREAD
																	? '#FB7A03'
																	: 'none',
															borderRadius: '50%',
															marginTop: '3px',
														}}
													></div>
													<div
														className='d-flex flex-column'
														style={{ gap: '5px' }}
													>
														<span className='BodyTwoLight text-left'>
															{notification.message}
														</span>
														<span className='BodyThreeLight placeholder-text'>
															{moment(notification?.createdAt).format(
																'MMMM DD, YYYY',
															)}
														</span>
													</div>
												</div>
												<div
													className='d-flex align-items-center justify-content-end'
													style={{ gap: '12px', width: '130px' }}
												>
													<span
														onClick={() =>
															handleMarkNotification(
																[notification?.id],
																notification?.status ==
																	NOTIFICATION_STATUS.READ
																	? NOTIFICATION_STATUS.UNREAD
																	: NOTIFICATION_STATUS.READ,
															)
														}
														className='BodyThreeLight primary-text mt-1 pointer'
													>
														{notification?.status ==
														NOTIFICATION_STATUS.READ
															? 'Mark as unread'
															: 'Mark as read'}
													</span>
													<span
														onClick={() =>
															handleDeleteNotification(
																notification?.id,
															)
														}
														className='pointer'
													>
														<DeleteIcon />
													</span>
												</div>
											</div>
										);
									})}
						</div>

						<Restricted
							AllowedUsers={[...standardPermissions]}
							userTeamRole={user?.user_roles?.map((item) => item.role_id)}
						>
							<div
								className='d-flex align-items-center pb-2 justify-content-between pointer'
								style={{ padding: '12px 22px' }}
								onClick={handleUnassignedTasks}
							>
								<div className='d-flex align-items-center'>
									<Warning />
									<span className='BodyTwoBold' style={{ marginLeft: '12px' }}>
										Unassigned Tasks
									</span>
								</div>
								<div className='d-flex align-items-center'>
									{unAssignedClientsTasks?.length > 0 && (
										<div
											style={{
												width: '8px',
												height: '8px',
												background: '#FB7A03',
												borderRadius: '50%',
												marginTop: '3px',
											}}
										></div>
									)}

									{openUnassignedTasks ? <UpArrow /> : <DownArrow />}
								</div>
							</div>
							{openUnassignedTasks &&
								unAssignedClientsTasks.length > 0 &&
								unAssignedClientsTasks.map((client, index) => {
									return (
										<div key={index}>
											<div
												style={{ padding: '12px 22px 12px 54px' }}
												className='d-flex justify-content-between align-items-center pointer'
											>
												<div onClick={() => handleClientClick(client?.id)}>
													<span className='BodyTwoLight text-left'>
														{client?.company_name}
													</span>
												</div>

												<div
													style={{ cursor: 'pointer' }}
													onClick={() => openClientSection(index)}
												>
													{selectedClient.index === index &&
													isClientSection ? (
														<UpArrow />
													) : (
														<DownArrow />
													)}
												</div>
											</div>

											<div
												style={{
													// minHeight: 'auto',
													// maxHeight: '250px',
													// overflow: 'auto',
													borderTop:
														selectedClient.index === index &&
														isClientSection &&
														'1px solid #E9E7E1',
													borderBottom:
														selectedClient.index === index &&
														isClientSection &&
														'1px solid #E9E7E1',
												}}
											>
												{selectedClient.index === index &&
													isClientSection &&
													client?.client_workflows &&
													client?.client_workflows?.map(
														(client_workflow, innerIndex) => {
															return (
																<div
																	key={innerIndex}
																	style={{
																		padding:
																			'12px 22px 12px 54px',
																	}}
																	className='d-flex justify-content-between align-items-center pointer'
																	onClick={() =>
																		handleWorkflowClick(
																			client,
																			client_workflow,
																		)
																	}
																>
																	<span className='BodyTwoLight text-left'>
																		{
																			client_workflow?.display_name
																		}
																	</span>
																	<span className='BodyTwoLight text-left'>
																		Unassigned Task:{' '}
																		{client_workflow
																			.client_workflow_tasks
																			.length || 0}
																	</span>
																</div>
															);
														},
													)}
											</div>
										</div>
									);
								})}

							<Restricted
								AllowedUsers={standardPermissions}
								userTeamRole={user?.user_roles?.map((item) => item.role_id)}
							>
								<div
									className='d-flex align-items-center pb-2 justify-content-between pointer'
									style={{ padding: '12px 22px' }}
									onClick={handleApprovalPendingBox}
								>
									<div className='d-flex align-items-center'>
										<Clock />
										<span
											className='BodyTwoBold'
											style={{ marginLeft: '12px' }}
										>
											Approval Pending
										</span>
									</div>
									<div className='d-flex align-items-center'>
										{approvalPendingTasks?.length > 0 && (
											<div
												style={{
													width: '8px',
													height: '8px',
													background: '#FB7A03',
													borderRadius: '50%',
													marginTop: '3px',
												}}
											></div>
										)}

										{openApprovalBox ? <UpArrow /> : <DownArrow />}
									</div>
								</div>
								{openApprovalBox &&
									approvalPendingTasks?.length > 0 &&
									approvalPendingTasks.map((task, index) => {
										return (
											<div key={index}>
												<div
													style={{ padding: '12px 22px 12px 54px' }}
													className='d-flex pointer'
													onClick={() => handleApprovalPendingTask(task)}
												>
													<span className='BodyTwoLight text-left'>{`${
														task.user_profile.full_name
													} is requesting approval on "${task.title}" - ${
														task.client?.dba?.trim() == ''
															? task.client?.company_name
															: task.client?.dba
													}`}</span>
												</div>
											</div>
										);
									})}
							</Restricted>
						</Restricted>
						{onboarding?.total < 100 && (
							<Restricted AllowedUsers={[Member.ownerId, Member.superAdminId]}>
								<div
									className='d-flex align-items-center pb-2 justify-content-between'
									style={{
										padding: '12px 22px',
										borderBottom: '1px solid #E9E7E1',
										cursor: 'pointer',
									}}
								>
									<div className='d-flex align-items-center'>
										<Shield />
										<span
											className='BodyTwoBold'
											style={{ marginLeft: '12px' }}
										>
											Onboarding Progress
										</span>
									</div>
									<div>
										<span className='BodyTwoLight text-left'>
											Complete:{' '}
											{onboarding?.teams +
												onboarding?.users +
												onboarding?.clients +
												onboarding?.client_workflows +
												onboarding?.client_software_stacks}
											%
										</span>
									</div>
								</div>
								<div
									className='d-flex align-items-center pb-2 justify-content-between pointer'
									onClick={CreateTeam}
									style={{ padding: ' 12px 54px' }}
								>
									<span className='BodyTwoLight text-left'>Create Team</span>
									{onboarding?.teams === 20 && (
										<div>
											<Complete />
										</div>
									)}
								</div>
								<div
									className='d-flex align-items-center pb-2 justify-content-between pointer'
									onClick={AddUser}
									style={{ padding: ' 12px 54px' }}
								>
									<span className='BodyTwoLight text-left'>
										Invite Team Members
									</span>
									{onboarding?.users === 20 && (
										<div>
											<Complete />
										</div>
									)}
								</div>
								<div
									className='d-flex align-items-center pb-2 justify-content-between pointer'
									onClick={AddClientForm}
									style={{ padding: ' 12px 54px' }}
								>
									<span className='BodyTwoLight text-left'>Add New Client</span>
									{onboarding?.clients === 20 && (
										<div>
											<Complete />
										</div>
									)}
								</div>
								<div
									className='d-flex align-items-center pb-2 justify-content-between pointer'
									style={{ padding: ' 12px 54px' }}
								>
									<span className='BodyTwoLight text-left'>Add Account</span>
									{onboarding?.client_software_stacks === 20 && (
										<div>
											<Complete />
										</div>
									)}
								</div>
								<div
									className='d-flex align-items-center pb-3 justify-content-between pointer'
									style={{
										padding: ' 12px 54px',
										borderBottom: '1px solid #E9E7E1',
									}}
								>
									<span className='BodyTwoLight text-left'>Add Workflow </span>
									{onboarding?.client_workflows === 20 && (
										<div>
											<Complete />
										</div>
									)}
								</div>
							</Restricted>
						)}

						{posthog?.isFeatureEnabled('leave_requests') && (
							<Restricted
								AllowedUsers={[
									Member.teamManagerId,
									Member.teamMemberId,
									Member.supervisorId,
								]}
							>
								<div
									className='d-flex align-items-center'
									style={{
										padding: '12px 22px',
										cursor: 'pointer',
									}}
									onClick={handleLeavesModal}
								>
									<Leaves />
									<span className='BodyTwoBold' style={{ marginLeft: '12px' }}>
										Leaves
									</span>
								</div>
							</Restricted>
						)}
						<div
							className='d-flex align-items-center '
							style={{
								padding: '12px 22px',
								cursor: 'pointer',
							}}
							onClick={handleStatusModal}
						>
							<Smiley stroke='#FB7A03' fill='#FFFFFF' />
							<span className='BodyTwoBold' style={{ marginLeft: '12px' }}>
								Update Your Status
							</span>
						</div>
						<div
							className='d-flex align-items-center pointer'
							style={{ padding: '12px 22px 22px 22px' }}
							id='helpCenter'
						>
							<Headset />
							<span className='BodyTwoBold' style={{ marginLeft: '12px' }}>
								Help Center
							</span>
						</div>
					</div>
				</div>
			)}
			{leavesModal && (
				<Modal
					onClose={() => setLeavesModal(false)}
					open={leavesModal}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<DummyModal
						title={'Leaves'}
						onClose={() => {
							setLeavesModal(false), dispatch(ActionCenterActions.resetLeavesModal());
						}}
					>
						<LeavesModal
							setLeaveFormSubmitted={setLeaveFormSubmitted}
							setLeavesModal={setLeavesModal}
							onCancel={() => {
								setLeavesModal(false),
									dispatch(ActionCenterActions.resetLeavesModal());
							}}
						/>
					</DummyModal>
				</Modal>
			)}
			{statusModal && (
				<div className='status-modal-container'>
					<div className='status-modal' ref={statusRef}>
						{initialLoading ? (
							<div
								className='d-flex align-items-center justify-content-center'
								style={{ marginTop: '70px' }}
							>
								<CircularProgress
									size={40}
									sx={{ color: '#fb7a03' }}
									disableShrink
								/>
							</div>
						) : (
							<>
								<div style={{ padding: '20px 24px 5px 24px' }}>
									<div className='d-flex justify-content-between align-items-center'>
										<div>
											<span className='HeadlineThreeBold'>Set Status</span>
										</div>
										<IconButton
											className='CloseButton transparent'
											onClick={handleStatusModal}
										>
											<CloseIcon sx={{ color: '#333' }} />
										</IconButton>
									</div>
									<div
										style={{
											width: '100%',
											height: '40px',
											border: '1px solid #E9E7E1',
											borderRadius: '4px',
											padding: '5px 20px',
										}}
										className='d-flex align-items-start justify-content-between'
									>
										<div className='d-flex'>
											<div
												onClick={() => setShowEmojiPicker(!showEmojiPicker)}
												style={{ cursor: 'pointer' }}
											>
												{emoji ? (
													<Emoji unified={emoji} size='20' />
												) : (
													<Smiley stroke='#B4B4B4' fill='#FFFFFF' />
												)}
											</div>
											<div style={{ paddingLeft: '12px' }}>
												<input
													placeholder="What's your status?"
													style={{
														background: 'transparent',
														border: 'none',
														outline: 'none',
													}}
													className={
														status
															? 'BodyTwoLight text-left'
															: 'BodyTwoLight'
													}
													value={status?.text ? status?.text : ''}
													onChange={handleChange}
												/>
											</div>
										</div>
										{showEmojiPicker && (
											<div className='emoji-picker'>
												<EmojiPicker
													onEmojiClick={handleEmojiClick}
													lazyLoadEmojis={true}
													searchDisabled={true}
												/>
											</div>
										)}
										{status?.text && (
											<div
												onClick={() => {
													handleSetStatus(null);
													dispatch(ActionCenterActions.setEmoji(null));
													handleSetSelectedTime({
														time: 'Please choose an option',
													});
												}}
												style={{ cursor: 'pointer' }}
											>
												<Cross />
											</div>
										)}
									</div>
								</div>
								<div
									style={{
										borderTop: '1px solid #E9E7E1',
										borderBottom: '1px solid #E9E7E1',
									}}
								>
									<div
										style={{ padding: '5px 40px' }}
										className='d-flex align-items-start flex-column'
									>
										<div style={{ paddingBottom: '10px' }}>
											<span className='BodyThreeBold'>
												<b>For Levvy</b>
											</span>
										</div>
										<div>
											{status?.text ? (
												<div>
													<div className='d-flex align-items-center'>
														<div style={{ position: 'relative' }}>
															<span className='BodyTwoLight text-left'>
																<b>Clear after:</b>
															</span>
															{timesDropdown && (
																<div
																	style={{
																		width: '224px',
																		height: 'auto',
																		minHeight: '150px',
																		borderRadius: '3px',
																		boxShadow:
																			'0px 4px 8px -4px rgba(0, 0, 0, 0.5)',
																		position: 'absolute',
																		background: '#ffffff',
																		top: '25px',
																		zIndex: '10',
																	}}
																>
																	{statusTimes &&
																		statusTimes.map(
																			(time, index) => {
																				return (
																					<div
																						key={index}
																						style={{
																							padding:
																								'5px',
																							cursor: 'pointer',
																						}}
																						className='d-flex'
																						onClick={() => {
																							time?.id ===
																							0
																								? handleSetSelectedTime(
																										{
																											time: 'Please choose an option',
																										},
																								  )
																								: handleSetSelectedTime(
																										time,
																								  );
																							setTimesDropdown(
																								!timesDropdown,
																							);
																						}}
																					>
																						<span className='BodyTwoLight'>
																							{
																								time.time
																							}
																						</span>
																					</div>
																				);
																			},
																		)}
																</div>
															)}
														</div>
														<div>
															<span className='BodyTwoLight text-left pl-1'>
																{selectedTime && selectedTime.time}
															</span>
														</div>
														<div
															onClick={handleTimesDropdown}
															style={{ cursor: 'pointer' }}
														>
															<DownArrow />
														</div>
													</div>

													{selectedTime?.id === 6 && (
														<div className='d-flex'>
															<div
																className='VerticalSpacer flex-column d-flex mb-2'
																style={{ width: '48%' }}
															>
																<label className='d-flex'>
																	Clear after
																</label>

																<KeyboardDatePicker
																	autoOk
																	disableToolbar
																	value={selectedDate}
																	name='due_date'
																	onChange={handleDateChange}
																	format={date_format}
																	disablePast
																	ref={statusRef}
																/>
															</div>
															<div
																className='VerticalSpacer flex-column d-flex mb-2 pl-2'
																style={{ width: '48%' }}
															>
																<label className='d-flex'>
																	Time
																</label>

																<div
																	className='d-flex align-items-center '
																	style={{ marginTop: '-5px' }}
																>
																	<DropDown
																		name='time'
																		style={{ height: '32px' }}
																		value={selectedTimePicker}
																		onChange={
																			handleTimePickerChange
																		}
																		options={timeList}
																	/>
																</div>

																{/* <KeyboardTimePicker
																	autoOk
																	ampm
																	value={selectedTimePicker}
																	name='time'
																	mask="__:__ _M"
																	onChange={handleTimePickerChange}
																	ref={statusRef}
																	format="hh:mm a"
																	
																/> */}
															</div>
														</div>
													)}
													<div className='d-flex align-items-center'>
														<input
															type='checkbox'
															className='multi-select-checkbox'
															onChange={onPauseNotifications}
															value={isPauseNotifications}
															checked={isPauseNotifications}
														/>
														<div className='pl-2 pb-1'>
															<span className='BodyTwoLight text-left'>
																<b>Pause Notifications</b>
															</span>
														</div>
													</div>
												</div>
											) : (
												statuses?.map((obj, index) => {
													return (
														<div
															key={index}
															className='d-flex pb-2 pointer'
															onClick={() => {
																handleSetStatus(obj);
																handleSetSelectedTime(obj);
																dispatch(
																	ActionCenterActions.setEmoji(
																		obj.unified,
																	),
																);
															}}
														>
															<div>
																<Emoji
																	unified={obj.unified}
																	size='20'
																/>
															</div>
															<div style={{ paddingLeft: '12px' }}>
																<span className='BodyTwoLight text-left'>
																	{obj.text} -{' '}
																	<span
																		style={{ color: '#B4B4B4' }}
																		className='BodyTwoLight text-left'
																	>
																		{obj.time}
																	</span>
																</span>
															</div>
														</div>
													);
												})
											)}
										</div>
									</div>
								</div>

								<div className='VerticalSpacer' style={{ padding: '16px' }}>
									<SubmitButtonsField child={true}>
										<button
											id='Element'
											className='secondary'
											type='button'
											onClick={onCancel}
										>
											Cancel
										</button>
										<button
											type='submit'
											className='d-flex align-items-center'
											id='Element'
											onClick={userStatus ? onUpdate : onSubmit}
											disabled={loading}
										>
											{loading ? (
												<CircularProgress
													size={24}
													sx={{ color: 'white' }}
													disableShrink
												/>
											) : (
												'Save'
											)}
										</button>
									</SubmitButtonsField>
								</div>
							</>
						)}
					</div>
				</div>
			)}
			{leaveFormSubmitted && (
				<Modal
					onClose={() => setLeaveFormSubmitted(false)}
					open={leaveFormSubmitted}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<DummyModal title={'Request Sent'} onClose={() => setLeaveFormSubmitted(false)}>
						<div className='pl-3 pb-3'>
							<span className='BodyTwoLight'>
								Request for leaves has been successfully sent to your team manager.
								Please wait for the approval.
							</span>
						</div>
						<div className='VerticalSpacer bg-white p-3 rounded-bottom'></div>
					</DummyModal>
				</Modal>
			)}
			{addUserModal ? (
				<Modal
					onClose={() => setAddUserModal(false)}
					open={addUserModal}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<DummyModal title={ModalName} onClose={() => setAddUserModal(false)}>
						<UserAdd
							onCancel={() => setAddUserModal(false)}
							value={FormUserFields}
							submit={onUserSubmit}
							type={modalType}
							selectedID={SelectedID}
						/>
					</DummyModal>
				</Modal>
			) : (
				<></>
			)}
			{addTeamModal ? (
				<Modal
					onClose={() => setAddTeamModal(false)}
					open={addTeamModal}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<DummyModal title={ModalName} onClose={() => setAddTeamModal(false)}>
						<AddTeam
							onCancel={() => setAddTeamModal(false)}
							value={FormFields}
							submit={OnSubmit}
							type={modalType}
							selectedID={SelectedID}
							response={'Success'}
						/>
					</DummyModal>
				</Modal>
			) : (
				<></>
			)}
			{addClientModal ? (
				<Modal
					onClose={() => setAddClientModal(false)}
					open={addClientModal}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<DummyModal title={ModalName} onClose={() => setAddClientModal(false)}>
						<AddClient
							onCancel={() => setAddClientModal(false)}
							value={ClientFormFields}
							submit={onClientSubmit}
							type={modalType}
							selectedID={SelectedID}
							OnChange={FormChange}
							OnDateChange={OnDateChange}
						/>
					</DummyModal>
				</Modal>
			) : (
				<></>
			)}
			{addTeamConfirmationModal && (
				<Modal
					onClose={() => setTeamConfirmationModal(false)}
					open={addTeamConfirmationModal}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<DummyModal title={'Success'} onClose={() => setTeamConfirmationModal(false)}>
						<ConfirmModal
							description='New team created successfully. To manage your teams please navigate to team management in the menu.'
							title='Add More Teams'
							Onsubmit={CreateTeam}
							OnCancel={() => setTeamConfirmationModal(false)}
						/>
					</DummyModal>
				</Modal>
			)}

			{addUserConfirmationModal && (
				<Modal
					onClose={() => setUserConfirmationModal(false)}
					open={addUserConfirmationModal}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<DummyModal title={'Success'} onClose={() => setUserConfirmationModal(false)}>
						<ConfirmModal
							description='New team member added successfully. You can manage your team members in each team in team management.'
							title='Add More Members'
							Onsubmit={AddUser}
							OnCancel={() => setUserConfirmationModal(false)}
						/>
					</DummyModal>
				</Modal>
			)}

			<Modal
				onClose={handleModalClose}
				open={myTaskModal}
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<MeetingMyTaskDetailsModal
					id={selectedTask?.id || selectedTaskId}
					handleModalClose={handleModalClose}
					handleTaskTimerRef={handleTaskTimerRef && handleTaskTimerRef}
				/>
			</Modal>

			<DeclineConfirmModal
				open={inviteDeclineModal}
				closeHandler={toggleDeclineModal}
				reason={declineReason}
				handleTaskModal={handleTaskModal}
			/>
		</div>
	);
}
