import { useState } from 'react';
import SubmitButtonsField from '../../Buttons/SubmitButtonsField';
import { CircularProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import * as clientActions from '../../../redux/actions/client-actions';
import * as workflowBuilderActions from '../../../redux/actions/workflow-builder-actions';
import * as workspaceActions from '../../../redux/actions/dashboard/workspaces-actions';

export default function DeleteFolder({ onCancel, item, isFileTrue, isFolderDefault }) {
	const dispatch = useDispatch();
	const clientWorkflow = useSelector((state) => state.WorkflowBuilder.selectedClientWorkflow);
	const tempObj = clientWorkflow?.attachment;
	const updatedAttachment = {
		...tempObj,
		sections: {
			...tempObj?.sections,
			folder: null,
		},
	};
	const [loading, setLoading] = useState(false);

	const handleDeleteDocument = () => {
		setLoading(true);
		dispatch(clientActions.deleteDocument(item.id))
			.then((res) => {
				dispatch(workspaceActions.updateCommentsAttachments(res));
				dispatch(
					workspaceActions.setSnackBarIsOpen(true, true, 'Document deleted succesfully'),
				);

				setLoading(false);
				if (isFileTrue) {
					return;
				} else {
					dispatch(
						clientActions.handleWorkflowAttachments(
							clientWorkflow?.attachment?.id,
							updatedAttachment,
						),
					)
						.then((res) => {
							dispatch(workflowBuilderActions.handleWorkflowAttachmentsReducer(res));
						})
						.catch((e) => {
							console.log(e, 'error');
						});
				}
				onCancel();
			})
			.catch((error) => {
				setLoading(false);
				dispatch(
					workspaceActions.setSnackBarIsOpen(
						false,
						true,
						'Error while deleting document',
					),
				);

				console.log(error.message);
			});
	};

	return (
		<div>
			<div>
				<form autoComplete='off'>
					<div
						className='py-2 px-3 '
						style={{
							maxHeight: '75vh',
							height: 'fit-content',
							position: 'relative',
							overflowY: 'auto',
						}}
					>
						<div className='improved-input-designs pl-1'>
							<p className='BodyTwoLight'>
								{isFolderDefault
									? 'This folder is associated with an active workflow and cannot be deleted at the moment.'
									: 'Deleted folders and files will be permanently removed from Levvy.Are you sure you want to continue?'}
							</p>
						</div>
					</div>

					<div className='VerticalSpacer bg-white p-3 rounded-bottom'>
						<SubmitButtonsField child={true}>
							{!isFolderDefault ? (
								<>
									<button
										id='Element'
										type='button'
										className='secondary'
										style={{ borderRadius: '50px', width: '125px' }}
										onClick={onCancel}
									>
										Cancel
									</button>

									<button
										type='submit'
										className='d-flex align-items-center'
										style={{ borderRadius: '50px', width: '125px' }}
										id='Element'
										disabled={loading}
										data-cy='add-client-btn'
										onClick={handleDeleteDocument}
									>
										{loading ? (
											<CircularProgress
												size={24}
												sx={{ color: 'white' }}
												disableShrink
											/>
										) : (
											'Confirm'
										)}
									</button>
								</>
							) : (
								<button
									id='Element'
									type='button'
									className='secondary'
									style={{ borderRadius: '50px', width: '125px' }}
									onClick={onCancel}
								>
									Close
								</button>
							)}
						</SubmitButtonsField>
					</div>
				</form>
			</div>
		</div>
	);
}
