import { useEffect, useState } from 'react';
// Material Ui Component
import { Input, Modal } from '@mui/material';
// Import Components
import ButtonSquare from '../components/Buttons/ButtonSquare';
// import DeleteSelected from '../components/Buttons/DeleteSelected';
// import SearchBar from '../components/Inputs/SearchBar';
import Sidebar from '../components/sidebar/SideBar';
import Card from '../components/Others/Card';
import TableHeader from '../components/Others/TableHeader';
import TableHeaderItem from '../components/Others/TableHeaderItem';
import TableRowItem from '../components/Others/TableRowItem';
import ActionButton from '../components/Buttons/ActionButton';
import useModal from '../components/Modal/useModal';
import TableRowButton from '../components/Others/TableRowButton';
import DummyModal from '../components/Modal/DummyModal';
// import ProjectPicker from '../components/sidebar/ProjectPicker';
import AddTeam from '../components/TeamManagement/AddTeam';
// import WorkflowPicker from '../components/sidebar/WorkflowPicker.';
// import TeamProfile from '../components/TeamManagement/TeamProfile';
// import ClientsPicker from '../components/sidebar/ClientsPicker';
import LoadingOverLay from '../components/Others/LoadingOverLay';
import TableRow from '../components/Others/TableRow';
import {
	TableItemWidth2,
	TableItemWidth4,
	TableItemWidth6,
} from '../components/TableSpacing/TableSpacingStyles';
import * as teamActions from '../redux/actions/team-management.actions';
import * as userActions from '../redux/actions/user-management.actions';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { executivePermissions, Member, standardPermissions } from '../utilities/utilities.js';
import Restricted from '../components/Permissions/Restricted';
import SectionHeader from '../components/sidebar/SectionHeader';
import Filter from '../components/sidebar/Filter';
import { routes } from '../utilities/routes';
import ReadMoreText from '../components/Others/ReadMoreText';
import AlertBar from '../components/sub_components/AlertBar.jsx';

function TeamManagement() {
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const [userTeamFilter, setUserTeamFilter] = useState([]);
	// Search State
	const [search, setSearch] = useState('');

	const InputClass = {
		width: '100%',
		fontFamily: 'CentraLight',
		fontWeight: 'light',
		fontSize: '14px !important',
		lineHeight: '18px',
		color: 'white',
		backgroundColor: 'rgba(0, 0, 0, 0.25)',
		padding: '8px 6px 2px 8px',
		borderRadius: '3px',
		marginTop: '8px',
	};

	//   Name State
	const resetFirst = () => {
		setSearch('');
	};

	const changeHandler = (event) => {
		setSearch(event.target.value);
	};

	// User State
	const InitialForm = {
		name: '',
		description: '',
	};
	const [FormFields, setFormFields] = useState(InitialForm);

	const AddUser = () => {
		setFormFields(InitialForm);
		ModalType('Add New Team');
		setmodalType('add');
		toggleModal();
	};

	// const EditUser = (team, id) => {
	// 	setFormFields(team);
	// 	ModalType('Edit Team');
	// 	setmodalType('edit');
	// 	setSelectedID(id);
	// 	toggleModal();
	// };

	//   Modal
	const { modalIsOpen, toggleModal, ModalName, ModalType } = useModal();
	const [modalType, setmodalType] = useState('');

	// For Profile Page
	const [viewProfile, setviewProfile] = useState(false);
	const toggleProfile = () => {
		setviewProfile(!viewProfile);
	};

	// Selected Id
	const [SelectedID, setSelectedID] = useState();

	// CheckBox
	// const [isCheckAll, setIsCheckAll] = useState(false);
	const [isCheck, setIsCheck] = useState([]);

	// const handleSelectAll = () => {
	// 	setIsCheckAll(!isCheckAll);
	// 	setIsCheck(teams.map((li) => li.id));
	// 	if (isCheckAll) {
	// 		setIsCheck([]);
	// 	}
	// };

	// const handleClick = (e) => {
	// 	const { id, checked } = e.target;
	// 	setIsCheck([...isCheck, parseInt(id)]);
	// 	if (!checked) {
	// 		setIsCheck(isCheck.filter((item) => item !== parseInt(id)));
	// 	}
	// };

	const OnSubmit = (type, id, inputs) => {
		if (type == 'add') {
			dispatch(teamActions.addTeam(inputs, toggleModal));
		} else {
			dispatch(teamActions.updateTeam(inputs, toggleModal));
		}
	};

	const onDelete = (id) => {
		dispatch(teamActions.deleteTeam(id));
		const array = [];
		setIsCheck(array);
	};

	// const multipleDelete = () => {
	// 	dispatch(teamActions.deleteTeams(isCheck));
	// 	const array = [];
	// 	setIsCheck(array);
	// };

	const setTeamId = (id) => {
		dispatch(teamActions.setTeamId(id));
		// toggleProfile();
		const tempArr = [{ url: location.pathname, name: 'Teams' }];
		localStorage.setItem('tempArr', JSON.stringify(tempArr));
		history.push(`team-profile/${id}`);
	};

	const teams = useSelector((state) => state.teamReducer.teams);
	let users = useSelector((state) => state.userReducer.users);
	const user = useSelector((state) => state.authReducer.user);
	const loading = useSelector((state) => state.teamReducer.loading);

	if (
		user?.user_roles?.map((item) => item.role_id)?.includes(Member.teamManagerId) ||
		user?.user_roles?.map((item) => item.role_id)?.includes(Member.teamMemberId)
	) {
		users = users?.filter((u) => !executivePermissions.includes(u.role_id));
	}

	const userFilterOptions = users
		?.filter((item) => item.is_active)
		?.map((item) => {
			return {
				value: item?.user_id,
				label: item?.user_name,
				is_checked: userTeamFilter?.includes(item.user_id),
			};
		});

	// if (filteredTeamManagement.length > 0) {
	// 	filteredTeamManagement = filteredTeamManagement
	// 		?.filter((client) => client.deleted !== true)
	// 		?.map((item) => {
	// 			return {
	// 				...item,
	// 				displayName: item?.dba == '' ? item?.company_name : item?.dba,
	// 			};
	// 		})
	// 		?.sort((a, b) => a.displayName.localeCompare(b.displayName))
	// 		?.map((filtered) => {
	// 			return {
	// 				value: filtered?.id,
	// 				label: filtered?.displayName,
	// 				is_checked: filters?.teamManagementClients?.includes(filtered.id),
	// 			};
	// 		});
	// }

	const handleUserTeamFilterChange = (e) => {
		let { value, checked } = e.target;
		value = parseInt(value);

		if (checked && !userTeamFilter.includes(value)) {
			setUserTeamFilter([...userTeamFilter, value]);
		}

		if (!checked) {
			setUserTeamFilter([...userTeamFilter?.filter((item) => item != value)]);
		}
	};

	const userProfileNavigation = (userId) => {
		const from = location.state || [];
		from.push({
			link: location.pathname,
			label: 'Teams',
		});
		if (from.length > 2) from.shift();
		user?.userId == userId
			? history.push(`${routes['account-settings']}`, from)
			: history.push(`${routes['user-profile']}${userId}`, from);
	};

	// const handleCheckboxes = (e) => {
	// 	dispatch(teamActions.setFilteredClients(parseInt(e.target.value)));
	// };

	// useEffect(() => {
	// 	dispatch(teamActions.getFilteredTeamManagement());
	// }, [dispatch]);

	useEffect(() => {
		dispatch(teamActions.getTeams(userTeamFilter));
		dispatch(userActions.getUsers());
		// return () => {
		// 	dispatch(teamActions.resetClientsFilter());
		// };
	}, [dispatch, userTeamFilter.length]);

	return (
		<div className='AppBody'>
			<Sidebar title='Team Management'>
				<div className='SidebarContentWrapper'>
					<div className='User-filters'>
						{/* <SearchBar
            type="filterSearchBar"
              style={{
                color: 'white',
                backgroundColor: 'rgba(0, 0, 0, 0.25)',
              }}
            />{' '} */}
						{/* {isCheck.length > 0 && (
              <DeleteSelected>
                <h5 onClick={multipleDelete} className='BodyThreeB'>
                  Delete Selected Teams
                </h5>
              </DeleteSelected>
            )} */}

						<div>
							<SectionHeader title='Search Team' label='Clear' onClear={resetFirst} />
							<Input
								sx={InputClass}
								disableUnderline={true}
								size='small'
								placeholder='Enter Team Name'
								name='first'
								value={search}
								onChange={changeHandler}
							/>
						</div>

						{/* <div>
							<div className='.SidebarListWrapper'>
								<ClientsPicker
									options={filteredTeamManagement}
									onChange={handleCheckboxes}
									onClear={() => dispatch(teamActions.resetClientsFilter())}
								/>
							</div>
						</div> */}

						{/* <div className='.SidebarListWrapper'>
							<Filter
								expandable={true}
								title='Clients'
								options={filteredTeamManagement}
								onClear={() => dispatch(teamActions.resetClientsFilter())}
								onChange={handleCheckboxes}
								onClick={(option) => clientProfileNavigation(option)}
							/>
						</div> */}

						<Restricted
							AllowedUsers={[...standardPermissions, Member.teamMemberId]}
							userTeamRole={user?.user_roles?.map((item) => item.role_id)}
						>
							<div className='.SidebarListWrapper'>
								<Filter
									expandable={true}
									title='People'
									onClick={(option) => userProfileNavigation(option)}
									options={userFilterOptions}
									onClear={() => setUserTeamFilter([])}
									onChange={handleUserTeamFilterChange}
								/>
							</div>
						</Restricted>
					</div>

					<Restricted AllowedUsers={executivePermissions}>
						<button
							data-cy='add-team-menu-btn'
							className='full-width rounded-button-1'
							onClick={AddUser}
						>
							Add New Team
						</button>
					</Restricted>
				</div>
			</Sidebar>
			<div
				style={{
					width: 'calc(100vw - 260px)',
					height: '100vh',
				}}
			>
				<AlertBar />
				<div className='Listing-Main' style={{ backgroundColor: '#EEF2F3' }}>
					<div className='Listing-Content'>
						<Card title='Teams'>
							<div className='card-body'>
								<TableHeader
									ClassName='TableHeader flex fixedHeader'
									border={false}
								>
									<TableHeaderItem
										ClassName='UManagement'
										label='Team Name'
										width={TableItemWidth4}
										margin='ml-4'
									>
										{/* <Restricted
                  AllowedUsers={executivePermissions}
                >
                  {/* <input
                    className='multi-select-checkbox'
                    type='checkbox'
                    name='selectAll'
                    id='selectAll'
                    onChange={handleSelectAll}
                    checked={isCheckAll}
                  />
                </Restricted> */}
									</TableHeaderItem>

									<TableHeaderItem
										ClassName='UManagement mr-20'
										label='Description'
										width={TableItemWidth6}
									/>

									<TableHeaderItem
										ClassName='UManagement text-center'
										label='Action'
										width={TableItemWidth2}
									/>
								</TableHeader>

								<div className='ListingLoading listing-scroll'>
									{loading ? (
										<LoadingOverLay active={loading} />
									) : teams.length < 1 ? (
										<div className='TableRow h-100 w-100 d-flex align-items-center justify-content-center'>
											<h4 className='BodyTwoLight'>No Teams</h4>
										</div>
									) : (
										teams
											?.filter(
												(team) =>
													team?.name
														?.toLowerCase()
														.includes(search?.toLowerCase()) ||
													team?.description
														?.toLowerCase()
														.includes(search?.toLowerCase()),
											)
											?.map((team, i) => {
												return (
													<TableRow key={i}>
														<TableRowButton
															ClassName='UManagement'
															label={team.name}
															width={TableItemWidth4}
															clickHandler={() => setTeamId(team.id)}
															margin='ml-4'
															id={`team_list_index_${team.id}`}
															dataCy={`team_list_index_${team.id}`}
														></TableRowButton>

														<TableRowItem
															onClick={() => toggleProfile()}
															label={
																<ReadMoreText
																	description={
																		team.description || ''
																	}
																	btnClasses='primary-text BodyThreeLight border-none bg-transparent'
																/>
															}
															width={TableItemWidth6}
															ClassName='UManagement text-left mr-20'
														/>

														<TableRowButton
															ClassName='UManagement'
															text='text-left'
															width={TableItemWidth2}
															margin='m-0'
														>
															<Restricted
																AllowedUsers={standardPermissions}
																userTeamRole={[
																	user?.user_roles?.find(
																		(item) => {
																			return item?.role_scopes
																				?.map(
																					(role) =>
																						role.team_id,
																				)
																				?.includes(team.id);
																		},
																	)?.role_id,
																]}
																RestrictedComponent={
																	<ActionButton
																		disable={true}
																		ClassName='opacity-50'
																		title={'Not Authorized'}
																	/>
																}
															>
																<ActionButton
																	EditUser={() => {
																		dispatch(
																			teamActions.setTeamId(
																				team.id,
																			),
																		);
																		const tempArr = [
																			{
																				url: location.pathname,
																				name: 'Teams',
																			},
																		];
																		localStorage.setItem(
																			'tempArr',
																			JSON.stringify(tempArr),
																		);
																		history.push(
																			`team-profile/${team.id}/?edit=true`,
																		);
																	}}
																	HandleRemove={onDelete}
																	Uid={team.id}
																	key={i}
																/>
															</Restricted>
														</TableRowButton>
													</TableRow>
												);
											})
									)}
								</div>
							</div>
						</Card>
					</div>
				</div>
			</div>

			{modalIsOpen ? (
				<Modal
					onClose={toggleModal}
					open={modalIsOpen}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<DummyModal title={ModalName} onClose={toggleModal}>
						<AddTeam
							onCancel={toggleModal}
							value={FormFields}
							submit={OnSubmit}
							type={modalType}
							selectedID={SelectedID}
							response={'Success'}
						/>
					</DummyModal>
				</Modal>
			) : (
				<></>
			)}
		</div>
	);
}

export default TeamManagement;
