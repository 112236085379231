import React from 'react';

function SelectMyTaskColor({ form, handleColorChange, requireClass }) {
	const colors = [
		'#FB1B1B',
		'#FFE55B',
		'#E4FF3E',
		'#60FFFF',
		'#9908DD',
		'#42FF00',
		'#9B0070',
		'#0B4FFF',
		'#FF7B7B',
	];

	return (
		<>
			{' '}
			<label className={requireClass}>Select Color</label>
			<div
				className='w-100 d-flex align-items-center justify-content-around'
				style={{
					backgroundColor: 'white',
					height: '32px',
					borderRadius: '4px',
				}}
			>
				{colors?.map((color) => {
					return (
						<div
							key={color}
							className='d-flex align-items-center justify-content-center'
							style={{
								border: form?.metadata
									? form?.metadata?.color == color
										? `1px solid #FB7A03`
										: ''
									: form?.color == color
									? `1px solid #FB7A03`
									: '',
								backgroundColor: 'white',
								width: '22px',
								height: '22px',
								borderRadius: '50%',
							}}
						>
							<div
								className='pointer'
								onClick={() => handleColorChange(color)}
								name='color'
								key={color}
								style={{
									width: '18px',
									height: '18px',
									backgroundColor: color,
									borderRadius: '50%',
								}}
							>
								{/*  */}
							</div>
						</div>
					);
				})}
			</div>
		</>
	);
}

export default SelectMyTaskColor;
