import { useState } from 'react';
import DummyModal from './DummyModal';
import { CircularProgress } from '@mui/material';
import { Modal } from '@mui/material';
import DocumentStorage from '../ClientManagement/DocumentStorage';
import SubmitButtonsField from '../Buttons/SubmitButtonsField';
import { useDispatch, useSelector } from 'react-redux';
import * as clientActions from '../../redux/actions/client-actions';
import { setSnackBarIsOpen } from '../../redux/actions/dashboard/workspaces-actions';
import { generateUniqueFiles, Member } from '../../utilities/utilities';

function UploadFileModal({
	modalIsOpen,
	toggleModal,
	setFiles,
	openProgressModal,
	closeProgressModal,
	handleAlertModal,
	client,
	clientWorkflow,
}) {
	const dispatch = useDispatch();
	const headers = [{ key: 'lastModified', label: 'Last Modified', width: '150px' }];

	const [loading, setLoading] = useState(false);
	const [selectedItem, setSelectedItem] = useState(null);

	let folderData = useSelector((state) => state.clientReducer.folderDataForModal);
	const user = useSelector((state) => state.authReducer.user);

	const role_id = user?.user_roles?.find((item) =>
		item.role_scopes?.some(
			(role) => role.team_id === client?.team_client_permissions[0]?.team_id,
		),
	)?.role_id;

	if (role_id === Member.teamMemberId) {
		folderData = folderData?.filter(
			(folder) =>
				folder?.access_control?.role_level === Member.teamMemberId ||
				folder?.access_control?.role_level === Member.externalCollaborator,
		);
	}
	const uniqueFolderData = folderData?.filter((folder, index, self) => {
		return index === self.findIndex((f) => f.id === folder.id);
	});

	const onCancel = () => {
		toggleModal();
	};

	const handleUploadChange = async (e) => {
		const selectedFiles = Array.from(e.target.files);
		const updatedFiles = generateUniqueFiles(selectedFiles);
		setLoading(true);
		setFiles(updatedFiles);
		openProgressModal();

		const uploadPromises = updatedFiles.map(async (updatedFile) => {
			const formData = new FormData();
			formData.append('file', updatedFile);
			formData.append('parent_folder_id', selectedItem);

			try {
				const data = await dispatch(clientActions.createFile(formData, updatedFile));
				dispatch(clientActions.createFileReducer(data));
			} catch (error) {
				if (error.message === 'Document name already exists') {
					handleAlertModal();
				} else {
					dispatch(setSnackBarIsOpen(true, true, 'File upload failed'));
				}
				throw error;
			}
		});

		try {
			await Promise.all(uploadPromises);
		} catch (error) {
			//
		} finally {
			setLoading(false);
			onCancel();
            setTimeout(() => {
                closeProgressModal();
            }, 3000);
		}
	};

	const handleFolderClickForMOdal = (e, folderId) => {
		e.preventDefault();
		e.stopPropagation();
		dispatch(clientActions.getAllFoldersAndFilesForMOdal(folderId));
	};

	return (
		<>
			<div>
				{modalIsOpen && (
					<Modal
						onClose={toggleModal}
						open={modalIsOpen}
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<>
							<DummyModal
								title='Upload file'
								onClose={toggleModal}
								style={{
									width: '800px',
									padding: '24px',
								}}
							>
								<div className='Profile-Content'>
									<div
										style={{
											padding: '0px 24px',
										}}
									>
										<span className='BodyTwoLight'>
											Select folder or subfolder where you want to upload the
											file to
										</span>
									</div>
									<div
										style={{
											padding: '24px',
											maxHeight: '70vh',
											overflowY: 'scroll',
										}}
									>
										<DocumentStorage
											headers={headers}
											tempFolderData={uniqueFolderData}
											handleFolderClick={handleFolderClickForMOdal}
											setSelectedItem={setSelectedItem}
											selectedItem={selectedItem}
											hideFile={true}
											clientWorkflow={clientWorkflow}
											disableDnd={true}
										/>
									</div>
									<div className='VerticalSpacer bg-white p-3 rounded-bottom'>
										<SubmitButtonsField child={true}>
											<button
												id='Element'
												type='button'
												className='secondary'
												style={{ borderRadius: '50px', width: '125px' }}
												onClick={toggleModal}
											>
												Cancel
											</button>
											<div
												className='upload-btn-wrapper'
												style={{
													position: 'relative',
													overflow: 'hidden',
													display: 'inline-block',
													cursor: 'pointer',
												}}
											>
												<button
													type='submit'
													className='d-flex align-items-center'
													style={{
														borderRadius: '50px',
														width: '125px',
														padding: '8px 20px',
													}}
													id='Element'
													disabled={loading || selectedItem == null}
													data-cy='add-client-btn'
												>
													{loading ? (
														<CircularProgress
															size={24}
															sx={{ color: 'white' }}
															disableShrink
														/>
													) : (
														<>
															<input
																type='file'
																name='myfile'
																disabled={selectedItem == null}
																style={{
																	position: 'absolute',
																	left: '0',
																	top: '0',
																	opacity: '0',
																}}
																multiple={true}
																onChange={handleUploadChange}
															/>
															<span>Upload</span>
														</>
													)}
												</button>
											</div>
										</SubmitButtonsField>
									</div>
								</div>
							</DummyModal>
						</>
					</Modal>
				)}
			</div>
		</>
	);
}

export default UploadFileModal;
