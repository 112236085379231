/* eslint-disable no-mixed-spaces-and-tabs */
import React, { memo, useRef, useState } from 'react';
import moment from 'moment';
import { ReactComponent as InfoIcon } from '../../assets/icons/Info.svg';
import { ReactComponent as LinkIcon } from '../../assets/icons/LinkSimpleHorizontal.svg';

import { MEETING_ACCEPT_STATUS, getAvatarLabel } from '../../utilities/utilities';
import { Avatar, Tooltip } from '@mui/material';
import { useSelector } from 'react-redux';
import GuestListPopper from './GuestListPopper';

function MyTaskCard({
	onTaskClick,
	color,
	title,
	startTime,
	endTime,
	clientDba,
	companyName,
	inviteStatus,
	creatorId,
	userId,
	guests,
	taskId,
	metadata
}) {
	// let dependentTask = taskDetails?.dependent_task;
	// if (dependentTask === undefined) {
	// 	dependentTask = [];
	// }
	const [anchorEl, setAnchorEl] = useState(null);
	const anchorRef = useRef(null);
	let hoverTimeout = undefined;

	const handleMouseEnter = (task_id) => (event) => {
		anchorRef.current = event.currentTarget;
		hoverTimeout && clearTimeout(hoverTimeout);
		hoverTimeout = setTimeout(() => {
			setAnchorEl(task_id);
		}, 500);
	};

	const handleClosePopper = () => {
		setAnchorEl(null);
	};

	const handleMouseLeave = () => {
		hoverTimeout && clearTimeout(hoverTimeout);
		setAnchorEl(null);
	};
	const user = useSelector((state) => state.authReducer.user);
	const invite = inviteStatus
		? inviteStatus
		: guests?.find((guest) => guest?.user_id == user?.userId)?.status;
	const updatedGuests = guests?.length > 5 ? guests?.slice(0, 4) : guests;

	return (
		<div onMouseLeave={handleMouseLeave}>
			<div
				onClick={onTaskClick(taskId)}
				className='my-task-card'
			>
				<div
					style={{
						width: '4px',
						backgroundColor: color ? color : '#FB7A03',
						borderRadius: '32px',
					}}
				>
					{/*  */}
				</div>
				<div className='d-flex flex-column' style={{ gap: '6px', width: '90%' }}>
					<div className='d-flex justify-content-between align-items-center'>
						<div
							style={{
								whiteSpace: 'nowrap',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
							}}
							className='BodyTwoLight te>xt-left primary-text'
						>
							{moment(startTime, ['HH.mm']).format('hh:mm a') +
								' ' +
								moment(endTime, ['HH.mm']).format('hh:mm a')}
						</div>
						{metadata?.google_event_id && <LinkIcon />}
					</div>
					<div className='d-flex justify-content-between w-100' style={{ gap: '20px' }}>
						<Tooltip title={title}>
							<div
								style={{
									whiteSpace: 'nowrap',
									overflow: 'hidden',
									textOverflow: 'ellipsis',
								}}
								className='BodyTwoLight text-left w-75'
							>
								{title}
							</div>
						</Tooltip>
						<div
							id={taskId}
							className='d-flex flex-row-reverse'
							onMouseEnter={handleMouseEnter(taskId)}
						>
							{guests?.length > 5 && (
								<div>
									<Avatar
										sx={{
											bgcolor: `#FEF2E7`,
											color: `#333`,
											// marginRight: '10px',
											height: '23px',
											width: '23px',
											fontSize: '0.8rem',
											// border: '1px solid black',
											marginLeft: '-2px',
										}}
									>
										{`+${guests?.length - updatedGuests?.length}`}
									</Avatar>
								</div>
							)}
							{updatedGuests?.map((guest) => {
								return (
									<div key={guest?.user_id}>
										<Avatar
											sx={{
												bgcolor: `#FEF2E7`,
												color: `#333`,
												// marginRight: '10px',
												height: '20px',
												width: '20px',
												fontSize: '0.8rem',
												marginLeft: '-6px',
											}}
											alt={guest?.user_profile?.full_name}
											src={guest?.user_profile?.image_url}
										>
											{getAvatarLabel(guest?.user_profile?.full_name)}
										</Avatar>
									</div>
								);
							})}
						</div>
					</div>
					<div className='d-flex justify-content-between'>
						<div
							style={{
								whiteSpace: 'nowrap',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
							}}
							className='BodyFourLight text-left d-flex justify-content-between'
						>
							{clientDba?.trim() == '' ? companyName : clientDba}
						</div>
						{userId !== creatorId && invite == MEETING_ACCEPT_STATUS.PENDING && (
							<Tooltip title='The meeting is not accepted or declined yet'>
								<InfoIcon />
							</Tooltip>
						)}
					</div>
				</div>
			</div>
			<GuestListPopper
				open={Boolean(anchorEl == taskId)}
				handleClosePopper={handleClosePopper}
				anchorRef={anchorRef}
				guests={guests}
			/>
		</div>
	);
}
export default memo(MyTaskCard);
