import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useHistory } from 'react-router';
import ResetLinkSent from './ResetLinkSent';
import * as authActions from '../../redux/actions/auth-actions';
import { validateEmailError } from '../../utilities/utilities';

const ForgetPass = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const handleSubmit = async (evt) => {
		evt.preventDefault();

		dispatch(
			authActions.forgotPasswordFormErrors({
				name: 'emailErrorText',
				value: validateEmailError(form.email),
			}),
		);

		if (!validateEmailError(form.email)) {
			dispatch(authActions.requestResetPassword({ email: form.email }));
		}
	};

	const form = useSelector((state) => state.authReducer.forgotPasswordForm);
	const isRequestResetPassword = useSelector(
		(state) => state.authReducer.isRequestResetPassword,
	);
	const errors = useSelector(
		(state) => state.authReducer.forgotPasswordFormErrors,
	);

	const handleChange = (event) => {
		event.preventDefault();

		const { name, value } = event.target;

		dispatch(authActions.forgotPasswordFormChange({ name, value }));
	};

	if (isRequestResetPassword) {
		return <ResetLinkSent />;
	}

	return (
		<>
			<div className='LoginBox formText'>
				<div className=''>
					<h1 className='titleBold'>Forgot Password</h1>
				</div>

				<form autoComplete='off' onSubmit={handleSubmit}>
					<div className=''>
						<label htmlFor='email' className=''>
							<div className='d-flex align-items-end'>
								<div className=' d-flex align-items-end'>Email &nbsp; </div>
								<div style={{ color: '#F05544' }} className=''>
									*
								</div>
							</div>
						</label>
						<div>
							<input
								type='text'
								id='email'
								name='email'
								value={form.email}
								onChange={handleChange}
							/>
						</div>
					</div>
					<span className='m8 error'>{errors.emailErrorText}</span>

					<div className=''>
						<div className='dGrid m16'>
							<button
								type='submit'
								value='Submit'
								className=''
								onClick={() => {}}
							>
								Continue
							</button>
						</div>
					</div>
					<span className='m8 error'>{errors.errorText}</span>
				</form>

				<div className='m24'>
					<div>
						<div style={{ borderTop: '1px solid #28383C ' }}></div>
					</div>
				</div>
				<div className='m8'>
					<div className='dfCenter'>
						<button
							onClick={() => {
								history.push('/login');
							}}
							style={{
								color: '#FB7A03',
								background: '#fffefb',
								fontWeight: 400,
							}}
						>
							Remember Your Password? Login
						</button>
					</div>
				</div>
			</div>
		</>
	);
};
const ForgetPassword = () => {
	const [display, setDisplay] = useState(<></>);
	useEffect(() => {
		setDisplay(<ForgetPass setName={setDisplay} />);
	}, []);

	return <>{display}</>;
};
export default ForgetPassword;
