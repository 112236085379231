
function Disclaimers() {
	return (
		<p className='BodyTwoLight'>
			<strong>
				Your use of our Site is at your sole risk. Except as otherwise provided
				in a writing by us, our Site and any content therein are provided “as
				is” and “as available” without warranties of any kind, either express or
				implied, including, but not limited to, implied warranties of
				merchantability, fitness for a particular purpose, title, and
				non-infringement. In addition, Levvy does not represent or warrant that
				our Site or its contents are accurate, complete, reliable, current, or
				error-free. While Levvy attempts to make your use of our Site and any
				content therein safe, we cannot and do not represent or warrant that our
				Site or servers are free of viruses or other harmful components. You
				assume the entire risk as to the quality and performance of the Site.
				Some jurisdictions do not allow the exclusion of or limitations on
				implied warranties or the limitations on the applicable statutory rights
				of a consumer, so some or all of the above exclusions and limitations
				may not apply to you.
			</strong>
		</p>
	);
}

export default Disclaimers;
