import { Member } from '../../utilities/utilities';
import * as clientAccountFormsActions from '../actions/client-accounts-actions';

const initialState = {
	softwareAccount: {
		name: '',
		institution_url_id: null,
		institution: '',
		account: '',
		password: '',
		description: '',
		notes: '',
		role_id: Member.teamMemberId,
	},
	merchantAccount: {
		name: '',
		institution_url_id: null,
		institution: '',
		account: '',
		password: '',
		description: '',
		notes: '',
		role_id: Member.teamMemberId,
		bankAccountLinkedTo: '',
		otherQuestions: [
			{
				question: '',
				answer: '',
				default: true,
			},
		],
	},
	paymentPortal: {
		name: '',
		institution_url_id: null,
		institution: '',
		account: '',
		password: '',
		description: '',
		notes: '',
		role_id: Member.teamMemberId,
		accountNumber: '',
		phone: '',
		email: '',
		otherQuestions: [
			{
				question: '',
				answer: '',
				default: true,
			},
		],
	},
	creditCardAccount: {
		name: '',
		institution_url_id: null,
		institution: '',
		account: '',
		password: '',
		description: '',
		notes: '',
		role_id: Member.teamMemberId,
		accountNumber: '',
		cvvCode: '',
		phone: '',
		creditCardName: '',
		zipCode: '',
		otherQuestions: [
			{
				question: '',
				answer: '',
				default: true,
			},
		],
	},
	bankAccount: {
		name: '',
		institution_url_id: null,
		institution: '',
		account: '',
		password: '',
		description: '',
		notes: '',
		role_id: Member.teamMemberId,
		accountNumber: '',
		routingNumber: '',
		contact: '',
		phone: '',
		email: '',
		otherQuestions: [
			{
				question: '',
				answer: '',
				default: true,
			},
		],
	},
	formError: '',
	formLoad: false,
	clientAccounts: [],
	editForm: null,
};

const clientAccountsReducer = (state = initialState, action) => {
	const { payload, type } = action;

	switch (type) {
		case clientAccountFormsActions.RESET_FORMS:
			return (state = {
				...state,
				softwareAccount: initialState.softwareAccount,
				merchantAccount: initialState.merchantAccount,
				paymentPortal: initialState.paymentPortal,
				creditCardAccount: initialState.creditCardAccount,
				bankAccount: initialState.bankAccount,
			});

		case clientAccountFormsActions.FORM_VALUE_CHANGE_HANLDER:
			return (state = {
				...state,
				[payload.form]: {
					...state[payload.form],
					[payload.name]: payload.value,
				},
			});

		case clientAccountFormsActions.RESET_FORM_VALUES:
			return (state = {
				...state,
				[payload.form]: { ...initialState[payload.form] },
			});

		case clientAccountFormsActions.SET_ERROR:
			return (state = {
				...state,
				formError: payload,
			});

		case clientAccountFormsActions.SET_ALL_URLS:
			return (state = {
				...state,
				allUrls: payload,
			});

		case clientAccountFormsActions.TOGGLE_lOAD:
			return (state = {
				...state,
				formLoad: !state.formLoad,
			});

		case clientAccountFormsActions.SET_ALL_ACCOUNTS:
			return (state = {
				...state,
				clientAccounts: payload,
			});

		case clientAccountFormsActions.RESET_ACCOUNT_LIST:
			return (state = {
				...state,
				clientAccounts: [],
			});

		case clientAccountFormsActions.SET_EDIT_FORM:
			return (state = {
				...state,
				editForm: payload,
			});

		case clientAccountFormsActions.SET_FORM:
			return (state = {
				...state,
				[payload.type]: payload.form ? payload.form : initialState[payload.type],
			});

		case clientAccountFormsActions.CLIENT_SOFTWARE_CHANGE:
			return (state = {
				...state,
				clientAccounts: state.clientAccounts?.map((acc) => {
					if (acc.id === payload.softwareStackId) {
						return {
							...acc,
							softwares: acc?.softwares?.map((soft) => {
								if (soft.id === payload.softwareId) {
									return {
										...soft,
										[payload.name]: payload.value,
									};
								} else {
									return soft;
								}
							}),
						};
					} else {
						return acc;
					}
				}),
			});
		case clientAccountFormsActions.CHANGE_CLIENT_ACCOUNTS_ARRAY_INDEX:
			return (state = {
				...state,
				clientAccounts: state.clientAccounts?.map((acc) => {
					if (acc.type === payload.objectBeingDragged) {
						return {
							...acc,
							softwares: [...payload.updatedIndexdArray],
						};
					} else {
						return acc;
					}
				}),
			});
		case clientAccountFormsActions.ADD_SECURITY_QUESTION:
			return (state = {
				...state,
				[payload]: {
					...state[payload],
					otherQuestions: [
						...state[payload]['otherQuestions'],
						{
							question: '',
							answer: '',
						},
					],
				},
			});
		case clientAccountFormsActions.DELETE_SECURITY_QUESTION:
			return (state = {
				...state,
				[payload.accountType]: {
					...state[payload.accountType],
					otherQuestions: state[payload.accountType]['otherQuestions']?.filter(
						(item, i) => payload.arrayIndex !== i,
					),
				},
			});
		case clientAccountFormsActions.OTHER_QUESTION_CHANGE_HANDLER:
			return (state = {
				...state,
				[payload.accountType]: {
					...state[payload.accountType],
					otherQuestions: state[payload.accountType]['otherQuestions']?.map((item, i) => {
						if (i === payload.arrayIndex) {
							return {
								...item,
								[payload.name]: payload.value,
							};
						}
						return item;
					}),
				},
			});
		default:
			return state;
	}
};

export default clientAccountsReducer;
