
function Feedback() {
	return (
		<p className='BodyTwoLight'>
			You may voluntarily post, submit or otherwise communicate to us any
			questions, comments, suggestions, ideas, original or creative materials or
			other information about Levvy or our Site (collectively, “
			<strong>Feedback</strong>”). You understand that we may use such Feedback
			for any purpose, commercial or otherwise, without acknowledgment or
			compensation to you, including, without limitation, to develop, copy,
			publish, or improve the Feedback in Levvy’s sole discretion. You
			understand that Levvy may treat Feedback as nonconfidential.
		</p>
	);
}

export default Feedback;
