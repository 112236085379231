import { Box } from '@mui/system';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Input } from '../Inputs/Input';
import TableHeader from '../Others/TableHeader';
import TableHeaderItem from '../Others/TableHeaderItem';
import TableRowItem from '../Others/TableRowItem';
import { borderGrey } from '../Style';
import { TableItemWidth3 } from '../TableSpacing/TableSpacingStyles';
import * as Actions from '../../redux/actions/action-center';
import { ReactComponent as LeaveIcon } from '../../assets/icons/UserCircleMinus.svg';

export default function Leaves() {
	const dispatch = useDispatch();

	const leaves = useSelector((state) => state.actionCenterReducer.leaves);
	const leaveTypes = useSelector(
		(state) => state.actionCenterReducer.leaveTypes,
	);
	const userLeaves = useSelector(
		(state) => state.actionCenterReducer.userLeaves,
	);
	const user = useSelector((state) => state.authReducer.user);
	const loading = useSelector((state) => state.actionCenterReducer.loading);

	useEffect(() => {
		dispatch(Actions.getLeaves());
		dispatch(Actions.getLeaveTypes());
		dispatch(Actions.getUserLeaves(user?.userId));
	}, [dispatch, loading]);

	return (
		<div className='profile-section' style={{gap: 12}}>
			<div className='Uprofile-sectionBar'>
				<div className='d-flex align-items-center' style={{ gap: '12px' }}>
					<LeaveIcon />
					<h2 className='HeadlineThreeBold text-left'>Leaves</h2>
				</div>
			</div>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					gap: '0.3em',
					padding: '15px',
					backgroundColor: '#EEF2F3',
					borderRadius: '4px'
				}}
			>
				<Box
				>
					<TableHeader ClassName='TableHeader flex' border={false}>
						<TableHeaderItem label='Dates' width={TableItemWidth3} />
						<TableHeaderItem label='Leave Type' width={TableItemWidth3} />
						<TableHeaderItem label='Status' width={TableItemWidth3} />
						<TableHeaderItem label='Reason' width={TableItemWidth3} />
						<TableHeaderItem label='No of leaves' width={TableItemWidth3} />
					</TableHeader>
					{user?.userRole.id === 11 || user?.userRole.id === 12 ? (
						leaves.length > 0 ? (
							leaves.map((leave, index) => {
								return (
									<div
										className='TableRow d-flex align-items-center'
										key={index}
									>
										{/* {console.log(leave.dates.map((leave, index, date) => {
												if(index + 1 === date.length){
													return leave;
												}
												return leave + ', ')} */}
										<TableRowItem
											ClassName=' text-left'
											label={leave.leaves.map((date, index, arr) => {
												var tempDate = new Date(date.leave_date);
												var currDate = tempDate.getDate();
												var day = tempDate.getDay();
												var month = tempDate.getMonth();
												var year = tempDate.getFullYear();
												if (index + 1 === arr.length) {
													return currDate + '/' + month + '/' + year;
												}
												return currDate + '/' + month + '/' + year + ', ';
											})}
											width={TableItemWidth3}
											input={false}
										></TableRowItem>

										<TableRowItem
											ClassName=' text-left'
											label={
												leaveTypes.find((type) => type.id === leave.leave_type)
													?.name
											}
											width={TableItemWidth3}
											input={false}
										></TableRowItem>

										<TableRowItem
											ClassName=' text-left'
											label={leave.status}
											width={TableItemWidth3}
											input={false}
											textStyle={{
												color: leave.status === 'PENDING' && '#fb7a03',
											}}
										></TableRowItem>

										<TableRowItem
											ClassName=' text-left'
											label={leave.reason}
											width={TableItemWidth3}
											input={false}
										></TableRowItem>
										<TableRowItem
											ClassName=' text-left'
											label={leave.leaves.length}
											width={TableItemWidth3}
											input={false}
										>
											<Input
												label='No of Leaves'
												name='num'
												value={leave.leaves.length}
											/>
										</TableRowItem>
									</div>
								);
							})
						) : (
							<div className='TableRow d-flex align-items-center'>
								<span className='BodyTwoLight' style={{ color: '#B4B4B4' }}>
									No Leaves
								</span>
							</div>
						)
					) : userLeaves.length > 0 ? (
						userLeaves.map((leave, index) => {
							return (
								<div className='TableRow d-flex align-items-center' key={index}>
									{/* {console.log(leave.dates.map((leave, index, date) => {
													if(index + 1 === date.length){
														return leave;
													}
													return leave + ', ')} */}
									<TableRowItem
										ClassName=' text-left'
										label={leave.leaves.map((date, index, arr) => {
											var tempDate = new Date(date.leave_date);
											var currDate = tempDate.getDate();
											var day = tempDate.getDay();
											var month = tempDate.getMonth();
											var year = tempDate.getFullYear();
											if (index + 1 === arr.length) {
												return currDate + '/' + month + '/' + year;
											}
											return currDate + '/' + month + '/' + year + ', ';
										})}
										width={TableItemWidth3}
										input={false}
									></TableRowItem>

									<TableRowItem
										ClassName=' text-left'
										label={
											leaveTypes.find((type) => type.id === leave.leave_type)
												?.name
										}
										width={TableItemWidth3}
										input={false}
									></TableRowItem>

									<TableRowItem
										ClassName=' text-left'
										label={leave.status}
										width={TableItemWidth3}
										input={false}
										textStyle={{
											color: leave.status === 'PENDING' && '#fb7a03',
										}}
									></TableRowItem>

									<TableRowItem
										ClassName=' text-left'
										label={leave.reason}
										width={TableItemWidth3}
										input={false}
									></TableRowItem>
									<TableRowItem
										ClassName=' text-left'
										label={leave.leaves.length}
										width={TableItemWidth3}
										input={false}
									>
										<Input
											label='No of Leaves'
											name='num'
											value={leave.leaves.length}
										/>
									</TableRowItem>
								</div>
							);
						})
					) : (
						<div className='TableRow d-flex align-items-center'>
							<span className='BodyTwoLight' style={{ color: '#B4B4B4' }}>
								No Leaves
							</span>
						</div>
					)}
				</Box>
			</Box>
		</div>
	);
}
