import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

function RequireToolTip({ title, placement, children, ...props }) {

    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            padding: '10px',
            gap: '10px',
            width: '228px',
            height: '52px',
            background: '#fffefb',
            boxShadow: '0px 2px 10px -4px #cdddd8',
            borderRadius: '3px',
            color: '#696f6c',
            fontFamily: 'Calibre',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '16px'
        },
    }));

    return (
        <HtmlTooltip title={title} placement={placement}  {...props} >
            {children}
        </HtmlTooltip>
    );
}

export default RequireToolTip;