function SectionBar({ header, headerActions }) {
	return (
		<div className='section-bar'>
			<div className='section-header'>{header}</div>
			<div className='section-header-actions'>{headerActions}</div>
		</div>
	);
}

export default SectionBar;
