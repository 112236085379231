import React from 'react';
import { ReactComponent as Levvy } from '../../assets/icons/LevvyWordMark.svg';

import { ReactComponent as LevvyLogo } from '../../assets/icons/Shape.svg';

import ForgetPassword from '../../components/login/ForgetPassword';

export default function ForgetPasswordComponent() {
	return (
		<div style={{ overflow: 'hidden' }}>
			<div
				className='login-screen'
				style={{ display: 'flex', justifyContent: 'center' }}
			>
				<div className='' style={{ justifyContent: 'center', display: 'grid' }}>
					<div className='tCenter'>
						<LevvyLogo
							className='levvyLogo'
							style={{ marginTop: '80px', marginBottom: '72px' }}
						/>
					</div>

					<div className=''>
						<div className='dfCenter'>
							<ForgetPassword />
						</div>
					</div>

					<div className='dfCenter'>
						<div className='' style={{ marginTop: 'auto' }}>
							<p
								style={{ marginTop: '70px', marginBottom: '2px' }}
								className='dfCenter'
							>
								Powered By
							</p>
							<p style={{ marginBottom: '15px' }}>
								<Levvy />
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
