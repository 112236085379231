/* eslint-disable no-mixed-spaces-and-tabs */
import * as Actions from '../actions/client-actions';

const inititalState = {
	clients: [],
	loading: false,
	client: null,
	dragValues: {
		objectBeingDragged: null,
		beingDragged: null,
		dragTo: null,
	},
	filteredClientManagement: null,
	addNewContact: {
		first_name: '',
		last_name: '',
		title: '',
		company_website: '',
		notes: '',
		email: [''],
		direct: [''],
		general: [''],
		address: [''],
		extension: [''],
	},
	contactToEdit: {
		first_name: '',
		last_name: '',
		title: '',
		company_website: '',
		notes: '',
		email: [''],
		direct: [''],
		general: [''],
		address: [''],
		extension: [''],
	},
	addNewOffice: {
		hq: '',
		street: '',
		country: '',
		city: '',
		state: '',
		zip: '',
	},
	officeToEdit: {
		hq: '',
		country: '',
		street: '',
		city: '',
		state: '',
		zip: '',
		index: null,
	},
	addNewSoftware: {
		software: '',
		username: '',
		login: '',
		notes: '',
		password: '',
		category: '',
	},
	addNewAccount: {
		institution: '',
		accountNumber: '',
		login: '',
		notes: '',
		password: '',
		category: '',
	},
	linksToEdit: {
		notes: '',
		name: '',
		account_document_storage: '',
		login: '',
	},
	filters: {
		industry: [],
		business: [],
		states: [],
		teams: [],
	},
	clientInvoice: {
		start_date: null,
		end_date: null,
	},
	billingWorkflowTaskDetails: [],
	softwareModal: false,
	contactModal: false,
	link: null,
	error: '',
	switchClientLoad: false,
	formLoad: false,
	workflowMyTasks: [],
	meetingMyTasks: [],
	clientSectionLoading: true,
	userTeamRole: undefined,
	clientProfileActiveTab: 'PROFILE',
	clientsForTeamProfile: [],
	folderData: [],
	folderDataForModal: [],
	upload_progress: {},
	upload_status: {},
	folderSelected: null,
	workflowsToAddForClientPreview: [],
};

const ClientReducer = (state = inititalState, action) => {
	const { payload, type } = action;
	switch (type) {
		case Actions.SET_CLIENT_PROFILE_ACTIVE_TAB:
			return (state = {
				...state,
				clientProfileActiveTab: payload,
			});
		case Actions.SET_USER_TEAM_ROLE:
			return (state = {
				...state,
				userTeamRole: payload,
			});
		case Actions.SET_CLIENT_SECTION_LOADING:
			return (state = {
				...state,
				clientSectionLoading: payload,
			});
		case Actions.RESET_CLIENT_STATE:
			return (state = {
				...state,
				client: null,
			});
		case Actions.SET_FORM_LOAD:
			return (state = {
				...state,
				formLaod: payload,
			});
		case Actions.ADD_LINK_TO_CLIENT:
			return (state = {
				...state,
				client: {
					...state.client,
					links: [...state.client.links, payload],
				},
			});
		case Actions.EDIT_LINK_TO_CLIENT:
			return (state = {
				...state,
				client: {
					...state.client,
					links: state.client.links.map((link, index) => {
						if (index === payload.index) {
							delete payload.index;
							return {
								...payload,
							};
						}
						return link;
					}),
				},
			});
		case Actions.REMOVE_LINK_FROM_CLIENT:
			return (state = {
				...state,
				client: {
					...state.client,
					links: state.client.links?.filter(function (item, i) {
						return i !== payload;
					}),
				},
			});
		case Actions.SET_ERROR:
			return (state = {
				...state,
				error: payload,
			});
		case Actions.LINK_CHANGE_HANDLER:
			return (state = {
				...state,
				link: {
					...state.link,
					[payload.name]: payload.value,
				},
			});
		case Actions.EDIT_LINK_CHANGE_HANDLER:
			return (state = {
				...state,
				linksToEdit: {
					...state.linksToEdit,
					[payload.name]: payload.value,
				},
			});
		case Actions.RESET_LINK:
			return (state = {
				...state,
				link: {},
			});
		case Actions.GET_CLIENTS:
			return (state = {
				...state,
				clients: payload.filter((client) => client.deleted === false),
				loading: false,
			});

		case Actions.GET_CLIENTS_START:
			return (state = {
				...state,
				loading: true,
			});

		case Actions.GET_CLIENTS_FAILED:
			return (state = {
				...state,
				loading: false,
			});
		case Actions.GET_FILTERED_CLIENT_MANAGEMENT:
			return (state = {
				...state,
				filteredClientManagement: payload,
			});
		case Actions.DEACTIVATE_CLIENT_WORKFLOWS_START:
			return (state = {
				...state,
				loading: true,
			});
		case Actions.DEACTIVATE_CLIENT_WORKFLOWS:
			return (state = {
				...state,
				clients: state.clients.filter((client) => client.id !== parseInt(payload)),
				loading: false,
			});
		case Actions.DEACTIVATE_CLIENT_WORKFLOWS_FAILED:
			return (state = {
				...state,
				loading: false,
			});
		case Actions.DELETE_CLIENT_END:
			return (state = {
				...state,
				clients: state.clients.filter((client) => client.id !== payload),
				loading: false,
			});

		case Actions.DELETE_CLIENT_START:
			return (state = {
				...state,
				loading: true,
			});

		case Actions.DELETE_CLIENT_FAILED:
			return (state = {
				...state,
				loading: false,
			});

		case Actions.ADD_CLIENT:
			return (state = {
				...state,
				clients: [...state.clients, payload],
				client: payload,
			});

		case Actions.UPDATE_CLIENT:
			return (state = {
				...state,
				clients: state?.clients?.map((client) => {
					if (client.id === payload.id) {
						return payload;
					}
					return client;
				}),
				client: {
					...state?.client,
					...payload,
				},
			});

		case Actions.MULTI_DELETE_START:
			return (state = {
				...state,
				loading: true,
			});

		case Actions.HANDLE_CLEAR_TEAM_CHECKBOX:
			return (state = {
				...state,
				filters: {
					...state.filters,
					teams: [],
				},
			});

		case Actions.MULTI_DELETE_END:
			return (state = {
				...state,
				clients: state.clients.filter(
					(client) => client.id !== payload.find((id) => id === client.id),
				),
				loading: false,
			});

		case Actions.MULTI_DELETE_FAILED:
			return (state = {
				...state,
				loading: false,
			});

		case Actions.GET_SINGLE_CLIENT:
			return (state = {
				...state,
				client: payload,
			});

		case Actions.CLIENT_DATA_CHANGE:
			return (state = {
				...state,
				client: {
					...state.client,
					[payload.name]: payload.value,
				},
			});

		case Actions.CLIENT_LINK_DATA_CHANGE:
			return (state = {
				...state,
				client: {
					...state.client,
					link: {
						...state.client.link,
						[payload.name]: payload.value,
					},
				},
			});

		case Actions.HANDLE_DATE_CHANGE:
			return (state = {
				...state,
				client: {
					...state.client,
					date: payload,
				},
			});
		case Actions.HANDLE_START_DATE_CHANGE:
			return (state = {
				...state,
				client: {
					...state.client,
					start_date: payload,
				},
			});
		case Actions.HANDLE_OFFICE_ADDRESS:
			return (state = {
				...state,
				addNewOffice: {
					...state.addNewOffice,
					city: state.client?.city ? state.client.city : inititalState.addNewOffice.city,
					zip: state.client?.zip ? state.client.zip : inititalState.addNewOffice.zip,
					state: state.client?.state
						? state.client.state
						: inititalState.addNewOffice.state,
					hq: state.client?.address
						? state.client.address.substring(0, state.client.address.indexOf(',')) || ''
						: inititalState.addNewOffice.street,
					street: state.client?.address
						? state.client.address.substring(state.client.address.indexOf(',') + 1) ||
						  ''
						: inititalState.addNewOffice.street,
					country: state.client?.country
						? state.client.country
						: inititalState.addNewOffice.country,
				},
			});
		case Actions.UPDATE_CLIENT_TEAM:
			return (state = {
				...state,
				client: {
					...state.client,
					team_client_permissions: [
						{
							team_id: parseInt(payload),
							id: state.client.team_client_permissions[0].id,
							client_id: state.client.team_client_permissions[0].client_id,
						},
					],
				},
			});

		case Actions.CLIENT_CONTACT_CHANGE:
			return (state = {
				...state,
				client: {
					...state.client,
					client_contact_list: state.client.client_contact_list.map((contact, index) => {
						if (index === payload.index) {
							return {
								...contact,
								[payload.name]: payload.value,
							};
						}
						return contact;
					}),
				},
			});

		case Actions.NEW_CONTACT_FORM_CHANGE:
			return (state = {
				...state,
				addNewContact: {
					...state.addNewContact,
					[payload.name]: isNaN(payload?.index)
						? payload.value
						: state?.addNewContact[payload.name]?.map((item, i) => {
								if (i === payload?.index) {
									return payload.value;
								} else {
									return item;
								}
						  }),
				},
			});
		case Actions.EDIT_CONTACT_FORM_CHANGE:
			return (state = {
				...state,
				contactToEdit: {
					...state.contactToEdit,
					[payload.name]: isNaN(payload?.index)
						? payload.value
						: state?.contactToEdit[payload.name]?.map((item, i) => {
								if (i === payload?.index) {
									return payload.value;
								} else {
									return item;
								}
						  }),
				},
			});
		case Actions.RESET_NEW_CONTACT_FORM:
			return (state = {
				...state,
				addNewContact: { ...inititalState.addNewContact },
			});
		case Actions.RESET_EDIT_CONTACT_FORM:
			return (state = {
				...state,
				contactToEdit: { ...inititalState.addNewContact, index: null },
			});
		case Actions.ADD_NEW_CONTACT:
			return (state = {
				...state,
				client: {
					...state.client,
					client_contact_list: [...state.client.client_contact_list, payload],
				},
			});
		case Actions.UPDATE_CLIENT_CONTACT_LIST:
			return (state = {
				...state,
				client: {
					...state?.client,
					client_contact_list: [
						...state?.client?.client_contact_list?.map((contact) => {
							if (contact.id === payload.id) {
								return {
									...payload,
								};
							}
							return contact;
						}),
					],
				},
			});
		case Actions.CLIENT_OFFICE_CHANGE:
			return (state = {
				...state,
				client: {
					...state.client,
					client_office_locations: state.client.client_office_locations.map(
						(office, index) => {
							if (index === payload.index) {
								return {
									...office,
									[payload.name]: payload.value,
								};
							}

							return office;
						},
					),
				},
			});

		case Actions.CLIENT_LINK_CHANGE:
			return (state = {
				...state,
				client: {
					...state.client,
					links: state.client.links.map((link, index) => {
						if (index === payload.index) {
							return {
								...link,
								[payload.name]: payload.value,
							};
						}
						return link;
					}),
				},
			});

		case Actions.NEW_OFFICE_FORM_CHANGE:
			return (state = {
				...state,

				addNewOffice: {
					...state.addNewOffice,
					[payload.name]: payload.value,
				},
			});

		case Actions.RESET_NEW_OFFICE_FORM:
			return (state = {
				...state,
				addNewOffice: { ...inititalState.addNewOffice },
			});
		case Actions.EDIT_OFFICE_FORM_CHANGE:
			return (state = {
				...state,

				officeToEdit: {
					...state.officeToEdit,
					[payload.name]: payload.value,
				},
			});

		case Actions.RESET_EDIT_OFFICE_FORM:
			return (state = {
				...state,
				officeToEdit: {
					...state.officeToEdit,
					hq: null,
					state: null,
					city: null,
					street: null,
					country: null,
					zip: null,
					index: null,
				},
			});
		case Actions.SUBMIT_NEW_OFFICE_FORM:
			return (state = {
				...state,
				client: {
					...state.client,
					client_office_locations: [...state.client.client_office_locations, payload],
				},
			});
		case Actions.ADD_PRIMARY_LOCATION:
			return (state = {
				...state,
				client: {
					...state.client,
					client_office_locations: state?.client?.client_office_locations?.find(
						(loc) => loc.is_primary,
					)
						? state?.client?.client_office_locations?.map((loc) => {
								if (loc.is_primary) {
									return {
										...payload,
									};
								}
								return loc;
						  })
						: [payload, ...state?.client?.client_office_locations],
				},
			});
		case Actions.SUBMIT_EDIT_OFFICE_FORM:
			return (state = {
				...state,
				client: {
					...state.client,
					client_office_locations: [
						...state.client.client_office_locations?.map((editedOffice) => {
							if (editedOffice.id === payload.id) {
								return {
									...editedOffice,
									hq: payload?.hq,
									street: payload?.street,
									country: payload?.country,
									city: payload?.city,
									zip: payload?.zip,
									state: payload?.state,
								};
							} else {
								return { ...editedOffice };
							}
						}),
					],
				},
			});
		case Actions.CLIENT_SOFTWARE_CHANGE:
			return (state = {
				...state,
				client: {
					...state.client,
					client_software_stacks: state.client.client_software_stacks.map(
						(software, index) => {
							if (index === payload.index) {
								return {
									...software,
									[payload.name]: payload.value,
								};
							}
							return software;
						},
					),
				},
			});

		case Actions.NEW_SOFTWARE_FORM_CHANGE:
			return (state = {
				...state,
				addNewSoftware: {
					...state.addNewSoftware,
					[payload.name]: payload.value,
				},
			});

		case Actions.RESET_NEW_SOFTWARE_FORM:
			return (state = {
				...state,
				addNewSoftware: { ...inititalState.addNewSoftware },
			});

		case Actions.SUBMIT_NEW_SOFTWARE_FORM:
			return (state = {
				...state,
				client: {
					...state.client,
					client_software_stacks: [...state.client.client_software_stacks, payload],
				},
			});

		case Actions.DELETE_CLIENT_CONTACT:
			return (state = {
				...state,
				client: {
					...state.client,
					client_contact_list: state?.client?.client_contact_list?.filter(
						(contact) => contact.id !== payload,
					),
				},
			});

		case Actions.CLIENT_CONTACT_TO_EDIT:
			return (state = {
				...state,
				contactToEdit: { ...payload },
			});

		case Actions.DELETE_CLIENT_SOFTWARE:
			return (state = {
				...state,
				client: {
					...state.client,
					client_software_stacks: state.client.client_software_stacks.filter(
						(s, index) => index !== payload,
					),
				},
			});

		case Actions.DELETE_CLIENT_OFFICE:
			return (state = {
				...state,
				client: {
					...state.client,
					client_office_locations: state.client.client_office_locations.filter(
						(o, index) => index !== payload,
					),
				},
			});

		case Actions.DELETE_LINK:
			return (state = {
				...state,
				client: {
					...state.client,
					links: state.client.links.filter((o, index) => index !== payload),
				},
			});

		case Actions.HANDLE_INDUSTRY_CHECKBOX:
			return (state = {
				...state,
				filters: {
					...state.filters,
					industry: state.filters.industry.includes(payload)
						? state.filters.industry.filter((p) => p !== payload)
						: [...state.filters.industry, payload],
				},
			});

		case Actions.HANDLE_TEAM_CHECKBOX:
			return (state = {
				...state,
				filters: {
					...state.filters,
					teams: state.filters.teams.includes(payload)
						? state.filters.teams.filter((p) => p !== payload)
						: [...state.filters.teams, payload],
				},
			});

		case Actions.HANDLE_BUSSINESS_CHECKBOX:
			return (state = {
				...state,
				filters: {
					...state.filters,
					business: state.filters.business.includes(payload)
						? state.filters.business.filter((p) => p !== payload)
						: [...state.filters.business, payload],
				},
			});
		case Actions.HANDLE_STATE_CHECKBOX:
			return (state = {
				...state,
				filters: {
					...state.filters,
					states: state.filters.states.includes(payload)
						? state.filters.states.filter((p) => p != payload)
						: [...state.filters.states, payload],
				},
			});
		case Actions.TOGGLE_CLIENT_MODAL:
			return (state = {
				...state,
				[payload]: !state[payload],
			});
		case Actions.TOGGLE_CLIENT_LOAD:
			return (state = {
				...state,
				switchClientLoad: payload,
			});
		case Actions.SET_DRAG_VALUES:
			return (state = {
				...state,
				dragValues: {
					...state.dragValues,
					...payload,
				},
			});
		case Actions.CHANGE_CLIENT_OFFICE_LOCATION_ARRAY_INDEX:
			return (state = {
				...state,
				client: {
					...state.client,
					client_office_locations: [...payload],
				},
			});
		case Actions.CHANGE_CLIENT_CONTACTS_ARRAY_INDEX:
			return (state = {
				...state,
				client: {
					...state.client,
					client_contact_list: [...payload],
				},
			});
		case Actions.CHANGE_CLIENT_LINKS_ARRAY_INDEX:
			return (state = {
				...state,
				client: {
					...state.client,
					links: [...payload],
				},
			});
		case Actions.OFFICE_LOCATION_TO_EDIT:
			return (state = {
				...state,
				officeToEdit: {
					...payload,
				},
			});
		case Actions.LINKS_TO_EDIT:
			return (state = {
				...state,
				linksToEdit: {
					...payload,
				},
			});
		case Actions.HANDLE_CLEAR_BUSINESS_CHECKBOX:
			return (state = {
				...state,
				filters: {
					...state.filters,
					business: inititalState.filters.business,
				},
			});

		case Actions.HANDLE_CLEAR_INDUSTRY_CHECKBOX:
			return (state = {
				...state,
				filters: {
					...state.filters,
					industry: inititalState.filters.industry,
				},
			});

		case Actions.HANDLE_CLEAR_STATE_CHECKBOX:
			return (state = {
				...state,
				filters: {
					...state.filters,
					states: inititalState.filters.states,
				},
			});
		case Actions.ADDING_NEW_FIELD:
			return (state = {
				...state,
				[payload.parent]: {
					...state[payload.parent],
					[payload.name]: state[payload.parent][payload.name].concat(''),
				},
			});
		case Actions.REMOVING_CONTACT_FIELD:
			return (state = {
				...state,
				[payload.parent]: {
					...state[payload.parent],
					[payload.name]: state[payload.parent][payload.name]?.filter(
						(field, i) => i !== payload.index,
					),
				},
			});
		case Actions.CLIENT_TEAM_CHANGE:
			return (state = {
				...state,
				clients: state?.clients?.map((client) => {
					if (client.id == payload.clientId) {
						return {
							...client,
							team_client_permissions: client?.team_client_permissions?.map(
								(item, index) => {
									if (index == 0) {
										return {
											...item,
											team_id: payload.value,
										};
									}
									return item;
								},
							),
						};
					}
					return client;
				}),
			});
		case Actions.ALL_CLIENT_TEAM_CHANGE:
			return (state = {
				...state,
				clients: state?.clients?.map((client) => {
					return {
						...client,
						team_client_permissions: client?.team_client_permissions?.map(
							(item, index) => {
								if (index == 0) {
									return {
										...item,
										team_id: payload.newTeamId,
									};
								}
								return item;
							},
						),
					};
				}),
			});
		case Actions.CLIENT_INVOICE_FORM_CHANGE_HANDLER:
			return (state = {
				...state,
				clientInvoice: {
					...state.clientInvoice,
					[payload.name]: payload.value,
				},
			});
		case Actions.SET_CLIENT_INVOICE:
			return (state = {
				...state,
				clientInvoice: payload,
			});
		case Actions.BILLING_WORKFLOW_TASK_DETAILS:
			return (state = {
				...state,
				billingWorkflowTaskDetails: payload,
			});
		case Actions.HANDLE_SELECT_ALL_CLIENT_INVOICE:
			return {
				...state,
				billingWorkflowTaskDetails: state.billingWorkflowTaskDetails.map(
					(billingWorkflowTaskDetail, index) => {
						if (payload.index == index) {
							return {
								...billingWorkflowTaskDetail,
								workflows: billingWorkflowTaskDetail.workflows.map((workflow) => {
									return {
										...workflow,
										workflow_users: workflow.workflow_users.map((user) => {
											return {
												...user,
												is_selected: payload.state,
											};
										}),
									};
								}),
							};
						}
						return billingWorkflowTaskDetail;
					},
				),
			};
		case Actions.HANDLE_SELECT_ALL_CLIENT_WORKFLOW_INVOICE:
			return {
				...state,
				billingWorkflowTaskDetails: state.billingWorkflowTaskDetails.map(
					(billingWorkflowTaskDetail, index) => {
						if (payload.index == index) {
							return {
								...billingWorkflowTaskDetail,
								workflows: billingWorkflowTaskDetail.workflows.map((workflow) => {
									if (workflow.workflow === payload.workflowName) {
										return {
											...workflow,
											workflow_users: workflow.workflow_users.map((user) => {
												return {
													...user,
													is_selected: payload.state,
												};
											}),
										};
									}
									return workflow;
								}),
							};
						}
						return billingWorkflowTaskDetail;
					},
				),
			};
		case Actions.HANDLE_SELECT_CLIENT_USER_INVOICE:
			return {
				...state,
				billingWorkflowTaskDetails: state.billingWorkflowTaskDetails.map(
					(billingWorkflowTaskDetail, index) => {
						if (payload.index == index) {
							return {
								...billingWorkflowTaskDetail,
								workflows: billingWorkflowTaskDetail.workflows.map((workflow) => {
									if (workflow.workflow === payload.workflowName) {
										return {
											...workflow,
											workflow_users: workflow.workflow_users.map((user) => {
												if (user.user_id === payload.userId) {
													return {
														...user,
														is_selected: payload.state,
													};
												}
												return user;
											}),
										};
									}
									return workflow;
								}),
							};
						}
						return billingWorkflowTaskDetail;
					},
				),
			};
		case Actions.CLIENT_USER_INVOICE_CHANGE_HANDLER:
			return {
				...state,
				billingWorkflowTaskDetails: state.billingWorkflowTaskDetails.map(
					(billingWorkflowTaskDetail, index) => {
						if (payload.billingIndex == index) {
							return {
								...billingWorkflowTaskDetail,
								workflows: billingWorkflowTaskDetail.workflows.map((workflow) => {
									if (workflow.workflow === payload.workflow) {
										return {
											...workflow,
											workflow_users: workflow.workflow_users.map(
												(user, index) => {
													if (payload.index === index) {
														return {
															...user,
															[payload.name]: payload.value,
															charged_amount:
																payload.value === ''
																	? parseFloat(0)
																	: parseFloat(
																			parseFloat(
																				payload.value,
																			) *
																				parseFloat(
																					billingWorkflowTaskDetail?.rate,
																				),
																	  ).toFixed(2),
														};
													}
													return user;
												},
											),
										};
									}
									return workflow;
								}),
							};
						}
						return billingWorkflowTaskDetail;
					},
				),
			};
		case Actions.CLIENT_USER_INVOICE_CHANGES_DONE:
			return {
				...state,
				billingWorkflowTaskDetails: state.billingWorkflowTaskDetails.map(
					(billingWorkflowTaskDetail) => {
						return {
							...billingWorkflowTaskDetail,
							workflows: billingWorkflowTaskDetail.workflows.map((workflow) => {
								const total_Workflow_hours = workflow.workflow_users
									.filter((user) => user.is_selected)
									.map((user) => parseFloat(user.total_hours))
									.reduce((total, current) => total + current, 0);
								const total_Workflow_amount = workflow.workflow_users
									.filter((user) => user.is_selected)
									.map((user) => parseFloat(user.charged_amount))
									.reduce((total, current) => total + current, 0);
								return {
									...workflow,
									workflow_hours:
										parseFloat(total_Workflow_hours) || parseFloat(0),
									workflow_amount: parseFloat(total_Workflow_amount),
								};
							}),
						};
					},
				),
			};

		case Actions.GET_WORKFLOW_MY_TASKS:
			return (state = {
				...state,
				workflowMyTasks: payload,
			});
		case Actions.GET_MEETING_MY_TASKS:
			return (state = {
				...state,
				meetingMyTasks: payload,
			});
		case Actions.DELETE_CLIENT_MEETING_TASK:
			return (state = {
				...state,
				meetingMyTasks: state?.meetingMyTasks?.filter((task) => task.id != payload.id),
			});
		case Actions.ADD_CLIENT_SHARED_LINK:
			return (state = {
				...state,
				links: [...state?.links, payload],
			});
		case Actions.GET_CLIENTS_FOR_TEAM:
			return (state = {
				...state,
				clientsForTeamProfile: payload?.map((client) => {
					const filteredWorkflows = client.client_workflows
						?.map((workflow) => {
							const tasks = client.tasks?.filter(
								(task) =>
									task?.my_task?.client_workflow_id === workflow?.id ||
									task.client_workflow?.id === workflow.id,
							);
							return {
								...workflow,
								tasks: tasks || [],
							};
						})
						.filter(
							(workflow) => workflow?.status !== 'draft' && workflow.tasks.length > 0,
						);

					if (client.workflow_my_tasks?.length > 0) {
						filteredWorkflows.push({
							display_name: 'Ad hoc Tasks',
							id: 'my_task_workflow',
							mytasks: client.workflow_my_tasks,
							is_active: true,
						});
					}

					return {
						...client,
						client_workflows: filteredWorkflows,
					};
				}),
			});
		case Actions.GET_CLIENTS_FOR_TEAM_LOADING:
			return (state = {
				...state,
				loading: payload,
			});
		case Actions.GET_SPECIFIC_FOLDER:
			return (state = {
				...state,
				folderData: [payload],
				folderDataForModal: [payload],
			});
		case Actions.GET_MULTIPLE_FOLDERS_AND_FILES:
			return (state = {
				...state,
				folderData: payload,
			});
		case Actions.SET_FOLDERS_AND_FILES:
			return (state = {
				...state,
				folderData: payload,
			});
		case Actions.GET_ALL_FOLDERS_AND_FILES:
			var tempArr = state.folderData.map((folder) =>
				folder.id === payload.id ? { ...folder, expanded: !folder.expanded } : folder,
			);

			var newDataExists = payload.data.some(
				(newItem) => !tempArr.some((item) => item.id === newItem.id),
			);

			return (state = {
				...state,
				folderData: newDataExists ? [...tempArr, ...payload.data] : tempArr,
			});
		case Actions.GET_ALL_FOLDERS_AND_FILES_FOR_MODAL:
			var tempArrV2 = state.folderDataForModal.map((folder) =>
				folder.id === payload.id ? { ...folder, expanded: !folder.expanded } : folder,
			);

			var newDataExistsForModal = payload.data.some(
				(newItem) => !tempArrV2.some((item) => item.id === newItem.id),
			);

			return (state = {
				...state,
				folderDataForModal: newDataExistsForModal
					? [...tempArrV2, ...payload.data]
					: tempArrV2,
			});
		case Actions.CREATE_FOLDER:
			return (state = {
				...state,
				folderData: [...state.folderData, payload],
				folderDataForModal: [...state.folderDataForModal, payload],
			});
		case Actions.UPDATE_DOCUMENT:
			return (state = {
				...state,
				folderData: payload,
				folderDataForModal: payload,
			});
		case Actions.UPDATE_FOLDER:
			var updatedFolderData = state.folderData.map((item) => {
				if (item.id === payload.dragOverFolder.id) {
					return {
						...item,
						expanded: true,
					};
				} else if (item.id === payload.targetFolderId) {
					return {
						...item,
						parent_folder_id: payload.dragOverFolder.id,
					};
				} else return item;
			});

			return {
				...state,
				folderData: updatedFolderData,
			};
		case Actions.UPDATE_FOLDER_DATA:
			return (state = {
				...state,
				folderData: payload,
			});
		case Actions.DELETE_DOCUMENT:
			return (state = {
				...state,
				folderData: state.folderData.filter((folder) => {
					if (folder.id !== payload.id) {
						return folder;
					}
				}),
			});
		case Actions.CREATE_FILE: {
			return {
				...state,
				folderData: [...state.folderData, payload],
			};
		}
		case Actions.UPLOAD_FILE:
			return {
				...state,
				upload_progress: {
					...state.upload_progress,
					[payload.file.name]: payload.pc,
				},
			};
		case Actions.UPLOAD_IMAGE_PROGRESS:
			return {
				...state,
				upload_status: {
					...state.upload_status,
					[payload.file.name]: {
						status: payload.status,
						error: payload?.error,
					},
				},
			};
		case Actions.RESET_UPLOAD_STATE:
			return (state = {
				...state,
				upload_progress: {},
				upload_status: {},
			});
		case Actions.SET_FOLDER_SELECTED:
			return (state = {
				...state,
				folderSelected: payload,
			});
		case Actions.SET_WORKFLOW_TO_ADD_FOR_CLIENT_PREVIEW:
			return (state = {
				...state,
				workflowsToAddForClientPreview: payload,
			});

		default:
			return state;
	}
};

export default ClientReducer;
