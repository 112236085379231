import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

Sentry.init({
	dsn: window._env_?.REACT_APP_SENTRY_DSN,
	integrations: [new BrowserTracing({ tracingOrigins: ['*'] })],
	tracesSampleRate: 1.0,
});

export default Sentry;
