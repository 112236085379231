import { CircularProgress, Input, InputAdornment } from '@mui/material';
import { ReactComponent as SearchIcon } from '../../assets/icons/MagnifyingGlass.svg';
import * as searchActions from '../../redux/actions/search-actions';
import { useDispatch, useSelector } from 'react-redux';

function SearchBar({ style, onSubmit,type,filterInputValue,filterOnChange,placeholder,disabled, ...prop }) {
	const dispatch = useDispatch();
	const search = useSelector((state) => state.searchReducer.search);
	const loading = useSelector((state) => state.searchReducer.loading);
	const modalIsOpen = useSelector(
		(state) => state.searchReducer.isSearchModalOpen,
	);

	const changeHandler = (e) => {
		const { value } = e.target;
		dispatch(searchActions.onSearchChangeHandler(value));
	};

	const handleSearchSubmit = (event) => {
		event.preventDefault();

		if (search !== '') {
			!modalIsOpen && dispatch(searchActions.setModalState(true));
			dispatch(searchActions.globalSearch(search));
		}
	};
	if(type=='filterSearchBar'){
		return (
			<form autoComplete='off' onSubmit={handleSearchSubmit}>
				<Input
					autoComplete='off'
					value={filterInputValue}
					onChange={filterOnChange}
					className='pl-1'
					disabled={disabled}
					placeholder={placeholder || 'Search ...'}
					disableUnderline={true}
					id='input-with-icon-adornment'
					sx={style}
					inputProps={{ style: { fontSize: 14 } }}
					startAdornment={
						<InputAdornment position='start'>
							{loading ? (
								<CircularProgress
									style={{
										color: '#fa7b03',
										height: '17px',
										width: '17px',
									}}
								/>
							) : (
								<SearchIcon />
							)}
						</InputAdornment>
					}
					{...prop}
				/>
			</form>
		);
	}
	else{
		return (
			<form style={style} autoComplete='off' onSubmit={handleSearchSubmit}>
				<Input
					autoComplete='off'
					value={search}
					onChange={changeHandler}
					className='global-search-sidebar'
					placeholder='Search'
					disableUnderline={true}
					id='input-with-icon-adornment'
					sx={style}
					inputProps={{ style: { fontSize: 14 } }}
					startAdornment={
						<InputAdornment position='start'>
							{loading ? (
								<CircularProgress
									style={{
										color: '#fa7b03',
										height: '17px',
										width: '17px',
									}}
								/>
							) : (
								<SearchIcon />
							)}
						</InputAdornment>
					}
					{...prop}
				/>
			</form>
		);
	}
}

export default SearchBar;
