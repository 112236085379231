/* eslint-disable react/no-unescaped-entities */

function Indemnification() {
	return (
		<p className='BodyTwoLight'>
			To the fullest extent permitted by applicable law, you will indemnify,
			defend and hold harmless Levvy and our subsidiaries and affiliates, and
			each of our respective officers, directors, agents, partners, and
			employees (individually and collectively, the “
			<strong>Levvy Parties</strong>”) from and against any losses, liabilities,
			claims, demands, damages, expenses, or costs (“<strong>Claims</strong>”)
			arising out of or related to (a) your access to or use of the Site; (b)
			your Feedback; (c) your violation of these Terms; (d) your violation,
			misappropriation or infringement of any rights of another (including
			intellectual property rights or privacy rights); or (e) your conduct in
			connection with your use of the Site. You agree to promptly notify Levvy
			Parties of any third-party Claims, cooperate with Levvy Parties in
			defending such Claims and pay all fees, costs and expenses associated with
			defending such Claims (including, but not limited to, attorneys' fees).
			You also agree that the Levvy Parties will have control of the defense or
			settlement, at Levvy's sole option, of any third-party Claims. This
			indemnity is in addition to, and not in lieu of, any other indemnities set
			forth in a written agreement between you and Levvy or the other Levvy
			Parties. We may provide information about third-party products, services,
			activities, or events, or we may allow third parties to make their content
			and information available on or through the Site (collectively, “
			<strong>Third- Party Content</strong>”). We provide Third-Party Content as
			a service to those interested in such content. Your dealings or
			correspondence with third parties and your use of or interaction with any
			Third-Party Content are solely between you and the third party. Levvy does
			not control or endorse, and makes no representations or warranties
			regarding, any Third-Party Content, and your access to and use of such
			Third-Party Content is at your own risk.
		</p>
	);
}

export default Indemnification;
