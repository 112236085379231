import { useDispatch, useSelector } from 'react-redux';
import QuickbooksLogo from '../../assets/icons/Integrations/QuickBooks.svg';
import * as AccountingActions from '../../redux/actions/integrations/accounting-actions';
import { useEffect, useState } from 'react';
import { PLATFORM_KEYS } from '../../utilities/utilities';
import { CircularProgress } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { setSnackBarIsOpen } from '../../redux/actions/dashboard/workspaces-actions';

export default function AccountingComponent() {
	const dispatch = useDispatch();
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const [disconnectLoading, setDisconnectLoading] = useState(false);
	const [connection, setConnection] = useState(false);
	const [redirectUrl, setRedirectUrl] = useState('');

	const accountingApps = [
		{
			logo: QuickbooksLogo,
			name: 'Quickbooks',
			details:
				'Supercharge your workflow with QuickBooks 3rd party app integrations. The integrated apps provide functionality that spans invoicing, inventory management, expense management, payroll, eCommerce, reporting and much more.',
		},
	];

	const companyProfileData = useSelector((state) => state.profileReducer.profileData);
	const integration = useSelector((state) => state.AccountingReducer.integration);

	const connectQBO = (companyId) => {
		dispatch(
			AccountingActions.connectQBO(
				integration != null ? integration?.company_id : companyId,
				{
					platformKey: PLATFORM_KEYS.QBO,
				},
			),
		)
			.then((res) => {
				const linkUrl = res.linkUrl;
				if (linkUrl) {
					setRedirectUrl(linkUrl);
					window.open(linkUrl, '_blank');
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};
	const onConnect = () => {
		setLoading(true);
		if (redirectUrl) {
			window.open(redirectUrl, '_blank');
			setLoading(false);
		} else {
			if (integration != null) {
				connectQBO();
			} else {
				dispatch(AccountingActions.createCodatCompany({ name: companyProfileData?.name }))
					.then((res) => {
						connectQBO(res?.company_id);
					})
					.catch((e) => {
						dispatch(setSnackBarIsOpen(false, true, e.message));
					})
					.finally(() => {
						setLoading(false);
					});
			}
		}
	};

	const onSync = () => {
		setLoading(true);
		dispatch(AccountingActions.syncCodat(integration?.company_id, {}))
			.then((res) => {
				dispatch(
					setSnackBarIsOpen(true, true, 'Customer list sync process has been started'),
				);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const onDisconnect = () => {
		setDisconnectLoading(true);
		dispatch(
			AccountingActions.disconnectQBO(integration?.company_id, integration?.connection_id),
		)
			.then(() => setConnection(false))
			.catch(() => {
				console.log('err');
			})
			.finally(() => {
				setDisconnectLoading(false);
			});
	};

	useEffect(() => {
		dispatch(AccountingActions.getIntegration()).then((res) => {
			if (res == null) {
				return;
			} else {
				dispatch(AccountingActions.getCodatCompany(res?.company_id)).then((response) => {
					const dataConnection = response?.dataConnections?.[0];
					if (dataConnection && dataConnection.status === 'PendingAuth') {
						setRedirectUrl(dataConnection?.linkUrl);
					} else if (dataConnection && dataConnection?.status === 'Linked') {
						setConnection(true);
					}
				});
			}
		});
	}, [dispatch, history]);

	return (
		<div className='d-flex map-div' style={{ flexDirection: 'column', gap: '18px' }}>
			{accountingApps.map((app, index) => (
				<div
					key={index}
					className='d-flex align-items-center justify-content-between'
					style={{
						width: '100%',
						height: 'auto',
						padding: '16px',
						border: '1px solid #EAEAEA',
						backgroundColor: 'white',
					}}
				>
					<div className='d-flex align-items-center'>
						<img
							src={app.logo}
							alt={`${app.name} logo`}
							style={{ width: '32px', height: '32px' }}
						/>

						<div style={{ paddingLeft: '16px' }}>
							<div className='d-flex'>
								<span className='BodyOneBold'>{app.name}</span>
							</div>
							<div className='d-flex text-justify'>
								<span className='BodyTwoLight'>{app.details}</span>
							</div>
						</div>
					</div>
					<div className='d-flex' style={{ marginLeft: '80px', cursor: 'pointer' }}>
						{connection ? (
							disconnectLoading ? (
								<CircularProgress size={22} sx={{ color: '#FB7A03', marginRight:'25px' }} disableShrink />
							) : (
								<div className='d-flex flex-column align-items-center'>
									<button
										className='rounded-button d-flex BodyOneBold text-center'
										style={{
											height: '32px',
											width: '125px',
											justifyContent: 'center',
											alignItems: 'center',
											opacity: loading ? 0.5 : 1,
										}}
										onClick={onSync}
										disabled={loading}
									>
										{loading ? (
											<CircularProgress
												size={22}
												sx={{ color: 'white' }}
												disableShrink
											/>
										) : (
											<p className='BodyOneBold mb-1 text-white'>Sync</p>
										)}
									</button>
									<div className='d-flex flex-row align-items-center pointer mt-2'>
										<button
											onClick={onDisconnect}
											className='BodyTwoLight mb-0 primary-text ml-1'
											style={{
												outline: 'none',
												border: 'none',
												backgroundColor: 'transparent',
											}}
											disabled={disconnectLoading}
										>
											Disconnect
										</button>
									</div>
								</div>
							)
						) : (
							<button
								className='rounded-button d-flex BodyOneBold text-center'
								style={{
									height: '32px',
									width: '125px',
									justifyContent: 'center',
									alignItems: 'center',
									opacity: loading ? 0.5 : 1,
								}}
								onClick={onConnect}
								disabled={loading}
							>
								{loading ? (
									<CircularProgress
										size={22}
										sx={{ color: 'white' }}
										disableShrink
									/>
								) : (
									<p className='BodyOneBold mb-1 text-white'>Connect</p>
								)}
							</button>
						)}
					</div>
				</div>
			))}
		</div>
	);
}
