import { CircularProgress } from '@mui/material';
import SubmitButtonsField from '../Buttons/SubmitButtonsField';

export default function ArchiveModal({
	archiveUser,
	client,
	title,
	list,
	onCancel,
	onArchive,
	totalTasks,
	loading,
}) {
	return (
		<div>
			<div className='p-3'>
				{client ? (
					<div className='d-flex text-justify'>
						<span className='BodyTwoLight'>
							After the client is archived, all files saved in the client folder will
							be deleted. You can download the files before archiving. Additionally,
							the following workflows will be deactivated.
						</span>
					</div>
				) : (
					<>
						<div className='d-flex text-justify'>
							<span className='BodyTwoLight'>
								You are about to archive the profile for <b>{archiveUser}</b>.{' '}
								{totalTasks} tasks currently assigned to this user will be
								unassigned. These will need to be reassigned in the action center.
							</span>
						</div>
						<div className='d-flex text-justify pt-3'>
							<span className='BodyTwoLight'>
								All historical data for this user will not be deleted and can still
								be accessed.
							</span>
						</div>
					</>
				)}
				<div
					style={{
						width: '100%',
						height: '40px',
						background: '#FCFBF7',
						paddingLeft: '5px',
						marginTop: '24px',
						marginBottom: '14px',
					}}
					className='d-flex align-items-center'
				>
					<span className='BodyTwoBold'>{title}</span>
				</div>
				<div style={{ maxHeight: '350px', overflowY: 'scroll' }}>
					{list.length > 0 ? (
						list.map((item, index) => {
							return (
								<div
									key={index}
									style={{
										paddingBottom: '12px',
										paddingLeft: '5px',
									}}
									className='d-flex align-items-start'
								>
									<span className='BodyTwoLight'>{item}</span>
								</div>
							);
						})
					) : (
						<div
							style={{ paddingBottom: '12px', paddingLeft: '5px' }}
							className='d-flex align-items-start'
						>
							<span className='BodyTwoLight'>
								{client ? 'No Active Workflows' : 'No Affected Tasks'}
							</span>
						</div>
					)}
				</div>
			</div>

			<div className='bg-white p-3 rounded-bottom' style={{ paddingTop: '12px' }}>
				<SubmitButtonsField child={true}>
					<button id='Element' type='button' className='secondary' onClick={onCancel}>
						Cancel
					</button>

					<button
						className='d-flex align-items-center'
						id='Element'
						type='submit'
						onClick={onArchive}
						disabled={loading}
						data-cy='archive-user-submit'
					>
						{loading ? (
							<CircularProgress size={24} sx={{ color: 'white' }} disableShrink />
						) : list.length > 0 ? (
							client ? (
								'Deactivate all & Archive'
							) : (
								'Archive'
							)
						) : (
							'Archive'
						)}
					</button>
				</SubmitButtonsField>
			</div>
		</div>
	);
}
