import { memo } from 'react';
import { ReactComponent as WorkflowIcon } from '../../assets/icons/Package.svg';
import { ReactComponent as EditWorkflowIcon } from '../../assets/icons/NotePencil.svg';
import { ReactComponent as Info } from '../../assets/icons/Info.svg';
import NewTaskSections from '../WorkflowBuilder/NewTaskSections';
import FormButtonOne from '../Buttons/FormButtonOne';
import AboutSections from '../WorkflowBuilder/AboutSections';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { executivePermissions, sortTemplateTasks } from '../../utilities/utilities';

function NewWorkflowDetailModal({ workflow, companyLibrary, onClose, onDone, editWorkflow, archiveView }) {
	const location = useLocation();
	const clientForbuilder = location?.state;
	const user = useSelector((state) => state.authReducer.user);
	const actionsloading = useSelector((state) => state.WorkflowBuilder.builderLoadSave);
	return (
		<div className='workflow-modal'>
			<div className='workflow-modal-header'>
				<div
					className='d-flex align-items-center justify-content-between w-100'
					style={{ paddingTop: '24px' }}
				>
					<div className='d-flex'>
						<div style={{ margin: '0px 12px' }}>
							<WorkflowIcon />
						</div>
						<div>
							<span className='HeadlineOneBold'>{workflow?.name}</span>
						</div>
					</div>
					{companyLibrary && executivePermissions?.includes(user?.userRole?.id) && (
						<div className='d-flex pointer' onClick={editWorkflow}>
							<EditWorkflowIcon />
							<span
								style={{ margin: '0px 12px' }}
								className='BodyTwoLigt primary-text'
							>
								Edit in Builder
							</span>
						</div>
					)}
				</div>
			</div>
			<div className='workflow-modal-body'>
				{companyLibrary && <InfoComponent workflow={workflow} user={user} />}
				<NewTaskSections
					tasks={
						companyLibrary || archiveView
							? sortTemplateTasks(workflow?.task_templates)
							: sortTemplateTasks(workflow?.library_tasks)
					}
				/>
				<AboutSections workflow={workflow} companyLibrary={companyLibrary} />
			</div>
			<div className='workflow-modal-footer'>
				<FormButtonOne onClick={onClose} className='secondary-btn-light'>
					Cancel
				</FormButtonOne>
				{
					<div className='d-flex align-items-center' style={{ gap: '24px' }}>
						<span className='BodyTwoLight'>
							{clientForbuilder && `Selected Client: ${clientForbuilder?.clientName}`}
						</span>
						<FormButtonOne
							onClick={onDone}
							loading={actionsloading}
							disabled={actionsloading}
							style={{ opacity: actionsloading ? 0.5 : 1 }}
						>
							{workflow?.status === 'DRAFT'
								? 'Publish'
								: companyLibrary
								? 'Add to Client'
								: archiveView 
                                ? 'Unarchive'
                                 : 'Save as Draft'}
						</FormButtonOne>
					</div>
				}
			</div>
		</div>
	);
}

export default memo(NewWorkflowDetailModal);

const InfoComponent = ({ workflow, user }) => {
	const isPublished = workflow?.status === 'PUBLISHED';
	return (
		<div
			style={{
				padding: '8px 18px',
				marginBottom: '24px',
				borderRadius: '4px',
				display: 'flex',
				gap: '15px',
				border: '1px solid #fb7a03',
				boxShadow: '0px 7px 29px 0px #64646F33',
			}}
		>
			<div className='d-flex flex-column' style={{ gap: '12px' }}>
				{!executivePermissions?.includes(user?.userRole?.id) ? (
					<span className='BodyTwoLight d-flex align-items-center' style={{ gap: '5px' }}>
						You are viewing a{' '}
						<div
							className='d-flex align-items-center'
							style={{
								height: '24px',
								padding: '4px 16px',
								width: 'fit-content',
								borderRadius: '4px',
								backgroundColor: isPublished ? '#18C58D' : '#FB7A03',
							}}
						>
							<span className='BodyTwoBold mt-1' style={{ color: 'white' }}>
								{isPublished ? 'Published' : 'Draft'}
							</span>
						</div>
						template in your Company Library. Click{' '}
						<span className='BodyTwoBold'>Add to Client</span> to make changes once it
						is added to a client profile.
					</span>
				) : (
					<>
						<span
							className='BodyTwoLight d-flex align-items-center'
							style={{ gap: '5px' }}
						>
							You are viewing a{' '}
							<div
								className='d-flex align-items-center'
								style={{
									height: '24px',
									padding: '4px 16px',
									width: 'fit-content',
									borderRadius: '4px',
									backgroundColor: isPublished ? '#18C58D' : '#FB7A03',
								}}
							>
								<span className='BodyTwoBold mt-1' style={{ color: 'white' }}>
									{isPublished ? 'Published' : 'Draft'}
								</span>
							</div>
							template in your Company Library. You can:
						</span>
						<ul className='BodyTwoLight'>
							{isPublished ? (
								<li className='mb-2'>
									Click <span className='BodyTwoBold'>Add to Client</span> to make
									changes specific to a client, or
								</li>
							) : (
								<li className='mb-2'>
									Click <span className='BodyTwoBold'>Publish</span> to share it
									with your team
								</li>
							)}
							<li>
								Click <span className='BodyTwoBold'>Edit in Builder</span> to
								customize it for your team to fit your company’s process
							</li>
						</ul>
					</>
				)}
			</div>
		</div>
	);
};
