import React, { memo } from 'react';

// Components
import SectionHeader from './SectionHeader';
import ProjectMultiPickList from '../Inputs/ProjectMultiPickList';

const StatePicker = ({ options, onChange, onClear }) => {
	return (
		<div className='FilterSpacing-1'>
			<SectionHeader
				label='Clear All'
				title='Location'
				onClear={() => onClear()}
			/>
			<ProjectMultiPickList
				className='high'
				// value={teamsList}
				options={options ? options : []}
				onChange={onChange}
			/>
		</div>
	);
};

export default memo(StatePicker);
