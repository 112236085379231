/* eslint-disable no-mixed-spaces-and-tabs */
import FormButtonOne from '../Buttons/FormButtonOne';

export default function PrebuildDraftSuccesWorkflows({ draftedWorkflows, onClose, setActiveTab }) {
	const workflow = draftedWorkflows?.length == 1 ? draftedWorkflows[0] : draftedWorkflows;

	return (
		<div className='draft-workflow-success-modal'>
			<div className='draft-workflow-success-header'>
				<h2 className='HeadlineTwoBold'>
					{draftedWorkflows?.length == 1
						? 'Template is saved as draft in your company library'
						: 'Selected templates are saved as drafts in your company library'}
				</h2>
			</div>
			<div className='draft-workflow-success-body scroll'>
				<label className='BodyTwoLight m-0'>
					{draftedWorkflows?.length == 1
						? `The template ${workflow?.name}, is now a draft in your Company Library. You can:`
						: `These templates are now as drafts in your Company Library. You can Edit tasks to fit your company's process, or Publish them in your Company Library for your team to use when adding it to a client.`}
				</label>
				{draftedWorkflows?.length == 1 && (
					<ul className='d-flex flex-column BodyTwoLight m-0' style={{ gap: '12px' }}>
						<li>{`Edit tasks to fit your company's process, or`}</li>
						<li>
							Publish {draftedWorkflows?.length == 1 ? 'it' : 'them'} in your Company
							Library for your team to use when adding it to a client
						</li>
					</ul>
				)}
				{draftedWorkflows?.length > 1 && (
					<div className='d-flex flex-column'>
						{draftedWorkflows?.map((draft) => {
							return (
								<ul key={draft?.id}>
									<li className='BodyTwoLight'>{draft?.name}</li>
								</ul>
							);
						})}
					</div>
				)}
			</div>
			<div className='draft-workflow-success-footer'>
				<div>
					<FormButtonOne onClick={onClose} className='secondary-btn-light'>
						Cancel
					</FormButtonOne>
				</div>

				<div className='d-flex' style={{ gap: '12px' }}>
					<FormButtonOne
						onClick={() => {
							setActiveTab('COMPANY_LIBRARY');
							onClose();
						}}
					>
						Go to Company Library
					</FormButtonOne>
				</div>
			</div>
		</div>
	);
}
