import { useState, useEffect, useCallback } from 'react';
import { Modal } from '@mui/material';
import TeamMembers from './TeamMembers';
import TeamInformation from './TeamInformation';
import * as teamActions from '../../redux/actions/team-management.actions';
import * as clientActions from '../../redux/actions/client-actions';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Sidebar from '../sidebar/SideBar';
import * as userActions from '../../redux/actions/user-management.actions';
import * as WorkSpaceActions from '../../redux/actions/dashboard/workspaces-actions';
import useModal from '../Modal/useModal';
import TeamClients from './TeamClients';

import ProfileBar from './ProfileBar';
import ArchieveTeamModal from './Modal/ArchieveTeamModal';
import NewSidebarItem from '../sidebar/NewSidebarItem';
import AlertBar from '../sub_components/AlertBar';

function TeamProfile() {
	const dispatch = useDispatch();
	const { id } = useParams();

	const teamDetails = useSelector((state) => state.teamReducer.teamDetails);
	const start_date = useSelector((state) => state.teamReducer.start_date);
	const end_date = useSelector((state) => state.teamReducer.end_date);

	const { modalIsOpen, toggleModal } = useModal();
	const [activeTab, setActiveTab] = useState('Team');

	const tabChangeHandler = useCallback(
		(tab) => () => {
			setActiveTab(tab);
		},
		[],
	);

	const sidemenu = [
		{ name: 'Team Details', sectionId: 'Team' },

		{ name: 'Team Members', sectionId: 'Members' },

		{ name: 'Clients', sectionId: 'Clients' },
	];

	useEffect(() => {
		fetchData();

		return () => {
			dispatch(teamActions.resetTeamProfileData());
		};
	}, []);

	const fetchData = () => {
		dispatch(teamActions.getTeamMembers(id));
		dispatch(userActions.getRoles());
		dispatch(userActions.getUsers(null, id));
		dispatch(userActions.getFilteredUserProfileManagement());
	};
	const handleDeleteTeam = () => {
		toggleModal();
	};

	useEffect(() => {
		start_date &&
			end_date &&
			dispatch(
				clientActions.getClientsForTeamProfile(id, start_date, end_date),
			);
	}, [start_date, end_date]);

	useEffect(() => {
		dispatch(WorkSpaceActions.getAssignees());
	}, []);

	return (
		<div className='AppBody'>
			<Sidebar title='Team Management'>
				<div className='new-sidebar-content-wrapper'>
					<div className='User-filters'>
						<div className='new-side-menu BodyTwoLight-bold m-0 d-flex flex-column align-items-start'>
							{sidemenu?.map((item, index) => {
								const prevOfActive =
									sidemenu[index + 1]?.sectionId == activeTab;
								return (
									<>
										{index == 0 && (
											<div
												style={{ padding: '8px' }}
												className={`w-100 main-sidebar-item ${
													sidemenu[0].sectionId == activeTab ? 'prev' : ''
												}`}
											></div>
										)}
										<NewSidebarItem
											key={item.sectionId}
											uniqueId={item.sectionId}
											label={item.name}
											isActive={activeTab == item.sectionId}
											setActiveTab={tabChangeHandler}
										/>
										<div
											style={{ padding: '8px' }}
											className={`w-100 main-sidebar-item ${
												prevOfActive ? 'prev' : ''
											}`}
										></div>
									</>
								);
							})}
						</div>
					</div>
				</div>
			</Sidebar>
            <div
                style={{
                    width: 'calc(100vw - 260px)',
                    height: '100vh',
                    overflowY:'auto'
                }}
            >
                <AlertBar/>
                <div className='UserProfile-main team-profile-main'>
                    <div className='UserProfile-content'>
                        <ProfileBar
                            BarText={teamDetails?.name}
                            onArchieve={handleDeleteTeam}
                        />
                        {activeTab === 'Team' && <TeamInformation />}
                        {activeTab === 'Members' && <TeamMembers />}
                        {activeTab === 'Clients' && (
                            <TeamClients
                                teamName={teamDetails?.name}
                            />
                        )}
                    </div>
                </div>
			</div>


			{modalIsOpen && (
				<Modal
					onClose={toggleModal}
					open={modalIsOpen}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<ArchieveTeamModal teamId={id} onCancel={toggleModal} />
				</Modal>
			)}
		</div>
	);
}

export default TeamProfile;
