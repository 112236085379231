import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormButtonOne from '../../Buttons/FormButtonOne';
import * as workspaceActions from '../../../redux/actions/dashboard/workspaces-actions';
import MeetingMyTaskModalInputs from './MeetingMyTaskModalInputs';
import WorkflowTaskModalInputs from './WorkflowTaskModalInputs';

function AddMyTaskModal({
	onCancel,
	onSubmit,
	clientId,
	formRef,
	taskType,
	title,
    actionLoading
}) {
	!clientId &&
		(formRef.current = useSelector(
			(state) => state.workSpaceReducer.createTaskForm,
		));

	const tabs = [
		{
			name: 'Ad hoc Task',
			id: 1,
			tabInputs: <WorkflowTaskModalInputs clientId={clientId} />,
			type: 'WORKFLOW',
		},
		{
			name: 'Meeting',
			id: 2,
			tabInputs: <MeetingMyTaskModalInputs clientId={clientId} />,
			type: 'OFFICE',
		},
		// {
		// 	name: 'Personal',
		// 	id: 3,
		// 	tabInputs: <PersonalTaskModalInputs />,
		// 	type: 'PERSONAL',
		// },
	];

	const dispatch = useDispatch();
	const [tab, setTab] = useState(tabs[taskType == 'OFFICE' ? 1 : 0]);
	const errors = useSelector((state) => state.workSpaceReducer.errors);

	const cancelButtonClick = () => {
		onCancel();
	};

	const changeTab = (item) => {
		dispatch(workspaceActions.resetCreateTaskForm());
		dispatch(workspaceActions.resetError());
		dispatch(workspaceActions.setTaskCreatedError(''));
		setTab(item);
	};

	const onCreate = (event) => {
		event.preventDefault();
		onSubmit(tab.type);
	};

	useEffect(() => {
		return () => {
			dispatch(workspaceActions.resetCreateTaskForm());
		};
	}, []);

	return (
		<form onSubmit={onCreate} autoComplete='off' className='my-task-modal'>
			<div style={{ height: '90%' }} className='my-task-body'>
				<div className='my-task-body-child'>
					<div className='HeadlineTwoBold dark-text'>
						{title ? title : 'Create Task'}
					</div>
					<div className='my-task-inner-body'>
						<div className='my-task-types'>
							{tabs.map((item) => {
								return (
									!clientId && (
										<div
											onClick={actionLoading ? null : () => changeTab(item)}
											key={item.id}
											className={`dark-text my-task-type ${
												tab.id == item.id && 'active-form-tab'
											} `}
										>
											<span className='BodyTwoBold'>{item.name}</span>
										</div>
									)
								);
							})}
						</div>

						<div
							style={{
								height: '100%',
								boxSizing: 'border-box',
								overflow: 'auto',
							}}
						>
							{tab.tabInputs}
						</div>
					</div>
				</div>
				<div className='pb-2 pt-2'>
					<span className='error-text BodyTwoLight'>{errors}</span>
				</div>
			</div>

			<div className='VerticalSpacer bg-white p-3 rounded-bottom d-flex justify-content-between'>
				<FormButtonOne
					onClick={cancelButtonClick}
					type='button'
					className='secondary-btn-light'
				>
					Cancel
				</FormButtonOne>
				<FormButtonOne loading={actionLoading} type='submit'>
					Create
				</FormButtonOne>
			</div>
		</form>
	);
}

export default AddMyTaskModal;
