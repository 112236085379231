
function Miscellaneous() {
	return (
		<p className='BodyTwoLight'>
			The failure of Levvy to exercise or enforce any right or provision of
			these Terms will not operate as a waiver of such right or provision. The
			section titles in these Terms are for convenience only and have no legal
			or contractual effect. Except as otherwise provided herein, these Terms
			are intended solely for the benefit of the parties and are not intended to
			confer third-party beneficiary rights upon any other person or entity. You
			agree that communications and transactions between us may be conducted
			electronically. Under California Civil Code Section 1789.3, California
			consumers are entitled to the following specific consumer rights notice:
			The Complaint Assistance Unit of the Division of Consumer Site of the
			California Department of Consumer Affairs may be contacted in writing at
			1625 N. Market Blvd., Suite N-112, Sacramento, California 95834, or by
			telephone at 1 (800) 952-5210.
		</p>
	);
}

export default Miscellaneous;
