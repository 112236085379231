import SubmitButtonsField from '../Buttons/SubmitButtonsField';

function RenewSubscriptionModal({
	OnCancel,
	Onsubmit,
	title,
	isLoading,
    subscriptionsData
}) {
	return (
		<div>
			<div className='stats-container'>
				<div className='header-bar'>
					<span className='text-left w-100 d-flex align-items-center justify-content-between'>
						<h3 className='HeadlineThreeBold'>Plan Details</h3>
					</span>
				</div>

				<div
					className='graph-container d-flex justify-content-between mb-4'
					style={{ padding: '18px', gap: '5rem' }}
				>
					<div
						className='d-flex flex-column'
					>
						<div className='d-flex flex-column' style={{ gap: '0.7em' }}>
							<div
								className='d-flex align-items-start'
								style={{ textAlign: 'start' }}
							>
								<span className='BodyTwoLight' style={{ color: '#696F6C' }}>
									Plan Name
								</span>
							</div>
							<div className='d-flex align-items-start'>
								<span className='BodyTwoLight'>
									{subscriptionsData?.stripe_product?.name}
								</span>
							</div>
						</div>
					</div>
					<div className='d-flex flex-column' style={{ gap: '0.7em' }}>
						<div className='d-flex align-items-start' style={{ textAlign: 'start' }}>
							<span className='BodyTwoLight' style={{ color: '#696F6C' }}>
								Number of Users
							</span>
						</div>
						<div className='d-flex align-items-start'>
							<span className='BodyTwoLight'>{subscriptionsData?.quantity}</span>
						</div>
					</div>
					<div className='d-flex flex-column' style={{ gap: '0.7em' }}>

						<div className='d-flex align-items-start' style={{ textAlign: 'start' }}>
							<span className='BodyTwoLight' style={{ color: '#696F6C' }}>
								Total Cost for this Billing Period
							</span>
						</div>
						<div className='d-flex align-items-start'>
							<span className='BodyTwoLight'>
								$
								{subscriptionsData?.stripe_pricing?.price_per_unit *
									subscriptionsData?.quantity}
							</span>
						</div>
					</div>
				</div>
				<SubmitButtonsField
					rootClass='bg-white p-3'
					title={title}
					onCancel={OnCancel}
					onSubmit={Onsubmit}
					isLoading={isLoading}
				/>
			</div>
		</div>
	);
}

export default RenewSubscriptionModal;
