import { useState } from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { ReactComponent as More } from '../../assets/icons/Primarymore.svg';

function WorkflowMoreButton({
  ClassName,
  onDuplicate,
  onEditWorkflow,
  onDeactivate,
  onReactivate,
  onArchieved,
  onRetrieved,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const Archieved = () => {
    handleClose();
    onArchieved();
  };

  const Retreieved = () => {
    handleClose();
    onRetrieved();
  };

  const Duplicate = () => {
    handleClose();
    onDuplicate();
  };

  const editWorkflow = () => {
    handleClose();
    onEditWorkflow();
  };

  const deactivate = () => {
    handleClose();
    onDeactivate();
  };

  const reactivate = () => {
    handleClose();
    onReactivate();
  };

  return (
    <>
      <IconButton
        style={{
          padding: 0,
          margin: 0,
        }}
        className={ClassName}
        id='basic-button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <More />
      </IconButton>

      <Menu
        elevation={0}
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          '& .MuiMenu-list': {},
          '& .MuiMenu-paper': {
            boxShadow: ' 0px 1px 3px -1px rgba(149, 157, 165, 0.9)',
          },
        }}
      >
        <div
          style={{
            backgroundColor: 'white',
            width: '150px',
            borderRadius: '3px',
            height: '100%',
          }}
        >
          {onDuplicate && (
            <MenuItem onClick={Duplicate}>
              <h3 className='BodyTwoLight'>Duplicate</h3>
            </MenuItem>
          )}

          {onEditWorkflow && (
            <MenuItem onClick={editWorkflow}>
              <h3 className='BodyTwoLight'>Edit Workflow</h3>
            </MenuItem>
          )}

          {onDeactivate && (
            <MenuItem onClick={deactivate}>
              <h3 className='BodyTwoLight'>Deactivate</h3>
            </MenuItem>
          )}

          {onReactivate && (
            <MenuItem onClick={reactivate}>
              <h3 className='BodyTwoLight'>Reactivate</h3>
            </MenuItem>
          )}

          {onArchieved && (
            <MenuItem onClick={Archieved}>
              <h3 className='BodyTwoLight'>Archive</h3>
            </MenuItem>
          )}

          {onRetrieved && (
            <MenuItem onClick={Retreieved}>
              <h3 className='BodyTwoLight'>Unarchive</h3>
            </MenuItem>
          )}
        </div>
      </Menu>
    </>
  );
}

export default WorkflowMoreButton;
