import { memo, useEffect, useState } from 'react';

// Material Ui Component
import CloseIcon from '@mui/icons-material/Close';
import { CircularProgress, IconButton } from '@mui/material';
import ClientSectionHeader from '../../Others/ClientSectionHeader';
import LightSearchInput from '../../Inputs/LightSearchInput';
import { useParams } from 'react-router-dom';
// Redux
import { useSelector, useDispatch } from 'react-redux';
import * as builderActions from '../../../redux/actions/workflow-builder-actions';
import useModal from '../../Modal/useModal';
import ExpandableWorkflowBox from '../../sub_components/ExpandableWorkflowBox';
import { REPEAT_TYPE } from '../../../utilities/utilities';
import moment from 'moment';
import { ReactComponent as Plus } from '../../../assets/icons/moreIcon.svg';
import { defaultTemplate } from '../../../utilities/BuilderUtils';
import { useHistory } from 'react-router-dom';
import WorkflowMoreButton from '../../Buttons/WorkflowMoreButton';
import WorkflowBrowserTabsBar from '../../sub_components/WorkflowBrowserTabsBar';
import ExpandDeactivatedWorkflowBox from '../ExpandDeactivatedWorkflowBox';

function WorkflowModal({ onClose }) {
	const [searchValue, setSearchValue] = useState('');
	const [activeTab, setActiveTab] = useState(1);

	const ChangeActiveTab = (e) => {
		setActiveTab(parseInt(e.target.id));
	};
	const history = useHistory();

	const client = useSelector((state) => state.clientReducer.client);
	const loading = useSelector((state) => state.WorkflowBuilder.loading);

	const teamMembers = useSelector((state) => state.teamReducer.teamMembers);

	const activeWorkflows = useSelector(
		(state) => state.WorkflowBuilder.activeWorkflows,
	);
	console.log(activeWorkflows, 'active workflows')

	const searchHandler = (event) => {
		const { value } = event.target;
		setSearchValue(value);
	};

	const dispatch = useDispatch();

	const workflowsToManage = useSelector(
		(state) => {
			console.log(state)
			return state.WorkflowBuilder.workflowsToManage
		}
	);

	console.log(workflowsToManage, "Workflow To Manage")
	const { id } = useParams();

	useEffect(() => {
		dispatch(builderActions.getWorkflowsList(client?.id ? client.id : id));
		dispatch(
			builderActions.getActiveWorkflowsList(client?.id ? client.id : id),
		);
	}, [dispatch, id, client]);

	const { toggleModal } = useModal();

	const handleEditClick = (workflow) => {
		toggleModal();
		dispatch(
			builderActions.setWorkflowData({
				id: workflow.id,
				start_date: moment(),
				workflowTasks: workflow.task_templates,
				repeat_interval: 1,
				repeat_type: REPEAT_TYPE.MONTHLY,
			}),
		);
	};

	const openWorkflowEditor = () => {
		toggleModal();
		dispatch(builderActions.setSelectedWorkflow(defaultTemplate));
		history.push(`/workflow/edit/${id}`);
	};

	const templateEdit = (workflow) => {
		dispatch(builderActions.getClientsForWorkflow(workflow.workflow_id));
		dispatch(builderActions.setWorkflow(workflow));
		history.push(`/workflow/edit/${id}`);
	};

	const deActivateWorkflow = (clientWorkflowId) => {
		dispatch(builderActions.deActivateWorkflow(clientWorkflowId, id));
	};

	return (
		<div className='ModalRoot fadeIn'>
			<div className='WorkflowModal'>
				<div className='Header w-100'>
					<ClientSectionHeader title='Workflow Browser' border={false} />
					<IconButton className='CloseButton transparent' onClick={onClose}>
						<CloseIcon sx={{ color: '#333' }} />
					</IconButton>
				</div>

				<div className='workflowModalContent'>
					<div className='workflowModalBox'>
						<h4 className='BodyOneEmphasizedB'>Browse Templates</h4>
						<div className='workflow-list'>
							<div
								className='workflow-list-item'
								style={{ padding: 0, width: '15%', minWidth: '200px' }}
							>
								<LightSearchInput
									OnChange={searchHandler}
									placeHolder='Search Workflows'
									value={searchValue}
								/>
							</div>
						</div>
					</div>
					<div>
						<WorkflowBrowserTabsBar
							ChangeActiveTab={ChangeActiveTab}
							activeTab={activeTab}
						/>
					</div>
					<div style={{ display: activeTab !== 1 && 'none' }}>
						<div className='workflowModalBox pb-4'>
							<h4 className='BodyOneEmphasizedB'>Active Workflows</h4>
							<div className='workflow-list'>
								{activeWorkflows?.filter((value) => value.is_active)?.length >
									0 ? (
									activeWorkflows
										?.filter((value) => value.is_active)
										?.filter((value) => {
											if (searchValue === '') {
												return value;
											} else if (
												value.workflow.name
													.toLowerCase()
													.includes(searchValue.toLowerCase())
											) {
												return value;
											}
										})
										?.map((workflow, i) => {
											return (
												<div key={i} className='workflow-list-item'>
													<span className='body-1'>
														{workflow?.workflow_submission?.data[0]?.displayName}
													</span>
													<WorkflowMoreButton
														onDeactivate={() => {
															deActivateWorkflow(workflow.id); //Client Workflow ID
														}}
													/>
												</div>
											);
										})
								) : (
									<div
										style={{
											border: '1px solid #EAEAEA',
											width: '100%',
											paddingBlock: '15px',
										}}
										className='BodyTwoLight d-flex align-items-center justify-content-center'
									>
										<span style={{ color: '#B4B4B4' }}>
											This client has no active workflow
										</span>
									</div>
								)}
							</div>
						</div>
						{console.info('activeWorkflows', activeWorkflows)}
						<div className='workflowModalBox pb-4'>
							<h4 className='BodyOneEmphasizedB'>Deactivated Templates</h4>
							<div className='workflow-list'>
								{activeWorkflows?.filter((workflow) => !workflow.is_active)
									?.length > 0 ? (
									activeWorkflows
										?.filter((workflow) => !workflow.is_active)
										?.filter((value) => {
											if (searchValue === '') {
												return value;
											} else if (
												value.name
													.toLowerCase()
													.includes(searchValue.toLowerCase())
											) {
												return value;
											}
										})
										?.map((workflow, i) => {
											const workflowData =
												workflow?.workflow_submission?.data[0];
											return (
												<ExpandDeactivatedWorkflowBox
													key={i}
													{...workflowData}
													openBuilder={(data) => templateEdit(data)}
													isDraggable={false}
													workflowTasks={workflow.client_workflow_tasks}
													users={teamMembers?.map((member) => {
														return {
															...member,
															full_name: member.name,
															id: member.user_id,
														};
													})}
													clientWorkflow={workflow}
												/>
											);
										})
								) : (
									<div
										style={{
											border: '1px solid #EAEAEA',
											width: '100%',
											paddingBlock: '15px',
										}}
										className='BodyTwoLight d-flex align-items-center justify-content-center'
									>
										<span style={{ color: '#B4B4B4' }}>
											This client has no deactivated workflow
										</span>
									</div>
								)}
							</div>
						</div>

						<div className='workflowModalBox pb-4'>
							<h4 className='BodyOneEmphasizedB'>Custom Templates</h4>
							<div className='workflow-list'>
								{workflowsToManage?.filter(
									(workflow) => workflow.tenant_id !== null,
								)?.length > 0 ? (
									workflowsToManage
										?.filter((workflow) => workflow.tenant_id !== null)
										?.filter((value) => {
											if (searchValue === '') {
												return value;
											} else if (
												value.name
													.toLowerCase()
													.includes(searchValue.toLowerCase())
											) {
												return value;
											}
										})
										?.map((workflow, i) => {
											return (
												<ExpandableWorkflowBox
													key={i}
													name={workflow?.name}
													tasks={workflow?.task_templates}
													edit={true}
													onEdit={() => handleEditClick(workflow)}
													workflow={workflow}
													clientId={client?.id ? client.id : id}
													onClose={onClose}
													isDraggable={false}
												/>
											);
										})
								) : (
									<div
										style={{
											border: '1px solid #EAEAEA',
											width: '100%',
											paddingBlock: '15px',
										}}
										className='BodyTwoLight d-flex align-items-center justify-content-center'
									>
										<span style={{ color: '#B4B4B4' }}>
											This client has no custom workflow templates
										</span>
									</div>
								)}
							</div>
						</div>

						<div className='workflowModalBox pb-4'>
							<h4 className='BodyOneEmphasizedB'>Standard Templates</h4>
							<div className='workflow-list'>
								{workflowsToManage?.filter(
									(workflow) => workflow.tenant_id === null,
								)?.length > 0 ? (
									workflowsToManage
										?.filter((workflow) => workflow.tenant_id === null)
										?.filter((value) => {
											if (searchValue === '') {
												return value;
											} else if (
												value.name
													.toLowerCase()
													.includes(searchValue.toLowerCase())
											) {
												return value;
											}
										})
										?.map((workflow, i) => {
											const duplicateWorkflow =
												workflow?.workflow_builder?.data[0];
											delete workflow._id;
											delete workflow.version;
											duplicateWorkflow.duplicate = true;
											duplicateWorkflow.duplicateWorkflowId = workflow?.id;

											return (
												<ExpandableWorkflowBox
													key={i}
													name={workflow?.name}
													tasks={workflow?.task_templates}
													edit={true}
													onEdit={() => handleEditClick(workflow)}
													workflow={workflow}
													clientId={client?.id ? client.id : id}
													onClose={onClose}
													isDraggable={false}
												/>
											);
										})
								) : (
									<div
										style={{
											border: '1px solid #EAEAEA',
											width: '100%',
											paddingBlock: '15px',
										}}
										className='BodyTwoLight d-flex align-items-center justify-content-center'
									>
										<span style={{ color: '#B4B4B4' }}>
											This client has no workflow templates
										</span>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>

				{
					loading &&
					<div
						className='d-flex justify-content-center align-items-center position-absolute' style={{ width: '100%', height: '100%' }}>
						<CircularProgress
							style={{
								color: '#fa7b03',
								height: '50px',
								width: '50px',
							}}
						/>
					</div>
				}

			</div>
		</div>
	);
}
export default memo(WorkflowModal);
