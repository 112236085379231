import { useMemo, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { instance } from '../axios/axios-config';
import { getCookie } from '../utilities/utilities';
import CircularProgress from '@mui/material/CircularProgress';
function PublicRoute({ ...rest }) {
	const [authentication, setAuthentication] = useState({
		loading: true,
		isLoggedIn: false,
	});
	useMemo(async () => {
		try {
			const request = await instance.request({
				url: 'v1/auth/refresh',
				method: 'Post',
				data: {
					refresh_token: getCookie('refresh_token'),
				},
			});
			setAuthentication({
				loading: false,
				isLoggedIn: true,
				response: request.data,
			});
		} catch {
			localStorage.removeItem('active_task_id');
			setAuthentication({
				loading: false,
				isLoggedIn: false,
			});
		}
	}, []);
	if (authentication.loading) {
		return (
			<div
				style={{
					height: '100vh',
					width: '100vw',
				}}
				className=' d-flex justify-content-center align-items-center'
			>
				<CircularProgress
					style={{
						color: '#FA7B03',
						height: '50px',
						width: '50px',
						// marginTop: '47vh',
					}}
				/>
			</div>
		);
	} else if(authentication?.isLoggedIn){
        return <Redirect to={'/workspace'} />;
    }else {
		return <Route {...rest} />;
	}
}

export default PublicRoute;
