import FormButtonOne from '../components/Buttons/FormButtonOne';
import { ReactComponent as ServerDown } from '../assets/icons/Server Down.svg';
import { Modal, Snackbar } from '@mui/material';
import ErrorReportModal from '../components/Modal/ErrorReportModal';
import { useState } from 'react';
import * as workspaceActions from '../redux/actions/dashboard/workspaces-actions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

function ErrorFound() {
	const dispatch = useDispatch();
	const history = useHistory();
	const snackBar = useSelector((state) => state.workSpaceReducer.snackBar);

	const [isModalOpen, setIsModalOpen] = useState(false);
	const toggleModal = () => {
		setIsModalOpen(!isModalOpen);
	};
	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		dispatch(workspaceActions.setSnackBarIsOpen(false, false, ''));
	};

	return (
		<div className='ErrorFound'>
			<div className='error-found-content-container'>
				<div>
					<ServerDown />
				</div>

				<div className='d-flex flex-column align-items-center justify-content-center'>
					<h4>Something went wrong!</h4>
					<p>Please try again or report an issue to support</p>
				</div>

				<div className='w-100 d-flex justify-content-around'>
					<FormButtonOne
						type='button'
						onClick={() => {
							localStorage.removeItem('WORKSPACE_DATE');
                            localStorage.removeItem('workspace_applied_filters');
							history.push('/workspace');
							window.location.reload();
						}}
					>
						Try again
					</FormButtonOne>

					{/* <FormButtonOne
						onClick={toggleModal}
						style={{
							boxShadow: '0px 8px 24px rgba(149, 157, 165, 0.2)',
						}}
						type='button'
						className='secondary-btn-light'
					>
						Report an issue{' '}
					</FormButtonOne> */}
				</div>
			</div>

			<Modal
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
				open={isModalOpen}
			>
				<ErrorReportModal closeModal={toggleModal} />
			</Modal>

			<Snackbar
				open={snackBar?.IsOpen}
				autoHideDuration={2500}
				onClose={handleClose}
			>
				<div className='BodyTwoLight SnackBarAlert d-flex justify-content-center align-items-center'>
					{snackBar?.message}
				</div>
			</Snackbar>
		</div>
	);
}

export default ErrorFound;
