import { secure_instance } from '../../axios/axios-config';
import { arrayMove } from '../../utilities/utilities';
import * as clientActions from '../actions/client-actions';
export const FORM_VALUE_CHANGE_HANLDER = 'FORM_VALUE_CHANGE_HANLDER';
export const RESET_FORM_VALUES = 'RESET_FORM_VALUES';
export const SET_ERROR = 'SET_ERROR';
export const TOGGLE_lOAD = 'TOGGLE_lOAD';
export const SET_ALL_ACCOUNTS = 'SET_ALL_ACCOUNTS';
export const CLIENT_SOFTWARE_CHANGE = 'CLIENT_SOFTWARE_CHANGE';
export const SET_EDIT_FORM = 'SET_EDIT_FORM';
export const SET_FORM = 'SET_FORM';
export const RESET_FORMS = 'RESET_FORMS';
export const DELETE_SECURITY_QUESTION = 'DELETE_SECURITY_QUESTION';
export const OTHER_QUESTION_CHANGE_HANDLER = 'OTHER_QUESTION_CHANGE_HANDLER';
export const ADD_SECURITY_QUESTION = 'ADD_SECURITY_QUESTION';
export const CHANGE_CLIENT_ACCOUNTS_ARRAY_INDEX =
	'CHANGE_CLIENT_ACCOUNTS_ARRAY_INDEX';
export const RESET_ACCOUNT_LIST = 'RESET_ACCOUNT_LIST';
export const SET_ALL_URLS = 'SET_ALL_URLS';

export const getAllUrls = () => {
	return async(dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/institution_urls/`,
				method: 'get',
			});
			dispatch({
				type: SET_ALL_URLS,
				payload: request.data,
			});
		} catch (e) {
			console.log(e.response);
		}
	};
};

export const formValueChangehandler = (form, name, value) => {
	return (dispatch) => {
		dispatch({
			type: FORM_VALUE_CHANGE_HANLDER,
			payload: { form: form, name: name, value: value },
		});
	};
};

export const resetFormValues = (form) => {
	return (dispatch) => {
		dispatch({
			type: RESET_FORM_VALUES,
			payload: { form: form },
		});
	};
};

export const resetForms = () => {
	return (dispatch) => {
		dispatch({
			type: RESET_FORMS,
		});
	};
};

export const toggleLoad = () => {
	return (dispatch) => {
		dispatch({
			type: TOGGLE_lOAD,
		});
	};
};

export const setError = (error) => {
	return (dispatch) => {
		dispatch({
			type: SET_ERROR,
			payload: error,
		});
	};
};

export const handleFormSubmit = (data, closeModal) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: '/v1/client_software_stack/',
				method: 'post',
				data,
			});
			dispatch(toggleLoad());
			closeModal();

			dispatch(getAllUrls());
			dispatch(getClientAccounts(data?.client_id));
		} catch (e) {
			dispatch(setError(e?.response?.data?.message));

			dispatch(toggleLoad());
		}
	};
};

export const updateAccountSoftwareList = (data, clientId, closeModal) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: SET_ALL_ACCOUNTS,
				payload: data,
			});

			dispatch(toggleLoad());
			closeModal();
		} catch (e) {
			dispatch(setError(e?.response?.data?.message));

			dispatch(toggleLoad());
		}
	};
};

export const updateAllAcocuntsList = (data, clientId, closeModal) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/client_software_stack/?client_id=${clientId}`,
				method: 'patch',
				data,
			});
			
			dispatch(getAllUrls());

			dispatch({
				type: SET_ALL_ACCOUNTS,
				payload: data,
			});

			dispatch(toggleLoad());
			closeModal();
		} catch (e) {
			//
			dispatch(toggleLoad());
		}
	};
};

export const getClientAccounts = (clientId) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/client_software_stack/?client_id=${clientId}`,
				method: 'get',
			});
			dispatch({
				type: SET_ALL_ACCOUNTS,
				payload: request.data,
			});
		} catch (e) {
			console.log(e.response);
		}
	};
};

export const clientSoftwareChange = (obj) => {
	return async (dispatch) => {
		dispatch({
			type: CLIENT_SOFTWARE_CHANGE,
			payload: obj,
		});
	};
};

export const updateClientAccounts = (software, clientId) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `v1/client_software_stack/${software.id}`,
				method: 'Patch',
				data: software,
			});

			dispatch(getClientAccounts(clientId));
		} catch (e) {
			//
		}
	};
};

export const deleteClientSoftware = (
	softwareId,
	clientId,
	setLoadingItems,
	loadingItems,
) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `v1/client_software_stack/${softwareId}`,
				method: 'delete',
			});

			dispatch(getClientAccounts(clientId));

			setLoadingItems &&
				setLoadingItems(loadingItems.filter((items) => items != softwareId));
		} catch (e) {
			//
			setLoadingItems &&
				setLoadingItems(loadingItems.filter((items) => items != softwareId));
		}
	};
};

export const setEditForm = (form) => {
	return (dispatch) => {
		dispatch({
			type: SET_EDIT_FORM,
			payload: form,
		});
	};
};

export const setForm = (form, type) => {
	return (dispatch) => {
		dispatch({
			type: SET_FORM,
			payload: { form: form, type: type },
		});
	};
};

export const changeClientAccountsIndex = (data) => {
	const updatedArrayData = arrayMove(
		data.account,
		data.beingDragged,
		data.dragTo,
	);
	const updatedIndexdArray = updatedArrayData?.map((acc, index) => ({
		...acc,
		order: index,
	}));
	return (dispatch) => {
		dispatch({
			type: CHANGE_CLIENT_ACCOUNTS_ARRAY_INDEX,
			payload: {
				objectBeingDragged: data.objectBeingDragged,
				updatedIndexdArray,
			},
		});
	};
};

export const addSecurityQuestion = (accountType) => {
	return (dispatch) => {
		dispatch({
			type: ADD_SECURITY_QUESTION,
			payload: accountType,
		});
	};
};

export const otherQuestionChangeHandler = (values) => {
	return (dispatch) => {
		dispatch({
			type: OTHER_QUESTION_CHANGE_HANDLER,
			payload: values,
		});
	};
};

export const removeQuestion = (values) => {
	return (dispatch) => {
		dispatch({
			type: DELETE_SECURITY_QUESTION,
			payload: values,
		});
	};
};

export const resetAccountsList = () => {
	return (dispatch) => {
		dispatch({
			type: RESET_ACCOUNT_LIST,
		});
	};
};