import React, { useEffect } from 'react';
import Image from '../assets/icons/user.svg';
import { ReactComponent as Logo } from '../assets/icons/Shape.svg';
import { ReactComponent as Arrow } from '../assets/icons/Group 105.svg';
import { ReactComponent as SlackLogo } from '../assets/icons/Group 107.svg';
import { ReactComponent as PlusIcon } from '../assets/icons/Group 108.svg';
import ButtonSquare from '../components/Buttons/ButtonSquare';
import { useDispatch, useSelector } from 'react-redux';
import * as slackActions from '../redux/actions/slack-actions';
import * as userActions from '../redux/actions/user-management.actions';
import { Link } from 'react-router-dom';

export default function SlackIntegrationComponent(props) {
	const dispatch = useDispatch();

	const redirectUrl = useSelector(
		(state) => state.slackIntegrationReducer.redirectUrl,
	);
	const user = useSelector((state) => state.authReducer.user);
	const profile = useSelector((state) => state.userReducer.userProfile);


	const handleSlackAccess = () => {
		dispatch(slackActions.getSlackUrl());
	};

	useEffect(() => {
		if (redirectUrl !== null) {
			window.location.replace(`${redirectUrl}`);
		}
		dispatch(userActions.getUserProfile(user?.userId));
	}, [redirectUrl]);

	return (
		<div style={{ padding: '64px' }}>
			<div className='slack-integration'>
				<div className='d-flex w-100 justify-content-end'>
					<div className='d-flex flex-column pr-3'>
						<div className='d-flex pb-2 '>
							<span className='BodyTwoLight' style={{fontWeight:'600'}}>
								{' '}
								<b>Logged in as:</b>
							</span>
						</div>
						<div className='d-flex align-items-center'>
							<div>
								<img
									src={profile?.image_url || Image}
									alt='image'
									style={{ width: '35px', height: '35px', borderRadius: '50%', background:'white' }}
								/>
							</div>
							<div className='pl-2'>
								<span className='BodyTwoLight'>
									{profile?.full_name || 'Username'}
								</span>
							</div>
						</div>
					</div>
				</div>
				<div
					className='d-flex flex-column align-items-center justify-content-center'
					style={{ paddingTop: '7%' }}
				>
					<div className='d-flex align-items-center justify-content-center'>
						<div
							className='d-flex align-items-center justify-content-center'
							style={{
								width: '70px',
								height: '70px',
								borderRadius: '8px',
								backgroundColor: 'white',
								boxShadow: '0px 2px 10px -4px #CDDDD8',
							}}
						>
							<Logo style={{ width: '50px', height: '50px' }} />
						</div>
						<div style={{ padding: '35px' }}>
							{props.connection ? <PlusIcon /> : <Arrow />}
						</div>
						<div>
							<SlackLogo />
						</div>
					</div>
					<div className='w-50 pt-5 pr-5 pl-5'>
						{props.connection ? (
							<span className='HeadlineTwoEmphasized'>
								{"You've successfully connected Levvy to Slack"}
							</span>
						) : (
							<span className='BodyTwoLight'>
								Levvy Slack Integration would like your permission to access
								your account {profile?.email || 'johnsmith@xyz.com'}. This
								allows Levvy Slack Integration to read, but not modify, files
								you have access to as well as read your name, email and profile
								image.
							</span>
						)}
					</div>
					<div style={{ paddingTop: '50px' }}>
						{props.connection ? (
							<div className='d-flex '>
								<div className='pr-5'>
									<ButtonSquare className='full-width'>
										Open My Workspace
									</ButtonSquare>
								</div>
								<div className='pl-2'>
									<ButtonSquare className='full-width '>
										Open Slack
									</ButtonSquare>
								</div>
							</div>
						) : (
							<>
								<ButtonSquare
									className='full-width'
									onClick={handleSlackAccess}
								>
									Allow access
								</ButtonSquare>
								<div className='pt-3' style={{ cursor: 'pointer' }}>
									<Link
										className='BodyOneBold'
										to='/account-settings'
										style={{ color: '#000', textDecoration: 'none' }}
									>
										{"No, don't allow access"}
									</Link>
								</div>
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}
