import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SubmitButtonsField from '../../Buttons/SubmitButtonsField';
import { CircularProgress } from '@mui/material';
import * as clientActions from '../../../redux/actions/client-actions';
import * as userActions from '../../../redux/actions/user-management.actions';
import { Member } from '../../../utilities/utilities';
import DropDown from '../../Inputs/DropDown';
import { setSnackBarIsOpen } from '../../../redux/actions/dashboard/workspaces-actions';

function AccessFolder({ onCancel, item }) {
	const folderData = useSelector((state) => state.clientReducer.folderData);

	const [actionLoading, setActionLoading] = useState(false);
	const [selectedRoleId, setSelectedRoleId] = useState();
	let roles = useSelector((state) => state.userReducer.roles);
	const dispatch = useDispatch();
	roles = roles
		?.filter((role) => role.id !== Member.clientId)
		?.map((role) => {
			return {
				value: role.id,
				label: role.role_name,
			};
		})
		?.sort((a, b) => a.value - b.value);

	const handleAccessFolder = () => {
		setActionLoading(true);
		const data = {
			access_control: {
				readonly: false,
				role_level: parseInt(selectedRoleId),
			},
		};
		dispatch(clientActions.updateDocument(item.id, data))
			.then((response) => {
				setActionLoading(false);
				dispatch(
					clientActions.updateDocumentReducer(
						folderData?.map((folder) => {
							if (folder.id == response?.id) {
								return {
									...response,
								};
							} else return folder;
						}),
					),
				);
				onCancel();
				dispatch(setSnackBarIsOpen(true, true, 'Access updated succesfully'));
			})
			.catch(() => {
				dispatch(setSnackBarIsOpen(false, true, 'Error while updating access'));
				setActionLoading(false);
			});
	};

	const handleRoleChange = (selectedValue) => {
		setSelectedRoleId(selectedValue.target.value);
	};

	useMemo(() => {
		if (item?.access_control?.role_level) {
			setSelectedRoleId(item.access_control.role_level);
		}
	}, [item?.access_control?.role_level]);

	useEffect(() => {
		dispatch(userActions.getRoles());
	}, [dispatch]);

	return (
		<div>
			<form autoComplete='off'>
				<div
					className='p-3'
					style={{
						maxHeight: '75vh',
						height: 'fit-content',
						position: 'relative',
						overflowY: 'auto',
					}}
				>
					<div className='improved-input-designs pb-3'>
						<label className='require'>Company Access</label>
						<DropDown
							onChange={handleRoleChange}
							name='type'
							disableNull
							options={roles}
							style={{ height: '32px' }}
							value={{ value: selectedRoleId }}
						/>
					</div>
				</div>

				<div className='VerticalSpacer bg-white p-3 rounded-bottom'>
					<SubmitButtonsField child={true}>
						<button
							id='Element'
							type='button'
							className='secondary'
							style={{ borderRadius: '50px', width: '125px' }}
							onClick={onCancel}
						>
							Cancel
						</button>

						<button
							type='submit'
							className='d-flex align-items-center'
							style={{ borderRadius: '50px', width: '125px' }}
							id='Element'
							disabled={actionLoading}
							data-cy='add-client-btn'
							onClick={handleAccessFolder}
						>
							{actionLoading ? (
								<CircularProgress size={24} sx={{ color: 'white' }} disableShrink />
							) : (
								'Save'
							)}
						</button>
					</SubmitButtonsField>
				</div>
			</form>
		</div>
	);
}

export default AccessFolder;
