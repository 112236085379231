/* eslint-disable no-mixed-spaces-and-tabs */
import { useState, useEffect, useMemo } from 'react';
// import EditableTextArea from '../Inputs/EditableTextArea';
import { useDispatch, useSelector } from 'react-redux';
import * as clientActions from '../../redux/actions/client-actions';
// import EditableText from '../Inputs/EditableText';
import {
	objectBeingDragged,
	standardPermissions,
	truncateString,
} from '../../utilities/utilities';
import TableHeader from '../Others/TableHeader';
import TableHeaderItem from '../Others/TableHeaderItem';
import {
	TableItemWidth1,
	TableItemWidth3,
} from '../TableSpacing/TableSpacingStyles';
import TableRowItem from '../Others/TableRowItem';
import TableRowButton from '../Others/TableRowButton';
import { ReactComponent as TrashIcon } from '../../assets/icons/Trash-Light.svg';
import AddIcon from '@mui/icons-material/Add';
import { Modal } from '@mui/material';
import DummyModal from '../Modal/DummyModal';
import useModal from '../Modal/useModal';
import AddLink from './Modals/AddLink';
import ReactHtmlParser from 'react-html-parser';
import { ReactComponent as EditIcon } from '../../assets/icons/PencilEdit.svg';
import { Tooltip } from '@mui/material';
import ConfirmModal from '../Modal/ConfirmModal';
import NotesModal from './Modals/NotesModal';
import { ReactComponent as Notes } from '../../assets/icons/client-notes.svg';
import Restricted from '../Permissions/Restricted';

function ClientNotes() {
	console.info('now ClientNotes');

	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(false);
	const [sharedNotes, setSharedNotes] = useState(null);
	const client = useSelector((state) => state.clientReducer.client);
	const { modalIsOpen, toggleModal, ModalName, ModalType } = useModal();
	const userTeamRole = useSelector((state) => state.clientReducer.userTeamRole);

	const [indexToDelete, setIndexToDelete] = useState(null);
	const { dragValues } = useSelector((state) => state.clientReducer);

	const removeItem = (index) => {
		ModalType('CONFIRM_MODAL');
		toggleModal();
		setIndexToDelete(index);
	};

	const removeLink = () => {
		setIsLoading(true);
		const linkId = client?.links?.find(
			(c, index) => index === indexToDelete,
		)?.id;

		dispatch(clientActions.removeClientSharedLink(linkId, indexToDelete)).then(
			() => {
				setIsLoading(false);
				toggleModal();
			},
		);
	};

	useEffect(() => {
		if (
			dragValues.beingDragged !== null &&
			dragValues.dragTo !== null &&
			dragValues.beingDragged !== dragValues.dragTo &&
			dragValues.objectBeingDragged === objectBeingDragged.CLIENT_LINKS
		) {
			dispatch(
				clientActions.changeClientLinksIndex({
					links: client?.links,
					beingDragged: dragValues.beingDragged,
					dragTo: dragValues.dragTo,
				}),
			);
		}
	}, [dragValues]);

	const toggleLoader = (state) => {
		setIsLoading(state);
	};

	const clientSharedNotes = useMemo(() => {
		return client?.notes;
	}, [client?.notes]);

	const clientUpdateSuccess = () => {
		toggleModal();
		toggleLoader();
	};

	const clientUpdateFailed = () => {
		toggleLoader();
	};

	const handleSubmit = (notes) => {
		toggleLoader(true);

		const payload = { ...client };
		delete payload.client_contact_list;
		delete payload.client_office_locations;
		delete payload.links;
		delete payload.client_software_stacks;
		delete payload.team_client_permissions[0].team;

		dispatch(
			clientActions.updateClient(
				{
					...payload,
					notes: notes,
				},
				{ clientUpdateSuccess, clientUpdateFailed },
			),
		);
	};

	return (
		<section className='client-profile' style={{ gap: '16px' }}>
			<div className='text-left w-100 d-flex align-items-center justify-content-between information-header'>
				<span className='d-flex' style={{ gap: '12px' }}>
					<Notes />
					<h3 className='HeadlineThreeBold'>Notes</h3>
				</span>

				<Restricted
					AllowedUsers={standardPermissions}
					userTeamRole={[userTeamRole]}
				>
					<Tooltip title='Edit Notes'>
						<EditIcon
							style={{
								color: '#fb7a03',
								cursor: 'pointer',
							}}
							onClick={() => {
								ModalType('EDIT_NOTES_MODAL');
								toggleModal();
							}}
						/>
					</Tooltip>
				</Restricted>
			</div>

			<div className='Profile-Content'>
				<div
					className='d-flex flex-column w-100 '
					style={{ gap: '1rem', minHeight: 'fit-content' }}
				>
					<div
						className='d-flex flex-column justify-content-between p-3 information-content'
						// style={{ background: '#EEF2F3'}}
					>
						{client?.notes ? (
							<p className='BodyTwoLight text-left'>
								{ReactHtmlParser(client?.notes)}
							</p>
						) : (
							<p className='BodyTwoLight text-left light-text'>
								Write your notes ...
							</p>
						)}
					</div>
				</div>

				{modalIsOpen && (
					<Modal
						onClose={toggleModal}
						open={modalIsOpen}
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<>
							{ModalName == 'CONFIRM_MODAL' && (
								<DummyModal title={'Delete Link'} onClose={toggleModal}>
									<ConfirmModal
										description={
											'You’re about to delete this link. Are you sure to want to continue?'
										}
										OnCancel={() => toggleModal()}
										Onsubmit={() => {
											removeLink();
										}}
										descriptionClassName='text-left'
										isLoading={isLoading}
									/>
								</DummyModal>
							)}
							{ModalName == 'NEW_LINKS_MODAL' && (
								<DummyModal title='Add Link' onClose={toggleModal}>
									<AddLink onCancel={toggleModal} />
								</DummyModal>
							)}
							{ModalName == 'EDIT_LINKS_MODAL' && (
								<DummyModal title='Edit Link' onClose={toggleModal}>
									<AddLink onCancel={toggleModal} beingEdited={true} />
								</DummyModal>
							)}

							{ModalName == 'EDIT_NOTES_MODAL' && (
								<DummyModal
									title={'Edit Notes'}
									onClose={toggleModal}
									style={{ width: '900px' }}
									hideCrossIcon
								>
									<NotesModal
										edit={true}
										initialNotes={clientSharedNotes}
										isLoading={isLoading}
										updateChanges={handleSubmit}
										onCancel={toggleModal}
									/>
								</DummyModal>
							)}
							{ModalName == 'VIEW_NOTES_MODAL' && (
								<DummyModal title='Notes' onClose={toggleModal}>
									<NotesModal
										edit={false}
										onCancel={toggleModal}
										initialNotes={sharedNotes}
									/>
								</DummyModal>
							)}
						</>
					</Modal>
				)}
			</div>
		</section>
	);
}

export default ClientNotes;
