import { secure_instance } from '../../axios/axios-config';
import {
	getQueryForFilters,
	graphTypes,
	graphVariations,
	varRelations,
} from '../../utilities/cubeUtils';
import _ from 'lodash';

export const GET_GRAPH_DATA = '[CUBE] GET_GRAPH_DATA';
export const GET_TOOLTIP_DATA = '[CUBE] GET_TOOLTIP_DATA';
export const APPLY_MULTI_SELECT_FILTERS = '[CUBE] APPLY_MULTI_SELECT_FILTERS';
export const GET_DATA_WITH_GROUP_BY = '[CUBE] GET_DATA_WITH_GROUP_BY';
export const SET_GRAPH_QUERY = '[CUBE] SET_GRAPH_QUERY';
export const SET_DATE_RANGE = '[CUBE] SET_DATE_RANGE';
export const SET_TIME = '[CUBE] SET_TIME';
export const SET_TOOLTIP_QUERY = '[CUBE] SET_TOOLTIP_QUERY';
export const SET_LOADING = '[CUBE] SET_LOADING';
export const APPLY_SINGLE_VALUE_FILTER = '[CUBE] APPLY_SINGLE_VALUE_FILTER';
export const GET_FILTERS_DATA = '[CUBE] GET_FILTERS_DATA';
export const RESET_ALL_FILTERS = '[CUBE] RESET_ALL_FILTERS';
export const RESET_DATE_RANGE = '[CUBE] RESET_DATE_RANGE';
export const CLEAR_SINGLE_VALUE_FILTER = '[CUBE] CLEAR_SINGLE_VALUE_FILTER';
export const CLEAR_SINGLE_VALUE_WORKFLOW_FILTER = '[CUBE] CLEAR_SINGLE_VALUE_WORKFLOW_FILTER';
export const CLEAR_ALL_WORKFLOW_FILTER = '[CUBE] CLEAR_ALL_WORKFLOW_FILTER';


export const setLoading = (data) => {
	return (dispatch) => {
		dispatch({
			type: SET_LOADING,
			payload: data,
		});
	};
};
export const getTooltipData = (data) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: SET_LOADING,
				payload: {
					type: 'tooltip',
					value: true,
					graphVariation: data.graphVariation,
				},
			});
			const filtersArray = [...data?.query?.filters];

			Object.entries(data?.filters)?.map((item) => {
				if (item[1]?.length > 0) {
					filtersArray.push({
						member: `${data.mainVariable}.${item[0]}`,
						operator: 'equals',
						values: item[1],
					});
				}
			});
			const query = { ...data.query, filters: filtersArray };
			const mainQuery = {
				...query,
				timeDimensions: query.timeDimensions.map((item) => {
					return {
						...item,
						dateRange: [data.dateRange.from, data.dateRange.to],
					};
				}),
			};

			const resultSet = await secure_instance.request({
				url: `v1/cube/v1/load/?query=${JSON.stringify(mainQuery)}`,
				method: 'GET',
			});

			if (resultSet?.data?.error) {
				dispatch(getTooltipData(data));
				return;
			} else {

				const results = resultSet?.data?.data;
				const dateRange = resultSet?.data?.query?.timeDimensions[0]?.dateRange;

				const dynamicVar = `${data.mainVariable}.${varRelations[data.graphType]}`;
				const profitByPercent = data.graphVariation === graphVariations.PROFITABILITY_BY_PERCENTAGE_GRAPH ? true : false;
				const mainVariable = `${data.mainVariable}`;
				const shouldIncludeSubcategory = !(
					data.graphType === graphTypes.groupByClients ||
					data.graphType === graphTypes.groupByNone
				);
				const countVar = varRelations.countVariable[data.graphVariation];
				const worker = new window.Worker('./cube-worker.js');

				worker.postMessage({
					message: 'REARRANGE_TOOLTIP_DATA',
					data: {
						results,
						dynamicVar,
						mainVariable: mainVariable,
						countVar,
						shouldIncludeSubcategory,
						graphType: profitByPercent,
						dateRange: dateRange
					},
				});
				worker.onerror = (err) => {
					console.log('~web worker error', err);
				};
				worker.onmessage = (e) => {
					const { message, result, error } = e.data;
					if (!error && message === 'REARRANGE_TOOLTIP_DATA_RESULT') {
						dispatch({
							type: GET_TOOLTIP_DATA,
							payload: {
								tooltipData: result || {},
								graphVariation: data.graphVariation,
							},
						});
					}
					worker.terminate();
				};
			}

		} catch (error) {
			console.log('error', error);
		}
	};
};

export const getGraphData = (data) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: SET_LOADING,
				payload: {
					type: 'graph',
					value: true,
					graphVariation: data.graphVariation,
				},
			});
			const mainQuery = { ...data?.query };
			const filters = Object.entries(data?.filters)
				?.filter((item) => item[1]?.length > 0)
				?.map((item) => {
					return {
						member: `${data?.mainVariable}.${item[0]}`,
						operator: 'equals',
						values: item[1],
					};
				});

			const query = {
				...mainQuery,
				filters: filters,
				timeDimensions: mainQuery?.timeDimensions?.map((item) => {
					return {
						...item,
						dateRange: [data.dateRange.from, data.dateRange.to],
					};
				}),
			};
			const resultSet = await secure_instance.request({
				url: `v1/cube/v1/load/?query=${JSON.stringify(query)}`,
				method: 'GET',
			});

			if (resultSet?.data?.error) {
				dispatch(getGraphData(data));
				return;
			} else {
				const results = resultSet?.data?.data;

				// if (data.graphType === graphTypes.defaultGraph) {
				// 	const key=data.graphVariation===graphVariations.WORKFLOW_VS_PERCENTAGE?'Percentage':'Hours';
				// 	const {shapedArr}= shapeDefaultGroupByGraphData(results,data.mainVariable,key);
				// 	console.log('aaaaaa',shapedArr);
				// 	dispatch({
				// 		type: GET_GRAPH_DATA,
				// 		payload: {
				// 			data: shapedArr || [],
				// 			max: 110,
				// 			keys:[key],
				// 			graphVariation:data.graphVariation
				// 		},
				// 	});
				// }

				// else{
				const dynamicVariable =  varRelations[data.graphType]; 

				const dataToCalculate =
					data.graphVariation === graphVariations.WORKFLOW_VS_PERCENTAGE
						? 'completedWorkflows'
						: 'totalConsumeHours';

				const targetVaribale = data.graphVariation === graphVariations.WORKFLOW_VS_PERCENTAGE ? 'totalWorkflows' :'totalEstimatedHours';
				
				const worker = new window.Worker('./cube-worker.js');
				worker.postMessage({
					message: 'REARRANGE_GRAPH_DATA',
					data: {
						results,
						dynamicVar: `${data.mainVariable}.${dynamicVariable}`,
						mainVariable: `${data.mainVariable}`,
						countVar:  dataToCalculate,
						targetVaribale: targetVaribale,

					},
				});
				worker.onerror = (err) => {
					console.log('~web worker error', err);
				};
				worker.onmessage = (e) => {
					const { message, result, error } = e.data;
					if (!error && message === 'REARRANGE_GRAPH_DATA_RESULT') {
						const { data: shapedData, keys, max } = result;
						dispatch({
							type: GET_GRAPH_DATA,
							payload: {
								data: shapedData || [],
								max: max ? max + Math.ceil((max * 1) / 10) : 'auto', //calculating ~10% of maximum value
								keys: keys,
								graphVariation: data.graphVariation,
								graphType: data.graphType
							},
						});
					}
					worker.terminate();
				};
			}

			// const {data:shapedData,keys, max }=shapeGraphData(
			// 	results,
			// 	`${data.mainVariable}.${varRelations[data.graphType]}`,
			// 	`${data.mainVariable}`,
			// 	dataToCalculate,
			// 	targetVaribale);

			// dispatch({
			// 		type: GET_GRAPH_DATA,
			// 		payload: {
			// 			data: shapedData || [],
			// 			max: max ? max+ Math.ceil(max * 1 /10) : 'auto',
			// 			keys:keys,
			// 			graphVariation:data.graphVariation
			// 		},
			// 	});
			// }
		} catch (e) {
			console.log('error', e);
		}
	};
};

export const getCumulativeGraphData = (data) => {
	return async (dispatch) => {
		dispatch({
			type: SET_LOADING,
			payload: {
				type: 'graph',
				value: true,
				graphVariation: data.graphVariation,
			},
		});
		const mainQuery = { ...data.query };
		const filters = Object.entries(data?.filters)
			?.filter((item) => item[1]?.length > 0)
			?.map((item) => {
				return {
					member: `${data.mainVariable}.${item[0]}`,
					operator: 'equals',
					values: item[1],
				};
			});
		const query = {
			...mainQuery,
			filters: filters,
			timeDimensions: mainQuery.timeDimensions.map((item) => {
				return {
					...item,
					dateRange: [data.dateRange.from, data.dateRange.to],
				};
			}),
		};

		const resultSet = await secure_instance.request({
			url: `v1/cube/v1/load/?query=${JSON.stringify(query)}`,
			method: 'GET',
		});

		if (resultSet?.data?.error) {
			dispatch(getCumulativeGraphData(data));
			return;
		}
		const results = resultSet?.data?.data;

		const dateRange = resultSet?.data?.query?.timeDimensions[0]?.dateRange;
		const dynamicVariable =  varRelations[data.graphType]; 

		const profitByPercent = data.graphVariation === graphVariations.PROFITABILITY_BY_PERCENTAGE_GRAPH ? true : false;

		const worker = new window.Worker('./cube-worker.js');
		worker.postMessage({
			message: 'REARRANGE_CUMULATIVE_GRAPH_DATA',
			data: {
				results,
				mainVariable: `${data.mainVariable}`,
				dynamicVar: `${data.mainVariable}.${dynamicVariable}`,
				graphType: profitByPercent,
				dateRange: dateRange
			},
		});

		worker.onerror = (err) => {
			console.log('~web worker error', err);
		};
		worker.onmessage = (e) => {
			const { message, result, error } = e.data;
			if (!error && message === 'REARRANGE_CUMULATIVE_GRAPH_DATA_RESULT') {
				// const shapedArr=[...result];
				// shapedArr=shapedArr?.map((item)=>{
				// 	return {...item,data:item.data.sort((a,b) => (a.x > b.x) ? 1 : ((b.x > a.x) ? -1 : 0))};
				// });

				dispatch({
					type: GET_GRAPH_DATA,
					payload: {
						data: result.data || [],
						min: result.min,
						keys: result.keys,
						graphVariation: data.graphVariation,
					},
				});
			}
			worker.terminate();
		};
		// const shapedArr=shapeDataForAreaGraph(results,
		// 	data.mainVariable,
		// 	`${data.mainVariable}.${varRelations[data.graphType]}`,
		// 	variableToCount);
		// shapedArr=shapedArr?.map((item)=>{
		// 	return {...item,data:item.data.sort((a,b) => (a.x > b.x) ? 1 : ((b.x > a.x) ? -1 : 0))};
		// });
		// dispatch({
		// 	type: GET_GRAPH_DATA,
		// 	payload: {
		// 		data: shapedArr || [],
		// 		max: 110,
		// 		keys:[],
		// 		graphVariation:data.graphVariation
		// 	},
		// });
	};
};

export const applyFilter = (data) => {
	return (dispatch) => {
		dispatch({
			type: APPLY_MULTI_SELECT_FILTERS,
			payload: data,
		});
	};
};

export const applySingleValueFilters = (data) => {
	return (dispatch) => {
		dispatch({
			type: APPLY_SINGLE_VALUE_FILTER,
			payload: data,
		});
	};
};
export const clearSingleValueFilter = (data) => {
	return (dispatch) => {
		dispatch({
			type: CLEAR_SINGLE_VALUE_FILTER,
			payload: data,
		});
	};
};

export const clearSingleValueWorkflowFilter = (data) => {
	return (dispatch) => {
		dispatch({
			type: CLEAR_SINGLE_VALUE_WORKFLOW_FILTER,
			payload: data,
		});
	};
};
export const clearAllWorkflowValueFilter = (data) => {
	return (dispatch) => {
		dispatch({
			type: CLEAR_ALL_WORKFLOW_FILTER,
			payload: data,
		});
	};
};
// export const setTooltipQuery = (data) =>{
// 	return (dispatch) => {
// 		dispatch({
// 			type:SET_TOOLTIP_QUERY,
// 			payload:{tooltipQuery:data.query,graphVariation:data.graphVariation}

// 		});
// 	};
// };

export const getFiltersData = (data) => {
	return async (dispatch) => {
		const query = getQueryForFilters(data);

		if(query?.dimensions){
			const getData = await secure_instance.request({
				url: `v1/cube/v1/load/?query=${JSON.stringify(query)}`,
				method: 'GET',
			});
			if (getData?.data?.error) {
				dispatch(getFiltersData(data));
				return;
			} else {
				const result = getData?.data?.data;
	
				const resultsData = result?.map((item) => {
					const dynamicLabel = varRelations[data.filterFor].resultedVar;
					return {
						label: item[`${data.mainVariable}.${dynamicLabel}`],
						value: item[`${data.mainVariable}.${data.filterFor}`],
					};
				});
				dispatch({
					type: GET_FILTERS_DATA,
					payload: {
						data: resultsData || [],
						for: varRelations[data.filterFor].dataStoredVar,
						graphVariation: data.graphVariation,
					},
				});
			}
		}
		
	};
};

// export const setGraphQuery=(data)=>{
// 	const mainQuery=queries.graphQueries[data?.graphType];
// 	const filters=Object.entries(data.filters)?.filter(item=>(item[1]?.length>0))?.map((item)=>{
// 		return {
// 			member:`Requiredata.${item[0]}`,
// 			operator: 'equals',
// 			values:item[1]
// 		};
// 	});
// 	const query={...mainQuery,filters:filters,timeDimensions:mainQuery.timeDimensions.map(item=>{
// 		return {...item,granularity:data.granularity,dateRange:[
// 			data.dateRange.from,data.dateRange.to
// 		]};
// 	})};
// 	return (dispatch) => {
// 		dispatch(
// 			{
// 				type:SET_GRAPH_QUERY,
// 				payload:{query:query,graphType:data.graphType}
// 			}
// 		);
// 	};
// };

export const setDateRange = (data) => {
	return (dispatch) => {
		dispatch({
			type: SET_DATE_RANGE,
			payload: data,
		});
	};
};

export const setTime = (data) => {
	return (dispatch) => {
		dispatch({
			type: SET_TIME,
			payload: data,
		});
	};
};

export const resetAllFilters = (data) => {
	return (dispatch) => {
		dispatch({
			type: RESET_ALL_FILTERS,
			payload: data,
		});
	};
};


export const resetDateRange = (data) => {
	return (dispatch) => {
		dispatch({
			type: RESET_DATE_RANGE,
			payload: data,
		});
	};
};