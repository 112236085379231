import { useDispatch, useSelector } from 'react-redux';
import { Member } from '../../../utilities/utilities';
import PickList from '../../Inputs/PickList';
import SecondaryCard from '../../Others/SecondaryCard';
import TableHeader from '../../Others/TableHeader';
import TableHeaderItem from '../../Others/TableHeaderItem';
import TableRowItem from '../../Others/TableRowItem';
import {
    TableItemWidth2,
    TableItemWidth3,
    TableItemWidth5,
    TableItemWidth6,
} from '../../TableSpacing/TableSpacingStyles';
import * as userActions from '../../../redux/actions/user-management.actions';
import * as teamActions from '../../../redux/actions/team-management.actions';
import { useState } from 'react';
import { CircularProgress } from '@mui/material';

function ArchieveTeamUsers({ teamId }) {
    const dispatch = useDispatch();

    const [selectedArchive, setSelectedArchive] = useState([]);
    const teamMembers = useSelector((state) => state.teamReducer.teamMembers)
        ?.filter((teamMember) => teamMember.role_id !== Member.ownerId)
        ?.map((member) => {
            const teams = [];
            member?.roles
                ?.filter((item) => item.user_id == member.user_id)
                ?.map((item) => {
                    item?.role_scopes?.map((scope) => {
                        teams.push(scope.team_id);
                    });
                });
            return {
                ...member,
                teams: teams,
            };
        })
        ?.filter((member) => {
            return member?.teams?.length == 1;
        });

    const Teams = useSelector((state) => state.teamReducer.teams)?.filter(
        (item) => item.id != teamId,
    );

    const archiveUser = (userId) => {
        setSelectedArchive([...selectedArchive, userId]);
        dispatch(
            userActions.deleteUser(userId, {
                success: () => successCallback(userId),
                fail: () => failCallback(userId),
            }),
        );
    };

    const successCallback = (userId) => {
        setTimeout(() => {
            dispatch(teamActions.getTeamMembers(teamId));
            setSelectedArchive(selectedArchive?.filter((user) => user != userId));
        }, 3000);
    };

    const failCallback = (userId) => {
        setTimeout(() => {
            setSelectedArchive(selectedArchive?.filter((user) => user != userId));
        }, 3000);
    };

    const handleTeamChange = (event, user) => {
        const { name, value } = event.target;

        dispatch(
            teamActions.handleTeamMemberChange({
                name: 'team_id',
                value: parseInt(value),
                user_id: user.user_id,
            }),
        );
    };

    const handleAllTeamChange = (event) => {
        const { value } = event.target;

        dispatch(
            teamActions.handleAllMemberChange(
                parseInt(value),
                teamMembers?.map(({ user_id }) => user_id),
            ),
        );
    };

    return (
        <div>
            <SecondaryCard title='Users'>
                <TableHeader
                    ClassName='TableHeader fixed-header flex justify-content-start'
                    border={false}
                >
                    <TableHeaderItem
                        ClassName='UManagement'
                        label='Name'
                        width={TableItemWidth6}
                    />
                    <TableHeaderItem
                        ClassName=''
                        label='New Team'
                        width={TableItemWidth5}
                    />
                </TableHeader>

                <div
                    className='ListingLoading'
                    style={{ height: '200px', overflowY: 'auto' }}
                >
                    <div className='TableRow d-flex align-items-center justify-content-start'>
                        <TableRowItem
                            width={TableItemWidth6}
                            ClassName='UManagement text-left'
                            input={true}
                        >
                            <span className='BodyTwoLight dark-text'>
                                All Users &nbsp;&nbsp;&nbsp;
                                <span className='BodyTwoLight light-text'>
                                    Optional - This will replace a team for all users
                                </span>
                            </span>
                        </TableRowItem>
                        <TableRowItem
                            width={TableItemWidth5}
                            ClassName='UManagement text-left'
                            input={true}
                        >
                            <PickList
                                placeholder='Select Team'
                                options={Teams?.map((opt) => {
                                    return {
                                        id: opt.id,
                                        name: opt.name,
                                    };
                                })}
                                value={
                                    teamMembers?.length > 0 &&
                                        teamMembers?.every(
                                            ({ team_id }) => team_id == teamMembers[0]?.team_id,
                                        )
                                        ? teamMembers[0]?.team_id
                                        : null
                                }
                                Name='team_id'
                                SelectRole={handleAllTeamChange}
                            />
                        </TableRowItem>
                    </div>
                    {Teams &&
                        teamMembers?.map((member, index) => {
                            return (
                                <div
                                    className='TableRow d-flex align-items-center justify-content-start'
                                    key={index}
                                >
                                    <TableRowItem
                                        label={member.name}
                                        width={TableItemWidth6}
                                        ClassName='UManagement text-left'
                                    />
                                    <TableRowItem
                                        width={TableItemWidth5}
                                        ClassName='UManagement text-left'
                                        input={true}
                                    >
                                        <PickList
                                            requiredTitle='Team selection is required'
                                            placeholder='Select Team'
                                            options={Teams?.filter(
                                                (team) =>
                                                    !member?.teams?.some((item) => item == team.id),
                                            )?.map((opt) => {
                                                return {
                                                    id: opt.id,
                                                    name: opt.name,
                                                };
                                            })}
                                            value={member.team_id}
                                            Name='team_id'
                                            require={true}
                                            SelectRole={(event) => handleTeamChange(event, member)}
                                        />
                                    </TableRowItem>
                                </div>
                            );
                        })}
                </div>
            </SecondaryCard>
        </div>
    );
}

export default ArchieveTeamUsers;
