import { useMemo, useState } from 'react';
import { ReactComponent as EditColor } from '../../assets/icons/PencilEdit.svg';
import { ReactComponent as ArrowsIn } from '../../assets/icons/ArrowsInSimpleColor.svg';
import { ReactComponent as Done } from '../../assets/icons/Check.svg';
import * as Actions from '../../redux/actions/dashboard/workspaces-actions';
import RichTextEditor from '../Inputs/RichTextEditor';
import { CircularProgress } from '@mui/material';
import { useDispatch } from 'react-redux';
import UpdateTaskNotesAlert from './Task Modal/UpdateTaskNotesAlert';

function ExpandableNotesView({
	title,
	isEdit,
	content,
	setExpandView,
	workflowNotes,
	taskId,
	name,
	isAdhoc,
}) {
	const dispatch = useDispatch();
	const [value, setValue] = useState();
	const [actionLoading, setActionLoading] = useState(false);
	const [openAlert, setOpenAlert] = useState(false);

	const toggleTaskAlert = () => {
		setOpenAlert(!openAlert);
	};

	const handleSaveNotes = (update_future = false) => () => {
		setActionLoading(true);
		openAlert && toggleTaskAlert();
		dispatch(
			Actions.updateTaskDetails(
				taskId,
				{ [name]: value },
				`${name}_change_future=${update_future}`,
			),
		)
			.then((response) => {
				dispatch(Actions.updateTaskInfo(response));
				setExpandView({
					isExpanded: true,
					content: content,
					title: title,
					name: name,
					isEdit: false,
				});
				setActionLoading(false);
			})
			.catch((error) => {
				setActionLoading(false);
			});
	};
	useMemo(() => {
		setValue(content);
	}, []);

	return (
		<div className='edit-task-details-container h-100'>
			<div className='d-flex flex-column w-100 h-100' style={{ backgroundColor: '#F7F9FA' }}>
				<div
					className='d-flex justify-content-between align-items-center p-2'
					style={{ borderRadius: 4 }}
				>
					<label className='HeadlineThreeBook text-left dark-text'>{title}</label>
					<div className='d-flex pointer' style={{ gap: '14px' }}>
						{!workflowNotes &&
							(isEdit ? (
								<span
									onClick={
										actionLoading
											? null
											: isAdhoc
											? handleSaveNotes()
											: toggleTaskAlert
									}
								>
									{actionLoading ? (
										<CircularProgress
											size={20}
											sx={{ color: '#fb7a03' }}
											disableShrink
										/>
									) : (
										<Done width={22} height={22} color={'Black'} />
									)}
								</span>
							) : (
								<span
									className='pointer'
									onClick={() => {
										setExpandView({
											isExpanded: true,
											content: content,
											title: title,
											name: name,
											isEdit: true,
										});
									}}
								>
									<EditColor />
								</span>
							))}
						<span
							onClick={() =>
								setExpandView({
									isExpanded: false,
									content: null,
								})
							}
						>
							<ArrowsIn />
						</span>
					</div>
				</div>
				<RichTextEditor
					Edit={isEdit}
					name='notes'
					value={value}
					onChange={(e) => {
						const value = e;
						const name = title;
						setValue(value);
					}}
					containerClass='h-100 overflow-auto'
					style={{
						height: 'calc(100% - 44px)',
						maxHeight: 'calc(100% - 44px)',
						width: '100%',
						padding: '8px',
						wordWrap: 'break-word',
						textAlign: 'left',
						// marginBottom: '3rem',
						backgroundColor: '#F7F9FA',
						borderRadius: '4px',
					}}
				/>
			</div>
			<UpdateTaskNotesAlert
				closeHandler={toggleTaskAlert}
				submitHandler={handleSaveNotes}
				open={openAlert}
                title={title}
			/>
		</div>
	);
}

export default ExpandableNotesView;
