import React from 'react';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	tableCellClasses,
	styled,
} from '@mui/material';

const StyledTableCell = styled(TableCell)(() => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: '#FCFDFD',
		color: '#7C7C7C',
		fontFamily: 'Centra',
		fontSize: 12,
		fontWeight: 300,
		height: '34px',
		lineHeight: '14px',
		borderBottom: '1px solid white',
		width: 'fit-content',
	},
	[`&.${tableCellClasses.body}`]: {
		backgroundColor: '#F7F9FA',
		color: '#333333',
		fontFamily: 'Centra',
		fontSize: 12,
		lineHeight: '14px',
		fontWeight: 300,
		height: '34px',
		borderBottom: '1px solid white',
		borderTop: '1px solid white',
		width: 'fit-content',
	},
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&:nth-of-type(odd)': {
		backgroundColor: theme.palette.action.hover,
	},
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0,
	},
}));

function DynamicTable({ values }) {
	return (
		<div>
			<Paper sx={{ width: '100%', overflow: 'hidden', boxShadow: 'none' }}>
				<TableContainer sx={{ maxHeight: 304 }}>
					<Table stickyHeader aria-label='sticky table' sx={{ minWidth: 700 }}>
						<TableHead>
							<TableRow>
								{values?.tableHeaders?.map((header, key) => {
									return (
										<StyledTableCell key={key}>{header?.name}</StyledTableCell>
									);
								})}
							</TableRow>
						</TableHead>
						<TableBody>
							{values?.tableRows?.length > 0 ? (
								values?.tableRows?.map((item, index) => {
									return (
										<StyledTableRow key={index}>
											{item?.values.map((rowItem, innerIndex) => {
												return (
													<StyledTableCell key={innerIndex}>
														{rowItem || '-'}
													</StyledTableCell>
												);
											})}
										</StyledTableRow>
									);
								})
							) : (
								<StyledTableRow>
									{values?.tableHeaders?.map((header, key) => {
										return <StyledTableCell key={key}>-</StyledTableCell>;
									})}
								</StyledTableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
		</div>
	);
}

export default DynamicTable;
