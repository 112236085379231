/* eslint-disable no-mixed-spaces-and-tabs */
import { useState } from 'react';
import { ReactComponent as ClipBoardIcon } from '../../assets/icons/ClipboardText.svg';
import { ReactComponent as ClockIcon } from '../../assets/icons/ApprovalClock.svg';
import { ReactComponent as StackIcon } from '../../assets/icons/Stack.svg';
import useModal from '../Modal/useModal';
import ReactHtmlParser from 'react-html-parser';
import { truncateString } from '../../utilities/utilities';
import { Modal } from '@mui/material';
import DummyModal from '../Modal/DummyModal';
import TaskDescriptionModal from '../ClientManagement/Modals/TaskDescriptionModal';

export default function WorkflowTaskCard({ task, tasks }) {
	const { modalIsOpen, toggleModal, ModalName, ModalType } = useModal();
	const [expandModal, setExpandModal] = useState({
		value: '',
		title: '',
	});

	return (
		<>
			<div className='task-section'>
				<div className='d-flex'>
					<div style={{ marginRight: '8px' }}>
						<ClipBoardIcon />
					</div>
					<span className='HeadlineThreeBold'>{task?.title}</span>
				</div>
				<div className='task-section-body'>
					<div>
						<span className='BodyTwoLight'>
							{task?.description?.length < 1 ||
							task?.description == null ||
							task?.description?.replace(/<[^>]+>/g, '')?.trim()?.length == 0 ? (
								'No description available'
							) : (
								<div
									style={{ minHeight: '14px' }}
									className='d-flex flex-column justify-content-between position-relative'
								>
									<p className='BodyTwoLight dark-text m-0 text-break'>
										{task?.description?.length <= 400
											? ReactHtmlParser(task?.description)
											: ReactHtmlParser(
													truncateString(task?.description, 400),
											  )}
										{task?.description?.length > 400 && (
											<span
												onClick={() => {
													ModalType('TASK_DESCRIPTION_MODAL');
													toggleModal();
													setExpandModal({
														value: task?.description,
														title: 'Description',
													});
												}}
												className='primary-text BodyThreeLight pointer'
											>
												Read More
											</span>
										)}
									</p>
								</div>
							)}
						</span>
					</div>
					<div style={{ marginTop: '20px' }}>
						<div className='d-flex align-items-center' style={{ marginBottom: '12px' }}>
							<ClockIcon />
							<div className='pl-1'>
								<span className='BodyTwoBold'>
									Budgeted Hours:{' '}
									<span className='BodyTwoLight'>{task.budgeted_hours}</span>
								</span>
							</div>
						</div>
						<div className='d-flex align-items-center'>
							<StackIcon />
							<div className='pl-1'>
								<span className='BodyTwoBold'>
									Dependency:{' '}
									<span className='BodyTwoLight'>
										{tasks.find((t) => t.id === task?.dependent_task_id)
											?.title || 'None'}
									</span>
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			{modalIsOpen && (
				<Modal
					onClose={toggleModal}
					open={modalIsOpen}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<>
						{ModalName == 'TASK_DESCRIPTION_MODAL' && (
							<DummyModal
								title='Workflow Description'
								onClose={toggleModal}
								height='400px'
							>
								<TaskDescriptionModal
									type={'text'}
									value={expandModal.value}
									Edit={false}
									OnCancel={() => toggleModal()}
									richTextStyle={{ height: '250px' }}
								/>
							</DummyModal>
						)}
					</>
				</Modal>
			)}
		</>
	);
}
