import React from 'react';
import { hasNumber } from '../../../utilities/utilities';
import { useDispatch } from 'react-redux';
import * as builderActions from '../../../redux/actions/workflow-builder-actions';
import {
	inputJson,
	dropdownJson,
	textareaJson,
	radioJson,
	pickList,
	datePickerJson,
	selectGroupJson,
	statePickList,
	contactPickList,
	accountPickList,
	softwarePickList,
	locationPickList,
	linksPickList,
	portalPickList,
	merchantPickList,
	creditCardPickList,
} from '../../../utilities/BuilderUtils';

const Board = ({ children, id, className, callBack, Style, index }) => {
	const dispatch = useDispatch();
	let withinColumnIndex = undefined;

	const drop = (e) => {
		e.preventDefault();
		e.target.style.border = 'none';

		var id = e.dataTransfer.getData('card_id');
		var element = document.getElementById(id);

		const elements = document.getElementsByClassName('selected');
		for (let i = 0; i < elements.length; i++) {
			elements[i].style.border = 'none';
		}

		let condition = element?.id;

		hasNumber(condition) && (condition = 'transfer');

		switch (condition) {
			case 'text' || 'input':
				dispatch(
					builderActions.updateWorkflow(
						{
							...inputJson,
							id: Math.random(),
							sectionIndex: index.sec,
							columnIndex: index.col,
						},
						index.col,
						index.sec,
						withinColumnIndex,
					),
				);
				break;
			case 'textarea':
				dispatch(
					builderActions.updateWorkflow(
						{
							...textareaJson,
							id: Math.random(),
							sectionIndex: index.sec,
							columnIndex: index.col,
						},
						index.col,
						index.sec,
						withinColumnIndex,
					),
				);
				break;
			case 'dropdown':
				dispatch(
					builderActions.updateWorkflow(
						{
							...dropdownJson,
							id: Math.random(),
							sectionIndex: index.sec,
							columnIndex: index.col,
						},
						index.col,
						index.sec,
						withinColumnIndex,
					),
				);
				break;
			case 'radio':
				dispatch(
					builderActions.updateWorkflow(
						{
							...radioJson,
							id: Math.random(),
							sectionIndex: index.sec,
							columnIndex: index.col,
						},
						index.col,
						index.sec,
						withinColumnIndex,
					),
				);
				break;
			case 'select-group':
				dispatch(
					builderActions.updateWorkflow(
						{
							...selectGroupJson,
							id: Math.random(),
							sectionIndex: index.sec,
							columnIndex: index.col,
						},
						index.col,
						index.sec,
						withinColumnIndex,
					),
				);
				break;
			case 'datepicker':
				dispatch(
					builderActions.updateWorkflow(
						{
							...datePickerJson,
							id: Math.random(),
							sectionIndex: index.sec,
							columnIndex: index.col,
						},
						index.col,
						index.sec,
						withinColumnIndex,
					),
				);
				break;
			case 'pick-list':
				dispatch(
					builderActions.updateWorkflow(
						{
							...pickList,
							id: Math.random(),
							sectionIndex: index.sec,
							columnIndex: index.col,
						},
						index.col,
						index.sec,
						withinColumnIndex,
					),
				);
				break;
			case 'state-pick-list':
				dispatch(
					builderActions.updateWorkflow(
						{
							...statePickList,
							id: Math.random(),
							sectionIndex: index.sec,
							columnIndex: index.col,
						},
						index.col,
						index.sec,
						withinColumnIndex,
					),
				);
				break;
			case 'contact-pick-list':
				dispatch(
					builderActions.updateWorkflow(
						{
							...contactPickList,
							id: Math.random(),
							sectionIndex: index.sec,
							columnIndex: index.col,
						},
						index.col,
						index.sec,
						withinColumnIndex,
					),
				);
				break;
			case 'account-pick-list':
				dispatch(
					builderActions.updateWorkflow(
						{
							...accountPickList,
							id: Math.random(),
							sectionIndex: index.sec,
							columnIndex: index.col,
						},
						index.col,
						index.sec,
						withinColumnIndex,
					),
				);
				break;
			case 'software-pick-list':
				dispatch(
					builderActions.updateWorkflow(
						{
							...softwarePickList,
							id: Math.random(),
							sectionIndex: index.sec,
							columnIndex: index.col,
						},
						index.col,
						index.sec,
						withinColumnIndex,
					),
				);
				break;
			case 'portal-pick-list':
				dispatch(
					builderActions.updateWorkflow(
						{
							...portalPickList,
							id: Math.random(),
							sectionIndex: index.sec,
							columnIndex: index.col,
						},
						index.col,
						index.sec,
						withinColumnIndex,
					),
				);
				break;
			case 'merchant-pick-list':
				dispatch(
					builderActions.updateWorkflow(
						{
							...merchantPickList,
							id: Math.random(),
							sectionIndex: index.sec,
							columnIndex: index.col,
						},
						index.col,
						index.sec,
						withinColumnIndex,
					),
				);
				break;
			case 'card-pick-list':
				dispatch(
					builderActions.updateWorkflow(
						{
							...creditCardPickList,
							id: Math.random(),
							sectionIndex: index.sec,
							columnIndex: index.col,
						},
						index.col,
						index.sec,
						withinColumnIndex,
					),
				);
				break;
			case 'location-pick-list':
				dispatch(
					builderActions.updateWorkflow(
						{
							...locationPickList,
							id: Math.random(),
							sectionIndex: index.sec,
							columnIndex: index.col,
						},
						index.col,
						index.sec,
						withinColumnIndex,
					),
				);
				break;
			case 'links-pick-list':
				dispatch(
					builderActions.updateWorkflow(
						{
							...linksPickList,
							id: Math.random(),
							sectionIndex: index.sec,
							columnIndex: index.col,
						},
						index.col,
						index.sec,
						withinColumnIndex,
					),
				);
				break;
			case 'transfer':
				dispatch(
					builderActions.swapComponent(Number(element.id), {
						col: index.col,
						sec: index.sec,
						withinColumnIndex: withinColumnIndex,
					}),
				);
				break;
			default:
				return 0;
		}
	};

	const dragOver = (e) => {
		e.preventDefault();

		const afterElement = getDragAfterElement(e.target, e.clientY);
		if (afterElement == null) {
			withinColumnIndex = e.target.childElementCount;
		} else {
			withinColumnIndex = Number(afterElement.dataset.elementIndex);
		}
	};

	const dragEnter = (e) => {
		e.target.classList.contains('Board') &&
			(e.target.style.border = '1px dotted #fb7a03');
	};
	const dragLeave = (e) => {
		e.target.style.border = 'none';
	};

	function getDragAfterElement(container, y) {
		const draggableElements = [...container.querySelectorAll('.selected')];

		return draggableElements.reduce(
			(closest, child) => {
				const box = child?.getBoundingClientRect();
				const offset = y - box.top - box.height / 2;
				if (offset < 0 && offset > closest?.offset) {
					return { offset: offset, element: child };
				} else {
					return closest;
				}
			},
			{ offset: Number.NEGATIVE_INFINITY },
		)?.element;
	}

	return (
		<div
			style={Style}
			className={className}
			onDrop={drop}
			onDragOver={dragOver}
			id={id}
			onDragEnter={dragEnter}
			onDragLeave={dragLeave}
		>
			{children}
		</div>
	);
};

export default Board;
