import { useEffect, useState } from 'react';
import moment from 'moment';
import * as userActions from '../../redux/actions/user-management.actions';
import * as teamActions from '../../redux/actions/team-management.actions';

import { Avatar, Modal } from '@mui/material';
import TableRowButton from '../Others/TableRowButton';
import TableRowItem from '../Others/TableRowItem';
import { useDispatch, useSelector } from 'react-redux';
import { getAllArchivedUsers } from '../../redux/actions/user-management.actions';
import LoadingOverLay from '../Others/LoadingOverLay';
import useModal from '../Modal/useModal';
import TableHeader from '../Others/TableHeader';
import TableHeaderItem from '../Others/TableHeaderItem';
import {
	TableItemWidth1,
	TableItemWidth4,
	TableItemWidth5,
} from '../TableSpacing/TableSpacingStyles';
import UnarchiveUserModal from './UnarchiveUserModal';
import ArchiveUserSuccessfullyModal from './ArchiveUserSuccessfullyModal';
import { getUserSettings } from '../../hooks/getUserSettings';

const ShowArchivedUsersTable = ({ Name }) => {
	const { archivedUsers, loading } = useSelector((state) => state.userReducer);
    const userDetails = useSelector((state) => state.authReducer.userDetails);
	const { date_format } = getUserSettings(userDetails);

	const dispatch = useDispatch();

	const MODAL_TYPES = {
		EXISTING_USER_MODAL: 'EXISTING_USER_MODAL',
		UNARCHIVE_SUCESS: 'UNARCHIVE_SUCESS',
		UNARCHIVE_MODAL: 'UNARCHIVE_MODAL',
	};

	const { modalIsOpen, toggleModal, ModalName, ModalType } = useModal();
	const [user, setUser] = useState();

	const onUnarchiveClick = (userData) => {
		setUser(userData);
		ModalType(MODAL_TYPES.UNARCHIVE_MODAL);
		toggleModal();
	};

	const handleUnarchiveSuccess = () => {
		ModalType(MODAL_TYPES.UNARCHIVE_SUCESS);
	};

	useEffect(() => {
		dispatch(userActions.getRoles());
		dispatch(teamActions.getTeams());
		dispatch(getAllArchivedUsers());
	}, [dispatch]);

	return (
		<>
			<div className='card-body'>
				<TableHeader ClassName='TableHeader flex fixedHeader' border={false}>
					<TableHeaderItem
						ClassName='UManagement'
						label='User Name'
						width={TableItemWidth5}
					></TableHeaderItem>
					<TableHeaderItem
						ClassName='UManagement'
						label='Email'
						width={TableItemWidth5}
						margin='mb-0'
					/>
					<TableHeaderItem
						ClassName='UManagement'
						label='Archive Date'
						width={TableItemWidth4}
						margin='mb-0'
					/>
					<TableHeaderItem
						ClassName='UManagement text-center'
						label='Action'
						width={TableItemWidth1}
						margin='mb-0'
					/>
				</TableHeader>

				<div data-cy='all-users-list' className='ListingLoading listing-scroll'>
					{loading ? (
						<LoadingOverLay active={loading} />
					) : archivedUsers?.length < 1 ? (
						<div className='TableRow w-100 d-flex align-items-center justify-content-center'>
							<h4 className='BodyTwoLight'>No Users</h4>
						</div>
					) : (
						archivedUsers
							?.filter(
								(user) =>
									user?.full_name
										?.toLowerCase()
										.includes(Name?.toLowerCase()) ||
									user?.email?.toLowerCase().includes(Name?.toLowerCase()),
							)
							?.map((user, i) => {
								return (
									<div
										className='TableRow d-flex align-items-center h-auto'
										key={i}
									>
										<TableRowButton
											ClassName='UManagement text-center'
											label={user?.full_name}
											width={TableItemWidth5}
										>
											<>
												<Avatar
													sx={{
														bgcolor: `#FEF2E7`,
														color: `#333`,
														marginRight: '10px',
														height: '24px',
														width: '24px',
														fontSize: '1rem',
													}}
													alt={user?.full_name}
													src={user?.image_url}
												/>
											</>
										</TableRowButton>

										<TableRowItem
											label={user?.email}
											width={TableItemWidth5}
											ClassName='UManagement text-left'
										/>

										<TableRowItem
											label={moment(user?.created_at).format(date_format)}
											width={TableItemWidth4}
											ClassName='UManagement text-left'
										/>

										<TableRowButton
											width={TableItemWidth1}
											ClassName='UManagement text-center'
										>
											<span
												type='button'
												className='BodyTwoLight primary-text'
												onClick={() => {
													onUnarchiveClick(user);
												}}
											>
												Unarchive
											</span>
										</TableRowButton>
									</div>
								);
							})
					)}
				</div>
			</div>

			{modalIsOpen && (
				<Modal
					onClose={toggleModal}
					open={modalIsOpen}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<>
						{ModalName == MODAL_TYPES.UNARCHIVE_MODAL && (
							<UnarchiveUserModal
								user={user}
								setUnarchiveSuccessfully={handleUnarchiveSuccess}
								toggleModal={toggleModal}
								title='Are you sure you want to unarchive this user?'
								descrption='Please select a team and role for the user '
							/>
						)}

						{ModalName == MODAL_TYPES.UNARCHIVE_SUCESS && (
							<ArchiveUserSuccessfullyModal onClose={toggleModal} />
						)}
					</>
				</Modal>
			)}
		</>
	);
};

export default ShowArchivedUsersTable;
