import { useEffect } from 'react';
import { Avatar } from '@mui/material';
import SectionHeader from '../sidebar/SectionHeader';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as searchActions from '../../redux/actions/search-actions';

function SearchCard({ title, list }) {
	const history = useHistory();
	const dispatch = useDispatch();
	const user = useSelector((state) => state.authReducer.user);

	const navigate = (data) => {
		switch (title) {
			case 'Teams':
				history.push(`/team-profile/${data.id}`);
				dispatch(searchActions.setModalState(false));
				break;

			case 'People':
				user.userId === data.id
					? history.push('/account-settings')
					: history.push(`/user-profile/${data.id}`);
				dispatch(searchActions.setModalState(false));
				break;

			case 'Clients':
				history.push(`/client-profile/${data.id}`);
				dispatch(searchActions.setModalState(false));
				break;

			case 'Tasks':
				history.push(`/workspace/?task_id=${data.id}`);
				dispatch(searchActions.setModalState(false));
				break;

			default:
				break;
		}
	};

	return (
		<div className='d-flex flex-column' style={{ gap: '16px' }}>
			<SectionHeader className='dark-text' title={title} />

			<div className='ClientList d-flex flex-column' style={{ gap: '16px' }}>
				{list?.length < 1 ? (
					<div className='d-flex align-items-center justify-content-center placeholder-text'>
						<h4 className='BodyTwoLight'>No {title} found</h4>
					</div>
				) : (
					list?.map((data, idx) => {
						return (
							<div
								key={idx}
								className={
									data.description
										? 'white-text d-flex flex-row align-items-start hover-pointer p-1'
										: 'white-text d-flex flex-row align-items-center hover-pointer p-1'
								}
								onClick={() => navigate(data)}
							>
								<Avatar
									sx={{
										bgcolor: `#FEF2E7`,
										color: `#333`,
										marginRight: '10px',
										height: '22px',
										width: '22px',
										fontSize: '0.8rem',
									}}
									alt={
										data.name ||
										data.company_name ||
										data.full_name ||
										data.title
									}
									src={data.image_url || './broken.png'}
								/>

								<div className='d-flex flex-column ' style={{ gap: '7px' }}>
									<h4 className='BodyTwoLight pointer dark-text'>
										{data.name ||
											data.company_name ||
											data.full_name ||
											data.title}
									</h4>
									{data.description && (
										<h4 className='BodyTwoLight pointer dark-text'>
											{data.description}
										</h4>
									)}
								</div>
							</div>
						);
					})
				)}
			</div>
		</div>
	);
}

export default SearchCard;
