import * as Actions from '../actions/invoices-actions';

const initialState = {
	invoices: [],
	invoice: null,
	filters: {
		status: [],
		type: [],
        start_date: null,
        end_date: null
	},
};

const InvoicesReducer = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case Actions.GET_INVOICES:
			return (state = {
				...state,
				invoices: payload,
			});
		case Actions.CREATE_INVOICE:
			return (state = {
				...state,
				invoices: [...state.invoices, payload],
			});
		case Actions.GET_SPECIFIC_INVOICE:
			return (state = {
				...state,
				invoice: payload,
			});
		case Actions.UPDATE_INVOICE:
			return (state = {
				...state,
				invoice: payload,
			});
		case Actions.HANDLE_STATUS_CHECKBOX:
			return (state = {
				...state,
				filters: {
					...state.filters,
					status: state.filters.status.includes(payload)
						? state.filters.status.filter((p) => p !== payload)
						: [...state.filters.status, payload],
				},
			});
		case Actions.HANDLE_CLEAR_STATUS_CHECKBOX:
			return (state = {
				...state,
				filters: {
					...state.filters,
					status: [],
				},
			});
		case Actions.HANDLE_TYPE_CHECKBOX:
			return (state = {
				...state,
				filters: {
					...state.filters,
					type: state.filters.type.includes(payload)
						? state.filters.type.filter((p) => p !== payload)
						: [...state.filters.type, payload],
				},
			});
		case Actions.HANDLE_CLEAR_TYPE_CHECKBOX:
			return (state = {
				...state,
				filters: {
					...state.filters,
					type: [],
				},
			});
        case Actions.HANDLE_INVOICE_DATE_FILTER:
            return state = {
                ...state,
                filters: {
                    ...state.filters,
                    start_date: payload?.start_date,
                    end_date: payload?.end_date
                }
            };
        case Actions.HANDLE_CLEAR_DATE:
            return state = {
                ...state,
                filters: {
                    ...state.filters,
                    start_date: null,
                    end_date: null
                }
            };
		default:
			return state;
	}
};

export default InvoicesReducer;
