import { combineReducers } from 'redux';
import authReducer from './auth-reducer';
import teamReducer from './team-management.reducer';
import userReducer from './user-management.reducer';
import profileReducer from './company-profile';
import clientReducer from './client-reducer';
import WorkflowBuilder from './workflow-builder-reducer';
import workSpaceReducer from './dashboard/workspaces.reducer';
import clientAccountsReducer from './client-accounts-reducer';
import slackIntegrationReducer from './slack-reducer';
import subscriptionReducer from './subscription-reducer';
import searchReducer from '../reducers/search reducer';
import cubeReducer from './cube-reducer';
import actionCenterReducer from '../reducers/action-center-reducer';
import TimeReports from './time-reports-reducer';
import clientBillingReducer from './client-billing-reducer';
import TimeReportsV2 from './time-reports-reducer-v2';
import invoicesReducer from './invoices-reducer';
import AccountingReducer from './integration/accounting-reducer';

const allReducers = combineReducers({
	searchReducer,
	authReducer,
	teamReducer,
	userReducer,
	profileReducer,
	clientReducer,
	WorkflowBuilder,
	workSpaceReducer,
	clientAccountsReducer,
	slackIntegrationReducer,
	subscriptionReducer,
	cubeReducer,
	actionCenterReducer,
	TimeReports,
	clientBillingReducer,
	TimeReportsV2,
    invoicesReducer,
    AccountingReducer
});

export default allReducers;
