import { useState } from 'react';

export const useSessionStorage = (key) => {
	const [name] = useState(key);

	const getSessionStorage = () => {
		const local = sessionStorage.getItem(name);
		if (local != null) {
			return JSON.parse(local);
		}
		return null;
	};

	const setSessionStorage = (item) => {
		sessionStorage.setItem(name, JSON.stringify(item));
	};

	const removeSessionStorage = () => {
		return sessionStorage.removeItem(name);
	};

	return { getSessionStorage, setSessionStorage, removeSessionStorage };
};
