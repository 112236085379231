
// Styled Components
import {backgroundGrey } from '../Style.jsx';

// Components

// Material Ui Components
import { Box } from '@mui/material';

function BillingBar({ BarText }) {
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'flex-start',
			}}
		>
			<Box
				sx={{
					backgroundColor: `#F7F9FA`,
					width: '100%',
					padding: '1em',
					height:'67px',
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					gap: '10px',
					boxSizing: 'border-box',
					borderRadius:'4px 4px 0px 0px'
				}}
			>
				<Box>
					<h1 className='HeadlineOneBold dark-text pt-3'>{BarText}</h1>
				</Box>
			</Box>
		</Box>
	);
}

export default BillingBar;
