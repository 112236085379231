import { useEffect, useState } from 'react';
import Sidebar from '../sidebar/SideBar';
// import ToggleSwitch from '../sub_components/ToggleSwitch';
// import SectionHeader from '../sidebar/SectionHeader';
// import NewToggleSwitch from '../sub_components/NewToggleSwitch';
import InvocieTopBar from './InvocieTopBar';
// import InvocieImg from '../../assets/icons/images 1.svg';
// import InvoicesInputs from './InvoicesInputs';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import * as clientActions from '../../redux/actions/client-actions';
import * as profileActions from '../../redux/actions/company-profile';
import * as invoiceActions from '../../redux/actions/invoices-actions';
import NewSearchDropdown from '../Inputs/NewSearchDropdown';
// import { KeyboardDatePicker } from '@material-ui/pickers';
// import { ReactComponent as CalendarIcon } from '../../assets/icons/Calendarcolor.svg';
import moment from 'moment';
import { CircularProgress } from '@mui/material';
import SkeletonLoading from '../Others/SkeletonLoading';
import DateRangePicker from '../Inputs/MultiDatePicker';
import { setSnackBarIsOpen } from '../../redux/actions/dashboard/workspaces-actions';

function CustomizeInvoice() {
	const history = useHistory();
	const dispatch = useDispatch();

	const [values, setValues] = useState({});
	const [loading, setLoading] = useState(false);

	let clients = useSelector((state) => state.clientReducer.clients);
	const user = useSelector((state) => state.authReducer.user);
	const companyProfileData = useSelector((state) => state.profileReducer.profileData);
	const companyProfileLoading = useSelector((state) => state.profileReducer.loading);

	if (clients?.length > 0) {
		clients = clients.map((client) => {
			return {
				value: client.id,
				label: client.company_name,
			};
		});
	}

	const handleClickInvoiceGenerate = () => {
		setLoading(true);
		dispatch(
			invoiceActions.createInvoice({
				client_id: values?.client_id,
				from_date: moment(values?.from).format('MM/DD/YYYY'),
				to_date: moment(values?.to).format('MM/DD/YYYY'),
			}),
		)
			.then((res) => {
				history.push(`/invoices/${res?._id}`);
			})
			.catch((err) => {
				dispatch(setSnackBarIsOpen(false, true, err.message));
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const onClientChange = (client) => {
		const { value } = client;
		setValues({
			...values,
			client_id: value,
		});
	};

	useEffect(() => {
		dispatch(profileActions.getTenantProfile(user?.tenantIds[0]));
		dispatch(clientActions.getClients());
	}, [dispatch]);

	return (
		<div className='AppBody'>
			<Sidebar title='Invoices'>
				<div className='SidebarContentWrapper'>
					<div className='User-filters'>
						<div className='SidebarListWrapper'>
							{/* <div className='FilterSpacing-1'>
								<SectionHeader title='Customize Invoice' />

								<div className='TeamMultiPickList w-100'>
									<div className='ItemWrapper pb-1 pt-1  the-item-wrapper align-items-center justify-content-center w-100'>
										<div className='w-100 d-flex align-items-center w-100'>
											<NewToggleSwitch
												label='Invoice no'
												labelPlacement='start'
												// checked={weekDaysOff}
												style={{ color: '#FB7A03' }}
												size='small'
												className=' white-text text-left w-100'
												// onChange={toggleWeekends}
											/>
										</div>
									</div>

									<div className='ItemWrapper pb-1 pt-1  the-item-wrapper align-items-center justify-content-center'>
										<div className='w-100 d-flex align-items-center '>
											<NewToggleSwitch
												label='Invoice Date'
												labelPlacement='start'
												// checked={weekDaysOff}
												style={{ color: '#FB7A03' }}
												size='small'
												className=' white-text text-left w-100'
												// onChange={toggleWeekends}
											/>
										</div>
									</div>
									<div className='ItemWrapper pb-1 pt-1  the-item-wrapper align-items-center justify-content-center w-100'>
										<div className='w-100 d-flex align-items-center w-100'>
											<NewToggleSwitch
												label='Payment Terms'
												labelPlacement='start'
												// checked={weekDaysOff}
												style={{ color: '#FB7A03' }}
												size='small'
												className=' white-text text-left w-100'
												// onChange={toggleWeekends}
											/>
										</div>
									</div>

									<div className='ItemWrapper pb-1 pt-1  the-item-wrapper align-items-center justify-content-center w-100'>
										<div className='w-100 d-flex align-items-center w-100'>
											<NewToggleSwitch
												label='Due Date'
												labelPlacement='start'
												// checked={weekDaysOff}
												style={{ color: '#FB7A03' }}
												size='small'
												className=' white-text text-left w-100'
												// onChange={toggleWeekends}
											/>
										</div>
									</div>
								</div>
							</div> */}
						</div>
					</div>
				</div>
			</Sidebar>
			<div className='UserProfile-main user-profile-main'>
				<div className='UserProfile-content ' style={{ width: '81vw' }}>
					{/* <InvocieBar /> */}
					<InvocieTopBar
						save={false}
						// Title='Draft'
						InvoiceNo='Generate Invoice'
					/>
					<div
						className='Teams-Table border'
						style={{
							padding: '18px',
							backgroundColor: '#EEF2F3',
							borderRadius: '4pxs',
							boxShadow: '0px 7px 29px 0px rgba(100, 100, 111, 0.20)',
						}}
					>
						<div style={{ display: 'flex', flexDirection: 'column', gap: '32px' }}>
							<div
								style={{
									width: '100%',
									display: 'flex',
									justifyContent: 'space-between',
								}}
							>
								<div
									className='d-flex flex-column text-left'
									style={{
										width: '18%',
										minHeight: '125px',
										gap: '0.8rem',
									}}
								>
									<span className='BodyOneBold'>From</span>
									<SkeletonLoading
										loading={companyProfileLoading}
										variant='text'
										width={150}
										height={70}
									>
										<div className='d-flex flex-column'>
											<div className='text-left ProfilePage'>
												<h3
													className='BodyTwoLight'
													style={{
														wordBreak: 'break-all',
													}}
												>
													{companyProfileData?.name}
													<span className='d-block mt-1'>
														{/* {business.address} */}
													</span>
													<span className='d-block mt-1'>
														{companyProfileData?.country}
													</span>
													<span className='d-block mt-1'>
														{/* {business.email} */}
													</span>
												</h3>
											</div>
										</div>
									</SkeletonLoading>
								</div>
								{companyProfileData?.image_url && (
									<SkeletonLoading
										loading={companyProfileLoading}
										variant='text'
										width={150}
										height={150}
									>
										<div style={{ width: '125px', height: '125px' }}>
											<img
												src={companyProfileData?.image_url}
												alt=''
												style={{ width: '125px', height: '125px' }}
											/>
										</div>
									</SkeletonLoading>
								)}
							</div>
							<div
								className='d-flex'
								style={{
									width: '100%',
									height: '78px',
									padding: '12px',
									borderRadius: '8px',
									justifyContent: 'space-between',
									backgroundColor: '#F7F9FA',
								}}
							>
								<div
									className=' d-flex justify-content-start pr-3'
									style={{ width: '72vw' }}
								>
									<div
										className='improved-input-designs '
										style={{ width: '30%' }}
									>
										<label className='require text-left'>Select Client</label>
										<NewSearchDropdown
											selectClassName={'w-100'}
											width={'390px'}
											required={true}
											options={clients}
											name={'client_id'}
											label={'Client'}
											disableLetters
											changeHandler={onClientChange}
											value={
												clients?.find(
													(item) => item.value == values?.client_id,
												)?.value
											}
										/>
									</div>
									<div
										className='improved-input-designs pl-4'
										style={{ width: '30%' }}
									>
										<label className='require text-left'>
											Select Date Range
										</label>
										<DateRangePicker
											value={{
												from: values?.from,
												to: values?.to,
											}}
											onChange={(e, name) => {
												const date = e?._d;
												setValues({
													...values,
													[name]: date,
												});
											}}
											style={{
												height: '30px',
												display: 'flex',
												alignItems: 'center',
												minWidth: '330px',
											}}
											className='dropdown-text'
										/>
									</div>
									{/* <div
										className='improved-input-designs d-flex'
										style={{ width: '30%' }}
									>
										<label className='require text-left'>Due Date</label>
										<KeyboardDatePicker
											autoOk
											// disableToolbar
											name='to_date'
											onChange={(date) => handleDateChange(date, 'to_date')}
											value={values?.to_date}
											format='MM/DD/yyyy'
											className='p-1'
											required
											helperText={null}
											keyboardIcon={<CalendarIcon />}
											minDate={values?.from_date && values?.from_date}
											disabled={values?.from_date ? false : true}
											style={{ opacity: values?.from_date ? 1 : 0.5 }}
										/>
									</div> */}
								</div>
								<button
									className='d-flex justify-content-center mt-4 pt-1'
									style={{
										width: '88px',
										height: '32px',
										borderRadius: '50px',
										background: '#FB7A03',
										border: 'none',
										opacity: loading ? 0.5 : 1,
									}}
									disabled={loading}
									onClick={handleClickInvoiceGenerate}
								>
									{loading ? (
										<CircularProgress
											size={22}
											sx={{ color: 'white' }}
											disableShrink
										/>
									) : (
										<p className='BodyTwoBold mt-1 text-white'>Generate</p>
									)}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default CustomizeInvoice;
