

const SecondaryCard = ({ title, children }) => (
    <div className="Card w-100">
        <div className='card-header pointer' style={{ height: '40px' }}>
            <h1 className='BodyTwoBold text-left dark-text'>
                {title}
            </h1>
        </div>
        {children}
    </div>
);

export default SecondaryCard;
