
function Severability() {
	return (
		<p className='BodyTwoLight'>
			If any provision or part of a provision of these Terms is unlawful, void,
			or unenforceable, that provision or part of the provision is deemed
			severable from these Terms and does not affect the validity and
			enforceability of any remaining provisions.
		</p>
	);
}

export default Severability;
