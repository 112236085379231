import React, { useEffect, useState } from 'react';
import SubmitButtonsField from '../Buttons/SubmitButtonsField';
import { ReactComponent as EyeIcon } from '../../assets/icons/Eye.svg';
import { CircularProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
	changePassword,
	setChangePasswordError,
} from '../../redux/actions/auth-actions';
import { passwordCheckError } from '../../utilities/utilities';
import { setSnackBarIsOpen } from '../../redux/actions/dashboard/workspaces-actions';

const PasswordInput = (props) => {
	return (
		<div>
			<div className='d-flex mb-2'>
				<span className='BodyTwoLight'>
					{props.label} <span style={{ color: 'red' }}>*</span>
				</span>
			</div>
			<div
				className='d-flex align-items-center justify-content-between pr-2'
				style={{
					width: '100%',
					height: '36px',
					backgroundColor: '#FFFFFF',
					// borderBottom: '1px solid black',
				}}
			>
				<input
					className={props.className}
					name={props.name}
					style={props.style}
					type={props.type}
					value={props.value}
					onChange={props.onChange}
					placeholder={props.placeholder}
					// disabled={props.disabled}
				/>
				<div onClick={props.onClick}>
					<EyeIcon />
				</div>
			</div>
		</div>
	);
};

const ChangePasswordModal = ({ onCancel }) => {
	const dispatch = useDispatch();
	const [newInputType, setNewInputType] = useState('password');
	const [inputType, setInputType] = useState('password');
	const [data, setData] = useState({ password: '', new_password: '' });
	const [actionLoading, setActionLoading] = useState(false);
	const { isSuccessful, message } = useSelector((state) => {
		return state.authReducer.passwordChanged;
	});
	const [error, setError] = useState('');

	const handleHidePassword = () => {
		if (inputType === 'password') {
			setInputType('text');
		}
		if (inputType === 'text') {
			setInputType('password');
		}
	};

	const handleNewPasswordHide = () => {
		if (newInputType === 'password') {
			setNewInputType('text');
		}
		if (newInputType === 'text') {
			setNewInputType('password');
		}
	};
	const handleChange = (name, value) => {
		setData({ ...data, [name]: value });
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setActionLoading(true);

		if (!data.password || !data.new_password) {
			console.log('loop1');
			dispatch(setChangePasswordError('Please Enter Valid Values!'));
			setError('Please Enter Valid Values!');
			setActionLoading(false);
		} else if (data.password === data.new_password) {
			console.log('loop2');
			dispatch(setChangePasswordError('Please choose different password'));
			setError('Please choose different password');
			setActionLoading(false);
		} else if (!passwordCheckError(data.new_password)) {
			console.log('loop3');
			dispatch(setChangePasswordError(''));
			setError('');
			dispatch(changePassword(data.password, data.new_password))
				.then(() => {
					dispatch(
						setSnackBarIsOpen(true, true, 'Password Updated Successfuly'),
					);
					setActionLoading(false);
					onCancel();
				})
				.catch((error) => {
					dispatch(setSnackBarIsOpen(false, true, error.message));
					setActionLoading(false);
				});
		} else {
			console.log('loop4');
			setActionLoading(false);
			dispatch(setChangePasswordError(passwordCheckError(data.new_password)));
		}
	};
	useEffect(() => {
		if (message && !isSuccessful) {
			setError(message);
		}
		return () => {
			dispatch(setChangePasswordError(''));
		};
	}, [isSuccessful, message]);

	return (
		<form onSubmit={handleSubmit}>
			<div className='p-3'>
				<div className='improved-input-designs pb-3'>
					<PasswordInput
						type={inputType}
						onClick={handleHidePassword}
						label='Current Password'
						onChange={(e) => {
							handleChange('password', e.target.value);
						}}
						value={data.password}
						style={{
							border: 'none',
							width: '100%',
							backgroundColor: '#FFFFFF',
							// borderBottom: '1px solid black',
						}}
					/>
				</div>
				<div className='improved-input-designs'>
					<PasswordInput
						type={newInputType}
						onClick={handleNewPasswordHide}
						label='New Password'
						value={data.new_password}
						onChange={(e) => {
							handleChange('new_password', e.target.value);
						}}
						style={{
							border: 'none',
							backgroundColor: '#FFFFFF',
							width: '100%',
							// borderBottom: '1px solid black',
						}}
					/>
				</div>
			</div>
			<p className='mt-1 pl-3 text-left'>
				{error && <span className='errorMessage'>{error}</span>}
			</p>
			<div className='VerticalSpacer bg-white p-3 rounded-bottom'>
				<SubmitButtonsField child={true}>
					<button
						id='Element'
						className='secondary'
						type='button'
						onClick={() => {
							onCancel();
						}}
					>
						Cancel
					</button>

					<button
						type='submit'
						className='d-flex align-items-center'
						id='Element'
						disabled={
							actionLoading ||
							(data?.password === '' && data.new_password === '')
						}
						onClick={handleSubmit}
					>
						{actionLoading ? (
							<CircularProgress
								size={24}
								sx={{ color: 'white' }}
								disableShrink
							/>
						) : (
							'Update'
						)}
					</button>
				</SubmitButtonsField>
			</div>
		</form>
	);
};

export default ChangePasswordModal;
