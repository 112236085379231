import { secure_instance } from '../axios/axios-config';

export const saveTimeTrackingRequest = (request = null) => {
	if (request) localStorage.setItem('time_tracking_request', JSON.stringify(request));
};

export const getTimeTrackingPendingRequests = () => {
	return JSON.parse(localStorage.getItem('time_tracking_request')) || null;
};

export const clearTimeTrackingPendingRequests = () => {
	localStorage.removeItem('time_tracking_request');
};

export const syncTimeTrackingPendingRequests = async () => {
	const pendingRequest = getTimeTrackingPendingRequests();

	if (pendingRequest) {
		try {
			await secure_instance.request(pendingRequest);
		} catch (error) {
			console.error('Failed to sync request:', pendingRequest, error);
			// If sync fails, you might want to retry or leave the request for the next attempt.
		}

		clearTimeTrackingPendingRequests();
	}
};
