const Select = ({
	onChange,
	value,
	options,
	selectClassName,
	placeholder,
	required,
	disabled,
	style,
    name
}) => {
	return (
		<select
			className={`PickList d-flex flex-column w-100 m-0 ${selectClassName}`}
			name={name}
			onChange={onChange}
			required={required}
			disabled={disabled}
			style={style}
			value={value?.value ? value.value : ''}
			data-cy={name}
		>
			<option value=''>{placeholder ? placeholder : ''}</option>
			{options?.map((opt, i) => {
				return (
					<option key={i} value={opt.value} disabled={opt.is_active}>
						{opt.label}
					</option>
				);
			})}
		</select>
	);
};

export default Select;
