import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	tableCellClasses,
	styled,
} from '@mui/material';
import { convertUnixDate } from '../../../utilities/utilities';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: '#FCFDFD',
		color: '#696F6C',
		fontFamily: 'CentraLight',
		fontSize: 14,
		fontWeight: 300,
		borderBottom: '1px solid white',
		width: 'fit-content',
	},
	[`&.${tableCellClasses.body}`]: {
		backgroundColor: '#F7F9FA',
		color: '#333333',
		fontFamily: 'CentraLight',
		fontSize: 14,
		fontWeight: 300,
		height: '76px',
		borderBottom: '1px solid white',
		borderTop: '1px solid white',
		width: 'fit-content',
	},
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&:nth-of-type(odd)': {
		backgroundColor: theme.palette.action.hover,
	},
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0,
	},
}));
const TransactionHistoryTable = ({ customerInvoices }) => {
	return (
		<div className='w-100'>
			<Paper sx={{ width: '100%', overflow: 'hidden', boxShadow: 'none' }}>
				<TableContainer sx={{ maxHeight: 304 }}>
					<Table stickyHeader aria-label='sticky table' sx={{ minWidth: 1000 }}>
						<TableHead>
							<TableRow>
								<StyledTableCell>Transaction ID</StyledTableCell>
								<StyledTableCell>Billing Period</StyledTableCell>
								<StyledTableCell>Discount</StyledTableCell>
								<StyledTableCell>Total Cost</StyledTableCell>
								<StyledTableCell>Status</StyledTableCell>
								<StyledTableCell></StyledTableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{customerInvoices?.data?.length > 0 &&
								customerInvoices?.data.map((invoice, index) => (
									<StyledTableRow key={index}>
										<StyledTableCell>{invoice?.id}</StyledTableCell>
										<StyledTableCell>{`${convertUnixDate(
											invoice?.lines?.data?.[0]?.period?.start,
										)} - ${convertUnixDate(
											invoice?.lines?.data?.[0]?.period?.end,
										)}`}</StyledTableCell>
										{/* <StyledTableCell>{''}</StyledTableCell> */}
										<StyledTableCell>
											{invoice?.discount?.coupon?.percent_off || '0'}%
										</StyledTableCell>
										<StyledTableCell>
											${invoice?.amount_due / 100}
										</StyledTableCell>
										<StyledTableCell
											style={{
												color: invoice?.paid == true ? '#228B22' : 'red',
												fontWeight: '500',
											}}
										>
											{invoice?.paid == true ? 'Paid' : 'Unpaid'}
										</StyledTableCell>
										<a
											target='_blank'
											href={invoice?.hosted_invoice_url}
											rel='noreferrer'
											style={{ textDecoration: 'none', outline: 'none' }}
										>
											<StyledTableCell className='primary-text pointer d-flex w-100 justify-content-center align-items-center'>
												View Invoice
											</StyledTableCell>
										</a>
									</StyledTableRow>
								))}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
		</div>
	);
};
export default TransactionHistoryTable;
