import { useSelector } from 'react-redux';

const FeatureFlag = ({ tenantIds, children }) => {
	const user = useSelector((state) => state.authReducer.user);
	const tenantId = user?.tenantId;

	return <>{tenantIds?.includes(tenantId) ? children : null}</>;
};

export default FeatureFlag;
