/* eslint-disable no-mixed-spaces-and-tabs */
import { useState } from 'react';
import { ReactComponent as ArrowUp } from '../assets/icons/up.svg';
import { ReactComponent as ArrowDown } from '../assets/icons/down.svg';

function UploadDocumentConfirmation({ logo_url, title, content, upload_status }) {
	const [isExpanded, setIsExpanded] = useState(false);
	const [fileStatus] = useState(upload_status || undefined);
	const uploadResults = fileStatus
		? Object.keys(fileStatus).map((file) => ({
				file: file,
				status: {
					status: fileStatus[file].status,
					error: fileStatus[file].error,
				},
		  }))
		: [];
	const completed = uploadResults.filter((file) => file?.status?.status === 1).length;
	const failed = uploadResults.filter((file) => file?.status?.status === -1);

	return (
		<div className='w-100 h-100 d-flex align-items-center justify-content-center'>
			<div className='document-upload-success'>
				<div>
					<div className='mb-2'>{logo_url && <img src={logo_url} width={100} />}</div>
					<h2 className='HeadlineThreeBold mb-1'>
						{fileStatus
							? failed?.length == 0
								? title
								: `${completed} ${
										completed == 1
											? 'File Uploaded Successfully'
											: 'Files Uploaded Successfully'
								  }`
							: title}
					</h2>
					<p className='BodyTwoLight text-left'>{content}</p>
					{failed?.length > 0 && (
						<div className='mt-3 text-left'>
							<p className='BodyTwoLight text-left mt-1'>
								Following files could not be uploaded due to some reason:
							</p>
							<div
								className='d-flex align-items-center justify-content-between failed-files-title'
								onClick={() => setIsExpanded(!isExpanded)}
							>
								<span className='BodyTwoLight light-text'>Files</span>
								{isExpanded ? <ArrowUp /> : <ArrowDown />}
							</div>
							{isExpanded && (
								<div className='failed-files-list'>
									{failed?.map((file) => {
										return (
											<div
												key={file?.file}
												className='d-flex justify-content-between failed-list-item'
											>
												<span className='BodyTwoLight dark-text w-50 tex-left'>
													{file?.file}
												</span>
												<span className='BodyTwoLight error-text w-50 text-right'>
													{file?.status?.error}
												</span>
											</div>
										);
									})}
								</div>
							)}
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

export default UploadDocumentConfirmation;
