/* eslint-disable no-mixed-spaces-and-tabs */
// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from 'react';

import TableHeader from '../Others/TableHeader';
import TableHeaderItem from '../Others/TableHeaderItem';
import {
	TableItemWidth1,
	TableItemWidth3,
	TableItemWidth4,
} from '../TableSpacing/TableSpacingStyles';
import TableRowItem from '../Others/TableRowItem';
import useModal from '../Modal/useModal';
import DummyModal from '../Modal/DummyModal';

import AddIcon from '@mui/icons-material/Add';
import { Modal } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import * as clientActions from '../../redux/actions/client-actions';
import * as clientAccountActions from '../../redux/actions/client-accounts-actions';
import EditableText from '../Inputs/EditableText';
import {
	getAccountAccessOfRoles,
	getRoleName,
	hideAccountNumber,
	objectBeingDragged,
	standardPermissions,
	truncateString,
} from '../../utilities/utilities';
import { useParams } from 'react-router-dom';
import { ReactComponent as Up } from '../../assets/icons/up.svg';
import { ReactComponent as Down } from '../../assets/icons/down.svg';
import AddPaymentPlatform from './Account Modals/AddPaymentPlatform';
import AddBankAccount from './Account Modals/AddBankAccount';
import AddCreditCardAccount from './Account Modals/AddCreditCardAccount';
import AddMerchantAccount from './Account Modals/AddMerchantAccount';
import AddSoftware from './Account Modals/AddSoftware';
import TableRowButton from '../Others/TableRowButton';
import { ReactComponent as TrashIcon } from '../../assets/icons/Trash-Light.svg';
import { Tooltip } from '@mui/material';
import ConfirmModal from '../Modal/ConfirmModal';
import { ReactComponent as EditIcon } from '../../assets/icons/PencilEdit.svg';
import ReactHtmlParser from 'react-html-parser';
import NotesModal from './Modals/NotesModal';
import AddLink from './Modals/AddLink';
import Restricted from '../Permissions/Restricted';

function SoftwareStack() {
	console.info('now SoftwareStack');

	const dispatch = useDispatch();
	const { id } = useParams();

	const user = useSelector((state) => state.authReducer.user);
	const client = useSelector((state) => state.clientReducer.client);
	const userTeamRole = useSelector((state) => state.clientReducer.userTeamRole);

	const clientAccounts = useSelector(
		(state) => state.clientAccountsReducer.clientAccounts,
	);
	const { dragValues } = useSelector((state) => state.clientReducer);
	const { modalIsOpen, toggleModal, ModalName, ModalType } = useModal();
	const urls = useSelector((state) => state.clientAccountsReducer.allUrls);

	const [showCredentials, setShowCredentials] = useState({});
	const [showAllTabs, setShowAllTabs] = useState(false);

	const [loadingItems, setLoadingItems] = useState([]);
	const [selectedSoftwareID, setSelectedSoftwareID] = useState(null);
	const [accountNotes, setAccountNotes] = useState('');
	const [indexToDelete, setIndexToDelete] = useState(null);
	const [sharedNotes, setSharedNotes] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	const removeItem = (index) => {
		ModalType('LINKS_CONFIRM_MODAL');
		toggleModal();
		setIndexToDelete(index);
	};

	const removeLink = () => {
		setIsLoading(true);
		const linkId = client?.links?.find(
			(c, index) => index === indexToDelete,
		)?.id;

		dispatch(clientActions.removeClientSharedLink(linkId, indexToDelete)).then(
			() => {
				setIsLoading(false);
				toggleModal();
			},
		);
	};

	const handleOnChange = (event, softwareId, softwareStackId) => {
		event.preventDefault();

		const { name, value } = event.target;
		dispatch(
			clientAccountActions.clientSoftwareChange({
				name,
				value,
				softwareId,
				softwareStackId,
			}),
		);
	};

	const [accountsExpand, setAccountsExpand] = useState({
		SOFTWARE: false,
		BANK_ACCOUNT: false,
		CREDIT_CARD_ACCOUNT: false,
		MERCHANT_ACCOUNT: false,
		PAYMENT_PORTAL: false,
	});
	const [objectTypeBeingDragged, setObjectTypeBeingDragged] = useState(null);
	const toggleAccountExpand = (name) => {
		setAccountsExpand({
			...accountsExpand,
			[name]: !accountsExpand[name],
		});
	};

	const accountTypes = [
		{
			displayName: 'Software',
			type: objectBeingDragged.SOFTWARE,
			get addText() {
				return this.displayName;
			},
		},
		{
			displayName: 'Credit Card Account',
			type: objectBeingDragged.CREDIT_CARD_ACCOUNT,
			get addText() {
				return this.displayName;
			},
		},
		{
			displayName: 'Bank Account',
			type: objectBeingDragged.BANK_ACCOUNT,
			get addText() {
				return this.displayName;
			},
		},
		// {
		// 	displayName: 'Merchant Account',
		// 	type: objectBeingDragged.MERCHANT_ACCOUNT,
		// },
		{
			displayName: 'Other Portals',
			type: objectBeingDragged.PAYMENT_PORTAL,
			get addText() {
				return 'Portal';
			},
		},
		{
			displayName: 'Shared Drives & Links',
			type: objectBeingDragged.SHAREDLINS,
			get addText() {
				return this.displayName;
			},
		},
	];

	const getCredentialsFromVault = (software) => {
		dispatch(
			clientActions.getSoftwareDetails({
				...software,
				login:
					urls?.find(({ id }) => id == software?.institution_url_id)?.url ||
					software?.custom_url,
				clientName:
					client?.dba?.trim() == '' ? client?.company_name : client?.dba,
			}),
		);
	};

	const removeSoftware = (softwareId) => {
		dispatch(
			clientAccountActions.deleteClientSoftware(
				softwareId,
				id,
				setLoadingItems,
				loadingItems,
			),
		).then(() => {
			toggleModal();
		});
	};

	const handleAccountRemove = () => {
		ModalType('CONFIRM_MODAL');
		toggleModal();
	};

	const confirmAccountRemove = () => {
		setLoadingItems([...loadingItems, selectedSoftwareID]);
		removeSoftware(selectedSoftwareID);
	};

	useEffect(() => {
		dispatch(clientAccountActions.getClientAccounts(id));
		dispatch(clientAccountActions.getAllUrls());
	}, [clientAccounts?.length, id]);

	useEffect(() => {
		if (
			dragValues.beingDragged !== null &&
			dragValues.dragTo !== null &&
			dragValues.beingDragged !== dragValues.dragTo &&
			dragValues.objectBeingDragged === objectTypeBeingDragged
		) {
			const accountList = clientAccounts?.find(
				(acc) => acc.type === objectTypeBeingDragged,
			)?.softwares;
			dispatch(
				clientAccountActions.changeClientAccountsIndex({
					account: accountList,
					beingDragged: dragValues.beingDragged,
					dragTo: dragValues.dragTo,
					objectBeingDragged: objectTypeBeingDragged,
				}),
			);
		}
	}, [dragValues]);

	const toggleCredentials = (id) => {
		setShowCredentials((prevState) => ({
			...prevState,
			[id]: !prevState[id],
		}));
	};

	const expandAllTabs = () => {
		setAccountsExpand({
			SOFTWARE: !showAllTabs,
			BANK_ACCOUNT: !showAllTabs,
			CREDIT_CARD_ACCOUNT: !showAllTabs,
			MERCHANT_ACCOUNT: !showAllTabs,
			PAYMENT_PORTAL: !showAllTabs,
		});
		setShowAllTabs(!showAllTabs);
	};

	const viewContactNotes = (notes) => {
		setSharedNotes(notes);
		ModalType('VIEW_SHARED_LINK_NOTES_MODAL');
		toggleModal();
	};

	const viewNotes = (notes) => {
		setAccountNotes(notes);
		ModalType('VIEW_NOTES_MODAL');
		toggleModal();
	};

	return (
		<>
			<div
				className='d-flex flex-column w-100 align-items-center '
				style={{ gap: '1em' }}
			>
				<div className='w-100 d-flex flex-column'>
					<div
						className='Teams-Table d-flex flex-column'
						style={{ border: 'none', gap: '18px' }}
					>
						{accountTypes?.map((account, i) => {
							return (
								<div
									key={i}
									style={{
										boxShadow: '0px 8px 24px rgba(149, 157, 165, 0.2)',
									}}
								>
									<div
										key={i}
										className='TableRow d-flex align-items-center justify-content-between'
										data-cy={`${account.displayName}-account-expand`}
										style={{
											padding: '8px 12px 8px 12px',
											borderRadius: '4px',
											backgroundColor: '#FCFDFD',
										}}
										onClick={
											accountsExpand[account?.type]
												? () => toggleAccountExpand(account?.type)
												: () => toggleAccountExpand(account?.type)
										}
									>
										<TableRowItem
											ClassName='text-left'
											input={true}
											width={TableItemWidth4}
										>
											<span className='BodyTwoLight text-left'>
												{account.displayName}
											</span>
										</TableRowItem>

										<TableRowItem
											ClassName='text-left'
											width={TableItemWidth3}
											input={true}
										></TableRowItem>

										<TableRowItem
											ClassName='text-left'
											width={TableItemWidth3}
											input={true}
										></TableRowItem>

										<TableRowItem
											ClassName='text-left'
											width={TableItemWidth3}
											input={true}
										></TableRowItem>

										<TableRowItem
											ClassName='text-right'
											width={TableItemWidth3}
											input={true}
										>
											{accountsExpand[account?.type] ? (
												<span>
													<Up />
												</span>
											) : (
												<span>
													<Down />
												</span>
											)}
										</TableRowItem>
									</div>

									{accountsExpand[account?.type] && (
										<>
											{account?.type != objectBeingDragged.SHAREDLINS ? (
												<>
													{clientAccounts
														?.find((acc) => acc.type === account.type)
														?.softwares?.filter((software) =>
															getAccountAccessOfRoles(
																software?.role_id,
															)?.includes(userTeamRole),
														)?.length ? (
														<>
															<TableHeader
																ClassName='TableHeader flex'
																border={false}
																backgroundcolor='#FFFFFF'
															>
																<TableHeaderItem
																	label='Name'
																	width={TableItemWidth3}
																/>
																<TableHeaderItem
																	label='URL'
																	width={TableItemWidth3}
																/>
																<TableHeaderItem
																	label='Username'
																	width={TableItemWidth3}
																/>
																<TableHeaderItem
																	label='Password'
																	width={TableItemWidth3}
																/>
																<TableHeaderItem
																	label='Description'
																	width={TableItemWidth3}
																/>
                                                                <TableHeaderItem
																	label='Notes'
																	width={TableItemWidth3}
																/>
																<TableHeaderItem
																	label='Permissions'
																	width={TableItemWidth3}
																/>
																<Restricted
																	AllowedUsers={standardPermissions}
																	userTeamRole={[userTeamRole]}
																>
																	<TableHeaderItem
																		label='Action'
																		width={TableItemWidth1}
																	/>
																</Restricted>
															</TableHeader>

															{clientAccounts
																?.find((acc) => acc.type === account.type)
																?.softwares?.filter((software) =>
																	getAccountAccessOfRoles(
																		software?.role_id,
																	)?.includes(userTeamRole),
																)
																?.map((software, i) => {
																	const softwareStackId = clientAccounts?.find(
																		(acc) => acc.type === account.type,
																	)?.id;
																	return (
																		<div
																			data-cy={`account-${software.id}`}
																			key={i}
																			className='TableRow d-flex align-items-center border-0'
																			draggable={true}
																			onDragStart={() => {
																				setObjectTypeBeingDragged(account.type);
																				dispatch(
																					clientActions.setDragValues({
																						dragTo: null,
																						beingDragged: null,
																						objectBeingDragged: account.type,
																					}),
																				);
																			}}
																			onDragEnd={() => {
																				dispatch(
																					clientActions.setDragValues({
																						beingDragged: i,
																					}),
																				);
																			}}
																			onDragEnter={() => {
																				dispatch(
																					clientActions.setDragValues({
																						dragTo: i,
																					}),
																				);
																			}}
																		>
																			<TableRowItem
																				ClassName='text-left'
																				label={
																					software.name ||
																					urls?.find(
																						(item) =>
																							item.id ==
																							software.institution_url_id,
																					)?.institution
																				}
																				width={TableItemWidth3}
																				input={false}
																			>
																				<EditableText
																					onChange={(e) =>
																						handleOnChange(
																							e,
																							software.id,
																							softwareStackId,
																						)
																					}
																					name='institution'
																					value={software?.institution}
																					Edit={false}
																				/>
																			</TableRowItem>

																			<TableRowItem
																				ClassName='text-left'
																				label={software?.login}
																				width={TableItemWidth3}
																				input={true}
																			>
																				<a
																					className='BodyTwoLight mb-0 primary-text extension-call '
																					onClick={() => {
																						getCredentialsFromVault(software);
																					}}
																					target='_blank'
																					rel='noreferrer'
																					style={{
																						display: 'inline-block',
																						maxWidth: '120px',
																						whiteSpace: 'nowrap',
																						overflow: 'hidden',
																						textOverflow: 'ellipsis',
																					}}
																				>
																					{urls
																						?.find(
																							({ id }) =>
																								id ==
																								software?.institution_url_id,
																						)
																						?.url?.replace('https://', '')
																						?.replace('http://', '') ||
																						software?.custom_url
																							?.replace('https://', '')
																							?.replace('http://', '')}
																				</a>
																			</TableRowItem>

																			<TableRowItem
																				ClassName='text-left'
																				label={
																					showCredentials[i]
																						? software?.account
																						: hideAccountNumber(
																								software?.account,
																						  )
																				}
																				width={TableItemWidth3}
																				input={false}
																			>
																				<EditableText
																					onChange={(e) =>
																						handleOnChange(
																							e,
																							software.id,
																							softwareStackId,
																						)
																					}
																					name='account'
																					value={software?.account}
																					Edit={false}
																				/>
																			</TableRowItem>

																			<TableRowItem
																				ClassName='text-left'
																				label={
																					showCredentials[i]
																						? software.password
																						: '*'.repeat(7)
																				}
																				width={TableItemWidth3}
																				input={false}
																			>
																				<EditableText
																					onChange={(e) =>
																						handleOnChange(
																							e,
																							software.id,
																							softwareStackId,
																						)
																					}
																					name='password'
																					type='password'
																					placeholder={'*'.repeat(10)}
																					Edit={false}
																				/>
																			</TableRowItem>

																			<TableRowItem
																				ClassName='text-left'
																				label={
																					<div>
																						<p className='BodyTwoLight dark-text m-0 text-break'>
																							{software?.description?.length < 1 ||
																							software?.description == null ||
																							software?.description
																								?.replace(/<[^>]+>/g, '')
																								?.trim()?.length == 0
																								? '-'
																								: ReactHtmlParser(
																										truncateString(
																											software?.description,
																										),
																								  )}
																						</p>
																						{software?.description?.length > 28 && (
																							<span
																								onClick={() => {
																									viewNotes(software?.description);
																								}}
																								className='primary-text BodyThreeLight'
																							>
																								Read More
																							</span>
																						)}
																					</div>
																				}
																				width={TableItemWidth3}
																				input={false}
																			>
																				<EditableText
																					onChange={(e) =>
																						handleOnChange(
																							e,
																							software.id,
																							softwareStackId,
																						)
																					}
																					name='notes'
																					value={software?.description}
																					Edit={false}
																				/>
																			</TableRowItem>

                                                                            <TableRowItem
																				ClassName='text-left'
																				label={
																					<div>
																						<p className='BodyTwoLight dark-text m-0 text-break'>
																							{software?.notes?.length < 1 ||
																							software?.notes == null ||
																							software?.notes
																								?.replace(/<[^>]+>/g, '')
																								?.trim()?.length == 0
																								? '-'
																								: ReactHtmlParser(
																										truncateString(
																											software?.notes,
																										),
																								  )}
																						</p>
																						{software?.notes?.length > 28 && (
																							<span
																								onClick={() => {
																									viewNotes(software?.notes);
																								}}
																								className='primary-text BodyThreeLight'
																							>
																								Read More
																							</span>
																						)}
																					</div>
																				}
																				width={TableItemWidth3}
																				input={false}
																			>
																				<EditableText
																					onChange={(e) =>
																						handleOnChange(
																							e,
																							software.id,
																							softwareStackId,
																						)
																					}
																					name='notes'
																					value={software?.notes}
																					Edit={false}
																				/>
																			</TableRowItem>

																			<TableRowItem
																				ClassName='text-left'
																				label={getRoleName(software.role_id)}
																				width={TableItemWidth3}
																				input={false}
																			/>
																			<Restricted
																				AllowedUsers={standardPermissions}
																				userTeamRole={[userTeamRole]}
																			>
																				<TableRowButton
																					ClassName='d-flex align-items-center justify-content-center'
																					width={TableItemWidth1}
																				>
																					<div
																						className='d-flex flex-row align-items-center'
																						style={{
																							gap: '7px',
																						}}
																					>
																						{/* <div
																							style={{
																								color: '#fb7a03',
																								minWidth: '100px',
																							}}
																							className='BodyThreeLight mb-0 text-left'
																							onClick={() =>
																								toggleCredentials(i)
																							}
																						>
																							{showCredentials[i]
																								? 'Hide'
																								: 'View'}{' '}
																							Credentials
																						</div> */}

																						<Tooltip title='Edit Account'>
																							<EditIcon
																								data-cy='client-account-edit'
																								fontSize='small'
																								style={{
																									color: '#fb7a03',
																								}}
																								onClick={() => {
																									dispatch(
																										clientAccountActions.setEditForm(
																											software,
																										),
																									);
																									dispatch(
																										clientAccountActions.setError(
																											'',
																										),
																									);
																									ModalType(account?.type);
																									toggleModal();
																								}}
																							/>
																						</Tooltip>

																						<Tooltip title='Remove Account'>
																							<TrashIcon
																								data-cy='client-account-delete'
																								ontSize='small'
																								sx={{
																									color: '#fb7a03',
																								}}
																								onClick={() => {
																									handleAccountRemove();
																									setSelectedSoftwareID(
																										software.id,
																									);
																								}}
																								className='pointer'
																							/>
																						</Tooltip>
																					</div>
																				</TableRowButton>
																			</Restricted>
																		</div>
																	);
																})}
														</>
													) : (
														<div className='TableRow h-100 w-100 d-flex align-items-center justify-content-center'>
															<h4 className='BodyTwoLight mb-0 placeholder-text'>
																The Client has no account
															</h4>
														</div>
													)}

													<Restricted
														AllowedUsers={standardPermissions}
														userTeamRole={[userTeamRole]}
													>
														<div
															className='d-flex justify-content-center information-header d-flex align-items-center'
															style={{ width: '100%' }}
														>
															<div
																className='pointer d-flex flex-row justify-content-start'
																style={{
																	height: '22px',
																	width: '6px',
																	borderRadius: '3px',
																	marginRight: '1rem',
																	padding: 0,
																	position: 'relative',
																	// backgroundColor: '#fb7a03',
																}}
																data-cy={`${account.displayName}-add-btn`}
																onClick={() => {
																	dispatch(
																		clientAccountActions.setEditForm(undefined),
																	);
																	dispatch(clientAccountActions.resetForms());
																	dispatch(clientAccountActions.setError(''));
																	ModalType(account?.type);
																	toggleModal();
																}}
															>
																<AddIcon
																	fontSize='small'
																	sx={{
																		color: '#fb7a03',
																		fontSize: 21,
																		position: 'absolute',
																		margin: 0,
																		padding: 0,
																		top: 0,
																		left: 0,
																	}}
																/>
															</div>
															<h3
																className='BodyTwoLight mt-2 dark-text'
																style={{ color: '#FB7A03', cursor: 'pointer' }}
																onClick={() => {
																	dispatch(
																		clientAccountActions.setEditForm(undefined),
																	);
																	dispatch(clientAccountActions.resetForms());
																	dispatch(clientAccountActions.setError(''));
																	ModalType(account?.type);
																	toggleModal();
																}}
															>
																Add New {account.addText}
															</h3>
														</div>
													</Restricted>
												</>
											) : (
												<>
													<div className='w-100 d-flex flex-column information-content'>
														<div
															className='Teams-Table'
															style={{ border: 'none' }}
														>
															<TableHeader
																ClassName='TableHeader flex'
																border={false}
															>
																<TableHeaderItem
																	label={'Name'}
																	width={TableItemWidth3}
																/>
																<TableHeaderItem
																	label={'URL'}
																	width={TableItemWidth3}
																/>
																<TableHeaderItem
																	label={'Notes'}
																	width={TableItemWidth3}
																/>

																<Restricted
																	AllowedUsers={standardPermissions}
																	userTeamRole={[userTeamRole]}
																>
																	<TableHeaderItem
																		label={'Action'}
																		width={TableItemWidth1}
																	/>
																</Restricted>
															</TableHeader>

															{client?.links?.length < 1 ? (
																<div className='TableRow h-100 w-100 d-flex align-items-center justify-content-center'>
																	<h4 className='BodyTwoLight m-0 placeholder-text'>
																		The Client has no Links
																	</h4>
																</div>
															) : (
																client?.links?.map((item, i) => {
																	return (
																		<div
																			key={i}
																			className='TableRow d-flex align-items-center'
																			draggable={true}
																			onDragStart={() => {
																				dispatch(
																					clientActions.setDragValues({
																						dragTo: null,
																						beingDragged: null,
																						objectBeingDragged:
																							objectBeingDragged.CLIENT_LINKS,
																					}),
																				);
																			}}
																			onDragEnd={() => {
																				dispatch(
																					clientActions.setDragValues({
																						beingDragged: i,
																					}),
																				);
																			}}
																			onDragEnter={() => {
																				dispatch(
																					clientActions.setDragValues({
																						dragTo: i,
																					}),
																				);
																			}}
																		>
																			<TableRowItem
																				ClassName='text-left'
																				label={item.name}
																				width={TableItemWidth3}
																				// input={edit}
																			/>
																			<TableRowItem
																				ClassName='text-left'
																				label={item.login}
																				width={TableItemWidth3}
																				input={true}
																			>
																				<a
																					className='BodyTwoLight m-0 primary-text extension-call '
																					target='_blank'
																					rel='noreferrer'
																					href={item?.login}
																					style={{
																						display: 'inline-block',
																						maxWidth: '120px',
																						whiteSpace: 'nowrap',
																						overflow: 'hidden',
																						textOverflow: 'ellipsis',
																					}}
																				>
																					{item?.login}
																				</a>
																				{/* )} */}
																			</TableRowItem>
																			<TableRowItem
																				ClassName='text-left'
																				label={
																					<div>
																						<p className='BodyTwoLight dark-text m-0 text-break'>
																							{item?.notes?.length < 1 ||
																							item?.notes == null ||
																							item?.notes
																								?.replace(/<[^>]+>/g, '')
																								?.trim()?.length == 0
																								? '-'
																								: ReactHtmlParser(
																										truncateString(item?.notes),
																								  )}
																						</p>
																						{item?.notes?.length > 28 && (
																							<span
																								onClick={() => {
																									viewContactNotes(item?.notes);
																								}}
																								className='primary-text BodyThreeLight'
																							>
																								Read More
																							</span>
																						)}
																					</div>
																				}
																				width={TableItemWidth3}
																			/>

																			<Restricted
																				AllowedUsers={standardPermissions}
																				userTeamRole={[userTeamRole]}
																			>
																				<TableRowButton
																					ClassName='d-flex align-items-center justify-content-center ml-0'
																					width={TableItemWidth1}
																				>
																					<div
																						className='d-flex flex-row align-items-center'
																						style={{
																							gap: '7px',
																						}}
																					>
																						<Tooltip title='Edit Link'>
																							<EditIcon
																								fontSize='15px'
																								style={{
																									color: '#fb7a03',
																								}}
																								onClick={() => {
																									ModalType('EDIT_LINKS_MODAL');
																									dispatch(
																										clientActions.linksToEdit({
																											...item,
																											index: i,
																										}),
																									);
																									toggleModal();
																								}}
																							/>
																						</Tooltip>
																						<Tooltip title='Remove Link'>
																							<TrashIcon
																								fontSize='10px'
																								sx={{
																									color: '#fb7a03',
																								}}
																								onClick={() => {
																									removeItem(i);
																								}}
																								className='pointer'
																							/>
																						</Tooltip>
																					</div>
																				</TableRowButton>
																			</Restricted>
																		</div>
																	);
																})
															)}

															<Restricted
																AllowedUsers={standardPermissions}
																userTeamRole={[userTeamRole]}
															>
																<div
																	className='d-flex justify-content-center information-header d-flex align-items-center'
																	style={{ width: '100%' }}
																>
																	<div
																		className='pointer d-flex flex-row justify-content-start'
																		style={{
																			height: '22px',
																			width: '6px',
																			borderRadius: '3px',
																			marginRight: '1rem',
																			padding: 0,
																			position: 'relative',
																			// backgroundColor: '#fb7a03',
																		}}
																		onClick={() => {
																			ModalType('NEW_LINKS_MODAL');
																			toggleModal();
																			dispatch(clientActions.resetLinkValues());
																		}}
																	>
																		<AddIcon
																			fontSize='small'
																			sx={{
																				color: '#fb7a03',
																				fontSize: 21,
																				position: 'absolute',
																				margin: 0,
																				padding: 0,
																				top: 0,
																				left: 0,
																			}}
																		/>
																	</div>
																	<h3
																		className='BodyTwoLight mt-2 dark-text'
																		style={{
																			color: '#FB7A03',
																			cursor: 'pointer',
																		}}
																		onClick={() => {
																			ModalType('NEW_LINKS_MODAL');
																			toggleModal();
																			dispatch(clientActions.resetLinkValues());
																		}}
																	>
																		Add New Shared Drives & Links
																	</h3>
																</div>
															</Restricted>
														</div>
													</div>
												</>
											)}
										</>
									)}
								</div>
							);
						})}
					</div>
				</div>
			</div>

			{modalIsOpen && (
				<Modal
					onClose={toggleModal}
					open={modalIsOpen}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<>
						{ModalName == 'PAYMENT_PORTAL' && (
							<DummyModal title={'Add Other Portals'} onClose={toggleModal}>
								<AddPaymentPlatform
									onCancel={toggleModal}
									accountType={ModalName}
								/>
							</DummyModal>
						)}
						{ModalName == 'BANK_ACCOUNT' && (
							<DummyModal title={'Add Bank Account'} onClose={toggleModal}>
								<AddBankAccount
									onCancel={toggleModal}
									accountType={ModalName}
								/>
							</DummyModal>
						)}
						{ModalName == 'CREDIT_CARD_ACCOUNT' && (
							<DummyModal
								title={'Add Credit Card Account'}
								onClose={toggleModal}
							>
								<AddCreditCardAccount
									onCancel={toggleModal}
									accountType={ModalName}
								/>
							</DummyModal>
						)}
						{ModalName == 'SOFTWARE' && (
							<DummyModal title={'Add Software'} onClose={toggleModal}>
								<AddSoftware onCancel={toggleModal} accountType={ModalName} />
							</DummyModal>
						)}
						{ModalName == 'MERCHANT_ACCOUNT' && (
							<DummyModal title={'Add Merchant Account'} onClose={toggleModal}>
								<AddMerchantAccount
									onCancel={toggleModal}
									accountType={ModalName}
								/>
							</DummyModal>
						)}
						{ModalName == 'CONFIRM_MODAL' && (
							<DummyModal title={'Delete Account'} onClose={toggleModal}>
								<ConfirmModal
									description={
										'You’re about to delete this account. Are you sure to want to continue?'
									}
									OnCancel={() => toggleModal()}
									Onsubmit={() => {
										confirmAccountRemove();
									}}
									descriptionClassName='text-left'
									isLoading={loadingItems.includes(selectedSoftwareID) && true}
								/>
							</DummyModal>
						)}

						{ModalName == 'VIEW_NOTES_MODAL' && (
							<DummyModal title='Description' onClose={toggleModal}>
								<NotesModal
									edit={false}
									onCancel={toggleModal}
									initialNotes={accountNotes}
								/>
							</DummyModal>
						)}
						{ModalName == 'VIEW_SHARED_LINK_NOTES_MODAL' && (
							<DummyModal title='Notes' onClose={toggleModal}>
								<NotesModal
									edit={false}
									onCancel={toggleModal}
									initialNotes={sharedNotes}
								/>
							</DummyModal>
						)}
						{ModalName == 'LINKS_CONFIRM_MODAL' && (
							<DummyModal title={'Delete Link'} onClose={toggleModal}>
								<ConfirmModal
									description={
										'You’re about to delete this link. Are you sure to want to continue?'
									}
									OnCancel={() => toggleModal()}
									Onsubmit={() => {
										removeLink();
									}}
									descriptionClassName='text-left'
									isLoading={isLoading}
								/>
							</DummyModal>
						)}
						{ModalName == 'NEW_LINKS_MODAL' && (
							<DummyModal title='Add Link' onClose={toggleModal}>
								<AddLink onCancel={toggleModal} />
							</DummyModal>
						)}
						{ModalName == 'EDIT_LINKS_MODAL' && (
							<DummyModal title='Edit Link' onClose={toggleModal}>
								<AddLink onCancel={toggleModal} beingEdited={true} />
							</DummyModal>
						)}
					</>
				</Modal>
			)}
		</>
	);
}

export default SoftwareStack;
