import React from 'react';
import { Button } from '@mui/material';

const CardHeader = ({
	title,
	onClick,
	buttonLabel = 'Edit',
	secondaryBtn,
	secondaryBtnClick,
}) => {
	return (
		<div className='card-header justify-content-between pointer'>
			<h1 className='HeadlineOneBold text-left dark-text m-0'>{title}</h1>
			{/* <ButtonSquare onClick={onClick}>{buttonLabel}</ButtonSquare> */}

			{secondaryBtn && (
				<span
					onClick={secondaryBtnClick}
					className='BodyTwoLight mx-3 primary-text'
				>
					{secondaryBtn}
				</span>
			)}
		</div>
	);
};

export default CardHeader;
