
export default function DocumentHeader({ headers }) {
	return (
		<div className='document-storage-header'>
			<div className='d-flex align-items-center'>
				<span className='BodyTwoLight' style={{ color: '#696F6C' }}>
					Name
				</span>
			</div>
			<div className='d-flex align-items-center'>
				{headers.map((header, index) => (
					<div key={index} className='d-flex align-items-center'>
						<div
							key={header.key}
							style={{ width: header.width }}
							className='d-flex align-items-center'
						>
							<span className='BodyTwoLight' style={{ color: '#696F6C' }}>
								{header.label}
							</span>
						</div>
					</div>
				))}
			</div>
		</div>
	);
}
