import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as clientActions from '../../../redux/actions/client-actions';
import { useHistory, useParams } from 'react-router';
import { getUserSettings } from '../../../hooks/getUserSettings';
import * as invoiceActions from '../../../redux/actions/invoices-actions';
import { CircularProgress } from '@mui/material';
import { setSnackBarIsOpen } from '../../../redux/actions/dashboard/workspaces-actions';

function GenerateInvoice({ onCancel }) {
	const dispatch = useDispatch();
	const history = useHistory();
	const { id } = useParams();
	const [loading, setLoading] = useState(false);

	const userDetails = useSelector((state) => state.authReducer.userDetails);
	const { date_format } = getUserSettings(userDetails);
	const clientBilling = useSelector((state) => state.clientBillingReducer.clientBilling);

	if (clientBilling?.length) {
		var oldestBill = clientBilling?.reduce((oldest, current) => {
			var oldestStartDate = moment(oldest.bill_start_date);
			var currentStartDate = moment(current.bill_start_date);

			return currentStartDate < oldestStartDate ? current : oldest;
		});
	}

    console.log(clientBilling,'clientBilling123');
	const clientInvoice = useSelector((state) => state.clientReducer.clientInvoice);

	const formSubmit = (event) => {
		event.preventDefault();
		setLoading(true);
		dispatch(
			invoiceActions.createInvoice({
				client_id: id,
				from_date: moment(clientInvoice?.start_date).format('MM/DD/YYYY'),
				to_date: moment(clientInvoice?.end_date).format('MM/DD/YYYY'),
			}),
		)
			.then((res) => {
				history.push(`/invoices/${res?._id}`);
			})
			.catch((err) => {
				dispatch(setSnackBarIsOpen(false, true, err.message == "Cannot read properties of null (reading 'start_invoice_no')" ? 'Please add the billing information of the client first.' :'Please fill all required fields'));
			})
			.finally(() => {
				setLoading(false);
			});

		// const from = location.state || [];
		// from.push({
		// 	link: location.pathname,
		// 	label: client?.dba?.trim() == '' ? client?.company_name : client?.dba,
		// });
		// if (from.length > 2) from.shift();

		// history.push(
		// 	`/client-invoice/?client_id=${id}&from=${moment(
		// 		clientInvoice.start_date,
		// 	).format('MM-DD-YYYY')}&to=${moment(clientInvoice.end_date).format(
		// 		'MM-DD-YYYY',
		// 	)}`, from
		// );
	};

	const onDateChange = (date, name) => {
		dispatch(clientActions.clientInvoiceFormChangeHanlder(name, date));
	};

	return (
		<>
			<form
				autoComplete='off'
				onSubmit={formSubmit}
				className='UserAdd-Form'
				style={{ overflow: 'visible' }}
			>
				<div className='p-3' style={{ overflow: 'visible' }}>
					<h3 className='BodyTwoBold text-left' style={{ wordBreak: 'break-all' }}>
						Billing Period
					</h3>

					<div className='improved-input-designs pb-3 d-flex flex-column'>
						<label className='w-100 require'>START DATE</label>
						<KeyboardDatePicker
							autoOk
							value={clientInvoice?.start_date}
							onChange={(date) => onDateChange(date, 'start_date')}
							format={date_format}
							required
							// disableFuture
							// minDate={oldestBill?.bill_start_date}
						/>
					</div>

					<div className='improved-input-designs pb-3 d-flex flex-column'>
						<label className='w-100'>END DATE</label>
						<KeyboardDatePicker
							autoOk
							onChange={(date) => onDateChange(date, 'end_date')}
							value={clientInvoice?.end_date}
							format={date_format}
							required
							// disableFuture
							disabled={!clientInvoice?.start_date}
							// minDate={clientInvoice?.start_date ? clientInvoice.start_date : oldestBill?.bill_start_date}
						/>
					</div>
				</div>

				<div className='VerticalSpacer bg-white p-3 rounded-bottom d-flex justify-content-between'>
					<button type='button' onClick={onCancel} className='secondary-btn'>
						Cancel
					</button>

					<button
						type='submit'
						className='primary-btn'
						// data-toggle='tooltip'
						// data-placement='top'
						// title={clientBilling?.length ? '' : 'Please set your client billing first'}
						// disabled={clientBilling?.length ? false : true}
						// style={{ opacity: clientBilling?.length ? '1' : '0.5' }}
					>
						{loading ? (
							<CircularProgress size={22} sx={{ color: 'white' }} disableShrink />
						) : (
							<p className='BodyTwoBold mt-1 text-white'>Generate</p>
						)}
					</button>
				</div>
			</form>
		</>
	);
}

export default memo(GenerateInvoice);
