import { useState } from 'react';

const useModal = () => {
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [ModalName, setModalName] = useState('');

	const toggleModal = () => {
		setModalIsOpen(!modalIsOpen);
		// document.body.style.overflow = !modalIsOpen ? 'hidden' : 'initial';
	};

	const ModalType = (Type) => {
		setModalName(Type);
	};

	return {
		modalIsOpen,
		toggleModal,
		ModalName,
		ModalType,
		setModalIsOpen,
	};
};

export default useModal;
