import { css } from 'styled-components';
import styled from "styled-components";



export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;
export const calibre = css`
  font-family: 'Calibre';
  font-weight: normal;
  font-style: normal;
`;

export const calibreLight = css`
  font-family: 'Calibre-Light';
  font-weight: 300;
  font-style: normal;
`;

export const calibreBold = css`
  font-family: 'Calibre-Bold';
  font-weight: bold;
  font-style: normal;
`;

export const calibreSemibold = css`
  font-family: 'Calibre-Semibold';
  font-weight: 600;
  font-style: normal;
`;

export const tiempos = css`
  font-family: 'Tiempos';
  font-weight: normal;
  font-style: normal;
`;

export const tiemposSemibold = css`
  font-family: 'Tiempos-Semibold';
  font-weight: 600;
  font-style: normal;
`;


// Shadows
export const shadow = '0 .2rem .3rem rgba(20, 20, 20, .1)';

// Borders
export const borderRadius = '0.3rem';
export const divider50 = (rgb) => `0.1rem solid rgba(${rgb}, 0.5)`;

// Transition
export const transitionTimingFunction = '0.3s ease-out';
export const transition = (props) =>
  css`
    transition: all ${transitionTimingFunction};
    transition-property: ${props.join(', ')};
  `;


// Spacing
export const spacing10 = '1rem';
export const spacing15 = '1.5rem';
export const spacing20 = '2rem';
export const spacing30 = '3rem';
export const spacing40 = '4rem';
export const spacing50 = '5rem';

// Paul Design
export const spacing4 = '0.4rem';
export const spacing8 = '0.8rem';
export const spacing16 = '1.6rem';
export const spacing24 = '2.4rem';
export const spacing32 = '3.2rem';
export const spacing64 = '6.4rem';

// export const black = '#333D21';

export const dark = '#0d3838';
export const darkRgb = '13,56,56';
export const light = '#41B952';
export const lightRgb = '65,185,82';

// PROJECT
// export const lightGreen = black;
export const textDarkGreen = '#8EA56A';
export const textLightGreen = '#DCE3D0';
export const buttonTextLightGreen = '#A9B19C';
export const borderGreen = '#6B7260';
export const dividerGreen = '#575F4A';
export const dividerGreenRgb = '87,95,74';
export const linkUnderlineGreen = '#76993D';
export const projectBackground = '#FFFEFB';

// PAUL
export const black = '#000';
export const white = '#FFF';
export const offWhite = '#FFFEFB';
export const backgroundGrey = '#F6F5F2';
export const borderGrey = '#E9E7E1';
export const cardGrey = '#FCFBF7';
export const secondaryButtonGrey = '#FEF2E7';
export const darkText = '#333';
export const lightText = '#696F6C';
export const placeholderText = '#B4B4B4';
export const darkGreen = '#28383C';
export const lightGreen = '#FB7A03';
export const buttonHover = '#648431';
export const buttonHoverSecondary = '#C6CFB9';
export const buttonActive = '#4C7111';
export const buttonActiveSecondary = '#ABB69B';
export const errorRed = '#D17558';
export const successBlue = '#63B5CE';
export const warningYellow = '#E3B15E';

export const boxShadow = '0 2px 6px 0 rgba(27, 41, 29, 0.36)';

// MODALS
export const modalHeaderGreen = black;
export const modalGrey = '#878B80';
export const commentBorderGrey = '#F5F5F5';
export const squareButtonGrey = '#6b7260';
export const squareButtonLightGrey = '#A1A1A1';
export const modalActionTextGreen = '#383F2C';
export const modalActionLinkGreen = '#76993D';

// TASKS
export const taskGrey = '#444444';

// PROJECTS
export const weekViewHeaderGreen = '#879175';

// CARDS
// export const boxShadowColor = '#D1D5C9';
export const boxShadowColor = 'rgba(0, 0, 0, 0.3)';
export const cardFooterTopBorderGrey = '#979797';

export const gradient = css`
  background: #707960;
  background-image: linear-gradient(
    135deg,
    rgba(213, 224, 192, 0.9) 0%,
    rgba(78, 90, 59, 0.9) 100%
  );
  background-blend-mode: multiply;
`;

export const gradientSidebar = css`
  background-image: linear-gradient(
    179deg,
    ${darkGreen} 1%,
    ${lightGreen} 100%
  );
`;
export const defaultStyles = css`
  /* font-size: 16px;
  margin: 0 0 16px; */

  & > input {
    box-sizing: border-box;
    background-color: transparent;
    border: none;
    border-bottom: 0.1rem solid ${darkGreen};
    margin: 0;
    ${calibre};
    font-size: 1.4rem;
    line-height: 1.6rem;

    ::placeholder {
      color: ${placeholderText};
    }

    &:focus {
      outline: 0.1rem dotted ${darkText};
      outline-offset: 0.2rem;
    }
  }

  & > label {
    ${calibre};
    font-size: 1.1rem;
    line-height: 1.4rem;
    margin: 0;
    color: ${lightText};
    // text-transform: uppercase;
    display: inline-block;
    margin-bottom: ${spacing4};
  }
  /* & > label {
    cursor: pointer;
    display: inline-block;
    margin-bottom: 0.3rem;
    font-size: 16px;
  } */
`;