import React from 'react';
import { useDispatch } from 'react-redux';
import { StoreToken } from '../redux/actions/authactions';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import { ReactComponent as Levvy } from '../assets/icons/levvy.svg';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color:#f2f5f6;

  width: 100vw;
  height: 100vh;

  svg {
    width: 116px;
    height: 38px;
  }
`;

const ButtonControls = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;
  width: 15rem;
`;
const Public = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  return (
    <Root>
      <Levvy />
      <ButtonControls>
      <p>
      This is a Private route
     
    </p>
  
   
      </ButtonControls>
      <ButtonControls>
  
      <button className='btn btn-md'
      style={{background:'#fb7a03',color:'#ffffff'}}
        onClick={() => {
          history.push('/');
          dispatch(StoreToken('', '', '', ''));
        }}
      >
        Log OUT
      </button>
   
      </ButtonControls>
    </Root>
   
  );
};
export default Public;
