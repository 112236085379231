import SubmitButtonsField from '../Buttons/SubmitButtonsField';

function ConfirmModal({
	OnCancel,
	Onsubmit,
	description,
	note,
	descriptionClassName,
	title,
	isLoading,
	error,
	workflowName,
}) {
	const parts = note?.split(workflowName);
	const coloredNote = (
		<span>
			{parts?.map((part, index) => (
				<span key={index}>
					{index > 0 && <span style={{ color: '#fb7a03' }}>{workflowName}</span>}
					{part}
				</span>
			))}
		</span>
	);
	return (
		<div>
			<div className='VerticalSpacer'>
				<h4 className={descriptionClassName + ' BodyTwoLight p-3 '}>{description}</h4>

				<h4 className={descriptionClassName + ' BodyTwoLight pl-3 pr-3 '}>{coloredNote}</h4>

				{error && (
					<div className='px-3 pb-3'>
						<span className='error-text BodyTwoLight'>{error} </span>
					</div>
				)}
				<SubmitButtonsField
					rootClass='bg-white p-3'
					title={title}
					onCancel={OnCancel}
					onSubmit={Onsubmit}
					isLoading={isLoading}
				/>
			</div>
		</div>
	);
}

export default ConfirmModal;
