import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as teamActions from '../../redux/actions/team-management.actions';
import { ReactComponent as LeftIcon } from '../../assets/icons/chevron-left-large.svg';
import { ReactComponent as RightIcon } from '../../assets/icons/chevron-right-large.svg';
import SkeletonLoading from '../Others/SkeletonLoading';
import { getUserSettings } from '../../hooks/getUserSettings';

const TeamClientsDate = () => {
	const dispatch = useDispatch();
	const loading = useSelector((state) => state.clientReducer.loading);
	const monthlyView = useSelector((state) => state.teamReducer.monthlyView);
	const start_date = useSelector((state) => state.teamReducer.start_date);
	const end_date = useSelector((state) => state.teamReducer.end_date);
    const userDetails = useSelector((state) => state.authReducer.userDetails);
    const { full_month_name_format }  = getUserSettings(userDetails);

	function getDateFromLocalStorage() {
		const storedDate = localStorage.getItem(
			monthlyView ? 'WORKSPACE_MONTH_DATE' : 'WORSPACE_DATE',
		);
		if (storedDate) {
			return storedDate;
		}
		const mondayDate = moment().isoWeekday(1);
		const currentDate = mondayDate.format('YYYY-MM-DD');
		localStorage.setItem(
			monthlyView ? 'WORKSPACE_MONTH_DATE' : 'WORSPACE_DATE',
			currentDate,
		);
		return currentDate;
	}
	function getDates(date) {
		const start_date = monthlyView
			? moment(date).startOf('month').format('YYYY-MM-DD')
			: moment(date).startOf('isoWeek').format('YYYY-MM-DD');
		const end_date = monthlyView
			? moment(date).endOf('month').format('YYYY-MM-DD')
			: moment(date).endOf('isoWeek').format('YYYY-MM-DD');
		return { start_date, end_date };
	}

	const handleNavigation = (direction, date) => {
		if (direction == 'left') {
			const newDate = moment(date)
				.subtract(1, monthlyView ? 'month' : 'week')
				.format('YYYY-MM-DD');
			const { start_date, end_date } = getDates(newDate);
			dispatch(teamActions.setDates(start_date, end_date));
			localStorage.setItem(
				monthlyView ? 'WORKSPACE_MONTH_DATE' : 'WORSPACE_DATE',
				newDate,
			);
		}
		if (direction == 'right') {
			const newDate = moment(date)
				.add(1, monthlyView ? 'month' : 'week')
				.format('YYYY-MM-DD');
			const { start_date, end_date } = getDates(newDate);
			dispatch(teamActions.setDates(start_date, end_date));
			localStorage.setItem(
				monthlyView ? 'WORKSPACE_MONTH_DATE' : 'WORSPACE_DATE',
				newDate,
			);
		}
	};

	useEffect(() => {
		const date = getDateFromLocalStorage();
		const { start_date, end_date } = getDates(date);
		dispatch(teamActions.setDates(start_date, end_date));
		console.log(date, 'inComponent');
	}, [monthlyView]);

	return (
		<div
			className='d-flex align-items-center justify-content-between w-100'
			style={{
				backgroundColor: 'white',
				// padding: '20px 18px',
				borderRadius: '4px 4px 0px 0px',
				gap: '1.5em',
				boxSizing: 'border-box',
			}}
		>
			<div className='d-flex justify-content-between w-100'>
				<SkeletonLoading
					loading={loading}
					variant='text'
					width='25%'
					height={45}
				>
					<div className='d-flex align-items-center' style={{ gap: '2em' }}>
						<div className='d-flex' style={{ gap: '1em' }}>
							<button
								onClick={() => {
									dispatch(teamActions.setMonthlyView(false));
								}}
								style={{ height: '26px', minWidth: '74px' }}
								className={!monthlyView ? 'primary-btn' : 'secondary-btn'}
							>
								Weekly
							</button>
							<button
								onClick={() => {
									dispatch(teamActions.setMonthlyView(true));
								}}
								style={{ height: '26px', minWidth: '74px' }}
								className={monthlyView ? 'primary-btn' : 'secondary-btn'}
							>
								Monthly
							</button>
						</div>
					</div>
				</SkeletonLoading>
				<SkeletonLoading
					loading={loading}
					variant='text'
					width='25%'
					height={45}
				>
					<div className='d-flex align-items-center'>
						<div
							className='d-flex align-items-center'
							onClick={() => handleNavigation('left', start_date)}
						>
							<LeftIcon className='pointer' />
							<p className='m-0 BodyTwoLight'>
								<span className='m-0 BodyTwoLight-light pointer'>
									{moment(start_date).format(full_month_name_format)}{' '}
								</span>
							</p>
						</div>

						<p className='m-0 BodyTwoLight'>
							<span className='m-0 BodyTwoLight-light'>
								&nbsp;&nbsp;to&nbsp;&nbsp;
							</span>{' '}
						</p>

						<div
							className='d-flex align-items-center'
							onClick={() => handleNavigation('right', start_date)}
						>
							<p className='m-0 BodyTwoLight'>
								<span
									className='m-0 BodyTwoLight-light pointer'
									onClick={() => handleNavigation('right', start_date)}
								>
									{moment(end_date).format(full_month_name_format)}
								</span>
							</p>
							<RightIcon className='pointer' />
						</div>
					</div>
				</SkeletonLoading>
			</div>
		</div>
	);
};

export default TeamClientsDate;
