import { secure_instance } from '../../../axios/axios-config';

export const GET_INTEGRATION = '[ACCOUNTING] GET_INTEGRATION';
export const CREATE_CODAT_COMPANY = '[ACCOUNTING] CREATE_CODAT_COMPANY';
export const CONNECT_QBO = '[ACCOUNTING] CONNECT_QBO';
export const GET_CODAT_COMPANY = '[ACCOUNTING] GET_CODAT_COMPANY';
export const SYNC_CODAT = '[ACCOUNTING] SYNC_CODAT';
export const GET_CODAT_CUSTOMERS = '[ACCOUNTING] GET_CODAT_CUSTOMERS';
export const CREATE_CODAT_INVOICE = '[ACCOUNTING] CREATE_CODAT_INVOICE';
export const DISCONNECT_QBO = '[ACCOUNTING] DISCONNECT_QBO';


export const getIntegration = () => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: '/v1/integrations/',
				method: 'GET',
			});

			dispatch({
				type: GET_INTEGRATION,
				payload: request?.data,
			});

			console.log(request, 'request');
			return request?.data;
		} catch (e) {
			throw new Error(e?.response?.data?.message || e?.message || 'Something went Wrong');
		}
	};
};

export const createCodatCompany = (data) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: '/v1/integrations/companies',
				method: 'POST',
				data: data,
			});

			dispatch({
				type: CREATE_CODAT_COMPANY,
				payload: request?.data,
			});

			return request?.data;
		} catch (e) {
			throw new Error(e?.response?.data?.message || e?.message || 'Something went Wrong');
		}
	};
};

export const connectQBO = (companyId, data) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/integrations/companies/${companyId}/connections`,
				method: 'POST',
				data: data,
			});

			dispatch({
				type: CONNECT_QBO,
				payload: request?.data,
			});

			return request?.data;
		} catch (e) {
			throw new Error(e?.response?.data?.message || e?.message || 'Something went Wrong');
		}
	};
};

export const getCodatCompany = (companyId) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/integrations/companies/${companyId}`,
				method: 'GET',
			});

			dispatch({
				type: GET_CODAT_COMPANY,
				payload: request?.data,
			});

			return request?.data;
		} catch (e) {
			throw new Error(e?.response?.data?.message || e?.message || 'Something went Wrong');
		}
	};
};

export const syncCodat = (companyId, data) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `v1/integrations/companies/${companyId}/data/queue/customers`,
				method: 'POST',
				data: data,
			});

			dispatch({
				type: SYNC_CODAT,
				payload: request?.data,
			});

			return request?.data;
		} catch (e) {
			throw new Error(e?.response?.data?.message || e?.message || 'Something went Wrong');
		}
	};
};

export const getCodatCustomers = (companyId) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/integrations/companies/${companyId}/data/customers`,
				method: 'GET',
			});

			dispatch({
				type: GET_CODAT_CUSTOMERS,
				payload: request?.data,
			});

			return request?.data;
		} catch (e) {
			throw new Error(e?.response?.data?.message || e?.message || 'Something went Wrong');
		}
	};
};

export const createCodatInvoice = (companyId, connectionId, data) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/integrations/companies/${companyId}/connections/${connectionId}/push/invoices?invoice_id=${data?.invoice_id}&codat_customer_id=${data?.codat_customer_id}&codat_customer_name=${data?.codat_customer_name}`,
				method: 'POST',
			});

			dispatch({
				type: CREATE_CODAT_INVOICE,
				payload: request?.data,
			});

			console.log(request, 'request123');
			return request?.data;
		} catch (e) {
			throw new Error(e?.response?.data?.message || e?.message || 'Something went Wrong');
		}
	};
};


export const disconnectQBO = (companyId, connectionId) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/integrations/companies/${companyId}/connections/${connectionId}`,
				method: 'DELETE',
			});

			dispatch({
				type: DISCONNECT_QBO,
				payload: request?.data,
			});

			return request?.data;
		} catch (e) {
			throw new Error(e?.response?.data?.message || e?.message || 'Something went Wrong');
		}
	};
};