
// import AppComponent from './AppComponent';
// import Logo from '../../assets/icons/Group 107.svg';
import { ReactComponent as AccountingIcon } from '../../assets/icons/Integrations/Calculator.svg';
import AccountingComponent from './AccountingComponent';

export default function Codat() {

	// const AccountingApp = [
	// 	{
	// 		logo: Logo,
	// 		name: 'Quickbooks',
	// 		details:
	// 			'Supercharge your workflow with QuickBooks 3rd party app integrations. The integrated apps provide functionality that spans invoicing, inventory management, expense management, payroll, eCommerce, reporting and much more.',
	// 	},
	// 	{
	// 		logo: Logo,
	// 		name: 'Quickbooks',
	// 		details:
	// 			'reshBooks integrations allow you to automate more of your workflow and save you time by connecting your FreshBooks account with the apps you already love working with.',
	// 	},
	// ];

    // useEffect(() => {
    //     dispatch(AccountingActions.getIntegration());
    // },[dispatch]);

	return (
		<div>
			<div className='d-flex flex-column' style={{ gap: '24px' }}>
				<div
					style={{
						background: 'white',
						borderRadius: '4px',
						padding: '10px',
						gap: '24px',
					}}
					className='d-flex flex-row'
				>
					<AccountingIcon />
					<h6 className='HeadlineTwoBold'>Accounting</h6>
				</div>
				<div
					className='Zap-Head-Section'
					style={{ display: 'flex', flexDirection: 'column', gap: '18px' }}
				>
					<div>
						<AccountingComponent />
					</div>
				</div>
			</div>
		</div>
	);
}
