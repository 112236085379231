import React, { useState } from 'react';

import BillingBar from './BillingBar';
import CompanyBillPlan from '../sub_components/CompanyProfile/CompanyBillPlan';
import CompanyPaymentMethod from '../sub_components/CompanyProfile/CompanyPaymentMethod';
import CompanyTransactionHistory from '../sub_components/CompanyProfile/CompanyTransactionHistory';

const CompanyProfileBilling = ({billingRef}) => {
	return (
		<div className='background-shadow'>
			<BillingBar BarText='Billing' />

			<div className='Profile-Content' ref={billingRef}>
				<CompanyBillPlan />

				<CompanyPaymentMethod />

				<CompanyTransactionHistory />

			</div>
		</div>
	);
};
export default CompanyProfileBilling;
