import * as Actions from '../actions/user-management.actions';

const initialState = {
	users: [],
	archivedUsers: [],
	loading: false,
	allTenantUsers: [],
	roles: [],
	image: '',
	profileLoading: true,
	userProfileManagement: null,
	filteredUserRole: null,
	filteredUserProfile: null,
	userProfile: {},
	userTeams: [],
	errors: '',
	profileInfoEdit: false,
	imageLoading: false,
	affectedTasks: [],
	filters: {
		userRoles: [],
		workMode: [],
		location: [],
		team: [],
	},
	userBillingList: [],
	specificUserBilling: [],
	userBilling: [],
};

const UserManagement = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case Actions.GET_USERS_START:
			return (state = {
				...state,
				loading: true,
			});

		case Actions.PROFILE_INFO_EDIT:
			return (state = {
				...state,
				profileInfoEdit: payload,
			});

		case Actions.GET_USERS_FAILED:
			return (state = {
				...state,
				loading: false,
			});
		case Actions.GET_ALL_TENANT_USERS:
			return (state = {
				...state,
				allTenantUsers: payload,
			});

		case Actions.PROFILE_CHANGE_HANDLER:
			return (state = {
				...state,
				userProfile: {
					...state.userProfile,
					[payload.name]: payload.value,
				},
			});

		case Actions.SET_USER_TEAMS:
			return (state = {
				...state,
				userTeams: [...payload],
			});

		case Actions.GET_USERS_END:
			return (state = {
				...state,
				users: payload.users.map((user) => {
					const UserRoleID = payload.user_roles.find(
						(role) => role.user_id === user.id && !role.deleted,
					)?.role_id;
					return {
						user_teams: user.user_teams,
						user_name: user?.full_name,
						email: user?.email,
						team_id: user?.user_teams[0]?.team?.id,
						team_name: user?.user_teams[0]?.team?.name,
						available_hours: user?.available_hours,
						image: user?.image_url,
						user_id: user.id,
						is_active: user.is_active,
						accepted: payload?.user_roles?.find(
							(role) => role.user_id === user.id && !role.deleted,
						)?.accepted,
						// user_id: payload.user_roles.find((role) => role.user_id === user.id)
						// 	?.user_id,
						role_name: state.roles.find((role) => role.id == UserRoleID)?.role_name,
						role_id: payload.user_roles.find(
							(role) => role.user_id === user.id && !role.deleted,
						)?.role_id,
						team_role_id: payload.team_id
							? payload.user_roles.find(
									(role) =>
										role.user_id === user.id &&
										role.role_scopes.length > 0 &&
										role.role_scopes.find(
											(scope) => scope.team_id === parseInt(payload.team_id),
										),
									// eslint-disable-next-line no-mixed-spaces-and-tabs
							  )?.role_id
							: null,
					};
				}),
				loading: false,
			});

		case Actions.UPDATE_USER:
			return (state = {
				...state,
				users: state.users.map((user) => {
					if (user.id === payload.id) {
						return payload;
					}
					return user;
				}),
			});

		case Actions.ADD_USER:
			return (state = {
				...state,
			});
		case Actions.RESET_USER_ADD_ERRORS:
			return (state = {
				...state,
				errors: '',
			});
		case Actions.ADD_USER_FAILED:
			return (state = {
				...state,
				errors: payload,
				loading: false,
			});
		case Actions.DELETE_USER_START:
			return (state = {
				...state,
				loading: true,
			});

		case Actions.DELETE_USER_FAILED:
			return (state = {
				...state,
				loading: false,
			});

		case Actions.DELETE_USER_END:
			return (state = {
				...state,
				loading: false,
			});

		case Actions.GET_ROLES:
			return (state = {
				...state,
				roles: payload,
			});

		case Actions.CLEAR_USERS:
			return (state = {
				...state,
				users: [],
			});

		case Actions.UPLOAD_USER_IMAGE_START:
			return (state = {
				...state,
				imageLoading: true,
			});
		case Actions.UPLOAD_USER_IMAGE:
			return (state = {
				...state,
				image: payload,
				imageLoading: false,
			});

		case Actions.UPLOAD_USER_IMAGE_FAILED:
			return (state = {
				...state,
				imageLoading: false,
			});
		case Actions.CLEAR_IMAGE:
			return (state = {
				...state,
				image: null,
			});

		case Actions.SET_PROFILE_LOADING:
			return (state = {
				...state,
				profileLoading: payload,
			});

		case Actions.GET_USERPROFILE_START:
			return (state = {
				...state,
				profileLoading: true,
			});

		case Actions.GET_USERPROFILE_END:
			return (state = {
				...state,
				userProfile: payload.users,
				profileLoading: false,
				// userClients: payload.clients,
			});
		case Actions.UPDATE_USER_PROFILE:
			return (state = {
				...state,
				userProfile: payload,
			});
		case Actions.GET_USERPROFILE_FAILED:
			return (state = {
				...state,
				profileLoading: false,
			});

		case Actions.RESET_USER_PROFILE:
			return (state = {
				...state,
				userProfile: {},
			});

		case Actions.GET_USER_ROLES:
			return (state = {
				...state,
				userRoles: payload.userRoles,
			});
		case Actions.DELETE_MULTI_USERS:
			return (state = {
				...state,
				users: state.users.filter(
					(user) => user.user_id !== payload.find((id) => id === user.user_id),
				),
			});
		case Actions.GET_USER_PROFILE_MANAGEMENT:
			return (state = {
				...state,
				userProfileManagement: payload,
			});

		case Actions.GET_FILTERED_USER_ROLES:
			return (state = {
				...state,
				filteredUserRole: payload,
			});

		case Actions.HANDLE_ROLE_CHECKBOXES:
			return (state = {
				...state,
				filters: {
					...state.filters,
					userRoles: !payload.checked
						? state.filters.userRoles.filter((r) => !payload.data.includes(r))
						: [...state.filters.userRoles, ...payload.data],
				},
			});
		case Actions.HANDLE_LOCATION_CHECKBOXES:
			return (state = {
				...state,
				filters: {
					...state.filters,
					location: state.filters.location.includes(payload)
						? state.filters.location.filter((p) => p !== payload)
						: [...state.filters.location, payload],
				},
			});
		case Actions.HANDLE_WORKMODE_CHECKBOXES:
			return (state = {
				...state,
				filters: {
					...state.filters,
					workMode: state.filters.workMode.includes(payload)
						? state.filters.workMode.filter((p) => p !== payload)
						: [...state.filters.workMode, payload],
				},
			});
		case Actions.HANDLE_TEAM_CHECKBOXES:
			return (state = {
				...state,
				filters: {
					...state.filters,
					team: state.filters.team.includes(payload)
						? state.filters.team.filter((p) => p !== payload)
						: [...state.filters.team, payload],
				},
			});
		case Actions.HANDLE_DATE_CHANGE:
			return (state = {
				...state,
				userProfile: {
					...state.userProfile,
					birthday: payload,
				},
			});
		case Actions.CLEAR_JOB_TITLES:
			return (state = {
				...state,
				filters: {
					...state.filters,
					userRoles: [],
				},
			});
		case Actions.CLEAR_LOCATION:
			return (state = {
				...state,
				filters: {
					...state.filters,
					location: [],
				},
			});
		case Actions.CLEAR_WORK_MODE:
			return (state = {
				...state,
				filters: {
					...state.filters,
					workMode: [],
				},
			});
		case Actions.CLEAR_TEAM:
			return (state = {
				...state,
				filters: {
					...state.filters,
					team: [],
				},
			});
		case Actions.GET_AFFECTED_CLIENTS:
			return (state = {
				...state,
				affectedTasks: payload,
			});
		case Actions.SET_USERS_BILLING_DETAILS:
			return (state = {
				...state,
				userBillingList: payload,
			});
		case Actions.SET_SPECIFIC_USERS_BILLING_DETAILS:
			return (state = {
				...state,
				specificUserBilling: payload,
			});
		case Actions.USER_BILLING_LIST_START:
			return (state = {
				...state,
				loading: true,
			});
		case Actions.ADD_USER_BILLING:
			return (state = {
				...state,
				specificUserBilling: [...state.specificUserBilling, payload],
			});
		case Actions.USER_BILLING_LIST_END:
			return (state = {
				...state,
				loading: false,
			});
		case Actions.USER_BILLING_LIST_FAILED:
			return (state = {
				...state,
				loading: false,
			});
		case Actions.GET_ALL_ARCHIVED_USERS:
			return (state = {
				...state,
				archivedUsers: payload,
			});
		case Actions.UPDATE_USER_ROLES:
			return (state = {
				...state,
				userRoles: payload,
			});
		case Actions.UNARCHIVE_USER:
			return (state = {
				...state,
				archivedUsers: state.archivedUsers?.filter((user) => user?.id !== payload),
			});
		default:
			return state;
	}
};

export default UserManagement;
