import React from 'react';

// Material Ui Imports
import { Input, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
//End

function LightSearchInput({ OnChange, value, placeHolder }) {
	const InputStyle = {
		width: 'fit-content',
		fontSize: '0.9em',
		fontFamily: 'Calibre',
		paddingLeft: '5px',
		borderRadius: '3px',
	};

	const IconStyle = {
		color: '#FB7A03',
		fontSize: 'large',
	};

	return (
		<Input
			value={value}
			onChange={OnChange}
			sx={InputStyle}
			placeholder={placeHolder}
			disableUnderline={true}
			id='input-with-icon-adornment'
			className='light-search-bar'
			startAdornment={
				<InputAdornment position='start'>
					<SearchIcon sx={IconStyle} />
				</InputAdornment>
			}
		/>
	);
}

export default LightSearchInput;
