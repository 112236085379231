import { useEffect, useRef, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import SearchBar from '../Inputs/SearchBar';
import * as searchActions from '../../redux/actions/search-actions';
import SearchCard from '../GlobalSearch/SearchCard';
import { CircularProgress } from '@mui/material';

function SearchModal({ onClose }) {
	const dispatch = useDispatch();
	const ref = useRef();
	const [activeTab, setActiveTab] = useState(1);
	const searchResult = useSelector((state) => state.searchReducer.searchResult);
	const modalIsOpen = useSelector(
		(state) => state.searchReducer.isSearchModalOpen,
	);

	useEffect(() => {
		return () => {
			dispatch(searchActions.setSearchResult(null));
		};
	}, []);

	useEffect(() => {
		const checkIfClickedOutside = (e) => {
			if (
				modalIsOpen &&
				ref.current &&
				!ref.current.contains(e.target)
			) {
				dispatch(searchActions.setModalState(false));
			}
		};

		document.addEventListener('mousedown', checkIfClickedOutside);

		return () => {
			document.removeEventListener('mousedown', checkIfClickedOutside);
		};
	}, [modalIsOpen]);

	const searchCategories = [
		{
			value: 'users',
			label: 'People',
			id: 1,
		},
		{
			value: 'teams',
			label: 'Teams',
			id: 2,
		},
		{
			value: 'clients',
			label: 'Clients',
			id: 3,
		},
		{
			value: 'tasks',
			label: 'Tasks',
			id: 4,
		},
	];

	return (
		<>
			{modalIsOpen && (
				<div className='search-modal-main' ref={ref}>
					<div className='main-first-child'>
						<span
							onClick={onClose}
							style={{ color: '#FB7A03' }}
							className='BodyTwoLight pointer text-right'
						>
							Close
						</span>
						<div className='search-outer'>
							<div className='search-inner'>
								<SearchBar style={{ width: '100%' }} />
							</div>
						</div>
					</div>

					{searchResult && (
						<div className='main-second-child'>
							<div className='VerticalSpacer'>
								<div className='TabWrapper'>
									{searchCategories?.map((item, i) => {
										return (
											<span
												key={i}
												className={
													item.id == activeTab ? 'Tab selected' : 'Tab'
												}
												onClick={() => setActiveTab(item.id)}
												style={{ position: 'relative' }}
											>
												{item.label}
												<span
													style={{
														position: 'absolute',
														bottom: 10,
														fontSize: '12px',
														paddingLeft: '2px',
													}}
												>
													{
														searchResult[
															searchCategories.find(
																(category) => category.id == item.id,
															)?.value
														]?.length
													}
												</span>
											</span>
										);
									})}
								</div>
							</div>

							<div className='SidebarListWrapper'>
								<SearchCard
									title={
										searchCategories.find((item) => item.id == activeTab)?.label
									}
									list={
										searchResult[
										searchCategories.find((item) => item.id == activeTab)
											?.value
										]
									}
								/>
							</div>
						</div>
					)}
				</div>
			)}
		</>
	);
}

export default SearchModal;
