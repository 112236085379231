import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import * as zapierActions from '../../redux/actions/integrations/zapier-actions';
import { CircularProgress } from '@mui/material';

function ZapierButton({ buttonType }) {
	const dispatch = useDispatch();
	const location = useLocation();
	const [isLoading, setIsLoading] = useState(false);

	const handleAllowAccess = async () => {
		try {
			setIsLoading(true);
			await dispatch(zapierActions.oauthAuthorized(location.search));
			setIsLoading(false);
		} catch (error) {
			console.error('Error allowing access:', error);
			setIsLoading(false);
		}
	};

	const handleClose = () => {
		window.close();
	};

	return (
		<div>
			{buttonType === 'primary' && (
				<button className='primaryButtonStyle BodyTwoBold' onClick={handleClose}>
					Cancel
				</button>
			)}
			{buttonType === 'secondary' && (
				<button
					className='secondaryButtonStyle BodyTwoBold'
					onClick={handleAllowAccess}
					disabled={isLoading}
				>
					{isLoading ? (
						<CircularProgress size={20} sx={{ color: 'white' }} disableShrink />
					) : (
						'Allow Access'
					)}
				</button>
			)}
		</div>
	);
}

export default ZapierButton;
