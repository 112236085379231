
const TranferAndProcessingData = () => {
	return (
		<p className='BodyTwoLight'>
			In order for us to provide the Site, you agree that we may process,
			transfer, and store information about you in the United States and other
			countries, where you may not have the same rights and protections as you
			do under local law.
		</p>
	);
};

export default TranferAndProcessingData;
