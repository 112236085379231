import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { ReactComponent as ProgressDone } from '../../../assets/icons/CheckCircle.svg';
import { ReactComponent as UploadFailed } from '../../../assets/icons/XCircleColor.svg';
import { FileIcon, defaultStyles } from 'react-file-icon';
import { useSelector } from 'react-redux';
import 'react-circular-progressbar/dist/styles.css';

function ProgressBar({ file }) {
	const upload_progress = useSelector((state) => state.clientReducer.upload_progress[file.name]);
	const upload_status = useSelector((state) => state.clientReducer.upload_status[file.name]);

	return (
		<div style={{ display: 'flex', justifyContent: 'space-between' }}>
			<div className='d-flex align-items-center p-4'>
				<div style={{ width: '25px', paddingRight: '6px' }}>
					<FileIcon
						extension={file?.type}
						{...defaultStyles[`${file?.type}`]}
						size='14px'
					/>
				</div>
				<div className='d-flex flex-column align-items-start'>
					<span className='BodyTwoLight ml-2'>
						{file?.name?.length > 30 ? file?.name?.substring(0, 30) + '...' : file?.name}
					</span>
                    <span className='error-text BodyTwoLight pl-2'>{upload_status?.error}</span>
				</div>
			</div>
			<div
				className='p-3 d-flex'
				style={{
					maxHeight: '75vh',
					height: 'fit-content',
					position: 'relative',
					overflowY: 'auto',
				}}
			>
				<div className='circular p-2 d-flex justify-content-between align-items-center'>
					{upload_status?.status === 1 ? (
						<ProgressDone style={{ width: '25px', height: '25px' }} />
					) : upload_status?.status === -1 ? (
						<UploadFailed style={{ width: '25px', height: '25px' }} />
					) : (
						<>
							<div className=''>{`${upload_progress || 0}%`}</div>
							<div className='ml-2' style={{ width: '25px', height: '25px' }}>
								<CircularProgressbar
									value={upload_progress || 0}
									styles={buildStyles({
										textColor: '#000',
										pathColor: '#FB7A03',
										trailColor: '#FED0A5',
									})}
								/>
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	);
}

export default ProgressBar;
