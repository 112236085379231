import DummyModal from './DummyModal';
import {
	Modal,
} from '@mui/material';
import AccessFolder from '../sub_components/DocumentStorage/AccessFolder';

function AccessModal({ modalIsOpen, toggleModal, item }) {
	return (
		<div>
			{modalIsOpen && (
				<Modal
					onClose={toggleModal}
					open={modalIsOpen}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<>
						<DummyModal title='Access' onClose={toggleModal}>
							<div className='pl-3 pr-3'>
								<span className='BodyTwoLight'>
									Please select which role can access the{' '}
									<span style={{ color: '#FB7A03' }}>{item?.name}</span> folder
								</span>
							</div>
							<AccessFolder item={item} onCancel={toggleModal} />
						</DummyModal>
					</>
				</Modal>
			)}
		</div>
	);
}

export default AccessModal;
