import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as Down } from '../../../assets/icons/down.svg';
import { ReactComponent as Up } from '../../../assets/icons/up.svg';
import ButtonSquare from '../../Buttons/ButtonSquare';
import ExpandableResourcesBox from '../../sub_components/ExpandableResourcesBox';
import DynamicTable from './DynamicTable';
import TasksTemplateTable from './TasksTemplateTable';
import * as builderActions from '../../../redux/actions/workflow-builder-actions';
import * as clientAccountActions from '../../../redux/actions/client-accounts-actions';
import { useHistory, useParams } from 'react-router';
import { getAccountComponentsWithSharedAccounts, objectBeingDragged } from '../../../utilities/utilities';
import SubmitButtonsField from '../../Buttons/SubmitButtonsField';
import { CircularProgress } from '@mui/material';

function NewExpandableWorkflowBar({
	workflow,
	activated,
	handleCheckBoxes,
	isChecked,
	clientWorkflowId,
	workflowSubmission
}) {
	const { id } = useParams();

	const history = useHistory();
	const dispatch = useDispatch();
	const [selected, setSelected] = useState(false);
	const [template, setTemplate] = useState('');

	const loading = useSelector((state) => state.WorkflowBuilder.loading);

	const accountTypes = [
		{
			title: 'Software',
			type: objectBeingDragged.SOFTWARE,
		},
		{
			title: 'Credit Card Account',
			type: objectBeingDragged.CREDIT_CARD_ACCOUNT,
		},
		{
			title: 'Bank Account',
			type: objectBeingDragged.BANK_ACCOUNT,
		},
		{
			title: 'Merchant Account',
			type: objectBeingDragged.MERCHANT_ACCOUNT,
		},
		{
			title: 'Other Portals',
			type: objectBeingDragged.PAYMENT_PORTAL,
		},
	];

	const handleWorkflowsTemplate = (item) => {
		if (item.tenant_id !== null) {
			setTemplate('Custom');
		}
		if (item.tenant_id === null) {
			setTemplate('Standard');
		}
	};

	const handleSelected = () => {
		setSelected(!selected);
	};

	const deActivateWorkflow = (clientWorkflowId) => {
		dispatch(builderActions.deActivateWorkflow(clientWorkflowId, id));
	};
	
	const handleDraft = () => {
		const workflowObj = { ...workflow, status: 'draft' };
		workflowObj.task_templates = workflowObj?.task_templates?.map((item) => {
			return {
				...item,
				budgeted_hours: parseFloat(item.budgeted_hours) || 0.1,
			};
		});
		
		dispatch(
			builderActions.activateWorkflow(
				id,
				workflowObj,
				setSelected,
				history,
			),
		);
	};

	useEffect(() => {
		handleWorkflowsTemplate(workflow);
	}, [workflow]);

	useEffect(() => {
		dispatch(clientAccountActions.getClientAccounts(id));
		dispatch(clientAccountActions.getAllUrls());
	}, [dispatch]);

	return (
		<div>
			<div className='workflow-bar'>
				<div className='d-flex align-items-center'>
					{!activated && (
						<div>
							<input
								data-cy={`workflow-browser-${workflow.id}`}
								type='checkbox'
								className='multi-select-checkbox'
								onChange={() => handleCheckBoxes(workflow)}
								checked={isChecked?.includes(workflow)}
							/>
						</div>
					)}
					<div style={{ padding: '0px 12px' }}>
						<span className='BodyOneBold'>
							{workflowSubmission ? workflowSubmission?.data[0]?.displayName :
							workflow?.name || 'No Workflow'}
						</span>
					</div>
					<div>
						<span className='BodyTwoLight' style={{ color: '#696F6C' }}>
							{template}
						</span>
					</div>
				</div>
				{activated ? (
					<button
						onClick={() => deActivateWorkflow(clientWorkflowId)}
						disabled={loading}
						style={{
							border: 'none',
							outline: 'none',
							backgroundColor: 'transparent',
						}}
					>
						<span className='BodyOneLight' style={{ color: '#FB7A03' }}>
							Deactivate
						</span>
					</button>
				) : (
					<div onClick={handleSelected} className='pointer'>
						{selected ? <Up /> : <Down />}
					</div>
				)}
			</div>
			{								console.info(workflowSubmission , 'notehere')}
			{selected && (
				<>
					<div className='workflow-details-box'>
						<div className='workflow-details-header'>
							<span className='HeadlineThreeBold'>
								{workflow?.name} Information
							</span>
						</div>
						<div className='workflow-resources-container'>
							{accountTypes?.map((account, index) => {
								let component = null;
								if (workflowSubmission?.data?.length > 0 && workflowSubmission?.data[0]?.props?.sections?.length > 0) {
									workflowSubmission?.data[0]?.props?.sections[0]?.columns?.map((col) => {
										col?.columnComponents?.map((col_comp) => {
											if (getAccountComponentsWithSharedAccounts(account.type) === col_comp?.componentType) {
												component = col_comp;
											}
										});
									});
								}
								return (
									<ExpandableResourcesBox
										key={index}
										links={false}
										account={account}
										workflowBrowser
										title={account.title}
										dynamicComponent={component}
									/>
								);
							})}
							<ExpandableResourcesBox
								title='Client Links'
								links={true}
								workflowBrowser
							/>
						</div>
						{workflow?.workflow_builder?.data[0]?.props?.sections?.map(
							(section) => {
								return (
									<>
										{section?.componentType === 'TABLE' && (
											<>
												<div className='workflow-details-header'>
													<span className='HeadlineThreeBold'>
														{section?.componentName === 'Links' ? 'Workflow ' + section?.componentName  : section?.componentName}
													</span>
												</div>
												<div style={{ marginBottom: '16px' }}>
													<DynamicTable values={section} />
												</div>
											</>
										)}
										{section?.sectionName === 'NOTES' && (
											<>
												<div className='workflow-details-header'>
													<span className='HeadlineThreeBold'>
														{workflow?.name} Notes
													</span>
												</div>
												<div className='workflow-resources-container d-flex'>
													<span className='BodyTwoLight text-left'>
														{section?.columns[0]?.columnComponents[0]?.value ||
															'-'}
													</span>
												</div>
											</>
										)}
									</>
								);
							},
						)}

						<div
							className='workflow-details-header'
							style={{ justifyContent: 'space-between' }}
						>
							<div>
								<span className='HeadlineThreeBold'>
									Workflow: {workflow?.name}
								</span>
							</div>
							<div className='d-flex align-items-center'>
								<div>
									<span className='BodyTwoBold pr-2'>Recurrence</span>
								</div>
								<div>
									<span className='BodyTwoLight'>
										Set recurrence in client profile
									</span>
								</div>
							</div>
						</div>
						<div>
							<TasksTemplateTable tasks={workflow?.task_templates} />
						</div>
					</div>
					<div className='workflow-bottom-bar'>
						<SubmitButtonsField child={true}>
							<button
								type='submit'
								className='d-flex align-items-center'
								id='Element'
								disabled={loading}
								onClick={handleDraft}
							>
								{loading ? (
									<CircularProgress
										size={24}
										sx={{ color: 'white' }}
										disableShrink
									/>
								) : (
									'Save as Draft'
								)}
							</button>
						</SubmitButtonsField>
					</div>
				</>
			)}
		</div>
	);
}

export default NewExpandableWorkflowBar;
