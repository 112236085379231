import { secure_instance } from '../../axios/axios-config';

export const SEARCH_CHANGE_HANDLER = 'SEARCH_CHANGE_HANDLER';
export const SET_SEARCH_RESULT = 'SET_SEARCH_RESULT';
export const SET_LOADING = 'SET_LOADING';
export const SET_MODAL_STATE = 'SET_MODAL_STATE';

export const setModalState = (state) => {
	return (dispatch) => {

		if(!state){
			dispatch(onSearchChangeHandler(''));
		}

		dispatch({
			type: SET_MODAL_STATE,
			payload: state,
		});
	};
};

export const globalSearch = (search) => {
	return async (dispatch) => {
		try {
			dispatch(setLoading(true));

			const request = await secure_instance.request({
				url: `/v1/search/?search=${search}`,
				method: 'Get',
			});

			dispatch(setSearchResult(request.data));

			dispatch(setLoading(false));
		} catch (error) {
			//
		}
	};
};

export const setSearchResult = (value) => {
	return (dispatch) => {
		dispatch({
			type: SET_SEARCH_RESULT,
			payload: value,
		});
	};
};

export const setLoading = (value) => {
	return (dispatch) => {
		dispatch({
			type: SET_LOADING,
			payload: value,
		});
	};
};

export const onSearchChangeHandler = (data) => {
	return (dispatch) => {
		dispatch({
			type: SEARCH_CHANGE_HANDLER,
			payload: data,
		});
	};
};
