import React, { useEffect, useRef, useState } from 'react';

// Material Ui Components
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Avatar } from '@mui/material';

// Icon
import Icon from '../../assets/icons/Shape.svg';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import { ReactComponent as MangersViewIcon } from '../../assets/icons/UserCircleGear.svg';
import ClientIcon from '../Icons/ClientIcon';
import UserIcon from '../Icons/UserIcon';
import ProgressIcon from '../Icons/ProgressIcon';
import LogoutIcon from '@mui/icons-material/Logout';
import { ReactComponent as Arrow } from '../../assets/icons/client copy.svg';
import { ReactComponent as CompanyProfile } from '../../assets/icons/Bank.svg';
import { ReactComponent as InvoiceIcon } from '../../assets/icons/Money.svg';
import { ReactComponent as LeaveIcon } from '../../assets/icons/CalendarX.svg';

import { ReactComponent as WorkflowBuilder } from '../../assets/icons/Workflow-builder.svg';
import { ReactComponent as IntegrationIcon } from '../../assets/icons/Wrench.svg';

// Router
import { NavLink, useHistory } from 'react-router-dom';

//
import { storeUserData, storeUserId, StoreToken } from '../../redux/actions/authactions';
import { useDispatch, useSelector } from 'react-redux';
import {
	executivePermissions,
	Member,
	setCookie,
	standardPermissions,
} from '../../utilities/utilities';
import Restricted from '../Permissions/Restricted';
import * as authActions from '../../redux/actions/auth-actions';
import * as workspaceActions from '../../redux/actions/dashboard/workspaces-actions';
import posthog from 'posthog-js';
import moment from 'moment';

function NavigationMenu({ title }) {
	const dispatch = useDispatch();
	const history = useHistory();
	const ref = useRef();

	const [sideBarModal, setSideBarModal] = useState(false);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleLogout = () => {
		dispatch(StoreToken('', '', '', ''));
		dispatch(storeUserId(''));
		dispatch(storeUserData(''));
		dispatch(authActions.resetCurrentUserDetails());
		try {
			posthog.reset();
		} catch {
			//
		}
		window.Intercom('shutdown');
		localStorage.removeItem('WORKSPACE_DATE');
		localStorage.removeItem('workspace_applied_filters');
		localStorage.removeItem('active_task_id');
		sessionStorage.clear();
		dispatch(workspaceActions.resetState());
		setCookie('refresh_token', '');
		history.push('/');
		dispatch(authActions.setUserActivity('offline', moment().subtract(1, 'hours')));
		window.location.reload();
	};

	const user = useSelector((state) => state.authReducer.user);
	const userDetails = useSelector((state) => state.authReducer.userDetails);
	const isFeatureFlag = useSelector((state) => state.authReducer.isFeatureFlag);

	const link = {
		height: '100%',
		width: '100%',
		color: '#FFFEFB',
		textDecoration: 'none',
		display: 'flex',
		alignItems: 'center',
		gap: '0.4em',
		padding: '0.6rem 0.5rem 0.6rem 1.3rem',
	};

	const activeLink = {
		backgroundColor: '#FB7A03',
	};

	const MenuItemStyle = {
		display: 'flex',
		alignItems: 'center',
		fontFamily: 'CentraLight',
		fontSize: '14px',
		lineHeight: '18px',
		margin: 0,
		padding: 0,
		':hover': {
			backgroundColor: '#FB7A03',
		},
	};
	const miniIcon = {
		height: '23px',
		width: '20px',
	};

	const handleAccountModal = () => {
		setSideBarModal(!sideBarModal);
	};

	useEffect(() => {
		const checkIfClickedOutside = (e) => {
			if (sideBarModal && ref.current && !ref.current.contains(e.target)) {
				setSideBarModal(false);
			}
		};

		document.addEventListener('mousedown', checkIfClickedOutside);

		return () => {
			document.removeEventListener('mousedown', checkIfClickedOutside);
		};
	}, [sideBarModal]);

	return (
		<div className='NavBar'>
			<div className='flex1'>
				<div className='Icon pointer' onClick={() => history.push('/workspace')}>
					<img src={Icon} />
				</div>
				<div>
					<h4 className='BodyOneBold'>{title}</h4>
				</div>
			</div>

			<button
				className='flex2'
				id='basic-button'
				aria-controls={open ? 'basic-menu' : undefined}
				aria-haspopup='true'
				aria-expanded={open ? 'true' : undefined}
				onClick={handleClick}
			>
				<MenuIcon fontSize='small' />
			</button>

			<Menu
				elevation={0}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-between',
					'& .MuiMenu-list': {
						height: '100%',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
						margin: '0',
					},
					'& .MuiMenu-paper': {
						backgroundColor: '#28383c',
						width: '260px',
						color: '#FFFEFB',
						// height: '92.5vh',
						minHeight: `calc(100vh - 40px)`,
						height: '100%',
						borderRadius: '0',
						boxSizing: 'border-box',
					},
				}}
				marginThreshold={0}
			>
				<div className='d-flex flex-column'>
					<MenuItem className='Listitem' sx={MenuItemStyle} onClick={handleClose}>
						<NavLink activeStyle={activeLink} to='/workspace' style={link}>
							<ProgressIcon size='20px' fill='white' />
							<span className='pt-1'>My Workspace</span>
						</NavLink>
					</MenuItem>

					<MenuItem sx={MenuItemStyle} className='MenuItem' onClick={handleClose}>
						<NavLink activeStyle={activeLink} to='/clients' style={link}>
							<ClientIcon size='20px' fill='white' />
							<span className='pt-1'>Client Management</span>
						</NavLink>
					</MenuItem>

					<Restricted
						AllowedUsers={standardPermissions}
						userTeamRole={user?.user_roles?.map((item) => item.role_id)}
					>
						<MenuItem sx={MenuItemStyle} className='MenuItem' onClick={handleClose}>
							<NavLink activeStyle={activeLink} to='/Workflow-Builder' style={link}>
								<WorkflowBuilder /> <span className='pt-1'>Workflow Library</span>
							</NavLink>
						</MenuItem>
					</Restricted>

					<MenuItem sx={MenuItemStyle} className='MenuItem' onClick={handleClose}>
						<NavLink activeStyle={activeLink} to='/time-reports' style={link}>
							<MangersViewIcon style={miniIcon} />
							<span className='pt-1'>Time Reports</span>
						</NavLink>
					</MenuItem>

					<Restricted
						AllowedUsers={standardPermissions}
						userTeamRole={user?.user_roles?.map((item) => item.role_id)}
					>
						<>
							<MenuItem sx={MenuItemStyle} className='MenuItem' onClick={handleClose}>
								<NavLink activeStyle={activeLink} to='/teams' style={link}>
									<PeopleOutlineIcon style={miniIcon} />
									<span className='pt-1'>Team Management</span>
								</NavLink>
							</MenuItem>
						</>
					</Restricted>

					{isFeatureFlag && (
						<Restricted AllowedUsers={[Member.ownerId, Member.supervisorId]}>
							<MenuItem sx={MenuItemStyle} className='MenuItem' onClick={handleClose}>
								<NavLink activeStyle={activeLink} to='/invoices' style={link}>
									<InvoiceIcon /> Invoices
								</NavLink>
							</MenuItem>
						</Restricted>
					)}
					<Restricted
						AllowedUsers={[...standardPermissions, Member.teamMemberId]}
						userTeamRole={user?.user_roles?.map((item) => item.role_id)}
					>
						<>
							<MenuItem sx={MenuItemStyle} className='MenuItem' onClick={handleClose}>
								<NavLink activeStyle={activeLink} to='/users' style={link}>
									<UserIcon size='20px' fill='white' />{' '}
									<span className='pt-1'>User Management</span>
								</NavLink>
							</MenuItem>
						</>
					</Restricted>

					<MenuItem sx={MenuItemStyle} className='MenuItem' onClick={handleClose}>
						<NavLink activeStyle={activeLink} to='/integration' style={link}>
							<IntegrationIcon stroke='white' />{' '}
							<span className='pt-1'>Integration</span>
						</NavLink>
					</MenuItem>

					{posthog?.isFeatureEnabled('leave_requests') && (
						<Restricted AllowedUsers={executivePermissions}>
							<MenuItem sx={MenuItemStyle} className='MenuItem' onClick={handleClose}>
								<NavLink activeStyle={activeLink} to='/leaves' style={link}>
									<LeaveIcon stroke='white' />{' '}
									<span className='pt-1'>Leaves</span>
								</NavLink>
							</MenuItem>{' '}
						</Restricted>
					)}

					<Restricted AllowedUsers={[Member.ownerId]}>
						<MenuItem sx={MenuItemStyle} className='MenuItem' onClick={handleClose}>
							<NavLink activeStyle={activeLink} to='/company-profile' style={link}>
								<CompanyProfile /> <span className='pt-1'>Company Profile</span>
							</NavLink>
						</MenuItem>
					</Restricted>
				</div>
				<div className='LogoutContainer' style={{ position: 'relative' }}>
					<div className='User-Logout'>
						<Avatar
							sx={{
								bgcolor: `#FEF2E7`,
								color: `#333`,
								height: '2.2rem',
								width: '2.2rem',
								fontSize: '0.8rem',
							}}
							alt={userDetails?.full_name}
							src={userDetails?.image_url}
						/>
						<div className='LogoutContent'>
							<h6>{userDetails?.full_name}</h6>
							<p className='placeholderText'>{user?.userRole?.role_name}</p>
						</div>
					</div>

					<div style={{ cursor: 'pointer' }} onClick={handleAccountModal}>
						<Arrow />
					</div>

					{sideBarModal && (
						<div
							ref={ref}
							style={{
								position: 'absolute',
								bottom: '55px',
								padding: '16px',
								width: '232px',
								height: 'auto',
								borderRadius: '8px',
								backgroundColor: '#333333',
								boxShadow: '2px 4px 12px -2px rgba(0, 0, 0, 0.25)',
							}}
						>
							<div style={{ cursor: 'pointer' }}>
								<NavLink to='/account-settings' style={{ color: 'white' }}>
									<span className='BodyTwoBold'>Profile Settings</span>
								</NavLink>
							</div>
							<div
								className='d-flex w-100 justify-content-between pt-2'
								style={{ cursor: 'pointer' }}
								onClick={handleLogout}
							>
								<div>
									<span className='BodyTwoBold'>Logout</span>
								</div>
								<div>
									<LogoutIcon sx={{ color: 'white' }} />
								</div>
							</div>
						</div>
					)}
				</div>
			</Menu>
		</div>
	);
}

export default NavigationMenu;
