import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import SelectList from '../../Inputs/SelectList';
import EditableTextArea from '../../Inputs/EditableTextArea';

function AddTemplateTask({ onCancel, onSubmit, editor, formValues, editTask }) {
	const [form, setForm] = useState({
		task_type_id: 2,
		title: '',
		description: '',
		is_billable: true,
		budgeted_hours: 1,
		dependent_task_id: null,
	});

	const task_templates = useSelector(
		(state) => state.WorkflowBuilder.task_templates,
	);

	const handleBudgetedHoursChange = (event) => {
		const { name, value } = event.target;
		const pattern = /^\d*\.?\d*$/;
		if (!pattern.test(value)) {
			return;
		}
		setForm({ ...form, [name]: value });
	};

	const formChangeHandler = (event) => {
		let { name, value } = event.target;

		if (name == 'dependent_task_id' && value == '') value = null;

		if (name == 'dependent_task_id' && !isNaN(value) && value !== null)
			value = parseInt(value);

		setForm({
			...form,
			[name]: value,
		});
	};

	const quillHandlerDescription = (content) => {
		setForm({
			...form,
			['description']: content,
		});
	};

	const formSubmit = (event) => {
		event.preventDefault();
		onSubmit(form);
	};

	useMemo(() => {
		if (formValues && Object.keys(formValues).length > 0) {
			setForm({
				...formValues,
				task_type_id: formValues.task_type_id || 2,
				title: formValues.title || '',
				description: formValues.description || '',
				is_billable: formValues.is_billable || true,
				budgeted_hours: formValues.budgeted_hours || 1,
				dependent_task_id: formValues.dependent_task_id || null,
			});
		}

		return () => {
			setForm({});
		};
	}, [formValues]);

	const taskIdOne = form.id;

	const dependentOfdependent = task_templates
		?.filter((t) => {
			return t.dependent_task_id == taskIdOne;
		})
		?.map((t) => t.id);

	task_templates?.map((t) => {
		const taskIdTwo = t.id;
		if (dependentOfdependent?.some((item) => item == t.dependent_task_id)) {
			dependentOfdependent.push(taskIdTwo);
		}
	});

	return (
		<>
			<form
				autoComplete='off'
				onSubmit={formSubmit}
				className='UserAdd-Form'
				style={{ overflow: 'auto' }}
			>
				<div className='p-3 pt-0' style={{ overflow: 'visible' }}>
					<div className='improved-input-designs pb-3'>
						<label className='require'>Task</label>
						<input
							className='w-100 input-style'
							type='text'
							name='title'
							value={form?.title}
							onChange={formChangeHandler}
							required={true}
						/>
					</div>
					<div className='improved-input-designs pb-3'>
						<label className=''>Instructions</label>
						{editor ? (
							<EditableTextArea
								richText
								Class='w-100 quill-input-style'
								name='description'
								value={form?.description}
								onChange={quillHandlerDescription}
								Edit={true}
								style={{
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'space-between',
									gap: 9,
									textAlign: 'left',
									height: '130px',
								}}
							/>
						) : (
							<input
								className='w-100 input-style'
								type='text'
								name='description'
								value={form?.description}
								onChange={formChangeHandler}
							/>
						)}
					</div>
					<div className='improved-input-designs pb-3'>
						<label className='require'>Budgeted Hours</label>
						<input
							className='w-100 input-style'
							type='text'
							value={form?.budgeted_hours}
							name='budgeted_hours'
							onChange={handleBudgetedHoursChange}
							required={true}
						/>
					</div>

					<div className='improved-input-designs pb-3'>
						<label className=''>Dependency (Optional)</label>
						<SelectList
							placeholder='Select Dependency'
							options={
								editor && form.id
									? task_templates
											?.filter((task) => {
												const taskIdOne = task.id;
												const taskIdTwo = form.id;
												return (
													taskIdOne != taskIdTwo &&
													!dependentOfdependent?.some(
														(item) => item == taskIdOne,
													)
												);
											})
											?.map((item) => {
												return {
													label: item.title,
													value: item.id,
												};
											})
									: task_templates?.map((task) => {
											return {
												label: task.title,
												value: task.id,
											};
									})
							}
							name='dependent_task_id'
							value={{ value: form?.dependent_task_id }}
							onChange={formChangeHandler}
						/>
					</div>
				</div>

				<div className='bg-white p-3 rounded-bottom d-flex flex-row justify-content-between'>
					<button type='button' onClick={onCancel} className='secondary-btn'>
						Cancel
					</button>

					<button type='submit' className='primary-btn'>
						{editTask ? 'Update' : 'Add'}
					</button>
				</div>
			</form>
		</>
	);
}

export default AddTemplateTask;
