import React, { ReactNode } from 'react';

// Import Components
import CardHeader from './CardHeader';
// import CardFooter from './CardFooter';
const styles = {
	backgroundColor: '#EEF2F3',
	border: 'none',
	boxShadow: '0px 8px 24px rgba(149, 157, 165, 0.2)',
	display: 'flex',
	flexDirection: 'column',
	// gap: '18px'
};

const Card = ({
	title = 'Card',
	children,
	onClick,
	buttonLabel,
	id,
	secondaryBtn,
	secondaryBtnClick,
}) => (
	<div className='Card w-100' id={id} style={styles}>
		<CardHeader
			title={title}
			onClick={onClick}
			secondaryBtn={secondaryBtn}
			secondaryBtnClick={secondaryBtnClick}
		/>
		{children}
		{/* <CardFooter onClick={onClick} buttonLabel={buttonLabel} /> */}
	</div>
);

export default Card;
