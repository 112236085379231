import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as builderActions from '../../redux/actions/workflow-builder-actions.js';
import ClientWorkflowRecurrence from './ClientWorkflowRecurrence.jsx';
import ClientWorkflowTask from './ClientWorkflowTask.jsx';
import AddIcon from '@mui/icons-material/Add';
import useModal from '../Modal/useModal.jsx';
import { Modal } from '@mui/material';
import DummyModal from '../Modal/DummyModal.jsx';
import WorkflowTaskModal from './Modals/WorkflowTaskModal.jsx';
import WorkflowResources from './WorkflowResources.jsx';
import * as clientActions from '../../redux/actions/client-actions';
import * as clientAccountActions from '../../redux/actions/client-accounts-actions.js';
import { useParams } from 'react-router';
import ClientWorkflowNotes from './ClientWorkflowNotes.jsx';
import WorkflowBar from './WorkflowBar.jsx';
import Restricted from '../Permissions/Restricted.jsx';
import { sortTasks, standardPermissions } from '../../utilities/utilities.js';
import DocumentManagement from './DocumentManagement.jsx';
import WorkflowProgressBar from './WorkflowProgressBar.jsx';
import { ReactComponent as TaskIcon } from '../../assets/icons/ClipboardText.svg';

function ClientWorkflow() {
	const dispatch = useDispatch();
	const { id } = useParams();

	const [workflowName, setWorkflowName] = useState('');

	const clientWorkflow = useSelector((state) => state.WorkflowBuilder.selectedClientWorkflow);
	const client = useSelector((state) => state.clientReducer.client);
	const user = useSelector((state) => state.authReducer.user);
	const teamMembers = useSelector((state) => state.teamReducer.teamMembers);

	const assignees = teamMembers?.map((member) => {
		return {
			...member,
			full_name: member.name,
			id: member.user_id,
			is_active: member.is_active,
			image_url: member.image_url,
		};
	});

	const defaultFolderId = clientWorkflow?.attachment?.sections?.folder?.default_folder_id;

	const userTeamRole = useMemo(() => {
		return (
			user?.user_roles?.find((item) =>
				item?.role_scopes
					?.map((role) => role.team_id)
					?.includes(client?.team_client_permissions[0]?.team_id),
			)?.role_id || user?.userRole?.id
		);
	}, [user?.user_roles, client?.team_client_permissions]);

	const handleClientWorkflowActivate = () => {
		const updatedclientWorkflow = {
			...clientWorkflow,
			status: 'active',
			is_active: true,
		};
		delete updatedclientWorkflow.client_workflow_tasks;
		delete updatedclientWorkflow.workflow_submission;

		dispatch(builderActions.updateClientWorkflow(updatedclientWorkflow)).then(
			(newClientWorkflow) => {
				dispatch(builderActions.updateClientWorkflowList(newClientWorkflow));
				dispatch(builderActions.setSelectedClientWorkflow(newClientWorkflow?.id))
					.then((res) => {
						console.log(res, 'response123');
					})
					.catch((err) => {
						console.log(err, 'error');
					});
			},
		);
	};

	const sortedWorkflowTasks = useMemo(() => {
		const tasks = clientWorkflow?.client_workflow_tasks
			? sortTasks(clientWorkflow?.client_workflow_tasks)
			: [];
		return tasks;
	}, [clientWorkflow?.client_workflow_tasks]);

	useMemo(() => {
		dispatch(clientAccountActions.getClientAccounts(id));
		dispatch(clientAccountActions.getAllUrls());

		return () => {
			dispatch(builderActions.resetSelectedClientWorkflow());
		};
	}, [id]);

	useEffect(() => {
		dispatch(clientActions.getSpecificFolderName(defaultFolderId))
			.then((res) => {
				setWorkflowName(res?.name);
			})
			.catch((err) => {
				console.log(err);
			});
	}, [defaultFolderId]);

	return (
		<section id='PROFILE' className='client-profile'>
			<div className='d-flex flex-column w-100 align-items-center' style={{ gap: '12px' }}>
				<WorkflowBar
					display_name={clientWorkflow?.display_name}
					workflowName={workflowName}
					draft={clientWorkflow?.status == 'draft'}
					enableAction={clientWorkflow?.repeat_type ? false : true}
					onActivate={handleClientWorkflowActivate}
					client_workflow_id={clientWorkflow?.id}
					user_team_role={userTeamRole}
                    color={clientWorkflow?.color}
				/>
				<WorkflowProgressBar
					repeat_type={clientWorkflow?.repeat_type}
					isTasksAssigned={
						!sortedWorkflowTasks?.some(
							(task) =>
								task?.assignee_user_id == null ||
								task?.assignee_user_id == undefined,
						)
					}
					workflow_submission={clientWorkflow?.workflow_submission}
				/>
				<div className='Profile-Content' style={{ gap: '12px' }}>
					<ClientWorkflowRecurrence
						user_team_role={userTeamRole}
						draft={clientWorkflow?.status == 'draft'}
						client_workflow_id={clientWorkflow?.id}
						is_tenative_task={clientWorkflow?.is_tenative_task}
						required_days={clientWorkflow?.required_days}
						repeat_interval={clientWorkflow?.repeat_interval}
						repeat_type={clientWorkflow?.repeat_type}
						start_date={clientWorkflow?.start_date}
						recur_preference={clientWorkflow?.recur_preference}
						is_monthly_on_day={clientWorkflow?.is_monthly_on_day}
						recurrence_end_on_type={clientWorkflow?.end_on?.type}
						recurrence_end_on_occurrence={clientWorkflow?.end_on?.occurrence}
						recurrence_end_on_end_date={clientWorkflow?.end_on?.end_date}
					/>

					<div className='client-workflow-task-section'>
						<span className='d-flex task-section-header' style={{ gap: '12px' }}>
							<TaskIcon />
							<h3 className='HeadlineThreeBold'>Tasks</h3>
						</span>
						{sortedWorkflowTasks?.map((task, index, total_tasks) => {
							const dependent_task_name = total_tasks?.find(
								(item) => item.id == task.dependent_task_id,
							)?.title;
							const taskAssignee = assignees?.find(
								(u) => u.id == task.assignee_user_id,
							);
							return (
								<ClientWorkflowTask
									key={task.id}
									task={task}
									dependent_task_name={dependent_task_name}
									user_team_role={userTeamRole}
									assignee_user={taskAssignee}
								/>
							);
						})}

						<Restricted
							AllowedUsers={standardPermissions}
							userTeamRole={[userTeamRole]}
						>
							<AddNewTask client_workflow_id={clientWorkflow?.id} />
						</Restricted>
					</div>
				</div>

				<div className='Profile-Content' style={{ gap: '12px' }}>
					<WorkflowResources
						user_team_role={userTeamRole}
						workflow_submission={clientWorkflow?.workflow_submission}
						display_name={clientWorkflow?.display_name}
					/>
				</div>

				<ClientWorkflowNotes
					workflow_submission={clientWorkflow?.workflow_submission}
					display_name={clientWorkflow?.display_name}
				/>

				<DocumentManagement
					client={client}
					clientWorkflow={clientWorkflow}
					showCheckBox={true}
					sharedAccess={false}
				/>
			</div>
		</section>
	);
}

export default memo(ClientWorkflow);

const AddNewTaskComponent = ({ client_workflow_id }) => {
	const { modalIsOpen, toggleModal, ModalName, ModalType } = useModal();

	const onAddNewClick = () => {
		ModalType('CLIENT_WORKFLOW_TASK');
		toggleModal();
	};

	return (
		<div
			className='w-100 d-flex justify-content-center information-header d-flex align-items-center'
			style={{
				boxShadow: '0px 8px 24px 0px #959DA533',
			}}
		>
			<div
				className='pointer d-flex flex-row align-items-center'
				style={{
					height: '22px',
					width: '6px',
					borderRadius: '3px',
					marginRight: '1rem',
					padding: 0,
					position: 'relative',
				}}
				onClick={toggleModal}
			>
				<AddIcon
					fontSize='small'
					sx={{
						color: '#fb7a03',
						fontSize: 21,
						position: 'absolute',
						margin: 0,
						padding: 0,
						top: 0,
						left: 0,
					}}
				/>
			</div>
			<h3
				className='BodyTwoLight mt-2 dark-text'
				style={{
					color: '#FB7A03',
					cursor: 'pointer',
				}}
				onClick={onAddNewClick}
			>
				Add New Task
			</h3>

			{modalIsOpen && (
				<Modal
					onClose={toggleModal}
					open={modalIsOpen}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<>
						{ModalName == 'CLIENT_WORKFLOW_TASK' && (
							<DummyModal
								style={{ width: '900px' }}
								hideCrossIcon
								title='Task Details'
								onClose={toggleModal}
							>
								<WorkflowTaskModal
									client_workflow_id={client_workflow_id}
									onCancel={toggleModal}
								/>
							</DummyModal>
						)}
					</>
				</Modal>
			)}
		</div>
	);
};

const AddNewTask = memo(AddNewTaskComponent);
