import React, { useEffect, useState } from 'react';

// Material Ui Components
import { Box, CircularProgress } from '@mui/material';

// Importing Components
import SubmitButtonsField from '../Buttons/SubmitButtonsField';
import PickList from '../Inputs/PickList';
import {
	states,
	industries2,
	business_types2,
	identificationTypes,
} from '../DemoData';
import { useDispatch, useSelector } from 'react-redux';
import * as teamActions from '../../redux/actions/team-management.actions';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { Member, executivePermissions } from '../../utilities/utilities';
import Select from '../Inputs/Select';

function AddClient({
	onCancel,
	OnChange,
	OnDateChange,
	value,
	submit,
	type,
	selectedID,
}) {
	const dispatch = useDispatch();
	
	const [loading, setLoading] = useState(false);
	const teams = useSelector((state) => state.teamReducer.teams);
	const error = useSelector((state) => state.clientReducer.error);
	const formLoad = useSelector((state) => state.clientReducer.formLoad);
	const user = useSelector((state) => state.authReducer.user);
	const [totalTeams, setTotalTeams] = useState([]);
	const [identificationType, setIdentificationType] = useState(null);

	const isAllowed = executivePermissions?.includes(user?.userRole?.id);

	const FormSubmit = (e) => {
		e.preventDefault();
		setLoading(true);

		submit(type, selectedID, value, setLoading);
	};

	const InputBgColor = '#F6F5F2';
	const Style = {
		backgroundColor: 'white',
		border: 'none',
		borderBottom: '1px solid #28383C',
	};

	useEffect(() => {
		dispatch(teamActions.getTeams());
	}, [dispatch]);

	// This useEffect filters the teams where logged in user is manager
	useEffect(() => {
		const ManagerTeams = [];
		user?.user_roles?.map((item) => {
			if (item?.role_id === Member.teamManagerId) {
				item?.role_scopes?.map((role) => {
					ManagerTeams.push(role.team_id);
					return role;
				});
			}
			return item;
		});
		if (ManagerTeams?.length > 0) {
			setTotalTeams(teams?.filter((item) => ManagerTeams?.includes(item.id)));
		} else {
			setTotalTeams(teams);
		}
	}, [teams.length]);


	return (
		<form autoComplete='off' onSubmit={FormSubmit}>
			<div
				className='p-3'
				style={{
					maxHeight: '75vh',
					height: 'fit-content',
					position: 'relative',
					overflowY: 'auto',
				}}
			>
				{/* <h4 className='BodyTwoBold'>Details</h4> */}

				<div className='improved-input-designs pb-3'>
					<label className='require w-100'>Name/Business Name</label>
					<input
						className='w-100'
						type='text'
						name='company_name'
						onChange={OnChange}
						value={value.company_name}
						style={Style}
						data-cy='company_name'
						required
						placeholder='Enter name'
					/>
				</div>

				<div className='improved-input-designs pb-3'>
					<label className=''>DBA</label>
					<input
						className='w-100'
						type='text'
						name='dba'
						style={Style}
						onChange={OnChange}
						value={value?.dba}
						placeholder='Enter DBA'
					/>
				</div>

				{/* <div className='improved-input-designs pb-3'>
					<label className=' require '>Identity Type</label>
					<PickList
						value={identificationType ? identificationType : ''}
						options={identificationTypes}
						Name='identification_type'
						SelectRole={(e) => setIdentificationType(parseInt(e.target.value))}
						cypressId='identification_type'
					/>
				</div>

				{identificationType === 2 ? (
					<div className='improved-input-designs pb-3'>
						<label className='require'>Ein</label>
						<input
							pattern='[0-9]{2}[-]{1}[0-9]{7}'
							className='w-100'
							name='ein'
							onChange={OnChange}
							value={value.ein}
							style={Style}
							required
							title='Match Ein format xx-xxxxxxx'
							placeholder='xx-xxxxxxx'
							data-cy='ein'
						/>
					</div>
				) : (
					identificationType === 1 && (
						<div className='improved-input-designs pb-3'>
							<label className='require'>SSN</label>
							<input
								pattern='\d{3}[\-]\d{2}[\-]\d{4}'
								className='w-100'
								name='ssn'
								onChange={OnChange}
								value={value.ssn}
								style={Style}
								required
								title='Match SSN format xxx-xx-xxxx'
								placeholder='xxx-xx-xxxx'
								data-cy='ssn'
							/>
						</div>
					)
				)}
				<div className='improved-input-designs pb-3'>
					<label className='w-100'>Business Type</label>

					<Select
						value={{ value: value.business_type }}
						options={business_types2}
						name='business_type'
						onChange={OnChange}
					/>
				</div>
				<div className='improved-input-designs pb-3'>
					<label className=''>Industry</label>
					<Select
						value={{ value: value.industry }}
						options={industries2}
						name='industry'
						onChange={OnChange}
					/>
				</div> */}

				{/* { value.industry === '24' &&
						<div className='VerticalSpacer'>
							<label className=' '>Industry</label>
							<input 
								name="industry" 
								type="text" 
								className='w-100' 
								style={Style} 
								onChange={OnChange} 
								value={value.industry} 
							/>	
						</div>} */}

				{/* <h4 className='BodyTwoBold pt-2'>Formation</h4>

				<div className='VerticalSpacer d-flex flex-column pb-3'>
					<label className='w-100'>Date of formation</label>
					<KeyboardDatePicker
						autoOk
						disableToolbar
						value={value?.date || null}
						name='date'
						onChange={OnDateChange}
						format='MM/DD/yyyy'
					/>
				</div> */}

				<div className='improved-input-designs pb-3'>
					<label className='require'>Team</label>
					<PickList
						value={
							value.team_client_permissions?.team_id ||
							value.team_client_permissions[0]?.team_id
						}
						SelectRole={OnChange}
						options={totalTeams}
						Name='team_id'
						cypressId='team_id'
						require={true}
						customAction={isAllowed}
						customText='Add New Team'
					/>
				</div>

				{/* <div className='improved-input-designs'>
					<label className='require'>State</label>
					<Select
						value={{ value: value.state }}
						options={states}
						name='state'
						onChange={OnChange}
						require={true}
					/>
				</div> */}
			</div>

			{error && (
				<div className='px-3 py-2'>
					<span style={{ color: 'red', fontSize: '0.9em' }}>
						{error}
					</span>
				</div>
			)}

			<div className='VerticalSpacer bg-white p-3 rounded-bottom'>
				<SubmitButtonsField child={true}>
					<button
						id='Element'
						type='button'
						className='secondary'
						onClick={onCancel}
					>
						Cancel
					</button>

					<button
						type='submit'
						className='d-flex align-items-center'
						id='Element'
						disabled={loading}
						data-cy='add-client-btn'
					>
						{loading ? (
							<CircularProgress
								size={24}
								sx={{ color: 'white' }}
								disableShrink
							/>
						) : (
							'Create'
						)}
					</button>
				</SubmitButtonsField>
			</div>
		</form>
	);
}

export default AddClient;
