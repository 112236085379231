import React, { memo } from 'react';

// Material Ui Components
import {
	Typography,
	Box,
	TextField,
	Input,
	InputAdornment,
	IconButton,
	Menu,
	MenuItem,
	Button,
	Avatar,
} from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AddIcon from '@mui/icons-material/Add';
import { makeStyles, Tooltip } from '@material-ui/core';
import { executivePermissions, Member } from '../../utilities/utilities';
import { useSelector } from 'react-redux';
import Restricted from '../Permissions/Restricted';
// ...

const useStyles = makeStyles({
	customWidth: {
		'& div': {
			width: '150px',
		},
	},
});

// ...

function ActionButton({
	EditUser,
	edit,
	HandleRemove,
	Uid,
	ClassName,
	disable,
	title,
	leaveFlow,
	// slack,
	ApproveRequest,
	RejectRequest,
	// ContactSlack,
	slackId,
	style,
	removebtn,
	btnText,
	customAction,
	customText,
	onCustomClick,
}) {
	const classes = useStyles();
	const user = useSelector((state) => state.authReducer.user);

	//   Action Button
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const OnRemove = (uid) => {
		handleClose();
		HandleRemove(uid);
	};

	const OnEdit = () => {
		handleClose();
		EditUser();
	};

	const OnApproval = () => {
		handleClose();
		ApproveRequest();
	};

	const OnRejection = () => {
		handleClose();
		RejectRequest();
	};

	// const OnContactSlack = () => {
	// 	handleClose();
	// 	ContactSlack();
	// };

	if (leaveFlow) {
		return (
			<>
				{disable ? (
					<Tooltip title={title}>
						<IconButton className={ClassName} id='basic-button'>
							<MoreHorizIcon htmlColor='#333' />
						</IconButton>
					</Tooltip>
				) : (
					<IconButton
						className={ClassName}
						id='basic-button'
						aria-controls={open ? 'basic-menu' : undefined}
						aria-haspopup='true'
						aria-expanded={open ? 'true' : undefined}
						onClick={handleClick}
					>
						{edit ? <AddIcon htmlColor='#333' /> : <MoreHorizIcon htmlColor='#333' />}
					</IconButton>
				)}

				<Menu
					elevation={0}
					id='basic-menu'
					anchorEl={anchorEl}
					open={open}
					onClose={handleClose}
					MenuListProps={{
						'aria-labelledby': 'basic-button',
					}}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					sx={{
						'& .MuiMenu-list': {},
						'& .MuiMenu-paper': {
							boxShadow: ' 0px 1px 3px -1px rgba(149, 157, 165, 0.9)',
						},
					}}
				>
					<MenuItem onClick={OnApproval}>
						<h3 className='BodyTwoLight pt-2'>Approve</h3>
					</MenuItem>
					{slackId !== null && (
						<MenuItem
							onClick={() => {
								window.open(
									`https://slack.com/app_redirect?channel=${slackId}`,
									'_blank',
								);
							}}
						>
							<h3 className='BodyTwoLight pt-2'>Contact on Slack</h3>{' '}
						</MenuItem>
					)}
					<MenuItem onClick={OnRejection}>
						<h3 className='BodyTwoLight pt-2'>Decline Request</h3>
					</MenuItem>

					{/* For User Listing So That user cannot delete itself */}
					{/* {user?.userId !== Uid && (
						<>
							<Restricted AllowedUsers={executivePermissions}>
								<MenuItem onClick={() => OnRemove(Uid)}>
									<h3 className='BodyOneB p-1'>Remove</h3>
								</MenuItem>
							</Restricted>
						</>
					)} */}
				</Menu>
			</>
		);
	} else {
		return (
			<>
				{disable ? (
					<Tooltip title={title}>
						<IconButton className={ClassName} id='basic-button'>
							<MoreHorizIcon htmlColor='#333' />
						</IconButton>
					</Tooltip>
				) : (
					<IconButton
						className={ClassName}
						id='basic-button'
						aria-controls={open ? 'basic-menu' : undefined}
						aria-haspopup='true'
						aria-expanded={open ? 'true' : undefined}
						onClick={handleClick}
					>
						{edit ? (
							<AddIcon htmlColor='#333' />
						) : (
							<MoreHorizIcon data-cy='open-row-action' htmlColor='#333' />
						)}
					</IconButton>
				)}

				<Menu
					elevation={0}
					id='basic-menu'
					anchorEl={anchorEl}
					open={open}
					onClose={handleClose}
					MenuListProps={{
						'aria-labelledby': 'basic-button',
					}}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					sx={{
						'& .MuiMenu-list': {},
						'& .MuiMenu-paper': {
							boxShadow: ' 0px 1px 3px -1px rgba(149, 157, 165, 0.9)',
						},
					}}
				>
					<div
						style={{
							backgroundColor: 'white',
							width: '150px',
							borderRadius: '3px',
							height: '100%',
							...style,
						}}
					>
						<MenuItem data-cy='edit-profile' onClick={OnEdit}>
							<h3 className='BodyTwoLight pt-2'>Edit</h3>
						</MenuItem>

						{/* For User Listing So That user cannot delete itself */}
						{/* {user?.userId !== Uid && (
							<>
								<Restricted
									AllowedUsers={[Member.adminId, Member.superAdminId]}> */}
						{removebtn && (
							<MenuItem onClick={OnRemove}>
								<h3 className='BodyTwoLight pt-2'>{btnText || 'Remove'}</h3>
							</MenuItem>
						)}
						{/* </Restricted>
							</>
						)} */}
						{customAction && (
							<MenuItem data-cy='edit-profile' onClick={onCustomClick}>
								<h3 className='BodyTwoLight pt-2'>{customText}</h3>
							</MenuItem>
						)}
					</div>
				</Menu>
			</>
		);
	}
}

export default memo(ActionButton);
