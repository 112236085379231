import React from 'react';
import { workflowAccountsComponent } from '../../utilities/utilities';
import ExpandableResourcesBox from '../sub_components/ExpandableResourcesBox';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';

function ResourcesTab({ accountTypes }) {
	const taskDetails = useSelector((state) => state.workSpaceReducer.newTaskDetails);
	const loading = useSelector((state) => state.workSpaceReducer.resourceLoading);
	const workflowSubmission = useSelector((state) => state.workSpaceReducer.workflowSubmission);
	const client_software_stacks = useSelector(
		(state) => state.workSpaceReducer.clinet_software_stacks,
	);

	const urls = useSelector((state) => state.clientAccountsReducer.allUrls);

	let clientAccounts = [];
	if (client_software_stacks?.length > 0) {
		clientAccounts = client_software_stacks?.map((item) => {
			return {
				...item,
				softwares: item?.softwares?.map((soft) => {
					return {
						...soft,
						institution:
							urls?.find((url) => url.id == soft.institution_url_id)?.institution ||
							soft?.name,
						login:
							urls?.find((url) => url.id == soft.institution_url_id)?.url ||
							soft?.custom_url,
					};
				}),
			};
		});
	}
	return (
		<>
			{loading ? (
				<div className='h-100 d-flex justify-content-center align-items-center'>
					<CircularProgress
						size={25}
						sx={{
							color: '#fb7a03',
						}}
						disableShrink
					/>
				</div>
			) : (
				<>
					{accountTypes?.map((software, index) => {
						const workflowAccounts = [];
						if (workflowSubmission?.props?.sections?.length > 0) {
							workflowSubmission?.props?.sections[0]?.columns?.map((col) => {
								col?.columnComponents?.map((col_comp) => {
									if (
										workflowAccountsComponent.includes(col_comp?.componentType)
									) {
										col_comp?.value &&
											workflowAccounts.push(...col_comp?.value);
									}
								});
							});
						}
						return (
							<ExpandableResourcesBox
								key={index}
								workflowAccounts={workflowAccounts}
								title={software.title}
								accounts={
									clientAccounts?.find(
										(accType) => accType.type == software.value,
									)?.softwares
								}
								taskDetails={taskDetails}
								links={false}
							/>
						);
					})}

					<ExpandableResourcesBox
						title='Shared Drives & Links'
						taskClient={taskDetails?.client}
						workflowSubmission={workflowSubmission}
						links={true}
					/>
				</>
			)}
		</>
	);
}

export default ResourcesTab;
