import React, { memo } from 'react';

const ButtonSquare = (props) => {
	const { id, clickHandler, className, style, disabled } = props;
	return (
		<button
			style={style}
			className={className}
			onClick={clickHandler && clickHandler}
			disabled={disabled}
			id={id || 'Element'}
			{...props}
		/>
	);
};

export default memo(ButtonSquare);
