import { CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SubmitButtonsField from '../../Buttons/SubmitButtonsField';
import * as clientActions from '../../../redux/actions/client-actions';
import RichTextEditor from '../../Inputs/RichTextEditor';

function NotesModal({
	edit,
	onCancel,
	updateChanges,
	isLoading,
	initialNotes,
}) {
	const [notes, setNotes] = useState('');

	// If notes already exist
	useEffect(() => {
		if (initialNotes) {
			setNotes(initialNotes);
		}
	}, [initialNotes]);

	const handleChange = (e) => {
		setNotes(e);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		updateChanges(notes);
	};

	return (
		<form autoComplete='off' onSubmit={handleSubmit}>
			<div className='notes-modal' style={{ height: '50vh' }}>
				<RichTextEditor
					Edit={edit}
					name='notes'
					value={notes}
					onChange={handleChange}
					containerClass='h-100'
					style={{
						width: '100%',
						height: '90%',
						padding: '1em',
						boxSizing: 'border-box',
						wordWrap: 'break-word',
						textAlign: 'left',
						borderRadius: '4px',
					}}
				/>
			</div>

			<div className='VerticalSpacer bg-white p-3 rounded-bottom'>
				<SubmitButtonsField child={true}>
					<button
						id='Element'
						type='button'
						className='secondary'
						onClick={onCancel}
					>
						Cancel
					</button>

					{edit && (
						<button
							className='d-flex align-items-center'
							id='Element'
							type='submit'
							disabled={isLoading && true}
						>
							{isLoading ? (
								<CircularProgress
									size={24}
									sx={{ color: 'white' }}
									disableShrink
								/>
							) : (
								'Submit'
							)}
						</button>
					)}
				</SubmitButtonsField>
			</div>
		</form>
	);
}

export default NotesModal;
