import React from 'react';
import SubmitButtonsField from '../../Buttons/SubmitButtonsField';
import { Checkbox } from '@mui/material';

function DeleteWorkflowMyTaskModal({
    OnCancel,
	Onsubmit,
	description,
	descriptionClassName,
	title,
    deleteCurrentTask,
    setDeleteCurrentTasks
}) {
  return (
		<div>
			<div className='VerticalSpacer'>
				<h4 className={descriptionClassName + ' BodyTwoLight p-3'}>
					{description}
				</h4>

						<div className='new-input-designs'>
							<div className='pl-3'>
								<Checkbox
									sx={{
										transform: 'scale(0.9)',
										color: '#b4b4b4 !important',
										'&.Mui-checked': {
											color: '#FB7A03 !important',
										},
										'&.MuiCheckbox-root': {
											'&:hover': {
												boxShadow: 'none',
												backgroundColor: 'transparent !important',
											},
										},
									}}
									style={{
										paddingLeft: 0,
										paddingRight: 0,
									}}
									checked={deleteCurrentTask}
									onChange={(event) =>
										setDeleteCurrentTasks(event.target.checked)
									}
								/>
								<label
									style={{ paddingTop: '10px !important' }}
									className='approval-text align-self-center w-50 pt-4'
								>
									Delete Current Task
								</label>
							</div>
						</div>

				<SubmitButtonsField
					rootClass='bg-white p-3'
					title={title}
					onCancel={OnCancel}
					onSubmit={Onsubmit}
				/>
			</div>
		</div>
	);
}

export default DeleteWorkflowMyTaskModal;