import { ReactComponent as WarningIcon } from '../../assets/icons/WarningBlack.svg';

const ClientSectionHeader = ({
	title,
	border = true,
	warningIcon,
	subheader,
    style,
    subHeaderStyle
}) => {
	return (
		<div
			className={
				border ? 'ClientSectionHeader with-border' : 'ClientSectionHeader'
			}
            style={style}
		>
			{warningIcon && (
				<div className='pr-1 pb-1 '>
					{' '}
					<WarningIcon />{' '}
				</div>
			)}
			<h2 className='HeadlineTwoBold'>{title}</h2>
			<span style={subHeaderStyle}>{subheader && subheader}</span>
		</div>
	);
};

export default ClientSectionHeader;
