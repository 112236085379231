import { useDispatch, useSelector } from 'react-redux';
import FormButtonOne from '../../Buttons/FormButtonOne';
import React, { forwardRef } from 'react';
import * as clientActions from '../../../redux/actions/client-actions';
import { useHistory } from 'react-router';

const AutomateInputModal = forwardRef(function Modal({ onClose }, ref) {
	const dispatch = useDispatch();
	const history = useHistory();

	const client = useSelector((state) => state.clientReducer.client);

	const [file, setFile] = React.useState('');
	const fileInputRef = React.createRef();
	const [formControls, setFormControls] = React.useState({
		error: undefined,
		loading: false,
	});

	const handleFileChange = (e) => {
		const file = e.target.files[0];
		if (file) {
			setFile(file);
		}
	};

	const handleButtonClick = () => {
		fileInputRef.current.click();
	};

	function submitHandler(e) {
		e.preventDefault();

		setFormControls({
			error: undefined,
			loading: true,
		});

		dispatch(clientActions.uploadAutomateFile(file))
			.then((result = {}) => {
                setFormControls({
					error: undefined,
					loading: false,
				});
				history.push('/client-profile-preview', {
					...result,
					clientTeam: client?.team_client_permissions[0]?.team,
					clientId: client?.id,
				});
			})
			.catch((error) => {
				setFormControls({
					error: error?.message || 'Something went wrong',
					loading: false,
				});
			});
	}

	return (
		<form onSubmit={submitHandler} tabIndex={-1} ref={ref} className='automate-input-modal'>
			<div className='container'>
				<div>
					<div className='d-flex align-items-center' style={{ gap: '8px' }}>
						<div className='ai-input-circle'></div>

						<span className='ai-input-text HeadlineThreeBook pt-1'>Automate Input</span>
					</div>
				</div>

				<div className=''>
					<span className='BodyTwoLight'>
						{`
					Please upload the document containing your client information. Our AI system
					will analyze the document and streamline the process for you. Simply select the
					file from your device and click 'Upload' to get started.`}
					</span>
				</div>

				<div className='d-flex align-items-end w-100' style={{ gap: '16px' }}>
					<div className='improved-input-designs pt-1 w-100'>
						<label>Upload File</label>
						<input
							type='file'
							id='fileInput'
							ref={fileInputRef}
							style={{ display: 'none' }}
							onChange={handleFileChange}
						/>
						<input type='text' value={file?.name || ''} readOnly />
					</div>
					<FormButtonOne type='button' onClick={handleButtonClick}>
						Browse
					</FormButtonOne>
				</div>
				{formControls?.error && (
					<span className='BodyTwoLight' style={{ color: 'red'}}>{formControls?.error}</span>
				)}
			</div>
			<div className='action-buttons'>
				<FormButtonOne onClick={onClose} type='button' className='secondary-btn-light'>
					Cancel
				</FormButtonOne>
				<FormButtonOne loading={formControls.loading} type='submit'>
					Upload
				</FormButtonOne>
			</div>
		</form>
	);
});

export default AutomateInputModal;
