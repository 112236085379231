import { ReactComponent as WarningIcon } from '../../assets/icons/WarningBlack.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/Close.svg';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { convertUnixDate, dateDifference } from '../../utilities/utilities';
import moment from 'moment';

function AlertBar() {
	const [showAlertBar, setShowAlertBar] = useState(false);
	const [message, setMessage] = useState('');
	const [showCloseIcon, setShowCloseIcon] = useState(false);

	const subscriptionsData = useSelector((state) => state.authReducer.subscriptions);

	useEffect(() => {
		if (subscriptionsData?.status != 'active') {
			const expirationDate = convertUnixDate(subscriptionsData?.expires_at);
			const subscriptionEndDate = moment(expirationDate)
				.add(14, 'days')
				.format('MMMM D, YYYY');
			const currentDate = moment().format('MMMM D, YYYY');
			const daysUntilExpiration = dateDifference(expirationDate, currentDate);

			if (subscriptionsData?.status == 'canceled' || subscriptionsData?.status == 'unpaid') {
				setShowAlertBar(true);
				setMessage(`${subscriptionsData?.status == 'canceled' ? 'Your access has been restricted as you have canceled your subscription. To restore access, please renew your subscription.' : 'Your access has been restricted as your subscription is unpaid. To restore access, please renew your subscription.'}
					`,
				);
				setShowCloseIcon(true);
			} else {
				if (
					daysUntilExpiration <= 5 &&
					moment(currentDate).isSameOrBefore(subscriptionEndDate)
				) {
					if (sessionStorage.getItem('showAlertBar') !== 'false') {
						setShowAlertBar(true);
						setMessage(
							`${
								subscriptionsData?.status == 'trialing'
									? 'Your trial period is about to end.'
									: ''
							} Your subscription is due for renewal on ${expirationDate}. To ensure uninterrupted access, please update your payment details by ${subscriptionEndDate}.`,
						);
						setShowCloseIcon(true);
					}
				} else if (moment(currentDate).isAfter(subscriptionEndDate)) {
					if (sessionStorage.getItem('showAlertBar') !== 'false') {
						setShowAlertBar(true);
						setMessage(
							'Your access has been restricted as the payment due date has passed. To restore access, please update your payment details.',
						);
						setShowCloseIcon(false);
					}
				}
			}
		}
	}, [subscriptionsData]);

	const handleCloseAlert = () => {
		setShowAlertBar(false);
		sessionStorage.setItem('showAlertBar', 'false');
	};

	return (
		<>
			{showAlertBar && (
				<div className='alert-bar-container'>
					<div className='d-flex align-items-center'>
						<WarningIcon width='32px' height='32px' />
						<span className='BodyOneLight px-4'>{message}</span>
					</div>
					{showCloseIcon && (
						<div onClick={handleCloseAlert} className='pointer'>
							<CloseIcon />
						</div>
					)}
				</div>
			)}
		</>
	);
}

export default AlertBar;
