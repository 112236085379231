/* eslint-disable no-mixed-spaces-and-tabs */
import { makeStyles } from '@material-ui/core';
import { FormControl, MenuItem, Select } from '@mui/material';
import { useState } from 'react';
// import { graphTypes } from '../../utilities/cubeUtils';
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const useStyles = makeStyles(() => ({
	select: {
		'& .MuiSelect-menu': {
			backgroundColor: 'white !important',
		},
	},
	menuItem: {
		display: 'block',
		backgroundColor: 'white !important',
		fontFamily: 'CentraLight !important',
		fontWeight: 'light',
		fontSize: '14px !important',
		lineHeight: '18px',
		'&:hover': {
			backgroundColor: '#fd7a03',
			color: 'white !important',
		},
	},
	selectedMenuItem: {
		backgroundColor: '#fd7a03 !important',
		color: 'white !important',
	},
}));

export default function DropDown({
	label,
	name,
	style,
	value,
	onChange,
	options,
	required,
	className,
	selectDisabled,
	autoComplete,
	disableNull,
	normalDropDown,
	RenderValue,
	disableIcon,
	rootClassName,
	edittable,
	defaultValue,
	placeholder,
	multipleDropdown,
	selectClassName,
}) {
	const [hasFocus, setFocus] = useState(false);

	const classes = useStyles();

	if (normalDropDown) {
		return (
			<div className='d-flex flex-column w-100'>
				<FormControl variant='standard'>
					<Select
						className='NewSearchDropdown'
						sx={
							style
								? { ...style }
								: {
										borderRadius: '4px',
										background: '#EEF2F3',
										color: 'black',
										height: '22px',
										paddingTop: '5px',
								  }
						}
						disableUnderline
						disabled={selectDisabled}
                        defaultValue={defaultValue}
						required={required}
						name={name}
						displayEmpty
						value={value}
						onChange={(e) => {
							onChange(e, name);
						}}
						MenuProps={{
							onClick: (e) => {
								e.preventDefault();
							},
							PaperProps: {
								style: {
									maxHeight: 145,
								},
							},
						}}
						IconComponent={
							!disableIcon
								? undefined
								: () => {
										return <></>;
								  }
						}
						renderValue={(selected) => {
							if (RenderValue) {
								return (
									<RenderValue selected={selected} />
									// </>
								);
							} else {
								return (
									<span className='BodyTwoLight pl-1'>
										{options.find((o) => o.value === selected)?.label}
									</span>
								);
							}
						}}
					>
						{!disableNull && (
							<MenuItem
								style={{
									display: 'block',
									margin: '4px',
									height: '18px',
								}}
								// className={`${classes.menuItem} ${classes.selectedMenuItem}`}
								value='null'
							>
								{placeholder ? placeholder : ''}
							</MenuItem>
						)}
						{options?.map((opt, i) => {
							return (
								<MenuItem
									key={i}
									value={opt.value}
									selected={opt.value === value?.value}
									name={name}
									className={`pt-1 select-menu-item ${classes.menuItem} ${
										opt.value == value && classes.selectedMenuItem
									}`}
									style={{
										display: 'block',
										padding: '1px',
										margin: '4px',
									}}
								>
									<span>{opt.label}</span>
								</MenuItem>
							);
						})}
					</Select>
				</FormControl>
			</div>
		);
	}
	if (multipleDropdown) {
		return (
			<div>
				<FormControl variant='standard'>
					<Select
						sx={{
							background: '#FFFFFF',
							color: 'black',
							border: 'none',
							fontFamily: 'CentraLight',
							fontWeight: 'light',
							fontSize: '14px !important',
							lineHeight: '18px',
							outline: 'none',
						}}
						multiple
						value={value}
						onChange={onChange}
						displayEmpty
						disableUnderline
						name='activeFilter'
						IconComponent={
							!disableIcon
								? undefined
								: () => {
										return <></>;
								  }
						}
						renderValue={() => {
							if (RenderValue) {
								return <RenderValue />;
							}
						}}
						MenuProps={{
							classes: { paper: classes.select },
						}}
					>
						{options?.map((option, i) => (
							<MenuItem key={i} value={option} className={classes.menuItem}>
								{option.label}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</div>
		);
	}
	if (edittable) {
		return (
			<div className='VerticalSpacer d-flex flex-column w-100'>
				<div className='d-flex'>
					<label className='BodyTwoLight'>{label}</label>
				</div>
				<select
					className='PickList-Edittable BodyTwoLight d-flex flex-column w-100 m-0'
					name={name}
					onChange={onChange}
					required={required}
					disabled={selectDisabled}
					autoComplete={autoComplete}
					placeholder={'Group By'}
					value={defaultValue}
					onFocus={() => setFocus(true)}
					onBlur={() => setFocus(false)}
					style={{
						...style, borderBottom: hasFocus ? '1px solid #FB7A03' : '1px solid white',
					}}
				>
					{!disableNull && <option value='null'></option>}

					{/* <option value={value ? value.value : 0}>{value ? value.label : ''}</option> */}
					{options?.map((opt, i) => {
						return (
							<option
								key={i}
								value={opt.value}
								selected={opt.value === value?.value}
								disabled={opt.is_active === false ? true : false}
								className='BodyTwoLight'
							>
								{opt.label}
							</option>
						);
					})}
				</select>
			</div>
		);
	}
	return (
		<div
			className={`${
				rootClassName ? rootClassName : 'VerticalSpacer'
			} d-flex flex-column w-100`}
		>
			<label className={`BodyTwoLight text-left ${className}`}>{label}</label>
			<select
				className={`PickList BodyTwoLight d-flex flex-column w-100 m-0 ${selectClassName} ${
					value?.value ? '' : 'not-selected'
				}`}
				name={name}
				onChange={onChange}
				required={required}
				disabled={selectDisabled}
				autoComplete={autoComplete}
				placeholder={'Group By'}
				style={{
                    ...style, borderBottom: hasFocus ? '1px solid #FB7A03' : '1px solid white',
                }}
				value={value?.value !== null ? value?.value : ''}
			>
				{!disableNull && (
					<option value='null'>{placeholder ? placeholder : ''}</option>
				)}

				{/* <option value={value ? value.value : 0}>{value ? value.label : ''}</option> */}
				{options?.map((opt, i) => {
					return (
						<option
							className={
								'valid-options' + (opt.is_active === false ? ' disabled' : '')
							}
							key={i}
							value={opt.value}
							selected={opt?.label === 'Never' ? true : false}
							disabled={opt.is_active === false ? true : false}
						>
							{opt.label}
						</option>
					);
				})}
			</select>
		</div>
	);
}
