export default function WorkflowBrowserTabsBar({ ChangeActiveTab, activeTab }) {
	return (
		<>
			<div className='workflow-browser-bar'>
				<div className='workflow-browser-container'>
					<a
						onClick={ChangeActiveTab}
						id='1'
						className={
							activeTab == '1'
								? 'BodyOneBold font-weight-bold active'
								: 'BodyOneBold font-weight-bold '
						}
					>
						Workflows
					</a>
				</div>
			</div>
		</>
	);
}
