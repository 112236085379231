import React, { memo } from 'react';

// Material Ui
import { Avatar } from '@mui/material';
import { useHistory , useLocation} from 'react-router-dom';
import { getAvatarLabel } from '../../utilities/utilities';
import {ReactComponent as CalendarIcon} from '../../assets/icons/Calendarcolor.svg';
import moment from 'moment';

const ProjectMultiPickList = (props) => {
	const { className, label, options, children, avatar, onChange, navigationPath, user } = props;
	const history=useHistory();
	const location = useLocation();

	const locationStr = location.pathname.slice(1);
	const locationName = locationStr.charAt(0).toUpperCase() + locationStr.slice(1);

	return (
		<div className='TeamMultiPickList'>
			<div className={`TeamMultiPickList ${className}`} label={label}>
				{options.map((choice, index) => {
					{
						/* const checked = selected.includes(choice.value); */
					}

					return (
						<div
							key={index}
							className='ItemWrapper pb-1 pt-1  the-item-wrapper align-items-center justify-content-center'
						>
							<div className='w-100 d-flex align-items-center '>
								{avatar && (
									<Avatar
										sx={{
											bgcolor: `#FEF2E7`,
											color: `#333`,
											marginRight: '10px',
											height: '23px',
											width: '23px',
											fontSize: '0.8rem',
										}}
										alt={choice.label}
										src={choice.image ? choice.image : ''}
									>
										{getAvatarLabel(choice.label)}
									</Avatar>
								)}
								<span data-toggle="tooltip" style={{textOverflow:'ellipsis', width:'110px', whiteSpace:'nowrap', overflow:'hidden'}} data-placement="top" title={choice.label} className='BodyTwoLight text-white pointer text-left' onClick={()=>{
									if(navigationPath){
										localStorage.setItem('tempArr', JSON.stringify([{url: location.pathname, name: locationName}]));
										history.push(`/${navigationPath}/${choice.value}`);
									}
								}}>{choice.label}</span>
								{user && (choice.leave_requests.length > 0) && (choice.leave_requests.filter(request => request.status === 'APPROVED' && (request.leaves.find(leave => moment(leave.leave_date).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')))).length > 0) &&
								<div>
									<CalendarIcon/>
								</div>
								}
								{
									user && (choice.user_statuses.length > 0) && (choice.user_statuses.find(a => a.is_away === true)) &&
									<div>
										<CalendarIcon/>
									</div>
								}
								{/* {choice.details && <BodyThree>{choice.details}</BodyThree>} */}
								{/* {<h5 className="BodyThree">Name</h5>} */}
							</div>
							<input
								type='checkbox'
								className='multi-select-checkbox'
								value={choice.value}
								checked={choice.is_checked}
								onChange={onChange}
							/>
						</div>
					);
				})}
				{children}
			</div>
		</div>
	);
};

export default memo(ProjectMultiPickList);
