import React from 'react';
import { ReactComponent as ZapierMainIcon } from '../assets/icons/Integrations/Group.svg';
import ZapierAccessLogin from '../components/ZapierAccess/ZapierAccessLogin';
import ZapierButton from '../components/ZapierAccess/ZapierButton';

function ZapierAccess() {
	return (
		<div>
			<div className='access-section d-flex flex-column align-items-center justify-content-center '>
				<div className='access-box d-flex flex-column align-items-center justify-content-center'>
					<ZapierMainIcon className='zapier-main-icon' />
					<span className='BodyTwoLight'>
						Zapier wants to access your Levvy account
					</span>
					<div>
						<ZapierAccessLogin/>
					</div>
				</div>
				<div className='zapierButtons'>
					<ZapierButton buttonType='primary' />
					<ZapierButton buttonType='secondary' />
				</div>
			</div>
		</div>
	);
}

export default ZapierAccess;
