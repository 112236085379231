/* eslint-disable no-mixed-spaces-and-tabs */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { ERROR_CODE, Member, detailsModal, taskDragAlertContent } from '../../utilities/utilities';
import { useDispatch, useSelector } from 'react-redux';
import * as WorkSpaceActions from '../../redux/actions/dashboard/workspaces-actions';
import moment from 'moment';
import ExpandableClientTasksView from './ExpandableClientTasksView';
import MonthlyViewDateCards from './MonthlyViewDateCards';
import TaskAlert from '../Alerts/TaskAlert';
import WorkspaceDateNavigation from './WorkspaceDateNavigation';

function MonthlyCalendar({ viewSideBar, SetVewSideBar }) {
	const user = useSelector((state) => state.authReducer.user);
	const weekData = useSelector((state) => state.workSpaceReducer.weekDate);
	const weekdays = weekData ? weekData?.daysOfTheWeek : [];
	const weekends = useSelector((state) => state.workSpaceReducer.weekDaysOff);
	const monthlyDate = useSelector((state) => state.workSpaceReducer.monthlyDate);
	const [selectedDate, setSelectedDate] = useState();
	const filters = useSelector((state) => state.workSpaceReducer.filters);
	const dispatch = useDispatch();
	const [dragOverContainer, setDragOverContainer] = useState(null);
	const [currentTaskDetails, setCurrentTaskDetails] = useState();
	const weeklyClientTasks = useSelector((state) => state.workSpaceReducer.weeklyClientTasks);
	const [taskDetailsForError, setTaskDetailsForError] = useState(null);
	const [dateToUpdate, setDateToUpdate] = useState(null);
	const [isTaskAlert, setIsTaskAlert] = useState(false);
	const taskDragError = useSelector((state) => state.workSpaceReducer.taskDragError);
	const { header, doneButtonText, cancelButtonText } =
		isTaskAlert && taskDragAlertContent(taskDragError?.error_code);
	const cardAdded = useSelector((state) => state.workSpaceReducer.cardAdded);
	const triggerGetTasks = useSelector((state) => state.workSpaceReducer.triggerGetTasks);
	const outSideContainerRef = useRef();

	let tasks = useSelector((state) => state.workSpaceReducer.tasks);
	if (user?.user_roles?.map((item) => item.role_id)?.includes(Member.externalCollaborator)) {
		tasks = tasks?.filter((task) => task.assignee_user_id === user.userId);
	}

	const handleSideBar = (currentDay) => {
		setSelectedDate(currentDay);
		SetVewSideBar(true);
	};

	const getTaskDetails = useCallback(
		(currentTask) => () => {
			setCurrentTaskDetails(currentTask);
		},
		[],
	);

	const toggleTaskAlert = () => {
		setIsTaskAlert(!isTaskAlert);
	};

	const handleDragLeave = useCallback((e) => {
		e.preventDefault();
		outSideContainerRef.current = true;
	}, []);

	const handleDragOver = useCallback(
		(e, date) => {
			e.preventDefault();
			if (date == dragOverContainer) {
				outSideContainerRef.current = false;
				return;
			} else {
				setDragOverContainer(date);
				outSideContainerRef.current = false;
			}
		},
		[dragOverContainer],
	);

	const handleDrop = useCallback(
		(task, index) => () => {
			const dragOverDate = moment(dragOverContainer).format('YYYY-MM-DD');
			setTaskDetailsForError(task);
			setDateToUpdate(dragOverDate);
			setCurrentTaskDetails(null);
			if (dragOverDate == null || outSideContainerRef.current == true) {
				setDragOverContainer(null);
				return;
			} else {
				task.task_type_id === 1
					? (task = {
							...task,
							extended_date: dragOverDate,
					  })
					: (task = {
							...task,
							extended_date: dragOverDate,
					  });

				dispatch(
					WorkSpaceActions.updateMonthlyTask(task, true, filters, {
						startDate: moment(monthlyDate?.startOfWeek)?.format('YYYY-MM-DD'),
						endDate: moment(monthlyDate?.endOfWeek)?.format('YYYY-MM-DD'),
					}),
				);
				setDragOverContainer(null);
				SetVewSideBar(true);
			}
		},
		[dragOverContainer],
	);

	const handleDoneTaskAlert = (error_code) => {
		toggleTaskAlert();
		dispatch(WorkSpaceActions.resetTaskError());
		if (
			error_code == ERROR_CODE.START_DATE_BEFORE_PRECEEDING_TASK_SAME_ASSIGNEE ||
			error_code == ERROR_CODE.START_DATE_BEFORE_PRECEEDING_TASK_DIFFERENT_ASSIGNEE
		) {
			dispatch(
				WorkSpaceActions.handleTaskClick({
					taskId: taskDetailsForError?.dependent_task_id,
					modalType: detailsModal.CLIENT,
				}),
			);
		}
		if (
			error_code == ERROR_CODE.MOVED_BY_MANAGER_BEFORE_START_DATE ||
			error_code == ERROR_CODE.MOVED_BY_MANAGER_AFTER_DUE_DATE
		) {
			if (taskDetailsForError?.my_task?.task_type == 'ADHOC') {
				updateAdhocTaskAlertOnAlert(error_code);
			} else {
				dispatch(
					WorkSpaceActions.updateTaskDetails(taskDetailsForError?.id, {
						start_date:
							error_code == ERROR_CODE.MOVED_BY_MANAGER_BEFORE_START_DATE
								? dateToUpdate
								: taskDetailsForError?.start_date,
						due_date:
							error_code == ERROR_CODE.MOVED_BY_MANAGER_AFTER_DUE_DATE
								? dateToUpdate
								: taskDetailsForError?.due_date,
						extended_date: dateToUpdate,
					}),
				)
					.then(() => {})
					.catch((error) => {
						dispatch(WorkSpaceActions.setSnackBarIsOpen(false, true, error));
					});
			}
		}
	};

	const updateAdhocTaskAlertOnAlert = (error_code) => {
		const formDetails = {
			id: taskDetailsForError?.id,
			type: taskDetailsForError?.my_task?.type,
			// description: taskDetailsForError?.my_task?.description,
			// notes: taskDetailsForError?.my_task?.notes,
			title: taskDetailsForError?.title,
			client_id:
				taskDetailsForError?.client_id == null
					? null
					: Number(taskDetailsForError?.client_id),
			budgeted_hours: Number(taskDetailsForError?.budgeted_hours),
			assignee_list:
				taskDetailsForError?.task_assignees?.length > 0
					? [taskDetailsForError?.task_assignees[0]?.user_profile?.id]
					: [],
			assignee_user_id: Number(taskDetailsForError?.assignee_user_id),
			approval_from: taskDetailsForError?.approval_from
				? Number(taskDetailsForError?.approval_from)
				: null,
			is_approval_required: taskDetailsForError?.is_approval_required,
			start_date:
				error_code == ERROR_CODE.MOVED_BY_MANAGER_BEFORE_START_DATE
					? dateToUpdate
					: taskDetailsForError?.start_date,
			extended_date: dateToUpdate,
			is_billable: taskDetailsForError?.my_task?.is_billable,
			task_type: taskDetailsForError?.my_task?.task_type,
			end_on: taskDetailsForError?.my_task?.end_on,
			required_days:
				error_code == ERROR_CODE.MOVED_BY_MANAGER_AFTER_DUE_DATE
					? moment(dateToUpdate).diff(moment(taskDetailsForError?.start_date), 'days') + 1
					: moment(taskDetailsForError?.due_date).diff(moment(dateToUpdate), 'days') + 1,
			repeat_type: taskDetailsForError?.my_task?.repeat_type,
			repeat_interval:
				taskDetailsForError?.my_task?.repeat_interval == null
					? null
					: Number(taskDetailsForError?.my_task?.repeat_interval),
			recur_preference: taskDetailsForError?.my_task?.recur_preference,
			is_monthly_on_day: taskDetailsForError?.my_task?.is_monthly_on_day,
		};
		dispatch(WorkSpaceActions.updateMeetingTaskDetails(formDetails, false))
			.then(() => {})
			.catch((error) => {
				dispatch(WorkSpaceActions.setSnackBarIsOpen(false, true, error.message));
			});
	};

	useMemo(() => {
		if (selectedDate)
			dispatch(
				WorkSpaceActions.getWeeklyClientTasks(
					filters,
					selectedDate?.format('YYYY-MM-DD'),
					selectedDate?.format('YYYY-MM-DD'),
				),
			);
	}, [tasks, selectedDate]);

	useMemo(() => {
		if (taskDragError !== null) {
			toggleTaskAlert();
		}
	}, [taskDragError]);

	const monthlytasks =
		tasks &&
		tasks
			?.filter((task) => task?.client?.deleted !== true)
			?.filter((task) => {
				if (
					user?.user_roles?.find((item) =>
						item?.role_scopes
							?.map((role) => role.team_id)
							?.includes(task?.client?.team?.id),
					)?.role_id === Member.teamMemberId
				) {
					return task.assignee_user_id !== null;
				} else {
					return true;
				}
			})
			.filter((item) => item?.client?.dba != null)
			?.map((task) => {
				return {
					id: task?.id,
					title: task?.title,
					client:
						task?.client?.dba?.trim() == ''
							? task?.client?.company_name
							: task?.client?.dba,
					status: task?.status,
					start:
						task.extended_date === null
							? task?.start_date?.replace(/T.*$/, '')
							: task?.extended_date?.replace(/T.*$/, ''),
					is_tentative: task?.is_tentative,
				};
			});

	useEffect(() => {
		if (monthlyDate) {
			// history.push(
			// 	`/workspace/${moment(monthlyDate?.monthStartDate)
			// 		.startOf('month')
			// 		.format('YYYY-MM')}/${
			// 		active_task_id.getLocalStorage()
			// 			? `?task_id=${active_task_id.getLocalStorage()}`
			// 			: ''
			// 	}`,
			// );
			const start_date = monthlyDate?.startOfWeek.format('YYYY-MM-DD');
			const end_date = monthlyDate?.endOfWeek.format('YYYY-MM-DD');
			// localStorage.setItem('WORKSPACE_MONTH_DATE', JSON.stringify(monthlyDate));
			if (start_date && end_date) {
				dispatch(WorkSpaceActions.getTasks(filters, start_date, end_date));
			}
		}
	}, [monthlyDate, filters, cardAdded, triggerGetTasks]);

	return (
		<div className='d-flex'>
			<div
				className='w-100'
				style={{
					minWidth: '1000px',
					height: '100vh',
					padding: '18px',
					boxSizing: 'border-box',
					overflow: 'auto',
				}}
			>
				<WorkspaceDateNavigation />
				{/* {renderWeeks()} */}
				<MonthlyViewDateCards
					monthlyDate={monthlyDate}
					monthlytasks={monthlytasks}
					weekends={weekends}
					weekdays={weekdays}
					handleDragOver={handleDragOver}
					handleDragLeave={handleDragLeave}
					handleSideBar={handleSideBar}
					currentTaskDetails={currentTaskDetails}
					dragOverContainer={dragOverContainer}
					selectedDate={selectedDate}
					viewSideBar={viewSideBar}
				/>
			</div>
			{viewSideBar && (
				<div
					className='d-flex justify-content-start flex-column'
					style={{
						minWidth: '330px',
						height: '100vh',
						backgroundColor: '#FCFDFD',
						borderRadius: '4px',
						boxSizing: 'border-box',
						padding: '18px',
					}}
				>
					<div
						className='d-flex align-items-start justify-content-end'
						style={{ gap: '2em', position: 'sticky', paddingBottom: '18px' }}
					>
						<div className='w-100 d-flex flex-column'>
							<span className='HeadlineOneBold text-left'>
								{selectedDate?.format('dddd')}
							</span>
							<h3
								className='HeadlineThreeBook text-left'
								style={{ color: '#696F6C' }}
							>
								{selectedDate?.format('MMMM DD YYYY')}
							</h3>
						</div>
						<div>
							<span
								className='pointer BodyOneBold primary-text text-right'
								onClick={() => {
									SetVewSideBar(false);
									// dispatch(WorkSpaceActions.resetWorkspaceTasks());
								}}
							>
								Close
							</span>
						</div>
					</div>
					<div style={{ overflowY: 'auto' }}>
						{weeklyClientTasks
							?.map((item) => {
								return {
									...item,
									displayName:
										item?.client_dba?.trim() == ''
											? item?.client_name
											: item?.client_dba,
								};
							})
							?.sort((a, b) => {
								if (a.displayName && b.displayName) {
									return a.displayName.localeCompare(b.displayName);
								}
							})
							?.map((client, i) => {
								return (
									<div
										key={i}
										className='d-flex'
										style={{
											height: 'auto',
											width: '100%',
											borderRadius: '4px',
											// borderTopRightRadius: i === 0 ? '0px' : '4px',
											// borderTopLeftRadius: i === 0 ? '0px' : '4px',
											background: '#EEF2F3',
											marginBottom: '24px',
											paddingBottom: '12px',
										}}
									>
										<ExpandableClientTasksView
											monthlyView
											client={client}
											i={i}
											days={[selectedDate]}
											handleDragEnd={handleDrop}
											getTaskDetails={getTaskDetails}
											currentTaskDetails={currentTaskDetails}
											sideViewExpand
										/>
									</div>
								);
							})}
					</div>
					<TaskAlert
						message={taskDragError?.message}
						header={header}
						open={isTaskAlert}
						onDone={() => handleDoneTaskAlert(taskDragError?.error_code)}
						doneButton={doneButtonText}
						closeButton={cancelButtonText}
						closeHandler={() => {
							toggleTaskAlert();
							dispatch(WorkSpaceActions.resetTaskError());
						}}
					/>
					{/* {loading && (
						<div
							style={{
								height: '100%',
								minWidth: '300px',
								backgroundColor: 'rgba(0, 0, 0, 0.05)',
								position: 'fixed',
								top: 0,
								bottom: 0,
							}}
						>
							<CircularProgress
								style={{
									color: '#fa7b03',
									height: '50px',
									width: '50px',
									marginTop: '47vh',
								}}
							/>
						</div>
					)} */}
				</div>
			)}
		</div>
	);
}

export default MonthlyCalendar;
