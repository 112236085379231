/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useState } from 'react';
import { ReactComponent as ArrowUp } from '../../assets/icons/up.svg';
import { ReactComponent as ArrowDown } from '../../assets/icons/down.svg';
import { useDispatch, useSelector } from 'react-redux';
import * as clientActions from '../../redux/actions/client-actions';
import { getAccountAccessOfRoles, standardPermissions } from '../../utilities/utilities';
import { getNewValidComponent } from '../../utilities/BuilderUtils';
import Restricted from '../Permissions/Restricted';
import AddIcon from '@mui/icons-material/Add';

export default function ExpandableResourcesBox({
	title,
	taskClient,
	links,
	workflowBrowser,
	account,
	edit,
	handleChnage,
	contacts,
	accounts,
	workflowAccounts,
	dynamicComponent,
	loadingValue,
	workflowSubmission,
	handleAddresource,
}) {
	const dispatch = useDispatch();

	const [selected, setSelected] = useState(false);
	const urls = useSelector((state) => state.clientAccountsReducer.allUrls);
	const userTeamRole = useSelector((state) => state.clientReducer.userTeamRole);

	const clientAccounts = useSelector((state) => state.clientAccountsReducer.clientAccounts)?.map(
		(item) => {
			return {
				...item,
				softwares: item?.softwares?.map((soft) => {
					return {
						...soft,
						institution:
							urls?.find((url) => url.id == soft.institution_url_id)?.institution ||
							soft?.name,
						login:
							urls?.find((url) => url.id == soft.institution_url_id)?.url ||
							soft?.custom_url,
					};
				}),
			};
		},
	);

	const user = useSelector((state) => state.authReducer.user);
	const client = useSelector((state) => state.clientReducer.client);

	const userRole =
		user?.user_roles?.find((item) =>
			item?.role_scopes
				?.map((role) => role.team_id)
				?.includes(client?.team_client_permissions[0]?.team_id),
		)?.role_id || user?.userRole?.id;

	// const toggleSelected = () => {
	//     setSelected(!selected);
	// };

	// const workflowLinks =
	//     taskDetails?.data?.props?.sections[0]?.columns?.filter((col) =>
	//         col.columnComponents?.find(
	//             (compo) => compo.componentType === 'links-pick-list'
	//         )
	//     )[0]?.columnComponents[0]?.value;

	const toggleSelected = () => {
		setSelected(!selected);
	};

	const workflowLinks = workflowSubmission?.props?.sections[0]?.columns?.filter((col) =>
		col.columnComponents?.find((compo) => compo.componentType === 'links-pick-list'),
	)[0]?.columnComponents[0]?.value;

	const linksList = workflowSubmission?.props?.sections.find(
		(section) => section.sectionName === 'LINKSSECTION',
	)?.tableRows;

	const getCredentialsFromVault = (software) => {
		dispatch(
			clientActions.getSoftwareDetails({
				...software,
				clientName:
					taskClient?.dba?.trim() == '' ? taskClient?.company_name : taskClient?.dba,
			}),
		);
	};

	const allClientAcounts = clientAccounts
		?.find((acc) => acc?.type === account?.type)
		?.softwares?.filter((software) =>
			getAccountAccessOfRoles(software?.role_id)?.includes(userRole),
		);

	const greyed_condition =
		(accounts && accounts?.filter((soft) => workflowAccounts?.includes(soft.id))?.length > 0) ||
		(links &&
			(linksList?.length > 0 ||
				taskClient?.links?.filter((link) => workflowLinks?.includes(link.id))?.length > 0));

	if (workflowBrowser) {
		return (
			<div className='expandable-resources-box'>
				<div
					className='d-flex align-items-center justify-content-between w-100'
					onClick={toggleSelected}
				>
					<div className='d-flex text-left'>
						<span className='BodyTwoLight'>{title}</span>
					</div>
					<div>{selected ? <ArrowUp /> : <ArrowDown />}</div>
				</div>
				{selected && (
					<div
						className='d-flex flex-column align-items-start p-2'
						style={{ gap: '10px' }}
					>
						{links
							? React.createElement(
									getNewValidComponent(dynamicComponent?.componentType),
									{
										onChange: handleChnage(dynamicComponent),
										edit: edit,
										...dynamicComponent,
										options: client?.links?.map((link) => {
											return {
												label: link.name,
												value: link.id,
												loading: loadingValue == link.id ? true : false,
											};
										}),
										value: dynamicComponent?.value || [],
										disabled: loadingValue ? true : false,
									},
							  )
							: // <>
							// 	{client?.links
							// 		?.filter((link) => {
							// 			if (edit) {
							// 				return true;
							// 			} else {
							// 				return linkStacks?.includes(link?.id);
							// 			}
							// 		})
							// 		?.map((link, i) => {
							// 			return (
							// 				<div
							// 					key={i}
							// 					className='d-flex flex-row align-items-center pl-2'
							// 					style={{ gap: '12px' }}
							// 				>
							// 					{edit && (
							// 						<input
							// 							type='checkbox'
							// 							className='multi-select-checkbox'
							// 							onChange={() => handleLinksChnage(link?.id)}
							// 							value={link?.id}
							// 							checked={linkStacks?.includes(link?.id)}
							// 						/>
							// 					)}
							// 					<span
							// 						key={i}
							// 						className='BodyTwoLight m-0 primary-text extension-call text-left pointer'
							// 					>
							// 						{link.name}
							// 					</span>
							// 				</div>
							// 			);
							// 		})}
							// </>
							contacts
							? React.createElement(
									getNewValidComponent(dynamicComponent?.componentType),
									{
										onChange: handleChnage(dynamicComponent),
										edit: edit,
										...dynamicComponent,
										options: client?.client_contact_list?.map((contact) => {
											return {
												label: contact.first_name + ' ' + contact.last_name,
												value: contact.id,
												loading: loadingValue == contact.id ? true : false,
											};
										}),
										value: dynamicComponent?.value || [],
										disabled: loadingValue ? true : false,
									},
							  )
							: // <>
							  // 	{client?.client_contact_list
							  // 		?.filter((contact) => {
							  // 			if (edit) {
							  // 				return true;
							  // 			} else {
							  // 				return contactStacks?.includes(contact?.id);
							  // 			}
							  // 		})
							  // 		?.map((contact, i) => {
							  // 			return (
							  // 				<div
							  // 					key={i}
							  // 					className='d-flex flex-row align-items-center pl-2'
							  // 					style={{ gap: '12px' }}
							  // 				>
							  // 					{edit && (
							  // 						<input
							  // 							type='checkbox'
							  // 							className='multi-select-checkbox'
							  // 							onChange={() => handleContactsChnage(contact?.id)}
							  // 							value={contact?.id}
							  // 							checked={contactStacks?.includes(contact?.id)}
							  // 						/>
							  // 					)}
							  // 					<span
							  // 						key={i}
							  // 						className='BodyTwoLight m-0 primary-text extension-call text-left pointer'
							  // 					>
							  // 						{contact.first_name + ' ' + contact.last_name}
							  // 					</span>
							  // 				</div>
							  // 			);
							  // 		})}
							  // </>
							  React.createElement(
									getNewValidComponent(
										dynamicComponent?.componentType || 'account-pick-list',
									),
									{
										onChange: handleChnage(dynamicComponent),
										edit: edit,
										...dynamicComponent,
										options: allClientAcounts?.map((item) => {
											return {
												label: item.institution,
												value: item.id,
												loading: loadingValue == item.id ? true : false,
											};
										}),
										disabled: loadingValue ? true : false,
										value: dynamicComponent?.value || [],
									},
							  )}
						{!contacts && (
							<Restricted
								AllowedUsers={standardPermissions}
								userTeamRole={[userTeamRole]}
							>
								<div
									className='d-flex justify-content-center d-flex align-items-center'
									style={{ width: '100%' }}
								>
									<div
										className='pointer d-flex flex-row justify-content-start align-items-start'
										style={{
											height: '22px',
											width: '6px',
											borderRadius: '3px',
											marginRight: '1rem',
											padding: 0,
											position: 'relative',
										}}
										onClick={handleAddresource(
											links ? 'NEW_LINKS_MODAL' : account?.type,
										)}
									>
										<AddIcon
											fontSize='small'
											sx={{
												color: '#fb7a03',
												fontSize: 21,
												position: 'absolute',
												margin: 0,
												padding: 0,
												top: 0,
												left: 0,
											}}
										/>
									</div>
									<h3
										className='BodyTwoLight mt-1 mb-0 dark-text'
										style={{ color: '#FB7A03', cursor: 'pointer' }}
										onClick={handleAddresource(
											links ? 'NEW_LINKS_MODAL' : account?.type,
										)}
									>
										Add New {title}
									</h3>
								</div>
							</Restricted>
						)}
					</div>
				)}
			</div>
		);
	} else {
		return (
			<div
				className={`expandable-resources-box ${
					greyed_condition ? '' : 'expandable-resources-box_greyed'
				}`}
			>
				<div
					onClick={greyed_condition ? toggleSelected : undefined}
					className='d-flex align-items-center justify-content-between w-100'
				>
					<div className='d-flex text-left'>
						<span className='BodyTwoLight'>{title}</span>
					</div>
					<div>{selected ? <ArrowUp /> : <ArrowDown />}</div>
				</div>
				{selected && (
					<div className='d-flex flex-column align-items-start'>
						{links ? (
							<>
								{taskClient?.links?.map((link, i) => {
									if (workflowLinks?.find((item) => item === link.id)) {
										return (
											<div key={i}>
												<a
													className='BodyTwoLight primary-text'
													target='_blank'
													href={link.login}
													rel='noreferrer'
												>
													{link.name}
												</a>
											</div>
										);
									}
								})}
								{/* {linksList?.map((list, i) => {
									return (
										<div key={i}>
											<a
												key={i}
												className='BodyTwoLight primary-text extension-call text-left pointer'
												href={list?.values[1]}
												target='_blank'
												rel='noreferrer'
											>
												{list?.values[0]}
											</a>
										</div>
									);
								})} */}
							</>
						) : (
							accounts
								?.filter((soft) => workflowAccounts?.includes(soft.id))
								?.map((soft, index) => {
									return (
										<div key={index}>
											<a
												className='BodyTwoLight primary-text'
												target='_blank'
												rel='nonreferrer'
												onClick={() => getCredentialsFromVault(soft)}
											>
												{soft.institution}
											</a>
										</div>
									);
								})
						)}
					</div>
				)}
			</div>
		);
	}
}
