import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

function PasswordInputIcon({ showPasswordState, togglePasswordShowState }) {
	return (
		<span className='input-password-icon'>
			{showPasswordState ? (
				<VisibilityOffIcon fontSize='small' onClick={togglePasswordShowState} />
			) : (
				<VisibilityIcon fontSize='small' onClick={togglePasswordShowState} />
			)}
		</span>
	);
}

export default PasswordInputIcon;
