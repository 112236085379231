/* eslint-disable no-mixed-spaces-and-tabs */
import { graphTypes, graphVariations } from '../../utilities/cubeUtils';
import * as Actions from '../actions/cube-actions';
import _ from 'lodash';

const commonState = {
	loading: {
		graph: true,
		tooltip: true,
		filters: {
			clients: true,
			teams: true,
			employees: true,
			workflows: true,
		},
	},
	// graphType: graphTypes.groupByClients,
	keys: [],
	indexBy: 'x',
	// if group by: none, then groupMode is stacked, else grouped
	groupMode: 'grouped',
	max: 'auto',
	min: 0,
	graphData: [],
	tooltipData: [],
	options: {
		activeFilter: [
			{
				value: 'clientid',
				label: 'Clients',
			},
			{
				value: 'teamID',
				label: 'Teams',
			},
			{
				value: 'userID',
				label: 'Employees',
			},
			{
				value: 'workflowID',
				label: 'Workflows',
			},
		],
		filtersData: {
			clients: [],
			teams: [],
			employees: [],
			workflows: [],
		},
		// granularity:[
		// 	{
		// 		value:'day',
		// 		label:'Daily'
		// 	},
		// 	{
		// 		value:'month',
		// 		label:'Monthly'
		// 	},
		// 	{
		// 		value:'year',
		// 		label:'Yearly'
		// 	}
		// ],
		groupByOptions: [
			// {
			// 	value:graphTypes.defaultGraph,
			// 	label:'No grouping (default)'
			// },
			{
				value: graphTypes.groupByNone,
				label: 'None',
			},
			{
				value: graphTypes.groupByClients,
				label: 'Clients',
			},
			{
				value: graphTypes.groupByTeams,
				label: 'Teams',
			},
			{
				value: graphTypes.groupByEmployees,
				label: 'Employees',
			},
			// {
			// 	value:graphTypes.groupByWorkflows,
			// 	label:'Workflows'
			// }
		],
	},
	filters: {
		groupBy: graphTypes.groupByNone,
		appliedFilters: {
			clientid: [],
			teamID: [],
			userID: [],
			workflowID: [],
		},
		activeFilter: [],
		recentFilter: [],
		granularity: 'month',
		dateRange: {
			from: new Date(
				Math.floor(new Date().setFullYear(new Date().getFullYear(), new Date().getMonth(), 1)),
			),
			to: new Date(
				Math.floor(new Date().setFullYear(new Date().getFullYear(), new Date().getMonth(), new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate())),
			)
		},
	},
};
const initialState = {
	[graphVariations.WORKFLOW_VS_PERCENTAGE]: _.cloneDeep({...commonState, filters: {...commonState.filters, groupBy: graphTypes.groupByClients}}),
	[graphVariations.WORKFLOW_VS_TIME_TRACKING_GRAPH]: _.cloneDeep({...commonState, filters: {...commonState.filters, groupBy: graphTypes.groupByTeams}}),
	[graphVariations.WORKFLOW_CUMULATIVE_GRAPH]: _.cloneDeep({...commonState, filters: {...commonState.filters, groupBy: graphTypes.groupByClients}}),
	[graphVariations.PROFITABILITY_BY_PERCENTAGE_GRAPH]: _.cloneDeep({...commonState, filters: {...commonState.filters, groupBy: graphTypes.groupByClients}}),
};

const cubeReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case Actions.SET_LOADING:
			return (state = {
				...state,
				[payload.graphVariation]: {
					...state[payload.graphVariation],
					loading: {
						...state[payload.graphVariation].loading,
						[payload.type]: payload.value,
					},
				},
			});
		case Actions.GET_GRAPH_DATA:
			return (state = {
				...state,
				[payload.graphVariation]: {
					...state[payload.graphVariation],
					graphData: payload?.data || [],
					keys: payload?.keys || [],
					min: payload?.min,
					loading: {
						...state[payload.graphVariation].loading,
						graph: false,
					},
				},
			});
		case Actions.GET_TOOLTIP_DATA:
			return (state = {
				...state,
				[payload.graphVariation]: {
					...state[payload.graphVariation],
					tooltipData: payload.tooltipData,
					loading: {
						...state[payload.graphVariation].loading,
						tooltip: false,
					},
				},
			});
		case Actions.APPLY_SINGLE_VALUE_FILTER:
			return (state = {
				...state,
				[payload?.graphVariation]: {
					...state[payload.graphVariation],
					filters: {
						...state[payload?.graphVariation]?.filters,
						[payload.name]: payload.value,
					},
				},
			});
		case Actions.CLEAR_SINGLE_VALUE_FILTER:
			return state = {
				...state,
				[payload?.graphVariation]: {
					...state[payload.graphVariation],
					filters: {
						...state[payload?.graphVariation]?.filters,
						activeFilter: state[payload?.graphVariation]?.filters?.activeFilter?.filter(item => {
							if(item.value !== payload?.filter){
								return item;
							}
						}),
						appliedFilters: {
							...state[payload?.graphVariation]?.filters?.appliedFilters,
							[payload.filter]: []
						}
					
					} 
					
				}
			};
		case Actions.CLEAR_SINGLE_VALUE_WORKFLOW_FILTER:
			return state = {
				...state,
				[payload?.graphVariation]: {
					...state[payload.graphVariation],
					filters: {
						...state[payload?.graphVariation]?.filters,
						appliedFilters: {
							...state[payload?.graphVariation]?.filters?.appliedFilters,
							[payload.name]: state[payload?.graphVariation]?.filters?.appliedFilters?.workflowID?.filter(item => {
								if(parseInt(item) !== payload?.filter?.value){
									return item;
								}
							})
						}
					
					} 
					
				}
			};
		case Actions.CLEAR_ALL_WORKFLOW_FILTER:
			return state = {
				...state,
				[payload?.graphVariation]: {
					...state[payload.graphVariation],
					filters: {
						...state[payload?.graphVariation]?.filters,
						appliedFilters: {
							...state[payload?.graphVariation]?.filters?.appliedFilters,
							[payload.name]: []
						}
					} 
				}
			};
		// case Actions.SET_TOOLTIP_QUERY:
		// 	return (
		// 		state={
		// 			...state,[payload.graphVariation]:{
		// 				...state[payload.graphVariation],tooltipQuery:payload.tooltipQuery,
		// 			}
		// 		}
		// 	);
		case Actions.APPLY_MULTI_SELECT_FILTERS:
			return (state = {
				...state,
				[payload.graphVariation]: {
					...state[payload.graphVariation],
					filters: {
						...state[payload.graphVariation].filters,
						// recentFilter:payload.value?.length>0?payload.name:null,
						recentFilter:
							state[payload.graphVariation].filters.recentFilter.indexOf(
								payload.name,
							) === -1
								? state[payload.graphVariation].filters.recentFilter.concat(
										payload.name,
								  )
								: payload.value?.length > 0
								? state[payload.graphVariation].filters.recentFilter
								: state[payload.graphVariation].filters.recentFilter?.filter(
										(item) => item !== payload.name,
								  ),
						appliedFilters: {
							...state[payload.graphVariation].filters.appliedFilters,
							[payload.name]: payload.value,
						},
					},
				},
			});
		// case Actions.SET_GRAPH_QUERY:
		// 	return (state={
		// 		...state,tasksGraph:{
		// 			...state.tasksGraph,graphQuery:payload.query,graphType:payload.graphType
		// 		}
		// 	});
		case Actions.SET_DATE_RANGE:
			return (state = {
				...state,
				[payload.graphVariation]: {
					...state[payload.graphVariation],
					filters: {
						...state[payload.graphVariation]?.filters,
						dateRange: {
							...state[payload.graphVariation]?.filters.dateRange,
							[payload.name]: payload.value,
						},
					},
				},
			});
		case Actions.SET_TIME:
			return (state = {
				...state,
				[payload.graphVariation]: {
					...state[payload.graphVariation],
					filters: {
						...state[payload.graphVariation]?.filters,
						dateRange: {
							...state[payload.graphVariation]?.filters.dateRange,
							['from']: payload.from,
							['to']: payload.to
						},
					},
				},
			});
		case Actions.GET_FILTERS_DATA:
			return (state = {
				...state,
				[payload.graphVariation]: {
					...state[payload.graphVariation],
					options: {
						...state[payload.graphVariation]?.options,
						filtersData: {
							...state[payload.graphVariation]?.options.filtersData,
							[payload.for]: payload.data?.filter((item) => item.value),
						},
					},
				},
			});
		case Actions.RESET_ALL_FILTERS:
			return (state = {
				...state,
				[payload?.graphVariation]: {
					...state[payload.graphVariation],
					filters: {
						...state[payload?.graphVariation].filters,
						appliedFilters: initialState[payload?.graphVariation].filters.appliedFilters,
						activeFilter: initialState[payload?.graphVariation].filters.activeFilter
					}
				},
			});
		case Actions.RESET_DATE_RANGE:
			return (state = {
				...state,
				[payload?.graphVariation]: {
					...state[payload.graphVariation],
					filters: {
						...state[payload?.graphVariation].filters,
						dateRange: initialState[payload?.graphVariation].filters.dateRange,
					}
				},
			});
		default:
			return state;
	}
};

export default cubeReducer;
