import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SubmitButtonsField from '../../Buttons/SubmitButtonsField';
import { CircularProgress } from '@mui/material';
import * as clientActions from '../../../redux/actions/client-actions';
import * as userActions from '../../../redux/actions/user-management.actions';
import { Member, executivePermissions } from '../../../utilities/utilities';
import DropDown from '../../Inputs/DropDown';
import Restricted from '../../Permissions/Restricted';

function CreateFolder({ onCancel, item }) {

	const dispatch = useDispatch();

	const [name, setName] = useState('');
	const [actionLoading, setActionLoading] = useState(false);
	const [selectedRoleId, setSelectedRoleId] = useState(14);

	let roles = useSelector((state) => state.userReducer.roles);

	roles = roles
		?.filter((role) => role.id !== Member.clientId)
		?.map((role) => {
			return {
				value: role.id,
				label: role.role_name,
			};
		})
		?.sort((a, b) => a.value - b.value);

	const handleCreateFolder = () => {
		setActionLoading(true);
		const data = {
			name,
			parent_folder_id: item.id,
			access_control: {
				readonly: false,
				role_level: parseInt(selectedRoleId),
			},
		};
		dispatch(clientActions.createFolder(data))
			.then(() => {
				setActionLoading(false);
				onCancel();
			})
			.catch(() => {
				setActionLoading(false);
			});
	};

	const handleRoleChange = (selectedValue) => {
		setSelectedRoleId(selectedValue?.target?.value);
	};

	useEffect(() => {
		dispatch(userActions.getRoles());
	}, [dispatch]);

	return (
		<div>
			<form autoComplete='off'>
				<div
					className='p-3'
					style={{
						maxHeight: '75vh',
						height: 'fit-content',
						position: 'relative',
						overflowY: 'auto',
					}}
				>
					<div className='improved-input-designs pb-3'>
						<label className='require w-100'>Folder Name</label>
						<input
							className='w-100'
							type='text'
							name='company_name'
							value={name}
							style={{
								backgroundColor: 'white',
								border: 'none',
								borderBottom: '1px solid #28383C',
							}}
							data-cy='company_name'
							required
							onChange={(e) => setName(e.target.value)}
						/>
					</div>
					<Restricted AllowedUsers={executivePermissions}>
						<div className='improved-input-designs pb-3'>
							<label className='require'>Access</label>
							<DropDown
								onChange={handleRoleChange}
								name='type'
								disableNull
								required={true}
								options={roles}
								style={{ height: '32px' }}
								value={{ value: selectedRoleId }}
							/>
						</div>
					</Restricted>
				</div>

				<div className='VerticalSpacer bg-white p-3 rounded-bottom'>
					<SubmitButtonsField child={true}>
						<button
							id='Element'
							type='button'
							className='secondary'
							style={{ borderRadius: '50px', width: '125px' }}
							onClick={onCancel}
						>
							Cancel
						</button>

						<button
							type='submit'
							className='d-flex align-items-center'
							style={{ borderRadius: '50px', width: '125px' }}
							id='Element'
							disabled={actionLoading}
							data-cy='add-client-btn'
							onClick={handleCreateFolder}
						>
							{actionLoading ? (
								<CircularProgress size={24} sx={{ color: 'white' }} disableShrink />
							) : (
								'Create'
							)}
						</button>
					</SubmitButtonsField>
				</div>
			</form>
		</div>
	);
}

export default CreateFolder;
