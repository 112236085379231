import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SkeletonLoading from '../../Others/SkeletonLoading';
import ReactHtmlParser from 'react-html-parser';
import { ReactComponent as ArrowsOut } from '../../../assets/icons/ArrowsOutSimple.svg';
import { ReactComponent as Edit } from '../../../assets/icons/PencilLight.svg';
import TaskModalContainer from '../../sub_components/TaskModalContainer';
import { Avatar } from '@mui/material';
import CommentsSection from '../../sub_components/CommentsSection';
import moment from 'moment';
import { Mention, MentionsInput } from 'react-mentions';
import EmojiPicker from 'emoji-picker-react';
import * as Actions from '../../../redux/actions/dashboard/workspaces-actions';
import { ReactComponent as EmojiIcon } from '../../../assets/icons/emoji.svg';
import { ReactComponent as PostIcon } from '../../../assets/icons/PaperPlaneRight.svg';

const MeeingAgendaTab = ({ id, setExpandView }) => {
	const dispatch = useDispatch();
	const mentionInputRef = useRef();
	const taskDetails = useSelector((state) => state.workSpaceReducer.newTaskDetails);
	const comments = useSelector((state) => state.workSpaceReducer.taskComments);
	// const loading = useSelector((state) => state.workSpaceReducer.loading);
	const userDetails = useSelector((state) => state.authReducer.userDetails);
	const comment = useSelector((state) => state.workSpaceReducer.comment);
	const user = useSelector((state) => state.authReducer.user);
	const commentRef = useRef(null);
	const allTenantUsers = useSelector((state) =>
		state.userReducer.allTenantUsers?.map((user) => {
			return {
				id: user?.id,
				display: user?.full_name,
			};
		}),
	);

	const assigneeMap = new Map(
		taskDetails?.assignee_users?.map((user) => [
			user.user_id,
			{
				...user,
			},
		]),
	);

	const today = moment().startOf('day');
	const start_date = moment(taskDetails?.start_date).startOf('day');

	const [showEmojiPicker, setShowEmojiPicker] = useState(false);

	const isTaskEditAble =
		taskDetails?.my_task?.creator_id == user?.userId && !start_date.isBefore(today);

	const mentionStyle = {
		width: '100%',
		height: '30px',
		paddingLeft: '10px',
		backgroundColor: 'white',
		// border: '1px solid #FED0A5',
		// borderRadius: '4px',
		control: {
			backgroundColor: '#fff',
			fontSize: 12,
			fontWeight: 'normal',
		},

		highlighter: {
			overflow: 'hidden',
		},

		input: {
			margin: 0,
		},

		'&singleLine': {
			control: {
				display: 'inline-block',

				width: 130,
			},

			highlighter: {
				padding: 1,
				border: 'none',
			},

			input: {
				padding: 1,
				color: '#007bff',
				border: 'none',
			},
		},

		'&multiLine': {
			control: {
				fontFamily: 'monospace',
				height: 30,
				// borderTop: '1px solid #FED0A5',
				// borderBottom: '1px solid #FED0A5',
			},

			highlighter: {
				padding: 9,
				height: 30,
			},

			input: {
				padding: 9,
				outline: 0,
				border: 0,
				height: 30,
				left: 'unset',
				// overflow: 'auto',
				position: 'absolute',
				bottom: 14,
				lineHeight: 1,
			},
		},

		suggestions: {
			top: 'unset',
			bottom: '25px',
			zIndex: 99,
			maxHeight: '70px',
			overflow: 'auto',
			list: {
				backgroundColor: 'white',
				border: '1px solid rgba(0,0,0,0.15)',
				fontSize: 10,
			},

			item: {
				padding: '5px 15px',
				borderBottom: '1px solid rgba(0,0,0,0.15)',

				'&focused': {
					backgroundColor: '#cee4e5',
				},
			},
		},
	};

	const handleEmojiClick = (emojiObject) => {
		dispatch(Actions.handleCommentChange(comment + emojiObject.emoji));
		setShowEmojiPicker(!showEmojiPicker);
	};

	const sortedUsers = useMemo(() => {
		const assigneeUser = allTenantUsers?.find(
			(user) => user?.id === taskDetails?.assignee_user_id,
		);

		// Non Assignee users sorted
		const nonAssigneeUsers = allTenantUsers
			.filter((user) => user?.id !== taskDetails?.assignee_user_id)
			.sort((a, b) => (a.display || '').localeCompare(b.display || ''));

		return assigneeUser ? [assigneeUser, ...nonAssigneeUsers] : nonAssigneeUsers;
	}, [allTenantUsers, taskDetails]);

	const OnCommentPost = () => {
		if (comment.length > 0) {
			const regex = /@\[.+?\]\(.+?\)/gm;
			const idRegex = /\(.+?\)/g;
			const matches = comment.match(regex);
			const arr = [];
			matches &&
				matches.forEach((m) => {
					const id = m.match(idRegex)[0].replace('(', '').replace(')', '');
					arr.push(JSON.parse(id));
				});
			dispatch(Actions.addComment(id, user?.userId, comment, arr));
		}
		dispatch(Actions.resetComment());
	};

	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			OnCommentPost();
		}
	};

	const handleCommentChange = (e, newValue) => {
		mentionInputRef?.current?.clearSuggestions();
		dispatch(Actions.handleCommentChange(newValue));
	};

	const scrollToBottom = () => {
		if (commentRef.current) {
			commentRef.current.scrollTop = commentRef.current.scrollHeight;
		}
	};

	useEffect(() => {
		scrollToBottom();
	}, [comments?.length]);
    
	return (
		<div className='d-flex flex-column h-100' style={{ gap: '1rem' }}>
			{
				<SkeletonLoading
					loading={taskDetails ? false : true}
					variant='text'
					width={'100%'}
					height={'100%'}
				>
					<div className='meeting-task-agenda-container'>
						<div className='d-flex justify-content-between align-items-center'>
							<label className='HeadlineThreeBook text-left dark-text'>Agenda</label>
							<div className='d-flex' style={{ gap: '14px' }}>
								{isTaskEditAble && (
									<span
										className='pointer'
										onClick={() =>
											setExpandView({
												isExpanded: true,
												content: taskDetails?.agenda,
												title: 'Agenda',
												isEdit: true,
											})
										}
									>
										<Edit />
									</span>
								)}
								<span
									className='pointer'
									onClick={() => {
										setExpandView({
											isExpanded: true,
											content: taskDetails?.agenda,
											title: 'Agenda',
											isEdit: false,
										});
									}}
								>
									<ArrowsOut />
								</span>
							</div>
						</div>
						<div style={{ maxHeight: 'calc(100% - 28px)', overflow: 'auto' }}>
							{ReactHtmlParser(taskDetails?.agenda)}
						</div>
					</div>
				</SkeletonLoading>
			}
			<div className='meeting-comments-container position-relative'>
				<TaskModalContainer
					title='Comments'
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
					}}
					className='fixed-title'
				>
					<div
						className='comments'
						style={{ overflowY: 'auto', maxHeight: 54 }}
						ref={commentRef}
					>
						{comments &&
							comments.map((comment, key) => {
								return (
									<div key={key} className='d-flex align-items-center'>
										<div
											className='d-flex w-100 justify-content-between'
											style={{
												marginBottom: '5px',
											}}
										>
											<div className='d-flex align-items-center'>
												<div className='pr-3 pt-1'>
													<Avatar
														data-toggle='tooltip'
														data-placement='top'
														title={
															comment?.user_profile?.full_name
																.charAt(0)
																.toUpperCase() +
															comment?.user_profile?.full_name.slice(
																1,
															)
														}
														sx={{
															bgcolor: `#FEF2E7`,
															color: `#333`,
															width: '20px',
															height: '20px',
														}}
														alt='User'
														src={comment?.user_profile?.image_url}
														srcSet={null}
													/>
												</div>
												<div className='d-flex flex-column align-items-start'>
													<div
														style={{
															marginBottom: '-10px',
															wordBreak: 'break-word',
														}}
														className='text-left'
													>
														<span className='BodyTwoLight d-flex'>
															<CommentsSection
																comment={comment.message}
															/>
														</span>
													</div>
													<div
														style={{
															marginTop: '5px',
														}}
													>
														<span
															className='BodyThreeLight'
															style={{
																color: '#7C7C7C',
															}}
														>
															{moment(comment.created_at).fromNow()}
														</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								);
							})}
					</div>
					<div
						style={{
							width: '100%',
							// height: '32px',
							backgroundColor: '#FFFFFF',
							paddingBlock: '8px',
							position: 'relative',
							// top:'90px',
							// marginBottom:'-12px'
						}}
						className='d-flex align-items-center '
					>
						<div>
							<Avatar
								sx={{
									bgcolor: `#FEF2E7`,
									color: `#333`,
									width: '24px',
									height: '24px',
								}}
								alt='User'
								src={userDetails?.image_url}
								srcSet={null}
							/>
						</div>
						<div
							className='comment-field pl-2'
							style={{
								wordBreak: 'break-word',
								paddingRight: '15px',
								overflowY: 'visible',
							}}
						>
							<MentionsInput
								value={comment}
								className='BodyTwoLight d-flex align-items-center'
								onKeyDown={comment?.trim()?.length == 0 ? () => {} : handleKeyDown}
								placeholder='Leave a comment'
								style={mentionStyle}
								onChange={handleCommentChange}
								ref={mentionInputRef}
							>
								<Mention
									trigger='@'
									data={sortedUsers}
									style={{
										backgroundColor: 'rgba(251, 122, 3, 0.15)',
										marginBottom: '20px',
									}}
									renderSuggestion={(
										entry,
										search,
										highlightedDisplay,
										index,
										focused,
									) => {
										const assigneeLabel = assigneeMap.get(entry.id) ? (
											<em>(Assignee)</em>
										) : null;

										return (
											<span>
												{highlightedDisplay} {assigneeLabel}
											</span>
										);
									}}
									appendSpaceOnAdd
								/>
							</MentionsInput>
						</div>
						<span
							role='img'
							aria-label='emoji'
							className='emoji-icon pointer'
							onClick={() => setShowEmojiPicker(!showEmojiPicker)}
						>
							<EmojiIcon />
						</span>

						<div className='pointer'>
							{
								<PostIcon
									style={{
										opacity: comment?.trim()?.length > 0 ? 1 : 0.5,
									}}
									onClick={
										comment?.trim()?.length == 0 ? () => {} : OnCommentPost
									}
								/>
							}
						</div>
					</div>
				</TaskModalContainer>
				{showEmojiPicker && (
					<div className='emoji-picker'>
						<EmojiPicker
							// width='100%'
							onEmojiClick={handleEmojiClick}
							lazyLoadEmojis={true}
							searchDisabled={true}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default MeeingAgendaTab;
