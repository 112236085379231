import { Dialog, Zoom } from '@mui/material';
import React from 'react';
import FormButtonOne from '../../Buttons/FormButtonOne';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Zoom in={true} ref={ref} {...props} style={{ transitionDelay: '50ms' }} />;
});

function DeclineConfirmModal({ open, closeHandler, reason, handleTaskModal }) {
	return (
		<Dialog
			PaperProps={{ style: { width: 448, height: 290 } }}
			TransitionComponent={Transition}
			open={open}
			onClose={closeHandler}
		>
			<div className='my-task-delete-alert d-flex flex-column justify-content-between h-100'>
				<div className='content d-flex flex-column' style={{ padding: 24, gap: 24 }}>
					<div
						className='HeadlineTwoBold dark-text form-title'
						style={{ lineHeight: '1.6' }}
					>
						Invite Declined
					</div>
					<div className='d-flex flex-column w-100'>
						<div className='BodyTwoLight dark-text text-left pb-1'>
							Reason for declining the meeting
						</div>
						<div
							className='BodyTwoLight w-100 p-2'
							style={{
								backgroundColor: reason?.length > 0 ? 'white' : '',
								height: '70px',
								overflowY: 'auto',
							}}
						>
							{reason?.length > 0 ? reason : 'Guest has not provided a reason'}
						</div>
                    <div className='d-flex justify-content-end'>
                    <span className='BodyTwoLight mt-2 primary-text pointer' onClick={handleTaskModal}>View Meeting Details</span>
                    </div>

					</div>
				</div>
				<div className='action-buttons'>
					<FormButtonOne
						onClick={closeHandler}
						type='button'
						className='secondary-btn-light rounded-btn'
					>
						Close
					</FormButtonOne>
				</div>
			</div>
		</Dialog>
	);
}

export default DeclineConfirmModal;
