import { useState } from 'react';
import { ReactComponent as ArrowUp } from '../../assets/icons/up.svg';
import { ReactComponent as ArrowDown } from '../../assets/icons/down.svg';

function ExpandableView({ rootClass, labelClass, label, children, subLabel }) {
	const [expand, setExpand] = useState(false);

	const toggleExpand = () => {
		setExpand(!expand);
	};

	return (
		<>
			<div
				className={`d-flex flex-row justify-content-between align-items-center ${rootClass}`}
			>
				<span className={labelClass}>{label}</span>
				<span className='pointer' onClick={toggleExpand}>
					{expand ? <ArrowUp /> : <ArrowDown />}
				</span>
			</div>
			<span className='labelClass'>{subLabel}</span>
			{expand && children}
		</>
	);
}

export default ExpandableView;
