import '../assets/styles/App.scss';
import { Route, Switch } from 'react-router-dom';

//Components
import AppComponent from '../components/AppComponent';
import PrivateRoutes from '../authservice/Auth';
import ProtectedRoute from './ProtectedRoute';
import Signup from './signup/SignupFormComponent';
// import PaymentInfoComponent from './signup/PaymentInfoComponent';
// import SignUpActivationComponent from './signup/SignUpActivationComponent';
// import SubscriptionComponent from './signup/SubscriptionComponent';

import Login from './login/LoginFormComponent';
import ResetLinkSentComponent from './login/ResetLinkSentComponent';
import ForgetPasswordComponent from './login/ForgetPasswordComponent';
import ResetPasswordComponent from './login/ResetPasswordComponent';
import Public from './Public';
import * as searchActions from '../redux/actions/search-actions';

// Views
import UserManagement from './UserManagement';
import TeamManagement from './TeamManagement';
import ClientManagement from './ClientManagement';
import CompanyProfile from './CompanyProfile';
import TeamProfile from '../components/TeamManagement/TeamProfile';
import UserProfile from '../components/UserManagement/UserProfile';
import ClientProfile from '../components/ClientManagement/ClientProfile';
import WorkSpace from './WorkSpace';
import SlackIntegrationComponent from './SlackIntegration';
import AccountSettingsComponent from './AccountSettings';

import {
	executivePermissions,
	featureFlagTenants,
	getRoleName,
	isLocalhost,
	Member,
	standardPermissions,
} from '../utilities/utilities';
import Restricted from '../components/Permissions/Restricted';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from '@material-ui/core';
import SearchModal from '../components/sidebar/SearchModal';
import ActionCenterModal from './ActionCenterModal';
import LeavesScreen from './LeavesScreen';
import ArchievedWorkflow from './ArchievedWorkflow';
import ClientInvoice from '../components/ClientManagement/ClientInvoice';
import { useEffect, useMemo } from 'react';
import posthog from 'posthog-js';
import { Snackbar } from '@mui/material';
import ActionCenterLogo from '../components/sub_components/ActionCenterLogo';
import WorkflowBrowser from '../components/ClientManagement/WorkflowBrowser/WorkflowBrowser';
import * as WorkSpaceActions from '../redux/actions/dashboard/workspaces-actions';
import PublicRoute from './PublicRoute';
import Sentry from '../sentry/sentry';
import ErrorFound from './ErrorFound';
import * as authActions from '../redux/actions/auth-actions';
import TimeReportsUserDetailsV2 from '../components/TimeReportsV2/TimeReportsUserDetailsV2';
import * as ActionCenterActions from '../redux/actions/action-center';
import TimeReportsClientDetailsV2 from '../components/TimeReportsV2/TimeReportsClientDetailsV2';
import TimeReportsV2 from './TimeReportsV2';
import WorkflowLibrary from './WorkflowLibrary';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import WorkflowBuilderEditor from '../components/ClientManagement/Workflow-Builder/WorkflowBuilderEditor';
import ZapierIntegration from './ZapierIntegration';
import ZapierAccess from './ZapierAccess';
import * as userActions from '../redux/actions/user-management.actions';
import { ReactComponent as CheckIcon } from '../assets/icons/CheckCircle.svg';
// import ShowArchivedUsersTable from '../components/UserManagement/ShowArchivedUsersTable';
import ArchivedUsers from './ArchivedUsers';
import { ReactComponent as ErrorIcon } from '../assets/icons/Info.svg';
import Invoices from './Invoices';
import CustomizeInvoice from '../components/Invoices/CustomizeInvoice';
import InvoiceDetails from '../components/Invoices/InvoiceDetails';
import InvoicePreview from '../components/Invoices/InvoicePreviewModal';
import useTimeZone from '../hooks/useTimeZone';
import RestrictedAccess from './RestrictedAccess';
import ClientProfilePreview from './ClientProfilePreview';
import TaskModalHandler from './TaskModalHandler';
import { ProductFruits } from 'react-product-fruits';
import UploadDocument from './UploadDocument';
import PostHogGroupIdentification from '../posthog/PostHogGroupIdentification';
import TimeReportPreviewClient from '../components/TimeReportsV2/TimeReportPreviewClient';
import TimeReportsPreviewUser from '../components/TimeReportsV2/TimeReportsPreviewUser';
import HotRefreshPolling from '../components/Others/HotRefreshPolling';
import DetectOnlineStatus from '../components/Others/DetectOnlineStatus';
import UsersPayrollExport from '../components/TimeReportsV2/UsersPayrollExport';

function App() {
	const dispatch = useDispatch();

	const user = useSelector((state) => state.authReducer.user);
	const userDetails = useSelector((state) => state.authReducer.userDetails);
	const actionCenterModal = useSelector((state) => state.authReducer.actionCenterModal);
	// const openActionCenterModal = useSelector(
	// 	(state) => state.workSpaceReducer.openActionCenterModal,
	// );
	const isTenantSubscribed = useSelector((state) => state.authReducer.isTenantSubscribed);

	const snackBar = useSelector((state) => state.workSpaceReducer.snackBar);

	const modalIsOpen = useSelector((state) => state.searchReducer.isSearchModalOpen);

	const toggleModal = () => {
		dispatch(searchActions.setModalState(!modalIsOpen));
	};

	useEffect(() => {
		const { formattedTimeZone } = useTimeZone();
		if (userDetails?.id) {
			dispatch(
				userActions.updateUserProfile({
					id: userDetails?.id,
					timezone: formattedTimeZone,
					email: userDetails?.email,
					full_name: userDetails?.full_name,
				}),
			);
		}
		dispatch(authActions.setFeatureFlag(featureFlagTenants?.includes(user?.tenantId)));
		if (!window.location.host.includes('localhost')) {
			if (userDetails?.id && user?.user_roles) {
				posthog.identify(String(userDetails?.id), {
					name: userDetails?.full_name,
					email: userDetails?.email,
					tenant_id: user?.tenantId,
					roles: user?.user_roles?.map((ur) => getRoleName(ur.role_id)),
				});
			}
		}
	}, [userDetails?.id, user]);

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		dispatch(WorkSpaceActions.setSnackBarIsOpen(false, false, ''));
	};
	useEffect(() => {
		const interval = setInterval(() => {
			dispatch(ActionCenterActions.getNotifications());
			dispatch(ActionCenterActions.getApprovalPending());
		}, 60000);

		return () => {
			clearInterval(interval);
		};
	}, []);

	const userInfo = useMemo(() => {
		if (!userDetails) {
			return null;
		}
		if (!user) {
			return null;
		}
		return {
			username: userDetails.email, // REQUIRED - any unique user identifier
			email: userDetails.email,
			firstname: userDetails.full_name,
			role: user?.userRole?.role_name,
			props: {
				roles: user?.user_roles?.map((ur) => getRoleName(ur.role_id)),
			},
            signUpAt: userDetails?.created_at
		};
	}, [userDetails, user]);


	return (
		<Sentry.ErrorBoundary
			fallback={(error) => {
				dispatch(authActions.setLatestErrorId(error));
				return <ErrorFound />;
			}}
		>
			<div className='App h-auto w-auto'>
				{!isLocalhost && userInfo?.username && (
					<ProductFruits
						workspaceCode={window._env_.REACT_APP_PRODUCT_FRUIT_KEY}
						language='en'
						user={userInfo}
					/>
				)}
				<TaskModalHandler />
				<PostHogGroupIdentification />
				<HotRefreshPolling />
				<DetectOnlineStatus />
				<Switch>
					<PublicRoute path='/login' component={Login} />

					<PublicRoute exact path='/signup' component={Signup} />

					<Route path='/upload-document/:id' component={UploadDocument} />

					{/* <Route
						exact
						path='/activation'
						comp={SignUpActivationComponent}
					/>

					<Route path='/subscription' comp={SubscriptionComponent} />

					<Route
						path='/activation/:activation_token'
						comp={SignUpActivationComponent}
					/> */}

					{/* <Route path='/paymentinfo' comp={PaymentInfoComponent} /> */}

					<Route path='/forgetpassword' component={ForgetPasswordComponent} />

					<Route path='/resetlinksent' component={ResetLinkSentComponent} />

					<Route exact path='/resetpassword' component={ResetPasswordComponent} />

					<Route path='/resetpassword/:resetId' component={ResetPasswordComponent} />
					<ProtectedRoute path='/slackintegration' comp={SlackIntegrationComponent} />
					<ProtectedRoute path='/account-settings' comp={AccountSettingsComponent} />
					<ProtectedRoute path='/client-profile-workflow/:id' comp={WorkflowBrowser} />

					<ProtectedRoute path='/users'>
						<Restricted
							AllowedUsers={[...standardPermissions, Member.teamMemberId]}
							userTeamRole={user?.user_roles?.map((item) => item.role_id)}
						>
							<UserManagement />
						</Restricted>
					</ProtectedRoute>

					<ProtectedRoute path='/archived-users'>
						<Restricted AllowedUsers={[...executivePermissions]}>
							<ArchivedUsers />
						</Restricted>
					</ProtectedRoute>

					<ProtectedRoute path='/user-profile/:id' comp={UserProfile} />

					<ProtectedRoute path='/teams'>
						<Restricted
							AllowedUsers={standardPermissions}
							userTeamRole={user?.user_roles?.map((item) => item.role_id)}
						>
							<TeamManagement />
						</Restricted>
					</ProtectedRoute>

					<ProtectedRoute path='/team-profile/:id'>
						<Restricted
							AllowedUsers={standardPermissions}
							userTeamRole={user?.user_roles?.map((item) => item.role_id)}
						>
							<TeamProfile />
						</Restricted>
					</ProtectedRoute>

					<ProtectedRoute path='/clients' comp={ClientManagement} />
					<ProtectedRoute path='/time-reports' comp={TimeReportsV2} />
                    <ProtectedRoute path='/users-payroll-export' comp={UsersPayrollExport} />
                    <ProtectedRoute path='/client-time-reports-preview' comp={TimeReportPreviewClient} />
                    <ProtectedRoute path='/user-time-reports-preview' comp={TimeReportsPreviewUser} />
					<ProtectedRoute path='/client-invoice'>
						<Restricted AllowedUsers={standardPermissions}>
							<ClientInvoice />
						</Restricted>
					</ProtectedRoute>
					<ProtectedRoute path='/client-profile/:id' comp={ClientProfile} />
					{posthog?.isFeatureEnabled('llm_onboarding') && (
						<ProtectedRoute path='/client-profile-preview'>
							<Restricted
								AllowedUsers={standardPermissions}
								userTeamRole={user?.user_roles?.map((item) => item.role_id)}
							>
								<ClientProfilePreview />
							</Restricted>
						</ProtectedRoute>
					)}
					<ProtectedRoute path='/time-reports-user/:id' comp={TimeReportsUserDetailsV2} />
					<ProtectedRoute
						path='/time-reports-client/:id'
						comp={TimeReportsClientDetailsV2}
					/>
					{/* <ProtectedRoute
						path='/workflow/edit/:id'
						comp={WorkflowEditor}
					/> */}
					<ProtectedRoute path='/workflow-editor/:id' comp={WorkflowBuilderEditor} />
					<ProtectedRoute path='/workspace' comp={WorkSpace} />
					<ProtectedRoute path='/restricted'>
						<RestrictedAccess />
					</ProtectedRoute>
					<ProtectedRoute path='/company-profile'>
						{/* <Restricted AllowedUsers={[Member.ownerId]}> */}
						<CompanyProfile />
						{/* </Restricted> */}
					</ProtectedRoute>
					<ProtectedRoute exact path='/invoices'>
						<Restricted AllowedUsers={executivePermissions}>
							<Invoices />
						</Restricted>
					</ProtectedRoute>
					<ProtectedRoute exact path='/invoices/:id'>
						<Restricted AllowedUsers={executivePermissions}>
							<InvoiceDetails />
						</Restricted>
					</ProtectedRoute>

					<ProtectedRoute path='/customize-invoice'>
						<Restricted AllowedUsers={executivePermissions}>
							<CustomizeInvoice />
						</Restricted>
					</ProtectedRoute>
					<ProtectedRoute path='/preview-invoice'>
						<Restricted AllowedUsers={executivePermissions}>
							<InvoicePreview />
						</Restricted>
					</ProtectedRoute>

					{posthog?.isFeatureEnabled('leave_requests') && (
						<ProtectedRoute path='/leaves'>
							<Restricted AllowedUsers={executivePermissions}>
								<LeavesScreen />
							</Restricted>
						</ProtectedRoute>
					)}

					<ProtectedRoute path='/integration'>
						<ZapierIntegration />
					</ProtectedRoute>

					<ProtectedRoute path='/oauth'>
						<ZapierAccess />
					</ProtectedRoute>

					<ProtectedRoute path='/Workflow-Builder'>
						<Restricted
							AllowedUsers={standardPermissions}
							userTeamRole={user?.user_roles?.map((item) => item.role_id)}
						>
							{/* <WorkflowBuilder /> */}
							<WorkflowLibrary />
						</Restricted>
					</ProtectedRoute>

					<ProtectedRoute path='/archived-workflow'>
						<Restricted
							AllowedUsers={executivePermissions}
						>
							<ArchievedWorkflow />
						</Restricted>
					</ProtectedRoute>

					<PrivateRoutes path='/private' component={Public} />

					<PublicRoute exact path='/' component={AppComponent} />
				</Switch>
				{actionCenterModal && isTenantSubscribed && (
					<>
						<div className='action-center'>
							<Restricted
								AllowedUsers={[...standardPermissions, Member.teamMemberId]}
								userTeamRole={user?.user_roles?.map((item) => item.role_id)}
							>
								{/* <SearchLogo modalIsOpen={modalIsOpen} /> */}
							</Restricted>

							<div>
								<ActionCenterLogo />
							</div>
						</div>
						<Modal
							onClose={toggleModal}
							open={modalIsOpen}
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<SearchModal onClose={toggleModal} />
						</Modal>
						<ActionCenterModal />
					</>
				)}

				<Snackbar
					open={snackBar?.IsOpen}
					autoHideDuration={5000}
					onClose={handleClose}
					anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				>
					<div className='SnackBarAlert'>
						<div className='d-flex align-items-center' style={{ gap: '18px' }}>
							<div>
								{snackBar?.isSuccess ? (
									<CheckIcon width={24} height={24} />
								) : (
									<ErrorIcon width={24} height={24} />
								)}
							</div>
							<span className='BodyTwoLight text-left'>{snackBar?.message}</span>
						</div>
						<IconButton
							size='small'
							aria-label='close'
							color='inherit'
							onClick={handleClose}
						>
							<CloseIcon fontSize='small' />
						</IconButton>
					</div>
				</Snackbar>
			</div>
		</Sentry.ErrorBoundary>
	);
}

export default App;
