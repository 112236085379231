import * as Actions from '../actions/team-management.actions';

const initialState = {
	teams: [],
	loading: true,
	teamId: null,
	teamDetails: {},
	teamMembers: [],
	filteredTeamManagements: [],
	newTeamMemberDetails: {
		name: '',
		email: '',
		role_id: null,
		user_id: null,
	},
	filters: {
		teamManagementClients: [],
	},
	archiveLoading: false,
	monthlyView: false,
};

const TeamManagement = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case Actions.GET_TEAMS_START:
			return (state = {
				...state,
				loading: true,
			});

		case Actions.GET_TEAMS_END:
			return (state = {
				...state,
				teams: payload,
				loading: false,
			});

		case Actions.GET_TEAMS_FAILED:
			return (state = {
				...state,
				loading: false,
			});

		case Actions.ADD_TEAM_END:
			return (state = {
				...state,
				teams: [...state.teams, payload],
			});

		case Actions.UPDATE_TEAM_END:
			return (state = {
				...state,
				teams: state.teams.map((team) => {
					if (team.id === payload.id) {
						return payload;
					}
					return team;
				}),
			});

		case Actions.DELETE_TEAM_START:
			return (state = {
				...state,
				loading: true,
			});

		case Actions.DELETE_TEAM_FAILED:
			return (state = {
				...state,
				loading: false,
			});

		case Actions.DELETE_TEAM_END:
			return (state = {
				...state,
				teams: state.teams.filter((team) => team.id !== payload),
				loading: false,
			});

		case Actions.DELETE_TEAMS_START:
			return (state = {
				...state,
				loading: true,
			});

		case Actions.DELETE_TEAMS_FAILED:
			return (state = {
				...state,
				loading: false,
			});

		case Actions.DELETE_TEAMS_END:
			return (state = {
				...state,
				teams: state.teams.filter(
					(team) => team.id !== payload.find((id) => id === team.id),
				),
				loading: false,
			});

		case Actions.SET_TEAM_ID:
			return (state = {
				...state,
				teamId: payload,
			});

		case Actions.HANDLE_INPUT_CHANGE:
			return (state = {
				...state,
				teamDetails: payload,
			});
		case Actions.HANDLE_NEW_TEAM_MEMBER_CHANGE:
			return (state = {
				...state,
				newTeamMemberDetails: {
					...state.newTeamMemberDetails,
					[payload.name]: payload.value,
				},
			});

		case Actions.RESET_NEW_TEAM_MEMBER_VALUES:
			return (state = {
				...state,
				newTeamMemberDetails: {
					name: '',
					email: '',
					role_id: null,
					user_id: null,
				},
			});

		case Actions.HANDLE_TEAM_MEMBER_CHANGE: {
			var tempArr = state.teamMembers.map((member) => {
				if (member.user_id === payload.user_id) {
					if (payload.name === 'user_id' || payload.name === 'role_id') {
						return {
							...member,
							[payload.name]: parseInt(payload.value),
						};
					} else {
						return {
							...member,
							[payload.name]: payload.value,
						};
					}
				} else {
					return member;
				}
			});
			return (state = {
				...state,
				teamMembers: tempArr,
			});
		}

		case Actions.HANDLE_ALL_TEAM_MEMBER_CHANGE: {
			return (state = {
				...state,
				teamMembers: state?.teamMembers?.map((member) => {
					if (payload?.teamMembers?.includes(member.user_id)) {
						return {
							...member,
							team_id: payload.teamId,
						};
					}
					return member;
				}),
			});
		}

		case Actions.SET_TEAM_MEMBERS:
			return (state = {
				...state,
				teamMembers: payload,
			});

		case Actions.GET_TEAM_MEMBERS:
			return (state = {
				...state,
				teamDetails: payload.teamDetails,
				teamMembers: payload.userTeams.map((user) => {
					return {
						roles: payload.roles,
						image_url: user?.user_profile?.image_url,
						name: user?.user_profile?.full_name,
						email: user?.user_profile?.email,
						user_id: user?.user_id,
						role:
							payload.roles.find(
								(role) =>
									role.user_id === user.user_id &&
									role.role_scopes.length > 0 &&
									role.role_scopes.find(
										(scope) => scope.team_id === parseInt(payload.team_id),
									),
							)?.roles?.role_name ||
							payload.roles.find((role) => role.user_id === user.user_id)?.roles
								?.role_name,
						role_id:
							payload.roles.find(
								(role) =>
									role.user_id === user.user_id &&
									role.role_scopes.length > 0 &&
									role.role_scopes.find(
										(scope) => scope.team_id === parseInt(payload.team_id),
									),
							)?.roles?.id ||
							payload.roles.find((role) => role.user_id === user.user_id)?.roles
								?.id,
						team_id: user?.team_id,
						user_team_id: user?.id,
						is_active: user?.user_profile?.is_active,
					};
				}),
			});
		case Actions.ADD_TEAM_MEMBER_START:
			return (state = {
				...state,
				loading: true,
			});
		case Actions.ADD_TEAM_MEMBER_END:
			return (state = {
				...state,
				loading: false,
				newTeamMemberDetails: {
					...initialState.newTeamMemberDetails,
				},
			});
		case Actions.DELETE_TEAM_MEMBER_START:
			return (state = {
				...state,
				loading: true,
			});
		case Actions.DELETE_TEAM_MEMBER_END:
			return (state = {
				...state,
				loading: false,
				teamMembers: state.teamMembers.filter(
					(member) => member.user_team_id !== payload,
				),
			});
		case Actions.UPDATE_TEAM_MEMBERS_START:
			return (state = {
				...state,
				loading: true,
			});
		case Actions.UPDATE_TEAM_MEMBERS_END:
			return (state = {
				...state,
				loading: false,
			});
		case Actions.UPDATE_TEAM_MEMBERS_FAILED:
			return (state = {
				...state,
				loading: false,
			});
		case Actions.GET_FILTERED_TEAM_MANAGEMENT:
			return (state = {
				...state,
				filteredTeamManagements: payload,
			});

		case Actions.SET_FILTERED_CLIENTS:
			return (state = {
				...state,
				filters: {
					...state.filters,
					teamManagementClients: state.filters.teamManagementClients.includes(
						payload,
					)
						? state.filters.teamManagementClients.filter((p) => p !== payload)
						: [...state.filters.teamManagementClients, payload],
				},
			});
		case Actions.RESET_CLIENTS_FILTER:
			return (state = {
				...state,
				filters: {
					teamManagementClients: [],
				},
			});
		case Actions.SET_ARCHIVE_LOADING:
			return (state = {
				...state,
				archiveLoading: payload,
			});
		case Actions.SET_DATES:
			return (state = {
				...state,
				start_date: payload.start_date,
				end_date: payload.end_date,
			});
		case Actions.SET_MONTHLY_VIEW:
			return (state = {
				...state,
				monthlyView: payload,
			});
		default:
			return state;
	}
};

export default TeamManagement;
