import React, { useEffect, useState } from 'react';
import SubmitButtonsField from '../Buttons/SubmitButtonsField';
import DropDown from '../Inputs/DropDown';
import { CircularProgress } from '@mui/material';

const EditUserTeamRole = ({
	onCancel,
	onSubmit,
	team,
	Roles,
	TeamRoles,
	actionLoading,
}) => {
	const [teamRole, setTeamRole] = useState(team);

	const handleChange = (e) => {
		console.log(e.target.value, 'eventCheck');
		setTeamRole((teamRole) => {
			return {
				...teamRole,
				role_id: parseInt(e.target.value),
			};
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		onSubmit(teamRole);
	};

	return (
		<form
			onSubmit={(e) => {
				e.preventDefault();
				onSubmit();
			}}
		>
			<div className='p-3'>
				<div className='improved-input-designs pb-3'>
					<label className='require'>Team Role</label>
					<DropDown
						onChange={handleChange}
						name='role_id'
						value={{
							value:
								Roles?.find((role) => role.id === teamRole?.role_id)?.id ||
								(TeamRoles?.length > 0 && TeamRoles[0]?.role_id),
						}}
						options={Roles?.filter(
							(role) => role.id == 13 || role.id == 14,
						)?.map((role) => {
							return {
								value: role.id,
								label: role.role_name,
							};
						})}
						className='text-left m-0'
						disableNull
					/>
				</div>
			</div>

			<div className='VerticalSpacer bg-white p-3 rounded-bottom'>
				<SubmitButtonsField child={true}>
					<button
						id='Element'
						className='secondary'
						type='button'
						onClick={() => {
							onCancel();
						}}
					>
						Cancel
					</button>

					<button
						type='submit'
						className='d-flex align-items-center'
						id='Element'
						disabled={actionLoading}
						onClick={handleSubmit}
					>
						{actionLoading ? (
							<CircularProgress
								size={24}
								sx={{ color: 'white' }}
								disableShrink
							/>
						) : (
							'Update'
						)}
					</button>
				</SubmitButtonsField>
			</div>
		</form>
	);
};

export default EditUserTeamRole;
