import DummyModal from './DummyModal';
import { Modal } from '@mui/material';
import SubmitButtonsField from '../Buttons/SubmitButtonsField';

function FileExistModal({ modalIsOpen, toggleModal }) {
	return (
		<div>
			{modalIsOpen && (
				<Modal
					onClose={toggleModal}
					open={modalIsOpen}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<>
						<DummyModal title='Duplicate file name exists in the selected folder' onClose={toggleModal} style={{width:'35%', minWidth:'600px'}}>
							<div>
								<p
									className='BodyTwoLight improved-input-designs pb-3 pl-3 pr-3 d-flex m-auto text-align-center'
									style={{ width: '100%' }}
								>
									A file with the same name already exists in the selected folder. Kindly rename the file or choose another folder to proceed with the upload
								</p>
							</div>
							<div className='VerticalSpacer bg-white p-3 rounded-bottom'>
								<SubmitButtonsField child={true}>
									<button
										id='Element'
										type='button'
										className='secondary'
										style={{ borderRadius: '50px', width: '125px' }}
										onClick={toggleModal}
									>
										Cancel
									</button>
									<button
										type='submit'
										className='d-flex align-items-center'
										style={{ borderRadius: '50px', width: 'auto' }}
										id='Element'
										data-cy='add-client-btn'
                                        onClick={toggleModal}
									>
										Okay
									</button>
								</SubmitButtonsField>
							</div>
						</DummyModal>
					</>
				</Modal>
			)}
		</div>
	);
}

export default FileExistModal;
