/* eslint-disable no-mixed-spaces-and-tabs */
import { Box } from '@mui/system';
import { useEffect } from 'react';
import TableHeader from '../Others/TableHeader';
import TableHeaderItem from '../Others/TableHeaderItem';
import TableRowItem from '../Others/TableRowItem';
import { TableItemWidth2, TableItemWidth3 } from '../TableSpacing/TableSpacingStyles';
import * as userActions from '../../redux/actions/user-management.actions';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { Modal, Tooltip } from '@mui/material';
import TableRowButton from '../Others/TableRowButton';
import DummyModal from '../Modal/DummyModal';
import UserBillingModal from './UserBillingModal';
import BillingHistory from '../ClientManagement/Modals/UserBillingHistory';
import moment from 'moment';
import { useParams } from 'react-router';
import {
	convertDecimalToTime,
	numberWithCommas,
} from '../../utilities/utilities';
import { ReactComponent as Billing } from '../../assets/icons/billing.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/PencilEdit.svg';
import getSymbolFromCurrency from 'currency-symbol-map';
import { ReactComponent as BillingLocked } from '../../assets/icons/LockSimple.svg';
import { getUserSettings } from '../../hooks/getUserSettings';

function UserBilling({ sectionId, userData }) {
	const dispatch = useDispatch();

	const { id } = useParams();

	const user = useSelector((state) => state.authReducer.user);
	const [selectedRow, setSelectedRow] = useState(null);
	const [edit, setEdit] = useState(false);
	const [billingModal, setBillingModal] = useState(false);
	const [isCreateModal, setIsCreateModal] = useState(true);
	const [createBillingModal, setCreateBillingModal] = useState(false);
	const companyProfileData = useSelector((state) => state.profileReducer.profileData);
    const userDetails = useSelector((state) => state.authReducer.userDetails);
    const { date_format }  = getUserSettings(userDetails);
	const currencySymbol = companyProfileData
		? getSymbolFromCurrency(companyProfileData?.currency)
		: '';

	const specificUserBilling = useSelector((state) => state.userReducer.specificUserBilling);

	const activeUserbilling = specificUserBilling?.find((item) => item?.is_active === true);

	const toggleBillingModal = () => {
		setBillingModal(!billingModal);
	};
	const toggleCreateBillingModal = () => {
		setCreateBillingModal(!createBillingModal);
	};

	const saveChanges = () => {
		setEdit(!edit);
	};

	useEffect(() => {
		if ((id || user?.userId) && specificUserBilling?.length < 1) {
			dispatch(userActions.getSpecificUserBilling(id || user?.userId));
		}
	}, [dispatch, id]);

	return (
		<>
			<div className='profile-section' style={{ gap: '12px' }} id={sectionId}>
				<div className='Uprofile-sectionBar'>
					<div className='d-flex align-items-center' style={{ gap: '12px' }}>
						<Billing />
						<h2 className='HeadlineThreeBold text-left'>User Billing</h2>
					</div>

					{specificUserBilling?.length > 0 && <div>
						<span
							className='BodyTwoLight primary-text mr-4'
							style={{ cursor: 'pointer' }}
							onClick={() => {
								toggleBillingModal();
							}}
						>
							View History
						</span>
					</div>}
				</div>

				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						gap: '0.3em',
						padding: '18px',
						backgroundColor: '#EEF2F3',
						borderRadius: '4px',
					}}
				>
					{activeUserbilling ? (
						<Box>
							<TableHeader ClassName='TableHeader flex' border={false}>
								<TableHeaderItem label='Name' width={TableItemWidth3} />
								<TableHeaderItem label='Available Hours/Week' width={TableItemWidth3} />
								<TableHeaderItem label='Benefits' width={TableItemWidth3} />
								<TableHeaderItem label='Hourly Rate' width={TableItemWidth3} />
								<TableHeaderItem label='Start Date' width={TableItemWidth3} />
								<TableHeaderItem label='Salary' width={TableItemWidth3} />
								<TableHeaderItem label='Cost/Hour' width={TableItemWidth3} />

								<>
									{/* <TableHeaderItem label='History' width={TableItemWidth3} /> */}
									<TableHeaderItem
										ClassName='TProfile d-flex justify-content-center'
										label='Action'
										width={TableItemWidth2}
									/>
								</>
							</TableHeader>

							<div className='TableRow d-flex align-items-center default-cursor'>
								<TableRowItem
									ClassName=' text-left'
									label={userData?.full_name || '-'}
									width={TableItemWidth3}
								/>
								<TableRowItem
									ClassName=' text-left'
									label={
										activeUserbilling
											? convertDecimalToTime(
													activeUserbilling?.available_hours,
											  )
											: '--'
									}
									width={TableItemWidth3}
								/>

								<TableRowItem
									ClassName=' text-left'
									label={`${currencySymbol}${numberWithCommas(
										activeUserbilling?.job_benefits,
									)}`}
									width={TableItemWidth3}
								/>

								<TableRowItem
									ClassName=' text-left'
									label={
										activeUserbilling
											? activeUserbilling?.cost_mode === 'annual'
												? '--'
												: activeUserbilling?.salary_per_hour !== 0
												? `${currencySymbol}${numberWithCommas(
														activeUserbilling?.salary_per_hour,
												  )}`
												: '--'
											: '--'
									}
									width={TableItemWidth3}
								/>
								<TableRowItem
									ClassName=' text-left'
									label={moment(activeUserbilling?.bill_start_date).format(date_format)}
									width={TableItemWidth3}
								/>
								<TableRowItem
									ClassName=' text-left'
									label={
										activeUserbilling
											? activeUserbilling?.cost_mode === 'hourly'
												? '-----'
												: activeUserbilling?.annual_salary !== 0
												? `${currencySymbol}${numberWithCommas(
														activeUserbilling?.annual_salary,
												  )}`
												: '-----'
											: '-----'
									}
									width={TableItemWidth3}
								/>

								<TableRowItem
									ClassName=' text-left'
									label={
										activeUserbilling?.cost_per_hour
											? `${currencySymbol}${numberWithCommas(
													activeUserbilling?.cost_per_hour,
											  )}`
											: '-----'
									}
									width={TableItemWidth3}
								/>
								<>
									{/* <TableRowButton
										ClassName='text-left pointer primary-text'
										textClass='BodyTwoLight'
										label='View'
										width={TableItemWidth3}
										input={false}
										clickHandler={() => {
											toggleBillingModal();
										}}
										dataCy='view-user'
									/> */}
									<TableRowButton
										ClassName='TProfile d-flex justify-content-center pointer'
										width={TableItemWidth2}
									>
										{activeUserbilling ? (
											<Tooltip title='Edit Client Info'>
												<EditIcon
													style={{
														color: '#fb7a03',
														cursor: 'pointer',
													}}
													onClick={() => {
														setSelectedRow(activeUserbilling);
														toggleCreateBillingModal();
														setIsCreateModal(false);
													}}
												/>
											</Tooltip>
										) : (
											<Tooltip title='Billing is Locked'>
												<BillingLocked
													style={{
														color: '#fb7a03',
														cursor: 'pointer',
													}}
												/>
											</Tooltip>
										)}
									</TableRowButton>
								</>
							</div>
						</Box>
					) : (
						<div
							className='d-flex row w-100 justify-content-between information-content'
							style={{
								borderTop: '1px solid  #EAEAEA ',
								padding: '1em',
								gap: '2%',
								background: 'white',
								width: '100%',
								margin: 'auto',
							}}
						>
							<h3 className='BodyTwoLight mb-0 table-header-text  ml-1 m-auto opacity-75'>
								No billing against the user. Click{' '}
								<span
									style={{ color: '#FB7A03' }}
									className='pointer'
									onClick={() => {
										toggleCreateBillingModal();
										setIsCreateModal(true);
									}}
								>
									here
								</span>{' '}
								to create a new billing
							</h3>
						</div>
					)}
				</Box>
			</div>

			<Modal
				onClose={toggleCreateBillingModal}
				open={createBillingModal}
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<DummyModal
					title={`${isCreateModal ? 'Create' : 'Edit'} User Billing`}
					onClose={() => {
						toggleCreateBillingModal();
					}}
				>
					<UserBillingModal
						selectedUser={selectedRow}
						onCancel={() => {
							toggleCreateBillingModal();
						}}
						title={isCreateModal ? 'Save' : 'Update'}
						isCreate={isCreateModal}
						currencySymbol={currencySymbol}
					/>
				</DummyModal>
			</Modal>

			<Modal
				onClose={toggleBillingModal}
				open={billingModal}
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<BillingHistory
					onCancel={toggleBillingModal}
					data={specificUserBilling}
					currencySymbol={currencySymbol}
				/>
			</Modal>
		</>
	);
}

export default UserBilling;
