import { Avatar, Modal } from '@mui/material';
import React from 'react';
import ProgressIcon from '../Icons/ProgressIcon';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { useHistory, useParams } from 'react-router-dom';
import * as clientActions from '../../redux/actions/client-actions';
import * as builderActions from '../../redux/actions/workflow-builder-actions.js';

import { useDispatch, useSelector } from 'react-redux';
import { executivePermissions, Member, standardPermissions } from '../../utilities/utilities';
import Restricted from '../Permissions/Restricted';
import {ReactComponent as EllipseIcon} from '../../assets/icons/Ellipse.svg';
import useModal from '../Modal/useModal';
import DummyModal from '../Modal/DummyModal';
import ArchiveModal from '../Modal/ArchiveModal';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';

function TopBar({ clientInfo, generateInvoice }) {
	const history = useHistory();
	const dispatch = useDispatch();

	const user = useSelector((state) => state.authReducer.user);
	const { modalIsOpen, toggleModal, ModalName, ModalType } = useModal();

	const { id } = useParams();

	const activeClientWorkflows = useSelector((state) => state.WorkflowBuilder.activeClientWorkflows);

	const activeWorkflowNames = activeClientWorkflows?.map(workflow => workflow.workflow.name);
	const onDelete = () => {

		dispatch(clientActions.deActivateWorklowsList(history, id));

	};

	const handleArchiveClient = () => {
		dispatch(builderActions.getActiveClientWorkflows(clientInfo?.id));
		toggleModal();
	};

	return (
		<div className='Profile-TopBar' style={{height: '63px', borderRadius:'4px'}}>
			<div className='d-flex flex-row align-items-center'>
				<Avatar
					sx={{
						bgcolor: `#FED0A5`,
						color: `#333`,
						marginRight: '10px',
						height: '36px',
						width: '36px',
						fontSize: '0.8rem',
					}}
					alt={clientInfo.name}
					src='/broken-image.jpg'
				/>
				<div className='d-flex flex-column' style={{ gap: '1px' }}>
					<h6 className='HeadlineThreeBold pt-1 text-left font-weight-bolder light-dark'>
						{clientInfo.name}
					</h6>
					<h6 className='light-dark BodyTwoLight m-0 text-left'>{clientInfo.team}</h6>
				</div>
			</div>

			<div
				className='d-flex flex-row'
				style={{ gap: '1em' }}

			>
					<Restricted AllowedUsers={executivePermissions}>
					<div className='d-flex flex-row align-items-center pointer'>
						<h3
							onClick={handleArchiveClient}
							className='BodyTwoLight mb-0 primary-text ml-1'
							data-cy="client-archive-opener"
						>
							Archive Client
						</h3>
					</div>
					<div className='d-flex flex-row align-items-center'>
						<EllipseIcon
							sx={{height: '30px', width: '30px' }}
						/>
					</div>
				</Restricted>

				<Restricted AllowedUsers={executivePermissions}>
					<div className='d-flex flex-row align-items-center pointer'>
						<h3
							// onClick={handleArchiveClient}
							className='BodyTwoLight mb-0 primary-text ml-1'
						>
							Payments
						</h3>
					</div>
					<div className='d-flex flex-row align-items-center'>
						<EllipseIcon
							sx={{height: '30px', width: '30px' }}
						/>
					</div>
				</Restricted>

				<Restricted AllowedUsers={standardPermissions}>
					<div className='d-flex flex-row align-items-center pointer'>
						<h3
							onClick={generateInvoice}
							className='BodyTwoLight mb-0 primary-text ml-1'
						>
							Generate Invoice
						</h3>
					</div>
					<div className='d-flex flex-row align-items-center'>
						<EllipseIcon
							sx={{height: '30px', width: '30px' }}
						/>
					</div>
				</Restricted>

				

				<div
					className='d-flex flex-row align-items-center pointer'
					onClick={() => history.push(`/workspace`)}
				>
					<h3 className='BodyTwoLight mb-0 primary-text ml-1'>Manage dashboard</h3>
				</div>
			</div>
			{modalIsOpen && (
				<Modal
					onClose={toggleModal}
					open={modalIsOpen}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<DummyModal title="Archive" onClose={toggleModal} warningIcon={true}>
						<ArchiveModal client={clientInfo?.name} onCancel={toggleModal} list={activeWorkflowNames} onArchive={onDelete} title={`Currently Active Workflows for ${clientInfo?.name}`} />
					</DummyModal>
				</Modal>
			)}
		</div>

	);
}

export default TopBar;
