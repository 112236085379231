/* eslint-disable no-mixed-spaces-and-tabs */
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SkeletonLoading from '../Others/SkeletonLoading';
import TableHeader from '../Others/TableHeader';
import TableHeaderItem from '../Others/TableHeaderItem';
import TableRowItem from '../Others/TableRowItem';
import { TableItemWidth3, TableItemWidth5 } from '../TableSpacing/TableSpacingStyles';
import HeadingTwo from '../TypoGraphy/HeadingTwo';
import * as TimeReportsActions from '../../redux/actions/time-reports-actions-v2';
import { Member, convertDecimalToTime, standardPermissions } from '../../utilities/utilities';
import { ReactComponent as ArrowUp } from '../../assets/icons/up.svg';
import { ReactComponent as ArrowDown } from '../../assets/icons/down.svg';
import { useParams } from 'react-router';
import ExpandableTimeReportsTasksViewV2 from '../TimeReportsV2/ExpandableTimeReportsTasksViewV2';

function TimeReportsUserInfoV2({ userData }) {
	const loading = useSelector((state) => state.TimeReportsV2.loading);
	const { id } = useParams();
	const dispatch = useDispatch();
	const userRole = useSelector((state) => state.TimeReportsV2.userRole);
	const loggedInUser = useSelector((state) => state.authReducer.user);
	const start_date = useSelector((state) => state.TimeReportsV2.start_date);
	const end_date = useSelector((state) => state.TimeReportsV2.end_date);
	const [selected, setSelected] = useState(false);
	const [clientToEdit, setClientToEdit] = useState(null);
	const [workflowToExpand, setWorkflowExpand] = useState();
	const tasks = useSelector((state) => state.TimeReportsV2.tasks);

	const expandTasks = (type, client_workflow_id, client_id) => {
		setSelected(true);
		setClientToEdit(client_id);
        dispatch(TimeReportsActions.resetTasks());
		client_workflow_id !== null
			? setWorkflowExpand(client_workflow_id)
			: setWorkflowExpand(type);
		dispatch(
			TimeReportsActions.getTasksDetails(
				client_workflow_id,
				client_id,
				id,
				type == 'meetings' || type == 'user_meetings' ? true : false,
				start_date,
				end_date,
				type == 'user_meetings' ? false : true,
			),
		);
	};

	console.log(userRole, 'userRoleCheck');

	const currentUser = userRole?.reduce(
		(acc, role) => {
			acc.user_id = role.user_id;
			acc.role_id = role.role_id;
			acc.scopes.push(
				...(role.role_scopes?.map((scope) => ({
					role_id: role?.role_id,
					team_id: scope?.team_id,
				})) || []),
			);
			return acc;
		},
		{ user_id: null, scopes: [], role_id: null },
	);

	const totalValues = useMemo(() => {
		return userData?.clients?.map((client) => {
			const budgeted_hours =
				(client?.client_workflows?.length > 0
					? parseFloat(
							client?.client_workflows?.reduce(
								(acc, workflow) => acc + workflow?.budgeted_hours,
								0,
							),
					  )
					: 0) +
				(client?.workflow_my_tasks !== null
					? client?.workflow_my_tasks?.budgeted_hours
					: 0) +
				(client?.meetings !== null ? client?.meetings?.budgeted_hours : 0);

			const consumed_time =
				(client?.client_workflows?.length > 0
					? parseFloat(
							client?.client_workflows?.reduce(
								(acc, workflow) => acc + workflow?.consumed_time,
								0,
							),
					  )
					: 0) +
				(client?.workflow_my_tasks !== null
					? client?.workflow_my_tasks?.consumed_time
					: 0) +
				(client?.meetings !== null ? client?.meetings?.consumed_time : 0);

			return {
				clientId: client?.client_id,
				totalBudgetedHours: convertDecimalToTime(budgeted_hours),
				totalConsumedTime: convertDecimalToTime(consumed_time / 3600000),
				totalUtilizationHours: Math.floor((consumed_time / 3600000 / budgeted_hours) * 100)
			};
		});
	}, [userData]);

	useEffect(() => {
		setSelected(false);
	}, [start_date, end_date]);

	let isAllowed = false;

	let currentUserRole = null;
	let loggedInUserRole = null;

	currentUser?.scopes?.length > 0 &&
		currentUser?.scopes?.some((scope) => {
			const RoleFound = loggedInUser?.user_roles?.find((item) =>
				item.role_scopes?.some((role) => role?.team_id === scope?.team_id),
			);
			currentUserRole = RoleFound && scope.role_id;
			loggedInUserRole = RoleFound && RoleFound?.role_id;
		});

	if (
		loggedInUser?.userRole?.id === Member.ownerId ||
		loggedInUser?.userRole?.id === Member.superAdminId
	) {
		isAllowed = true;
	} else if (
		currentUser?.scopes?.length < 1 &&
		currentUser?.role_id == Member.ownerId &&
		loggedInUser?.userRole?.id == Member.supervisorId
	) {
		isAllowed = true;
	} else {
		if (loggedInUserRole == Member.supervisorId) {
			isAllowed = true;
		} else if (loggedInUserRole == Member.teamManagerId) {
			if (currentUser?.user_id == loggedInUser?.userId) {
				isAllowed = true;
			} else if (
				currentUserRole == Member.teamMemberId ||
				currentUserRole == Member.externalCollaborator
			) {
				isAllowed = true;
			} else {
				isAllowed = false;
			}
		} else if (
			(loggedInUserRole == Member.teamMemberId ||
				loggedInUserRole == Member.externalCollaborator) &&
			loggedInUser?.userId == currentUser?.user_id
		) {
			isAllowed = true;
		} else {
			isAllowed = false;
		}
	}

	const meetingAndMyTasksBudgetedHours = userData?.user_meetings?.budgeted_hours;
	const meetingAndMyTasksLoggedHours = userData?.user_meetings?.consumed_time / 3600000;
	const meetingAndMyTasksPercentage = Math.floor(
		(meetingAndMyTasksLoggedHours / meetingAndMyTasksBudgetedHours) *
		100
	);

	return (
		<div>
			{
				<div id='MEETINGS'>
					<div
						className='d-flex align-items-center justify-content-between w-100'
						style={{
							backgroundColor: '#FCFDFD',
							width: '100%',
							padding: '20px 18px',
							gap: '1.5em',
							boxSizing: 'border-box',
							borderRadius: '4px 4px 0px 0px',
						}}
					>
						<SkeletonLoading loading={loading} variant='text' width='25%' height={45}>
							<div>
								<HeadingTwo>{'Meetings and My Tasks'}</HeadingTwo>{' '}
							</div>
						</SkeletonLoading>
					</div>

					<div className='user-section-body'>
						<div className='team-members-body'>
							<SkeletonLoading
								loading={loading}
								variant='text'
								width='100%'
								height={45}
							>
								<TableHeader ClassName='new-table-header' border={false}>
									<TableHeaderItem
										labelClass='BodyTwoLight'
										label=''
										width={TableItemWidth5}
									/>
									<TableHeaderItem
										labelClass='BodyTwoLight'
										label='Budgeted Hours'
										width={TableItemWidth5}
									/>
									<TableHeaderItem
										labelClass='BodyTwoLight'
										label='Logged Time'
										width={TableItemWidth5}
									/>
									<TableHeaderItem
										labelClass='BodyTwoLight'
										label='Utilization'
										width={TableItemWidth5}
									/>
									<TableHeaderItem
										labelClass='BodyTwoLight'
										label=''
										width={TableItemWidth3}
									/>
								</TableHeader>
							</SkeletonLoading>
							<div className='ListingLoading scroll'>
								{userData?.user_meetings == null ? (
									<SkeletonLoading
										loading={loading}
										variant='text'
										width='100%'
										height={45}
									>
										<div className='TableRow w-100 d-flex align-items-center justify-content-center'>
											<h4 className='BodyTwoLight'>No Meetings</h4>
										</div>
									</SkeletonLoading>
								) : (
									<SkeletonLoading
										loading={loading}
										variant='text'
										width='100%'
										height={45}
									>
										<div className='new-table-row d-flex align-items-center'>
											<TableRowItem
												ClassName='text-left'
												textClass='BodyTwoLight'
												label='Meetings'
												width={TableItemWidth5}
												input={false}
											/>

											<TableRowItem
												ClassName='text-left'
												textClass='BodyTwoLight'
												label={convertDecimalToTime(
													userData?.user_meetings?.budgeted_hours,
												)}
												width={TableItemWidth5}
												input={false}
											/>
											<TableRowItem
												ClassName='text-left'
												textClass='BodyTwoLight'
												label={convertDecimalToTime(
													userData?.user_meetings?.consumed_time /
														3600000,
												)}
												width={TableItemWidth5}
												input={false}
											/>
											<TableRowItem
												ClassName='text-left'
												textClass={`BodyTwoLight ${
													meetingAndMyTasksPercentage < 0
														? 'red-text'
														: ''
												}`}
												label={`${meetingAndMyTasksPercentage} %`}
												width={TableItemWidth5}
												input={false}
												toolTipText='Logged Hours / Budgeted Hours'
											/>
											<TableRowItem
												className='text-right'
												label=''
												input={true}
												width={TableItemWidth3}
											>
												<div className='pointer'>
													{selected &&
													workflowToExpand == 'user_meetings' ? (
														<span onClick={() => setSelected(false)}>
															<ArrowUp />
														</span>
													) : (
														<span
															onClick={() =>
																expandTasks(
																	'user_meetings',
																	null,
																	null,
																)
															}
														>
															<ArrowDown />
														</span>
													)}
												</div>
											</TableRowItem>
										</div>
										{selected && workflowToExpand == 'user_meetings' && (
											<ExpandableTimeReportsTasksViewV2
												tasks={tasks}
												isAllowed={isAllowed}
												userId={id}
											/>
										)}
									</SkeletonLoading>
								)}
							</div>
						</div>
					</div>
				</div>
			}
			{userData?.clients
				?.filter(
					(client) =>
						client?.client_workflows?.length > 0 ||
						client?.workflow_my_task !== null ||
						client?.meetings !== null,
				)
				?.map((client, index) => {
					const loggedInUser_role = loggedInUser?.user_roles?.find((item) => {
						return item?.role_scopes
							?.map((role) => role.team_id)
							?.includes(client.team_id);
					})?.role_id;

					const currentUserRole =
						currentUser?.scopes?.length > 0
							? currentUser?.scopes?.find(
									(scope) => scope?.team_id == client?.team_id,
							  )?.role_id
							: null;

					let isAllowed = false;
					if (
						loggedInUser?.userRole?.id == Member.ownerId ||
						loggedInUser?.userRole?.id == Member.superAdminId
					) {
						isAllowed = true;
					} else if (loggedInUser_role) {
						if (loggedInUser_role == Member.supervisorId) {
							isAllowed = true;
						} else if (loggedInUser_role == Member.teamManagerId) {
							if (currentUser?.user_id === loggedInUser?.userId) {
								isAllowed = true;
							} else if (currentUserRole) {
								if (
									currentUserRole == Member.teamMemberId ||
									currentUserRole == Member.externalCollaborator
								) {
									isAllowed = true;
								} else {
									isAllowed = false;
								}
							} else if (currentUser.role_id !== Member.ownerId) {
								isAllowed = true;
							} else {
								isAllowed = false;
							}
						} else if (
							(loggedInUser_role == Member.teamMemberId ||
								loggedInUser_role == Member.externalCollaborator) &&
							loggedInUser?.userId === currentUser?.user_id
						) {
							isAllowed = true;
						} else {
							isAllowed = false;
						}
					}
					const adHocClientBudgetedHours = client?.workflow_my_tasks?.budgeted_hours;
					const adHocClientLoggedHours =
						client?.workflow_my_tasks?.consumed_time / 3600000;
					const adHocClientPercentage = Math.floor(
						(adHocClientLoggedHours / adHocClientBudgetedHours) *
						100
					);

					const clientMeetingsBudgetedHours = client?.meetings?.budgeted_hours;
					const clientMeetingsLoggedHours = client?.meetings?.consumed_time / 3600000;
					const clientMeetingsPercentage = Math.floor(
						(clientMeetingsLoggedHours / clientMeetingsBudgetedHours) *
						100
					);

					return (
						<div id={client?.client_name} key={index}>
							<SkeletonLoading
								loading={loading}
								variant='text'
								width='100%'
								height={45}
							>
								<div
									className='d-flex align-items-center justify-content-between w-100'
									style={{
										backgroundColor: '#FCFDFD',
										padding: '20px 18px',
										gap: '1.5em',
										boxSizing: 'border-box',
										borderRadius: '4px 4px 0px 0px',
									}}
								>
									<div>
										<HeadingTwo>{client?.client_name}</HeadingTwo>{' '}
									</div>
								</div>
							</SkeletonLoading>
							<div className='user-section-body'>
								<div className='team-members-body'>
									<SkeletonLoading
										loading={loading}
										variant='text'
										width='100%'
										height={45}
									>
										<TableHeader ClassName='new-table-header' border={false}>
											<TableHeaderItem
												labelClass='BodyTwoLight'
												label='Workflow'
												width={TableItemWidth5}
											/>
											<TableHeaderItem
												labelClass='BodyTwoLight'
												label='Budgeted Hours'
												width={TableItemWidth5}
											/>
											<TableHeaderItem
												labelClass='BodyTwoLight'
												label='Logged Time'
												width={TableItemWidth5}
											/>
											<TableHeaderItem
												labelClass='BodyTwoLight'
												label='Utilization'
												width={TableItemWidth5}
											/>
											<TableHeaderItem
												labelClass='BodyTwoLight'
												label=''
												width={TableItemWidth3}
											/>
										</TableHeader>
									</SkeletonLoading>
									<div className='ListingLoading scroll'>
										{client?.client_workflows?.length < 1 &&
										client?.workflow_my_tasks == null &&
										client?.meetings == null ? (
											<SkeletonLoading
												loading={loading}
												variant='text'
												width='100%'
												height={45}
											>
												<div className='TableRow w-100 d-flex align-items-center justify-content-center'>
													<h4 className='BodyTwoLight'>No Workflows</h4>
												</div>
											</SkeletonLoading>
										) : (
											client?.client_workflows?.map((workflow, index) => {
												const BudgetedHours = !workflow.budgeted_hours
													? 0
													: workflow.budgeted_hours;

												const LoggedHours = !workflow.consumed_time
													? 0
													: workflow.consumed_time / 3600000;

												const UtilizationPercentage = Math.floor(
													(LoggedHours / BudgetedHours) *
													100
												);

												return (
													<SkeletonLoading
														loading={loading}
														variant='text'
														width='100%'
														height={45}
														key={index}
													>
														<div className='new-table-row d-flex align-items-center'>
															<TableRowItem
																ClassName='text-left'
																textClass='BodyTwoLight'
																label={workflow.display_name}
																width={TableItemWidth5}
																input={false}
															/>

															<TableRowItem
																ClassName='text-left'
																textClass='BodyTwoLight'
																label={
																	!workflow.budgeted_hours
																		? convertDecimalToTime(0)
																		: convertDecimalToTime(
																				workflow.budgeted_hours,
																		  )
																}
																width={TableItemWidth5}
																input={false}
															/>
															<TableRowItem
																ClassName='text-left'
																textClass='BodyTwoLight'
																label={convertDecimalToTime(
																	workflow.consumed_time /
																		3600000,
																)}
																width={TableItemWidth5}
																input={false}
															/>
															<TableRowItem
																ClassName='text-left'
																textClass={`BodyTwoLight ${
																	UtilizationPercentage < 0
																		? 'red-text'
																		: ''
																}`}
																label={`${UtilizationPercentage} %`}
																width={TableItemWidth5}
																input={false}
																toolTipText='Budgeted Hours - Logged Hours'
															/>
															<TableRowItem
																className='text-right'
																label=''
																input={true}
																width={TableItemWidth3}
															>
																<div className='pointer'>
																	{selected &&
																	workflowToExpand ==
																		workflow?.client_workflow_id ? (
																		<span
																			onClick={() =>
																				setSelected(false)
																			}
																		>
																			<ArrowUp />
																		</span>
																	) : (
																		<span
																			onClick={() =>
																				expandTasks(
																					'workflows',
																					workflow?.client_workflow_id,
																					null,
																				)
																			}
																		>
																			<ArrowDown />
																		</span>
																	)}
																</div>
															</TableRowItem>
														</div>
														{selected &&
															workflowToExpand ==
																workflow?.client_workflow_id && (
																<ExpandableTimeReportsTasksViewV2
																	tasks={tasks}
																	isAllowed={isAllowed}
																	userId={id}
																/>
															)}
													</SkeletonLoading>
												);
											})
										)}
										{client?.workflow_my_tasks !== null && (
											<SkeletonLoading
												loading={loading}
												variant='text'
												width='100%'
												height={45}
											>
												<div className='new-table-row d-flex align-items-center'>
													<TableRowItem
														ClassName='text-left'
														textClass='BodyTwoLight'
														label='Ad hoc Tasks'
														width={TableItemWidth5}
														input={false}
													/>
													<TableRowItem
														ClassName='text-left'
														textClass='BodyTwoLight'
														label={convertDecimalToTime(
															client?.workflow_my_tasks
																?.budgeted_hours,
														)}
														width={TableItemWidth5}
														input={false}
													/>
													<TableRowItem
														ClassName='text-left'
														textClass='BodyTwoLight'
														label={convertDecimalToTime(
															client?.workflow_my_tasks
																?.consumed_time / 3600000,
														)}
														width={TableItemWidth5}
														input={false}
													/>
													<TableRowItem
														ClassName='text-left'
														textClass={`BodyTwoLight ${
															adHocClientPercentage < 0
																? 'red-text'
																: ''
														}`}
														label={`${adHocClientPercentage} %`}
														width={TableItemWidth5}
														input={false}
														toolTipText='Budgeted Hours - Logged Hours'
													/>
													<TableRowItem
														className='text-right'
														label=''
														input={true}
														width={TableItemWidth3}
													>
														<div className='pointer'>
															{selected &&
															clientToEdit == client?.client_id &&
															workflowToExpand ==
																'workflow_my_tasks' ? (
																<span
																	onClick={() =>
																		setSelected(false)
																	}
																>
																	<ArrowUp />
																</span>
															) : (
																<span
																	onClick={() =>
																		expandTasks(
																			'workflow_my_tasks',
																			null,
																			client?.client_id,
																		)
																	}
																>
																	<ArrowDown />
																</span>
															)}
														</div>
													</TableRowItem>
												</div>
												{selected &&
													clientToEdit == client?.client_id &&
													workflowToExpand == 'workflow_my_tasks' && (
														<ExpandableTimeReportsTasksViewV2
															tasks={tasks}
															isAllowed={isAllowed}
															userId={id}
														/>
													)}
											</SkeletonLoading>
										)}
										{client?.meetings !== null && (
											<SkeletonLoading
												loading={loading}
												variant='text'
												width='100%'
												height={45}
											>
												<div className='new-table-row d-flex align-items-center'>
													<TableRowItem
														ClassName='text-left'
														textClass='BodyTwoLight'
														label='Meetings'
														width={TableItemWidth5}
														input={false}
													/>
													<TableRowItem
														ClassName='text-left'
														textClass='BodyTwoLight'
														label={convertDecimalToTime(
															client?.meetings?.budgeted_hours,
														)}
														width={TableItemWidth5}
														input={false}
													/>
													<TableRowItem
														ClassName='text-left'
														textClass='BodyTwoLight'
														label={convertDecimalToTime(
															client?.meetings?.consumed_time /
																3600000,
														)}
														width={TableItemWidth5}
														input={false}
													/>
													<TableRowItem
														ClassName='text-left'
														textClass={`BodyTwoLight ${
															clientMeetingsPercentage < 0
																? 'red-text'
																: ''
														}`}
														label={`${clientMeetingsPercentage} %`}
														width={TableItemWidth5}
														input={false}
														toolTipText='Budgeted Hours - Logged Hours'
													/>
													<TableRowItem
														className='text-right'
														label=''
														input={true}
														width={TableItemWidth3}
													>
														<div className='pointer'>
															{selected &&
															workflowToExpand == 'meetings' &&
															clientToEdit == client?.client_id ? (
																<span
																	onClick={() =>
																		setSelected(false)
																	}
																>
																	<ArrowUp />
																</span>
															) : (
																<span
																	onClick={() =>
																		expandTasks(
																			'meetings',
																			null,
																			client?.client_id,
																		)
																	}
																>
																	<ArrowDown />
																</span>
															)}
														</div>
													</TableRowItem>
												</div>
												{selected &&
													workflowToExpand == 'meetings' &&
													clientToEdit == client?.client_id && (
														<ExpandableTimeReportsTasksViewV2
															tasks={tasks}
															isAllowed={isAllowed}
															userId={id}
														/>
													)}
											</SkeletonLoading>
										)}
										{(client?.client_workflows?.length > 0 ||
											client?.workflow_my_tasks !== null ||
											client?.meetings !== null) && (
											<SkeletonLoading
												loading={loading}
												variant='text'
												width='100%'
												height={45}
											>
												<div className='new-table-row d-flex align-items-center'>
													<TableRowItem
														ClassName='text-left'
														textClass='BodyTwoLight'
														width={TableItemWidth5}
														input={false}
													/>
													<TableRowItem
														ClassName='text-left'
														textClass='BodyTwoBold'
														label={
															totalValues?.find(
																(item) =>
																	item.clientId ===
																	client.client_id,
															)?.totalBudgetedHours
														}
														width={TableItemWidth5}
														input={false}
													/>
													<TableRowItem
														ClassName='text-left'
														textClass='BodyTwoBold'
														label={
															totalValues?.find(
																(item) =>
																	item.clientId ===
																	client.client_id,
															)?.totalConsumedTime
														}
														width={TableItemWidth5}
														input={false}
													/>
													<TableRowItem
														ClassName='text-left'
														textClass={`BodyTwoBold ${
															totalValues?.find(
																(item) =>
																	item.clientId ===
																	client.client_id,
															)?.totalUtilizationHours < 0
																? 'red-text'
																: ''
														}`}
														label={
															`${totalValues?.find(
																(item) =>
																	item.clientId ===
																	client.client_id,
															)?.totalUtilizationHours} %`
														}
														width={TableItemWidth5}
														input={false}
													/>
													<TableRowItem
														ClassName='text-left'
														textClass='BodyTwoLight'
														width={TableItemWidth3}
														input={false}
													/>
												</div>
											</SkeletonLoading>
										)}
									</div>
								</div>
							</div>
						</div>
					);
				})}
		</div>
	);
}

export default TimeReportsUserInfoV2;
