/* eslint-disable no-mixed-spaces-and-tabs */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import CommentsSection from '../sub_components/CommentsSection';
import moment from 'moment';
import { Avatar, CircularProgress, Tooltip } from '@mui/material';
import { Mention, MentionsInput } from 'react-mentions';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from '../../redux/actions/dashboard/workspaces-actions';
import { ReactComponent as PostIcon } from '../../assets/icons/PaperPlaneRight.svg';
import { ReactComponent as ArrowsOut } from '../../assets/icons/ArrowsOutSimpleColor.svg';
import { ReactComponent as ArrowsIn } from '../../assets/icons/ArrowsInSimpleColor.svg';
import { ReactComponent as DownloadIcon } from '../../assets/icons/DownloadSimple.svg';
import { ReactComponent as ProgressDone } from '../../assets/icons/CheckCircle.svg';
import { ReactComponent as UploadFailed } from '../../assets/icons/XCircleColor.svg';
import * as clientActions from '../../redux/actions/client-actions';
import { ReactComponent as EmojiIcon } from '../../assets/icons/emoji.svg';
import { ReactComponent as UplaodIcon } from '../../assets/icons/CloudArrowUp.svg';
import { ReactComponent as Cross } from '../../assets/icons/close-primary.svg';
import { ReactComponent as PaperClip } from '../../assets/icons/Paperclip.svg';
import { FileIcon, defaultStyles } from 'react-file-icon';
import EmojiPicker from 'emoji-picker-react';
import ChangeFolderTaskModal from '../Modal/ChangeFolderTaskModal';
import useOutsideClick from '../../hooks/useOutsideClick';
import FileExistModal from '../Modal/FileExistModal';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { generateUniqueFiles } from '../../utilities/utilities';

function TaskModalComments({
	assignee_user_id,
	id,
	setExpandComment,
	expandComment,
	folderId,
	isAdhoc,
	tabs,
	modalIsOpen,
	toggleModal,
	ModalName,
	handleModalOpen,
	selectedFiles,
	setSelectedFiles,
	files,
	setFiles,
	selectedItem,
	displayName,
	loading,
}) {
	const dispatch = useDispatch();
	const commentSectionRef = useRef();
	const mentionInputRef = useRef();
	const emojiPickerRef = useRef(null);
	const fileInputRef = useRef(null);
	const [showDropBox, setShowDropBox] = useState(false);
	const [defaultFolder, setDefaultFolder] = useState(null);
	const [isDragOver, setIsDragOver] = useState(false);
	const [actionLoading, setActionLoading] = useState(false);
	// const loading = useSelector((state) => state.workSpaceReducer.resourceLoading);
	const [showEmojiPicker, setShowEmojiPicker] = useState(false);
	const [alertModal, setAlertModal] = useState(false);
	const comment = useSelector((state) => state.workSpaceReducer.comment);
	const comments = useSelector((state) => state.workSpaceReducer.taskComments);
	const userDetails = useSelector((state) => state.authReducer.userDetails);
	const user = useSelector((state) => state.authReducer.user);
	const allTenantUsers = useSelector((state) =>
		state.userReducer.allTenantUsers?.map((user) => {
			return {
				id: user?.id,
				display: user?.full_name,
			};
		}),
	);
	const upload_progress = useSelector((state) => state.clientReducer.upload_progress);
	const upload_status = useSelector((state) => state.clientReducer.upload_status);
	const folderDataTaskModal = useSelector((state) => state.clientReducer.folderData);
	const inputRef = useRef(null);
	const commentRef = useRef(null);

	const mentionStyle = {
		width: '93%',
		// height: '30px',
		height: 'inherit',
		paddingLeft: '5px',
		backgroundColor: 'white',
		zIndex: '9999',
		// border: '1px solid #FED0A5',
		// borderRadius: '4px',
		control: {
			backgroundColor: '#fff',
			fontSize: 12,
			fontWeight: 'normal',
		},

		highlighter: {
			overflow: 'hidden',
		},

		input: {
			margin: 0,
		},

		'&singleLine': {
			control: {
				display: 'inline-block',

				width: 130,
			},

			highlighter: {
				padding: 1,
				border: 'none',
			},

			input: {
				padding: 1,
				color: '#007bff',
				border: 'none',
			},
		},

		'&multiLine': {
			control: {
				fontFamily: 'monospace',
				height: 30,
				// borderTop: '1px solid #FED0A5',
				// borderBottom: '1px solid #FED0A5',
			},

			highlighter: {
				padding: 9,
				height: 30,
			},

			input: {
				padding: 9,
				outline: 0,
				border: 0,
				height: 'inherit',
				left: 'unset',
				// overflow: expandComment ? 'auto' : 'hidden',
				overflow: 'hidden',
				position: 'absolute',
				bottom: 14,
				lineHeight: 1,
			},
		},

		suggestions: {
			top: 'unset',
			bottom: '25px',
			zIndex: 99,
			height: '100px',
			overflow: 'auto',
			list: {
				backgroundColor: 'white',
				border: '1px solid rgba(0,0,0,0.15)',
				fontSize: 10,
			},

			item: {
				padding: '5px 15px',
				borderBottom: '1px solid rgba(0,0,0,0.15)',

				'&focused': {
					backgroundColor: '#cee4e5',
				},
			},
		},
	};

	const handleAlertModal = () => {
		setAlertModal(!alertModal);
	};
	const onCancel = () => {
		toggleModal();
		setActionLoading(false);
	};
	const handleUploadChange = async (e) => {
		setActionLoading(true);
		e.preventDefault();
		e.stopPropagation();
		setDefaultFolder(selectedItem);
		onCancel();
		dispatch(Actions.setSnackBarIsOpen(true, true, 'Folder changed successfully'));
	};

	const sortedUsers = useMemo(() => {
		const assigneeUser = allTenantUsers.find((user) => user?.id === assignee_user_id);
		// Non Assignee users sorted
		const nonAssigneeUsers = allTenantUsers
			.filter((user) => user?.id !== assignee_user_id)
			.sort((a, b) => (a.display || '').localeCompare(b.display || ''));

		return assigneeUser
			? [
					{ ...assigneeUser, id: assigneeUser.id.toString() },
					...nonAssigneeUsers.map((obj) => ({ ...obj, id: obj.id.toString() })),
			  ]
			: nonAssigneeUsers?.map((obj) => ({ ...obj, id: obj.id.toString() }));
	}, [allTenantUsers, assignee_user_id]);

	const handleDragLeave = (e) => {
		e.preventDefault();
		e.stopPropagation();

		setIsDragOver(false);
		setShowDropBox(false);
		commentSectionRef.current = true;
	};
	const postComment = (attachedFiles) => {
		const regex = /@\[.+?\]\(.+?\)/gm;
		const idRegex = /\(.+?\)/g;
		const matches = comment.match(regex);
		const arr = [];
		matches &&
			matches.forEach((m) => {
				const id = m.match(idRegex)[0].replace('(', '').replace(')', '');
				arr.push(JSON.parse(id));
			});

		dispatch(Actions.addComment(id, user?.userId, comment, attachedFiles));
	};

	const OnCommentPost = async () => {
		if (comment.length > 0 || selectedFiles?.length > 0) {
			const updatedFiles = selectedFiles.map((file) => ({
				display_name: file?.file?.name,
				type: file?.file?.type,
			}));
			dispatch(
				Actions.updateTaskComments({
					comment_id: moment().format(),
					attachment: updatedFiles,
					message: comment,
					task_id: id,
					user_id: user?.userId,
					user_profile: {
						full_name: userDetails?.full_name,
						image_url: userDetails?.image_url,
					},
				}),
			);
			dispatch(Actions.resetComment());
			setFiles([]);

			let attachedFiles = [];

			if (selectedFiles?.length > 0) {
				try {
					const uploadedFiles = await uploadFiles();
					attachedFiles = uploadedFiles.map((file) => ({
						document_id: file.id,
						display_name: file.name,
						type: file.type,
						url: file.url,
					}));
				} catch (error) {
					console.error('Error uploading files:', error);
				}
			}
			postComment(attachedFiles);
			setSelectedFiles([]);
		} else {
			console.log('No comment or files to post');
		}
	};

	const uploadFiles = async () => {
		const successfullyUploadedFiles = [];

		try {
			const uploadPromises = selectedFiles.map(async (file) => {
				const defaultFolderId = defaultFolder ? defaultFolder : folderId;
				const formData = new FormData();
				formData.append('file', file?.file);
				formData.append('parent_folder_id', defaultFolderId);

				try {
					const data = await dispatch(clientActions.createFile(formData, file?.file));
					dispatch(clientActions.createFileReducer(data));
					successfullyUploadedFiles.push(data);
					return data;
				} catch (error) {
					if (error.message === 'Document name already exists') {
						handleAlertModal && handleAlertModal();
					} else {
						dispatch(Actions.setSnackBarIsOpen(false, true, 'File upload failed'));
					}
					throw error;
				}
			});

			await Promise.allSettled(uploadPromises);
			return successfullyUploadedFiles;
		} catch (error) {
			console.error('Error uploading files:', error);
			throw error;
		} finally {
			setIsDragOver(false);
			setShowDropBox(false);
		}
	};

	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			OnCommentPost();
		}
	};

	const handleCommentChange = (e, newValue) => {
		mentionInputRef?.current?.clearSuggestions();
		dispatch(Actions.handleCommentChange(newValue));
	};

	const handleDragEnter = useCallback(
		(e) => {
			e.preventDefault();
			e.stopPropagation();
			setIsDragOver(true);
			commentSectionRef.current = false;
		},
		[isDragOver],
	);

	// const handleDragEnd = (e) => {
	// 	e.preventDefault();
	// 	e.stopPropagation();
	// 	setIsDragOver(false);
	// };

    const handleFileChange = useCallback((event) => {
        event.preventDefault();
        event.stopPropagation();
        const filesFromInput = event.target.files;
        const filesFromDrop = event.dataTransfer?.files;
        const uploadedFiles = filesFromDrop ? Array.from(filesFromDrop) : Array.from(filesFromInput);
      
        const uniqueFiles = generateUniqueFiles(uploadedFiles);
        const filesWithIdAndUniqueNames = uniqueFiles?.map((file, index) => ({
          id: (selectedFiles?.length || 0) + index,
          file: file,
        }));
      
        setSelectedFiles(prevFiles => [...prevFiles, ...filesWithIdAndUniqueNames]);
        setFiles(prevFiles => [...prevFiles, ...filesWithIdAndUniqueNames]);
      
        setIsDragOver(false);
        setShowDropBox(false);
      }, [selectedFiles]);

	const handleDeleteImage = (fileId) => {
		setSelectedFiles((prevFiles) => prevFiles.filter((file) => file.id !== fileId));
		setFiles((prevFiles) => prevFiles.filter((file) => file.id !== fileId));
		dispatch(Actions.setSnackBarIsOpen(false, true, 'Document deleted succesfully'));
	};

	const handleDownload = (item) => {
		const fileUrl = item.url;
		const fileName = item.name;

		const link = document.createElement('a');
		link.href = fileUrl;
		link.setAttribute('download', fileName);
		document.body.appendChild(link);

		link.click();
		link.remove();
	};

	const handleEmojiClick = (emojiObject) => {
		dispatch(Actions.handleCommentChange(comment + emojiObject.emoji));
		setShowEmojiPicker(!showEmojiPicker);
	};

	const scrollToBottom = () => {
		if (commentRef.current) {
			commentRef.current.scrollTop = commentRef.current.scrollHeight;
		}
	};

	useOutsideClick(emojiPickerRef, () => setShowEmojiPicker(!showEmojiPicker));

	useEffect(() => {
		scrollToBottom();
	}, [comments.length]);

	return (
		<>
			<div
				id='label-file-upload'
				htmlFor='input-file-upload'
				style={{
					position: 'relative',
					zIndex: '10',
					padding: 0,
				}}
				className='task-modal-container'
				draggable={false}
				onDragOver={(e) => {
					e.preventDefault();
					setShowDropBox(true);
				}}
			>
				{showDropBox && !isAdhoc && (
					<div
						className={`task-modal-container  ${isDragOver ? 'drag-over-style' : ''}`}
						style={{
							top: 0,
							left: 0,
							width: '100%',
							height: '100%',
							position: 'absolute',
							zIndex: 999,
							backgroundColor: 'transparent',
						}}
						onDragEnter={(e) => {
							handleDragEnter(e);
						}}
						onDragLeave={(e) => {
							handleDragLeave(e);
						}}
						onDrop={(e) => handleFileChange(e)}
					></div>
				)}
				<div
					className={'task-modal-container fixed-title'}
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
						backgroundColor: '#FCFDFD',
						position: 'relative',
						// zIndex: '104',
					}}
				>
					<div
						className='d-flex justify-content-between'
						style={{ position: 'relative', zIndex: '103' }}
					>
						<div
							style={{
								width: '100%',
								display: 'flex',
								justifyContent: 'space-between',
							}}
						>
							<span className='HeadlineThreeBook'>Comments</span>
							<span
								className='pointer'
								style={{
									transform: 'rotate(-45deg)',
									color: '#fb7a03',
								}}
								onClick={() => {
									setExpandComment(!expandComment);
								}}
							>
								{expandComment ? <ArrowsIn /> : <ArrowsOut />}
							</span>
						</div>
					</div>
					{loading ? (
						<div className='h-100 d-flex justify-content-center align-items-center'>
							<CircularProgress
								size={25}
								sx={{
									color: '#fb7a03',
								}}
								disableShrink
							/>
						</div>
					) : (
						<div
							className='task-modal-children d-flex flex-column h-100 position-relative justify-content-between'
							style={{ flex: 1 }}
						>
							<input
								ref={inputRef}
								type='file'
								id='input-file-upload'
								multiple={true}
								disabled={true}
								hidden
								className='task-comments-container'
								// style={{ background: 'red' }}
							/>

							<div
								className='comments d-flex flex-column w-100'
								style={{
									overflowY: 'auto',
									maxHeight: expandComment
										? 'calc(100% - 148px)'
										: 'calc(100% - 45px)',
									flex: 1,
									position: 'absolute',
								}}
								ref={commentRef}
							>
								{comments &&
									comments?.map((comment) => {
										const isUploadingComment = comment.comment_id !== undefined;
										return (
											<div
												key={comment.id}
												className='d-flex  align-items-center'
											>
												<div
													className='d-flex w-100 justify-content-between'
													style={{
														marginBottom: '5px',
													}}
												>
													<div className='d-flex align-items-start w-100'>
														<div className='pr-3'>
															<Avatar
																data-toggle='tooltip'
																data-placement='top'
																title={
																	comment?.user_profile?.full_name
																		.charAt(0)
																		.toUpperCase() +
																	comment?.user_profile?.full_name.slice(
																		1,
																	)
																}
																sx={{
																	bgcolor: `#FEF2E7`,
																	color: `#333`,
																	width: '30px',
																	height: '30px',
																	marginTop: '4px',
																}}
																alt='User'
																src={
																	comment?.user_profile?.image_url
																}
																srcSet={null}
															/>
														</div>
														<div className='d-flex flex-column align-items-start w-100'>
															<div
																style={{
																	marginBottom: '-10px',
																	wordBreak: 'break-word',
																}}
																className='text-left'
															>
																<span className='BodyTwoBold'>
																	{
																		comment?.user_profile
																			?.full_name
																	}
																</span>
																<span
																	className='BodyTwoLight d-flex'
																	style={{
																		opacity: isUploadingComment
																			? 0.5
																			: 1,
																	}}
																>
																	<CommentsSection
																		comment={comment.message}
																	/>
																</span>
																{isUploadingComment && (
																	<span
																		className='BodyThreeLight d-flex py-1'
																		style={{
																			color: '#696F6C',
																		}}
																	>
																		Uploading...
																	</span>
																)}
															</div>
															<div className='container'>
																<div className='row'>
																	{comment?.attachment
																		? comment?.attachment?.map(
																				(item, index) => {
																					return (
																						<div
																							key={
																								index
																							}
																							className='col'
																							style={{
																								display:
																									'flex',
																								alignItems:
																									'center',
																								minWidth:
																									'180px',
																								maxWidth:
																									'220px',
																								padding:
																									'8px',
																								borderRadius:
																									'4px',
																								height: '34px',
																								background:
																									'#F7F9FA',
																								justifyContent:
																									'space-between',
																								marginTop:
																									'10px',
																								marginRight:
																									'6px',
																							}}
																						>
																							<div
																								className='d-flex align-items-center justify-content-center'
																								style={{
																									opacity:
																										isUploadingComment
																											? 0.5
																											: 1,
																								}}
																							>
																								<span
																									style={{
																										marginRight:
																											'4px',
																										width: '14px',
																									}}
																								>
																									<FileIcon
																										extension={
																											item.type
																										}
																										{...defaultStyles[
																											`${item.type}`
																										]}
																										size='14px'
																									/>
																								</span>{' '}
																								<Tooltip
																									title={
																										item?.display_name
																									}
																								>
																									<span className='BodyTwoLight pr-1'>
																										{item
																											?.display_name
																											?.length >
																										18
																											? item?.display_name.substring(
																													0,
																													18,
																											  ) +
																											  '...'
																											: item?.display_name}
																									</span>
																								</Tooltip>
																							</div>
																							{!isUploadingComment && (
																								<div
																									onClick={() =>
																										handleDownload(
																											item,
																										)
																									}
																									className='pointer'
																								>
																									<DownloadIcon />
																								</div>
																							)}
																							{isUploadingComment && (
																								<div className='d-flex'>
																									{upload_status[
																										item
																											?.display_name
																									]?.status ===
																									1 ? (
																										<ProgressDone />
																									) : upload_status[
																											item
																												?.display_name
																									  ]?.status ===
																									  -1 ? (
																										<UploadFailed />
																									) : (
																										<div className='d-flex'>
																											<div>
																												<span className='BodyTwoLight'>{`${
																													upload_progress[
																														item
																															?.display_name
																													] ||
																													0
																												}%`}</span>
																											</div>
																											<div
																												className='ml-1 mb-2'
																												style={{
																													width: '16px',
																													height: '16px',
																												}}
																											>
																												<CircularProgressbar
																													value={
																														upload_progress[
																															item
																																?.display_name
																														] ||
																														0
																													}
																													styles={buildStyles(
																														{
																															textColor:
																																'#000',
																															pathColor:
																																'#FB7A03',
																															trailColor:
																																'#FED0A5',
																														},
																													)}
																												/>
																											</div>
																										</div>
																									)}
																								</div>
																							)}
																						</div>
																					);
																				},
																		  )
																		: null}
																</div>
															</div>
															{!isUploadingComment && (
																<div className='pt-1'>
																	<span
																		className='BodyThreeLight'
																		style={{
																			color: '#7C7C7C',
																		}}
																	>
																		{moment(
																			comment.created_at,
																		).fromNow()}
																	</span>
																</div>
															)}
														</div>
													</div>
												</div>
											</div>
										);
									})}
							</div>
							<div
								style={{
									width: '100%',
									// height: '32px',
									backgroundColor: '#FFFFFF',
									// paddingBlock: '8px',
									// position: 'relative',
									bottom: '0px',
									// marginBottom:'-12px'
								}}
								className='d-flex align-items-start position-absolute'
							>
								<div>
									<Avatar
										sx={{
											bgcolor: `#FEF2E7`,
											color: `#333`,
											width: '24px',
											height: '24px',
										}}
										alt='User'
										src={userDetails?.image_url}
										srcSet={null}
									/>
								</div>
								<div
									className='comment-field pl-2 '
									style={{
										wordBreak: 'break-word',
										paddingRight: '15px',
										position: 'relative',
										// maxHeight: expandComment ? '150px' : 'inherit',
										height: 'inherit',
										overflowY: 'visible',
									}}
								>
									<div
										className='comment-scroll'
										// style={{ position: 'relative', overflowY: 'auto' }}
									>
										<MentionsInput
											value={comment}
											className='BodyTwoLight d-flex align-items-center'
											onKeyDown={
												comment?.trim()?.length == 0
													? () => {}
													: handleKeyDown
											}
											placeholder={
												isAdhoc
													? 'Type a comment'
													: `Type a comment or drop files here`
											}
											style={mentionStyle}
											onChange={handleCommentChange}
											ref={mentionInputRef}
										>
											<Mention
												trigger='@'
												data={sortedUsers}
												style={{
													backgroundColor: 'rgba(251, 122, 3, 0.15)',
													marginBottom: '20px',
												}}
												renderSuggestion={(
													entry,
													search,
													highlightedDisplay,
													index,
												) => {
													const isAssignee = entry.id == assignee_user_id;

													const assigneeLabel =
														isAssignee && index == 0 ? (
															<em>(Assignee)</em>
														) : null;

													return (
														<span>
															{highlightedDisplay}
															{assigneeLabel}
														</span>
													);
												}}
												appendSpaceOnAdd
											/>
										</MentionsInput>
									</div>

									<div style={{ overflowY: 'auto', width: '100%' }}>
										{files?.length > 0 && (
											<div className='d-flex'>
												<span className='BodyThreeBold'>
													{files?.length > 1
														? 'Attachments'
														: 'Attachment'}
												</span>
											</div>
										)}
										<div
											style={{
												// overflow: 'hidden',
												maxHeight: '50px',
												// position: 'absolute',
											}}
										>
											<div>
												{files?.length > 0
													? files?.map((file) => {
															return (
																<div
																	key={file.id}
																	className='d-flex flex-column'
																>
																	<div
																		style={{
																			display: 'flex',
																			alignItems: 'center',
																			width: '240px',
																			padding: '8px',
																			borderRadius: '4px',
																			height: '34px',
																			background: '#F7F9FA',
																			justifyContent:
																				'space-between',
																			marginBottom: '5px',
																		}}
																	>
																		<div className='d-flex align-items-center justify-content-center'>
																			<span
																				style={{
																					marginRight:
																						'4px',
																					width: '14px',
																				}}
																			>
																				<FileIcon
																					extension={
																						file.file
																							.type
																					}
																					{...defaultStyles[
																						`${file.file.type}`
																					]}
																					size='14px'
																				/>
																			</span>{' '}
																			<span className='BodyTwoLight pr-1'>
																				{file?.file?.name
																					?.length > 20
																					? file?.file?.name.substring(
																							0,
																							20,
																					  ) + '...'
																					: file?.file
																							?.name}
																			</span>
																		</div>
																		<div className='circular  d-flex justify-content-between align-items-center'>
																			<div
																				onClick={() =>
																					handleDeleteImage(
																						file?.id,
																					)
																				}
																				className='pointer'
																			>
																				<Cross
																					width={16}
																					height={16}
																				/>
																			</div>
																		</div>
																	</div>
																</div>
															);
													  })
													: null}
												{files?.length > 0 && (
													<div className='d-flex pl-2 pt-1'>
														<span
															className='BodyThreeLight'
															style={{
																color: '#696F6C',
															}}
														>
															{defaultFolder !== null
																? `Uploaded to ${
																		folderDataTaskModal?.filter(
																			(folder) =>
																				folder.id ==
																				defaultFolder,
																		)[0]?.name
																  }`
																: `Upload to ${displayName}`}
														</span>
														<span
															className='BodyThreeLight ml-2'
															style={{
																color: '#FB7A03',
																cursor: 'pointer',
															}}
															onClick={handleModalOpen(
																'CHANGE_FOLDER_MODAL',
															)}
														>
															Change Folder
														</span>
													</div>
												)}
											</div>
										</div>
									</div>

									{ModalName == 'CHANGE_FOLDER_MODAL' && (
										<ChangeFolderTaskModal
											modalIsOpen={modalIsOpen}
											toggleModal={toggleModal}
											handleUploadChange={handleUploadChange}
											selectedItem={selectedItem}
											tabs={tabs}
											actionLoading={actionLoading}
										/>
									)}

									<input
										type='file'
										style={{ display: 'none' }}
										multiple
										ref={fileInputRef}
										onChange={handleFileChange}
									/>
									{!isAdhoc && (
										<div
											className='paper-icon pointer'
											onClick={() => fileInputRef.current.click()}
										>
											<PaperClip />
										</div>
									)}
									<span
										role='img'
										aria-label='emoji'
										className='emoji-icon pointer'
										onClick={() => setShowEmojiPicker(!showEmojiPicker)}
									>
										<EmojiIcon />
									</span>
								</div>

								<div className='pointer'>
									{
										<PostIcon
											style={{
												opacity:
													selectedFiles?.length > 0 ||
													comment?.trim()?.length > 0
														? 1
														: 0.5,
											}}
											onClick={
												selectedFiles?.length > 0 ||
												comment?.trim()?.length > 0
													? OnCommentPost
													: () => {}
											}
										/>
									}
								</div>
							</div>
						</div>
					)}
				</div>
				{isDragOver && (
					<div
						style={{
							top: 0,
							left: 0,
							width: '100%',
							height: '100%',
							position: 'absolute',
							zIndex: 8,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							opacity: '50%',
						}}
					>
						<div>
							<UplaodIcon
								style={{
									width: '40px',
									height: '40px',
								}}
							/>
						</div>
						<span style={{ color: '#696f6c' }}>Upload a file here</span>
					</div>
				)}
			</div>
			{showEmojiPicker && (
				<div className='emoji-picker' ref={emojiPickerRef}>
					<EmojiPicker
						// width='100%'
						onEmojiClick={handleEmojiClick}
						lazyLoadEmojis={true}
						searchDisabled={true}
					/>
				</div>
			)}
			{alertModal && (
				<FileExistModal modalIsOpen={alertModal} toggleModal={handleAlertModal} />
			)}
		</>
	);
}

export default TaskModalComments;
