import React, { memo } from 'react';

function SelectList({
    options,
    value,
    placeholder,
    ...props
}) {
    return (
        <select
        className={`PickList d-flex flex-column w-100 ${value?.value ? 'text-white' : 'not-selected'}`}
        {...props}
        >
            <option value='' className='valid-options'>{placeholder ? placeholder : ''}</option>
            {options?.map((opt, i) => {
                return (
                    <option
                        className='valid-options'
                        selected={opt?.value == value?.value}
                        key={i}
                        value={opt?.value}
                    >
                        {opt?.label}
                    </option>
                );
            })}
        </select>
    );
}

export default memo(SelectList);
