import { useCallback, useEffect, useState } from 'react';
import AccountProfile from '../components/AccountSettings/AccountProfile';
import AccountSettingsHeader from '../components/AccountSettings/AccountSettingsHeader';
import ChangePassword from '../components/AccountSettings/ChangePassword';
import ConnectedApps from '../components/AccountSettings/ConnectedApps';
import Leaves from '../components/AccountSettings/Leaves';
import UserBilling from '../components/AccountSettings/UserBilling';
import Restricted from '../components/Permissions/Restricted';
import Sidebar from '../components/sidebar/SideBar';
import { Member } from '../utilities/utilities';
import NewSidebarItem from '../components/sidebar/NewSidebarItem';
import PerosnalDetails from '../components/UserManagement/PerosnalDetails';
import AccountTeams from '../components/AccountSettings/AccountTeams';
import * as userActions from '../redux/actions/user-management.actions';
import * as profileActions from '../redux/actions/company-profile';
import * as teamActions from '../redux/actions/team-management.actions';
import { useDispatch, useSelector } from 'react-redux';
import ProfileBar from '../components/AccountSettings/ProfileBar';
import AlertBar from '../components/sub_components/AlertBar';
import posthog from 'posthog-js';

export default function AccountSettingsComponent() {
	const dispatch = useDispatch();

	const user = useSelector((state) => state.authReducer.user);
	const list = useSelector((state) => state.userReducer.users);
	const Profile = useSelector((state) => state.userReducer.userProfile); //User Profile

	useEffect(() => {
		dispatch(userActions.getUsers());
		dispatch(teamActions.getTeams());
		list.length > 0 && getData();
	}, [list.length]);

	const getData = (List) => {
		let role = List?.find((u) => u.user_id == user?.userId);
		role = role?.role_name;
		dispatch(userActions.getUserProfile(user?.userId, role));
	};

	useEffect(() => {
		dispatch(profileActions.getTenantProfile(user.tenantIds[0]));
		getData();
	}, [dispatch, user]);

	const [activeTab, setActiveTab] = useState('User');

	const tabChangeHandler = useCallback(
		(tab) => () => {
			setActiveTab(tab);
		},
		[],
	);

	let sidemenu = [
		{ name: 'User Profile', sectionId: 'User' },

		{ name: 'Teams & Roles', sectionId: 'Teams' },
        
		...(posthog.isFeatureEnabled('leave_requests')
			? [{ name: 'Leaves', sectionId: 'Leaves' }]
			: []),

		{ name: 'Security', sectionId: 'Security' },
	];

	if (user?.userRole?.id == Member.ownerId) {
		sidemenu = [...sidemenu, { name: 'User Billing', sectionId: 'user-billing' }];
	}

	useEffect(() => {
		return () => {
			dispatch(userActions.resetUserProfile());
			dispatch(userActions.setSpecificUserBilling([]));
		};
	}, []);

	return (
		<div className='CompanyProfile'>
			<Sidebar title='Profile Settings'>
				<div className='new-sidebar-content-wrapper'>
					<div className='User-filters'>
						<div>
							<div className='new-side-menu BodyTwoLight-bold m-0 d-flex flex-column align-items-start'>
								{sidemenu?.map((item, index) => {
									const prevOfActive =
										sidemenu[index + 1]?.sectionId == activeTab;
									return (
										<>
											{index == 0 && (
												<div
													style={{ padding: '8px' }}
													className={`w-100 main-sidebar-item ${
														sidemenu[0].sectionId == activeTab
															? 'prev'
															: ''
													}`}
												></div>
											)}
											<NewSidebarItem
												key={item.sectionId}
												uniqueId={item.sectionId}
												label={item.name}
												isActive={activeTab == item.sectionId}
												setActiveTab={tabChangeHandler}
											/>
											<div
												style={{ padding: '8px' }}
												className={`w-100 main-sidebar-item ${
													prevOfActive ? 'prev' : ''
												}`}
											></div>
										</>
									);
								})}
							</div>
						</div>
					</div>
				</div>
			</Sidebar>
			<div
				style={{
					width: 'calc(100vw - 260px)',
					height: '100vh',
				}}
			>
				<AlertBar />
				<div className='UserProfile-main user-profile-main'>
					<div className='UserProfile-content'>
						<div className='h-25'></div>
						<ProfileBar
							BarText={`${Profile?.full_name || ''}`}
							accountSettings
							backToList={() => history.push('/users')}
						/>
						{activeTab == 'User' && <PerosnalDetails />}
						{activeTab == 'Teams' && <AccountTeams />}
						{activeTab === 'Leaves' && <Leaves />}
						{activeTab === 'Security' && <ChangePassword />}
						{activeTab === 'user-billing' && <UserBilling userData={Profile} />}
						{/* {activeTab === 'connected-apps' && <ConnectedApps />} */}
					</div>
				</div>
			</div>
		</div>
	);
}
