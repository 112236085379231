import SubmitButtonsField from '../Buttons/SubmitButtonsField';

const ArchiveUserSuccessfullyModal = ({ onClose }) => {
	return (
		<div className='ModalRoot fadeIn'>
			<div className='Content'>
				<div style={{ height: '5rem' }} className='Header px-4'>
					<h1 className='HeadlineTwoBold'>User Unarchived</h1>
				</div>
				<div className='Body'>
					<p className='BodyTwoLight  w-100 px-4'>
						You have successfully unarchived the user. An email notification
						will be sent to the user to inform them of their accounts
						reactivation.
					</p>

					<div className='VerticalSpacer bg-white py-3  px-4 rounded-bottom'>
						<SubmitButtonsField child={true}>
							<button
								id='Element'
								type='button'
								className='secondary'
								onClick={onClose}
							>
								Ok
							</button>
						</SubmitButtonsField>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ArchiveUserSuccessfullyModal;
