import React from 'react';
import { ReactComponent as ZapIntegration } from '../../assets/icons/integration.svg';

function ZapierIntegrationTopBar({ Title }) {
	return (
		<div className='Zapier-Integration-TopBar '>
			<div className='d-flex flex-row align-items-center'>
				<div className='d-flex flex-row justify-content-between' style={{ width: '184px' }}>
					<ZapIntegration />
					<h6 className='HeadlineTwoBold'>{Title}</h6>
				</div>
			</div>
		</div>
	);
}

export default ZapierIntegrationTopBar;
