import React from 'react';
import NewWorkflowCard from './NewWorkflowCard';

export default function PreBuiltLibrary({
	prebuiltWorkflows,
	setActiveTab,
	handleCheckBoxes,
	isChecked,
	moveWorkflowsToDraft,
}) {
	return (
		<>
			{prebuiltWorkflows?.length > 0 ? (
				prebuiltWorkflows?.map((category, index) => {
					return (
						<div key={index} style={{ marginTop: '24px' }}>
							<div
								className='workflow-categories-header'
								style={{ marginBottom: '18px' }}
							>
								<span className='HeadlineThreeBold'>{category?.name}</span>
							</div>
							<div className='workflow-categories-body container-fluid'>
								<div className='row w-100 '>
									{category?.workflow_libraries?.length > 0 &&
										category?.workflow_libraries?.map(
											(workflow, innerIndex) => {
												return (
													<NewWorkflowCard
														key={innerIndex}
														workflow={workflow}
														handleCheckBoxes={handleCheckBoxes}
														isChecked={isChecked}
                                                        moveWorkflowsToDraft={moveWorkflowsToDraft}
													/>
												);
											},
										)}
								</div>
							</div>
						</div>
					);
				})
			) : (
				<div className='workflow-bar d-flex justify-content-center mt-4'>
					<span className='BodyTwoLight' style={{ color: '#696F6C' }}>
						No Prebuilt workflow available. Go back to{' '}
						<span
							onClick={() => setActiveTab('COMPANY_LIBRARY')}
							style={{ color: '#fb7a03', cursor: 'pointer' }}
						>
							{'Company Library'}
						</span>{' '}
					</span>
				</div>
			)}
		</>
	);
}
