import { useEffect } from 'react';

// Styled Components
import { lightGreen, backgroundGrey } from '../Style.jsx';

// Components
import ButtonSquare from '../Buttons/ButtonSquare.jsx';
import SimpleButton from '../Buttons/SimpleButton.jsx';

// Material Ui Components
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { Box, CircularProgress } from '@mui/material';

import { executivePermissions, Member } from '../../utilities/utilities.js';

import { useDispatch, useSelector } from 'react-redux';
import * as teamActions from '../../redux/actions/team-management.actions';
import * as userActions from '../../redux/actions/user-management.actions';
import * as AuthActions from '../../redux/actions/auth-actions';

import SubmitButtonsField from '../Buttons/SubmitButtonsField.jsx';
import Restricted from '../Permissions/Restricted.jsx';
import { useHistory } from 'react-router';
import useBreadCrumb from '../../hooks/useBreadCrumb.jsx';
import { routes } from '../../utilities/routes.js';

function ProfileBar({ BarText, UserType, accountSettings, backToList }) {
	const dispatch = useDispatch();
	const { BreadCrumbs } = useBreadCrumb();

	const user = useSelector((state) => state.authReducer.user);
	const Profile = useSelector((state) => state.userReducer.userProfile); //User Profile
	const edit = useSelector((state) => state.userReducer.profileInfoEdit); //User ProfileEdit
	const Teams = useSelector((state) => state.userReducer.userTeams);
	const loading = useSelector((state) => state.userReducer.imageLoading);
	const history = useHistory();

	const EditProfile = () => {
		toggleProfileEdit();
	};

	const UpdateChanges = () => {
		let profileToUpdate = Profile;
		delete profileToUpdate.role;

		profileToUpdate = {
			...profileToUpdate,
			user_teams: profileToUpdate?.user_teams?.map((item) => {
				const obj = item;
				if (obj.team) {
					delete obj.team;
				}
				return obj;
			}),
		};

		dispatch(userActions.updateUserProfile(profileToUpdate));
		dispatch(AuthActions.getCurrentUserDetails(user?.userId));

		dispatch(userActions.clearUsers());

		toggleProfileEdit();
	};

	const toggleProfileEdit = () => {
		dispatch(userActions.toggleProfileInfoEdit(!edit));
	};

	const onDelete = () => {
		dispatch(userActions.deleteUser(Profile.id));
		dispatch(userActions.toggleProfileInfoEdit(false));
		backToList();
	};

	// const backToUsersList = () => {
	// 	dispatch(userActions.toggleProfileInfoEdit(false));
	// 	backToList();
	// 	dispatch(userActions.getUsers());
	// };

	const handleProfileClick = (item, index) => {
		const tempArr = [];
		UserType.map((a, i) => {
			if (i < index) {
				tempArr.push(a);
			}
		});
		localStorage.setItem('tempArr', JSON.stringify(tempArr));
		history.push(`${item.url}`);
	};

	useEffect(() => {
		const queryParams = new URLSearchParams(window.location.search);
		if (queryParams.get('edit')) {
			dispatch(userActions.toggleProfileInfoEdit(true));
			queryParams.set('edit', false);
			window.history.pushState(null, '', queryParams.toString());
		}
	}, []);

	return (
		<>
			{UserType?.length > 0 && (
				<Box
					className='py-2'
					sx={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						':hover': {
							cursor: 'pointer',
						},
						color: `${lightGreen}`,
					}}
				>
					<ArrowLeftIcon />
					<BreadCrumbs>
						<h2
							onClick={() => history.push(routes.users)}
							className='HeadlineThreeBook text-left'
						>
							Users
						</h2>
					</BreadCrumbs>
				</Box>
			)}
			<div className='heading'>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'flex-start',
						width: accountSettings && '100%',
					}}
				>
					<Box
						sx={{
							backgroundColor: '#FCFDFD',
							width: '100%',
							padding: '20px 18px',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
							gap: '1.5em',
							boxSizing: 'border-box',
							borderRadius: '4px 4px 0px 0px',
						}}
					>
						<Box>
							<h1
								className={
									!accountSettings ? 'HeadlineOneBold' : 'text HeadlineOneBold'
								}
							>
								{BarText}
							</h1>
						</Box>
						<Box
							sx={{
								width: 'fit-content',
								display: 'flex',
								alignItems: 'end',
								gap: '0.5em',
							}}
						>
							{!accountSettings && (
								<Restricted AllowedUsers={executivePermissions}>
									<SimpleButton
										text={'Delete ' + UserType}
										clickHandler={onDelete}
									/>
								</Restricted>
							)}
							{/* {edit ? (
								<SubmitButtonsField child={true}>
									<button
										type='submit'
										className='d-flex align-items-center'
										id='Element'
										disabled={loading}
										onClick={UpdateChanges}
									>
										{loading ? (
											<CircularProgress
												size={24}
												sx={{ color: 'white' }}
												disableShrink
											/>
										) : (
											'Done'
										)}
									</button>
								</SubmitButtonsField>
							) : (
								<ButtonSquare clickHandler={EditProfile}>Edit</ButtonSquare>
							)} */}
						</Box>
					</Box>
				</Box>
			</div>
		</>
	);
}

export default ProfileBar;
