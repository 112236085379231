import React from 'react';

function TextBox({ children, style, className }) {
	return (
		<div className={className + ' TextBox'} style={style}>
			{children}
		</div>
	);
}

export default TextBox;
