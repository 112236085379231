import { memo } from 'react';
import ClientContacts from './ClientContacts';
import ClientInformationSection from './ClientInformationSection';
import OfficeLocations from './OfficeLocations';
import NavigatorSection from './NavigatorSection';
import * as clientActions from '../../redux/actions/client-actions';
import { useDispatch } from 'react-redux';

function ClientDetail() {
	const dispatch = useDispatch();
	return (
		<section id='PROFILE' className='client-profile'>
			<ClientInformationSection />

			<ClientContacts />

			<OfficeLocations />
            
			<NavigatorSection
				title='Resources'
				handleClick={() => dispatch(clientActions.setClientProfileActiveTab('RESOURCES'))}
			/>
		</section>
	);
}

export default memo(ClientDetail);
