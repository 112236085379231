import { memo } from 'react';

const SectionHeader = ({ title, onClear, label, className, filtersApplied }) => {
	return (
		<div className='SectionHeader'>
			<h2 className={'HeadlineThreeBold NameHeading ' + className}>{title}</h2>

			<div className='d-flex' style={{ gap: '4px', height: '100%', alignItems: 'center' }}>
				{filtersApplied && (
					<div className='applied-filters'>
						<span className='BodyTwoBold pt-1'>{filtersApplied}</span>
					</div>
				)}
				<button className='clearButton p-0 m-0' onClick={onClear}>
					<h4 className='text-white BodyTwoBold m-0 pt-1'>{label}</h4>
				</button>
			</div>
		</div>
	);
};

export default memo(SectionHeader);
