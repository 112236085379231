export const StoreToken =
	(access_token, expires_at, refresh_token, refresh_token_expires) =>
	async (dispatch) => {
		// alert(JSON.stringify(access_token))
		await dispatch({
			type: 'STORE_TOKEN',
			payload: {
				access_token,
				expires_at,
				refresh_token,
				refresh_token_expires,
			},
		});
	};
export const isAuthenticated = (loggedIn) => async (dispatch) => {
	// alert(loggedIn)
	switch (loggedIn) {
		case true:
			await dispatch({
				type: 'AUTHENTICATED',
			});
			break;
		case false:
			await dispatch({
				type: 'NOTAUTHENTICATED',
			});
			break;
		default:
			break;
	}
};
export const storeUserData = (email) => async (dispatch) => {
	try {
		dispatch({ type: 'STORE_EMAIL', payload: email });
	} catch (error) {
		console.log(error);
	}
};
export const storeUserId = (userId) => async (dispatch) => {
	try {
		dispatch({ type: 'STORE_USERID', payload: userId });
	} catch (error) {
		console.log(error);
	}
};
export const updateLoginStep = (stepName) => async (dispatch) => {
	try {
		switch (stepName) {
			case 'LoginForm':
				dispatch({ type: 'LoginForm' });
				break;
			case 'ForgetPassword':
				dispatch({ type: 'ForgetPassword' });
				break;
			case 'ResetLinkSent':
				dispatch({ type: 'ResetLinkSent' });
				break;
			case 'ResetLinkExpired':
				dispatch({ type: 'ResetLinkExpired' });
				break;
			case 'ResetPassword':
				dispatch({ type: 'ResetPassword' });
				break;
			case 'Error404':
				dispatch({ type: 'Error404' });
				break;
			default:
				break;
		}
		// if (stepNumber == 1) {
		//   dispatch({ type: 'LoginForm' });
		// }
		// if (stepNumber == 2) {
		//   dispatch({ type: 'ForgetPassword' });
		// } else if (stepNumber == 3) {
		//   dispatch({ type: 'ResetLinkSent' });
		// } else if (stepNumber == 4) {
		//   dispatch({ type: 'ResetLinkExpired' });
		// } else if (stepNumber == 5) {
		//   dispatch({ type: 'ResetPassword' });
		// } else if (stepNumber == 6) {
		//   dispatch({ type: 'Error404' });
		// }
	} catch (error) {
		console.log(error);
	}
};
