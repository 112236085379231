import { Dialog, Zoom } from '@mui/material';
import React, { useEffect, useState } from 'react';
import FormButtonOne from '../Buttons/FormButtonOne';
import { useDispatch } from 'react-redux';
import * as Actions from '../../redux/actions/dashboard/workspaces-actions';
import InputTaskDetails from '../Inputs/InputTaskDetails';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Zoom in={true} ref={ref} {...props} style={{ transitionDelay: '50ms' }} />;
});

const RequestDocumentModal = ({ open, inviteLink, closeHandler }) => {
	const dispatch = useDispatch();
	const [actionLoading, setActionLoading] = useState({
		copyLink: false,
		sendInvite: false,
	});
	const [form, setForm] = useState({
		email: '',
		cc: '',
		message: '',
	});
	const [error, setError] = useState({
		email: '',
		cc: '',
	});

	const copyLink = () => {
		setActionLoading({
			...actionLoading,
			copyLink: true,
		});
		if (form?.message?.length > 0 && form?.message !== inviteLink?.message) {
			const payload = {
				message: form?.message,
			};
			dispatch(Actions.updateInviteMessage(payload, inviteLink?.uuid))
				.then(() => {
					dispatch(Actions.setSnackBarIsOpen(true, true, 'Link Copied'));
					navigator.clipboard.writeText(inviteLink?.link || '');
					setActionLoading({
						...actionLoading,
						copyLink: false,
					});
				})
				.catch((error) => {
					dispatch(Actions.setSnackBarIsOpen(false, true, error.message));
					setActionLoading({
						...actionLoading,
						copyLink: false,
					});
				});
		} else {
			dispatch(Actions.setSnackBarIsOpen(true, true, 'Link Copied'));
			navigator.clipboard.writeText(inviteLink?.link || '');
			setActionLoading({
				...actionLoading,
				copyLink: false,
			});
		}
	};

	const handleEmailChange = (event) => {
		const { name, value } = event.target;
		setForm({
			...form,
			[name]: value,
		});

		if (value == '') {
			setError({
				...error,
				[name]: '',
			});
		} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
			setError({
				...error,
				[name]: 'Please enter a valid email',
			});
		} else {
			setError({
				...error,
				[name]: '',
			});
		}
	};

	const handleMessageChange = (message) => {
		setForm({
			...form,
			['message']: message,
		});
	};

	const onInvite = () => {
		setActionLoading({
			...actionLoading,
			sendInvite: true,
		});
		const payload = {
			email: form?.email,
			uuid: inviteLink?.uuid,
			cc_email: [form?.cc],
			message: form?.message,
			requested_url: `${window.location.origin}/upload-document`,
		};
		dispatch(Actions.sendDocumentUploadInvite(payload))
			.then(() => {
				setActionLoading({
					...actionLoading,
					sendInvite: false,
				});
				dispatch(Actions.setSnackBarIsOpen(true, true, 'Email Sent Succesfully'));
			})
			.catch((error) => {
				dispatch(Actions.setSnackBarIsOpen(false, true, error.message));

				setActionLoading({
					...actionLoading,
					sendInvite: false,
				});
			});
	};

	useEffect(() => {
		setForm({
			...form,
			['message']: inviteLink?.message || '',
		});
		return () => {
			setForm({
				email: '',
				cc: '',
			});
			setError({
				email: '',
				cc: '',
			});
		};
	}, [inviteLink]);

	return (
		<Dialog
			TransitionComponent={Transition}
			open={open}
			onClose={closeHandler}
			disableEnforceFocus
		>
			<div className='request-document-modal'>
				<div
					className='content align-items-start'
					style={{
						gap: '24px',
					}}
				>
					<div
						className='HeadlineOneBold dark-text form-title'
						style={{ lineHeight: '1.6' }}
					>
						Request Files from Clients
					</div>
					<div className='d-flex flex-column w-100 BodyTwoLight'>
						{`Please enter client's email and an optional message to send an email to
						upload the required document. The client has 14 days to upload the file.`}
					</div>
					<div className='improved-input-designs w-100'>
						<label>To</label>
						<input
							type='email'
							name='email'
							value={form?.email}
							onChange={handleEmailChange}
						/>
						<span className='error-text BodyThreeLight'>{error.email}</span>
					</div>
					<div className='improved-input-designs w-100'>
						<label>Cc</label>
						<input
							type='email'
							name='cc'
							value={form?.cc}
							onChange={handleEmailChange}
						/>
						<span className='error-text BodyThreeLight'>{error.cc}</span>
					</div>
					<div className='w-100 improved-input-designs'>
						<label>Message (Optional)</label>
						<InputTaskDetails
							richText
							type='text'
							name='description'
							onChange={handleMessageChange}
							value={form?.message}
							style={{
								height: '200px',
							}}
						/>
					</div>
					<span className='BodyTwoLight'>
						Or you can copy the given link and share it with the client.
					</span>
					<div
						className='d-flex align-items-end w-100 justify-content-between pb-3'
						style={{ gap: '12px' }}
					>
						<div className='improved-input-designs w-100'>
							<label>Link to Request Files</label>
							<input type='text' disabled placeholder={inviteLink?.link} />
						</div>
						<FormButtonOne
							onClick={copyLink}
							className='rounded-btn primary-btn'
							loading={actionLoading.copyLink}
							disabled={actionLoading.copyLink}
						>
							Copy Link
						</FormButtonOne>
					</div>
				</div>
				<div className='d-flex action-buttons w-100 f-flex'>
					<FormButtonOne
						onClick={closeHandler}
						type='button'
						className={'rounded-btn secondary-btn-light'}
					>
						Close
					</FormButtonOne>
					<FormButtonOne
						className='rounded-btn primary-btn'
						onClick={onInvite}
						type='button'
						disabled={Boolean(
							(form?.email == '' && form?.cc == '') ||
								error?.email !== '' ||
								error.cc !== '' ||
								actionLoading.sendInvite,
						)}
						loading={actionLoading.sendInvite}
					>
						Send Request
					</FormButtonOne>
				</div>
			</div>
		</Dialog>
	);
};

export default RequestDocumentModal;
