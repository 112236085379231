/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState } from 'react';
import RichTextEditor from '../../Inputs/RichTextEditor';
import { ReactComponent as ArrowsIn } from '../../../assets/icons/ArrowsInSimple.svg';
import { ReactComponent as Done } from '../../../assets/icons/CheckGrey.svg';
import { ReactComponent as Edit } from '../../../assets/icons/PencilLight.svg';
import { useMemo } from 'react';
import moment from 'moment';
import UpdateMeetingTaskAlert from './UpdateMeetingTaskAlert';
import { useDispatch, useSelector } from 'react-redux';
import * as WorkSpaceActions from '../../../redux/actions/dashboard/workspaces-actions';
import { CircularProgress } from '@mui/material';

const ExpandedNotes = ({ taskDetails, content, title, setExpandView, isEdit }) => {
	const dispatch = useDispatch();
	const user = useSelector((state) => state.authReducer.user);
	const [value, setValue] = useState();
	const [open, setOpen] = useState(false);
	const todayDate = moment().format('MM-DD-YYYY');
	const today = moment(todayDate).startOf('day');
	const start_date = moment(taskDetails?.start_date).startOf('day');
	const [actionLoading, setActionLoading] = useState(false);
	const toggleAlert = () => {
		setOpen(!open);
	};

	const onUpdateSuccess = (update_future) => {
		setExpandView({
			isExpanded: false,
			content: null,
			title: '',
			isEdit: false,
		});
		update_future && dispatch(WorkSpaceActions.getMeetings());
		dispatch(WorkSpaceActions.setSnackBarIsOpen(true, true, 'Updated'));
	};
	const isTaskEditAble =
		title == 'Notes'
			? taskDetails?.my_task?.creator_id == user.userId
			: taskDetails?.my_task?.creator_id == user.userId && !start_date.isBefore(today);

	useMemo(() => {
		setValue(content);
	}, []);

	const onUpdateTask = (update_future) => {
		setActionLoading(true);
		const form = taskDetails;
		let formDetails = {};
		formDetails = {
			id: form?.id,
			type: form?.task_type_id == 1 ? 'ONE_TIME' : 'RECURRING',
			agenda: title == 'Agenda' ? value : form?.agenda,
			notes: title == 'Notes' ? value : form?.notes,
			title: form?.title,
			assignee_list: form?.assignee_users?.map((assignee) => assignee.user_id) || [],
			start_date: form?.start_date || moment(),
			is_monthly_on_day: form?.my_task?.is_monthly_on_day,
			metadata: {
				meeting_link: form?.metadata?.meeting_link,
				meeting_platform: form?.metadata?.meeting_platform,
			},
			start_time: moment(form.start_time, 'hh:mm A').format('hh:mm:ss a') || null,
			end_time: moment(form.end_time, 'hh:mm A').format('hh:mm:ss a') || null,
			is_billable: form?.is_billable == 'Billable' ? true : false,
			end_on: form?.my_task?.end_on,
			required_days: form?.my_task?.required_days,
			repeat_type: form?.repeat_type,
			repeat_interval: form?.repeat_interval,
			recur_preference: form?.my_task?.recur_preference,
			color: form?.metadata?.color,
		};
		dispatch(WorkSpaceActions.updateMeetingTaskDetails(formDetails, update_future))
			.then(() => {
				setActionLoading(false);
				onUpdateSuccess(update_future);
			})
			.catch((error) => {
				setActionLoading(false);
				dispatch(WorkSpaceActions.setSnackBarIsOpen(false, true, error.message));
			});
		open && toggleAlert();
	};

	return (
		<div className='meeting-my-details-modal p-4 position-absolute' style={{ zIndex: 1 }}>
			<div className='meeting-task-agenda-container h-100'>
				<div className='d-flex justify-content-between align-items-center'>
					<label className='HeadlineThreeBook text-left dark-text'>{title}</label>
					<div className='d-flex pointer' style={{ gap: '14px' }}>
						{isTaskEditAble &&
							(isEdit ? (
								<span
									onClick={() =>
										taskDetails?.task_type_id == 1
											? actionLoading
												? null
												: onUpdateTask()
											: toggleAlert()
									}
								>
									{actionLoading ? (
										<CircularProgress
											size={20}
											sx={{ color: 'Black' }}
											disableShrink
										/>
									) : (
										<Done />
									)}
								</span>
							) : (
								<span
									className='pointer'
									onClick={() => {
										setExpandView({
											isExpanded: true,
											content: content,
											title: title,
											isEdit: true,
										});
									}}
								>
									<Edit />
								</span>
							))}
						<span
							onClick={() =>
								setExpandView({
									isExpanded: false,
									content: null,
								})
							}
						>
							<ArrowsIn />
						</span>
					</div>
				</div>
				<RichTextEditor
					Edit={isTaskEditAble && isEdit}
					name='notes'
					value={value}
					onChange={(e) => {
						const value = e;
						const name = title;
						setValue(value);
					}}
					containerClass='h-100 overflow-auto'
					style={{
						height: '100%',
						width: '100%',
						// padding: '0.5em 1em',
						wordWrap: 'break-word',
						textAlign: 'left',
						// marginBottom: '3rem',
						// backgroundColor: '#F7F9FA',
						borderRadius: '4px',
					}}
				/>
			</div>
			<UpdateMeetingTaskAlert
				open={open}
				isRecurrenceUpdate={false}
				closeHandler={toggleAlert}
				submitHandler={({ update_future }) => {
					onUpdateTask(update_future);
				}}
			/>
		</div>
	);
};

export default ExpandedNotes;
