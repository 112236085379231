import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Sentry from '../../sentry/sentry';
import FormButtonOne from '../Buttons/FormButtonOne';
import * as workspaceActions from '../../redux/actions/dashboard/workspaces-actions';

function ErrorReportModal({ closeModal }) {
	const dispatch = useDispatch();
	const [formValues, setFormValues] = useState({
		email: '',
		message: '',
	});
	const lastError = useSelector((state) => state.authReducer.lastError);
	const onChangeHandler = (event) => {
		const { name, value } = event.target;
		setFormValues({ ...formValues, [name]: value });
	};

	const handleSubmit = (event) => {
		event.preventDefault();

		Sentry.captureMessage(lastError?.error, {
			extra: { email: formValues.email, description: formValues.message },
			event_id: lastError?.eventId || Sentry.lastEventId(),
		});

		dispatch(workspaceActions.setSnackBarIsOpen(true, true, 'Report sent!'));

		closeModal();
	};

	return (
		<form onSubmit={handleSubmit} className='error-report-modal'>
			<div className='content'>
				<div className='HeadlineTwoBold dark-text form-title'>
					Report Issue
				</div>
				<div className='input-container'>
					<div className='new-input-designs pt-1'>
						<label className='require'>Email</label>
						<input
							className='w-100'
							type='email'
							name='email'
							onChange={onChangeHandler}
							value={formValues?.email}
							required
						/>
					</div>

					<div className='new-input-designs pt-1'>
						<label className='require'>Summary</label>
						<textarea
							className='w-100'
							type='text'
							name='message'
							onChange={onChangeHandler}
							value={formValues?.message}
							rows={8}
							required
						/>
					</div>
				</div>
			</div>
			<div className='action-buttons'>
				<FormButtonOne
					onClick={closeModal}
					type='button'
					className='secondary-btn-light'
				>
					Cancel
				</FormButtonOne>

				<FormButtonOne type='submit'>Submit</FormButtonOne>
			</div>
		</form>
	);
}

export default ErrorReportModal;
