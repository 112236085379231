import React from 'react';

import Icon from './Icon';

const UserIcon = (props) => {

  const { fill = '#000' } = props;

  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <defs>
        <path
          d="M8 1.9a2.1 2.1 0 110 4.2 2.1 2.1 0 010-4.2m0 9c2.97 0 6.1 1.46 6.1 2.1v1.1H1.9V13c0-.64 3.13-2.1 6.1-2.1M8 0C5.79 0 4 1.79 4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 9c-2.67 0-8 1.34-8 4v3h16v-3c0-2.66-5.33-4-8-4z"
          id="user-icon-a"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(4 4)">
          <mask id="user-icon-b" fill={fill}>
            <use xlinkHref="#user-icon-a" />
          </mask>
          <use
            className="fill-target"
            fill={fill}
            fillRule="nonzero"
            xlinkHref="#user-icon-a"
          />
          <path
            className="fill-target"
            fill={fill}
            mask="url(#user-icon-b)"
            d="M-4-4h24v24H-4z"
          />
        </g>
        <path d="M0 0h24v24H0z" />
      </g>
    </Icon>
  );
};

export default UserIcon;
