/* eslint-disable no-mixed-spaces-and-tabs */
import { secure_instance } from '../../axios/axios-config';
import CryptoJS from 'crypto-js';
import { arrayMove, PROTECTION_KEY, sortByOrder } from '../../utilities/utilities';
import { returnAddress } from '../../components/Inputs/EditableLocationText';
import { states } from '../../components/DemoData';
import { setSnackBarIsOpen } from './dashboard/workspaces-actions';

export const HANDLE_CLEAR_TEAM_CHECKBOX = '[Get Clients] HANDLE_CLEAR_TEAM_CHECKBOX';
export const GET_CLIENTS = '[Get Clients] GET_CLIENTS';
export const GET_CLIENTS_START = '[Get Clients] GET_CLIENTS_START';
export const GET_CLIENTS_FAILED = '[Get Clients] GET_CLIENTS_FAILED';
export const DELETE_CLIENT_END = '[Get Clients] DELETE_CLIENT_END';
export const DELETE_CLIENT_FAILED = '[Get Clients] DELETE_CLIENT_FAILED';
export const DELETE_CLIENT_START = '[Get Clients] DELETE_CLIENT_START';
export const ADD_CLIENT = '[Get Clients] ADD_CLIENT';
export const UPDATE_CLIENT = '[Get Clients] UPDATE_CLIENT';
export const MULTI_DELETE_END = '[Get Clients] MULTI_DELETE_END';
export const MULTI_DELETE_START = '[Get Clients] MULTI_DELETE_START';
export const MULTI_DELETE_FAILED = '[Get Clients] MULTI_DELETE_FAILED';
export const GET_SINGLE_CLIENT = '[Get Clients] GET_SINGLE_CLIENT';
export const CLIENT_DATA_CHANGE = '[Get Clients] CLIENT_DATA_CHANGE';
export const UPDATE_CLIENT_TEAM = '[Get Clients] UPDATE_CLIENT_TEAM';
export const CLIENT_CONTACT_CHANGE = '[Get Clients] CLIENT_CONTACT_CHANGE';
export const NEW_CONTACT_FORM_CHANGE = '[Get Clients] NEW_CONTACT_FORM_CHANGE';
export const RESET_NEW_CONTACT_FORM = '[Get Clients] RESET_NEW_CONTACT_FORM';
export const ADD_NEW_CONTACT = '[Get Clients] ADD_NEW_CONTACT';
export const EDIT_CONTACT_FORM_CHANGE = '[Get Clients] EDIT_CONTACT_FORM_CHANGE';
export const RESET_EDIT_CONTACT_FORM = '[Get Clients] RESET_EDIT_CONTACT_FORM';
export const UPDATE_CLIENT_CONTACT_LIST = '[Get Clients] UPDATE_CLIENT_CONTACT_LIST';
export const CLIENT_OFFICE_CHANGE = '[Get Clients] CLIENT_OFFICE_CHANGE';
export const NEW_OFFICE_FORM_CHANGE = '[Get Clients] NEW_OFFICE_FORM_CHANGE';
export const RESET_NEW_OFFICE_FORM = '[Get Clients] RESET_NEW_OFFICE_FORM';
export const EDIT_OFFICE_FORM_CHANGE = '[Get Clients] EDIT_OFFICE_FORM_CHANGE';
export const RESET_EDIT_OFFICE_FORM = '[Get Clients] RESET_EDIT_OFFICE_FORM';
export const SUBMIT_NEW_OFFICE_FORM = '[Get Clients] SUBMIT_NEW_OFFICE_FORM';
export const SUBMIT_EDIT_OFFICE_FORM = '[Get Clients] SUBMIT_EDIT_OFFICE_FORM';
export const CLIENT_SOFTWARE_CHANGE = '[Get Clients] CLIENT_SOFTWARE_CHANGE';
export const RESET_NEW_SOFTWARE_FORM = '[Get Clients] RESET_NEW_SOFTWARE_FORM';
export const DELETE_CLIENT_CONTACT = '[Get Clients] DELETE_CLIENT_CONTACT';
export const DELETE_CLIENT_OFFICE = '[Get Clients] DELETE_CLIENT_OFFICE';
export const DELETE_LINK = '[Get Clients] DELETE_LINK';
export const HANDLE_OFFICE_ADDRESS = '[Get Clients] HANDLE_OFFICE_ADDRESS';
export const DELETE_CLIENT_SOFTWARE = '[Get Clients] DELETE_CLIENT_SOFTWARE';
export const HANDLE_DATE_CHANGE = '[Get Clients] HANDLE_DATE_CHANGE';
export const OFFICE_LOCATION_TO_EDIT = '[Get Clients] OFFICE_LOCATION_TO_EDIT';
export const HANDLE_CLEAR_INDUSTRY_CHECKBOX = '[Get Clients] HANDLE_CLEAR_INDUSTRY_CHECKBOX';
export const HANDLE_CLEAR_BUSINESS_CHECKBOX = '[Get Clients] HANDLE_CLEAR_BUSINESS_CHECKBOX';
export const HANDLE_CLEAR_STATE_CHECKBOX = '[Get Clients] HANDLE_CLEAR_STATE_CHECKBOX';

export const HANDLE_START_DATE_CHANGE = '[Get Clients] HANDLE_START_DATE_CHANGE';
export const HANDLE_INDUSTRY_CHECKBOX = '[Get Clients] HANDLE_INDUSTRY_CHECKBOX';
export const HANDLE_TEAM_CHECKBOX = '[Get Clients] HANDLE_TEAM_CHECKBOX';
export const DEACTIVATE_CLIENT_WORKFLOWS_START = '[Get Clients] DEACTIVATE_CLIENT_WORKFLOWS_START';
export const DEACTIVATE_CLIENT_WORKFLOWS_FAILED =
	'[Get Clients] DEACTIVATE_CLIENT_WORKFLOWS_FAILED';
export const DEACTIVATE_CLIENT_WORKFLOWS = '[Get Clients] DEACTIVATE_CLIENT_WORKFLOWS';

export const HANDLE_BUSSINESS_CHECKBOX = '[Get Clients] HANDLE_BUSSINESS_CHECKBOX';
export const HANDLE_STATE_CHECKBOX = '[Get Clients] HANDLE_STATE_CHECKBOX';
export const SUBMIT_NEW_SOFTWARE_FORM = '[Get Clients] SUBMIT_NEW_SOFTWARE_FORM';
export const NEW_SOFTWARE_FORM_CHANGE = '[Get Clients] NEW_SOFTWARE_FORM_CHANGE';
export const RESET_CLIENT_STATE = '[Get Clients] RESET_CLIENT_STATE';
export const GET_FROM_VAULT = 'GET_FROM_VAULT';
export const GET_FILTERED_CLIENT_MANAGEMENT = '[Get Clients] GET_FILTERED_CLIENT_MANAGEMENT';
export const TOGGLE_CLIENT_MODAL = 'TOGGLE_CLIENT_MODAL';
export const CLIENT_LINK_DATA_CHANGE = 'CLIENT_LINK_DATA_CHANGE';
export const LINK_CHANGE_HANDLER = 'LINK_CHANGE_HANDLER';
export const RESET_LINK = 'RESET_LINK';
export const SET_ERROR = 'SET_ERROR';
export const ADD_LINK_TO_CLIENT = 'ADD_LINK_TO_CLIENT';
export const CLIENT_LINK_CHANGE = 'CLIENT_LINK_CHANGE';
export const REMOVE_LINK_FROM_CLIENT = 'REMOVE_LINK_FROM_CLIENT';
export const TOGGLE_CLIENT_LOAD = 'TOGGLE_CLIENT_LOAD';
export const SET_FORM_LOAD = 'SET_FORM_LOAD';
export const CHANGE_CLIENT_OFFICE_LOCATION_ARRAY_INDEX =
	'CHANGE_CLIENT_OFFICE_LOCATION_ARRAY_INDEX';
export const CHANGE_CLIENT_CONTACTS_ARRAY_INDEX = 'CHANGE_CLIENT_CONTACTS_ARRAY_INDEX';
export const CHANGE_CLIENT_LINKS_ARRAY_INDEX = 'CHANGE_CLIENT_LINKS_ARRAY_INDEX';
export const SET_DRAG_VALUES = 'SET_DRAG_VALUES';
export const CLIENT_CONTACT_TO_EDIT = '[Get Clients] CLIENT_CONTACT_TO_EDIT';
export const EDIT_LINK_CHANGE_HANDLER = 'EDIT_LINK_CHANGE_HANDLER';
export const LINKS_TO_EDIT = 'LINKS_TO_EDIT';
export const EDIT_LINK_TO_CLIENT = 'EDIT_LINK_TO_CLIENT';
export const ADDING_NEW_FIELD = 'ADDING_NEW_FIELD';
export const REMOVING_CONTACT_FIELD = 'REMOVING_CONTACT_FIELD';
export const CLIENT_INVOICE_FORM_CHANGE_HANDLER = 'CLIENT_INVOICE_FORM_CHANGE_HANDLER';
export const SET_CLIENT_INVOICE = 'SET_CLIENT_INVOICE';
export const BILLING_WORKFLOW_TASK_DETAILS = 'BILLING_WORKFLOW_TASK_DETAILS';
export const HANDLE_SELECT_ALL_CLIENT_INVOICE = 'HANDLE_SELECT_ALL_CLIENT_INVOICE';
export const HANDLE_SELECT_ALL_CLIENT_WORKFLOW_INVOICE =
	'HANDLE_SELECT_ALL_CLIENT_WORKFLOW_INVOICE';
export const HANDLE_SELECT_CLIENT_USER_INVOICE = 'HANDLE_SELECT_CLIENT_USER_INVOICE';
export const CLIENT_USER_INVOICE_CHANGE_HANDLER = 'CLIENT_USER_INVOICE_CHANGE_HANDLER';
export const CLIENT_USER_INVOICE_CHANGES_DONE = 'CLIENT_USER_INVOICE_CHANGES_DONE';
export const CLIENT_TEAM_CHANGE = 'CLIENT_TEAM_CHANGE';
export const ALL_CLIENT_TEAM_CHANGE = 'ALL_CLIENT_TEAM_CHANGE';
export const ADD_PRIMARY_LOCATION = 'ADD_PRIMARY_LOCATION';
export const GET_WORKFLOW_MY_TASKS = 'GET_WORKFLOW_MY_TASKS';
export const SET_WORKFLOW_MY_TASK_LOADING = 'SET_WORKFLOW_MY_TASK_LOADING';
export const GET_MEETING_MY_TASKS = 'GET_MEETING_MY_TASKS';
export const ADD_CLIENT_SHARED_LINK = 'ADD_CLIENT_SHARED_LINK';
export const SET_CLIENT_SECTION_LOADING = 'SET_CLIENT_SECTION_LOADING';
export const DELETE_CLIENT_MEETING_TASK = 'DELETE_CLIENT_MEETING_TASK';
export const SET_USER_TEAM_ROLE = 'SET_USER_TEAM_ROLE';
export const SET_CLIENT_PROFILE_ACTIVE_TAB = 'SET_CLIENT_PROFILE_ACTIVE_TAB';
export const GET_CLIENTS_FOR_TEAM = 'GET_CLIENTS_FOR_TEAM';
export const GET_CLIENTS_FOR_TEAM_LOADING = 'GET_CLIENTS_FOR_TEAM_LOADING';
export const GET_SPECIFIC_FOLDER = 'GET_SPECIFIC_FOLDER';
export const GET_MULTIPLE_FOLDERS_AND_FILES = 'GET_MULTIPLE_FOLDERS_AND_FILES';
export const SET_FOLDERS_AND_FILES = 'SET_FOLDERS_AND_FILES';
export const GET_ALL_FOLDERS_AND_FILES = 'GET_ALL_FOLDERS_AND_FILES';
export const GET_ALL_FOLDERS_AND_FILES_FOR_MODAL = 'GET_ALL_FOLDERS_AND_FILES_FOR_MODAL';
export const UPDATE_FOLDER = 'UPDATE_FOLDER';
export const DELETE_DOCUMENT = 'DELETE_DOCUMENT';
export const CREATE_FOLDER = 'CREATE_FOLDER';
export const UPDATE_FOLDER_DATA = 'UPDATE_FOLDER_DATA';
export const UPLOAD_DOCUMENT = 'UPLOAD_DOCUMENT';
export const CREATE_FILE = 'CREATE_FILE';
export const UPLOAD_FILE = 'UPLOAD_FILE';
export const UPLOAD_IMAGE_PROGRESS = 'UPLOAD_IMAGE_PROGRESS';
export const SET_FOLDER_SELECTED = 'SET_FOLDER_SELECTED';
export const UPDATE_DOCUMENT = 'UPDATE_DOCUMENT';
export const RESET_UPLOAD_STATE = 'RESET_UPLOAD_STATE';

export const SET_WORKFLOW_TO_ADD_FOR_CLIENT_PREVIEW = 'SET_WORKFLOW_TO_ADD_FOR_CLIENT_PREVIEW';

export const clientTeamChange = (clientId, value) => {
	return (dispatch) => {
		dispatch({
			type: CLIENT_TEAM_CHANGE,
			payload: { clientId, value },
		});
	};
};

export const allClientTeamChange = (newTeamId) => {
	return (dispatch) => {
		dispatch({
			type: ALL_CLIENT_TEAM_CHANGE,
			payload: { newTeamId },
		});
	};
};

export const getClients = (teamId, filters, start_date, end_date) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: GET_CLIENTS_START,
			});

			const industryQuery = filters?.industry
				.map((arr, index) => {
					if (index === filters.industry.length - 1) {
						return 'industry[]=' + arr;
					} else {
						return 'industry[]=' + arr + '&';
					}
				})
				.join('');

			const businessQuery = filters?.business
				.map((arr, index) => {
					if (index === filters.business.length - 1) {
						return 'business_type[]=' + arr;
					} else {
						return 'business_type[]=' + arr + '&';
					}
				})
				.join('');

			const stateQuery = filters?.states
				.map((arr, index) => {
					if (index === filters.states.length - 1) {
						return 'state[]=' + arr;
					} else {
						return 'state[]=' + arr + '&';
					}
				})
				.join('');

			const teamQuery = filters?.teams
				.map((arr, index) => {
					if (index === filters.teams.length - 1) {
						return 'team[]=' + arr;
					} else {
						return 'team[]=' + arr + '&';
					}
				})
				.join('');

			const request = await secure_instance.request({
				url: teamId
					? `v1/clients/?expand=[team_client_permissions.team, workflow_my_tasks(selectWorkflowMyTasks).task_timers, tasks.[task_timers, client_workflow , user_profile], client_workflows.workflow]&team_id=${teamId}&start_date=${start_date}&end_date=${end_date}`
					: `v1/clients/?expand=team_client_permissions.team${
							industryQuery ? '&' + industryQuery : ''
					  }${stateQuery ? '&' + stateQuery : ''}${
							businessQuery ? '&' + businessQuery : ''
					  }${teamQuery ? '&' + teamQuery : ''}`,
				method: 'GET',
			});

			dispatch({
				type: GET_CLIENTS,
				payload: request.data,
			});
		} catch (e) {
			dispatch({
				type: GET_CLIENTS_FAILED,
			});
		}
	};
};

export const setLoadingForClientsInTeam = (loading) => {
	return (dispatch) => {
		dispatch({
			type: GET_CLIENTS_FOR_TEAM_LOADING,
			payload: loading,
		});
	};
};

export const getClientsForTeamProfile = (teamId, start_date, end_date) => {
	return async (dispatch) => {
		try {
			dispatch(setLoadingForClientsInTeam(true));
			const request = await secure_instance.request({
				url: `v1/clients/?expand=[workflow_my_tasks(selectAdhocWithoutWorkflow).[task_timers, user_profile], tasks.[task_timers, client_workflow, my_task.client_workflow(selectedFields) user_profile], client_workflows.workflow]&team_id=${teamId}&start_date=${start_date}&end_date=${end_date}`,
				method: 'GET',
			});
			dispatch({
				type: GET_CLIENTS_FOR_TEAM,
				payload: request.data,
			});
			dispatch(setLoadingForClientsInTeam(false));
		} catch (error) {
			dispatch(setLoadingForClientsInTeam(false));
			//
		}
	};
};

export const addClient = (data, toggleModal, setLoading, history, location) => {
	return async (dispatch) => {
		try {
			dispatch(setError(''));

			const request = await secure_instance.request({
				url: 'v1/clients/',
				method: 'Post',
				data,
			});

			dispatch({
				type: ADD_CLIENT,
				payload: request.data,
			});

			setLoading(false);

			const tempArr = [{ url: location.pathname, name: 'Clients' }];
			localStorage.setItem('tempArr', JSON.stringify(tempArr));
			toggleModal();
			history.push(`client-profile/${request.data.id}`);
		} catch (e) {
			//
			setLoading(false);
			dispatch(setError(e.response.data.message));
		}
	};
};

export const updateClient = (client, callBack) => {
	return async (dispatch) => {
		// const newData = {
		// 	...data,
		// 	client_contact_list: data?.client_contact_list?.map((item) => {
		// 		const contact = { ...item?.contact };
		// 		delete contact?.slack;
		// 		return { ...item, contact };
		// 	}),
		// };

		try {
			const request = await secure_instance.request({
				url: `v1/clients/${client?.id}?expand=[team_client_permissions.team.tenant_profile]`,
				method: 'Patch',
				data: client,
			});

			// const client_contact_list = sortByOrder(request.data.client_contact_list);
			// const client_office_locations = sortByOrder(
			// 	request.data.client_office_locations,
			// );
			// const links = sortByOrder(request.data?.links);

			// const response = {
			// 	...request.data,
			// 	client_contact_list,
			// 	links,
			// 	client_office_locations,
			// };

			dispatch({
				type: UPDATE_CLIENT,
				payload: request.data,
			});

			callBack?.clientUpdateSuccess && callBack?.clientUpdateSuccess(request.data);
		} catch (e) {
			callBack?.clientUpdateFailed &&
				callBack?.clientUpdateFailed(e?.response?.data?.message);
		}
	};
};

export const deleteMultiple = (ids) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: MULTI_DELETE_START,
			});

			await secure_instance.request({
				url: 'v1/clients/',
				method: 'Delete',
				data: {
					ids,
				},
			});

			dispatch({
				type: MULTI_DELETE_END,
				payload: ids,
			});
		} catch (e) {
			if (e.response.data.message) {
				alert(e.response.data.message);
			}
			dispatch({
				type: MULTI_DELETE_FAILED,
			});
		}
	};
};

export const deActivateWorklowsList = (history, clientId, callBack) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: DEACTIVATE_CLIENT_WORKFLOWS_START,
			});

			const request = await secure_instance.request({
				url: `/v1/client_workflows/deactivate_client_workflows/?client_id=${clientId}`,
				method: 'GET',
			});

			console.log(request, 'req deactivate');
			dispatch({
				type: DEACTIVATE_CLIENT_WORKFLOWS,
				payload: request.data,
			});

			history && history.push('/clients');

			callBack?.success && callBack?.success();
		} catch (e) {
			callBack?.fail && callBack?.fail();
			dispatch({
				type: DEACTIVATE_CLIENT_WORKFLOWS_FAILED,
			});
		}
	};
};

export const deleteClient = (id, history) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: DELETE_CLIENT_START,
			});

			await secure_instance.request({
				url: `v1/clients/${id}`,
				method: 'Delete',
			});

			history && history.push(`/clients`);

			dispatch({
				type: DELETE_CLIENT_END,
				payload: id,
			});
		} catch (e) {
			if (e.response.data.message) {
				alert(e.response.data.message);
			}
			dispatch({
				type: DELETE_CLIENT_FAILED,
			});
		}
	};
};

export const getClient = (id) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `v1/clients/${id}?expand=[team_client_permissions.team.tenant_profile,client_contact_list(orderByOrder),client_office_locations(orderByOrder),client_software_stacks,links(orderByOrder),referral]`,
				method: 'Get',
			});

			dispatch({
				type: SET_CLIENT_SECTION_LOADING,
				payload: true,
			});

			dispatch({
				type: GET_SINGLE_CLIENT,
				payload: request.data,
			});

			dispatch({
				type: SET_CLIENT_SECTION_LOADING,
				payload: false,
			});
		} catch (error) {
			dispatch({
				type: SET_CLIENT_SECTION_LOADING,
				payload: false,
			});

			throw new Error(
				error?.response?.data?.message || error?.message || 'Something went wrong',
			);
		}
	};
};

export const getFilteredClientManagement = () => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `v1/clients/filter_management`,
				method: 'Get',
			});
			dispatch({
				type: GET_FILTERED_CLIENT_MANAGEMENT,
				payload: request.data,
			});
		} catch (e) {
			//
		}
	};
};

export const resetClientState = () => {
	return async (dispatch) => {
		dispatch({
			type: RESET_CLIENT_STATE,
		});
	};
};

export const clientDataChange = (data) => {
	return {
		type: CLIENT_DATA_CHANGE,
		payload: data,
	};
};

export const clientLinkDataChange = (data) => {
	return {
		type: CLIENT_LINK_DATA_CHANGE,
		payload: data,
	};
};

export const handleDateChange = (data) => {
	return {
		type: HANDLE_DATE_CHANGE,
		payload: data,
	};
};

export const handleStartDateChange = (data) => {
	return {
		type: HANDLE_START_DATE_CHANGE,
		payload: data,
	};
};

export const updateClientTeam = (team_id) => {
	return {
		type: UPDATE_CLIENT_TEAM,
		payload: team_id,
	};
};

export const clientContactChange = (data) => {
	return {
		type: CLIENT_CONTACT_CHANGE,
		payload: data,
	};
};

export const newContactFormChange = (data) => {
	return {
		type: NEW_CONTACT_FORM_CHANGE,
		payload: data,
	};
};

export const editContactFormChange = (data) => {
	return {
		type: EDIT_CONTACT_FORM_CHANGE,
		payload: data,
	};
};

export const resetNewContactForm = () => {
	return {
		type: RESET_NEW_CONTACT_FORM,
	};
};

export const resetEditContactForm = () => {
	return {
		type: RESET_EDIT_CONTACT_FORM,
	};
};

export const submitNewContactForm = (data) => {
	return {
		type: ADD_NEW_CONTACT,
		payload: data,
	};
};

export const submitEditContactForm = (data) => {
	return {
		type: UPDATE_CLIENT_CONTACT_LIST,
		payload: data,
	};
};

export const clientContactToEdit = (data) => {
	return (dispatch) => {
		dispatch({
			type: CLIENT_CONTACT_TO_EDIT,
			payload: data,
		});
	};
};
export const clientOfficeChange = (data) => {
	return {
		type: CLIENT_OFFICE_CHANGE,
		payload: data,
	};
};

export const clientLinkChange = (data) => {
	return {
		type: CLIENT_LINK_CHANGE,
		payload: data,
	};
};

export const newOfficeFormChange = (data) => {
	return {
		type: NEW_OFFICE_FORM_CHANGE,
		payload: data,
	};
};

export const editOfficeFormChange = (data) => {
	return {
		type: EDIT_OFFICE_FORM_CHANGE,
		payload: data,
	};
};

export const resetNewOfficeForm = () => {
	return {
		type: RESET_NEW_OFFICE_FORM,
	};
};

export const resetEditOfficeForm = () => {
	return {
		type: RESET_EDIT_OFFICE_FORM,
	};
};

export const handleOfficeAddress = () => {
	return {
		type: HANDLE_OFFICE_ADDRESS,
	};
};

export const submitNewOfficeForm = (data) => {
	return {
		type: SUBMIT_NEW_OFFICE_FORM,
		payload: data,
	};
};

export const submitEditOfficeForm = (data) => {
	return {
		type: SUBMIT_EDIT_OFFICE_FORM,
		payload: data,
	};
};

export const addPrimaryLocation = (data) => {
	return {
		type: ADD_PRIMARY_LOCATION,
		payload: data,
	};
};

export const clientSoftwareChange = (data) => {
	return {
		type: CLIENT_SOFTWARE_CHANGE,
		payload: data,
	};
};

export const newSoftwareFormChange = (data) => {
	return {
		type: NEW_SOFTWARE_FORM_CHANGE,
		payload: data,
	};
};

export const resetNewSoftwareForm = () => {
	return {
		type: RESET_NEW_SOFTWARE_FORM,
	};
};

export const submitNewSoftwareForm = (data) => {
	return {
		type: SUBMIT_NEW_SOFTWARE_FORM,
		payload: data,
	};
};

// export const deleteClientContact = (index) => {
//     return {
//         type: DELETE_CLIENT_CONTACT,
//         payload: index,
//     };
// };

export const deleteClientOffice = (index) => {
	return {
		type: DELETE_CLIENT_OFFICE,
		payload: index,
	};
};

export const deleteLinkOffice = (index) => {
	return {
		type: DELETE_LINK,
		payload: index,
	};
};

export const handleIndustryCheckbox = (data) => {
	return {
		type: HANDLE_INDUSTRY_CHECKBOX,
		payload: data,
	};
};

export const handleTeamCheckbox = (data) => {
	return {
		type: HANDLE_TEAM_CHECKBOX,
		payload: data,
	};
};

export const handleBussinessCheckbox = (data) => {
	return {
		type: HANDLE_BUSSINESS_CHECKBOX,
		payload: data,
	};
};
export const handleStateCheckbox = (data) => {
	return {
		type: HANDLE_STATE_CHECKBOX,
		payload: data,
	};
};

export const addSoftware = (softwareData, clientId, setLoad, closeModal, setError) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `v1/client_software_stack/`,
				method: 'POST',
				data: { ...softwareData, client_id: clientId },
			});
			setError('');

			dispatch(getClient(clientId));
			setLoad(false);
			closeModal();
			dispatch(resetNewSoftwareForm());
		} catch (e) {
			//
			setLoad(false);
			setError(e.response.data.message);
		}
	};
};

export const getSoftwareDetails = (credentials) => {
	return async (dispatch) => {
		try {
			window.postMessage(
				{
					type: 'LEVVY_CREDENTIALS',
					email: credentials.account,
					password: CryptoJS.AES.decrypt(credentials.password, PROTECTION_KEY).toString(
						CryptoJS.enc.Utf8,
					),
					redirect: credentials.login,
					metaData: { ...credentials },
					clientName: credentials?.clientName,
				},
				'*',
			);
		} catch (e) {
			//
		}
	};
};

export const updateClientSoftware = (software, clientId) => {
	return async (dispatch) => {
		const softwareId = software.id;
		delete software.id;
		delete software.client_id;
		delete software.updated_at;
		delete software.index;
		software.password == '' && delete software.password;
		try {
			const request = await secure_instance.request({
				url: `v1/client_software_stack/${softwareId}`,
				method: 'patch',
				data: software,
			});

			dispatch(getClient(clientId));
		} catch (e) {
			//
		}
	};
};

export const changeModalState = (modalName) => {
	return (dispatch) => {
		dispatch({
			type: TOGGLE_CLIENT_MODAL,
			payload: modalName,
		});
	};
};

export const linkChangeHanlder = (obj) => {
	return (dispatch) => {
		dispatch({
			type: LINK_CHANGE_HANDLER,
			payload: obj,
		});
	};
};

export const editLinkChangeHanlder = (obj) => {
	return (dispatch) => {
		dispatch({
			type: EDIT_LINK_CHANGE_HANDLER,
			payload: obj,
		});
	};
};

export const resetLinkValues = () => {
	return (dispatch) => {
		dispatch({
			type: RESET_LINK,
		});
	};
};

export const setError = (error) => {
	return (dispatch) => {
		dispatch({
			type: SET_ERROR,
			payload: error,
		});
	};
};

export const addLink = (link) => {
	return (dispatch) => {
		dispatch({
			type: ADD_LINK_TO_CLIENT,
			payload: link,
		});
	};
};

export const editLink = (link) => {
	return (dispatch) => {
		dispatch({
			type: EDIT_LINK_TO_CLIENT,
			payload: link,
		});
	};
};

export const removeLink = (index) => {
	return (dispatch) => {
		dispatch({
			type: REMOVE_LINK_FROM_CLIENT,
			payload: index,
		});
	};
};

export const setClientLoader = (state) => {
	return (dispatch) => {
		dispatch({
			type: TOGGLE_CLIENT_LOAD,
			payload: state,
		});
	};
};

export const setFormLoad = (state) => {
	return (dispatch) => {
		dispatch({
			type: SET_FORM_LOAD,
			payload: state,
		});
	};
};

export const changeClientOfficeLocationIndex = (data) => {
	// console.info("aaaaa",changedArray);
	const updatedArrayData = arrayMove(data.location, data.beingDragged, data.dragTo);
	const updatedIndexdArray = updatedArrayData?.map((location, index) => ({
		...location,
		order: index,
	}));
	return (dispatch) => {
		dispatch({
			type: CHANGE_CLIENT_OFFICE_LOCATION_ARRAY_INDEX,
			payload: updatedIndexdArray,
		});
	};
};

export const changeClientContactsIndex = (data) => {
	const updatedArrayData = arrayMove(data.contact, data.beingDragged, data.dragTo);
	const updatedIndexdArray = updatedArrayData?.map((contact, index) => ({
		...contact,
		order: index,
	}));
	return (dispatch) => {
		dispatch({
			type: CHANGE_CLIENT_CONTACTS_ARRAY_INDEX,
			payload: updatedIndexdArray,
		});
	};
};

export const changeClientLinksIndex = (data) => {
	const updatedArrayData = arrayMove(data.links, data.beingDragged, data.dragTo);
	const updatedIndexdArray = updatedArrayData?.map((links, index) => ({
		...links,
		order: index,
	}));
	return (dispatch) => {
		dispatch({
			type: CHANGE_CLIENT_LINKS_ARRAY_INDEX,
			payload: updatedIndexdArray,
		});
	};
};

export const setDragValues = (data) => {
	return (dispatch) => {
		dispatch({
			type: SET_DRAG_VALUES,
			payload: data,
		});
	};
};
export const officeLocationToEdit = (data) => {
	return (dispatch) => {
		dispatch({
			type: OFFICE_LOCATION_TO_EDIT,
			payload: data,
		});
	};
};

export const linksToEdit = (data) => {
	return (dispatch) => {
		dispatch({
			type: LINKS_TO_EDIT,
			payload: data,
		});
	};
};
export const handleClearTeamCheckbox = () => {
	return {
		type: HANDLE_CLEAR_TEAM_CHECKBOX,
	};
};

export const addLocationManually = (data) => {
	return async (dispatch) => {
		const autoComplete = new window.google.maps.places.AutocompleteService();
		const result = await autoComplete.getPlacePredictions({
			input: data?.address,
			types: ['address'],
			// fields: ["address_components", "geometry", "icon", "name"],
			componentRestrictions: {
				country: ['us'],
			},
		});
		if (result.predictions.length > 0) {
			const placeId = result.predictions[0].place_id;
			const geocoder = new window.google.maps.Geocoder();
			const { results } = await geocoder.geocode({ placeId: placeId });
			if (results[0]) {
				const address = returnAddress(results[0]);

				const state = address.state
					? String(states.find((state) => state.value == address.state)?.value)
					: '';
				data.primaryLocationIndex === -1
					? dispatch({
							type: SUBMIT_NEW_OFFICE_FORM,
							payload: {
								hq: address?.fullAddress,
								street: address?.suite || '',
								city: address?.city || '',
								state: state,

								zip: address.zipCode ? address.zipCode : '',
								is_primary: true,
							},
					  })
					: dispatch({
							type: SUBMIT_EDIT_OFFICE_FORM,
							payload: {
								hq: address?.fullAddress,
								street: address?.suite || '',
								city: address?.city || '',
								state: state,
								zip: address.zipCode ? address.zipCode : '',
								is_primary: true,
								index: data?.primaryLocationIndex,
							},
					  });
				// const state=String(states2.find((state) => state.label == address.state)?.value);
				// dispatch({
				// 	type: HANDLE_LOCATION_AUTOFILL,
				// 	payload: {
				// 		zip:address?.zipCode,
				// 		city:address?.city,
				// 		state:state,
				// 	},
				// });
			}
		}
	};
};

export const addNewField = (data) => {
	return (dispatch) => {
		dispatch({
			type: ADDING_NEW_FIELD,
			payload: data,
		});
	};
};

export const removeContactField = (data) => {
	return (dispatch) => {
		dispatch({
			type: REMOVING_CONTACT_FIELD,
			payload: data,
		});
	};
};
export const handleClearStateCheckbox = () => {
	return {
		type: HANDLE_CLEAR_STATE_CHECKBOX,
	};
};

export const handleClearBusinessCheckbox = () => {
	return {
		type: HANDLE_CLEAR_BUSINESS_CHECKBOX,
	};
};

export const handleClearIndustryCheckbox = () => {
	return {
		type: HANDLE_CLEAR_INDUSTRY_CHECKBOX,
	};
};

export const clientInvoiceFormChangeHanlder = (name, value) => {
	return (dispatch) => {
		dispatch({
			type: CLIENT_INVOICE_FORM_CHANGE_HANDLER,
			payload: { name, value },
		});
	};
};

export const setClientInvoice = (obj) => {
	return (dispatch) => {
		dispatch({
			type: SET_CLIENT_INVOICE,
			payload: obj,
		});
	};
};

export const getClientInvoices = (clientId, date) => {
	return async (dispatch) => {
		try {
			dispatch(setClientLoader(true));

			const request = await secure_instance.request({
				url: `/v1/client_invoices/?client_id=${clientId}&from_date=${date.from}&to_date=${date.to}`,
				method: 'Get',
			});

			dispatch({
				type: BILLING_WORKFLOW_TASK_DETAILS,
				payload: request.data,
			});

			dispatch(setClientLoader(false));
		} catch (error) {
			//
			dispatch(setClientLoader(false));
		}
	};
};

export const resetBillingWorkflowTaskDetails = () => {
	return (dispatch) => {
		dispatch({
			type: BILLING_WORKFLOW_TASK_DETAILS,
			payload: {},
		});
	};
};

export const selectAllClientInvoice = (state, index) => {
	return (dispatch) => {
		dispatch({
			type: HANDLE_SELECT_ALL_CLIENT_INVOICE,
			payload: { state, index },
		});
	};
};

export const selectAllClientWorkflowsInvoice = (workflowName, state, index) => {
	return (dispatch) => {
		dispatch({
			type: HANDLE_SELECT_ALL_CLIENT_WORKFLOW_INVOICE,
			payload: { workflowName, state, index },
		});
	};
};

export const selectClientUserInvoice = (workflowName, userId, state, index) => {
	return (dispatch) => {
		dispatch({
			type: HANDLE_SELECT_CLIENT_USER_INVOICE,
			payload: { userId, state, workflowName, index },
		});
	};
};

export const clientUserInvoiceChangeHandler = (value, name, index, workflow, billingIndex) => {
	return (dispatch) => {
		dispatch({
			type: CLIENT_USER_INVOICE_CHANGE_HANDLER,
			payload: { value, name, index, workflow, billingIndex },
		});
	};
};

export const clientUserInvoiceChangesDone = () => {
	return (dispatch) => {
		dispatch({
			type: CLIENT_USER_INVOICE_CHANGES_DONE,
		});
	};
};

export const getWorkflowMyTasks = (clientId, setLoading) => {
	return async (dispatch) => {
		try {
			!setLoading && dispatch(setClientLoader(true));
			const request = await secure_instance.request({
				url: `/v1/tasks/workflow_my_tasks/?client_id=${clientId}`,
				method: 'Get',
			});

			dispatch({
				type: GET_WORKFLOW_MY_TASKS,
				payload: request.data,
			});
		} catch (error) {
			//
		} finally {
			if (setLoading) {
				setLoading(false);
			} else {
				dispatch(setClientLoader(false));
			}
		}
	};
};

export const getMeetingMyTasks = (clientId) => {
	return async (dispatch) => {
		try {
			dispatch(setClientLoader(true));
			const request = await secure_instance.request({
				url: `/v1/my_tasks/?client_id=${clientId}`,
				method: 'Get',
			});
			console.log(request?.data, 'response');
			dispatch({
				type: GET_MEETING_MY_TASKS,
				payload: request.data,
			});
			dispatch(setClientLoader(false));
		} catch (error) {
			dispatch(setClientLoader(false));
			//
		}
	};
};

export const deleteClientMeetingTask = (taskId, delete_future) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/my_tasks/?task_id=${taskId}&delete_future=${delete_future}`,
				method: 'delete',
			});

			dispatch({
				type: DELETE_CLIENT_MEETING_TASK,
				payload: request.data,
			});

			return request.data;
		} catch (error) {
			throw new Error(
				error?.response?.data?.message || error?.message || 'Something went wrong',
			);
		}
	};
};

export const deleteWorkflowMyTasks = (groupKey, ClientId, current_task_id, del_current) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/tasks/workflow_my_tasks/?group_key=${groupKey}&current_task_id=${current_task_id}&del_current=${del_current}`,
				method: 'Delete',
			});

			request && dispatch(getWorkflowMyTasks(ClientId));
		} catch (error) {
			if (error.response.data.message) {
				alert(error.response.data.message);
			}
		}
	};
};

export const updateWorkflowMyTask = (repeatTime, taskDetails, setLoading, toggleModal) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/tasks/workflow_my_tasks/?repeat_time=${repeatTime}`,
				method: 'Patch',
				data: taskDetails,
			});
			request && dispatch(getWorkflowMyTasks(taskDetails?.client_id, setLoading));
		} catch (error) {
			if (error.response.data.message) {
				alert(error.response.data.message);
			}
		} finally {
			setLoading(false);
			toggleModal();
		}
	};
};

export const addClientContact = (data) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: '/v1/client_contacts/',
				method: 'Post',
				data,
			});

			dispatch({
				type: ADD_NEW_CONTACT,
				payload: request.data,
			});

			return request.data;
		} catch (error) {
			throw new Error(
				error?.response?.data?.message || error?.message || 'Something went wrong',
			);
		}
	};
};

export const updateClientContact = (data) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/client_contacts/${data?.id}?expand=team_client_permissions.team.tenant_profile`,
				method: 'Patch',
				data,
			});

			dispatch({
				type: UPDATE_CLIENT_CONTACT_LIST,
				payload: request.data,
			});

			return request.data;
		} catch (error) {
			throw new Error(
				error?.response?.data?.message || error?.message || 'Something went wrong',
			);
		}
	};
};

export const deleteClientContact = (clientContactId) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/client_contacts/${clientContactId}`,
				method: 'Delete',
			});

			dispatch({
				type: DELETE_CLIENT_CONTACT,
				payload: clientContactId,
			});

			return request?.data;
		} catch (error) {
			throw new Error(
				error?.response?.data?.message || error?.message || 'Something went wrong',
			);
		}
	};
};

export const addClientLocation = (data, callBack) => {
	return async (dispatch) => {
		try {
			dispatch(setError(''));
			const request = await secure_instance.request({
				url: '/v1/client_locations/',
				method: 'Post',
				data,
			});

			dispatch({
				type: SUBMIT_NEW_OFFICE_FORM,
				payload: request.data,
			});

			callBack?.onLocationAddSuccess && callBack?.onLocationAddSuccess(request.data);
		} catch (e) {
			callBack?.onLocationAddFailed &&
				callBack?.onLocationAddFailed(e?.response?.data?.message);
		}
	};
};

export const updateClientLocation = (data, callBack) => {
	return async (dispatch) => {
		try {
			dispatch(setError(''));
			const request = await secure_instance.request({
				url: `/v1/client_locations/${data?.id}`,
				method: 'Patch',
				data,
			});

			dispatch({
				type: SUBMIT_EDIT_OFFICE_FORM,
				payload: request.data,
			});

			callBack?.onLocationAddSuccess && callBack?.onLocationAddSuccess(request.data);
		} catch (e) {
			callBack?.onLocationAddFailed &&
				callBack?.onLocationAddFailed(e?.response?.data?.message);
		}
	};
};

export const deleteClientLocation = (data, index) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/client_locations/${data?.id}`,
				method: 'Delete',
			});

			dispatch({
				type: DELETE_CLIENT_OFFICE,
				payload: index,
			});
		} catch (e) {
			dispatch(setError(e.response.data.message));
		}
	};
};

// CLIENT SHARED LINK
export const addClientSharedLink = (data) => {
	return async (dispatch) => {
		try {
			dispatch(setError(''));

			const request = await secure_instance.request({
				url: 'v1/client_shared_links/',
				method: 'POST',
				data,
			});

			dispatch({
				type: ADD_LINK_TO_CLIENT,
				payload: request.data,
			});
		} catch (e) {
			dispatch(setError(e.response.data.message));
		}
	};
};

export const editClientSharedLink = (data, linkIndex) => {
	return async (dispatch) => {
		try {
			dispatch(setError(''));

			const request = await secure_instance.request({
				url: `v1/client_shared_links/${data.id}`,
				method: 'PATCH',
				data,
			});

			request.data.index = linkIndex;

			dispatch({
				type: EDIT_LINK_TO_CLIENT,
				payload: request.data,
			});
		} catch (e) {
			dispatch(setError(e.response.data.message));
		}
	};
};

export const removeClientSharedLink = (id, linkIndex) => {
	return async (dispatch) => {
		try {
			dispatch(setError(''));
			const request = await secure_instance.request({
				url: `/v1/client_shared_links/${id}`,
				method: 'Delete',
			});
			request.data.index = linkIndex;
			dispatch({
				type: REMOVE_LINK_FROM_CLIENT,
				payload: request.data.index,
			});
		} catch (e) {
			dispatch(setError(e.response.data.message));
		}
	};
};

export const setUserTeamRole = (role) => {
	return (dispatch) => {
		dispatch({
			type: SET_USER_TEAM_ROLE,
			payload: role,
		});
	};
};

export const getSpecificFolder = (id) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/documents/${id}`,
				method: 'Get',
			});

			dispatch({
				type: GET_SPECIFIC_FOLDER,
				payload: request.data,
			});
		} catch (e) {
			//
		}
	};
};

export const getSpecificFolderName = (id) => {
	return async () => {
		try {
			const request = await secure_instance.request({
				url: `/v1/documents/${id}`,
				method: 'Get',
			});

			return request.data;
		} catch (e) {
			//
		}
	};
};

export const updateDocumentReducer = (data) => {
	return (dispatch) => {
		dispatch({
			type: UPDATE_DOCUMENT,
			payload: data,
		});
	};
};
export const updateFolderReducer = (data, targetFolderId, dragOverFolder) => {
	return (dispatch) => {
		dispatch({
			type: UPDATE_FOLDER,
			payload: { data, targetFolderId, dragOverFolder },
		});
	};
};

export const updateDocument = (id, data) => {
	return async () => {
		try {
			const request = await secure_instance.request({
				url: `/v1/documents/${id}`,
				method: 'Patch',
				data: data,
			});
			return request.data;
		} catch (e) {
			throw new Error(e.response?.data?.message || e.message || 'Something went Wrong');
		}
	};
};

export const deleteDocument = (id) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/documents/${id}`,
				method: 'Delete',
			});

			dispatch({
				type: DELETE_DOCUMENT,
				payload: request.data,
			});

			return request.data;
		} catch (e) {
			throw new Error(e.response?.data?.message || e.message || 'Something went Wrong');
		}
	};
};

export const getAllFoldersAndFiles = (id) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/documents/?parent_folder_id=${id}`,
				method: 'Get',
			});

			dispatch({
				type: GET_ALL_FOLDERS_AND_FILES,
				payload: { data: request.data, id: id },
			});
		} catch (e) {
			//
		}
	};
};
export const getAllFoldersAndFilesForMOdal = (id) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/documents/?parent_folder_id=${id}`,
				method: 'Get',
			});

			dispatch({
				type: GET_ALL_FOLDERS_AND_FILES_FOR_MODAL,
				payload: { data: request.data, id: id },
			});
		} catch (e) {
			//
		}
	};
};

export const getMultipleFoldersAndFiles = (ids) => {
	return async (dispatch) => {
		const folderIdsQuery = ids
			?.map((arr, index) => {
				if (index === ids.length - 1) {
					return 'ids[]=' + arr;
				} else {
					return 'ids[]=' + arr + '&';
				}
			})
			.join('');
		try {
			const request = await secure_instance.request({
				url: `/v1/documents/list?${folderIdsQuery}`,
				method: 'Get',
			});

			dispatch({
				type: GET_MULTIPLE_FOLDERS_AND_FILES,
				payload: request.data,
			});
		} catch (e) {
			//
		}
	};
};

export const createFolder = (data) => {
	return async (dispatch) => {
		try {
			const response = await secure_instance.request({
				url: '/v1/documents/',
				method: 'POST',
				data: data,
			});

			dispatch({
				type: CREATE_FOLDER,
				payload: response.data,
			});

			dispatch(setSnackBarIsOpen(true, true, 'Folder created successfully'));
		} catch (error) {
			if (error?.response?.data?.message == 'Document name already exists') {
				dispatch(setSnackBarIsOpen(false, true, 'Folder with same name already exists'));
			} else {
				dispatch(setSnackBarIsOpen(false, true, 'Error while creating folder!!!'));
			}
		}
	};
};

export const uploadDocument = (id, data) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/documents/file`,
				method: 'Post',
				data: data,
			});

			dispatch({
				type: UPLOAD_DOCUMENT,
				payload: request.data,
			});
			dispatch(setSnackBarIsOpen(true, true, 'Document uploaded succesfully'));
		} catch (e) {
			//
			dispatch(setSnackBarIsOpen(false, true, 'Error while uploading document'));
		}
	};
};

export const handleWorkflowAttachments = (id, data) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/workflow_attachments/${id}`,
				method: 'PATCH',
				data: data,
			});

			return request.data;
		} catch (e) {
			//
		}
	};
};

export const setFolderSelected = (item) => {
	return (dispatch) => {
		dispatch({
			type: SET_FOLDER_SELECTED,
			payload: item,
		});
	};
};

export const setFoldersAndFiles = (folders) => {
	return (dispatch) => {
		dispatch({
			type: SET_FOLDERS_AND_FILES,
			payload: folders,
		});
	};
};
export const setClientProfileActiveTab = (tab) => {
	return (dispatch) => {
		dispatch({
			type: SET_CLIENT_PROFILE_ACTIVE_TAB,
			payload: tab,
		});
	};
};

export const updateFolderData = (data) => {
	return (dispatch) => {
		dispatch({
			type: UPDATE_FOLDER_DATA,
			payload: data,
		});
	};
};

export const createFile = (data, file) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/documents/file`,
				method: 'Post',
				data: data,
				onUploadProgress: (event) => {
					const perc = parseFloat(((event.loaded / event.total) * 100).toFixed(0));
					dispatch({
						type: UPLOAD_FILE,
						payload: { file, pc: perc },
					});
				},
			});

			dispatch({
				type: UPLOAD_IMAGE_PROGRESS,
				payload: { file, status: 1 },
			});

			return request.data;
		} catch (e) {
			dispatch({
				type: UPLOAD_IMAGE_PROGRESS,
				payload: {
					file,
					status: -1,
					error: e.response?.data?.message || e.message || 'File Upload Failed',
				},
			});
			throw new Error(e.response?.data?.message || e.message || 'File Upload Failed');
		}
	};
};

export const resetUploadState = () => ({
	type: RESET_UPLOAD_STATE,
});

export const createFileReducer = (data) => {
	return async (dispatch) => {
		dispatch({
			type: CREATE_FILE,
			payload: data,
		});
	};
};

export const checkDefaultFolder = (id) => {
	return async () => {
		try {
			const request = await secure_instance.request({
				url: `/v1/workflow_attachments/is_set_default?folder_id=${id}`,
				method: 'GET',
			});

			return request.data;
		} catch (e) {
			throw new Error(e.response?.data?.message || e.message || 'Something went Wrong');
		}
	};
};

export const uploadAutomateFile = (file) => {
	return async () => {
		try {
			const formData = new FormData();
			formData.append('file', file);

			const request = await secure_instance.request({
				url: 'v1/llm_requests/process_doc',
				method: 'post',
				data: formData,
			});

			return JSON.parse(request?.data?.data);
		} catch (e) {
			throw new Error(e?.response?.data?.message || e?.message || 'Something went Wrong');
		}
	};
};

export const saveClientPreviewInformation = (clientInfo) => {
	return async () => {
		try {
			const request = await secure_instance.request({
				url: `v1/clients/${clientInfo?.id}`,
				method: 'Patch',
				data: clientInfo,
			});

			return request;
		} catch (error) {
			throw new Error(
				error?.response?.data?.message || error?.message || 'Something went Wrong',
			);
		}
	};
};

export const saveClientPreviewOfficeInformation = (data) => {
	return async () => {
		try {
			const request = await secure_instance.request({
				url: '/v1/client_locations/',
				method: 'Post',
				data,
			});

			return request;
		} catch (error) {
			throw new Error(
				error?.response?.data?.message || error?.message || 'Something went Wrong',
			);
		}
	};
};

export const setWorkflowsToAddForClientPreview = (workflows = []) => {
	return {
		type: SET_WORKFLOW_TO_ADD_FOR_CLIENT_PREVIEW,
		payload: workflows,
	};
};
