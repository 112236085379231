

function Privacy() {
    const privacy_policy_link = 'https://levvy.com/privacy-policy/';

	return (
		<p className='BodyTwoLight'>
			For information about how we collect, use, share, and otherwise process
			information about you, please see our{' '}
			<a className='primary-text' rel="noreferrer" href={privacy_policy_link} target='_blank'>
				Privacy Policy
			</a>
		</p>
	);
}

export default Privacy;
