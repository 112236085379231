import { DATE_FORMATS } from '../utilities/utilities';

// Format Examples
// date_format: MM/DD/YYYY
// short_date_format: MM/DD
// full_month_name_format: MMMM Do YYYY

export function getUserSettings(userDetails) {
    return { date_format: userDetails?.date_format || 'MM/DD/YYYY' , short_date_format: DATE_FORMATS[userDetails?.date_format]?.short || 'MM/DD' , full_month_name_format: DATE_FORMATS[userDetails?.date_format]?.full_month_name || 'MMMM Do YYYY'  };
}
