import DummyModal from './DummyModal';
import { CircularProgress, Modal } from '@mui/material';
import SubmitButtonsField from '../Buttons/SubmitButtonsField';

function ChangeFolderTaskModal({
	modalIsOpen,
	toggleModal,
	handleUploadChange,
	selectedItem,
	actionLoading,
	tabs,

}) {
	return (
		<>
			<div>
				{modalIsOpen && (
					<Modal
						onClose={toggleModal}
						open={modalIsOpen}
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<>
							<DummyModal
								title='Change Folder'
								onClose={toggleModal}
								style={{
									width: '800px',
									padding: '24px',
								}}
							>
								<div className='Profile-Content'>
									<div
										style={{
											padding: '0px 24px',
										}}
									>
										<span className='BodyTwoLight'>
											Select folder to move this to
										</span>
									</div>
									<div
										style={{
											padding: '24px',
											maxHeight: '70vh',
											overflowY: 'scroll',
										}}
									>
										<div className='task-tabs-details-container'>
											{tabs[4]?.tabInputs}
										</div>
									</div>
									<div className='VerticalSpacer bg-white p-3 rounded-bottom'>
										<SubmitButtonsField child={true}>
											<button
												id='Element'
												type='button'
												className='secondary'
												style={{ borderRadius: '50px', width: '125px' }}
												onClick={toggleModal}
											>
												Cancel
											</button>

											<button
												type='submit'
												className='d-flex align-items-center'
												style={{ borderRadius: '50px', width: '125px' }}
												id='Element'
												disabled={actionLoading || selectedItem == null}
												data-cy='add-client-btn'
												onClick={(e) => handleUploadChange(e)}
											>
												{actionLoading ? (
													<CircularProgress
														size={24}
														sx={{ color: 'white' }}
														disableShrink
													/>
												) : (
													'Move'
												)}
											</button>
										</SubmitButtonsField>
									</div>
								</div>
							</DummyModal>
						</>
					</Modal>
				)}
			</div>
		</>
	);
}

export default ChangeFolderTaskModal;
