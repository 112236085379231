import DummyModal from './DummyModal';
import { Modal } from '@mui/material';
import Rename from '../sub_components/DocumentStorage/Rename';

function RenameFolderModal({ modalIsOpen, toggleModal, item, isFolder }) {
	return (
		<div>
			{modalIsOpen && (
				<Modal
					onClose={toggleModal}
					open={modalIsOpen}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<>
						<DummyModal title='Rename Folder' onClose={toggleModal}>
							<Rename item={item} onCancel={toggleModal} isFolder={isFolder} />
						</DummyModal>
					</>
				</Modal>
			)}
		</div>
	);
}

export default RenameFolderModal;
