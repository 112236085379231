/* eslint-disable no-mixed-spaces-and-tabs */
import { useEffect, useRef, useState } from 'react';
import { SERVER_SENT_EVENTS } from '../utilities/utilities';
import WeeklyCalendar from '../components/WorkSpace/WeeklyCalendar';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import * as WorkSpaceActions from '../redux/actions/dashboard/workspaces-actions';
import { CircularProgress, Modal } from '@mui/material';
import * as clientAccountActions from '../redux/actions/client-accounts-actions';
import AddMyTaskModal from '../components/WorkSpace/Task Modal/AddMyTaskModal';
import MonthlyCalendar from '../components/WorkSpace/MonthlyCalendar';
import WorkspaceSidebar from '../components/WorkSpace/WorkspaceSidebar';
import EventSource from '../EventSource/EventSource';
import { evnetParseHandler } from '../EventSource/EventSourceHandler';
import AlertBar from '../components/sub_components/AlertBar';

export default function WorkSpace() {
	const dispatch = useDispatch();
	const handleTaskTimerRef = useRef();


	const [modalIsOpen, setModalIsOpen] = useState(false);

	const monthlyView = useSelector((state) => state.workSpaceReducer.monthlyView);
	const event_source = useSelector((state) => state.workSpaceReducer.eventSource);

	const formRef = useRef({});

	const userStatus = useSelector((state) => state.actionCenterReducer.userStatus);

	const loading = useSelector((state) => state.workSpaceReducer.loading);
	const showTeamMembersMeetings = useSelector(
		(state) => state.workSpaceReducer.showTeamMembersMeetings,
	);
	const weeklyClientTasks = useSelector((state) => state.workSpaceReducer.weeklyClientTasks);
	const weeklyClientMeetingTasks = useSelector(
		(state) => state.workSpaceReducer.weeklyClientMeetingTasks,
	);
	const [viewSideBar, SetVewSideBar] = useState(false);
	const [actionLoading, setActionLoading] = useState(false);

	const submitForm = (taskCategoryType) => {
		setActionLoading(true);
		const form = formRef.current;
		let formDetails = { ...form, type: taskCategoryType };
		if (taskCategoryType == 'OFFICE') {
			const date = moment(form?.due_date).format('YYYY-MM-DD');
			const startDateTime = moment(
				date + ' ' + form.start_time,
				'YYYY-MM-DD HH:mm:ss a',
			).utc();
			const endDateTime = moment(date + ' ' + form.end_time, 'YYYY-MM-DD HH:mm:ss a').utc();
			const task_date = moment(startDateTime).format('YYYY-MM-DD');

			formDetails = {
				...formDetails,
				type: form?.task_type_id == 1 ? 'ONE_TIME' : 'RECURRING',
				task_type: 'MEETING',
				assignee_list: form?.meeting_guests || [],
				start_date: task_date,
				client_id: form?.client_id || null,
				is_monthly_on_day: form.is_monthly_on_day,
				required_days: 1,
				repeat_interval: form?.repeat_interval ? Number(form?.repeat_interval) : null,
				metadata: {
					meeting_platform: form?.meeting_platform || null,
					meeting_link: form?.meeting_link || null,
				},
				start_time: moment(startDateTime, 'hh:mm A').format('hh:mm:ss a') || null,
				end_time: moment(endDateTime, 'hh:mm A').format('hh:mm:ss a') || null,
				is_billable: formDetails?.is_billable == 1 ? true : false,
				color: form?.color,
			};
			delete formDetails.meeting_platform;
			delete formDetails.meeting_link;
			delete formDetails.meeting_guests;
			delete formDetails.task_type_id;
			delete formDetails?.assignee_user_id;
			delete formDetails?.due_date;
		} else if (taskCategoryType === 'WORKFLOW') {
			formDetails = {
				...formDetails,
				is_billable: formDetails?.is_billable == 1 ? true : false,
				assignee_list: formDetails?.assignee_user_id ? [formDetails?.assignee_user_id] : [],
				task_type: 'ADHOC',
				client_workflow_id: formDetails?.client_workflow_id || null,
				type: 'ONE_TIME',
				start_date: moment(formDetails?.due_date).format('YYYY-MM-DD'),
				is_approval_required: formDetails?.is_approval_required
					? formDetails?.is_approval_required
					: false,
				required_days: Number(formDetails?.required_days) || 1,
			};
			delete formDetails?.due_date;
			delete formDetails?.assignee_user_id;
			delete formDetails?.task_type_id;
		}

		dispatch(WorkSpaceActions.createMeetingTask(formDetails))
			.then(() => {
				setActionLoading(false);
				toggleModal();
			})
			.catch(() => {
				setActionLoading(false);
			});
	};

	const toggleModal = () => {
		setModalIsOpen(!modalIsOpen);
	};
    
	useEffect(() => {
		dispatch(WorkSpaceActions.getFilteredWorkspace());
		dispatch(clientAccountActions.getAllUrls());

		dispatch(WorkSpaceActions.getWorkspaceTeams());
		dispatch(WorkSpaceActions.getFilteredWorkflows());
	}, [dispatch, userStatus]);

	useEffect(() => {
		if (!modalIsOpen) {
			dispatch(WorkSpaceActions.resetCreateTaskForm());
		}
	}, [modalIsOpen]);

	useEffect(() => {
		if (weeklyClientTasks !== null) dispatch(WorkSpaceActions.setClientTasksLoading(false));
	}, [weeklyClientTasks]);

	useEffect(() => {
		if (showTeamMembersMeetings && weeklyClientMeetingTasks !== null) {
			dispatch(WorkSpaceActions.setMeetingTasksLoading(false));
		}
	}, [weeklyClientMeetingTasks, showTeamMembersMeetings]);

	useEffect(() => {
		if (event_source) {
			EventSource.addListener(
				SERVER_SENT_EVENTS.WORKSPACE_UPDATED,
				evnetParseHandler,
				event_source,
			);
		} else {
			EventSource.connect();
		}
		return () => {
			if (event_source) {
				EventSource.removeListener(
					SERVER_SENT_EVENTS.WORKSPACE_UPDATED,
					evnetParseHandler,
					event_source,
				);
				EventSource.disconnect(event_source);
			}
		};
	}, [event_source]);

	return (
		<div className='d-flex' style={{ height: 'auto', width: 'auto', overflow: 'hidden' }}>
			<WorkspaceSidebar
				monthlyView={monthlyView}
				setModalIsOpen={setModalIsOpen}
				SetVewSideBar={SetVewSideBar}
			/>
			<div
				style={{
					width: 'calc(100vw - 260px)',
					height: '100vh',
					overflow: monthlyView ? 'hidden' : 'auto',
					backgroundColor: '#EEF2F3',
				}}
			>
				<AlertBar />
				<>
					{monthlyView ? (
						<MonthlyCalendar
							viewSideBar={viewSideBar}
							SetVewSideBar={SetVewSideBar}
						/>
					) : (
						<WeeklyCalendar handleTaskTimerRef={handleTaskTimerRef} />
					)}
				</>
				{loading && monthlyView && (
					<div
						style={{
							height: '100%',
							width: 'inherit',
							backgroundColor: 'rgba(0, 0, 0, 0.05)',
							position: 'fixed',
							top: 0,
							bottom: 0,
						}}
					>
						<CircularProgress
							style={{
								color: '#fa7b03',
								height: '50px',
								width: '50px',
								marginTop: '47vh',
							}}
						/>
					</div>
				)}
			</div>
			{modalIsOpen && (
				<Modal
					onClose={toggleModal}
					open={modalIsOpen}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<AddMyTaskModal
						formRef={formRef}
						onCancel={toggleModal}
						onSubmit={submitForm}
						actionLoading={actionLoading}
					/>
				</Modal>
			)}
		</div>
	);
}
