import FilterMultiplePickList from './FilterMultiplePickList';
import SectionHeader from './SectionHeader';

function Filter({ onClear, title, options, onChange, onClick, expandable }) {
	const appliedFilters = options?.filter((option) => option?.is_checked);
	return (
		<div className='FilterSpacing-1'>
			<SectionHeader
				label='Clear'
				title={title}
				onClear={onClear}
				filtersApplied={appliedFilters?.length ? appliedFilters?.length : undefined}
			/>
			<FilterMultiplePickList
				onClick={onClick || null}
				className='high'
				options={options?.length > 0 ? options : []}
				onChange={onChange}
				expandable={expandable}
			/>
		</div>
	);
}

export default Filter;
