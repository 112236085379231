import { memo } from 'react';

const TableHeaderItem = ({ label, width, children, ClassName, check, margin, labelClass }) => {
	return (
		<div
			className={'d-flex TableHeaderItem ' + ClassName}
			style={{ width: check ? `${width}` : `calc(${width})` }}
		>
			{children && children}
			<h3 className={margin + ` BodyTwoLight m-0 text-left table-header-text ${labelClass}`}>
				{label && label}
			</h3>
		</div>
	);
};

export default memo(TableHeaderItem);
