import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router';
import * as authActions from '../../redux/actions/auth-actions';
import { validateEmailError, requiredError } from '../../utilities/utilities';
import PasswordInputIcon from '../Others/PasswordInputIcon';

const LoginForm = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const search = useLocation().search;

	const zapierClientId = new URLSearchParams(search).get('client_id');
	const zapierRedirectUri = new URLSearchParams(search).get('redirect_uri');
	const zapierState = new URLSearchParams(search).get('state');

	const form = useSelector((state) => state.authReducer.loginForm);
	const errors = useSelector((state) => state.authReducer.loginFormErrors);
	const loading = useSelector((state) => state.authReducer.loginFormLoading);

	const [isPasswordShow, setIsPasswordShow] = useState(false);

	const togglePasswordShow = () => {
		setIsPasswordShow(!isPasswordShow);
	};

	const handleChange = (event) => {
		event.preventDefault();

		const { name, value } = event.target;
		dispatch(authActions.loginFormChange({ name, value }));
	};

	const handleSubmit = (event) => {
		event.preventDefault();

		dispatch(
			authActions.setLoginFormErrors({
				name: 'emailErrorText',
				value: validateEmailError(form.email),
			}),
		);

		dispatch(
			authActions.setLoginFormErrors({
				name: 'passwordErrorText',
				value: requiredError(form.password),
			}),
		);

		if (
			!requiredError(form.email) &&
			!requiredError(form.password) &&
			!validateEmailError(form.email)
		) {
			dispatch(
				authActions.login(form, history, zapierClientId, zapierRedirectUri, zapierState),
			);
		}
	};
	return (
		<>
			<div className='LoginBox formText'>
				<div className=''>
					<p className='titleBold'>Login</p>
				</div>

				<form autoComplete='off' onSubmit={handleSubmit}>
					<div className=''>
						<label htmlFor='email' className=''>
							<div className='d-flex align-items-end'>
								<div className=' d-flex align-items-end'>Email &nbsp; </div>
								<div style={{ color: '#F05544' }} className=''>
									*
								</div>
							</div>
						</label>
						<div>
							<input
								type='text'
								id='email'
								name='email'
								value={form.email}
								onChange={handleChange}
								style={{ background: '#F6F5F2' }}
							/>
						</div>
						<span className='m8 error'>{errors.emailErrorText}</span>
					</div>
					<div className=''>
						<label htmlFor='password'>
							<div className='d-flex align-items-end'>
								<div className=' d-flex align-items-end'>Password &nbsp; </div>
								<div style={{ color: '#F05544' }} className=''>
									*
								</div>
							</div>
						</label>
						<div className='w-100 position-relative'>
							<input
								type={isPasswordShow ? 'text' : 'password'}
								id='password'
								name='password'
								value={form.password}
								onChange={handleChange}
								style={{ background: '#F6F5F2' }}
							/>
							<PasswordInputIcon
								showPasswordState={isPasswordShow}
								togglePasswordShowState={togglePasswordShow}
							/>
						</div>
						<span className='m8 error'>{errors.passwordErrorText}</span>
						<span className='m8 error'>{errors.errorText}</span>
					</div>

					<div className='m16 justify-content-start d-flex'>
						<div>
							<a
								onClick={() => {
									// dispatch(updateLoginStep('ForgetPassword'));
									// alert('called')
									history.push('forgetpassword');
								}}
								style={{
									color: '#Fb7a03',
									fontWeight: 400,
									background: '#Fffefb',
									border: 0,
									padding: 0,
									height: 'fit-content',
									cursor: 'pointer',
								}}
							>
								Forgot Password
							</a>
						</div>
					</div>
					<div className='m16'>
						<div className='' style={{ display: 'grid' }}>
							<button
								type='submit'
								value='Submit'
								style={{ background: '#FB7A03', opacity: loading ? 0.5 : 1 }}
								className=''
								disabled={loading}
							>
								{loading ? (
									<div
										className='spinner-border spinner-border-sm text-light'
										role='status'
									></div>
								) : (
									`Login`
								)}
							</button>
						</div>
					</div>
				</form>
				<div className=''>
					<div className='dfCenter m16'>
						<Link
							to={`/signup${search}`}
							style={{ color: '#FB7A03', fontSize: '14px', lineHeight: '16px' }}
						>
							{`Don't have an account? Join Now`}
						</Link>
					</div>
				</div>
			</div>
		</>
	);
};
export default LoginForm;
