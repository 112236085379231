import { CircularProgress } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SubmitButtonsField from '../../Buttons/SubmitButtonsField';
import * as clientActions from '../../../redux/actions/client-actions';

function AddLink({ onCancel, beingEdited }) {
	const [isLoading, setIsLoading] = useState(false);
	const dispatch = useDispatch();
	const link = useSelector((state) => state.clientReducer.link);
	const editLink = useSelector((state) => state.clientReducer.linksToEdit);
	const errors = useSelector((state) => state.clientReducer.error);
	const links = useSelector((state) => state.clientReducer.client?.links);
    const client = useSelector((state) => state.clientReducer.client);

	const onChange = (e) => {
		const { name, value } = e.target;
		if (!beingEdited) {
			dispatch(clientActions.linkChangeHanlder({ name: name, value: value }));
		} else {
			dispatch(
				clientActions.editLinkChangeHanlder({ name: name, value: value }),
			);
		}
	};

	const toggleLoader = (state) => {
		setIsLoading(state)
	}

	const hanldeSubmit = (e) => {
		e.preventDefault();
		toggleLoader(true)

		if (!beingEdited) {
			dispatch(clientActions.addClientSharedLink({ ...link, order: links?.length, client_id: client?.id })).then(() =>{
				toggleLoader(false)
				onCancel();
			});
		} else {
			if(editLink?.index){
				var linkIndex = editLink.index;
				delete editLink.index;
			}
			dispatch(clientActions.editClientSharedLink(editLink, linkIndex)).then(() => {
				toggleLoader(false)
				onCancel();
			});			
		}

		dispatch(clientActions.resetEditContactForm());
	};

	return (
		<form autoComplete='off' onSubmit={hanldeSubmit}>
			<div className='p-3'>
				<div className='improved-input-designs pb-3'>
					<label className='w-100 require'>Name</label>
					<input
						className='w-100'
						type='text'
						name='name'
						onChange={onChange}
						value={beingEdited ? editLink?.name : link?.name}
						required
					/>
				</div>

				<div className='improved-input-designs pb-3'>
					<label className='require'>URL</label>
					<input
						className='w-100'
						type='text'
						name='login'
						onChange={onChange}
						value={beingEdited ? editLink?.login : link?.login}
						required
					/>
				</div>

				<div className='improved-input-designs'>
					<label className=''>Notes</label>
					<input
						className='w-100'
						type='text'
						name='notes'
						onChange={onChange}
						value={beingEdited ? editLink?.notes : link?.notes}
					/>
				</div>

				<div className='pt-3'>
					<span style={{ color: 'red', fontSize: '0.9em' }}>{errors}</span>
				</div>
			</div>

			<div className='VerticalSpacer bg-white p-3 rounded-bottom'>
				<SubmitButtonsField child={true}>
					<button
						id='Element'
						type='button'
						className='secondary'
						onClick={onCancel}
					>
						Cancel
					</button>

					<button
						className='d-flex align-items-center'
						id='Element'
						type='submit'
						disabled={isLoading}
					>
						{isLoading ? (
							<CircularProgress
								size={24}
								sx={{ color: 'white' }}
								disableShrink
							/>
						) : (
							'Submit'
						)}
					</button>
				</SubmitButtonsField>
			</div>
		</form>
	);
}

export default AddLink;
