import { CircularProgress } from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PickList from '../../Inputs/PickList';
import SecondaryCard from '../../Others/SecondaryCard';
import TableHeader from '../../Others/TableHeader';
import TableHeaderItem from '../../Others/TableHeaderItem';
import TableRowItem from '../../Others/TableRowItem';
import {
    TableItemWidth2,
    TableItemWidth3,
    TableItemWidth5,
    TableItemWidth6,
} from '../../TableSpacing/TableSpacingStyles';
import * as clientActions from '../../../redux/actions/client-actions';

function ArchieveTeamClient({ teamId }) {
    const dispatch = useDispatch();

    const [selectedArchive, setSelectedArchive] = useState([]);

    const clients = useSelector((state) => state.clientReducer.clients);
    const Teams = useSelector((state) => state.teamReducer.teams)?.filter(
        (item) => item.id != teamId,
    );

    const archiveClient = (client) => {
        setSelectedArchive([...selectedArchive, client.id]);
        dispatch(
            clientActions.deActivateWorklowsList(null, client.id, {
                success: () => successCallBack(client.id),
                fail: () => failCallback(client.id),
            }),
        );
    };

    const successCallBack = (clientId) => {
        setTimeout(() => {
            dispatch(clientActions.getClients(teamId));
            setSelectedArchive(
                selectedArchive?.filter((client) => client != clientId),
            );
        }, 3000);
    };

    const failCallback = (clientId) => {
        setTimeout(() => {
            setSelectedArchive(
                selectedArchive?.filter((client) => client != clientId),
            );
        }, 3000);
    };

    const handleClientTeamChange = (event, client) => {
        dispatch(
            clientActions.clientTeamChange(client.id, parseInt(event.target.value)),
        );
    };

    const handleAllTeamChange = (event) => {
        const { value } = event.target;

        dispatch(clientActions.allClientTeamChange(parseInt(value)));
    };

    return (
        <div>
            <SecondaryCard title='Clients'>
                <TableHeader
                    ClassName='TableHeader fixed-header flex justify-content-start'
                    border={false}
                >
                    <TableHeaderItem
                        ClassName='UManagement'
                        label='Name'
                        width={TableItemWidth6}
                    />
                    <TableHeaderItem
                        ClassName=''
                        label='New Team'
                        width={TableItemWidth5}
                    />
                </TableHeader>

                <div
                    className='ListingLoading'
                    style={{ height: '200px', overflowY: 'auto' }}
                >
                    <div className='TableRow d-flex align-items-center justify-content-start'>
                        <TableRowItem
                            width={TableItemWidth6}
                            ClassName='UManagement text-left'
                            input={true}
                        >
                            <span className='BodyTwoLight dark-text'>
                                All Clients
                                &nbsp;&nbsp;&nbsp;
                                <span className='BodyTwoLight light-text'>
                                    Optional - This will replace a team for all clients
                                </span>
                            </span>
                        </TableRowItem>
                        <TableRowItem
                            width={TableItemWidth5}
                            ClassName='UManagement text-left'
                            input={true}
                        >
                            <PickList
                                placeholder='Select Team'
                                options={Teams?.map((opt) => {
                                    return {
                                        id: opt.id,
                                        name: opt.name,
                                    };
                                })}
                                value={
                                    clients?.length > 0 &&
                                        clients?.every(
                                            ({ team_client_permissions }) =>
                                                team_client_permissions[0]?.team_id ==
                                                clients[0]?.team_client_permissions[0]?.team_id,
                                        )
                                        ? clients[0]?.team_client_permissions[0]?.team_id
                                        : null
                                }
                                Name='team_id'
                                SelectRole={handleAllTeamChange}
                            />
                        </TableRowItem>
                    </div>

                    {clients?.map((client, index) => {
                        return (
                            <div
                                className='TableRow d-flex align-items-center justify-content-start'
                                key={index}
                            >
                                <TableRowItem
                                    label={client?.dba?.trim() == '' ? client?.company_name : client?.dba}
                                    width={TableItemWidth6}
                                    ClassName='UManagement text-left'
                                />
                                <TableRowItem
                                    width={TableItemWidth5}
                                    ClassName='UManagement text-left'
                                    input={true}
                                >
                                    <PickList
                                        requiredTitle='Team selection is required'
                                        placeholder='Select Team'
                                        options={Teams?.map((opt) => {
                                            return {
                                                id: opt.id,
                                                name: opt.name,
                                            };
                                        })}
                                        Name='team_id'
                                        require={true}
                                        value={client?.team_client_permissions[0]?.team_id}
                                        SelectRole={(event) =>
                                            handleClientTeamChange(event, client)
                                        }
                                    />
                                </TableRowItem>
                            </div>
                        );
                    })}
                </div>
            </SecondaryCard>
        </div>
    );
}

export default ArchieveTeamClient;
