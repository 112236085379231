/* eslint-disable no-mixed-spaces-and-tabs */
// Components
import { memo } from 'react';
import { ReactComponent as Resources } from '../../assets/icons/Resources.svg';
import ClientNotes from './ClientNotes';
import SoftwareStack from './SoftwareStack';
import { useDispatch } from 'react-redux';
import * as clientActions from '../../redux/actions/client-actions';
import NavigatorSection from './NavigatorSection';

function SharedAccess() {
	const dispatch = useDispatch();
	return (
		<div className='d-flex flex-column' style={{ gap: '24px' }}>
			<section className='client-profile' style={{ gap: '16px' }}>
				<div className='text-left w-100 d-flex align-items-center justify-content-between information-header'>
					<span className='d-flex' style={{ gap: '12px' }}>
						<Resources />
						<h3 className='HeadlineThreeBold'>Resources</h3>
					</span>
				</div>

				<div className='Profile-Content'>
					<SoftwareStack />
				</div>
			</section>
			<ClientNotes />
			<NavigatorSection
				title='Folder & Files'
				handleClick={() =>
					dispatch(clientActions.setClientProfileActiveTab('FOLDERS_AND_FILES'))
				}
			/>
		</div>
	);
}

export default memo(SharedAccess);
