import React, { useState } from 'react';
import SubmitButtonsField from '../Buttons/SubmitButtonsField';
import { useDispatch } from 'react-redux';
import * as WorkSpaceActions from '../../redux/actions/dashboard/workspaces-actions';
import FormButtonOne from '../Buttons/FormButtonOne';

const AdHocTaskDeleteModal = ({ onClose, taskDetails, onModalClose }) => {
	const [loading, setLoading] = useState();
	const dispatch = useDispatch();

	const deleteAdhocTask = () => {
		setLoading(true);
		dispatch(WorkSpaceActions.deleteAdhocTask(taskDetails))
			.then(() => {
				onClose();
				onModalClose();
				setLoading(false);
				dispatch(WorkSpaceActions.setSnackBarIsOpen(true, true, 'Ad Hoc Task Deleted'));
			})
			.catch((error) => {
				setLoading(false);
				dispatch(
					WorkSpaceActions.setSnackBarIsOpen(
						false,
						true,
						'Something went wrong. Try again!',
					),
				);
			});
	};

	return (
		<div className='ModalRoot fadeIn p-3'>
			<div className='Content'>
				<div style={{ height: '5rem' }} className='Header px-3'>
					<h1 className='HeadlineTwoBold'>Delete Ad Hoc Task</h1>
				</div>
				<div className='Body'>
					<p className='BodyTwoLight  w-100 px-3'>
						Deletion of this task will remove task from you workspace. Do you want to
						proceed?
					</p>

					<div className='VerticalSpacer bg-white py-3  px-3 rounded-bottom'>
						<SubmitButtonsField child={true}>
							<FormButtonOne
								onClick={onClose}
								type='button'
								className='secondary-btn-light'
							>
								Cancel
							</FormButtonOne>
							<FormButtonOne
								onClick={deleteAdhocTask}
								loading={loading}
								type='button'
							>
								Proceed
							</FormButtonOne>
						</SubmitButtonsField>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AdHocTaskDeleteModal;
