import { useDispatch } from 'react-redux';
import { TableItemWidth4 } from '../TableSpacing/TableSpacingStyles';
import * as clientActions from '../../redux/actions/client-actions';
import EditableText from '../Inputs/EditableText';
import {
	convertDecimalToTime,
	convertToDecimal,
	numberWithCommas,
	paymentTypesConstants,
} from '../../utilities/utilities';
import { useState } from 'react';

function InvoiceTable({ headers, edit, billingDetails, billingIndex }) {
	const dispatch = useDispatch();
	const [userToEdit, setUerToEdit] = useState({});

	const handleSelectAll = (e) => {
		const { checked } = e.target;

		dispatch(clientActions.selectAllClientInvoice(checked, billingIndex));
	};
	const handleSelectAllWorkflow = (workflow) => (e) => {
		const { checked } = e.target;
		dispatch(
			clientActions.selectAllClientWorkflowsInvoice(
				workflow.workflow,
				checked,
				billingIndex,
			),
		);
	};
	const handleSelectUser = (workflow, user) => (e) => {
		const { checked } = e.target;
		dispatch(
			clientActions.selectClientUserInvoice(
				workflow.workflow,
				user.user_id,
				checked,
				billingIndex,
			),
		);
	};
	const onChangeHandler = (index, workflow, user_id) => (e) => {
		setUerToEdit({
			user_id: user_id,
			workflow: workflow,
		});
		let value = e.target.value;
		const name = e.target.name;
		value = value.replace(/[^0-9.:]/g, '');

		dispatch(
			clientActions.clientUserInvoiceChangeHandler(
				value,
				name,
				index,
				workflow,
				billingIndex,
			),
		);
	};

	const handleInputBlur = (index, workflow) => (event) => {
		setUerToEdit({});
		let value = 0;
		const name = event.target.name;
		const inputValue = event.target.value;

		if (inputValue === '') {
			value = 0;
		} else if (inputValue.includes(':')) {
			value = convertToDecimal(inputValue);
		} else if (!isNaN(inputValue)) {
			value = parseFloat(inputValue);
		}

		dispatch(
			clientActions.clientUserInvoiceChangeHandler(
				value,
				name,
				index,
				workflow,
				billingIndex,
			),
		);
	};

	return (
		<div className='invoice-table'>
			<div className='invoice-table-parent-table-header'>
				{headers?.map((item, index) => {
					return (
						<span
							style={{ width: `calc(${TableItemWidth4})`, gap: '10px' }}
							className={`d-flex BodyTwoLight light-text ${
								headers?.length - 1 == index ? 'text-right' : 'text-left'
							}`}
							key={index}
						>
							{index == 0 ? (
								<>
									{billingDetails.payment_type ==
										paymentTypesConstants.HOURLY &&
										edit && (
											<span>
												<input
													className='multi-select-checkbox'
													type='checkbox'
													name='selectAll'
													id='selectAll'
													onChange={handleSelectAll}
													checked={billingDetails?.workflows?.every(
														(workflow) =>
															workflow?.workflow_users
																?.map((item) => item.is_selected)
																?.every((item) => item),
													)}
												/>
											</span>
										)}

									<span>{item}</span>
								</>
							) : (
								<span>{item}</span>
							)}
						</span>
					);
				})}
			</div>

			{billingDetails?.workflows?.map((workflow, i) => {
				return (
					<div key={i} className='child-body'>
						<div className='invoice-table-child-table-header'>
							<span
								style={{ width: `calc(${TableItemWidth4})`, gap: '10px' }}
								className='d-flex BodyTwoBold text-left'
							>
								{billingDetails.payment_type == paymentTypesConstants.HOURLY &&
									edit && (
										<span>
											<input
												className='multi-select-checkbox'
												type='checkbox'
												name='selectAll'
												id='selectAll'
												onChange={handleSelectAllWorkflow(workflow)}
												checked={workflow?.workflow_users
													?.map((item) => item.is_selected)
													?.every((item) => item)}
											/>
										</span>
									)}
								<span> {workflow?.workflow}</span>
							</span>
							<span
								style={{ width: `calc(${TableItemWidth4})` }}
								className='BodyTwoBold text-left'
							>
								-
							</span>
							<span
								style={{ width: `calc(${TableItemWidth4})` }}
								className='BodyTwoBold text-left'
							>
								{billingDetails?.payment_type == paymentTypesConstants.HOURLY
									? convertDecimalToTime(workflow.workflow_hours)
									: ''}
							</span>
							<span
								style={{ width: `calc(${TableItemWidth4})` }}
								className='BodyTwoBold text-right'
							>
								{billingDetails?.payment_type == paymentTypesConstants.HOURLY
									? `$${numberWithCommas(workflow.workflow_amount.toFixed(2))}`
									: ''}
							</span>
						</div>

						{workflow?.workflow_users?.map((user, index) => {
							return (
								<div key={index} className='invoice-table-child-table-row'>
									<span
										style={{ width: `calc(${TableItemWidth4})` }}
										className='BodyTwoLight text-left'
									>
										{billingDetails.payment_type ==
											paymentTypesConstants.HOURLY &&
											edit && (
												<span>
													<input
														className='multi-select-checkbox'
														type='checkbox'
														name='selectAll'
														id='selectAll'
														onChange={handleSelectUser(workflow, user)}
														checked={user.is_selected}
													/>
												</span>
											)}
										<span> {''}</span>
									</span>
									<span
										style={{ width: `calc(${TableItemWidth4})` }}
										className='BodyTwoLight text-left'
									>
										{user?.full_name}
									</span>
									<span
										style={{ width: `calc(${TableItemWidth4})` }}
										className='text-left p-0 m-0'
									>
										{billingDetails?.payment_type ==
										paymentTypesConstants.HOURLY ? (
											<EditableText
												name='total_hours'
												value={
													userToEdit?.workflow == workflow.workflow &&
													userToEdit?.user_id == user?.user_id
														? user?.total_hours
														: convertDecimalToTime(user?.total_hours)
												}
												Edit={edit}
												onChange={onChangeHandler(
													index,
													workflow.workflow,
													user?.user_id,
												)}
												onBlur={handleInputBlur(index, workflow?.workflow)}
												type='text'
												placeholder='HH : MM'
											/>
										) : (
											''
										)}
									</span>
									<span
										style={{ width: `calc(${TableItemWidth4})` }}
										className='BodyTwoLight text-right'
									>
										{billingDetails?.payment_type ==
										paymentTypesConstants.HOURLY
											? `$${numberWithCommas(user?.charged_amount)}`
											: ''}
									</span>
								</div>
							);
						})}
					</div>
				);
			})}
		</div>
	);
}

export default InvoiceTable;
