import React from 'react';

import Icon from './Icon';

const ClientIcon = (props) => {

    const { fill = '#000' } = props;

  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <defs>
        <path
          d="M19.9 5.89l-1.05-4.37c-.22-.9-1-1.52-1.91-1.52H3.05c-.9 0-1.69.63-1.9 1.52L.1 5.89C-.14 6.91.08 7.95.72 8.77c.08.11.19.19.28.29V16c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V9.06c.09-.09.2-.18.28-.28.64-.82.87-1.87.62-2.89zm-2.99-3.9l1.05 4.37c.1.42.01.84-.25 1.17-.14.18-.44.47-.94.47-.61 0-1.14-.49-1.21-1.14L14.98 2l1.93-.01zM11 2h1.96l.54 4.52c.05.39-.07.78-.33 1.07-.22.26-.54.41-.95.41C11.55 8 11 7.41 11 6.69V2zM6.49 6.52L7.04 2H9v4.69C9 7.41 8.45 8 7.71 8c-.34 0-.65-.15-.89-.41a1.42 1.42 0 01-.33-1.07zm-4.45-.16L3.05 2h1.97l-.58 4.86C4.36 7.51 3.84 8 3.23 8c-.49 0-.8-.29-.93-.47-.27-.32-.36-.75-.26-1.17zM3 16V9.97c.08.01.15.03.23.03.87 0 1.66-.36 2.24-.95.6.6 1.4.95 2.31.95.87 0 1.65-.36 2.23-.93.59.57 1.39.93 2.29.93.84 0 1.64-.35 2.24-.95.58.59 1.37.95 2.24.95.08 0 .15-.02.23-.03V16H3z"
          id="client-icon-a"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <g transform="translate(2 3)">
          <mask id="client-icon-b" fill={fill}>
            <use xlinkHref="#client-icon-a" />
          </mask>
          <use
            className="fill-target"
            fill={fill}
            fillRule="nonzero"
            xlinkHref="#client-icon-a"
          />
          <path
            className="fill-target"
            fill={fill}
            mask="url(#client-icon-b)"
            d="M-2-3h24v24H-2z"
          />
        </g>
      </g>
    </Icon>
  );
};

export default ClientIcon;
