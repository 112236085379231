import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SubmitButtonsField from '../../Buttons/SubmitButtonsField';
import { CircularProgress } from '@mui/material';
import * as clientActions from '../../../redux/actions/client-actions';
import { setSnackBarIsOpen } from '../../../redux/actions/dashboard/workspaces-actions';

export default function Rename({ onCancel, item, isFolder }) {
	const dispatch = useDispatch();

	const folderData = useSelector((state) => state.clientReducer.folderData);

	const [actionLoading, setActionLoading] = useState(false);
	const [name, setName] = useState(item.name);

	const handleRename = () => {
		setActionLoading(true);

		dispatch(clientActions.updateDocument(item?.id, { name }))
			.then((response) => {
				setActionLoading(false);

				dispatch(
					clientActions.updateDocumentReducer(
						folderData?.map((folder) => {
							if (folder?.id === response?.id) {
								return {
									...response,
								};
							} else return folder;
						}),
					),
				);

				onCancel();
				const successMessage = isFolder
					? 'Folder renamed successfully'
					: 'File renamed successfully';
				dispatch(setSnackBarIsOpen(true, true, successMessage));
			})
			.catch((error) => {
				if (error.message == 'Document name already exists') {
                    const errorMessage = isFolder
						? 'Folder name already exists'
						: 'File name already exists';
					dispatch(setSnackBarIsOpen(false, true, errorMessage));
				} else {
					const errorMessage = isFolder
						? 'Error while renaming folder'
						: 'Error while renaming file';
					dispatch(setSnackBarIsOpen(false, true, errorMessage));
				}
				setActionLoading(false);
			});
	};

	return (
		<div>
			<form autoComplete='off'>
				<div
					className='py-2 px-3'
					style={{
						maxHeight: '75vh',
						height: 'fit-content',
						position: 'relative',
						overflowY: 'auto',
					}}
				>
					<div className='improved-input-designs px-1 pb-3'>
						<label className='require w-100'>Folder name</label>
						<input
							className='w-100'
							type='text'
							style={{
								backgroundColor: 'white',
								border: 'none',
								borderBottom: '1px solid #28383C',
							}}
							required
							onChange={(e) => setName(e.target.value)}
							value={name}
						/>
					</div>
				</div>

				<div className='VerticalSpacer bg-white p-3 rounded-bottom'>
					<SubmitButtonsField child={true}>
						<button
							id='Element'
							type='button'
							className='secondary'
							style={{ borderRadius: '50px', width: '125px' }}
							onClick={onCancel}
						>
							Cancel
						</button>

						<button
							type='submit'
							className='d-flex align-items-center'
							style={{ borderRadius: '50px', width: '125px' }}
							id='Element'
							disabled={actionLoading}
							data-cy='add-client-btn'
							onClick={handleRename}
						>
							{actionLoading ? (
								<CircularProgress size={24} sx={{ color: 'white' }} disableShrink />
							) : (
								'Rename'
							)}
						</button>
					</SubmitButtonsField>
				</div>
			</form>
		</div>
	);
}
