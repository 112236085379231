import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import HeadingTwo from '../TypoGraphy/HeadingTwo.jsx';
import SkeletonLoading from '../Others/SkeletonLoading.jsx';
import { convertDecimalToTime } from '../../utilities/utilities.js';

function ProfileBarV2({ barText, availbleHours, clientDetails, capacity, budgeted_hours, userId }) {
	const loading = useSelector((state) => state.TimeReportsV2.loading);
	const history = useHistory();

	const handleUserInfoClick = () => {
		history.push('/time-reports');
	};

	const handlExportClick = () => {
		if (clientDetails) history.push('/client-time-reports-preview', clientDetails);

		// capacity check for user time reports
		if (userId) history.push('/user-time-reports-preview', { id: userId, name: barText });
	};

	return (
		<div className='d-flex flex-column align-items-start w-100'>
			<div className='py-2 d-flex flex-row align-items-center pointer primary-text'>
				<ArrowLeftIcon />
				<h2 className='HeadlineThreeBook text-left' onClick={() => handleUserInfoClick()}>
					Time Reports
				</h2>
			</div>
			<div
				className='d-flex align-items-start justify-content-between text-left w-100'
				style={{
					backgroundColor: '#FCFDFD',
					padding: '20px 18px',
					gap: '1.5em',
					boxSizing: 'border-box',
					borderRadius: '4px 4px 0px 0px',
				}}
			>
				<SkeletonLoading loading={loading} variant='text' width='100%' height={45}>
					<div>
						<HeadingTwo>{barText}</HeadingTwo>
						{!clientDetails && (
							<>
								<h3 className='BodyTwoLight m-0 table-header-text'>
									Available Hours : {convertDecimalToTime(availbleHours || 0)}
								</h3>
								<h3 className={`BodyTwoLight m-0 table-header-text text-left`}>
									Capacity :
									<span
										className={`BodyTwoLight m-0 table-header-text ${
											capacity < 0 && 'red-text'
										}`}
									>
										{' '}
										{convertDecimalToTime(capacity || 0)}
									</span>
								</h3>
								<h3 className={`BodyTwoLight m-0 table-header-text text-left`}>
									Budgeted Hours :{' '}
									{budgeted_hours == null
										? convertDecimalToTime(0)
										: convertDecimalToTime(budgeted_hours)}
								</h3>
							</>
						)}
					</div>
				</SkeletonLoading>

				<SkeletonLoading loading={loading} variant='text' width='20%' height={32}>
					<button onClick={handlExportClick} className='rounded-button-1'>
						Export to CSV
					</button>
				</SkeletonLoading>
			</div>
		</div>
	);
}

export default ProfileBarV2;
