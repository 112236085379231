import React from 'react';

function TimeReportsTopBarV2({ timereports }) {
	return (
		<div className='time-reports-TopBar'>
			<div className='d-flex flex-row align-items-center'>
				<div>
					<h6 className='HeadlineTwoBold w-100 d-flex justify-content-start'>
						{timereports}
					</h6>
				</div>
			</div>
		</div>
	);
}

export default TimeReportsTopBarV2;
