/* eslint-disable no-mixed-spaces-and-tabs */
import FormButtonOne from '../Buttons/FormButtonOne';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { Avatar } from '@mui/material';
import { ReactComponent as SearchIcon } from '../../assets/icons/MagnifyingGlass.svg';
import {
	Member,
	getAvatarLabel,
	isTeamManager,
} from '../../utilities/utilities';
import * as builderActions from '../../redux/actions/workflow-builder-actions';

const AddToClientModal = ({ onClose, onDone }) => {
	const dispatch = useDispatch();
	let clients = useSelector((state) => state.clientReducer.clients);
	const user = useSelector((state) => state.authReducer.user);
	const [search, setSearch] = useState('');
	const actionLoading = useSelector(
		(state) => state.WorkflowBuilder.builderLoadSave,
	);
	const selected = useSelector(
		(state) => state.WorkflowBuilder.clientSelectedForWorkflowActivation,
	);

	const changeHandler = (event) => {
		setSearch(event.target.value);
	};

	const handleClientSelect = (clientId) => (event) => {
		dispatch(builderActions.setClientSelectedForWorkflowActivation(clientId));
	};

	clients = clients?.filter((client) => {
		return isTeamManager(user?.user_roles?.map((item) => item.role_id))
			? user?.user_roles?.find((item) =>
					item?.role_scopes
						?.map((role) => role.team_id)
						?.includes(client?.team_client_permissions[0]?.team_id),
			  )?.role_id === Member.teamManagerId
			: true;
	});

	return (
		<div className='add-to-client-modal'>
			<div className='add-to-client-header'>
				<div className='d-flex'>
					<h2 className='HeadlineTwoBold'>Select Client</h2>
				</div>
				<div
					className='d-flex pr-2 align-items-center rounded'
					style={{ backgroundColor: 'white' }}
				>
					<div className='improved-input-designs w-100'>
						<input
							type='text'
							placeholder='Search Client'
							value={search}
							name='client'
							onChange={changeHandler}
						/>
					</div>
					<SearchIcon />
				</div>
			</div>
			<div className='add-to-client-body scroll'>
				<label className='BodyTwoLight m-0'>Client List</label>
				<div
					className='d-flex flex-column'
					style={{ gap: '8px', padding: '8px 12px' }}
				>
					{clients?.filter?.((client) =>
						client?.dba?.trim() == ''
							? client?.company_name
									?.toLowerCase()
									?.includes(search?.toLowerCase())
							: client?.dba?.toLowerCase()?.includes(search?.toLowerCase()),
					)?.length < 1 ? (
						<span className='BodyTwoLight text-center'>No Match Found</span>
					) : (
						clients
							?.filter?.((client) =>
								client?.dba?.trim() == ''
									? client?.company_name
											?.toLowerCase()
											?.includes(search?.toLowerCase())
									: client?.dba?.toLowerCase()?.includes(search?.toLowerCase()),
							)
							?.map((client) => {
								return (
									<div
										className='d-flex align-items-center justify-content-between'
										key={client.id}
									>
										<div className='d-flex' style={{ gap: '8px' }}>
											<Avatar
												sx={{
													bgcolor: `#FEF2E7`,
													color: `#333`,
													height: '24px',
													width: '24px',
													fontSize: '0.8rem',
												}}
												alt={
													client?.dba?.trim() == ''
														? client?.company_name
														: client?.dba
												}
											>
												{getAvatarLabel(
													client?.dba?.trim() == ''
														? client?.company_name
														: client?.dba,
												)}
											</Avatar>

											<span className='BodyTwoLight pt-1 dark-text text-left'>
												{client?.dba?.trim() == ''
													? client?.company_name
													: client?.dba}
											</span>
										</div>

										<input
											type='checkbox'
											className='multi-select-checkbox'
											name='client_id'
											value={client?.id}
											checked={client?.id === selected}
											onClick={(event) => event.stopPropagation()}
											onChange={handleClientSelect(client.id)}
										/>
									</div>
								);
							})
					)}
				</div>
			</div>
			<div className='add-to-client-footer'>
				<FormButtonOne onClick={onClose} className='secondary-btn-light'>
					Cancel
				</FormButtonOne>
				<FormButtonOne
					loading={actionLoading}
					onClick={selected ? () => onDone(selected) : () => {}}
					disabled={actionLoading}
					style={{
						opacity: actionLoading ? 0.5 : 1,
					}}
				>
					Continue
				</FormButtonOne>
			</div>
		</div>
	);
};
export default AddToClientModal;
