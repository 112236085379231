/* eslint-disable no-mixed-spaces-and-tabs */
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	tableCellClasses,
	styled,
	Modal,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { ReactComponent as EditIcon } from '../../assets/icons/PencilSimpleColor.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/Trash-Light.svg';
import ReactHtmlParser from 'react-html-parser';
import { truncateString } from '../../utilities/utilities';
import useModal from '../Modal/useModal';
import DummyModal from '../Modal/DummyModal';
import WorkflowTaskDescriptinModal from '../ClientManagement/Modals/TaskDescriptionModal';
import { useState } from 'react';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: '#FFFFFF',
		color: '#7C7C7C',
		fontFamily: 'Centra',
		fontSize: 12,
		fontWeight: 300,
		height: '38px',
		lineHeight: '14px',
		borderBottom: '1px solid white',
		width: 'fit-content',
	},
	[`&.${tableCellClasses.body}`]: {
		backgroundColor: '#F7F9FA',
		color: '#333333',
		fontFamily: 'Centra',
		fontSize: 12,
		fontWeight: 300,
		height: '38px',
		borderBottom: '1px solid white',
		borderTop: '1px solid white',
		width: 'fit-content',
	},
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&:nth-of-type(odd)': {
		backgroundColor: theme.palette.action.hover,
	},
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0,
	},
}));

export default function TasksComponent({
	tasks,
	headers,
	addNewTaskRow,
	handleEditTask,
	deleteTask,
}) {
	const { modalIsOpen, toggleModal, ModalName, ModalType } = useModal();
	const [expandModal, setExpandModal] = useState({
		value: '',
		title: '',
	});

	return (
		<>
			<div className='w-100'>
				<Paper sx={{ width: '100%', overflow: 'hidden', boxShadow: 'none' }}>
					<TableContainer sx={{ maxHeight: 304 }}>
						<Table
							stickyHeader
							aria-label='sticky table'
							sx={{ minWidth: 700 }}
						>
							<TableHead>
								<TableRow>
									{headers?.map((header, index) => {
										return (
											<StyledTableCell key={index}>{header}</StyledTableCell>
										);
									})}
								</TableRow>
							</TableHead>
							<TableBody>
								{tasks?.length > 0 ? (
									tasks?.map((item, index) => {
										return (
											<StyledTableRow key={index}>
												<StyledTableCell>{item?.title || '-'}</StyledTableCell>
												<StyledTableCell>
													{item?.description?.length < 1 ||
													item?.description == null ||
													item?.description?.replace(/<[^>]+>/g, '')?.trim()
														?.length == 0 ? (
														'-'
													) : (
														<div
															style={{ minHeight: '14px' }}
															className='d-flex flex-column justify-content-between position-relative'
														>
															<p className='BodyTwoLight dark-text m-0 text-break'>
																{ReactHtmlParser(
																	truncateString(item?.description, 40),
																)}
															</p>
															{item?.description.length > 40 && (
																<span
																	onClick={() => {
																		ModalType('TASK_DESCRIPTION_MODAL');
																		toggleModal();
																		setExpandModal({
																			value: item?.description,
																			title: 'Description',
																		});
																	}}
																	className='primary-text BodyThreeLight pointer'
																>
																	Read More
																</span>
															)}
														</div>
													)}
												</StyledTableCell>
												<StyledTableCell>
													{item?.budgeted_hours || '-'}
												</StyledTableCell>
												<StyledTableCell>
													{item.dependent_task_id
														? tasks?.find(
																(state) =>
																	state.id == item.dependent_task_id ||
																	state['#id'] == item.dependent_task_id,
														  )?.title || '-'
														: '-'}
												</StyledTableCell>
												<StyledTableCell>
													<EditIcon
														style={{ marginRight: '8px', cursor: 'pointer' }}
														onClick={() => handleEditTask(item)}
													/>
													<DeleteIcon
														style={{ cursor: 'pointer' }}
														onClick={() => deleteTask(item)}
													/>
												</StyledTableCell>
											</StyledTableRow>
										);
									})
								) : (
									<StyledTableRow>
										<StyledTableCell
											style={{ textAlign: 'center', color:'#696F6C' }}
											colSpan={5}
										>
											No Tasks
										</StyledTableCell>
									</StyledTableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
				</Paper>
				<div
					className='d-flex justify-content-center align-items-center'
					style={{
						width: '100%',
						background: '#FFFFFF',
						height: '38px',
					}}
				>
					<div
						className='pointer d-flex flex-row'
						style={{
							height: '22px',
							width: '6px',
							borderRadius: '3px',
							marginRight: '1rem',
							padding: 0,
							position: 'relative',
						}}
						onClick={addNewTaskRow}
					>
						<AddIcon
							fontSize='small'
							sx={{
								color: '#fb7a03',
								fontSize: 21,
								position: 'absolute',
								margin: 0,
								padding: 0,
								top: 0,
								left: 0,
							}}
						/>
					</div>
					<h3
						className='BodyTwoLight mt-2 dark-text'
						style={{
							color: '#FB7A03',
							cursor: 'pointer',
						}}
						onClick={addNewTaskRow}
					>
						Add New Task
					</h3>
				</div>
			</div>
			{modalIsOpen && (
				<Modal
					onClose={toggleModal}
					open={modalIsOpen}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<>
						{ModalName == 'TASK_DESCRIPTION_MODAL' && (
							<DummyModal
								title='Instructions'
								onClose={toggleModal}
								height='400px'
							>
								<WorkflowTaskDescriptinModal
									type={'text'}
									value={expandModal.value}
									Edit={false}
									OnCancel={() => toggleModal()}
									richTextStyle={{ height: '250px' }}
								/>
							</DummyModal>
						)}
					</>
				</Modal>
			)}
		</>
	);
}
