import React, { memo } from 'react';

// const Link = styled.a`
//   color: ${lightGreen};

//   &:hover {
//     text-decoration: underline;
//   }
// `;

const TableRowButton = ({
  label,
  url,
  width,
  children,
  clickHandler,
  ClassName,
  check,
  text,
  margin,
  input,
  dataCy,
  id
}) => {
  if (input) {
    return (
      <div
        className={'d-flex TableRowButton ' + ClassName}
        onClick={clickHandler}
        style={{ width: check ? `${width}` : `calc(${width})` }}
        data-cy={dataCy}
        id={id}
      >
        {children}
      </div>
    );
  } else {
    return (
      <div
        className={'d-flex TableRowButton ' + ClassName}
        style={{ width: check ? `${width}` : `calc(${width})` }}
        id={id}

      >
        {children}
        {label && <h3   onClick={clickHandler} className={margin + ' BodyTwoLight text-left m-0' + ClassName}>{label}</h3>}
      </div>
    );
  }
};

export default memo(TableRowButton);
