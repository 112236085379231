import { memo, useCallback, useMemo, useState } from 'react';
import { ReactComponent as ResourcesIcon } from '../../assets/icons/Resources.svg';
import {
	getAccountComponentsWithSharedAccounts,
	objectBeingDragged,
	standardPermissions,
} from '../../utilities/utilities';
import ExpandableResourcesBox from '../sub_components/ExpandableResourcesBox';
import * as builderActions from '../../redux/actions/workflow-builder-actions';
import * as clientActions from '../../redux/actions/client-actions';
import { useDispatch } from 'react-redux';
import useModal from '../Modal/useModal';
import { Modal } from '@mui/material';
import DummyModal from '../Modal/DummyModal';
import AddPaymentPlatform from './Account Modals/AddPaymentPlatform';
import AddBankAccount from './Account Modals/AddBankAccount';
import AddCreditCardAccount from './Account Modals/AddCreditCardAccount';
import AddMerchantAccount from './Account Modals/AddMerchantAccount';
import AddSoftware from './Account Modals/AddSoftware';
import AddLink from './Modals/AddLink';
import * as clientAccountActions from '../../redux/actions/client-accounts-actions';

function WorkflowResources({ workflow_submission, user_team_role, display_name }) {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState();

	const { modalIsOpen, toggleModal, ModalName, ModalType } = useModal();

	const data = workflow_submission?.data?.length > 0 ? workflow_submission?.data[0] : undefined;

	const accountTypes = [
		{
			title: 'Software',
			type: objectBeingDragged.SOFTWARE,
		},
		{
			title: 'Credit Card Account',
			type: objectBeingDragged.CREDIT_CARD_ACCOUNT,
		},
		{
			title: 'Bank Account',
			type: objectBeingDragged.BANK_ACCOUNT,
		},
		// {
		// 	title: 'Merchant Account',
		// 	type: objectBeingDragged.MERCHANT_ACCOUNT,
		// },
		{
			title: 'Other Portals',
			type: objectBeingDragged.PAYMENT_PORTAL,
		},
	];

	const handleAddresource = (type) => () => {
		dispatch(clientAccountActions.setEditForm(undefined));
		dispatch(clientAccountActions.resetForms());
		dispatch(clientAccountActions.setError(''));
		if (type == 'NEW_LINKS_MODAL') {
			dispatch(clientActions.resetLinkValues());
		}
		ModalType(type);
		toggleModal();
	};

	const handleSubmissionChange = useCallback(
		(currentComponent) => (value, singleValue) => {
			setLoading(singleValue);
			const updated_workflow_submission = workflow_submission?.data?.map((data) => {
				data.props.sections[0].columns?.map((column) => {
					column?.columnComponents?.map((component) => {
						if (
							component &&
							component.componentType == currentComponent.componentType
						) {
							component.value = value;
						}

						return component;
					});
					return column;
				});

				return data;
			})[0];

			dispatch(
				builderActions.setAccountsComponentValue(
					updated_workflow_submission,
					workflow_submission?._id,
				),
			)
				.then(() => {
					setLoading(null);
				})
				.catch(() => {
					setLoading(null);
				});
		},
		[workflow_submission],
	);

	const contactComponent = useMemo(() => {
		let dynamicComponent = undefined;
		if (data?.props?.sections?.length > 0) {
			data?.props?.sections[0]?.columns?.map((col) => {
				col?.columnComponents?.map((col_comp) => {
					if ('contact-pick-list' === col_comp?.componentType) {
						dynamicComponent = col_comp;
					}
				});
			});
		}
		return dynamicComponent;
	}, [workflow_submission]);

	const linksComponent = useMemo(() => {
		let dynamicComponent = undefined;
		if (data?.props?.sections?.length > 0) {
			data?.props?.sections[0]?.columns?.map((col) => {
				col?.columnComponents?.map((col_comp) => {
					if ('links-pick-list' === col_comp?.componentType) {
						dynamicComponent = col_comp;
					}
				});
			});
		}
		return dynamicComponent;
	}, [workflow_submission]);

	const allowEdit = standardPermissions?.includes(user_team_role);


	return (
		<div className='w-100 d-flex flex-column' style={{ gap: '24px' }}>
			<div className='resources-header'>
				<div className='w-100 text-left d-flex align-items-center justify-content-between'>
					<span className='d-flex' style={{ gap: '12px' }}>
						<ResourcesIcon />
						<h3 className='HeadlineThreeBold'>{display_name} Resources</h3>
					</span>
				</div>
				<p className='text-left BodyTwoLight' style={{ margin: 0, padding: 0 }}>
					Choose which resources should be made available in this workflow
				</p>
			</div>

			<div className='w-100'>
				{accountTypes?.map((account, index) => {
					let component = null;
					if (data?.props?.sections?.length > 0) {
						data?.props?.sections[0]?.columns?.map((col) => {
							col?.columnComponents?.map((col_comp) => {
								if (
									getAccountComponentsWithSharedAccounts(account.type) ===
									col_comp?.componentType
								) {
									component = col_comp;
								}
							});
						});
					}
					return (
						<ExpandableResourcesBox
							key={index}
							index={index}
							edit={allowEdit}
							account={account}
							workflowBrowser
							title={account.title}
							handleChnage={handleSubmissionChange}
							dynamicComponent={component}
							loadingValue={loading}
							handleAddresource={handleAddresource}
						/>
					);
				})}

				{linksComponent && (
					<ExpandableResourcesBox
						title='Shared Drives & Links'
						workflowBrowser
						links
						edit={allowEdit}
						handleChnage={handleSubmissionChange}
						dynamicComponent={linksComponent}
						loadingValue={loading}
						handleAddresource={handleAddresource}
					/>
				)}

				{contactComponent && (
					<ExpandableResourcesBox
						title='Contacts'
						workflowBrowser
						contacts
						edit={allowEdit}
						handleChnage={handleSubmissionChange}
						dynamicComponent={contactComponent}
						loadingValue={loading}
					/>
				)}
			</div>
			{modalIsOpen && (
				<Modal
					onClose={toggleModal}
					open={modalIsOpen}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<>
						{ModalName == 'PAYMENT_PORTAL' && (
							<DummyModal title={'Add Other Portals'} onClose={toggleModal}>
								<AddPaymentPlatform
									onCancel={toggleModal}
									accountType={ModalName}
								/>
							</DummyModal>
						)}
						{ModalName == 'BANK_ACCOUNT' && (
							<DummyModal title={'Add Bank Account'} onClose={toggleModal}>
								<AddBankAccount onCancel={toggleModal} accountType={ModalName} />
							</DummyModal>
						)}
						{ModalName == 'CREDIT_CARD_ACCOUNT' && (
							<DummyModal title={'Add Credit Card Account'} onClose={toggleModal}>
								<AddCreditCardAccount
									onCancel={toggleModal}
									accountType={ModalName}
								/>
							</DummyModal>
						)}
						{ModalName == 'SOFTWARE' && (
							<DummyModal title={'Add Software'} onClose={toggleModal}>
								<AddSoftware onCancel={toggleModal} accountType={ModalName} />
							</DummyModal>
						)}
						{ModalName == 'MERCHANT_ACCOUNT' && (
							<DummyModal title={'Add Merchant Account'} onClose={toggleModal}>
								<AddMerchantAccount
									onCancel={toggleModal}
									accountType={ModalName}
								/>
							</DummyModal>
						)}
						{ModalName == 'NEW_LINKS_MODAL' && (
							<DummyModal title='Add Link' onClose={toggleModal}>
								<AddLink onCancel={toggleModal} />
							</DummyModal>
						)}
					</>
				</Modal>
			)}
		</div>
	);
}

export default memo(WorkflowResources);
