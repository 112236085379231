import React, { memo } from 'react';
import TableRowItem from '../Others/TableRowItem';
import {
	TableItemWidth1,
	TableItemWidth2,
	TableItemWidth3,
	TableItemWidth5,
	TableItemWidth6,
} from '../TableSpacing/TableSpacingStyles';
import { Input } from '../Inputs/Input';
import Restricted from '../Permissions/Restricted';
import {
	Member,
	executivePermissions,
	standardPermissions,
} from '../../utilities/utilities';
import TableRowButton from '../Others/TableRowButton';
import { Tooltip } from '@mui/material';
import { ReactComponent as TrashIcon } from '../../assets/icons/Trash-Light.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/PencilEdit.svg';

const UserTeamRole = ({
	key,
	team_name,
	team_description,
	role_name,
	handleNavigateClick,
	team_id,
	role_id,
	loggedInUserTeamRole,
	handleEditClick,
	handleClick,
	user_id,
	user,
	allowdUsers,
	userTeamRole
}) => {
	return (
		<div key={key} className='TableRow d-flex align-items-center'>
			<TableRowItem
				ClassName=' text-left'
				label={team_name}
				width={TableItemWidth3}
				onClick={handleNavigateClick(team_id)}
			/>

			<TableRowItem
				ClassName=' text-left'
				label={team_description}
				width={TableItemWidth6}
				input={false}
			>
				<Input
					label='Description'
					name='desc'
					value={team_description}
					disabled={true}
				/>
			</TableRowItem>
			<TableRowItem
				ClassName=' text-left'
				label={role_name}
				width={TableItemWidth5}
				input={false}
			>
				<Input
					label='Description'
					name='desc'
					value={role_name}
					disabled={true}
				/>
			</TableRowItem>
			<Restricted
				AllowedUsers={allowdUsers}
				userTeamRole={userTeamRole}
			>
				<TableRowButton ClassName='TProfile d-flex p-0' width={TableItemWidth2}>
					<TableRowButton
						ClassName='TProfile d-flex m-0'
						width={TableItemWidth1}
					>
						{!executivePermissions.includes(user?.userRole?.id) ||
						role_id === Member.supervisorId ||
						role_id === Member.externalCollaborator ? (
							<Tooltip title='Not Allowed'>
								<EditIcon
									style={{
										color: '#fb7a03',
										cursor: 'pointer',
										opacity: '0.5',
									}}
								/>
							</Tooltip>
						) : (
							<Tooltip title='Edit Team Role'>
								<EditIcon
									style={{
										color: '#fb7a03',
										cursor: 'pointer',
										marginRight: '10px',
									}}
									onClick={handleEditClick({
										user_id: user_id,
										role_id: role_id,
										team_id: team_id,
									})}
								/>
							</Tooltip>
						)}
					</TableRowButton>
					<Restricted
						AllowedUsers={
							role_id === Member.teamManagerId
								? executivePermissions
								: standardPermissions
						}
						userTeamRole={[loggedInUserTeamRole]}
						RestrictedComponent={
							<TableRowButton
								ClassName='TProfile d-flex p-0'
								width={TableItemWidth1}
							>
								<Tooltip title='Not Authorised'>
									<TrashIcon
										style={{
											color: '#fb7a03',
											opacity: '0.5',
										}}
									/>
								</Tooltip>
							</TableRowButton>
						}
					>
						<TableRowButton
							ClassName='TProfile d-flex p-0'
							width={TableItemWidth1}
						>
							<Tooltip title='Remove'>
								<TrashIcon
									style={{
										color: '#fb7a03',
										cursor: 'pointer',
									}}
									onClick={handleClick({
										user_id: user_id,
										role_id: role_id,
										team_id: team_id,
										team_name: team_name,
									})}
								/>
							</Tooltip>
						</TableRowButton>
					</Restricted>
				</TableRowButton>
			</Restricted>
		</div>
	);
};

export default memo(UserTeamRole);
