/* eslint-disable no-mixed-spaces-and-tabs */
import { Checkbox, Tooltip } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DropDown from '../../Inputs/DropDown';
import * as WorkSpaceActions from '../../../redux/actions/dashboard/workspaces-actions';
import * as clientActions from '../../../redux/actions/client-actions';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { ReactComponent as CalendarIcon } from '../../../assets/icons/Calendarcolor.svg';
import { ReactComponent as InfoIcon } from '../../../assets/icons/Info.svg';
import { BillTypes, RepeatTypes } from '../../DemoData';
import moment from 'moment';
import MultipleDropDown from '../../Inputs/MultipleDropDown';
import InputTaskDetails from '../../Inputs/InputTaskDetails';
import SelectMyTaskColor from './SelectMyTaskColor';
import EditableRadioButton from '../../Inputs/EditableRadioButton';
import * as userActions from '../../../redux/actions/user-management.actions';
import {
	REPEAT_TYPE,
	endTimeOptions,
	startTimeOptions,
	toLocalTz,
	weekOfMonth,
} from '../../../utilities/utilities';
import NewSearchDropdown from '../../Inputs/NewSearchDropdown';
import { getUserSettings } from '../../../hooks/getUserSettings';

const MeetingMyTaskModalInputs = ({ clientId }) => {
	const dispatch = useDispatch();
	const taskTypes = useSelector((state) => state.workSpaceReducer.taskTypes);
	const form = useSelector((state) => state.workSpaceReducer.createTaskForm);
	const clients = useSelector((state) => state.clientReducer.clients);
	const user = useSelector((state) => state.authReducer.user);
	const [monthlySpecialDate, setMonthlySpecialDate] = useState(false);
	let allTenantUsers = useSelector((state) => state.userReducer.allTenantUsers);
	const userDetails = useSelector((state) => state.authReducer.userDetails);
	const { date_format } = getUserSettings(userDetails);

	const Style = {
		backgroundColor: 'white',
		border: 'none',
		borderBottom: '1px solid #28383C',
	};

	const RECUR_PREFERENCES = [
		{
			value: 2,
			label: `Start the recurrence on last ${moment(form?.start_date).format(
				'dddd',
			)} of the month`,
		},
		{
			value: 1,
			label: `Start the recurrence on every 4th ${moment(form?.start_date).format(
				'dddd',
			)} of the month`,
		},
	];

	const END_ON = [
		{ value: 'occurrence', label: 'Occurrences' },
		{ value: 'end_date', label: 'Date' },
		{ value: 'never', label: 'Never' },
	];

	const REPEAT_TYPE_LABEL = {
		DAILY: 'Daily',
		WEEKLY: 'Weekly',
		MONTHLY: 'Monthly',
		YEARLY: 'Annually',
	};

	const timeRangeHandle = (event, indexName) => {
		const time = event.target.value;
		if (indexName == 'start_time') {
			const end_time =
				time == '11:00 PM'
					? moment(time, 'hh:mm A').clone().add(59, 'minutes').format('hh:mm A')
					: moment(time, 'hh:mm A').add(1, 'hour').format('hh:mm A');
			dispatch(WorkSpaceActions.handleChange({ name: 'end_time', value: end_time }));
			dispatch(WorkSpaceActions.handleChange({ name: indexName, value: time }));
		} else {
			dispatch(WorkSpaceActions.handleChange({ name: indexName, value: time }));
		}
	};

	const quillHandlerNotes = (content) => {
		dispatch(
			WorkSpaceActions.handleChange({
				name: 'notes',
				value: content,
			}),
		);
	};
	const quillHandlerAgenda = (content) => {
		dispatch(
			WorkSpaceActions.handleChange({
				name: 'agenda',
				value: content,
			}),
		);
	};

	const handleChange = (event) => {
		let { name, value } = event.target;

		if (name == 'is_approval_required') {
			value = event.target.checked;
		}
		if (name == 'is_billable') {
			value = BillTypes?.find((BillType) => BillType.value == value)?.id;
		}

		dispatch(WorkSpaceActions.handleChange({ name, value }));
	};

	const handleTaskType = (event) => {
		const { name, value } = event.target;
		if (value == 1) {
			dispatch(
				WorkSpaceActions.handleTaskType({
					...form,
					[name]: value,
					end_on: null,
					repeat_interval: null,
					repeat_type: null,
					is_monthly_on_day: false,
					recur_preference: null,
				}),
			);
		} else {
			dispatch(
				WorkSpaceActions.handleTaskType({
					...form,
					[name]: value,
				}),
			);
		}
	};
	const handleClientChange = (client) => {
		const { label, value } = client;
		dispatch(WorkSpaceActions.handleChange({ name: 'client_id', value }));
	};

	const handleEndOnType = (event) => {
		const { name, value } = event.target;
		if (name == 'type') {
			dispatch(
				WorkSpaceActions.handleTaskRecurrence({
					type: value,
					occurrence: null,
					end_date: null,
				}),
			);
		}

		if (name == 'occurrence') {
			dispatch(
				WorkSpaceActions.handleTaskRecurrence({
					type: name,
					[name]: Number(value),
					end_date: null,
				}),
			);
		}
		if (name == 'never') {
			dispatch(
				WorkSpaceActions.handleTaskRecurrence({
					type: name,
					occurrence: null,
					end_date: null,
				}),
			);
		}
	};

	const onEndDateChange = (date) => {
		dispatch(
			WorkSpaceActions.handleTaskRecurrence({
				type: 'end_date',
				occurrence: null,
				end_date: date,
			}),
		);
	};

	const recurPreferenceChangeHandler = (event) => {
		let { name, value } = event.target;

		if (value === 'null') {
			dispatch(WorkSpaceActions.handleChange({ name, value: null }));
		} else {
			value = Number(value);
			dispatch(WorkSpaceActions.handleChange({ name, value }));
		}
	};

	const isOnMonthlyChangeHandler = (event) => {
		const { name, checked } = event.target;
		if (checked) {
			setMonthlySpecialDate(false);
			dispatch(WorkSpaceActions.handleChange({ name, value: checked }));
			dispatch(
				WorkSpaceActions.handleChange({
					name: 'recur_preference',
					value: null,
				}),
			);
		} else {
			if (
				weekOfMonth(moment(form?.start_date)) === 4 &&
				moment(form?.start_date).add(7, 'days').month() > moment(form?.start_date).month()
			) {
				setMonthlySpecialDate(true);
				dispatch(WorkSpaceActions.handleChange({ name, value: checked }));
				dispatch(
					WorkSpaceActions.handleChange({
						name: 'recur_preference',
						value: null,
					}),
				);
			} else {
				setMonthlySpecialDate(true);
				dispatch(WorkSpaceActions.handleChange({ name, value: checked }));
				dispatch(
					WorkSpaceActions.handleChange({
						name: 'recur_preference',
						value: null,
					}),
				);
				setMonthlySpecialDate(false);
			}
		}
	};

	const handleColorChange = (color) => {
		dispatch(
			WorkSpaceActions.handleChange({
				name: 'color',
				value: color,
			}),
		);
	};

	const handleMultipleGuestSelection = (selecteds) => {
		dispatch(
			WorkSpaceActions.handleChange({
				name: 'meeting_guests',
				value: selecteds,
			}),
		);
	};

	const handleDateChange = (date) => {
		if (form?.repeat_type === REPEAT_TYPE.MONTHLY) {
			if (
				weekOfMonth(moment(date)) === 4 &&
				moment(date).add(7, 'days').month() > moment(date).month() &&
				!form?.is_monthly_on_day
			) {
				setMonthlySpecialDate(true);
				dispatch(
					WorkSpaceActions.handleChange({
						name: 'recur_preference',
						value: null,
					}),
				);
				dispatch(WorkSpaceActions.handleDateChange(date));
			} else {
				dispatch(
					WorkSpaceActions.handleChange({
						name: 'recur_preference',
						value: null,
					}),
				);
				dispatch(WorkSpaceActions.handleDateChange(date));
				setMonthlySpecialDate(false);
			}
		} else {
			dispatch(WorkSpaceActions.handleDateChange(date));
		}
		dispatch(WorkSpaceActions.handleDateChange(date));
	};

	if (allTenantUsers?.length >= 1) {
		allTenantUsers = allTenantUsers
			.filter((a) => a.id !== user.userId)
			?.map((u) => {
				return {
					id: u.id,
					full_name: u.full_name,
				};
			})
			?.sort((a, b) => a.full_name.localeCompare(b.full_name));
	}

	useEffect(() => {
		dispatch(WorkSpaceActions.getTaskTypes());
		dispatch(clientActions.getClients());
		dispatch(userActions.getAllTenantUsers());
	}, [dispatch]);

	useEffect(() => {
		if (clientId) {
			{
				dispatch(
					WorkSpaceActions.handleTaskType({
						...form,
						task_type_id: 2,
					}),
				);
				dispatch(WorkSpaceActions.handleChange({ name: 'client_id', value: clientId }));
				dispatch(WorkSpaceActions.handleChange({ name: 'is_billable', value: 1 }));
			}
		}
	}, []);

	useMemo(() => {
		const remainder = 15 - (moment().minute() % 15);
		const start_time = moment().add(remainder, 'minutes').startOf('minute');

		dispatch(
			WorkSpaceActions.handleChange({
				name: 'start_time',
				value: start_time.format('hh:mm A'), // Format start_time using moment
			}),
		);

		const end_time =
			start_time.format('hh:mm A') === '11:00 PM'
				? start_time.clone().add(59, 'minutes')
				: start_time.clone().add(1, 'hour');

		dispatch(
			WorkSpaceActions.handleChange({
				name: 'end_time',
				value: end_time.format('hh:mm A'), // Format end_time using moment
			}),
		);
	}, [dispatch]);

	return (
		<div
			className='UserAdd-Form d-flex flex-column pr-2'
			style={{ gap: '16px', height: 'inherit' }}
		>
			<div className='improved-input-designs pt-1'>
				<label className='require'>Name</label>
				<input
					className='w-100'
					type='text'
					name='title'
					onChange={handleChange}
					style={Style}
					required
					value={form?.title}
					placeholder='Enter Name'
				/>
			</div>

			<div className='improved-input-designs'>
				<label>Agenda</label>
				<InputTaskDetails
					richText
					type='text'
					name='agenda'
					value={form?.agenda}
					onChange={quillHandlerAgenda}
					placeholderText='Enter agenda'
				/>
			</div>

			<div className='improved-input-designs'>
				<label className='w-100'>Meeting Link</label>
				<input
					className='w-100'
					type='text'
					style={Style}
					value={form?.meeting_link}
					name='meeting_link'
					onChange={handleChange}
					placeholder='Enter meeting link'
				/>
			</div>

			<div className='improved-input-designs'>
				<label className=' w-100'>Guests</label>
				<MultipleDropDown
					name='meeting_guests'
					placeholderText='Select guests'
					options={allTenantUsers?.map((assignee) => {
						return {
							value: assignee?.id,
							label: assignee?.full_name,
							is_checked: form?.teams?.find(({ value }) => value === assignee.value)
								?.is_checked
								? true
								: false,
						};
					})}
					changeHandler={(people) => {
						handleMultipleGuestSelection(
							people?.filter((item) => item.is_checked)?.map(({ value }) => value),
						);
					}}
				/>
			</div>

			<div className='improved-input-designs'>
				<label className='require'>Start Time</label>
				<div className='d-flex align-items-center ' style={{ marginTop: '-5px' }}>
					<DropDown
						name='start_time'
						normalDropDown
						style={{ height: '32px' }}
						value={form?.start_time || ''}
						onChange={(event) => timeRangeHandle(event, 'start_time')}
						options={startTimeOptions()}
						required={true}
						disableIcon
						disableNull
					/>
				</div>
			</div>

			<div className='improved-input-designs'>
				<label className='require'>End Time</label>
				<DropDown
					name='end_time'
					normalDropDown
					style={{ height: '32px' }}
					value={form?.end_time || ''}
					onChange={(event) => timeRangeHandle(event, 'end_time')}
					options={endTimeOptions(form?.start_time)}
					required={true}
					disableIcon
					disableNull
				/>
			</div>

			<div
				className='d-flex flex-column'
				style={{
					backgroundColor: '#F7F9FA',
					padding: '12px',
					borderRadius: '4px',
					gap: '1rem',
				}}
			>
				<label className='BodyOneBold'>Recurrence</label>

				<div className='improved-input-designs'>
					<label className='require'>Task Type</label>

					<DropDown
						onChange={handleTaskType}
						normalDropDown
						name='task_type_id'
						style={{ height: '32px' }}
						selectDisabled={clientId ? true : false}
						required={true}
						disableNull
						value={
							taskTypes
								?.map((task) => {
									return {
										value: task.id,
										label: task.name,
									};
								})
								?.find((item) => item.value == form?.task_type_id)?.value || ''
						}
						options={
							taskTypes && clientId
								? taskTypes
										?.map((task) => {
											return {
												value: task.id,
												label: task.name,
											};
										})
										?.filter((taskType) => taskType?.value == 2)
								: taskTypes &&
								  taskTypes?.map((task) => {
										return {
											value: task.id,
											label: task.name,
										};
								  })
						}
					/>
				</div>

				{form?.task_type_id == 1 && (
					<div className='improved-input-designs'>
						<label className='require'>Date</label>
						<KeyboardDatePicker
							autoOk
							disableToolbar
							value={form?.due_date || null}
							name='due_date'
							keyboardIcon={<CalendarIcon />}
							onChange={handleDateChange}
							format={date_format}
							disablePast
							style={{ padding: '2px' }}
							required
						/>
					</div>
				)}

				{form?.task_type_id === 2 && (
					<>
						<div className='improved-input-designs'>
							<label className='require'>Repeat Type</label>
							<DropDown
								onChange={handleChange}
								normalDropDown
								name='repeat_type'
								style={{ height: '32px' }}
								required={true}
								options={RepeatTypes}
								disableNull
								value={
									RepeatTypes.find((item) => item.value === form?.repeat_type)
										?.value
								}
							/>
						</div>
						<div className='improved-input-designs'>
							<label className='require'>Date</label>
							<KeyboardDatePicker
								autoOk
								disableToolbar
								value={toLocalTz(form?.due_date) || moment.tz()}
								name='due_date'
								onChange={handleDateChange}
								keyboardIcon={<CalendarIcon />}
								format={date_format}
								disablePast
								style={{ padding: '2px' }}
								required
							/>
						</div>

						{form?.repeat_type === REPEAT_TYPE.MONTHLY && monthlySpecialDate && (
							<div className='improved-input-designs pb-3'>
								<label className=''>RECUR PREFERENCES</label>
								<DropDown
									onChange={recurPreferenceChangeHandler}
									normalDropDown
									disableNull
									style={{ height: '32px' }}
									value={
										RECUR_PREFERENCES.find(
											(item) => item.value === form?.recur_preference,
										)?.value
									}
									name='recur_preference'
									options={RECUR_PREFERENCES}
								/>
							</div>
						)}
						<div className='improved-input-designs'>
							<label className='require'>Repeat every</label>
							<div
								className='d-flex w-100 align-items-center justify-content-between'
								style={{
									gap: '16px',
								}}
							>
								<div className='input-field-wrapper'>
									<input
										className='w-100'
										type='number'
										name='repeat_interval'
										required
										onChange={handleChange}
										style={Style}
										value={form?.repeat_interval}
										min={1}
									/>
									<span className='input-field-icon'>
										<Tooltip
											componentsProps={{
												tooltip: {
													sx: {
														backgroundColor: '#FED0A5',
														color: '#333333',
														fontSize: 14,
													},
												},
											}}
											title='Type any numeric value here'
										>
											<InfoIcon />
										</Tooltip>
									</span>
								</div>
								<span className='BodyTwoBold'>
									{REPEAT_TYPE_LABEL[form?.repeat_type]}
								</span>
							</div>
						</div>

						{form?.repeat_type === REPEAT_TYPE.MONTHLY && (
							<div className='pb-3 d-flex flex-row align-items-center'>
								<Checkbox
									className='input-style bg-white'
									checked={form?.is_monthly_on_day}
									sx={{
										color: '#EAEAEA !important',
										'&.Mui-checked': {
											color: '#FB7A03 !important',
										},
										'&.MuiCheckbox-root': {
											'&:hover': {
												boxShadow: 'none',
											},
										},
									}}
									style={{
										padding: 0,
									}}
									name={'is_monthly_on_day'}
									onChange={isOnMonthlyChangeHandler}
								/>
								<label
									style={{ color: '#333333' }}
									className='formText BodyTwoLight p-0 m-0 ml-1 pt-1'
								>
									Repeat on this day of the month
								</label>
							</div>
						)}
						<div className='improved-input-designs'>
							<label className='require'>Ends on</label>
							<DropDown
								onChange={handleEndOnType}
								normalDropDown
								name='type'
								disableNull
								required={true}
								options={END_ON}
								style={{ height: '32px' }}
								value={
									END_ON?.find((item) => item?.value == form?.end_on?.type)?.value
								}
							/>
						</div>

						{form?.end_on?.type === 'occurrence' ? (
							<div className='improved-input-designs pb-3'>
								<label className='w-100 require'>Number of Occurrences</label>
								<div
									className='d-flex flex-row align-items-center'
									style={{ gap: '5px' }}
								>
									<input
										className='w-50'
										type='number'
										name='occurrence'
										required={true}
										value={form?.end_on?.occurrence || null}
										onChange={handleEndOnType}
										min={1}
									/>
								</div>
							</div>
						) : (
							form?.end_on?.type === 'end_date' && (
								<div className='improved-input-designs pb-3 d-flex flex-column'>
									<label className='w-100 require'>Ends On</label>
									<KeyboardDatePicker
										autoOk
										disableToolbar
										value={form?.end_on?.end_date || null}
										name='end_date'
										onChange={onEndDateChange}
										keyboardIcon={<CalendarIcon />}
										format={date_format}
										minDate={form?.start_date || moment()}
										required
									/>
								</div>
							)
						)}
					</>
				)}
			</div>

			<div className='improved-input-designs pb-3'>
				<label className='require'>Bill Type</label>
				<EditableRadioButton
					name='is_billable'
					value={clientId ? 'Billable' : form?.is_billable}
					onChange={handleChange}
					options={BillTypes}
					required
					disabled={clientId}
					Edit
				/>
			</div>

			<div className='improved-input-designs pb-3'>
				<label className={form?.is_billable === 1 ? 'require' : ''}>Client</label>
				<NewSearchDropdown
					selectClassName={'w-100'}
					width={'380px'}
					value={
						clients
							?.map((client) => {
								{
									return {
										value: client.id,
										label:
											client?.dba?.trim() == ''
												? client.company_name
												: client.dba,
									};
								}
							})
							.find((item) => item.value == form?.client_id)?.value
					}
					placeholder='Please Select'
					disableLetters
					changeHandler={handleClientChange}
					options={
						clientId
							? clients
									?.map((client) => {
										return {
											value: client.id,
											label:
												client?.dba?.trim() == ''
													? client.company_name
													: client.dba,
										};
									})
									?.filter((client) => client?.value == clientId)
							: clients?.map((client) => {
									return {
										value: client.id,
										label:
											client?.dba?.trim() == ''
												? client.company_name
												: client.dba,
									};
							  })
					}
					name={'client_id'}
					label={'Client'}
					selecDisabled={clientId ? true : false}
					required={form?.is_billable === 1 ? true : false}
					disableNull={false}
				/>
			</div>

			<div className='improved-input-designs pb-3'>
				<label>Notes</label>
				<InputTaskDetails
					richText
					type='text'
					name='notes'
					value={form?.notes}
					onChange={quillHandlerNotes}
					placeholderText='Enter Notes'
				/>
			</div>
			<div className='new-input-designs'>
				<SelectMyTaskColor
					form={form}
					handleColorChange={handleColorChange}
					requireClass='require'
				/>
			</div>
		</div>
	);
};

export default MeetingMyTaskModalInputs;
