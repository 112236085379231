import { forwardRef } from 'react';
import FormButtonOne from '../Buttons/FormButtonOne';

const ConfirmationModal = forwardRef(function confirm(
	{ onClose, onConfirm, description, title, loading },
	ref,
) {
	return (
		<div tabIndex={-1} ref={ref} autoComplete='off' className='my-task-modal'>
			<div className='ConfirmationModal'>
				<h2 className='HeadlineTwoBold'>{title}</h2>

				<div className='BodyOneLight'>{description}</div>
			</div>
			<div className='VerticalSpacer bg-white p-3 rounded-bottom d-flex justify-content-between'>
				<FormButtonOne onClick={onClose} type='button' className='secondary-btn-light'>
					Cancel
				</FormButtonOne>
				<FormButtonOne loading={loading} onClick={onConfirm}>
					Confirm
				</FormButtonOne>
			</div>
		</div>
	);
});

export default ConfirmationModal;
